import React from "react";
import { Card } from "antd5";
import classNames from "classnames";

import AssigneeSelect from "components/form_components/AssigneeSelect";
import { QualificationActions } from "components/record_qualifications/QualificationActions";
import { QualificationStatus } from "components/record_qualifications/QualificationStatus";
import { PropertyInline } from "lib/core_components/Property";
import { BasicSignal, getSignalTypes } from "../../lib/types/models";
import { Qualification, QualificationEnum } from "../record_qualifications/QualificationStatus";

import css from "./WorkflowCard.module.scss";

type WorkflowCardProps = {
  procurementStage: { id: string; stage: string; qualification?: Qualification };
  notice: { guid: string; name: string; buyers: { id: number; guid: string; name: string }[] };
  assignee?: {
    guid: string;
    firstName: string;
    lastName: string;
  };
  score: number;
  signals: BasicSignal[];
  trackingContextName: "Title header" | "Record preview slide-out";
};

const QUALIFICATIONS: Qualification[] = [
  QualificationEnum.Won,
  QualificationEnum.Lost,
  QualificationEnum.NoBid,
];

export default function WorkflowCard({
  notice,
  procurementStage,
  score,
  assignee,
  signals,
  trackingContextName,
}: WorkflowCardProps) {
  const includeActionsSection = procurementStage.qualification
    ? !QUALIFICATIONS.includes(procurementStage.qualification)
    : true;

  return (
    <div>
      <Card
        className={classNames(css.upperCard, {
          [css.borderRadius]: !includeActionsSection,
        })}
      >
        <div className={css.cardContent}>
          <h2 className={css.mediumTitle}>Workflow</h2>
          <PropertyInline
            title="Status"
            value={
              <QualificationStatus
                procurementStage={{
                  stage: procurementStage.stage,
                  id: procurementStage.id,
                  qualification: procurementStage.qualification,
                }}
                noticeGuid={notice.guid}
                score={score}
                signalTypes={getSignalTypes(signals)}
                enableUpdate
              />
            }
            className={classNames(css.property, css.customAlign)}
          />
          <PropertyInline
            title="Assign to"
            value={
              <AssigneeSelect
                recordGuid={notice?.guid}
                procurementStageId={procurementStage.id}
                procurementStageStage={procurementStage.stage}
                signals={signals}
                score={score}
                assignee={assignee}
                iconClassName={css.caretDownIcon}
              />
            }
            className={css.property}
          />
        </div>
      </Card>
      {includeActionsSection && (
        <Card className={css.lowerCard}>
          <QualificationActions
            procurementStage={{
              stage: procurementStage.stage,
              id: procurementStage.id,
              qualification: procurementStage.qualification,
            }}
            notice={{
              name: notice.name,
              guid: notice.guid,
              buyers: notice.buyers,
            }}
            signalTypes={getSignalTypes(signals)}
            score={score}
            contextSource={trackingContextName}
            buttonClassName={css.qualificationBtn}
            containerClassName={css.qualificationContainer}
          />
        </Card>
      )}
    </div>
  );
}
