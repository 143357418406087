import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useStotlesApi } from "lib/stotlesApiContext";
import { EventDataTypes, EventNames, useTracking } from "lib/tracking";
import { UserListSummaryWithCount } from "lib/types/models";

type Params = {
  name: string;
};

export function useCreateNoticeList(
  options?: UseMutationOptions<UserListSummaryWithCount, unknown, Params, unknown>,
) {
  const api = useStotlesApi();
  const { logEvent } = useTracking();
  const queryClient = useQueryClient();
  return useMutation(async ({ name }: Params) => api.createUserList(name), {
    ...options,
    onSuccess: async (data, vars, ctx) => {
      await queryClient.invalidateQueries(["noticeLists"]);

      logEvent(EventNames.listCreated, {
        "User List name": data.name,
        "User List id": data.id,
        "Data type": EventDataTypes.recordList,
      });
      options?.onSuccess?.(data, vars, ctx);
    },
  });
}
