import React, { useState } from "react";
import { Input, Tag } from "antd5";

import css from "./CandidateAliasSelection.module.scss";

type Props = {
  aliases: string[];
  setAliases: (aliases: string[]) => void;
  adminReview: boolean | undefined;
};

function CandidateAliasSelection({ aliases, setAliases, adminReview }: Props): JSX.Element {
  const [inputValue, setInputValue] = useState("");

  const onAliasSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim()) {
      setAliases([...aliases, inputValue.trim()]);
      setInputValue("");
      e.preventDefault();
    }
  };

  const onAliasRemove = (removedAlias: string) => {
    const newAliases = aliases.filter((alias) => alias !== removedAlias);
    setAliases(newAliases);
  };

  return (
    <div className={css.aliasContainer}>
      <h3>{adminReview ? "Candidate aliases" : "Add aliases"}</h3>
      <Input
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={onAliasSubmit}
        placeholder="Type and press Enter"
      />

      <div className={css.tagContainer}>
        {aliases.map((alias: string, index: number) => (
          <Tag key={`${alias}-${index}`} closable onClose={() => onAliasRemove(alias)}>
            {alias}
          </Tag>
        ))}
      </div>
    </div>
  );
}

export default CandidateAliasSelection;
