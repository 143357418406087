import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { APIError } from "lib/Api";
import { RecordSearchRequestDto, RecordSearchResponseDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

export type RecordSearchQueryKey = ["records", RecordSearchRequestDto];

export function useRecordSearch(
  requestDto: RecordSearchRequestDto,
  options?: UseQueryOptions<
    RecordSearchResponseDto | null,
    unknown,
    RecordSearchResponseDto | null,
    RecordSearchQueryKey
  >,
) {
  const api = useOpenApi();
  return useQuery(
    ["records", requestDto],
    async ({ queryKey: [, dto] }) => {
      try {
        const response = await api.searchRecords({ recordSearchRequestDto: dto });
        return response;
      } catch (e) {
        if (e instanceof APIError && e.statusCode === 404) {
          return null;
        }
        throw e;
      }
    },
    options,
  );
}
