/* eslint no-restricted-imports: ["error", { patterns: [{
    group: ["*"],
    message: "Please consider the importance of this import, and add exceptions if really necessary"
}]}]
--------
This module is used for landing pages, which need to be very light and minimal on imports. If you
need to change some functionality here, consider looking at lib/search/SequentialRequestHandler
*/

export class OutOfOrderResponseError extends Error {
  name = "OutOfOrderResponseError";

  constructor() {
    super();

    // https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, OutOfOrderResponseError.prototype);
  }
}

/**
 * Wraps a given function so that responses coming out of order throw an error.
 *
 * If a promise of `fetchFunction` call resovles after the wrapped function was called again,
 * an error is thrown.
 *
 * @throws {OutOfOrderResponseError} Must make sure it is handled in an appropriate manner by the caller
 */
export function enforceSequentialResponse<Params extends unknown[], Ret>(
  asyncFunction: (...args: Params) => Promise<Ret>,
): typeof asyncFunction {
  let lastRequestId = 0;
  return async (...args: Params): Promise<Ret> => {
    const thisRequestId = ++lastRequestId;

    const response = await asyncFunction(...args);

    if (thisRequestId !== lastRequestId) {
      throw new OutOfOrderResponseError();
    }

    return response;
  };
}
