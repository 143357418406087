import React, { useEffect, useState } from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { DevCycleDebugger } from "@devcycle/web-debugger/react";
import { Dropdown } from "antd5";
import classnames from "classnames";
import SwitchCompanyModal from "pages/admin/SwitchCompanyModal";

import { LAYER_CAKE_FILTERS, useVariableValue } from "lib/featureFlags";
import { AdminAPIProvider } from "lib/stotlesAdminApiContext";

import css from "./AdminBar.module.scss";

const HIDE_ADMIN_BAR_KEY = "hideAdminBar";

type Props = {
  content: () => React.ReactNode;
};

type ModalShowed = null | "switch_company" | "switch_team";

export function AdminBar({ content }: Props) {
  const [show, setShow] = useState<boolean>(false);
  const [modalShowed, setModalShowed] = useState<ModalShowed>(null);

  const isLayerCakeFiltersEnabled = useVariableValue(LAYER_CAKE_FILTERS, false);

  let cssAdminBarEnv = css.adminDev;
  let cssAdminButtonEnv = css.adminButtonDev;
  if (window.RAILS_ENV === "production") {
    if (window.SANDBOX_NAME) {
      cssAdminBarEnv = css.adminSandbox;
      cssAdminButtonEnv = css.adminButtonSandbox;
    } else {
      cssAdminBarEnv = css.adminProd;
      cssAdminButtonEnv = css.adminButtonProd;
    }
  }

  const toggleAdminFeaturesVisability = (flag = true) => {
    sessionStorage.setItem(HIDE_ADMIN_BAR_KEY, String(flag));
    setShow(!flag);
  };

  useEffect(() => {
    if (sessionStorage.getItem(HIDE_ADMIN_BAR_KEY) !== "true") {
      toggleAdminFeaturesVisability(false);
    }

    function handleKeyDown(event: KeyboardEvent) {
      if (typeof event?.getModifierState !== "function") {
        return;
      }

      if (
        (event.getModifierState("Control") &&
          event.getModifierState("Shift") &&
          event.code === "KeyU") ||
        event.code === "KeyK"
      ) {
        toggleAdminFeaturesVisability(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  if (!window.currentUser || window.currentUser?.admin === false) {
    return null;
  }

  let buttonLinkText = "DEVELOPMENT ADMIN";
  if (window.RAILS_ENV === "production") {
    buttonLinkText = window.SANDBOX_NAME ? `SANDBOX ADMIN: ${window.SANDBOX_NAME}` : "ADMIN";
  }

  const menuItems = [
    {
      key: "switch_company",
      onClick: () => setModalShowed("switch_company"),
      label: "Switch company",
    },
    {
      key: "switch_team",
      onClick: () => setModalShowed("switch_team"),
      label: "Switch team",
    },
  ];

  return (
    <section className={classnames(css.adminBar, cssAdminBarEnv, show ? css.show : null)}>
      <a href="/admin" className={classnames(cssAdminButtonEnv, css.adminButton)}>
        {buttonLinkText}
      </a>
      {isLayerCakeFiltersEnabled && (
        <div className={css.layerCake}>
          Layer Cake 🎂
          {!window.currentUser.use_supplier_name ? ` & signals migrated` : ` signals not migrated`}
        </div>
      )}
      <AdminAPIProvider>
        <SwitchCompanyModal
          userId={String(window.currentUser.id)}
          userGuid={window.currentUser.guid}
          onClose={() => setModalShowed(null)}
          isOpen={modalShowed !== null}
          switchTeams={modalShowed === "switch_team"}
        />
      </AdminAPIProvider>
      <div className={css.adminBarContent}>{content()}</div>
      <div className={css.adminBarProfile}>
        <div>{window.HOSTNAME}</div>
        <Dropdown menu={{ items: menuItems }}>
          <a href={`/admin/users/${window.currentUser.id}`} target="_blank">
            {window.currentUser.email}
            <CaretDownOutlined />
          </a>
        </Dropdown>
        <a href={`/admin/companies/${window.currentUser.company.id}`} target="_blank">
          {window.currentUser.company.name}
          {window.currentUser.team?.name ? ` ${window.currentUser.team?.name}` : null}
        </a>
        <button
          onClick={() => toggleAdminFeaturesVisability()}
          className={css.adminBarClose}
          title="To show the admin bar again press Ctrl+Shift+U (U as in unhide)."
        >
          &#10799;
        </button>
      </div>
      {show ? <DevCycleDebugger position="left" shouldEnable={show} /> : null}
    </section>
  );
}
