import { Configuration, DefaultApi, Middleware, ResponseContext } from "./generated/app-api";
import { APIError } from "./Api";
import { defineInjectable } from "./reactInjector";
import { getCSRFToken } from "./utils";

const authToken = window.authToken;

const headers: Record<string, string> = {
  "X-CSRF-Token": getCSRFToken() as string,
};

if (authToken) {
  headers.Authorization = `Bearer ${authToken}`;
}

/**
 * This generates our APIError exception from error responses
 * returned by the backend
 */
class OpenAPIErrorMiddleware implements Middleware {
  async post(context: ResponseContext): Promise<void | Response> {
    const { response, url } = context;
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    throw await APIError.fromFetchResponse(url, response);
  }
}

const apiConfig = new Configuration({
  basePath: "",
  headers,
  middleware: [new OpenAPIErrorMiddleware()],
});

export const STOTLES_OPEN_API = new DefaultApi(apiConfig);

const OPEN_API_INJECTABLE = defineInjectable<DefaultApi>("AppAPI", () => {
  return STOTLES_OPEN_API;
});

export const useOpenApi = OPEN_API_INJECTABLE.useInstance;

export const OpenAPIProvider = OPEN_API_INJECTABLE.Provider;
