import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  HighlightNoticeQuery,
  NoticeHighlightRequest,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const highlightNoticeQuery = graphql(`
  query highlightNotice($request: NoticeHighlightRequest!) {
    highlightNotice(NoticeHighlightRequest: $request) {
      title
      description
    }
  }
`);

export type NoticeHighlights = HighlightNoticeQuery["highlightNotice"];

export function useNoticeHighlight(
  req: NoticeHighlightRequest,
  options?: UseQueryOptions<HighlightNoticeQuery, unknown, HighlightNoticeQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["noticeHighlight", req],
    highlightNoticeQuery,
    [{ request: req }],
    {
      ...options,
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
    },
  );

  return { data: data?.highlightNotice, ...rest };
}
