import * as React from "react";

import { Markdown } from "./Markdown";

import css from "./SectionHeader.module.scss";

type Props = {
  title?: string;
  description?: string;
};

function SectionHeader({ title, description }: Props): JSX.Element | null {
  if (!title && !description) return null;
  return (
    <div className={css.sectionHeader}>
      {title && <h2 className={css.sectionTitle}>{title}</h2>}
      {description && <Markdown className={css.sectionDescription} text={description} />}
    </div>
  );
}

export default SectionHeader;
