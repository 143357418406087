import React from "react";
import { DateTime } from "luxon";

import { TextLink } from "components/actions/Links";
import { Markdown } from "components/reports/builder_components/Markdown";
import { useStotlesApi } from "lib/stotlesApiContext";

import css from "./Header.module.scss";

import StotlesLogo from "../../../../../assets/images/stotles_logo_blue.svg";

type HeaderProps = {
  title: string;
  description: string;
  date?: Date | null;
  logoUrl?: string;
  companyId: string;
  useCompanyLogo?: boolean;
  id: string;
};

export function Header({
  title,
  description,
  date,
  logoUrl,
  companyId,
  useCompanyLogo,
  id,
}: HeaderProps): JSX.Element {
  // The logo that stotles has on file for this company
  const [companyLogoUrl, setCompanyLogoUrl] = React.useState<string | undefined>(undefined);

  const api = useStotlesApi();
  React.useEffect(() => {
    if (useCompanyLogo) {
      const fetchCompanyLogo = async () => {
        const { logo_url } = await api.getCompanyLogo(companyId);
        setCompanyLogoUrl(logo_url);
      };

      void fetchCompanyLogo();
    }
  }, [useCompanyLogo, api, companyId]);

  // If the flag useCompanyLogo is true, then we'll fetch the logo stotles has on file for this company
  // Else, we'll check if we've saved a different logoUrl to use
  // That means, the clientLogoUrl can be a string or undefined
  const clientLogoUrl = React.useMemo(() => {
    if (useCompanyLogo) {
      return companyLogoUrl;
    } else {
      return logoUrl;
    }
  }, [logoUrl, companyLogoUrl, useCompanyLogo]);

  return (
    <div className={css.header} id={id}>
      <div className={css.logosContainer}>
        <TextLink to="https://stotles.com">
          <img alt="stotles logo" width={150} height={48} src={StotlesLogo} />
        </TextLink>
        {clientLogoUrl && <img alt="client logo" height={48} src={clientLogoUrl} />}
      </div>
      <h1 className={css.title}>{title}</h1>
      <div className={css.description}>
        <div>
          <Markdown className={css.markdownText} text={description} />
        </div>
        {date && <div>{DateTime.fromJSDate(date).toFormat("MMMM d, yyyy")}</div>}
      </div>
    </div>
  );
}
