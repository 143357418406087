/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyersRelationshipStatsResponseSignalStatsStatsCompetitors } from './BuyersRelationshipStatsResponseSignalStatsStatsCompetitors';
import {
    BuyersRelationshipStatsResponseSignalStatsStatsCompetitorsFromJSON,
    BuyersRelationshipStatsResponseSignalStatsStatsCompetitorsFromJSONTyped,
    BuyersRelationshipStatsResponseSignalStatsStatsCompetitorsToJSON,
} from './BuyersRelationshipStatsResponseSignalStatsStatsCompetitors';
import type { BuyersRelationshipStatsResponseSignalStatsStatsExpiries } from './BuyersRelationshipStatsResponseSignalStatsStatsExpiries';
import {
    BuyersRelationshipStatsResponseSignalStatsStatsExpiriesFromJSON,
    BuyersRelationshipStatsResponseSignalStatsStatsExpiriesFromJSONTyped,
    BuyersRelationshipStatsResponseSignalStatsStatsExpiriesToJSON,
} from './BuyersRelationshipStatsResponseSignalStatsStatsExpiries';
import type { BuyersRelationshipStatsResponseSignalStatsStatsMatchingRecords } from './BuyersRelationshipStatsResponseSignalStatsStatsMatchingRecords';
import {
    BuyersRelationshipStatsResponseSignalStatsStatsMatchingRecordsFromJSON,
    BuyersRelationshipStatsResponseSignalStatsStatsMatchingRecordsFromJSONTyped,
    BuyersRelationshipStatsResponseSignalStatsStatsMatchingRecordsToJSON,
} from './BuyersRelationshipStatsResponseSignalStatsStatsMatchingRecords';
import type { BuyersRelationshipStatsResponseSignalStatsStatsOpenOps } from './BuyersRelationshipStatsResponseSignalStatsStatsOpenOps';
import {
    BuyersRelationshipStatsResponseSignalStatsStatsOpenOpsFromJSON,
    BuyersRelationshipStatsResponseSignalStatsStatsOpenOpsFromJSONTyped,
    BuyersRelationshipStatsResponseSignalStatsStatsOpenOpsToJSON,
} from './BuyersRelationshipStatsResponseSignalStatsStatsOpenOps';
import type { BuyersRelationshipStatsResponseSignalStatsStatsPartners } from './BuyersRelationshipStatsResponseSignalStatsStatsPartners';
import {
    BuyersRelationshipStatsResponseSignalStatsStatsPartnersFromJSON,
    BuyersRelationshipStatsResponseSignalStatsStatsPartnersFromJSONTyped,
    BuyersRelationshipStatsResponseSignalStatsStatsPartnersToJSON,
} from './BuyersRelationshipStatsResponseSignalStatsStatsPartners';

/**
 * 
 * @export
 * @interface BuyersRelationshipStatsResponseSignalStatsStats
 */
export interface BuyersRelationshipStatsResponseSignalStatsStats {
    /**
     * 
     * @type {BuyersRelationshipStatsResponseSignalStatsStatsCompetitors}
     * @memberof BuyersRelationshipStatsResponseSignalStatsStats
     */
    competitors: BuyersRelationshipStatsResponseSignalStatsStatsCompetitors;
    /**
     * 
     * @type {BuyersRelationshipStatsResponseSignalStatsStatsPartners}
     * @memberof BuyersRelationshipStatsResponseSignalStatsStats
     */
    partners: BuyersRelationshipStatsResponseSignalStatsStatsPartners;
    /**
     * 
     * @type {BuyersRelationshipStatsResponseSignalStatsStatsMatchingRecords}
     * @memberof BuyersRelationshipStatsResponseSignalStatsStats
     */
    matchingRecords: BuyersRelationshipStatsResponseSignalStatsStatsMatchingRecords;
    /**
     * 
     * @type {BuyersRelationshipStatsResponseSignalStatsStatsOpenOps}
     * @memberof BuyersRelationshipStatsResponseSignalStatsStats
     */
    openOps: BuyersRelationshipStatsResponseSignalStatsStatsOpenOps;
    /**
     * 
     * @type {BuyersRelationshipStatsResponseSignalStatsStatsExpiries}
     * @memberof BuyersRelationshipStatsResponseSignalStatsStats
     */
    expiries: BuyersRelationshipStatsResponseSignalStatsStatsExpiries;
}

/**
 * Check if a given object implements the BuyersRelationshipStatsResponseSignalStatsStats interface.
 */
export function instanceOfBuyersRelationshipStatsResponseSignalStatsStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "competitors" in value;
    isInstance = isInstance && "partners" in value;
    isInstance = isInstance && "matchingRecords" in value;
    isInstance = isInstance && "openOps" in value;
    isInstance = isInstance && "expiries" in value;

    return isInstance;
}

export function BuyersRelationshipStatsResponseSignalStatsStatsFromJSON(json: any): BuyersRelationshipStatsResponseSignalStatsStats {
    return BuyersRelationshipStatsResponseSignalStatsStatsFromJSONTyped(json, false);
}

export function BuyersRelationshipStatsResponseSignalStatsStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyersRelationshipStatsResponseSignalStatsStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'competitors': BuyersRelationshipStatsResponseSignalStatsStatsCompetitorsFromJSON(json['competitors']),
        'partners': BuyersRelationshipStatsResponseSignalStatsStatsPartnersFromJSON(json['partners']),
        'matchingRecords': BuyersRelationshipStatsResponseSignalStatsStatsMatchingRecordsFromJSON(json['matching_records']),
        'openOps': BuyersRelationshipStatsResponseSignalStatsStatsOpenOpsFromJSON(json['open_ops']),
        'expiries': BuyersRelationshipStatsResponseSignalStatsStatsExpiriesFromJSON(json['expiries']),
    };
}

export function BuyersRelationshipStatsResponseSignalStatsStatsToJSON(value?: BuyersRelationshipStatsResponseSignalStatsStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'competitors': BuyersRelationshipStatsResponseSignalStatsStatsCompetitorsToJSON(value.competitors),
        'partners': BuyersRelationshipStatsResponseSignalStatsStatsPartnersToJSON(value.partners),
        'matching_records': BuyersRelationshipStatsResponseSignalStatsStatsMatchingRecordsToJSON(value.matchingRecords),
        'open_ops': BuyersRelationshipStatsResponseSignalStatsStatsOpenOpsToJSON(value.openOps),
        'expiries': BuyersRelationshipStatsResponseSignalStatsStatsExpiriesToJSON(value.expiries),
    };
}

