/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters } from './BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters';
import {
    BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersFromJSON,
    BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersFromJSONTyped,
    BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersToJSON,
} from './BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters';

/**
 * 
 * @export
 * @interface BuyersRelationshipStatsRequest
 */
export interface BuyersRelationshipStatsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BuyersRelationshipStatsRequest
     */
    buyerGuids?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BuyersRelationshipStatsRequest
     */
    buyerListId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BuyersRelationshipStatsRequest
     */
    onlyRelevantBuyers?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BuyersRelationshipStatsRequest
     */
    sort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuyersRelationshipStatsRequest
     */
    sortOrder?: string | null;
    /**
     * 
     * @type {BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters}
     * @memberof BuyersRelationshipStatsRequest
     */
    filters?: BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters;
    /**
     * 
     * @type {number}
     * @memberof BuyersRelationshipStatsRequest
     */
    limit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BuyersRelationshipStatsRequest
     */
    offset?: number | null;
}

/**
 * Check if a given object implements the BuyersRelationshipStatsRequest interface.
 */
export function instanceOfBuyersRelationshipStatsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BuyersRelationshipStatsRequestFromJSON(json: any): BuyersRelationshipStatsRequest {
    return BuyersRelationshipStatsRequestFromJSONTyped(json, false);
}

export function BuyersRelationshipStatsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyersRelationshipStatsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buyerGuids': !exists(json, 'buyer_guids') ? undefined : json['buyer_guids'],
        'buyerListId': !exists(json, 'buyer_list_id') ? undefined : json['buyer_list_id'],
        'onlyRelevantBuyers': !exists(json, 'only_relevant_buyers') ? undefined : json['only_relevant_buyers'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'sortOrder': !exists(json, 'sort_order') ? undefined : json['sort_order'],
        'filters': !exists(json, 'filters') ? undefined : BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersFromJSON(json['filters']),
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
    };
}

export function BuyersRelationshipStatsRequestToJSON(value?: BuyersRelationshipStatsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buyer_guids': value.buyerGuids,
        'buyer_list_id': value.buyerListId,
        'only_relevant_buyers': value.onlyRelevantBuyers,
        'sort': value.sort,
        'sort_order': value.sortOrder,
        'filters': BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersToJSON(value.filters),
        'limit': value.limit,
        'offset': value.offset,
    };
}

