import * as React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd5";
import classnames from "classnames";

import Steps from "components/form_components/Steps";

import css from "./NavigationFooter.module.scss";

type Props = {
  onBack?: () => void;
  onNext: () => void;
  onSkip?: () => void;
  backButtonLabel?: string;
  nextButtonLabel: string;
  currentStep: number;
  totalSteps: number;
  nextDisabled?: boolean;
  nextLoading?: boolean;
  enableSkip?: boolean;
  renderAdditionalCTARight?: () => JSX.Element;
  renderAdditionalCTALeft?: () => JSX.Element;
  nextHtmlType?: "button" | "submit";
  className?: string;
};

function NavigationFooter({
  onBack,
  onNext,
  onSkip,
  backButtonLabel,
  nextButtonLabel,
  currentStep,
  totalSteps,
  nextDisabled,
  nextLoading,
  enableSkip,
  renderAdditionalCTARight,
  renderAdditionalCTALeft,
  className,
  nextHtmlType = "button",
}: Props): JSX.Element {
  return (
    <nav
      aria-label="onboarding-navigation"
      className={classnames(css.onboardingNavigation, className)}
    >
      <div>
        {backButtonLabel ? (
          <Button className={css.backButton} type="link" onClick={onBack}>
            <LeftOutlined /> {backButtonLabel}
          </Button>
        ) : undefined}
        {renderAdditionalCTALeft && renderAdditionalCTALeft()}
      </div>
      <div className={css.stepsContainer}>
        <Steps totalSteps={totalSteps} currentStep={currentStep} />
      </div>
      <div className={css.nextContainer}>
        {enableSkip && (
          <Button className={css.skipButton} type="link" onClick={onSkip}>
            Skip
          </Button>
        )}
        {renderAdditionalCTARight && renderAdditionalCTARight()}
        <Button
          className={css.nextButton}
          type="primary"
          onClick={onNext}
          disabled={nextDisabled}
          loading={nextLoading}
          htmlType={nextHtmlType}
          key="nextButton"
          size="large"
        >
          {nextButtonLabel}
        </Button>
      </div>
    </nav>
  );
}

export default NavigationFooter;
