/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PermissionUpdate } from './PermissionUpdate';
import {
    PermissionUpdateFromJSON,
    PermissionUpdateFromJSONTyped,
    PermissionUpdateToJSON,
} from './PermissionUpdate';

/**
 * 
 * @export
 * @interface UpdateBuyerListPermissionsRequest
 */
export interface UpdateBuyerListPermissionsRequest {
    /**
     * 
     * @type {Array<PermissionUpdate>}
     * @memberof UpdateBuyerListPermissionsRequest
     */
    updates: Array<PermissionUpdate>;
}

/**
 * Check if a given object implements the UpdateBuyerListPermissionsRequest interface.
 */
export function instanceOfUpdateBuyerListPermissionsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "updates" in value;

    return isInstance;
}

export function UpdateBuyerListPermissionsRequestFromJSON(json: any): UpdateBuyerListPermissionsRequest {
    return UpdateBuyerListPermissionsRequestFromJSONTyped(json, false);
}

export function UpdateBuyerListPermissionsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBuyerListPermissionsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updates': ((json['updates'] as Array<any>).map(PermissionUpdateFromJSON)),
    };
}

export function UpdateBuyerListPermissionsRequestToJSON(value?: UpdateBuyerListPermissionsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updates': ((value.updates as Array<any>).map(PermissionUpdateToJSON)),
    };
}

