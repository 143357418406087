import React from "react";

import {
  CpvChartSection,
  DocumentReferenceParameters,
  HeaderSection,
  LinkListSection,
  RecordListSection,
  ReportConfig,
  RichTextSection,
} from "lib/generated/admin-api";
import { DocumentListSection } from "lib/generated/admin-api/models/DocumentListSection";
import { SuppliersSection } from "lib/generated/admin-api/models/SuppliersSection";
import { AiQuestionSection } from "./items/aiQuestion/AiQuestionSection";
import ContactsGrid from "./items/contacts/ContactsChart";
import CpvChart from "./items/cpv_chart/CpvChart";
import DocumentList from "./items/document_list/DocumentList";
import { Header } from "./items/header/Header";
import IframeSection from "./items/iframe/IframeSection";
import LinkList from "./items/link_list/LinkList";
import RecordList from "./items/record_list/RecordList";
import Suppliers from "./items/suppliers/Suppliers";
import { RichText } from "./items/text/Text";
import { DispatchComponentProps, ReadOnlySection, SectionDispatcher } from "./Section";
import { extractMetadata } from "./utils";

import "react-mde/lib/styles/css/react-mde-all.css";
import css from "./Report.module.scss";

const ReportSectionDispatcher: SectionDispatcher = {
  RichText: function ({
    section,
    reportSectionId,
  }: DispatchComponentProps<RichTextSection>): JSX.Element | null {
    return <RichText {...section.generated} id={reportSectionId} />;
  },
  Header: function ({
    section: { generated },
    metadata: { title, publishedAt, companyId },
    reportSectionId,
  }: DispatchComponentProps<HeaderSection>): JSX.Element {
    return (
      <Header
        {...generated}
        title={title}
        date={publishedAt}
        companyId={companyId}
        id={reportSectionId}
      />
    );
  },
  RecordList: function ({
    section,
    reportSectionId,
  }: DispatchComponentProps<RecordListSection>): JSX.Element {
    return <RecordList {...section.generated} id={reportSectionId} />;
  },
  LinkList: function ({
    section,
    reportSectionId,
  }: DispatchComponentProps<LinkListSection>): JSX.Element {
    return <LinkList {...section.generated} id={reportSectionId} />;
  },
  DocumentList: function ({
    section,
    metadata: { reportId },
    reportSectionId,
    getDocumentRef,
  }: DispatchComponentProps<DocumentListSection>): JSX.Element {
    return (
      <DocumentList
        {...section.generated}
        id={reportSectionId}
        getDocumentRef={getDocumentRef}
        reportId={reportId}
      />
    );
  },
  CpvChart: function ({
    section: { generated },
    reportSectionId,
  }: DispatchComponentProps<CpvChartSection>): JSX.Element | null {
    return <CpvChart {...generated} id={reportSectionId} />;
  },
  Suppliers: function ({
    section,
    reportSectionId,
  }: DispatchComponentProps<SuppliersSection>): JSX.Element {
    return <Suppliers {...section.generated} {...section.parameters} id={reportSectionId} />;
  },
  Contacts: ContactsGrid,
  Iframe: IframeSection,
  AiQuestion: AiQuestionSection,
};

type ReportProps = { report: ReportConfig };

export function Report({ report }: ReportProps): JSX.Element {
  const metadata = React.useMemo(() => extractMetadata(report), [report]);

  const getDocumentRef = React.useCallback(
    (id: string): DocumentReferenceParameters | undefined => {
      return report.documents?.find((d) => d.id === id)?.parameters;
    },
    [report.documents],
  );

  return (
    <div>
      <div className={css.reportLayout}>
        {metadata &&
          report.reportData.sections.map((section) => (
            <div key={section.id} className={css.section}>
              <ReadOnlySection
                reportSectionId={section.id}
                section={section}
                dispatcher={ReportSectionDispatcher}
                metadata={metadata}
                getDocumentRef={getDocumentRef}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
