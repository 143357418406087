/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PagingInfo
 */
export interface PagingInfo {
    /**
     * 
     * @type {number}
     * @memberof PagingInfo
     */
    totalResults: number;
    /**
     * 
     * @type {number}
     * @memberof PagingInfo
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof PagingInfo
     */
    limit: number | null;
    /**
     * 
     * @type {number}
     * @memberof PagingInfo
     */
    nextOffset: number | null;
}

/**
 * Check if a given object implements the PagingInfo interface.
 */
export function instanceOfPagingInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalResults" in value;
    isInstance = isInstance && "offset" in value;
    isInstance = isInstance && "limit" in value;
    isInstance = isInstance && "nextOffset" in value;

    return isInstance;
}

export function PagingInfoFromJSON(json: any): PagingInfo {
    return PagingInfoFromJSONTyped(json, false);
}

export function PagingInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagingInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalResults': json['total_results'],
        'offset': json['offset'],
        'limit': json['limit'],
        'nextOffset': json['next_offset'],
    };
}

export function PagingInfoToJSON(value?: PagingInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_results': value.totalResults,
        'offset': value.offset,
        'limit': value.limit,
        'next_offset': value.nextOffset,
    };
}

