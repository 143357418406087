import * as React from "react";
import { Empty } from "antd5";

import { DispatchComponentProps } from "components/reports/Section";
import { IframeSection as iIframeSection } from "lib/generated/admin-api";

export default function IframeSection({
  section,
  reportSectionId,
}: DispatchComponentProps<iIframeSection>): JSX.Element {
  return (
    <div id={reportSectionId}>
      {section.src ? (
        <iframe
          src={section.src}
          frameBorder="0"
          seamless
          style={{ backgroundColor: "transparent", width: "100%", height: "100%", minHeight: 600 }}
        />
      ) : (
        <Empty description="Paste an iframe or src to get started" />
      )}
    </div>
  );
}
