/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateGuestAccessPassRequest
 */
export interface UpdateGuestAccessPassRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateGuestAccessPassRequest
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGuestAccessPassRequest
     */
    resourceType: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateGuestAccessPassRequest
     */
    resourceId: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateGuestAccessPassRequest
     */
    expiresAt: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGuestAccessPassRequest
     */
    companyGuid: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGuestAccessPassRequest
     */
    teamGuid: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGuestAccessPassRequest
     */
    clientTrackingEmail: string;
}

/**
 * Check if a given object implements the UpdateGuestAccessPassRequest interface.
 */
export function instanceOfUpdateGuestAccessPassRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "resourceType" in value;
    isInstance = isInstance && "resourceId" in value;
    isInstance = isInstance && "expiresAt" in value;
    isInstance = isInstance && "companyGuid" in value;
    isInstance = isInstance && "teamGuid" in value;
    isInstance = isInstance && "clientTrackingEmail" in value;

    return isInstance;
}

export function UpdateGuestAccessPassRequestFromJSON(json: any): UpdateGuestAccessPassRequest {
    return UpdateGuestAccessPassRequestFromJSONTyped(json, false);
}

export function UpdateGuestAccessPassRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateGuestAccessPassRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'resourceType': json['resource_type'],
        'resourceId': json['resource_id'],
        'expiresAt': json['expires_at'],
        'companyGuid': json['company_guid'],
        'teamGuid': json['team_guid'],
        'clientTrackingEmail': json['client_tracking_email'],
    };
}

export function UpdateGuestAccessPassRequestToJSON(value?: UpdateGuestAccessPassRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'resource_type': value.resourceType,
        'resource_id': value.resourceId,
        'expires_at': value.expiresAt,
        'company_guid': value.companyGuid,
        'team_guid': value.teamGuid,
        'client_tracking_email': value.clientTrackingEmail,
    };
}

