/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupplierSignalBuyersRequestFilters
 */
export interface SupplierSignalBuyersRequestFilters {
    /**
     * 
     * @type {boolean}
     * @memberof SupplierSignalBuyersRequestFilters
     */
    showKeyAccountsOnly: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierSignalBuyersRequestFilters
     */
    keywordSignals: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SupplierSignalBuyersRequestFilters
     */
    buyerName: string;
}

/**
 * Check if a given object implements the SupplierSignalBuyersRequestFilters interface.
 */
export function instanceOfSupplierSignalBuyersRequestFilters(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "showKeyAccountsOnly" in value;
    isInstance = isInstance && "keywordSignals" in value;
    isInstance = isInstance && "buyerName" in value;

    return isInstance;
}

export function SupplierSignalBuyersRequestFiltersFromJSON(json: any): SupplierSignalBuyersRequestFilters {
    return SupplierSignalBuyersRequestFiltersFromJSONTyped(json, false);
}

export function SupplierSignalBuyersRequestFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierSignalBuyersRequestFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'showKeyAccountsOnly': json['show_key_accounts_only'],
        'keywordSignals': json['keyword_signals'],
        'buyerName': json['buyer_name'],
    };
}

export function SupplierSignalBuyersRequestFiltersToJSON(value?: SupplierSignalBuyersRequestFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'show_key_accounts_only': value.showKeyAccountsOnly,
        'keyword_signals': value.keywordSignals,
        'buyer_name': value.buyerName,
    };
}

