/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ForwardGeocodeResponse
 */
export interface ForwardGeocodeResponse {
    /**
     * 
     * @type {string}
     * @memberof ForwardGeocodeResponse
     */
    lat: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardGeocodeResponse
     */
    lng: string;
    /**
     * 
     * @type {string}
     * @memberof ForwardGeocodeResponse
     */
    response: string;
}

/**
 * Check if a given object implements the ForwardGeocodeResponse interface.
 */
export function instanceOfForwardGeocodeResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lat" in value;
    isInstance = isInstance && "lng" in value;
    isInstance = isInstance && "response" in value;

    return isInstance;
}

export function ForwardGeocodeResponseFromJSON(json: any): ForwardGeocodeResponse {
    return ForwardGeocodeResponseFromJSONTyped(json, false);
}

export function ForwardGeocodeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ForwardGeocodeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lat': json['lat'],
        'lng': json['lng'],
        'response': json['response'],
    };
}

export function ForwardGeocodeResponseToJSON(value?: ForwardGeocodeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lat': value.lat,
        'lng': value.lng,
        'response': value.response,
    };
}

