import * as React from "react";
import classnames from "classnames";

import PaywallPopover from "components/paywall/PaywallPopover";
import { convertDataTypeToFeatureType, FeatureType } from "components/paywall/paywallUtils";
import { ProHelperDataTypes } from "lib/providers/ProHelper";
import { useSubscription } from "lib/providers/Subscription";
import { useTracking } from "lib/tracking";

import css from "./RedactedWrapper.module.scss";

const ALPHABET = "abcdefghijklmnopqrstuvwxyz";

// This function returns a string with random characters in the same length of the inpput string
function redactify(str: string) {
  let result = "";
  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    if (char >= "a" && char <= "z") {
      result += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
    } else if (char >= "A" && char <= "Z") {
      result += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length)).toLocaleUpperCase();
    } else if (char >= "0" && char <= "9") {
      result += Math.floor(Math.random() * 10);
    } else result += char;
  }
  return result;
}

export function RedactedSpan({
  textToRedact,
  className,
}: {
  textToRedact: string;
  className?: string;
}): JSX.Element {
  const redactedText = React.useMemo(() => redactify(textToRedact), [textToRedact]);
  return <span className={classnames(css.blurred, className)}>{redactedText}</span>;
}

export function RedactedLink({ textToRedact }: { textToRedact: string }): JSX.Element {
  return <RedactedSpan className={css.link} textToRedact={textToRedact} />;
}

function RedactedWrapper({
  children,
  redactContent,
  featureType,
  requiredDataType,
  showModalOnClick = true,
  contextSource,
  redactedForGuestUser = true,
  video = false,
}: React.PropsWithChildren<{
  redactContent: React.ReactNode;
  requiredDataType?: ProHelperDataTypes; // TODO: Eventually remove all instances of ProHelperDataTypes and replace with FeatureType (or merge the two types into one)?
  featureType?: FeatureType;
  showModalOnClick?: boolean;
  contextSource?: string;
  redactedForGuestUser?: boolean;
  video?: boolean;
}>): JSX.Element {
  const subscription = useSubscription();

  const { trackingData } = useTracking();
  const showRedactedText = React.useMemo(
    () =>
      requiredDataType
        ? !subscription.hasDataTypes(requiredDataType) || (redactedForGuestUser && window.guestUser)
        : false,
    [subscription, requiredDataType, redactedForGuestUser],
  );

  const redactedItem = React.useMemo(() => {
    if (showRedactedText) {
      if (typeof redactContent === "string") return <RedactedSpan textToRedact={redactContent} />;
      else return redactContent;
    } else {
      undefined;
    }
  }, [showRedactedText, redactContent]);

  if (!showRedactedText) {
    return <>{children}</>;
  } else {
    return (
      <PaywallPopover
        featureType={featureType ?? convertDataTypeToFeatureType(requiredDataType)}
        showModalOnClick={showModalOnClick}
        contextSource={contextSource}
        trackingData={trackingData}
        video={video}
      >
        {/* We have to wrap this because otherwise the child doesn't receive Tooltip's hover event */}
        <span>{redactedItem}</span>
      </PaywallPopover>
    );
  }
}

export default RedactedWrapper;
