/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    adminNotes?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Report
     */
    publishedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    pdfUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    coverImageUrl?: string | null;
}

/**
 * Check if a given object implements the Report interface.
 */
export function instanceOfReport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function ReportFromJSON(json: any): Report {
    return ReportFromJSONTyped(json, false);
}

export function ReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): Report {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'adminNotes': !exists(json, 'admin_notes') ? undefined : json['admin_notes'],
        'publishedAt': !exists(json, 'published_at') ? undefined : (json['published_at'] === null ? null : new Date(json['published_at'])),
        'pdfUrl': !exists(json, 'pdf_url') ? undefined : json['pdf_url'],
        'coverImageUrl': !exists(json, 'cover_image_url') ? undefined : json['cover_image_url'],
    };
}

export function ReportToJSON(value?: Report | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'admin_notes': value.adminNotes,
        'published_at': value.publishedAt === undefined ? undefined : (value.publishedAt === null ? null : value.publishedAt.toISOString()),
        'pdf_url': value.pdfUrl,
        'cover_image_url': value.coverImageUrl,
    };
}

