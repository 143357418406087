/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Account } from './Account';
import {
    AccountFromJSON,
    AccountFromJSONTyped,
    AccountToJSON,
} from './Account';

/**
 * 
 * @export
 * @interface SearchAccountsResponse
 */
export interface SearchAccountsResponse {
    /**
     * 
     * @type {Array<Account>}
     * @memberof SearchAccountsResponse
     */
    accounts: Array<Account>;
    /**
     * 
     * @type {boolean}
     * @memberof SearchAccountsResponse
     */
    moreExist: boolean;
}

/**
 * Check if a given object implements the SearchAccountsResponse interface.
 */
export function instanceOfSearchAccountsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accounts" in value;
    isInstance = isInstance && "moreExist" in value;

    return isInstance;
}

export function SearchAccountsResponseFromJSON(json: any): SearchAccountsResponse {
    return SearchAccountsResponseFromJSONTyped(json, false);
}

export function SearchAccountsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchAccountsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accounts': ((json['accounts'] as Array<any>).map(AccountFromJSON)),
        'moreExist': json['moreExist'],
    };
}

export function SearchAccountsResponseToJSON(value?: SearchAccountsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accounts': ((value.accounts as Array<any>).map(AccountToJSON)),
        'moreExist': value.moreExist,
    };
}

