/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InspectRecordSearchRequestByEntriesApiParams
 */
export interface InspectRecordSearchRequestByEntriesApiParams {
    /**
     * 
     * @type {number}
     * @memberof InspectRecordSearchRequestByEntriesApiParams
     */
    recordListId: number;
    /**
     * 
     * @type {string}
     * @memberof InspectRecordSearchRequestByEntriesApiParams
     */
    userGuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InspectRecordSearchRequestByEntriesApiParams
     */
    apiParams: string;
}

/**
 * Check if a given object implements the InspectRecordSearchRequestByEntriesApiParams interface.
 */
export function instanceOfInspectRecordSearchRequestByEntriesApiParams(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recordListId" in value;
    isInstance = isInstance && "apiParams" in value;

    return isInstance;
}

export function InspectRecordSearchRequestByEntriesApiParamsFromJSON(json: any): InspectRecordSearchRequestByEntriesApiParams {
    return InspectRecordSearchRequestByEntriesApiParamsFromJSONTyped(json, false);
}

export function InspectRecordSearchRequestByEntriesApiParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectRecordSearchRequestByEntriesApiParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordListId': json['record_list_id'],
        'userGuid': !exists(json, 'user_guid') ? undefined : json['user_guid'],
        'apiParams': json['api_params'],
    };
}

export function InspectRecordSearchRequestByEntriesApiParamsToJSON(value?: InspectRecordSearchRequestByEntriesApiParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'record_list_id': value.recordListId,
        'user_guid': value.userGuid,
        'api_params': value.apiParams,
    };
}

