/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ScrapeLinkMetadataResponseLinksMetadata } from './ScrapeLinkMetadataResponseLinksMetadata';
import {
    ScrapeLinkMetadataResponseLinksMetadataFromJSON,
    ScrapeLinkMetadataResponseLinksMetadataFromJSONTyped,
    ScrapeLinkMetadataResponseLinksMetadataToJSON,
} from './ScrapeLinkMetadataResponseLinksMetadata';

/**
 * 
 * @export
 * @interface ScrapeLinkMetadataResponse
 */
export interface ScrapeLinkMetadataResponse {
    /**
     * 
     * @type {ScrapeLinkMetadataResponseLinksMetadata}
     * @memberof ScrapeLinkMetadataResponse
     */
    linksMetadata?: ScrapeLinkMetadataResponseLinksMetadata;
    /**
     * 
     * @type {string}
     * @memberof ScrapeLinkMetadataResponse
     */
    error?: string;
}

/**
 * Check if a given object implements the ScrapeLinkMetadataResponse interface.
 */
export function instanceOfScrapeLinkMetadataResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ScrapeLinkMetadataResponseFromJSON(json: any): ScrapeLinkMetadataResponse {
    return ScrapeLinkMetadataResponseFromJSONTyped(json, false);
}

export function ScrapeLinkMetadataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScrapeLinkMetadataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'linksMetadata': !exists(json, 'links_metadata') ? undefined : ScrapeLinkMetadataResponseLinksMetadataFromJSON(json['links_metadata']),
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function ScrapeLinkMetadataResponseToJSON(value?: ScrapeLinkMetadataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'links_metadata': ScrapeLinkMetadataResponseLinksMetadataToJSON(value.linksMetadata),
        'error': value.error,
    };
}

