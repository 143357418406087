import React, { SVGProps } from "react";

// source: https://fontawesome.com/icons/share
// licensed under CC BY 4.0 https://creativecommons.org/licenses/by/4.0/
// modification: outline only (hollow)

/* USAGE:
 *
 * import { Icon } from "antd5";
 * ...
 * <Icon component={ShareIcon} />
 *
 **/

export default function Salesforce({ color = "#00A1E0", fill }: SVGProps<SVGElement>) {
  return (
    <svg
      version="1.1"
      height="14"
      width="14"
      viewBox="0 0 273 191"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Salesforce.com logo</title>
      <desc>A cloud computing company based in San Francisco, California, United States</desc>
      <defs>
        <path id="a" d="m0.06 0.5h272v190h-272z" />
      </defs>
      <g fillRule="evenodd">
        <path
          d="m113 21.3c8.78-9.14 21-14.8 34.5-14.8 18 0 33.6 10 42 24.9a58 58 0 0 1 23.7-5.05c32.4 0 58.7 26.5 58.7 59.2s-26.3 59.2-58.7 59.2c-3.96 0-7.82-0.398-11.6-1.15-7.35 13.1-21.4 22-37.4 22a42.7 42.7 0 0 1-18.8-4.32c-7.45 17.5-24.8 29.8-45 29.8-21.1 0-39-13.3-45.9-32a45.1 45.1 0 0 1-9.34 0.972c-25.1 0-45.4-20.6-45.4-45.9 0-17 9.14-31.8 22.7-39.8a52.6 52.6 0 0 1-4.35-21c0-29.2 23.7-52.8 52.9-52.8 17.1 0 32.4 8.15 42 20.8"
          fill={fill || color}
          mask="url(#b)"
        />
      </g>
    </svg>
  );
}
