/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactsSectionGeneratedContactsInner
 */
export interface ContactsSectionGeneratedContactsInner {
    /**
     * 
     * @type {string}
     * @memberof ContactsSectionGeneratedContactsInner
     */
    stotlesCats: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsSectionGeneratedContactsInner
     */
    jobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsSectionGeneratedContactsInner
     */
    stotlesSeniority: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsSectionGeneratedContactsInner
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsSectionGeneratedContactsInner
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsSectionGeneratedContactsInner
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactsSectionGeneratedContactsInner
     */
    lastName?: string;
}

/**
 * Check if a given object implements the ContactsSectionGeneratedContactsInner interface.
 */
export function instanceOfContactsSectionGeneratedContactsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stotlesCats" in value;
    isInstance = isInstance && "jobTitle" in value;
    isInstance = isInstance && "stotlesSeniority" in value;

    return isInstance;
}

export function ContactsSectionGeneratedContactsInnerFromJSON(json: any): ContactsSectionGeneratedContactsInner {
    return ContactsSectionGeneratedContactsInnerFromJSONTyped(json, false);
}

export function ContactsSectionGeneratedContactsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactsSectionGeneratedContactsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stotlesCats': json['stotlesCats'],
        'jobTitle': json['jobTitle'],
        'stotlesSeniority': json['stotlesSeniority'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
    };
}

export function ContactsSectionGeneratedContactsInnerToJSON(value?: ContactsSectionGeneratedContactsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stotlesCats': value.stotlesCats,
        'jobTitle': value.jobTitle,
        'stotlesSeniority': value.stotlesSeniority,
        'firstName': value.firstName,
        'phone': value.phone,
        'email': value.email,
        'lastName': value.lastName,
    };
}

