/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DescribeEntityRequest
 */
export interface DescribeEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof DescribeEntityRequest
     */
    entityName: string;
}

/**
 * Check if a given object implements the DescribeEntityRequest interface.
 */
export function instanceOfDescribeEntityRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entityName" in value;

    return isInstance;
}

export function DescribeEntityRequestFromJSON(json: any): DescribeEntityRequest {
    return DescribeEntityRequestFromJSONTyped(json, false);
}

export function DescribeEntityRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DescribeEntityRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityName': json['entityName'],
    };
}

export function DescribeEntityRequestToJSON(value?: DescribeEntityRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityName': value.entityName,
    };
}

