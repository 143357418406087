import * as React from "react";
import { Spin } from "antd5";

import { useComments } from "lib/hooks/api/notices/comments/useComments";
import CommentInput from "./CommentInput";
import { CommentSection } from "./CommentSection";

import css from "./CommentsContainer.module.scss";

type Props = {
  procurementStageId: string;
  recordGuid: string;
  showHeader?: boolean;
  className?: string;
  containerClassName?: string;
};

export function CommentsContainer({
  showHeader,
  procurementStageId,
  recordGuid,
  className,
  containerClassName,
}: Props): JSX.Element {
  // TODO: Replace this with the comments already fetched in useNotices
  const { data: comments, isLoading } = useComments(procurementStageId);

  if (isLoading) {
    return <Spin />;
  }

  return (
    <div className={containerClassName || css.container}>
      {showHeader && <h2>Comments</h2>}
      <div className={className}>
        {comments && comments.length > 0 ? (
          comments.map((c) => (
            <CommentSection
              key={c.id}
              comment={c}
              recordGuid={recordGuid}
              procurementStageId={procurementStageId}
            />
          ))
        ) : (
          <div className={css.noComments}>No comments yet.</div>
        )}
      </div>
      <CommentInput procurementStageId={procurementStageId} recordGuid={recordGuid} />
    </div>
  );
}
