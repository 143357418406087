import React from "react";
import classnames from "classnames";

import PaywallPopover from "components/paywall/PaywallPopover";
import { SHOW_ON_HOVER } from "lib/core_components/ShowOnHover";
import { NoticeDetailsResponse, ProviderTypes } from "lib/generated/integration-api";
import { useFindIntegrationProvider } from "lib/hooks/api/integrations/useFindIntegrationProvider";
import { useNoticeDetails } from "lib/hooks/api/integrations/useNoticeDetails";
import { useDialogManager } from "lib/providers/DialogManager";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { EventNames } from "lib/tracking";
import { EntityType } from "lib/types/graphQLEnums";
import { ConnectToCRMButton, SendToCRMButton, ViewInCRMButton } from "./ProviderButtons";
import { SendNoticeToCRMModal } from "./SendNoticeToCRMModal";

type Notice = {
  guid: string;
  name: string;
  value?: number;
  url: string;
  closeDate: string;
  type?: EntityType;
};

type SendNoticeToCRMButtonProps = {
  notice: Notice;
  providerType: ProviderTypes;
  className?: string;
  noticeDetails?: NoticeDetailsResponse;
};

export function SendNoticeToCRM({
  notice,
  className,
  displayInRow = false,
}: {
  notice: Notice;
  className?: string;
  displayInRow?: boolean;
}): JSX.Element | null {
  const { data: providerTypeResponse } = useFindIntegrationProvider();
  const providerType = providerTypeResponse?.providerType;
  const { data: noticeDetails } = useNoticeDetails(notice.guid, { enabled: !!providerType });

  return (
    <div className={classnames({ [SHOW_ON_HOVER]: !displayInRow || !noticeDetails?.externalUrl })}>
      {providerType ? (
        <SendNoticeToCRMButton
          notice={notice}
          providerType={providerType}
          className={className}
          noticeDetails={noticeDetails}
        />
      ) : (
        <ConnectToCRMButton />
      )}
    </div>
  );
}

function SendNoticeToCRMButton({
  notice,
  noticeDetails,
  providerType,
  className,
}: SendNoticeToCRMButtonProps) {
  const dialogManager = useDialogManager();

  const { checkSubscription, authorised: hasAwards } = useCheckSubscription("AWARDS", {
    "Context source": "CRM button",
  });

  if (notice.type === "Contract" && !hasAwards) {
    return (
      <PaywallPopover featureType="AWARDS" contextSource="CRM button">
        <SendToCRMButton
          providerType={providerType}
          onClick={(e) => {
            e?.stopPropagation();
            checkSubscription();
          }}
          className={className}
          eventName={EventNames.sendToCRMModalOpened}
        />
      </PaywallPopover>
    );
  }

  return noticeDetails?.externalUrl ? (
    <ViewInCRMButton externalUrl={noticeDetails.externalUrl} providerType={providerType} />
  ) : (
    <SendToCRMButton
      providerType={providerType}
      onClick={async () =>
        void dialogManager.openDialog(SendNoticeToCRMModal, {
          notice,
          providerType: providerType,
        })
      }
      className={className}
      loading={!noticeDetails}
      eventName={EventNames.sendToCRMModalOpened}
    />
  );
}
