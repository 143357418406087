/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateRequestSourceUrlsInner } from './UpdateRequestSourceUrlsInner';
import {
    UpdateRequestSourceUrlsInnerFromJSON,
    UpdateRequestSourceUrlsInnerFromJSONTyped,
    UpdateRequestSourceUrlsInnerToJSON,
} from './UpdateRequestSourceUrlsInner';

/**
 * 
 * @export
 * @interface UpdateRequest
 */
export interface UpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequest
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequest
     */
    publishDate: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequest
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRequest
     */
    buyers: Array<string>;
    /**
     * 
     * @type {Array<UpdateRequestSourceUrlsInner>}
     * @memberof UpdateRequest
     */
    sourceUrls: Array<UpdateRequestSourceUrlsInner>;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequest
     */
    alternativeSource: string;
}

/**
 * Check if a given object implements the UpdateRequest interface.
 */
export function instanceOfUpdateRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "publishDate" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "buyers" in value;
    isInstance = isInstance && "sourceUrls" in value;
    isInstance = isInstance && "alternativeSource" in value;

    return isInstance;
}

export function UpdateRequestFromJSON(json: any): UpdateRequest {
    return UpdateRequestFromJSONTyped(json, false);
}

export function UpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'category': json['category'],
        'publishDate': json['publishDate'],
        'description': json['description'],
        'buyers': json['buyers'],
        'sourceUrls': ((json['sourceUrls'] as Array<any>).map(UpdateRequestSourceUrlsInnerFromJSON)),
        'alternativeSource': json['alternativeSource'],
    };
}

export function UpdateRequestToJSON(value?: UpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'category': value.category,
        'publishDate': value.publishDate,
        'description': value.description,
        'buyers': value.buyers,
        'sourceUrls': ((value.sourceUrls as Array<any>).map(UpdateRequestSourceUrlsInnerToJSON)),
        'alternativeSource': value.alternativeSource,
    };
}

