import React from "react";
import Icon from "@ant-design/icons";
import { Tooltip } from "antd5";
import classNames from "classnames";

import DoubleTick from "lib/icons/DoubleTick";
import { blue600 } from "lib/themes/colors";
import { joinToEnglishList } from "lib/utils";

import css from "./SeenBy.module.scss";

export function NoticeSeenBy({
  seenByUsers,
  className,
  displayNumber,
}: {
  seenByUsers: {
    guid: string;
    firstName: string;
  }[];
  className?: string;
  displayNumber?: boolean;
}): JSX.Element | null {
  const currentUser = window.currentUser;

  if (!currentUser || seenByUsers.length === 0) {
    return null;
  }

  const userNames = seenByUsers.map((u) => {
    return u.guid === currentUser.guid ? "you" : `${u.firstName}`;
  });

  const tooltipTile = `Seen by ${joinToEnglishList(userNames)}`;

  return (
    <Tooltip title={tooltipTile}>
      <div className={className} aria-label="Notice has been seen">
        <Icon
          component={() => <DoubleTick color={displayNumber ? blue600 : undefined} />}
          className={classNames({ [css.icon]: displayNumber })}
        />
        {displayNumber && <span>{seenByUsers.length}</span>}
      </div>
    </Tooltip>
  );
}
