/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Signal } from './Signal';
import {
    SignalFromJSON,
    SignalFromJSONTyped,
    SignalToJSON,
} from './Signal';

/**
 * 
 * @export
 * @interface SupplierStat
 */
export interface SupplierStat {
    /**
     * 
     * @type {number}
     * @memberof SupplierStat
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierStat
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierStat
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SupplierStat
     */
    allActivityCount: number;
    /**
     * 
     * @type {number}
     * @memberof SupplierStat
     */
    upcomingExpiriesCount: number;
    /**
     * 
     * @type {number}
     * @memberof SupplierStat
     */
    relevantAwardsCount: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierStat
     */
    keywords?: Array<string> | null;
    /**
     * 
     * @type {Array<Signal>}
     * @memberof SupplierStat
     */
    signals: Array<Signal>;
    /**
     * 
     * @type {string}
     * @memberof SupplierStat
     */
    lastActivity: string | null;
    /**
     * 
     * @type {number}
     * @memberof SupplierStat
     */
    totalSpendAmount?: number | null;
}

/**
 * Check if a given object implements the SupplierStat interface.
 */
export function instanceOfSupplierStat(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "allActivityCount" in value;
    isInstance = isInstance && "upcomingExpiriesCount" in value;
    isInstance = isInstance && "relevantAwardsCount" in value;
    isInstance = isInstance && "signals" in value;
    isInstance = isInstance && "lastActivity" in value;

    return isInstance;
}

export function SupplierStatFromJSON(json: any): SupplierStat {
    return SupplierStatFromJSONTyped(json, false);
}

export function SupplierStatFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierStat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'guid': json['guid'],
        'name': json['name'],
        'allActivityCount': json['all_activity_count'],
        'upcomingExpiriesCount': json['upcoming_expiries_count'],
        'relevantAwardsCount': json['relevant_awards_count'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'signals': ((json['signals'] as Array<any>).map(SignalFromJSON)),
        'lastActivity': json['last_activity'],
        'totalSpendAmount': !exists(json, 'total_spend_amount') ? undefined : json['total_spend_amount'],
    };
}

export function SupplierStatToJSON(value?: SupplierStat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'guid': value.guid,
        'name': value.name,
        'all_activity_count': value.allActivityCount,
        'upcoming_expiries_count': value.upcomingExpiriesCount,
        'relevant_awards_count': value.relevantAwardsCount,
        'keywords': value.keywords,
        'signals': ((value.signals as Array<any>).map(SignalToJSON)),
        'last_activity': value.lastActivity,
        'total_spend_amount': value.totalSpendAmount,
    };
}

