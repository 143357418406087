/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordSearchConfigRecordFilterSuppliers
 */
export interface RecordSearchConfigRecordFilterSuppliers {
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilterSuppliers
     */
    guid?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RecordSearchConfigRecordFilterSuppliers
     */
    id?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilterSuppliers
     */
    countryCode?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RecordSearchConfigRecordFilterSuppliers
     */
    isSme?: boolean | null;
}

/**
 * Check if a given object implements the RecordSearchConfigRecordFilterSuppliers interface.
 */
export function instanceOfRecordSearchConfigRecordFilterSuppliers(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RecordSearchConfigRecordFilterSuppliersFromJSON(json: any): RecordSearchConfigRecordFilterSuppliers {
    return RecordSearchConfigRecordFilterSuppliersFromJSONTyped(json, false);
}

export function RecordSearchConfigRecordFilterSuppliersFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSearchConfigRecordFilterSuppliers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guid': !exists(json, 'guid') ? undefined : json['guid'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
        'isSme': !exists(json, 'is_sme') ? undefined : json['is_sme'],
    };
}

export function RecordSearchConfigRecordFilterSuppliersToJSON(value?: RecordSearchConfigRecordFilterSuppliers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guid': value.guid,
        'id': value.id,
        'country_code': value.countryCode,
        'is_sme': value.isSme,
    };
}

