import StotlesAPI, { BuyerCategory } from "../StotlesApi";

export type BuyerCategories = Map<string, BuyerCategory>;

// TODO: Make this class abstract after updating to TS 4.2
export class IBuyerDataProvider {
  getBuyerCategories(): Promise<BuyerCategories> {
    throw new Error("Not implemented");
  }
}

export class BuyerDataProvider extends IBuyerDataProvider {
  private buyerCategories: Promise<BuyerCategories> | undefined;

  constructor(private readonly api: StotlesAPI) {
    super();
  }

  async getBuyerCategories(): Promise<BuyerCategories> {
    if (this.buyerCategories) {
      return this.buyerCategories;
    }

    const fetchBuyerCategories = async () => {
      const response = await this.api.getBuyerCategories();
      const allBuyerCategories = response.buyer_categories;
      return new Map<string, BuyerCategory>(allBuyerCategories.map((cat) => [cat.id, cat]));
    };
    this.buyerCategories = fetchBuyerCategories();
    return this.buyerCategories;
  }
}
