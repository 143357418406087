/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto
 */
export interface RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto {
    /**
     * 
     * @type {Date}
     * @memberof RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto
     */
    from?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto
     */
    to?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto
     */
    relativeFrom?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto
     */
    relativeTo?: string | null;
}

/**
 * Check if a given object implements the RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto interface.
 */
export function instanceOfRecordSearchRequestDtoNullableDateFilterDtoDateFilterDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RecordSearchRequestDtoNullableDateFilterDtoDateFilterDtoFromJSON(json: any): RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto {
    return RecordSearchRequestDtoNullableDateFilterDtoDateFilterDtoFromJSONTyped(json, false);
}

export function RecordSearchRequestDtoNullableDateFilterDtoDateFilterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : (json['from'] === null ? null : new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (json['to'] === null ? null : new Date(json['to'])),
        'relativeFrom': !exists(json, 'relative_from') ? undefined : json['relative_from'],
        'relativeTo': !exists(json, 'relative_to') ? undefined : json['relative_to'],
    };
}

export function RecordSearchRequestDtoNullableDateFilterDtoDateFilterDtoToJSON(value?: RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from === undefined ? undefined : (value.from === null ? null : value.from.toISOString()),
        'to': value.to === undefined ? undefined : (value.to === null ? null : value.to.toISOString()),
        'relative_from': value.relativeFrom,
        'relative_to': value.relativeTo,
    };
}

