import { useQuery } from "@tanstack/react-query";

import { ReferenceOrgDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type Filters = {
  text?: string;
  source?: ReferenceOrgDto["source"];
  sourceIdentifier?: string[];
};

export const useReferenceOrgs = (filters: Filters) => {
  const api = useOpenApi();

  return useQuery(
    ["reference_organisations", filters],
    async () => {
      const resp = await api.searchReferenceOrgs({
        searchReferenceOrgsRequest: {
          source: filters.source,
          text: filters.text,
          sourceIdentifier: filters.sourceIdentifier,
          limit: 500,
          sortOrder: "ASC",
          sort: "name",
        },
      });

      return resp.results;
    },
    { enabled: !!filters.sourceIdentifier?.length || !!filters.text?.length },
  );
};
