import * as React from "react";
import { ExclamationCircleOutlined, FilterOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Spin, Tooltip } from "antd5";

import { SignalFiltersData } from "components/onboarding/onboardingUtils";
import {
  FeedPreviewPageSources,
  PreviewSignalFilters,
} from "components/onboarding/SharedOnboarding";
import { pluralise } from "lib/utils";
import RecordPreviewFilterDropdown from "./RecordPreviewFilterDropdown";

import css from "./RecordPreviewHeading.module.scss";

type Props = {
  totalResultLength: number;
  filteredResultLength: number;
  loading?: boolean;
  handleFilterCheck?: (filterObject?: PreviewSignalFilters) => void;
  resultsShowing?: number;
  signalFiltersData?: SignalFiltersData;
  checkedKeys: PreviewSignalFilters;
  activeFiltersCount?: number;
  dateValue?: Date;
  monthlyAverageResults: number;
  recordListCountLabel?: string;
  pageSource: FeedPreviewPageSources;
};

function RecordPreviewHeading({
  totalResultLength,
  filteredResultLength,
  loading,
  handleFilterCheck,
  resultsShowing,
  signalFiltersData,
  checkedKeys,
  activeFiltersCount,
  dateValue,
  monthlyAverageResults,
  recordListCountLabel,
  pageSource,
}: Props): JSX.Element {
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const renderCautionMessage = React.useMemo(() => {
    if (
      !loading &&
      monthlyAverageResults > 0 &&
      (monthlyAverageResults >= 210 || monthlyAverageResults <= 10)
    ) {
      const message =
        monthlyAverageResults <= 10
          ? "These are fewer results than the majority of our users tend to see in their Feed per month. Consider adding or broadening some keywords relevant to your business."
          : "These are more results than the majority of our users tend to see in their Feed per month. Consider using more specific keywords to narrow down your search and build a more relevant Feed.";
      return (
        <Tooltip title={message} trigger="hover" overlayClassName={css.cautionPopup}>
          <div className={css.cautionMessage}>
            <ExclamationCircleOutlined /> Caution
          </div>
        </Tooltip>
      );
    } else return undefined;
  }, [monthlyAverageResults, loading]);

  const filtersApplied = (activeFiltersCount && activeFiltersCount > 0) || dateValue;

  return (
    <div className={css.recordListHeading}>
      <div className={css.recordListCount}>
        {pluralise(filteredResultLength, "notice")} matched {recordListCountLabel}
      </div>
      {renderCautionMessage}
      {/* TODO: Change spinner. Irina to design where it should be*/}
      {loading && (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 22 }} spin />}
          className={css.loadingSpinner}
        />
      )}
      {handleFilterCheck && signalFiltersData ? (
        <div className={css.filterRow}>
          <div>
            <RecordPreviewFilterDropdown
              handleFilterCheck={handleFilterCheck}
              signalFiltersData={signalFiltersData}
              checkedKeys={checkedKeys}
              pageSource={pageSource}
              open={filtersOpen}
              setOpen={setFiltersOpen}
            >
              <Button
                onClick={(e) => e.preventDefault()}
                className={css.filterButton}
                icon={<FilterOutlined />}
                type={filtersOpen ? "primary" : "default"}
                ghost={filtersOpen}
              >
                Filter
                {!!activeFiltersCount && (
                  <span className={css.filterCount}>{activeFiltersCount}</span>
                )}
              </Button>
            </RecordPreviewFilterDropdown>
            {activeFiltersCount ? (
              <button className={css.clearFilter} onClick={() => handleFilterCheck()}>
                Clear all
              </button>
            ) : undefined}
          </div>
          <p className={css.currentResults}>
            {filtersApplied ? (
              <>
                Showing <b>{resultsShowing}</b> out of <b>{totalResultLength}</b> notices
              </>
            ) : (
              <>
                Showing <b>{totalResultLength}</b> notices
              </>
            )}
          </p>
        </div>
      ) : undefined}
    </div>
  );
}

export default RecordPreviewHeading;
