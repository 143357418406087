import React from "react";
import { Select } from "antd5";

import { countryCodes } from "lib/data/optionItems";

import css from "./CountryFilter.module.scss";

interface Props {
  value: string[] | undefined;
  label?: string;
  onChange: (newValue: string[]) => void;
  showLabel?: boolean;
  placeholder?: string;
  id?: string;
}

function CountryFilter({
  label,
  value,
  onChange,
  showLabel = true,
  placeholder,
  id,
}: Props): JSX.Element {
  return (
    <div className={css.countryFilter}>
      {showLabel && <span>{label || "Country"}</span>}
      <Select
        id={id}
        onChange={onChange}
        placeholder={placeholder || "Select..."}
        value={value || []}
        filterOption={(input, option) =>
          (option?.label || "").toLowerCase().startsWith(input.toLowerCase())
        }
        mode="multiple"
        options={countryCodes.map((countryObj) => ({
          label: countryObj.name,
          value: countryObj.code,
          title: countryObj.name,
        }))}
      />
    </div>
  );
}

export default CountryFilter;
