/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AggregationResultValue
 */
export interface AggregationResultValue {
    /**
     * 
     * @type {number}
     * @memberof AggregationResultValue
     */
    numeric: number;
}

/**
 * Check if a given object implements the AggregationResultValue interface.
 */
export function instanceOfAggregationResultValue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "numeric" in value;

    return isInstance;
}

export function AggregationResultValueFromJSON(json: any): AggregationResultValue {
    return AggregationResultValueFromJSONTyped(json, false);
}

export function AggregationResultValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): AggregationResultValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numeric': json['numeric'],
    };
}

export function AggregationResultValueToJSON(value?: AggregationResultValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numeric': value.numeric,
    };
}

