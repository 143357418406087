import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { SwitchTeamRequest } from "lib/generated/admin-api";
import { useAdminApi } from "lib/stotlesAdminApiContext";

type SwitchTeamParams = {
  userId: string;
  fields: SwitchTeamRequest;
};

export function useSwitchTeam(
  options?: UseMutationOptions<void, unknown, SwitchTeamParams, unknown>,
) {
  const { api } = useAdminApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ fields }: SwitchTeamParams) =>
      api.switchTeam({
        switchTeamRequest: fields,
      }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(["userDetails", variables.userId]);
        void queryClient.invalidateQueries(["userDetails", variables.fields.userGuid]);
        void queryClient.invalidateQueries(["teams", "currentTeamInfo"]);
        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
    },
  );
}
