/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLeadSubscriptionRequest
 */
export interface UpdateLeadSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateLeadSubscriptionRequest
     */
    frequency: UpdateLeadSubscriptionRequestFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeadSubscriptionRequest
     */
    webhookUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeadSubscriptionRequest
     */
    name?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLeadSubscriptionRequest
     */
    viewIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLeadSubscriptionRequest
     */
    listIds?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeadSubscriptionRequest
     */
    disabled?: boolean | null;
}


/**
 * @export
 */
export const UpdateLeadSubscriptionRequestFrequencyEnum = {
    Daily: 'DAILY',
    Weekly: 'WEEKLY'
} as const;
export type UpdateLeadSubscriptionRequestFrequencyEnum = typeof UpdateLeadSubscriptionRequestFrequencyEnum[keyof typeof UpdateLeadSubscriptionRequestFrequencyEnum];


/**
 * Check if a given object implements the UpdateLeadSubscriptionRequest interface.
 */
export function instanceOfUpdateLeadSubscriptionRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "frequency" in value;
    isInstance = isInstance && "viewIds" in value;

    return isInstance;
}

export function UpdateLeadSubscriptionRequestFromJSON(json: any): UpdateLeadSubscriptionRequest {
    return UpdateLeadSubscriptionRequestFromJSONTyped(json, false);
}

export function UpdateLeadSubscriptionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeadSubscriptionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frequency': json['frequency'],
        'webhookUrl': !exists(json, 'webhook_url') ? undefined : json['webhook_url'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'viewIds': json['view_ids'],
        'listIds': !exists(json, 'list_ids') ? undefined : json['list_ids'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
    };
}

export function UpdateLeadSubscriptionRequestToJSON(value?: UpdateLeadSubscriptionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'frequency': value.frequency,
        'webhook_url': value.webhookUrl,
        'name': value.name,
        'view_ids': value.viewIds,
        'list_ids': value.listIds,
        'disabled': value.disabled,
    };
}

