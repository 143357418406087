/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeedbackRequest
 */
export interface FeedbackRequest {
    /**
     * 
     * @type {number}
     * @memberof FeedbackRequest
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof FeedbackRequest
     */
    feedback: string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackRequest
     */
    tourIdentifier: FeedbackRequestTourIdentifierEnum;
}


/**
 * @export
 */
export const FeedbackRequestTourIdentifierEnum = {
    CrmContactSendIntro: 'crm_contact_send_intro',
    CrmContactSendFeedback: 'crm_contact_send_feedback',
    OutreachBuilderSendFeedback: 'outreach_builder_send_feedback',
    OutreachBuilderEmailSetup: 'outreach_builder_email_setup',
    NewQualificationIntro: 'new_qualification_intro',
    PreEngageQualificationIntro: 'pre_engage_qualification_intro',
    ContactsQualificationIntro: 'contacts_qualification_intro',
    RelatedContactsIntro: 'related_contacts_intro'
} as const;
export type FeedbackRequestTourIdentifierEnum = typeof FeedbackRequestTourIdentifierEnum[keyof typeof FeedbackRequestTourIdentifierEnum];


/**
 * Check if a given object implements the FeedbackRequest interface.
 */
export function instanceOfFeedbackRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "feedback" in value;
    isInstance = isInstance && "tourIdentifier" in value;

    return isInstance;
}

export function FeedbackRequestFromJSON(json: any): FeedbackRequest {
    return FeedbackRequestFromJSONTyped(json, false);
}

export function FeedbackRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedbackRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['user_id'],
        'feedback': json['feedback'],
        'tourIdentifier': json['tour_identifier'],
    };
}

export function FeedbackRequestToJSON(value?: FeedbackRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_id': value.userId,
        'feedback': value.feedback,
        'tour_identifier': value.tourIdentifier,
    };
}

