import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { CommentDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";
import { updateCachedNotices, updateCachedRecord } from "../utils";

type Params = {
  id: string;
  recordGuid: string;
  procurementStageId: string;
};

export function useDeleteComment(
  options?: UseMutationOptions<CommentDto, unknown, Params, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  const { logEvent } = useTracking();
  return useMutation(({ id }: Params) => api.deleteComment({ id }), {
    ...options,
    onSuccess: async (data, vars, context) => {
      const procurementStageId = vars.procurementStageId;

      logEvent(EventNames.commentActioned, {
        "Action type": "deleted",
        "Comment ID": data.id,
      });

      await queryClient.invalidateQueries(["comments", procurementStageId]);

      updateCachedRecord(queryClient, vars.recordGuid, (r) => ({
        ...r,
        commentCount: r.commentCount ? r.commentCount - 1 : 0,
      }));

      updateCachedNotices(queryClient, vars.recordGuid, (r) => ({
        ...r,
        commentCount: r.comments?.length ? r.comments.length + 1 : 1,
      }));

      options?.onSuccess?.(data, vars, context);
    },
  });
}
