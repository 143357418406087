/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegionSearchDto
 */
export interface RegionSearchDto {
    /**
     * 
     * @type {string}
     * @memberof RegionSearchDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof RegionSearchDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RegionSearchDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof RegionSearchDto
     */
    countryCode: string;
    /**
     * 
     * @type {number}
     * @memberof RegionSearchDto
     */
    sortOrder: number;
}

/**
 * Check if a given object implements the RegionSearchDto interface.
 */
export function instanceOfRegionSearchDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "sortOrder" in value;

    return isInstance;
}

export function RegionSearchDtoFromJSON(json: any): RegionSearchDto {
    return RegionSearchDtoFromJSONTyped(json, false);
}

export function RegionSearchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegionSearchDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'countryCode': json['country_code'],
        'sortOrder': json['sort_order'],
    };
}

export function RegionSearchDtoToJSON(value?: RegionSearchDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'country_code': value.countryCode,
        'sort_order': value.sortOrder,
    };
}

