import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { GenerateOutreachRequest } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

export function useGenerateOutreach(
  options?: UseMutationOptions<unknown, unknown, GenerateOutreachRequest, unknown>,
) {
  const api = useOpenApi();
  return useMutation((data: GenerateOutreachRequest) => {
    return api.generateOutreach({ generateOutreachRequest: data });
  }, options);
}
