import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { SearchRegionsResponse } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "./utils";

export function useSearchRegions(
  options?: UseQueryOptions<SearchRegionsResponse, unknown, SearchRegionsResponse, string[]>,
) {
  const api = useOpenApi();
  return useQuery(
    ["regions"],
    async () => {
      return await api.searchRegions();
    },
    { ...REACT_QUERY_OPTIONS_NEVER_REFETCH, ...options },
  );
}
