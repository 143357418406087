/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupplierSearchRequestSignalFilter
 */
export interface SupplierSearchRequestSignalFilter {
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierSearchRequestSignalFilter
     */
    categories: Array<SupplierSearchRequestSignalFilterCategoriesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierSearchRequestSignalFilter
     */
    ids: Array<string> | null;
}


/**
 * @export
 */
export const SupplierSearchRequestSignalFilterCategoriesEnum = {
    Competitors: 'Competitors',
    Partners: 'Partners',
    Keywords: 'Keywords',
    CpvCodes: 'CPV codes'
} as const;
export type SupplierSearchRequestSignalFilterCategoriesEnum = typeof SupplierSearchRequestSignalFilterCategoriesEnum[keyof typeof SupplierSearchRequestSignalFilterCategoriesEnum];


/**
 * Check if a given object implements the SupplierSearchRequestSignalFilter interface.
 */
export function instanceOfSupplierSearchRequestSignalFilter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "ids" in value;

    return isInstance;
}

export function SupplierSearchRequestSignalFilterFromJSON(json: any): SupplierSearchRequestSignalFilter {
    return SupplierSearchRequestSignalFilterFromJSONTyped(json, false);
}

export function SupplierSearchRequestSignalFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierSearchRequestSignalFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categories': json['categories'],
        'ids': json['ids'],
    };
}

export function SupplierSearchRequestSignalFilterToJSON(value?: SupplierSearchRequestSignalFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categories': value.categories,
        'ids': value.ids,
    };
}

