/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuedQueryHighlightingRequest
 */
export interface QuedQueryHighlightingRequest {
    /**
     * 
     * @type {string}
     * @memberof QuedQueryHighlightingRequest
     */
    queryJson: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuedQueryHighlightingRequest
     */
    useElasticsearch?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuedQueryHighlightingRequest
     */
    recordGuids: Array<string>;
}

/**
 * Check if a given object implements the QuedQueryHighlightingRequest interface.
 */
export function instanceOfQuedQueryHighlightingRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "queryJson" in value;
    isInstance = isInstance && "recordGuids" in value;

    return isInstance;
}

export function QuedQueryHighlightingRequestFromJSON(json: any): QuedQueryHighlightingRequest {
    return QuedQueryHighlightingRequestFromJSONTyped(json, false);
}

export function QuedQueryHighlightingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): QuedQueryHighlightingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'queryJson': json['query_json'],
        'useElasticsearch': !exists(json, 'use_elasticsearch') ? undefined : json['use_elasticsearch'],
        'recordGuids': json['record_guids'],
    };
}

export function QuedQueryHighlightingRequestToJSON(value?: QuedQueryHighlightingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'query_json': value.queryJson,
        'use_elasticsearch': value.useElasticsearch,
        'record_guids': value.recordGuids,
    };
}

