/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DecisionType } from './DecisionType';
import {
    DecisionTypeFromJSON,
    DecisionTypeFromJSONTyped,
    DecisionTypeToJSON,
} from './DecisionType';

/**
 * 
 * @export
 * @interface SubmittedDecision
 */
export interface SubmittedDecision {
    /**
     * 
     * @type {string}
     * @memberof SubmittedDecision
     */
    candidateOrganisationId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmittedDecision
     */
    candidateBuyerGuid?: string;
    /**
     * 
     * @type {DecisionType}
     * @memberof SubmittedDecision
     */
    decisionData: DecisionType;
}

/**
 * Check if a given object implements the SubmittedDecision interface.
 */
export function instanceOfSubmittedDecision(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "decisionData" in value;

    return isInstance;
}

export function SubmittedDecisionFromJSON(json: any): SubmittedDecision {
    return SubmittedDecisionFromJSONTyped(json, false);
}

export function SubmittedDecisionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmittedDecision {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'candidateOrganisationId': !exists(json, 'candidate_organisation_id') ? undefined : json['candidate_organisation_id'],
        'candidateBuyerGuid': !exists(json, 'candidate_buyer_guid') ? undefined : json['candidate_buyer_guid'],
        'decisionData': DecisionTypeFromJSON(json['decision_data']),
    };
}

export function SubmittedDecisionToJSON(value?: SubmittedDecision | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'candidate_organisation_id': value.candidateOrganisationId,
        'candidate_buyer_guid': value.candidateBuyerGuid,
        'decision_data': DecisionTypeToJSON(value.decisionData),
    };
}

