import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { SubmitShareViaEmailFormParams } from "../../../StotlesApi";
import { useStotlesApi } from "../../../stotlesApiContext";

export function useShareNotice(
  options?: UseMutationOptions<
    Record<string, never>,
    unknown,
    SubmitShareViaEmailFormParams,
    unknown
  >,
) {
  const api = useStotlesApi();
  return useMutation(
    (values: SubmitShareViaEmailFormParams) => api.submitShareViaEmailForm(values),
    options,
  );
}
