/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommentProcurementStage
 */
export interface CommentProcurementStage {
    /**
     * 
     * @type {string}
     * @memberof CommentProcurementStage
     */
    actorName: string;
    /**
     * 
     * @type {string}
     * @memberof CommentProcurementStage
     */
    recordTitle: string;
    /**
     * 
     * @type {string}
     * @memberof CommentProcurementStage
     */
    commentText: string;
    /**
     * 
     * @type {Date}
     * @memberof CommentProcurementStage
     */
    deletedAt: Date | null;
}

/**
 * Check if a given object implements the CommentProcurementStage interface.
 */
export function instanceOfCommentProcurementStage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "actorName" in value;
    isInstance = isInstance && "recordTitle" in value;
    isInstance = isInstance && "commentText" in value;
    isInstance = isInstance && "deletedAt" in value;

    return isInstance;
}

export function CommentProcurementStageFromJSON(json: any): CommentProcurementStage {
    return CommentProcurementStageFromJSONTyped(json, false);
}

export function CommentProcurementStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentProcurementStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actorName': json['actor_name'],
        'recordTitle': json['record_title'],
        'commentText': json['comment_text'],
        'deletedAt': (json['deleted_at'] === null ? null : new Date(json['deleted_at'])),
    };
}

export function CommentProcurementStageToJSON(value?: CommentProcurementStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor_name': value.actorName,
        'record_title': value.recordTitle,
        'comment_text': value.commentText,
        'deleted_at': (value.deletedAt === null ? null : value.deletedAt.toISOString()),
    };
}

