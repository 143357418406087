/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenerateOutreachRequest
 */
export interface GenerateOutreachRequest {
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachRequest
     */
    intent: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GenerateOutreachRequest
     */
    tone: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachRequest
     */
    length: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachRequest
     */
    companyInformation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachRequest
     */
    senderPersonalisation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachRequest
     */
    buyerName: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachRequest
     */
    contactName: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateOutreachRequest
     */
    contactJobTitle: string;
    /**
     * 
     * @type {number}
     * @memberof GenerateOutreachRequest
     */
    numberOfResponses: number;
}

/**
 * Check if a given object implements the GenerateOutreachRequest interface.
 */
export function instanceOfGenerateOutreachRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "intent" in value;
    isInstance = isInstance && "tone" in value;
    isInstance = isInstance && "length" in value;
    isInstance = isInstance && "buyerName" in value;
    isInstance = isInstance && "contactName" in value;
    isInstance = isInstance && "contactJobTitle" in value;
    isInstance = isInstance && "numberOfResponses" in value;

    return isInstance;
}

export function GenerateOutreachRequestFromJSON(json: any): GenerateOutreachRequest {
    return GenerateOutreachRequestFromJSONTyped(json, false);
}

export function GenerateOutreachRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateOutreachRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'intent': json['intent'],
        'tone': json['tone'],
        'length': json['length'],
        'companyInformation': !exists(json, 'company_information') ? undefined : json['company_information'],
        'senderPersonalisation': !exists(json, 'sender_personalisation') ? undefined : json['sender_personalisation'],
        'buyerName': json['buyer_name'],
        'contactName': json['contact_name'],
        'contactJobTitle': json['contact_job_title'],
        'numberOfResponses': json['number_of_responses'],
    };
}

export function GenerateOutreachRequestToJSON(value?: GenerateOutreachRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'intent': value.intent,
        'tone': value.tone,
        'length': value.length,
        'company_information': value.companyInformation,
        'sender_personalisation': value.senderPersonalisation,
        'buyer_name': value.buyerName,
        'contact_name': value.contactName,
        'contact_job_title': value.contactJobTitle,
        'number_of_responses': value.numberOfResponses,
    };
}

