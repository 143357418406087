import React, { useCallback } from "react";
import { CaretDownFilled, LoadingOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd5";

import BookmarkIcon from "components/actions/Bookmark";
import { SHOW_ON_HOVER } from "lib/core_components/ShowOnHover";
import { useIsBuyerInList } from "lib/hooks/api/buyer_lists/useBuyerLists";
import { BuyerContextSource } from "lib/hooks/api/buyer_lists/useCreateBuyerList";
import { EventNames, useTracking } from "lib/tracking";
import SaveBuyerPopover from "./SaveBuyerPopover";

import css from "./SaveBuyer.module.scss";

type Props = {
  buyerGuid: string;
  buyerName: string;
  triggerType?: "bookmark" | "button";
  saveType?: "bookmark" | "button";
  contextSource: BuyerContextSource;
};

function SaveBuyer({
  buyerGuid,
  buyerName,
  triggerType = "bookmark",
  saveType = "button",
  contextSource,
}: Props) {
  const {
    isSaved: { isSaved, savedListNames },
    isLoading,
  } = useIsBuyerInList(buyerGuid);

  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const tracking = useTracking();

  const buyerListTracking = useCallback(
    (buyerGuids: string[], buyerListName: string, op: "add" | "remove") => {
      tracking.logEvent(op === "add" ? EventNames.buyerSaved : EventNames.buyerRemoved, {
        "Context source": contextSource,
        "Buyer GUID": buyerGuids[0],
        "Buyer name": buyerName,
        "Buyer list name": buyerListName,
      });
    },
    [buyerName, contextSource, tracking],
  );

  return (
    <SaveBuyerPopover
      buyerGuids={[buyerGuid]}
      onBuyerListOp={buyerListTracking}
      contextSource={contextSource}
      isPopoverOpen={isPopoverOpen}
      onOpenChange={setIsPopoverOpen}
    >
      <div>
        {isSaved ? (
          <Tooltip title={`Saved to ${savedListNames.join(", ")}`}>
            {/*tooltip doesn't work without this div */}
            <div>
              {triggerType === "bookmark" ? (
                <>{isLoading ? <LoadingOutlined /> : <BookmarkIcon colour="blue" filled />}</>
              ) : (
                <Button type="default" className={css.saveBuyerButton} loading={isLoading}>
                  <BookmarkIcon colour="blue" filled /> Saved
                </Button>
              )}
            </div>
          </Tooltip>
        ) : (
          <span className={isPopoverOpen ? undefined : SHOW_ON_HOVER}>
            <Tooltip title="Save to list">
              {saveType === "bookmark" ? (
                <Button type="default" className={css.bookmarkButton} loading={isLoading}>
                  <BookmarkIcon colour="blue" />
                  <CaretDownFilled className={css.caretDown} />
                </Button>
              ) : (
                <Button type="default" className={css.saveBuyerButton} loading={isLoading}>
                  <BookmarkIcon colour="grey" /> Save buyer
                </Button>
              )}
            </Tooltip>
          </span>
        )}
      </div>
    </SaveBuyerPopover>
  );
}

export default SaveBuyer;
