import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { assertCurrentUser } from "lib/currentUser";
import { SimpleUserDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "./utils";

export function useUsers(
  companyGuid?: string,
  options?: UseQueryOptions<SimpleUserDto[], unknown, SimpleUserDto[], string[]>,
) {
  const api = useOpenApi();
  const currentUser = assertCurrentUser();
  return useQuery(
    ["users", companyGuid || currentUser.company.guid],
    async () => {
      const { users } = await api.getUsers({ companyGuid: currentUser.company.guid });
      return users;
    },
    { ...REACT_QUERY_OPTIONS_NEVER_REFETCH, ...options },
  );
}
