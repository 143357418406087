/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateBuyerListOwnerRequest
 */
export interface UpdateBuyerListOwnerRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBuyerListOwnerRequest
     */
    newOwnerGuid: string;
}

/**
 * Check if a given object implements the UpdateBuyerListOwnerRequest interface.
 */
export function instanceOfUpdateBuyerListOwnerRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "newOwnerGuid" in value;

    return isInstance;
}

export function UpdateBuyerListOwnerRequestFromJSON(json: any): UpdateBuyerListOwnerRequest {
    return UpdateBuyerListOwnerRequestFromJSONTyped(json, false);
}

export function UpdateBuyerListOwnerRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBuyerListOwnerRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newOwnerGuid': json['new_owner_guid'],
    };
}

export function UpdateBuyerListOwnerRequestToJSON(value?: UpdateBuyerListOwnerRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_owner_guid': value.newOwnerGuid,
    };
}

