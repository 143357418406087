import React, { useState } from "react";

import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import SaveSupplier, { SupplierContextSource } from "./SaveSupplier";

import css from "./SupplierNameCell.module.scss";

export function SupplierNameCell({
  supplierGuid,
  supplierName,
  contextSource = "In-row",
}: {
  supplierGuid: string;
  supplierName: string;
  contextSource?: SupplierContextSource;
}) {
  const [mouseEntered, setMouseEntered] = useState(false);
  return (
    <div
      className={css.nameColumn}
      onMouseEnter={() => setMouseEntered(true)}
      onMouseLeave={() => setMouseEntered(false)}
    >
      <EllipsisTooltipTextLink
        fullText={supplierName}
        linkText={supplierName}
        linkProps={{
          to: `/suppliers/${supplierGuid}`,
          authorised: !window.guestUser,
        }}
        containerClassname={css.titleLink}
      />

      <div
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        {!window.guestUser && (
          <SaveSupplier
            supplierGuid={supplierGuid}
            supplierName={supplierName}
            triggerType={mouseEntered ? "button" : "bookmark"}
            contextSource={contextSource}
          />
        )}
      </div>
    </div>
  );
}
