import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { MarkNotificationsAsUnreadRequest } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";

export function useMarkNotificationsAsUnread(
  options?: UseMutationOptions<
    unknown,
    unknown,
    MarkNotificationsAsUnreadRequest["notificationIds"],
    unknown
  >,
) {
  const api = useOpenApi();
  const qc = useQueryClient();
  const { logEvent } = useTracking();

  return useMutation(
    async (notificationIds: MarkNotificationsAsUnreadRequest["notificationIds"]) =>
      api.markNotificationsAsUnread({ markNotificationsAsUnreadRequest: { notificationIds } }),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await qc.invalidateQueries(["notifications"]);
        logEvent(EventNames.notificationMarkedAsUnread, {
          "Context source": "Mark as unread",
          "Notification ID": variables.length === 1 ? variables[0] : variables,
        });
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}
