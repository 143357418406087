import React, { useState } from "react";
import { Divider, Modal } from "antd5";

import { ProHelperProvider } from "lib/providers/ProHelper";
import BulkSaveSupplier from "./BulkSaveSupplier";
import { ExportSuppliersButton } from "./ExportSuppliersButton";
import { SupplierTable } from "./SupplierTable";
import { DEFAULT_FILTERS, SupplierColumns, SupplierFilters } from "./utils";

import css from "./SuppliersModal.module.scss";

const SUPPLIER_MODAL_COLUMNS: SupplierColumns[] = ["name", "notices", "sme"];

export type SuppliersModalProps = { isOpen: boolean; onClose: () => void; guids: string[] };

export function SuppliersModal({ isOpen, onClose, guids }: SuppliersModalProps) {
  const [selectedSuppliers, setSelectedSuppliers] = useState<string[]>(guids);

  const filtersWithSupplierGuids: SupplierFilters = {
    ...DEFAULT_FILTERS,
    guids,
  };

  const supplierGuids = selectedSuppliers.length > 0 ? selectedSuppliers : guids;

  return (
    <Modal
      onCancel={onClose}
      title={
        <>
          <h3 className={css.title}>Suppliers awarded this contract</h3>
          <Divider className={css.divider} />
        </>
      }
      open={isOpen}
      centered
      footer={null}
      width={1000}
      closable
    >
      <ProHelperProvider>
        <div className={css.modal}>
          <div className={css.modalHeader}>
            <h3>{supplierGuids.length} suppliers</h3>

            <div className={css.actions}>
              {selectedSuppliers.length > 0 && <BulkSaveSupplier supplierGuids={supplierGuids} />}
              <ExportSuppliersButton
                filters={filtersWithSupplierGuids}
                selectedSuppliers={selectedSuppliers}
              />
            </div>
          </div>
          <div className={css.table}>
            <SupplierTable
              filters={filtersWithSupplierGuids}
              selectedColumns={SUPPLIER_MODAL_COLUMNS}
              selectedRows={selectedSuppliers}
              onSelectedRowsChange={(rows) => setSelectedSuppliers(rows)}
            />
          </div>
        </div>
      </ProHelperProvider>
    </Modal>
  );
}
