/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnboardingTemplateDto
 */
export interface OnboardingTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof OnboardingTemplateDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingTemplateDto
     */
    industry: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingTemplateDto
     */
    subIndustry: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingTemplateDto
     */
    templateData: string;
}

/**
 * Check if a given object implements the OnboardingTemplateDto interface.
 */
export function instanceOfOnboardingTemplateDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "industry" in value;
    isInstance = isInstance && "subIndustry" in value;
    isInstance = isInstance && "templateData" in value;

    return isInstance;
}

export function OnboardingTemplateDtoFromJSON(json: any): OnboardingTemplateDto {
    return OnboardingTemplateDtoFromJSONTyped(json, false);
}

export function OnboardingTemplateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingTemplateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'industry': json['industry'],
        'subIndustry': json['sub_industry'],
        'templateData': json['template_data'],
    };
}

export function OnboardingTemplateDtoToJSON(value?: OnboardingTemplateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'industry': value.industry,
        'sub_industry': value.subIndustry,
        'template_data': value.templateData,
    };
}

