/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReportConfig } from './ReportConfig';
import {
    ReportConfigFromJSON,
    ReportConfigFromJSONTyped,
    ReportConfigToJSON,
} from './ReportConfig';

/**
 * 
 * @export
 * @interface SaveReportRequest
 */
export interface SaveReportRequest {
    /**
     * 
     * @type {ReportConfig}
     * @memberof SaveReportRequest
     */
    report: ReportConfig;
}

/**
 * Check if a given object implements the SaveReportRequest interface.
 */
export function instanceOfSaveReportRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "report" in value;

    return isInstance;
}

export function SaveReportRequestFromJSON(json: any): SaveReportRequest {
    return SaveReportRequestFromJSONTyped(json, false);
}

export function SaveReportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveReportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'report': ReportConfigFromJSON(json['report']),
    };
}

export function SaveReportRequestToJSON(value?: SaveReportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'report': ReportConfigToJSON(value.report),
    };
}

