import { DEFAULT_FILTERS, EMPTY_FILTERS } from "components/my_feed/useMyFeedPageState";
import { parseBooleanValue, parseNumberValue, useURLState } from "lib/hooks/useURLState";
import { NoticeColumns } from "./NoticeTable";
import { NoticeFilters } from "./utils";

type FiltersObjectKeys = keyof Pick<NoticeFilters, "publishDate">;

type FiltersArrayKeys = keyof Pick<
  NoticeFilters,
  "signals" | "procurementStageQualifications" | "assignee"
>;

function parseUrlState(val?: NoticeFilters, defaultFilters?: NoticeFilters) {
  if (!val) {
    return defaultFilters || DEFAULT_FILTERS;
  }

  // if array values are undefined set as an empty array otherwise default will override
  Object.keys(DEFAULT_FILTERS).forEach((k) => {
    const key = k as keyof NoticeFilters;
    if (
      typeof DEFAULT_FILTERS[key] === "object" &&
      !Array.isArray(DEFAULT_FILTERS[key]) &&
      !val[key]
    ) {
      val[key as FiltersObjectKeys] = {};
    }
    if (Array.isArray(DEFAULT_FILTERS[key]) && !val[key]) {
      val[key as FiltersArrayKeys] = [];
    }
  });

  return {
    ...defaultFilters,
    ...val,
    value: {
      from: parseNumberValue(val.value?.from),
      to: parseNumberValue(val.value?.to),
      hideNulls: parseBooleanValue(val.value?.hideNulls),
    },
    closeDate: {
      ...val.closeDate,
      hideNulls: parseBooleanValue(val.closeDate?.hideNulls),
    },
    expiryDate: {
      ...val.expiryDate,
      hideNulls: parseBooleanValue(val.expiryDate?.hideNulls),
    },
    suppliers: val.suppliers.map((id) => Number(id)) || [],
    supplierSme: parseBooleanValue(val.supplierSme) ?? "",
  };
}

export function useFilterableNoticeTableState() {
  const [noticeTableFilters, setNoticeTableFilters] = useURLState<NoticeFilters | undefined>(
    "noticeFilters",
    EMPTY_FILTERS,
    (val) => parseUrlState(val as NoticeFilters, EMPTY_FILTERS),
  );

  const [noticeTableColumns, setNoticeTableColumns] = useURLState<NoticeColumns[] | undefined>(
    "noticeColumns",
    undefined,
  );

  let tableColumns: NoticeColumns[] | undefined;
  if (noticeTableColumns) {
    tableColumns = noticeTableColumns as NoticeColumns[];
  } else {
    tableColumns = undefined;
  }

  return {
    noticeTableFilters,
    setNoticeTableFilters,
    noticeTableColumns,
    setNoticeTableColumns,
    tableColumns,
  };
}
