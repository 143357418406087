/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ContactDto } from './ContactDto';
import {
    ContactDtoFromJSON,
    ContactDtoFromJSONTyped,
    ContactDtoToJSON,
} from './ContactDto';
import type { PagingInfo } from './PagingInfo';
import {
    PagingInfoFromJSON,
    PagingInfoFromJSONTyped,
    PagingInfoToJSON,
} from './PagingInfo';

/**
 * 
 * @export
 * @interface ContactSearchResponseDto
 */
export interface ContactSearchResponseDto {
    /**
     * 
     * @type {Array<ContactDto>}
     * @memberof ContactSearchResponseDto
     */
    contacts: Array<ContactDto>;
    /**
     * 
     * @type {PagingInfo}
     * @memberof ContactSearchResponseDto
     */
    pagingInfo: PagingInfo;
}

/**
 * Check if a given object implements the ContactSearchResponseDto interface.
 */
export function instanceOfContactSearchResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contacts" in value;
    isInstance = isInstance && "pagingInfo" in value;

    return isInstance;
}

export function ContactSearchResponseDtoFromJSON(json: any): ContactSearchResponseDto {
    return ContactSearchResponseDtoFromJSONTyped(json, false);
}

export function ContactSearchResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSearchResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contacts': ((json['contacts'] as Array<any>).map(ContactDtoFromJSON)),
        'pagingInfo': PagingInfoFromJSON(json['paging_info']),
    };
}

export function ContactSearchResponseDtoToJSON(value?: ContactSearchResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contacts': ((value.contacts as Array<any>).map(ContactDtoToJSON)),
        'paging_info': PagingInfoToJSON(value.pagingInfo),
    };
}

