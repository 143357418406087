import React from "react";
import { Skeleton } from "antd5";

import { Currency } from "components/app_layout/Typography";
import {
  useSpendExists,
  useSupplierGuidSummary,
} from "lib/generated/spend-data-api/spendDataManagementAPI";

export function SpendDataCell({ supplier, buyers }: { supplier: string; buyers?: string[] }) {
  const isFilteredByBuyer = !!(buyers && buyers.length > 0);
  const { data: spendCheck, isLoading: isLoadingCheck } = useSpendExists(
    { buyerIds: buyers || [] },
    {
      query: {
        enabled: isFilteredByBuyer,
      },
    },
  );
  const spendExists = !!spendCheck?.exists;
  const { data, isLoading } = useSupplierGuidSummary(
    {
      filters: { supplierGuids: [supplier], buyerIds: buyers },
    },
    { query: { enabled: isFilteredByBuyer ? spendExists : true } },
  );

  if (isFilteredByBuyer && !spendExists && !isLoadingCheck) {
    return <>-</>;
  }

  if (isLoading || (isFilteredByBuyer && isLoadingCheck)) {
    return <Skeleton paragraph={false} />;
  }

  if (!data) {
    return <>-</>;
  }

  const total = data.data.reduce((total, supplier) => supplier.total + total, 0);

  return <Currency value={total} />;
}
