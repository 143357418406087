import React from "react";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, DatePicker, Input, Upload } from "antd5";
import { UploadChangeParam, UploadFile } from "antd5/lib/upload/interface";

import { FileData } from "./UploadDocuments";

import css from "./UploadDocument.module.scss";

type UploadDocumentProps = {
  fileNumber: number;
  fileData: FileData;
  onFileDataUpdate: (fileData: FileData | undefined, fileNumber: number) => void;
};

export function UploadDocument({
  fileNumber,
  fileData,
  onFileDataUpdate,
}: UploadDocumentProps): JSX.Element {
  const handleFileUploadChange = ({ fileList }: UploadChangeParam<UploadFile>) => {
    // 1. Limit the number of uploaded files
    fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    if (fileList.length > 0) {
      onFileDataUpdate({ ...fileData, uploadedFile: fileList[0] }, fileNumber);
    }
  };

  const handleDocumentClose = () => {
    onFileDataUpdate(undefined, fileNumber);
  };

  return (
    <div className={css.divWrapper}>
      <div className={css.field}>
        <span>{fileNumber + 1}</span>
        <Button className={css.closeFile} onClick={handleDocumentClose}>
          <CloseOutlined />
        </Button>
      </div>
      <div className={css.field}>
        <span className={css.linkInput}>
          <Input
            placeholder="Document link"
            value={fileData.fileLink}
            onChange={(e) =>
              onFileDataUpdate({ ...fileData, fileLink: e.currentTarget.value }, fileNumber)
            }
          />
        </span>{" "}
        OR{" "}
        <Upload
          onChange={handleFileUploadChange}
          fileList={fileData.uploadedFile ? [fileData.uploadedFile] : undefined}
          multiple={false}
          onRemove={() => onFileDataUpdate({ ...fileData, uploadedFile: undefined }, fileNumber)}
          beforeUpload={() => false} // return false so that antd doesn't upload the file right away
        >
          <Button icon={<UploadOutlined />}>Upload document</Button>
        </Upload>
      </div>
      <div className={css.field}>
        <DatePicker
          placeholder="Publish Date"
          onChange={(date, _) =>
            onFileDataUpdate(
              {
                ...fileData,
                publishDate: date ? date : undefined,
              },
              fileNumber,
            )
          }
          picker="month"
          value={fileData.publishDate}
        />
      </div>
      <div className={css.field}>
        <span className={css.description}>
          <Input
            placeholder="Description"
            value={fileData.description}
            onChange={(e) =>
              onFileDataUpdate({ ...fileData, description: e.currentTarget.value }, fileNumber)
            }
          />
        </span>
      </div>
    </div>
  );
}
