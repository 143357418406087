export const MidPaddingModal = {
  components: {
    Modal: {
      // For some reason, the padding prop doesn't act as a default for the padding* props
      // Therefore, we need to set each individual viewport padding prop to 32
      padding: 32,
      paddingLG: 32,
      paddingXL: 32,
      paddingSM: 32,
      paddingMD: 32,
      paddingXS: 32,
      paddingXXS: 32,
      // For some even more insane reason, the padding prop only affects the vertical (top and bottom) padding
      // Have to manuall set the horizontal padding to 32 for each viewport
      paddingContentHorizontal: 32,
      paddingContentHorizontalLG: 32,
      paddingContentHorizontalSM: 32,
    },
  },
};
