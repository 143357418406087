/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateQualificationRequest
 */
export interface CreateQualificationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateQualificationRequest
     */
    procurementStageId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQualificationRequest
     */
    qualification: CreateQualificationRequestQualificationEnum;
}


/**
 * @export
 */
export const CreateQualificationRequestQualificationEnum = {
    NotRelevant: 'not_relevant',
    InReview: 'in_review',
    BidPrep: 'bid_prep',
    BidSubmitted: 'bid_submitted',
    NoBid: 'no_bid',
    Won: 'won',
    Lost: 'lost',
    PreEngageToDo: 'pre_engage_to_do',
    PreEngageDone: 'pre_engage_done',
    PreEngageNotRelevant: 'pre_engage_not_relevant'
} as const;
export type CreateQualificationRequestQualificationEnum = typeof CreateQualificationRequestQualificationEnum[keyof typeof CreateQualificationRequestQualificationEnum];


/**
 * Check if a given object implements the CreateQualificationRequest interface.
 */
export function instanceOfCreateQualificationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "procurementStageId" in value;
    isInstance = isInstance && "qualification" in value;

    return isInstance;
}

export function CreateQualificationRequestFromJSON(json: any): CreateQualificationRequest {
    return CreateQualificationRequestFromJSONTyped(json, false);
}

export function CreateQualificationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateQualificationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'procurementStageId': json['procurement_stage_id'],
        'qualification': json['qualification'],
    };
}

export function CreateQualificationRequestToJSON(value?: CreateQualificationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'procurement_stage_id': value.procurementStageId,
        'qualification': value.qualification,
    };
}

