import * as React from "react";
import { Button, message, Modal, Select, Tag } from "antd5";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";

import { TextLink } from "components/actions/Links";
import { useSendInvites } from "lib/hooks/api/teams/useSendInvites";
import * as tracking from "lib/tracking";

import css from "./SendInviteModal.module.scss";

type SendInviteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  linkToTeamPage?: boolean;
};

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default function SendInviteModal({
  isOpen,
  onClose,
  linkToTeamPage,
}: SendInviteModalProps): JSX.Element {
  const [saving, setSaving] = React.useState(false);
  const [emails, setEmails] = React.useState<string[]>([]);

  const { mutate: sendInvites } = useSendInvites({
    onSuccess: () => {
      void message.success("Invite sent");
      tracking.logEvent(tracking.EventNames.userInviteResent, {});
    },
    onError() {
      void message.error("Unable to send invite; please contact us if the problem persists");
      setSaving(false);
    },
  });

  const handleInvitesSend = React.useCallback(async () => {
    setSaving(true);
    sendInvites(emails);
    setSaving(false);
    onClose();
  }, [sendInvites, emails, onClose]);

  const invalidEmails = emails.filter((e) => !e.match(EMAIL_REGEX));

  return (
    <Modal
      open={isOpen}
      title="Invite team members"
      onCancel={onClose}
      footer={
        <div className={css.actions}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleInvitesSend}
            type="primary"
            loading={saving}
            disabled={!!invalidEmails.length}
          >
            Send invite
          </Button>
        </div>
      }
    >
      <div className={css.content}>
        Invite new users by typing in their emails below:
        <div className={css.emailInput}>
          <Select
            mode="tags"
            value={emails}
            onChange={(v: string[]) => setEmails(v)}
            tokenSeparators={[",", ";", " "]}
            popupClassName={css.hide}
            placeholder="email@domain.com"
            tagRender={EmailTag}
          >
            {emails?.map((email) => {
              return <Select.Option key={email}>{email}</Select.Option>;
            })}
          </Select>
          <span className={css.label}>Separate multiple email addresses with a comma</span>
          {linkToTeamPage && (
            <div className={css.teamPageLink}>
              <TextLink to="/account-management/my-team">
                Go to <b>My Team</b>
              </TextLink>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

const EmailTag = (props: CustomTagProps) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const valid = value?.match(EMAIL_REGEX);

  return (
    <Tag
      color={valid ? "default" : "error"}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
};
