/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplierStatsSummary } from './SupplierStatsSummary';
import {
    SupplierStatsSummaryFromJSON,
    SupplierStatsSummaryFromJSONTyped,
    SupplierStatsSummaryToJSON,
} from './SupplierStatsSummary';

/**
 * 
 * @export
 * @interface SupplierListSummaryResponse
 */
export interface SupplierListSummaryResponse {
    /**
     * 
     * @type {Array<SupplierStatsSummary>}
     * @memberof SupplierListSummaryResponse
     */
    suppliers: Array<SupplierStatsSummary>;
}

/**
 * Check if a given object implements the SupplierListSummaryResponse interface.
 */
export function instanceOfSupplierListSummaryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "suppliers" in value;

    return isInstance;
}

export function SupplierListSummaryResponseFromJSON(json: any): SupplierListSummaryResponse {
    return SupplierListSummaryResponseFromJSONTyped(json, false);
}

export function SupplierListSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierListSummaryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'suppliers': ((json['suppliers'] as Array<any>).map(SupplierStatsSummaryFromJSON)),
    };
}

export function SupplierListSummaryResponseToJSON(value?: SupplierListSummaryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'suppliers': ((value.suppliers as Array<any>).map(SupplierStatsSummaryToJSON)),
    };
}

