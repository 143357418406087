import * as React from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Collapse, ConfigProvider, Skeleton } from "antd5";

import { GoldenCollapse } from "lib/themes/GoldenCollapse";
import { GoldenSkeleton } from "lib/themes/GoldenSkeleton";
import { useSummaryData } from "./hooks";
import SummaryPoint from "./SummaryPoint";
import { ORDER_OF_SECTIONS_ON_PAGE, SummaryValue } from "./utils";

import css from "./SummarySection.module.scss";

import SPARKLES from "../../../assets/images/icons/sparkles.svg";

type Props = {
  recordGuid: string;
  highlights?: string[];
  isNewHighlightingLogicEnabled?: boolean;
};

function SummarySection({ recordGuid, highlights, isNewHighlightingLogicEnabled }: Props) {
  const [panelOpen, setPanelOpen] = React.useState(false);
  const panelHeader = React.useRef(null);

  const { data, isCalculating } = useSummaryData(recordGuid);

  const summaryPoints = React.useMemo(
    () =>
      ORDER_OF_SECTIONS_ON_PAGE.map((s) => data[s]).filter((s) => s && s.content) as SummaryValue[],
    [data],
  );

  React.useEffect(() => {
    if (
      !panelOpen &&
      summaryPoints.length > 0 &&
      summaryPoints.some((sp) => sp.content && sp.loaded)
    ) {
      setPanelOpen(true);
    }
    // We don't want this hook to depend on panelOpen, because we don't want it to be re-run
    // every time the user toggles the panel open & closed; we only want the panel to automatically
    // open when the data loads
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryPoints]);

  const expandIcon = panelOpen ? (
    <UpOutlined className={css.collapseIcon} />
  ) : (
    <DownOutlined className={css.collapseIcon} />
  );

  return (
    <ConfigProvider
      theme={{
        ...GoldenSkeleton,
        ...GoldenCollapse,
        components: {
          ...GoldenSkeleton.components,
          ...GoldenCollapse.components,
        },
      }}
    >
      <Collapse
        // we show our own
        expandIcon={() => null}
        className={css.collapse}
        activeKey={panelOpen ? "summary" : undefined}
        onChange={(key) => {
          setPanelOpen(!!key && key[0] === "summary");
        }}
        items={[
          {
            label: (
              <div className={css.panelHeader}>
                <img
                  src={SPARKLES}
                  height={28}
                  alt="sparkles"
                  className={css.sparkles}
                  ref={panelHeader}
                />
                <div className={css.panelHeaderInner}>
                  {isCalculating ? (
                    <>
                      <h6>Generating your AI summary...</h6>
                      <Skeleton.Input active style={{ height: 7, width: 270 }} />
                    </>
                  ) : (
                    <>
                      <h6>Your AI summary</h6>
                      <p>Get the key highlights from this notice</p>
                    </>
                  )}
                  {expandIcon}
                </div>
              </div>
            ),
            key: "summary",
            children: (
              <div>
                {
                  // if nothing has loaded yet, show all skeletons
                  summaryPoints.every((p) => !p.loaded) ? (
                    <>
                      <Skeleton avatar title paragraph active />
                      <Skeleton avatar title paragraph active />
                      <Skeleton avatar title paragraph active />
                      <Skeleton avatar title paragraph active />
                    </>
                  ) : (
                    <>
                      <ul className={css.items}>
                        {summaryPoints.map((sp) => (
                          <SummaryPoint
                            summaryPoint={sp}
                            key={sp.attributeName}
                            wordsToHighlight={highlights}
                            isNewHighlightingLogicEnabled={isNewHighlightingLogicEnabled}
                          />
                        ))}
                      </ul>
                      {
                        // If some data is still loading, show a skeleton
                        summaryPoints.some((p) => !p.loaded) && (
                          <Skeleton avatar title paragraph active />
                        )
                      }
                    </>
                  )
                }
              </div>
            ),
          },
        ]}
      />
    </ConfigProvider>
  );
}

export default SummarySection;
