import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { OpportunityWorkflowQuery } from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";

const opportunityWorkflowQuery = graphql(`
  query opportunityWorkflow {
    opportunityWorkflow {
      id
      isDefault
      transitions {
        id
        group
        order
        stage {
          id
          name
        }
      }
    }
  }
`);

export function useOpportunityWorkflow(
  options?: UseQueryOptions<OpportunityWorkflowQuery, unknown, OpportunityWorkflowQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["opportunityWorkflow"],
    opportunityWorkflowQuery,
    [],
    options,
  );
  return { ...rest, data: data?.opportunityWorkflow };
}
