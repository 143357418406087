/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupplierFiltersSignalFilter
 */
export interface SupplierFiltersSignalFilter {
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierFiltersSignalFilter
     */
    categories: Array<SupplierFiltersSignalFilterCategoriesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierFiltersSignalFilter
     */
    ids: Array<string> | null;
}


/**
 * @export
 */
export const SupplierFiltersSignalFilterCategoriesEnum = {
    Competitors: 'Competitors',
    Partners: 'Partners',
    Keywords: 'Keywords',
    CpvCodes: 'CPV codes'
} as const;
export type SupplierFiltersSignalFilterCategoriesEnum = typeof SupplierFiltersSignalFilterCategoriesEnum[keyof typeof SupplierFiltersSignalFilterCategoriesEnum];


/**
 * Check if a given object implements the SupplierFiltersSignalFilter interface.
 */
export function instanceOfSupplierFiltersSignalFilter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "ids" in value;

    return isInstance;
}

export function SupplierFiltersSignalFilterFromJSON(json: any): SupplierFiltersSignalFilter {
    return SupplierFiltersSignalFilterFromJSONTyped(json, false);
}

export function SupplierFiltersSignalFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierFiltersSignalFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categories': json['categories'],
        'ids': json['ids'],
    };
}

export function SupplierFiltersSignalFilterToJSON(value?: SupplierFiltersSignalFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categories': value.categories,
        'ids': value.ids,
    };
}

