/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ViewConfigurationSortOrder
 */
export interface ViewConfigurationSortOrder {
    /**
     * 
     * @type {string}
     * @memberof ViewConfigurationSortOrder
     */
    field: string;
    /**
     * 
     * @type {string}
     * @memberof ViewConfigurationSortOrder
     */
    order: ViewConfigurationSortOrderOrderEnum;
}


/**
 * @export
 */
export const ViewConfigurationSortOrderOrderEnum = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;
export type ViewConfigurationSortOrderOrderEnum = typeof ViewConfigurationSortOrderOrderEnum[keyof typeof ViewConfigurationSortOrderOrderEnum];


/**
 * Check if a given object implements the ViewConfigurationSortOrder interface.
 */
export function instanceOfViewConfigurationSortOrder(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "field" in value;
    isInstance = isInstance && "order" in value;

    return isInstance;
}

export function ViewConfigurationSortOrderFromJSON(json: any): ViewConfigurationSortOrder {
    return ViewConfigurationSortOrderFromJSONTyped(json, false);
}

export function ViewConfigurationSortOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewConfigurationSortOrder {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': json['field'],
        'order': json['order'],
    };
}

export function ViewConfigurationSortOrderToJSON(value?: ViewConfigurationSortOrder | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'order': value.order,
    };
}

