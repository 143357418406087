import React, { ComponentProps } from "react";
import { Drawer } from "antd5";

import { FILTER_COLLAPSE_NOTICES, useVariableValue } from "../../../lib/featureFlags";

import css from "./FilterDrawer.module.scss";

function FilterDrawer(props: ComponentProps<typeof Drawer>) {
  const isCollapseEnabled = useVariableValue(FILTER_COLLAPSE_NOTICES, false);

  return (
    <Drawer
      title={isCollapseEnabled ? null : "Filters"}
      width={isCollapseEnabled ? 460 : undefined}
      closeIcon={isCollapseEnabled ? null : undefined}
      classNames={isCollapseEnabled ? { body: css.drawerBody } : undefined}
      size={isCollapseEnabled ? undefined : "large"}
      {...props}
    >
      {props.children}
    </Drawer>
  );
}

export default FilterDrawer;
