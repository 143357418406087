import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { DescribeUserResponse } from "lib/generated/admin-api/models";
import { useAdminApi } from "lib/stotlesAdminApiContext";

type QueryKey = [string, string];

export function useDescribeUser(
  guid: string,
  options?: UseQueryOptions<DescribeUserResponse, unknown, DescribeUserResponse, QueryKey>,
) {
  const { api } = useAdminApi();
  return useQuery(
    ["userDetails", guid],
    async () => {
      const user = await api.describeUser({ describeUserRequest: { guid } });
      return user;
    },
    options,
  );
}
