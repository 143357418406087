import * as React from "react";
import classNames from "classnames";

import { DetailsContent } from "components/app_layout/DetailsLayout";
import { PaywallContent } from "./PaywallContent";
import { FeatureType, PaywallImg } from "./paywallUtils";

import css from "./PaywallBanner.module.scss";

type BannerProps = { featureType?: FeatureType; className?: string; isAddOn?: boolean };

export function PaywallBanner({
  featureType,
  isAddOn,
  className,
}: BannerProps): JSX.Element | null {
  if (!featureType) {
    return null;
  }

  return (
    <DetailsContent className={classNames(css.banner, className)}>
      <div className={css.bannerContainer} data-testid="details-content">
        <PaywallContent featureType={featureType} isAddOn={isAddOn} />
        <PaywallImg context={"BANNER"} featureType={featureType} />
      </div>
    </DetailsContent>
  );
}
