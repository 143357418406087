/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyerCategoryAssignment } from './BuyerCategoryAssignment';
import {
    BuyerCategoryAssignmentFromJSON,
    BuyerCategoryAssignmentFromJSONTyped,
    BuyerCategoryAssignmentToJSON,
} from './BuyerCategoryAssignment';

/**
 * 
 * @export
 * @interface AssignBuyerCategoriesRequest
 */
export interface AssignBuyerCategoriesRequest {
    /**
     * 
     * @type {Array<BuyerCategoryAssignment>}
     * @memberof AssignBuyerCategoriesRequest
     */
    buyerCategoryAssignments: Array<BuyerCategoryAssignment>;
    /**
     * 
     * @type {boolean}
     * @memberof AssignBuyerCategoriesRequest
     */
    replaceAssignments: boolean;
}

/**
 * Check if a given object implements the AssignBuyerCategoriesRequest interface.
 */
export function instanceOfAssignBuyerCategoriesRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "buyerCategoryAssignments" in value;
    isInstance = isInstance && "replaceAssignments" in value;

    return isInstance;
}

export function AssignBuyerCategoriesRequestFromJSON(json: any): AssignBuyerCategoriesRequest {
    return AssignBuyerCategoriesRequestFromJSONTyped(json, false);
}

export function AssignBuyerCategoriesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignBuyerCategoriesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buyerCategoryAssignments': ((json['buyer_category_assignments'] as Array<any>).map(BuyerCategoryAssignmentFromJSON)),
        'replaceAssignments': json['replace_assignments'],
    };
}

export function AssignBuyerCategoriesRequestToJSON(value?: AssignBuyerCategoriesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buyer_category_assignments': ((value.buyerCategoryAssignments as Array<any>).map(BuyerCategoryAssignmentToJSON)),
        'replace_assignments': value.replaceAssignments,
    };
}

