/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RegionSearchDto } from './RegionSearchDto';
import {
    RegionSearchDtoFromJSON,
    RegionSearchDtoFromJSONTyped,
    RegionSearchDtoToJSON,
} from './RegionSearchDto';

/**
 * 
 * @export
 * @interface SearchRegionsResponse
 */
export interface SearchRegionsResponse {
    /**
     * 
     * @type {Array<RegionSearchDto>}
     * @memberof SearchRegionsResponse
     */
    regions: Array<RegionSearchDto>;
}

/**
 * Check if a given object implements the SearchRegionsResponse interface.
 */
export function instanceOfSearchRegionsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "regions" in value;

    return isInstance;
}

export function SearchRegionsResponseFromJSON(json: any): SearchRegionsResponse {
    return SearchRegionsResponseFromJSONTyped(json, false);
}

export function SearchRegionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRegionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'regions': ((json['regions'] as Array<any>).map(RegionSearchDtoFromJSON)),
    };
}

export function SearchRegionsResponseToJSON(value?: SearchRegionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'regions': ((value.regions as Array<any>).map(RegionSearchDtoToJSON)),
    };
}

