import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { EnumValue } from "lib/generated/integration-api";
import { useIntegrationApi } from "lib/integrationApiContext";

type QueryKey = [string, string, Record<string, string>];

export function usePropertyOptions(
  propertyName: string,
  formData: Record<string, string>,
  options?: UseQueryOptions<EnumValue[], unknown, EnumValue[], QueryKey>,
) {
  const api = useIntegrationApi();
  return useQuery(
    ["fetchPropertyValues", propertyName, formData],
    async () => {
      const response = await api.fetchPropertyValues({
        fetchPropertyValuesRequest: { propertyName, formData },
      });
      return response.values;
    },
    options,
  );
}
