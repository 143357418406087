import React from "react";
import { Layout } from "antd5";
import classnames from "classnames";

import { useVariableValue, VERTICAL_NAV } from "../../lib/featureFlags";
import AppLayoutNavbar from "./AppLayoutNavbar";
import SmallScreenWarning from "./SmallScreenWarning";

import css from "./AppContentLayout.module.scss";

type AppContentLayoutProps = {
  hideMenuItems?: boolean;
  hideUserItems?: boolean;
  displayCustomAction?: React.ReactNode;
  disableLogoNav?: boolean;
  textLogo?: boolean;
  contentClassName?: string;
  children: React.ReactNode;
};

function AppContentLayout({
  hideMenuItems,
  hideUserItems,
  displayCustomAction,
  disableLogoNav,
  children,
  contentClassName,
  textLogo,
}: AppContentLayoutProps) {
  const isVerticalNavEnabled = useVariableValue(VERTICAL_NAV, false);

  const showNav = window.currentUser && !hideMenuItems;

  return (
    <div className={isVerticalNavEnabled && showNav ? css.verticalNavLayout : undefined}>
      <AppLayoutNavbar
        hideMenuItems={hideMenuItems}
        hideUserItems={hideUserItems}
        displayCustomAction={displayCustomAction}
        disableLogoNav={disableLogoNav}
        textLogo={textLogo}
      />
      <SmallScreenWarning />
      <Layout.Content className={classnames(css.pageContent, contentClassName)}>
        {children}
      </Layout.Content>
    </div>
  );
}

export default AppContentLayout;
