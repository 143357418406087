/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeadSubscriptionResponse
 */
export interface LeadSubscriptionResponse {
    /**
     * 
     * @type {string}
     * @memberof LeadSubscriptionResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSubscriptionResponse
     */
    userId: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadSubscriptionResponse
     */
    companyId: string;
    /**
     * 
     * @type {string}
     * @memberof LeadSubscriptionResponse
     */
    teamId: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadSubscriptionResponse
     */
    frequency: LeadSubscriptionResponseFrequencyEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LeadSubscriptionResponse
     */
    disabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeadSubscriptionResponse
     */
    name: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadSubscriptionResponse
     */
    webhookUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeadSubscriptionResponse
     */
    type: string | null;
    /**
     * 
     * @type {object}
     * @memberof LeadSubscriptionResponse
     */
    leadSubscriptionContents: object;
}


/**
 * @export
 */
export const LeadSubscriptionResponseFrequencyEnum = {
    Daily: 'DAILY',
    Weekly: 'WEEKLY'
} as const;
export type LeadSubscriptionResponseFrequencyEnum = typeof LeadSubscriptionResponseFrequencyEnum[keyof typeof LeadSubscriptionResponseFrequencyEnum];


/**
 * Check if a given object implements the LeadSubscriptionResponse interface.
 */
export function instanceOfLeadSubscriptionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "teamId" in value;
    isInstance = isInstance && "frequency" in value;
    isInstance = isInstance && "disabled" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "webhookUrl" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "leadSubscriptionContents" in value;

    return isInstance;
}

export function LeadSubscriptionResponseFromJSON(json: any): LeadSubscriptionResponse {
    return LeadSubscriptionResponseFromJSONTyped(json, false);
}

export function LeadSubscriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeadSubscriptionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['user_id'],
        'companyId': json['company_id'],
        'teamId': json['team_id'],
        'frequency': json['frequency'],
        'disabled': json['disabled'],
        'name': json['name'],
        'webhookUrl': json['webhook_url'],
        'type': json['type'],
        'leadSubscriptionContents': json['lead_subscription_contents'],
    };
}

export function LeadSubscriptionResponseToJSON(value?: LeadSubscriptionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'user_id': value.userId,
        'company_id': value.companyId,
        'team_id': value.teamId,
        'frequency': value.frequency,
        'disabled': value.disabled,
        'name': value.name,
        'webhook_url': value.webhookUrl,
        'type': value.type,
        'lead_subscription_contents': value.leadSubscriptionContents,
    };
}

