import * as React from "react";

import { ProHelperProvider } from "lib/providers/ProHelper";
import {
  availableDataTypes,
  DefaultSubscriptionProvider,
  SubscriptionContext,
} from "lib/providers/Subscription";
import { DataTypeName } from "lib/types/models";

type Props = {
  children: React.ReactNode;
};

export function ReportSubscriptionContextProvider({ children }: Props): JSX.Element {
  return (
    <SubscriptionContext.Provider
      value={
        new DefaultSubscriptionProvider(availableDataTypes, [] as DataTypeName[], null, null, null)
      }
    >
      <ProHelperProvider>{children}</ProHelperProvider>
    </SubscriptionContext.Provider>
  );
}
