import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { SearchBuyersParams, SearchResponse } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";
import { BuyerSummary } from "lib/types/models";

export function useBuyers(
  props: SearchBuyersParams,
  options?: UseQueryOptions<
    SearchResponse<BuyerSummary>,
    unknown,
    SearchResponse<BuyerSummary>,
    [string, SearchBuyersParams]
  >,
) {
  const api = useStotlesApi();

  return useQuery(["buyers", props], () => api.searchBuyers(props), options);
}
