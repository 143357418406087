/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecordSearchRequestDtoAnySignals } from './RecordSearchRequestDtoAnySignals';
import {
    RecordSearchRequestDtoAnySignalsFromJSON,
    RecordSearchRequestDtoAnySignalsFromJSONTyped,
    RecordSearchRequestDtoAnySignalsToJSON,
} from './RecordSearchRequestDtoAnySignals';
import type { RecordSearchRequestDtoBuyers } from './RecordSearchRequestDtoBuyers';
import {
    RecordSearchRequestDtoBuyersFromJSON,
    RecordSearchRequestDtoBuyersFromJSONTyped,
    RecordSearchRequestDtoBuyersToJSON,
} from './RecordSearchRequestDtoBuyers';
import type { RecordSearchRequestDtoDateFilterDto } from './RecordSearchRequestDtoDateFilterDto';
import {
    RecordSearchRequestDtoDateFilterDtoFromJSON,
    RecordSearchRequestDtoDateFilterDtoFromJSONTyped,
    RecordSearchRequestDtoDateFilterDtoToJSON,
} from './RecordSearchRequestDtoDateFilterDto';
import type { RecordSearchRequestDtoFrameworks } from './RecordSearchRequestDtoFrameworks';
import {
    RecordSearchRequestDtoFrameworksFromJSON,
    RecordSearchRequestDtoFrameworksFromJSONTyped,
    RecordSearchRequestDtoFrameworksToJSON,
} from './RecordSearchRequestDtoFrameworks';
import type { RecordSearchRequestDtoNullableDateFilterDto } from './RecordSearchRequestDtoNullableDateFilterDto';
import {
    RecordSearchRequestDtoNullableDateFilterDtoFromJSON,
    RecordSearchRequestDtoNullableDateFilterDtoFromJSONTyped,
    RecordSearchRequestDtoNullableDateFilterDtoToJSON,
} from './RecordSearchRequestDtoNullableDateFilterDto';
import type { RecordSearchRequestDtoQualifications } from './RecordSearchRequestDtoQualifications';
import {
    RecordSearchRequestDtoQualificationsFromJSON,
    RecordSearchRequestDtoQualificationsFromJSONTyped,
    RecordSearchRequestDtoQualificationsToJSON,
} from './RecordSearchRequestDtoQualifications';
import type { RecordSearchRequestDtoSignalFilterDto } from './RecordSearchRequestDtoSignalFilterDto';
import {
    RecordSearchRequestDtoSignalFilterDtoFromJSON,
    RecordSearchRequestDtoSignalFilterDtoFromJSONTyped,
    RecordSearchRequestDtoSignalFilterDtoToJSON,
} from './RecordSearchRequestDtoSignalFilterDto';
import type { RecordSearchRequestDtoSuppliers } from './RecordSearchRequestDtoSuppliers';
import {
    RecordSearchRequestDtoSuppliersFromJSON,
    RecordSearchRequestDtoSuppliersFromJSONTyped,
    RecordSearchRequestDtoSuppliersToJSON,
} from './RecordSearchRequestDtoSuppliers';
import type { RecordSearchRequestDtoValue } from './RecordSearchRequestDtoValue';
import {
    RecordSearchRequestDtoValueFromJSON,
    RecordSearchRequestDtoValueFromJSONTyped,
    RecordSearchRequestDtoValueToJSON,
} from './RecordSearchRequestDtoValue';

/**
 * 
 * @export
 * @interface RecordSearchRequestDto
 */
export interface RecordSearchRequestDto {
    /**
     * 
     * @type {number}
     * @memberof RecordSearchRequestDto
     */
    offset?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RecordSearchRequestDto
     */
    limit?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchRequestDto
     */
    sort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchRequestDto
     */
    sortOrder?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    id?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    guid?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    procurementStageId?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    procurementProcessId?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    recordType?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    countryCode?: Array<string> | null;
    /**
     * 
     * @type {RecordSearchRequestDtoDateFilterDto}
     * @memberof RecordSearchRequestDto
     */
    publishDate?: RecordSearchRequestDtoDateFilterDto;
    /**
     * 
     * @type {RecordSearchRequestDtoBuyers}
     * @memberof RecordSearchRequestDto
     */
    buyers?: RecordSearchRequestDtoBuyers;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    cpvDimensions?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    languageCode?: Array<string> | null;
    /**
     * 
     * @type {RecordSearchRequestDtoValue}
     * @memberof RecordSearchRequestDto
     */
    value?: RecordSearchRequestDtoValue;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    stage?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    relevanceScore?: Array<RecordSearchRequestDtoRelevanceScoreEnum>;
    /**
     * 
     * @type {RecordSearchRequestDtoDateFilterDto}
     * @memberof RecordSearchRequestDto
     */
    awardDate?: RecordSearchRequestDtoDateFilterDto;
    /**
     * 
     * @type {RecordSearchRequestDtoNullableDateFilterDto}
     * @memberof RecordSearchRequestDto
     */
    expiryDate?: RecordSearchRequestDtoNullableDateFilterDto;
    /**
     * 
     * @type {RecordSearchRequestDtoNullableDateFilterDto}
     * @memberof RecordSearchRequestDto
     */
    closeDate?: RecordSearchRequestDtoNullableDateFilterDto;
    /**
     * 
     * @type {RecordSearchRequestDtoSuppliers}
     * @memberof RecordSearchRequestDto
     */
    suppliers?: RecordSearchRequestDtoSuppliers;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchRequestDto
     */
    text?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RecordSearchRequestDto
     */
    recordListId?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RecordSearchRequestDto
     */
    recordListIds?: Array<number> | null;
    /**
     * 
     * @type {RecordSearchRequestDtoDateFilterDto}
     * @memberof RecordSearchRequestDto
     */
    recordListAddedDate?: RecordSearchRequestDtoDateFilterDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    assignee?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    signalCategory?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchRequestDto
     */
    showUserHiddenLeads?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    ignoredSignalCategories?: Array<string> | null;
    /**
     * 
     * @type {RecordSearchRequestDtoQualifications}
     * @memberof RecordSearchRequestDto
     */
    qualifications?: RecordSearchRequestDtoQualifications;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    procurementStageQualifications?: Array<RecordSearchRequestDtoProcurementStageQualificationsEnum>;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchRequestDto
     */
    buyerListId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    buyerListIds?: Array<string> | null;
    /**
     * 
     * @type {RecordSearchRequestDtoSignalFilterDto}
     * @memberof RecordSearchRequestDto
     */
    signalsFilter?: RecordSearchRequestDtoSignalFilterDto;
    /**
     * 
     * @type {RecordSearchRequestDtoAnySignals}
     * @memberof RecordSearchRequestDto
     */
    anySignals?: RecordSearchRequestDtoAnySignals;
    /**
     * 
     * @type {Array<number>}
     * @memberof RecordSearchRequestDto
     */
    detailTags?: Array<number> | null;
    /**
     * 
     * @type {RecordSearchRequestDtoDateFilterDto}
     * @memberof RecordSearchRequestDto
     */
    dateAdded?: RecordSearchRequestDtoDateFilterDto;
    /**
     * 
     * @type {boolean}
     * @memberof RecordSearchRequestDto
     */
    dedupe?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RecordSearchRequestDto
     */
    preview?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchRequestDto
     */
    signalSort?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDto
     */
    selectedColumns?: Array<string> | null;
    /**
     * 
     * @type {RecordSearchRequestDtoFrameworks}
     * @memberof RecordSearchRequestDto
     */
    frameworks?: RecordSearchRequestDtoFrameworks;
}


/**
 * @export
 */
export const RecordSearchRequestDtoRelevanceScoreEnum = {
    None: 'None',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High'
} as const;
export type RecordSearchRequestDtoRelevanceScoreEnum = typeof RecordSearchRequestDtoRelevanceScoreEnum[keyof typeof RecordSearchRequestDtoRelevanceScoreEnum];

/**
 * @export
 */
export const RecordSearchRequestDtoProcurementStageQualificationsEnum = {
    Unqualified: 'unqualified',
    NotRelevant: 'not_relevant',
    InReview: 'in_review',
    BidPrep: 'bid_prep',
    BidSubmitted: 'bid_submitted',
    NoBid: 'no_bid',
    Won: 'won',
    Lost: 'lost',
    PreEngageToDo: 'pre_engage_to_do',
    PreEngageDone: 'pre_engage_done',
    PreEngageNotRelevant: 'pre_engage_not_relevant'
} as const;
export type RecordSearchRequestDtoProcurementStageQualificationsEnum = typeof RecordSearchRequestDtoProcurementStageQualificationsEnum[keyof typeof RecordSearchRequestDtoProcurementStageQualificationsEnum];


/**
 * Check if a given object implements the RecordSearchRequestDto interface.
 */
export function instanceOfRecordSearchRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RecordSearchRequestDtoFromJSON(json: any): RecordSearchRequestDto {
    return RecordSearchRequestDtoFromJSONTyped(json, false);
}

export function RecordSearchRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSearchRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'sortOrder': !exists(json, 'sort_order') ? undefined : json['sort_order'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'guid': !exists(json, 'guid') ? undefined : json['guid'],
        'procurementStageId': !exists(json, 'procurement_stage_id') ? undefined : json['procurement_stage_id'],
        'procurementProcessId': !exists(json, 'procurement_process_id') ? undefined : json['procurement_process_id'],
        'recordType': !exists(json, 'record_type') ? undefined : json['record_type'],
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
        'publishDate': !exists(json, 'publish_date') ? undefined : RecordSearchRequestDtoDateFilterDtoFromJSON(json['publish_date']),
        'buyers': !exists(json, 'buyers') ? undefined : RecordSearchRequestDtoBuyersFromJSON(json['buyers']),
        'cpvDimensions': !exists(json, 'cpv_dimensions') ? undefined : json['cpv_dimensions'],
        'languageCode': !exists(json, 'language_code') ? undefined : json['language_code'],
        'value': !exists(json, 'value') ? undefined : RecordSearchRequestDtoValueFromJSON(json['value']),
        'stage': !exists(json, 'stage') ? undefined : json['stage'],
        'relevanceScore': !exists(json, 'relevance_score') ? undefined : json['relevance_score'],
        'awardDate': !exists(json, 'award_date') ? undefined : RecordSearchRequestDtoDateFilterDtoFromJSON(json['award_date']),
        'expiryDate': !exists(json, 'expiry_date') ? undefined : RecordSearchRequestDtoNullableDateFilterDtoFromJSON(json['expiry_date']),
        'closeDate': !exists(json, 'close_date') ? undefined : RecordSearchRequestDtoNullableDateFilterDtoFromJSON(json['close_date']),
        'suppliers': !exists(json, 'suppliers') ? undefined : RecordSearchRequestDtoSuppliersFromJSON(json['suppliers']),
        'text': !exists(json, 'text') ? undefined : json['text'],
        'recordListId': !exists(json, 'record_list_id') ? undefined : json['record_list_id'],
        'recordListIds': !exists(json, 'record_list_ids') ? undefined : json['record_list_ids'],
        'recordListAddedDate': !exists(json, 'record_list_added_date') ? undefined : RecordSearchRequestDtoDateFilterDtoFromJSON(json['record_list_added_date']),
        'assignee': !exists(json, 'assignee') ? undefined : json['assignee'],
        'signalCategory': !exists(json, 'signal_category') ? undefined : json['signal_category'],
        'showUserHiddenLeads': !exists(json, 'show_user_hidden_leads') ? undefined : json['show_user_hidden_leads'],
        'ignoredSignalCategories': !exists(json, 'ignored_signal_categories') ? undefined : json['ignored_signal_categories'],
        'qualifications': !exists(json, 'qualifications') ? undefined : RecordSearchRequestDtoQualificationsFromJSON(json['qualifications']),
        'procurementStageQualifications': !exists(json, 'procurement_stage_qualifications') ? undefined : json['procurement_stage_qualifications'],
        'buyerListId': !exists(json, 'buyer_list_id') ? undefined : json['buyer_list_id'],
        'buyerListIds': !exists(json, 'buyer_list_ids') ? undefined : json['buyer_list_ids'],
        'signalsFilter': !exists(json, 'signals_filter') ? undefined : RecordSearchRequestDtoSignalFilterDtoFromJSON(json['signals_filter']),
        'anySignals': !exists(json, 'any_signals') ? undefined : RecordSearchRequestDtoAnySignalsFromJSON(json['any_signals']),
        'detailTags': !exists(json, 'detail_tags') ? undefined : json['detail_tags'],
        'dateAdded': !exists(json, 'date_added') ? undefined : RecordSearchRequestDtoDateFilterDtoFromJSON(json['date_added']),
        'dedupe': !exists(json, 'dedupe') ? undefined : json['dedupe'],
        'preview': !exists(json, 'preview') ? undefined : json['preview'],
        'signalSort': !exists(json, 'signal_sort') ? undefined : json['signal_sort'],
        'selectedColumns': !exists(json, 'selected_columns') ? undefined : json['selected_columns'],
        'frameworks': !exists(json, 'frameworks') ? undefined : RecordSearchRequestDtoFrameworksFromJSON(json['frameworks']),
    };
}

export function RecordSearchRequestDtoToJSON(value?: RecordSearchRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offset': value.offset,
        'limit': value.limit,
        'sort': value.sort,
        'sort_order': value.sortOrder,
        'id': value.id,
        'guid': value.guid,
        'procurement_stage_id': value.procurementStageId,
        'procurement_process_id': value.procurementProcessId,
        'record_type': value.recordType,
        'country_code': value.countryCode,
        'publish_date': RecordSearchRequestDtoDateFilterDtoToJSON(value.publishDate),
        'buyers': RecordSearchRequestDtoBuyersToJSON(value.buyers),
        'cpv_dimensions': value.cpvDimensions,
        'language_code': value.languageCode,
        'value': RecordSearchRequestDtoValueToJSON(value.value),
        'stage': value.stage,
        'relevance_score': value.relevanceScore,
        'award_date': RecordSearchRequestDtoDateFilterDtoToJSON(value.awardDate),
        'expiry_date': RecordSearchRequestDtoNullableDateFilterDtoToJSON(value.expiryDate),
        'close_date': RecordSearchRequestDtoNullableDateFilterDtoToJSON(value.closeDate),
        'suppliers': RecordSearchRequestDtoSuppliersToJSON(value.suppliers),
        'text': value.text,
        'record_list_id': value.recordListId,
        'record_list_ids': value.recordListIds,
        'record_list_added_date': RecordSearchRequestDtoDateFilterDtoToJSON(value.recordListAddedDate),
        'assignee': value.assignee,
        'signal_category': value.signalCategory,
        'show_user_hidden_leads': value.showUserHiddenLeads,
        'ignored_signal_categories': value.ignoredSignalCategories,
        'qualifications': RecordSearchRequestDtoQualificationsToJSON(value.qualifications),
        'procurement_stage_qualifications': value.procurementStageQualifications,
        'buyer_list_id': value.buyerListId,
        'buyer_list_ids': value.buyerListIds,
        'signals_filter': RecordSearchRequestDtoSignalFilterDtoToJSON(value.signalsFilter),
        'any_signals': RecordSearchRequestDtoAnySignalsToJSON(value.anySignals),
        'detail_tags': value.detailTags,
        'date_added': RecordSearchRequestDtoDateFilterDtoToJSON(value.dateAdded),
        'dedupe': value.dedupe,
        'preview': value.preview,
        'signal_sort': value.signalSort,
        'selected_columns': value.selectedColumns,
        'frameworks': RecordSearchRequestDtoFrameworksToJSON(value.frameworks),
    };
}

