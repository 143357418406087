import React from "react";
import classNames from "classnames";

import { TextLink } from "components/actions/Links";
import { formatNumber } from "components/app_layout/Typography/Numbers";
import { EventNames } from "lib/tracking";
import { pluralise } from "lib/utils";

import css from "./FrameworkCallOffTableCell.module.scss";

type FrameworkCallOffTableCellProps = {
  averageAmount?: number;
  callOffCount?: number;
  currency?: string;
  linkTo: string;
  eventName?: EventNames;
  eventAttributes?: Record<string, string | string[] | Record<string, string[]> | undefined | null>;
  className?: string;
  onClick?: () => void;
};

export function FrameworkCallOffTableCell({
  averageAmount,
  callOffCount,
  currency,
  linkTo,
  eventName,
  eventAttributes,
  onClick,
  className,
}: FrameworkCallOffTableCellProps) {
  const avgValue = formatNumber({
    value: averageAmount,
    locale: "en-GB",
    currency: currency,
  });

  return (
    <div className={classNames(css.frameworkCallOff, className)}>
      <TextLink
        to={linkTo}
        onClick={onClick}
        eventName={eventName}
        eventAttributes={eventAttributes}
      >
        <div className={css.callOffCount}>{pluralise(callOffCount || 0, "Call-off")}</div>
      </TextLink>
      <div className={css.callOffValue}>{avgValue ? `${avgValue} Avg.` : "--"}</div>
    </div>
  );
}
