/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubmittedDecision } from './SubmittedDecision';
import {
    SubmittedDecisionFromJSON,
    SubmittedDecisionFromJSONTyped,
    SubmittedDecisionToJSON,
} from './SubmittedDecision';

/**
 * 
 * @export
 * @interface SubmitAndProcessOrgCleaningTaskRequest
 */
export interface SubmitAndProcessOrgCleaningTaskRequest {
    /**
     * 
     * @type {Array<SubmittedDecision>}
     * @memberof SubmitAndProcessOrgCleaningTaskRequest
     */
    decisions: Array<SubmittedDecision>;
    /**
     * 
     * @type {string}
     * @memberof SubmitAndProcessOrgCleaningTaskRequest
     */
    organisationId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitAndProcessOrgCleaningTaskRequest
     */
    buyerGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitAndProcessOrgCleaningTaskRequest
     */
    taskType: SubmitAndProcessOrgCleaningTaskRequestTaskTypeEnum;
}


/**
 * @export
 */
export const SubmitAndProcessOrgCleaningTaskRequestTaskTypeEnum = {
    DeDupeCleaning: 'de_dupe_cleaning',
    RelationshipCleaning: 'relationship_cleaning',
    DataMigration: 'data_migration'
} as const;
export type SubmitAndProcessOrgCleaningTaskRequestTaskTypeEnum = typeof SubmitAndProcessOrgCleaningTaskRequestTaskTypeEnum[keyof typeof SubmitAndProcessOrgCleaningTaskRequestTaskTypeEnum];


/**
 * Check if a given object implements the SubmitAndProcessOrgCleaningTaskRequest interface.
 */
export function instanceOfSubmitAndProcessOrgCleaningTaskRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "decisions" in value;
    isInstance = isInstance && "taskType" in value;

    return isInstance;
}

export function SubmitAndProcessOrgCleaningTaskRequestFromJSON(json: any): SubmitAndProcessOrgCleaningTaskRequest {
    return SubmitAndProcessOrgCleaningTaskRequestFromJSONTyped(json, false);
}

export function SubmitAndProcessOrgCleaningTaskRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitAndProcessOrgCleaningTaskRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'decisions': ((json['decisions'] as Array<any>).map(SubmittedDecisionFromJSON)),
        'organisationId': !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
        'buyerGuid': !exists(json, 'buyer_guid') ? undefined : json['buyer_guid'],
        'taskType': json['task_type'],
    };
}

export function SubmitAndProcessOrgCleaningTaskRequestToJSON(value?: SubmitAndProcessOrgCleaningTaskRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'decisions': ((value.decisions as Array<any>).map(SubmittedDecisionToJSON)),
        'organisation_id': value.organisationId,
        'buyer_guid': value.buyerGuid,
        'task_type': value.taskType,
    };
}

