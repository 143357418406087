import React from "react";

import { bell03, logOut01, settings01, users01 } from "../../../lib/icons/untitled_ui/SVGs";
import { performLogout } from "../../../lib/userUtils";
import DropdownButton from "../../ui/dropdown_menu/DropdownButton";
import DropdownMenu from "../../ui/dropdown_menu/DropdownMenu";
import Item from "../../ui/dropdown_menu/DropdownMenuItem";

type TeamDropdownProps = {
  onOpenChange?: (open: boolean) => void;
};

function TeamDropdown({ onOpenChange }: TeamDropdownProps) {
  const teamName = window.currentUser?.team.name;

  if (!teamName) {
    return null;
  }

  return (
    <DropdownMenu
      onOpenChange={onOpenChange}
      trigger={<DropdownButton>{teamName}</DropdownButton>}
      items={[
        <Item icon={settings01} path="/account-management/subscription">
          Account settings
        </Item>,
        <Item icon={bell03} path="/account-management/notifications">
          Notifaction settings
        </Item>,
        <Item icon={users01} path="/account-management/my-team">
          Manage Team
        </Item>,
        <Item seperator />,
        <Item icon={logOut01} onClick={() => performLogout()}>
          Logout
        </Item>,
      ]}
    />
  );
}

export default TeamDropdown;
