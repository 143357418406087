import * as React from "react";
import classnames from "classnames";

import css from "./Steps.module.scss";

export type Props = {
  totalSteps: number;
  currentStep: number;
};

function Steps({ totalSteps, currentStep }: Props): JSX.Element {
  const stepComponents: JSX.Element[] = [];
  for (let i = 0; i < totalSteps; i++) {
    stepComponents.push(
      <div className={css.step} key={i}>
        <span className={classnames(css.bar, { [`${css[`bar__active`]}`]: currentStep >= i })} />
      </div>,
    );
  }

  return <div className={css.steps}>{stepComponents}</div>;
}

export default Steps;
