import { GetRecordSummaryDetailsRequestAttributeNamesEnum } from "lib/generated/app-api";

/**
 *  in this case the check is very simple - if it includes null (either on its own or in amongst
 *  other words) then we're excluding it
 *
 *  TODO: this could live elsewhere eg. model for storing responses in the DB
 */
export function usefulResponse(responseStr: string): boolean {
  return !responseStr.match(/\bnull\b/i);
}

export const ORDER_OF_SECTIONS_ON_PAGE: GetRecordSummaryDetailsRequestAttributeNamesEnum[] = [
  "thing_being_procured",
  "contract_value",
  "topic_relevance",
  "mentioned_parties",
  "bid_requirements",
];

export type SummaryValue = {
  loaded: boolean;
  content: string | null;
  attributeName: GetRecordSummaryDetailsRequestAttributeNamesEnum;
};

export const ATTRIBUTE_NAMES = {
  [GetRecordSummaryDetailsRequestAttributeNamesEnum.ContractValue]: "Contract structure",
  [GetRecordSummaryDetailsRequestAttributeNamesEnum.TopicRelevance]: "Tech stack mentioned",
  [GetRecordSummaryDetailsRequestAttributeNamesEnum.BidRequirements]: "Supplier requirements",
  [GetRecordSummaryDetailsRequestAttributeNamesEnum.MentionedParties]: "Organisations mentioned",
  [GetRecordSummaryDetailsRequestAttributeNamesEnum.RoutesToMarket]: "Framework info",
  [GetRecordSummaryDetailsRequestAttributeNamesEnum.IncumbentRelationships]:
    "Incumbent relationships",
  [GetRecordSummaryDetailsRequestAttributeNamesEnum.PreviousSimilarActivity]:
    "Previous similar activity",
  [GetRecordSummaryDetailsRequestAttributeNamesEnum.ThingBeingProcured]: "Product / service",
  [GetRecordSummaryDetailsRequestAttributeNamesEnum.Summary]: "Headline",
};
