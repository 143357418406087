import * as React from "react";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Button } from "antd5";
import { Skeleton } from "antd5/lib";

import { RecordDto } from "lib/generated/app-api";
import { useRecord } from "lib/hooks/api/useRecord";
import useElementInViewport from "lib/hooks/useElementInViewport";
import { RecordViewerEventData, useRecordViewer } from "lib/providers/RecordViewer";
import { EventNames, TrackingProvider, useTracking } from "lib/tracking";
import { getRecordSignals, getSignalTypes, recordFromDto } from "lib/types/models";
import RecordDetailsContent from "./RecordDetailsContent";
import RecordHeader from "./RecordHeader";

import css from "./RecordPreview.module.scss";

type RecordPreviewProps = {
  recordGuid: string;
  eventData?: RecordViewerEventData;
};

type RecordPreviewContentProps = {
  record: RecordDto;
  eventData?: RecordViewerEventData;
};

function RecordPreviewContainer(props: RecordPreviewProps) {
  const { data, isLoading } = useRecord(props.recordGuid);

  if (isLoading) {
    return (
      <div className={css.loadingSkeleton}>
        <Skeleton active title paragraph={{ rows: 5, width: "100%" }} />
        <Skeleton title={false} paragraph={{ rows: 10, width: "80%" }} />
        <Skeleton title={false} paragraph={{ rows: 10, width: "60%" }} />
      </div>
    );
  }

  if (!data) {
    return <div>No data found for this record.</div>;
  }

  return <RecordPreview record={data} eventData={props.eventData} />;
}

function RecordPreview({ record, eventData }: RecordPreviewContentProps) {
  const [topOfPageRef, topOfPageIsVisible] = useElementInViewport<HTMLDivElement>();

  const { closeRecord } = useRecordViewer();
  const { logEvent } = useTracking();

  const getSignals = (record: RecordDto) => {
    const recordDetails = recordFromDto(record);
    return getRecordSignals(recordDetails);
  };

  const signals = getSignals(record);
  const signalTypes = getSignalTypes(signals);
  const cpvCodes = record.cpvCodes.map((code) => `${code.code} - ${code.name}`);

  React.useEffect(() => {
    logEvent(EventNames.recordPreviewSlideoutOpened, {
      ...eventData,
      "Record guid": record.guid,
      "Signal score": record?.relevanceScore ?? "0",
      Stage: record.stage,
      "Signal types": signalTypes ? Object.keys(signalTypes) : [],
      "Signals.CPV codes": cpvCodes || [],
      Signals: signalTypes,
    });
    // Should log only once on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TrackingProvider
      data={{
        ...eventData,
        "Context source": `Record preview slide-out`,
        "Record GUID": record.guid,
        "Signal score": record?.relevanceScore ?? "0",
        Stage: record.stage,
        "Signal types": signalTypes ? Object.keys(signalTypes) : [],
        Signals: signalTypes,
      }}
    >
      <div className={css.previewWrapper}>
        <div className={css.controls}>
          <Button
            title="Close"
            icon={<DoubleRightOutlined className={css.closeIcon} />}
            onClick={closeRecord}
            className={css.controlsBtn}
          />
        </div>
        <div className={css.previewContent}>
          <RecordHeader
            record={record}
            trackingContextName="Record preview slide-out"
            disableTitleLink={!!window.guestUser}
            headerClassName={css.header}
            scrollClassName={css.scroll}
            isTopOfPageVisible={topOfPageIsVisible}
          />
          <div ref={topOfPageRef} />
          <div className={css.contentContainer}>
            <RecordDetailsContent
              record={record}
              containerClassName={css.columnsContainer}
              trackingContextName="Record preview slide-out"
            />
          </div>
        </div>
      </div>
    </TrackingProvider>
  );
}

export default RecordPreviewContainer;
