/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AllBuyersStatsStatsTotalRecordCount } from './AllBuyersStatsStatsTotalRecordCount';
import {
    AllBuyersStatsStatsTotalRecordCountFromJSON,
    AllBuyersStatsStatsTotalRecordCountFromJSONTyped,
    AllBuyersStatsStatsTotalRecordCountToJSON,
} from './AllBuyersStatsStatsTotalRecordCount';

/**
 * 
 * @export
 * @interface AllBuyersStatsStats
 */
export interface AllBuyersStatsStats {
    /**
     * 
     * @type {AllBuyersStatsStatsTotalRecordCount}
     * @memberof AllBuyersStatsStats
     */
    totalRecordCount: AllBuyersStatsStatsTotalRecordCount;
}

/**
 * Check if a given object implements the AllBuyersStatsStats interface.
 */
export function instanceOfAllBuyersStatsStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalRecordCount" in value;

    return isInstance;
}

export function AllBuyersStatsStatsFromJSON(json: any): AllBuyersStatsStats {
    return AllBuyersStatsStatsFromJSONTyped(json, false);
}

export function AllBuyersStatsStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllBuyersStatsStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalRecordCount': AllBuyersStatsStatsTotalRecordCountFromJSON(json['total_record_count']),
    };
}

export function AllBuyersStatsStatsToJSON(value?: AllBuyersStatsStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_record_count': AllBuyersStatsStatsTotalRecordCountToJSON(value.totalRecordCount),
    };
}

