/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyerDetails } from './BuyerDetails';
import {
    BuyerDetailsFromJSON,
    BuyerDetailsFromJSONTyped,
    BuyerDetailsToJSON,
} from './BuyerDetails';

/**
 * 
 * @export
 * @interface AdminBuyerListDetails
 */
export interface AdminBuyerListDetails {
    /**
     * 
     * @type {string}
     * @memberof AdminBuyerListDetails
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AdminBuyerListDetails
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminBuyerListDetails
     */
    ownerId: string;
    /**
     * 
     * @type {string}
     * @memberof AdminBuyerListDetails
     */
    sharingType: string;
    /**
     * 
     * @type {Date}
     * @memberof AdminBuyerListDetails
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof AdminBuyerListDetails
     */
    updatedAt: Date;
    /**
     * 
     * @type {Array<BuyerDetails>}
     * @memberof AdminBuyerListDetails
     */
    entries: Array<BuyerDetails>;
    /**
     * 
     * @type {boolean}
     * @memberof AdminBuyerListDetails
     */
    isDeleted: boolean;
}

/**
 * Check if a given object implements the AdminBuyerListDetails interface.
 */
export function instanceOfAdminBuyerListDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "ownerId" in value;
    isInstance = isInstance && "sharingType" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "entries" in value;
    isInstance = isInstance && "isDeleted" in value;

    return isInstance;
}

export function AdminBuyerListDetailsFromJSON(json: any): AdminBuyerListDetails {
    return AdminBuyerListDetailsFromJSONTyped(json, false);
}

export function AdminBuyerListDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminBuyerListDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'ownerId': json['owner_id'],
        'sharingType': json['sharing_type'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'entries': ((json['entries'] as Array<any>).map(BuyerDetailsFromJSON)),
        'isDeleted': json['is_deleted'],
    };
}

export function AdminBuyerListDetailsToJSON(value?: AdminBuyerListDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'owner_id': value.ownerId,
        'sharing_type': value.sharingType,
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'entries': ((value.entries as Array<any>).map(BuyerDetailsToJSON)),
        'is_deleted': value.isDeleted,
    };
}

