import React, { useMemo, useState } from "react";
import { FileTextOutlined } from "@ant-design/icons";

import { TextLink } from "components/actions/Links";
import SectionHeader from "components/reports/builder_components/SectionHeader";
import { getDocumentInfo } from "components/reports/utils";
import { PdfPreviewSlideout } from "lib/documents/PdfPreviewSlideout";
import { extractSearchTerms } from "lib/documents/search";
import { DocumentReferenceParameters } from "lib/generated/admin-api";
import { DocumentEditItem } from "lib/generated/admin-api/models/DocumentEditItem";
import { EventNames } from "lib/tracking";

import css from "./DocumentList.module.scss";

export type DocumentReferenceInfo = {
  url: string;
  searchTerm?: string;
};

type Props = {
  documents: DocumentEditItem[];
  getDocumentRef: (id: string) => DocumentReferenceParameters | undefined;
  title?: string;
  description?: string;
  id: string;
  reportId?: string;
};

function DocumentList({
  documents,
  title,
  description,
  id,
  reportId,
  getDocumentRef,
}: Props): JSX.Element {
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const searchTerms = useMemo(() => extractSearchTerms(search), [search]);

  return (
    <div className={css.documentListContainer} id={id}>
      <SectionHeader title={title} description={description} />
      <ul>
        {documents.map((doc) => {
          const documentRefInfo = getDocumentInfo(getDocumentRef(doc.ref));
          return (
            <li key={doc.ref}>
              <div className={css.imageContainer}>
                <FileTextOutlined />
              </div>
              <div className={css.textContainer}>
                <h3 className={css.title}>
                  <TextLink
                    // This is only undefined when the report has not been saved yet
                    // It is only used when the report is converted to a pdf
                    to={
                      reportId
                        ? `${window.location.origin}/reports/${reportId}/documents/${doc.ref}`
                        : ""
                    }
                    eventName={EventNames.reportListItemDocumentClicked}
                    eventAttributes={{ link: documentRefInfo?.url }}
                    targetType="external"
                    showIcon={false}
                    onClick={(e) => {
                      setPreview(documentRefInfo?.url);
                      setSearch(documentRefInfo?.searchTerm);
                      e.preventDefault();
                    }}
                  >
                    {doc.title}
                  </TextLink>
                </h3>
                {doc.description && <p className={css.description}>{doc.description}</p>}
                {doc.caption && <p className={css.caption}>{doc.caption}</p>}
              </div>
            </li>
          );
        })}
      </ul>
      <PdfPreviewSlideout
        documentUrl={preview}
        searchTerms={searchTerms}
        onSlideoutClose={() => setPreview(undefined)}
      />
    </div>
  );
}

export default DocumentList;
