import * as React from "react";
import { CheckCircleTwoTone, WarningTwoTone } from "@ant-design/icons";
import { Button, Collapse, message, Tooltip } from "antd5";

import { CandidateListPanel } from "components/organisation_clean/OrgListPanel";
import { OrgToBeCreated, OrgWithStats } from "components/organisation_clean/types";
import { copyToClipboard } from "lib/clipboard";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import StotlesVerifiedIcon from "lib/core_components/StotlesVerifiedIcon";
import { sysStatusPositive, sysWarningDefault } from "lib/themes/colors";
import { NewLabel } from "./OrgLabels";

import css from "./OrgRelationshipPreview.module.scss";

type Props = {
  targetOrgs: OrgWithStats[];
  newOrgs: OrgToBeCreated[];
  inactiveOrgs: OrgWithStats[];
  formStateStringified: string;
  orgPrimaryRole: "Buyer" | "Supplier";
};

/**
 * Component which allows user to see a preview orgs to be marked as inactive and possibly transferring responsibilities to
 * the targetOrgs or newOrgs
 */
function OrgRelationshipPreview({
  targetOrgs,
  newOrgs,
  inactiveOrgs,
  formStateStringified,
  orgPrimaryRole,
}: Props): JSX.Element {
  const renderOscarId = (org: OrgWithStats): JSX.Element => {
    if (org.oscar_id) {
      return <span className={css.oscarIdText}>Oscar ID: {org.oscar_id} </span>;
    } else return <></>;
  };

  return (
    <div className={css.page}>
      <div className={css.buyerRelationshipContent}>
        <h2>
          <IsVerifiedIcon orgs={inactiveOrgs} />
          {orgPrimaryRole} becoming inactive
        </h2>
        <CandidateListPanel
          orgPrimaryRole={orgPrimaryRole}
          orgs={inactiveOrgs}
          disableSelected={true}
          className={css.orgPreview}
          renderAdditionalContent={renderOscarId}
          hideCount
        />
        <Collapse
          className={css.formState}
          items={[
            {
              key: "formState",
              label: "Form state",
              extra: (
                <Button
                  size="small"
                  type="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToClipboard({ plain: formStateStringified });
                    void message.success("Copied formstate to clipbaord");
                  }}
                >
                  Copy to clipboard
                </Button>
              ),
              children: formStateStringified,
            },
          ]}
        />
      </div>
      <div className={css.buyerRelationshipContent}>
        <h2>{orgPrimaryRole} to transfer responsibility to</h2>
        <h3>
          <IsVerifiedIcon orgs={targetOrgs} />
          Existing {orgPrimaryRole}s
        </h3>
        {targetOrgs.length > 0 && (
          <CandidateListPanel
            orgPrimaryRole={orgPrimaryRole}
            orgs={targetOrgs}
            disableSelected={true}
            className={css.orgPreview}
            renderAdditionalContent={renderOscarId}
            hideCount
          />
        )}

        <h3>
          <IsVerifiedIcon orgs={targetOrgs} />
          New {orgPrimaryRole}s
        </h3>
        <div className={css.listContainer}>
          <ul>
            {newOrgs.map((org) => (
              <li className={css.titleContainer} key={org.guid}>
                <EllipsisTooltipText fullText={org.name} containerClassname={css.title} />{" "}
                <NewLabel />
                {org.is_stotles_verified && <StotlesVerifiedIcon />}
                {org.oscar_id && <span className={css.oscarIdText}>Oscar ID: {org.oscar_id} </span>}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

type IsVerifiedIconProps<Org extends OrgWithStats | OrgToBeCreated> = {
  orgs: Org[];
};

function IsVerifiedIcon<Org extends OrgWithStats | OrgToBeCreated>({
  orgs,
}: IsVerifiedIconProps<Org>): JSX.Element | null {
  if (orgs.length === 0) {
    return null;
  }

  const unverifiedOrgNames = orgs.filter((org) => !org.is_stotles_verified).map((org) => org.name);

  return unverifiedOrgNames.length > 0 ? (
    <Tooltip title={`Unverified organisations: ${unverifiedOrgNames.join(", ")}`}>
      <WarningTwoTone twoToneColor={sysWarningDefault} style={{ marginRight: "8px" }} />
    </Tooltip>
  ) : (
    <Tooltip title="All organisations verfified">
      <CheckCircleTwoTone twoToneColor={sysStatusPositive} style={{ marginRight: "8px" }} />
    </Tooltip>
  );
}

export default OrgRelationshipPreview;
