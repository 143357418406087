import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { CompanyDto } from "lib/generated/admin-api/models";
import { useAdminApi } from "lib/stotlesAdminApiContext";

type QueryKey = [string, string];

export function useDescribeCompany(
  guid: string,
  options?: UseQueryOptions<CompanyDto, unknown, CompanyDto, QueryKey>,
) {
  const { api } = useAdminApi();
  return useQuery(
    ["companyDetails", guid],
    async () => {
      const response = await api.describeCompany({ describeCompanyRequest: { guid } });
      return response.company;
    },
    options,
  );
}
