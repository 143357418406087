/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamManagementUsersRequest
 */
export interface TeamManagementUsersRequest {
    /**
     * 
     * @type {string}
     * @memberof TeamManagementUsersRequest
     */
    sort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamManagementUsersRequest
     */
    sortOrder?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TeamManagementUsersRequest
     */
    limit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TeamManagementUsersRequest
     */
    offset?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TeamManagementUsersRequest
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamManagementUsersRequest
     */
    teamId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TeamManagementUsersRequest
     */
    paymentType?: string | null;
}

/**
 * Check if a given object implements the TeamManagementUsersRequest interface.
 */
export function instanceOfTeamManagementUsersRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TeamManagementUsersRequestFromJSON(json: any): TeamManagementUsersRequest {
    return TeamManagementUsersRequestFromJSONTyped(json, false);
}

export function TeamManagementUsersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamManagementUsersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'sortOrder': !exists(json, 'sort_order') ? undefined : json['sort_order'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'teamId': !exists(json, 'teamId') ? undefined : json['teamId'],
        'paymentType': !exists(json, 'paymentType') ? undefined : json['paymentType'],
    };
}

export function TeamManagementUsersRequestToJSON(value?: TeamManagementUsersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sort': value.sort,
        'sort_order': value.sortOrder,
        'limit': value.limit,
        'offset': value.offset,
        'text': value.text,
        'teamId': value.teamId,
        'paymentType': value.paymentType,
    };
}

