/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RuleResultsResponseNoticesResultsInner } from './RuleResultsResponseNoticesResultsInner';
import {
    RuleResultsResponseNoticesResultsInnerFromJSON,
    RuleResultsResponseNoticesResultsInnerFromJSONTyped,
    RuleResultsResponseNoticesResultsInnerToJSON,
} from './RuleResultsResponseNoticesResultsInner';

/**
 * 
 * @export
 * @interface RuleResultsResponseNotices
 */
export interface RuleResultsResponseNotices {
    /**
     * 
     * @type {Array<RuleResultsResponseNoticesResultsInner>}
     * @memberof RuleResultsResponseNotices
     */
    results: Array<RuleResultsResponseNoticesResultsInner>;
}

/**
 * Check if a given object implements the RuleResultsResponseNotices interface.
 */
export function instanceOfRuleResultsResponseNotices(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function RuleResultsResponseNoticesFromJSON(json: any): RuleResultsResponseNotices {
    return RuleResultsResponseNoticesFromJSONTyped(json, false);
}

export function RuleResultsResponseNoticesFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleResultsResponseNotices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(RuleResultsResponseNoticesResultsInnerFromJSON)),
    };
}

export function RuleResultsResponseNoticesToJSON(value?: RuleResultsResponseNotices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(RuleResultsResponseNoticesResultsInnerToJSON)),
    };
}

