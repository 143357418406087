import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import {} from "lib/data_providers/UserDataProvider";
import { GetRecordListsResponseResult } from "lib/generated/app-api";
import { useStotlesApi } from "lib/stotlesApiContext";
import { EventDataTypes, EventNames, useTracking } from "lib/tracking";
import { BasicSignal, getSignalTypes } from "lib/types/models";
import { updateCachedNotices, updateCachedRecord } from "../utils";

type Params = {
  list: GetRecordListsResponseResult;
  recordGuid: string;
  recordStage: string;
  signals: BasicSignal[];
  contextSource: string;
};

export function useRemoveNoticeFromList(
  options?: UseMutationOptions<unknown, unknown, Params, unknown>,
) {
  const api = useStotlesApi();
  const { logEvent } = useTracking();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ list, recordGuid }: Params) => api.removeRecordFromList(list.id, recordGuid),
    {
      ...options,
      onSuccess: async (data, vars, ctx) => {
        await queryClient.invalidateQueries(["noticeLists"]);
        const { list, contextSource, recordStage, recordGuid } = vars;
        const signalTypes = getSignalTypes(vars.signals);

        logEvent(EventNames.recordRemovedFromList, {
          "List name": list.name,
          "List id": list.id,
          "Data type": EventDataTypes.recordList,
          "Context source": contextSource,
          Stage: recordStage,
          "Signal types": signalTypes ? Object.keys(signalTypes) : [],
          Signals: signalTypes,
        });

        // Update the cache with the new qualification
        updateCachedRecord(queryClient, recordGuid, (r) => ({
          ...r,
          lists: r.lists.filter((l) => l.id !== list.id),
        }));

        updateCachedNotices(queryClient, recordGuid, (r) => ({
          ...r,
          lists: r.lists.filter((l) => l.id !== list.id),
        }));

        options?.onSuccess?.(data, vars, ctx);
      },
    },
  );
}
