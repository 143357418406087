import React from "react";
import { Button } from "antd5";
import { ActiveSupplierTab } from "pages/app/SupplierSearchPage";

import css from "./SavedSuppliersEmpty.module.scss";

import EmptySavedSuppliers from "../../../assets/images/empty_saved_suppliers.svg";

type Props = {
  activeTab: ActiveSupplierTab.COMPETITORS | ActiveSupplierTab.PARTNERS;
  setActiveTab: (tab: ActiveSupplierTab) => void;
};

export function SavedSuppliersEmpty({ activeTab, setActiveTab }: Props) {
  const supplierType = activeTab === ActiveSupplierTab.COMPETITORS ? "competitor" : "partner";
  return (
    <div className={css.saveSupplierEmptyState}>
      <img src={EmptySavedSuppliers} />
      <div className={css.content}>
        <h2>Getting started with {supplierType}s</h2>
        <p>
          Tracking your {supplierType}s' activity will give insight on their existing contracts and
          upcoming expiries, which you can use to be more reactive to upcoming tenders. Saving a
          supplier as a {supplierType} is easy, here's how:
        </p>
        <ol className={css.list}>
          <li>Go to a supplier that you want to save as a {supplierType}.</li>
          <li>Click 'Save supplier', and in the dropdown select 'My {supplierType}s.'</li>
          <li>
            You can also do this via the signal settings. Go to the {supplierType} section in
            signals and search through our suppliers to add them to the your signals.
          </li>
        </ol>
        <div className={css.btnContainer}>
          <Button
            type="primary"
            onClick={() => {
              setActiveTab(ActiveSupplierTab.ALL_SUPPLIERS);
            }}
          >
            View all suppliers
          </Button>
          <Button href="/account-management/feed-settings">
            <span className={css.btnText}>Go to signal settings</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
