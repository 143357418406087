import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { DropdownMenuSeparator } from "@radix-ui/react-dropdown-menu";

import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { Text } from "../../../styles/utility-components";

type DropdownMenuItemProps = {
  seperator?: boolean;
  icon?: JSX.Element;
  path?: string;
  onClick?: () => void;
  children?: string | ReactNode;
};

function DropdownMenuItem({ seperator, icon, path, onClick, children }: DropdownMenuItemProps) {
  if (seperator) {
    return <Seperator />;
  }

  return (
    <Wrapper as={path ? "a" : "div"} href={path} onClick={onClick}>
      {icon && <UIcon svg={icon} size={16} color={"inherit"} />}
      {typeof children === "string" ? (
        <Text fontWeight="500" color="inherit" removeLineHeight>
          {children}
        </Text>
      ) : (
        children
      )}
    </Wrapper>
  );
}

export default DropdownMenuItem;

const Wrapper = styled.a(({ theme }) => ({
  height: 32,
  width: "100%",
  padding: "0 8px",
  display: "flex",
  alignItems: "center",
  gap: 8,
  cursor: "pointer",
  borderRadius: 4,
  transition: "background-color 0.3s",
  color: theme.colors.sysTextDefault,

  "&:hover": {
    backgroundColor: theme.colors.sysPrimarySubtle,
    color: theme.colors.sysPrimaryHover,
    outline: "none",
  },
}));

const Seperator = styled(DropdownMenuSeparator)(({ theme }) => ({
  height: 1,
  backgroundColor: theme.colors.sysBorderPrimary,
  margin: "4px 0",
}));
