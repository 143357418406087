import { isEqual, pickBy } from "lodash";

import { EventDataTypes, EventNames, Tracking, useTracking } from "lib/tracking";
import { isEmptyDeep } from "lib/utils";
import { SupplierFilters } from "./utils";

const trackingEvents = (tracking: Tracking) => ({
  filterDrawerOpened: () =>
    tracking.logEvent(EventNames.filterPanelOpened, {
      "Context source": "Filters button",
      "Data type": "Suppliers",
    }),
  freeSearchLog: () =>
    tracking.logEvent(EventNames.freeSearch, {
      "Filter name": "Free search",
      "Action type": "Filter applied",
      "Data type": EventDataTypes.supplier,
    }),
  filterFormChanged: (previous: SupplierFilters, current: SupplierFilters) => {
    const diff = pickBy(current, (value, key) => {
      const previousVal = previous[key as keyof SupplierFilters];
      // If this check isn't present, the tracking code is triggered for empty filters also
      if (isEmptyDeep(previousVal) && isEmptyDeep(value)) return false;
      return !isEqual(previous[key as keyof SupplierFilters], value);
    });

    const events = Object.entries(diff).map(([key, value]) => ({
      name: EventNames.filterActioned,
      data: {
        "Context source": "Filter slide-out",
        "Filter name": key,
        "Filter selection": value,
        "Action type":
          Array.isArray(value) && value.length === 0 ? "Filter cleared" : "Filter applied",
        "Data type": EventDataTypes.supplier,
      },
    }));

    tracking.logEvents(...events);
  },
});

export function useSuppliersTracking() {
  const tracking = useTracking();
  return trackingEvents(tracking);
}
