import * as React from "react";
import { CloseOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Popover, PopoverProps } from "antd5";
import linkify from "linkify-it";

import { ScreenSize, useResponsiveScreenSize } from "lib/providers/Responsive";
import { RecordMatchInfo } from "lib/qued/queryRunner";
import * as tracking from "lib/tracking";
import { RecordDetails, RecordPreview } from "lib/types/models";
import { defaultStopEventPropagation, recordDetailsUrl, stopEventPropagation } from "lib/utils";
import {
  MatchType,
  TextHighlighterMatch,
  TextMatchHighlighter,
} from "../../lib/core_components/TextMatchHighlighter";
import { TextLink } from "./Links";

import css from "./ViewDescription.module.scss";

type Props = {
  record: RecordDetails | RecordPreview;
  queryMatchInfo?: RecordMatchInfo;
  onClickIcon?: (e: React.MouseEvent<HTMLAnchorElement & HTMLButtonElement, MouseEvent>) => void;
  onClickClose?: (e: React.MouseEvent<HTMLAnchorElement & HTMLButtonElement, MouseEvent>) => void;
  onClickTitle?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  disableNavigation?: boolean;
  triggerLabel?: string;
  popoverPlacement?: PopoverProps["placement"];
};

// allow users to hover to preview the popup, and click to have it persist
function ViewDescription({
  record,
  queryMatchInfo,
  onClickIcon,
  onClickClose,
  onClickTitle,
  disableNavigation,
  triggerLabel,
  popoverPlacement = "left",
}: Props) {
  const { logEvent } = tracking.useTracking();
  const [clicked, setClicked] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);
  const [visibleStart, setVisibleStart] =
    React.useState<Partial<Record<"hover" | "click", Date> | undefined>>(undefined);
  const screenSize = useResponsiveScreenSize();
  const handleClose = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement & HTMLButtonElement, MouseEvent>) => {
      setHovered(false);
      setClicked(false);
      if (onClickClose) onClickClose(e);
    },
    [onClickClose],
  );

  const handleVisibilityTimer = React.useCallback(
    (isBecomingVisible: boolean, trigger: "hover" | "click") => {
      if (isBecomingVisible && (!visibleStart || !visibleStart[trigger])) {
        setVisibleStart({ [trigger]: new Date() });
      } else if (!isBecomingVisible && visibleStart && visibleStart[trigger]) {
        const durationMS = new Date().valueOf() - (visibleStart[trigger] as Date).valueOf();
        setVisibleStart(undefined);
        logEvent(tracking.EventNames.descriptionBoxOpened, {
          "Open for (secs)": durationMS / 1000,
          Trigger: trigger,
          "Record GUID": record.guid,
        });
      }
    },
    [logEvent, record.guid, visibleStart],
  );

  const handleClickClose = React.useMemo(() => stopEventPropagation(handleClose), [handleClose]);
  const handleClickIcon = React.useMemo(() => stopEventPropagation(onClickIcon), [onClickIcon]);
  const handleClickTitle = React.useMemo(() => stopEventPropagation(onClickTitle), [onClickTitle]);

  const recordUrl = recordDetailsUrl(record.guid, undefined);

  const description = React.useMemo(() => {
    let descriptionMatches = [] as TextHighlighterMatch[];
    if (queryMatchInfo) {
      // map and filter
      descriptionMatches = queryMatchInfo.keyword_matches.reduce<TextHighlighterMatch[]>(
        (collection, match) => {
          if (match.field === "description") {
            collection.push({ ...match, matchType: MatchType.Keyword });
          }
          return collection;
        },
        [],
      );

      if (record.description) {
        const descriptionLinks = linkify()
          .match(record.description)
          ?.map((m) => ({
            start: m.index,
            end: m.lastIndex,
            keyword: m.text,
            matchType: MatchType.Link,
          }));
        if (descriptionLinks) {
          descriptionMatches = descriptionMatches.concat(descriptionLinks);
        }
      }
    }
    return (
      <div className={css.popoverContent} onClick={defaultStopEventPropagation}>
        {record.description && (
          <p>
            {descriptionMatches.length ? (
              <TextMatchHighlighter text={record.description} matches={descriptionMatches} />
            ) : (
              record.description
            )}
          </p>
        )}
      </div>
    );
  }, [queryMatchInfo, record.description]);

  const titleContent = React.useMemo(() => {
    if (queryMatchInfo) {
      const titleMatches = queryMatchInfo.keyword_matches.filter((m) => m.field === "title");
      if (titleMatches.length > 0) {
        return <TextMatchHighlighter text={record.name} matches={titleMatches} />;
      }
    }
    return record.name;
  }, [record.name, queryMatchInfo]);

  const title = (
    <div className={css.titleBar} onClick={defaultStopEventPropagation}>
      {disableNavigation ? (
        <h4 className={css.title}>{titleContent}</h4>
      ) : (
        <TextLink
          className={css.title}
          to={recordUrl}
          onClick={handleClickTitle}
          targetType="new-tab"
        >
          {titleContent}
        </TextLink>
      )}
      <Button onClick={handleClickClose} icon={<CloseOutlined />} />
    </div>
  );

  return (
    <Popover
      content={description}
      title={title}
      trigger="hover"
      open={hovered}
      onOpenChange={(visible: boolean) => {
        handleVisibilityTimer(visible, "hover");
        setHovered(visible);
        setClicked(false);
      }}
      placement={popoverPlacement}
      overlayClassName={css.popover}
    >
      <Popover
        content={description}
        title={title}
        trigger="click"
        open={clicked}
        onOpenChange={(visible: boolean) => {
          handleVisibilityTimer(visible, "click");
          setClicked(visible);
          setHovered(false);
        }}
        placement={popoverPlacement}
        overlayClassName={css.popover}
      >
        <Button type="primary" ghost onClick={handleClickIcon} icon={<EyeOutlined />}>
          {triggerLabel || (screenSize > ScreenSize.LG && "Preview")}
        </Button>
      </Popover>
    </Popover>
  );
}

export default React.memo(ViewDescription);
