import * as React from "react";
import { Button, Mentions } from "antd5";
import { MentionsRef } from "antd5/es/mentions";

import TextButton from "components/actions/TextButton";
import { assertCurrentUser } from "lib/currentUser";
import { CommentDto } from "lib/generated/app-api";
import { useCreateComment } from "lib/hooks/api/notices/comments/useCreateComment";
import { useUpdateComment } from "lib/hooks/api/notices/comments/useUpdateComment";
import { useUsers } from "lib/hooks/api/useUsers";
import {
  convertMarkdownToText,
  convertTextToMarkdown,
  getDisplayUserName,
} from "../../lib/comment_utils";
import UserInitials from "./UserInitials";

import css from "./CommentInput.module.scss";

type Props = {
  // if the current input is editing a existing comment
  comment?: CommentDto;
  onSave?: () => void;
  onCancel?: () => void;
  procurementStageId: string;
  recordGuid: string;
};

const MIN_TEXTAREA_HEIGHT = 32;

function CommentInput({
  comment,
  procurementStageId,
  recordGuid,
  onCancel,
  onSave,
}: Props): JSX.Element {
  const user = assertCurrentUser();
  const { data: users } = useUsers();

  const [value, setValue] = React.useState<string>(
    comment ? convertMarkdownToText(comment.content) : "",
  );
  const [cancelling, setCancelling] = React.useState<boolean>(false);
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const { mutate: createComment, isLoading: isCreating } = useCreateComment({
    onSuccess: () => {
      setValue("");
      setIsInputFocused(false);
      onSave?.();
    },
  });
  const { mutate: updateComment, isLoading: isUpdating } = useUpdateComment({
    onSuccess: () => {
      setIsInputFocused(false);
      onSave?.();
    },
  });

  const ref = React.useRef<MentionsRef>(null);
  // Can be removed with rc-mentions 2.5.0 which is 3 years old now but ant depend on ~2.3.0 :(
  React.useEffect(() => {
    // ant hack to grab the actual text area that is being rendered
    // rcMentions is not accessible but we need to work with the textarea height
    // to make sure we enable scrolling after 5 lines of text is entered.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const textArea = ref.current.textarea;
    // Reset height - important to shrink on delete
    if (textArea) {
      textArea.style.height = "inherit";
      textArea.style.height = `${Math.max(textArea.scrollHeight, MIN_TEXTAREA_HEIGHT)}px`;
    }
  }, [value]);

  const handleCancel = React.useCallback(() => {
    setValue("");
    setCancelling(false);
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  const handleOnSave = () => {
    const content = convertTextToMarkdown(value, users);

    if (comment) {
      updateComment({ id: comment.id, content, procurementStageId });
    } else {
      createComment({ procurementStageId, content, recordGuid });
    }
  };

  return (
    <div className={css.commentInput}>
      <div className={css.inputContainer}>
        <UserInitials firstName={user.first_name} lastName={user.last_name} />
        <Mentions
          ref={ref}
          className={css.textInput}
          value={value}
          placeholder="Add a comment..."
          onFocus={() => setIsInputFocused(true)}
          onChange={(e) => setValue(e)}
          options={users
            ?.filter((u) => u.guid !== user.guid && u.active)
            .map((u) => ({
              value: getDisplayUserName(u),
              label: getDisplayUserName(u),
            }))}
        />
      </div>

      {cancelling ? (
        <div className={css.cancelContainer}>
          <p>Cancel? The text will be deleted</p>
          <TextButton onClick={() => setCancelling(false)}>No, continue writing</TextButton>
          <TextButton danger onClick={handleCancel}>
            Yes, cancel
          </TextButton>
        </div>
      ) : (
        (isInputFocused || comment) && (
          <div className={css.saveContainer}>
            <span className={css.mentionHelp}>
              Tip: use "@" to tag and notify people in your team
            </span>
            <TextButton
              onClick={() => {
                setCancelling(true);
              }}
            >
              Cancel
            </TextButton>
            <Button onClick={handleOnSave} type="primary" loading={isCreating || isUpdating}>
              Save
            </Button>
          </div>
        )
      )}
    </div>
  );
}

export default CommentInput;
