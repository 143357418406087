/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NoticeMappingDetails
 */
export interface NoticeMappingDetails {
    /**
     * 
     * @type {string}
     * @memberof NoticeMappingDetails
     */
    noticeGuid: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeMappingDetails
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeMappingDetails
     */
    externalUrl?: string;
}

/**
 * Check if a given object implements the NoticeMappingDetails interface.
 */
export function instanceOfNoticeMappingDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "noticeGuid" in value;

    return isInstance;
}

export function NoticeMappingDetailsFromJSON(json: any): NoticeMappingDetails {
    return NoticeMappingDetailsFromJSONTyped(json, false);
}

export function NoticeMappingDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoticeMappingDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'noticeGuid': json['noticeGuid'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'externalUrl': !exists(json, 'externalUrl') ? undefined : json['externalUrl'],
    };
}

export function NoticeMappingDetailsToJSON(value?: NoticeMappingDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'noticeGuid': value.noticeGuid,
        'externalId': value.externalId,
        'externalUrl': value.externalUrl,
    };
}

