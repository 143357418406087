/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuppliersV3Request
 */
export interface SuppliersV3Request {
    /**
     * 
     * @type {string}
     * @memberof SuppliersV3Request
     */
    sort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuppliersV3Request
     */
    sortOrder?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuppliersV3Request
     */
    limit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuppliersV3Request
     */
    offset?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SuppliersV3Request
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SuppliersV3Request
     */
    partners?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SuppliersV3Request
     */
    competitors?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SuppliersV3Request
     */
    kind: string;
    /**
     * 
     * @type {string}
     * @memberof SuppliersV3Request
     */
    supplierName?: string | null;
}

/**
 * Check if a given object implements the SuppliersV3Request interface.
 */
export function instanceOfSuppliersV3Request(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "kind" in value;

    return isInstance;
}

export function SuppliersV3RequestFromJSON(json: any): SuppliersV3Request {
    return SuppliersV3RequestFromJSONTyped(json, false);
}

export function SuppliersV3RequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuppliersV3Request {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'sortOrder': !exists(json, 'sort_order') ? undefined : json['sort_order'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'partners': !exists(json, 'partners') ? undefined : json['partners'],
        'competitors': !exists(json, 'competitors') ? undefined : json['competitors'],
        'kind': json['kind'],
        'supplierName': !exists(json, 'supplier_name') ? undefined : json['supplier_name'],
    };
}

export function SuppliersV3RequestToJSON(value?: SuppliersV3Request | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sort': value.sort,
        'sort_order': value.sortOrder,
        'limit': value.limit,
        'offset': value.offset,
        'keywords': value.keywords,
        'partners': value.partners,
        'competitors': value.competitors,
        'kind': value.kind,
        'supplier_name': value.supplierName,
    };
}

