import React from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { message } from "antd5";

import { ViewInCRMButton } from "components/integrations/ProviderButtons";
import { friendlyProviderName } from "components/integrations/util";
import { ProviderTypes, ResponseError, SendNoticeResponse } from "lib/generated/integration-api";
import { useIntegrationApi } from "lib/integrationApiContext";
import { TrackingProvider } from "lib/tracking";

type RequestParams = {
  recordGuid: string;
  data: Record<string, unknown>;
};

export function useSendNotice(
  providerType: ProviderTypes,
  noticeGuid: string,
  noticeName: string,
  options?: UseMutationOptions<SendNoticeResponse, unknown, RequestParams, unknown>,
) {
  const api = useIntegrationApi();
  const queryClient = useQueryClient();

  const providerName = friendlyProviderName[providerType];
  const shortenedRecordName = noticeName.slice(0, 30);
  const messageKey = "crmNotice";

  return useMutation(
    ({ recordGuid, data }: RequestParams) =>
      api.sendNotice({
        sendNoticeRequest: { recordId: recordGuid, fields: JSON.stringify(data) },
      }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
        // The externalURL is currently an optional return because we don't want to make it required on the datamodel
        // This is to hedge for the future where we may integrate with messaging services
        void message.success({
          content: data.externalUrl ? (
            <TrackingProvider
              data={{
                "Context source": "Pop-up message top screen",
                "Connected provider": providerType,
              }}
            >
              <b>{shortenedRecordName}...</b> sent to {providerName}
              {"  "}
              <ViewInCRMButton providerType={providerType} externalUrl={data.externalUrl} />
            </TrackingProvider>
          ) : (
            `Successfully sent to ${providerName}`
          ),
          duration: 5,
        });
      },
      onError: (err: unknown) => {
        if (err instanceof ResponseError && err.response.status === 400) {
          void message.error(
            "Please contact team@stotles.com to modify your CRM settings. The form is out of sync with your CRM.",
            8,
          );
        } else {
          void message.error("Something went wrong sending the notice to the CRM");
        }
      },
      onSettled: () => {
        message.destroy(messageKey);
        void queryClient.invalidateQueries(["noticeDetails", noticeGuid]);
      },
      onMutate: () => {
        void message.loading({
          key: messageKey,
          content: (
            <>
              Sending <b>{shortenedRecordName}...</b> to {providerName}
            </>
          ),
          duration: 0,
        });
      },
    },
  );
}
