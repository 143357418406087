import React from "react";
import { Button, TableProps } from "antd5";

import AsyncExportButton from "components/actions/AsyncExportButton";
import { LimitedExportButton } from "components/actions/ExportButton";
import { createHandleTableRowClickthrough } from "lib/core_components/commonTableItems";
import { Table } from "lib/core_components/Table";
import { ColumnType } from "lib/core_components/Table/ColumnTypes";
import { createUseDebounce } from "lib/debounce";
import { ProHelperDataTypes } from "lib/providers/ProHelper";
import { SearchParams, SortState } from "lib/search/types";
import StotlesAPI from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";
import * as tracking from "lib/tracking";
import { assertDefined, KeysToSnakeCase, pluralise } from "lib/utils";

import css from "./SearchTable.module.scss";

type PagingState = {
  total: number;
  currentOffset: number;
  currentPage: number;
};

type TableChangeCallback<T> = NonNullable<TableProps<T>["onChange"]>;

export type TypedColumnProps<T> = ColumnType<T> & {
  dataIndex?: keyof T;
};

type CommonSearchAPIParams = KeysToSnakeCase<SortPaginationParams>;

export type SortPaginationParams = {
  offset?: number;
  limit?: number;
  sort?: string;
  sortOrder?: "ASC" | "DESC";
};

type SearchAPIResponse<ResultType> = {
  results: ResultType[];
  paging_info: {
    total_results: number;
    offset: number;
    limit: number | null;
    next_offset: number | null;
  };
};

export type SearchCallback<Filters extends Record<string, unknown>, ResultType> = (
  api: StotlesAPI,
  commonParams: CommonSearchAPIParams,
  searchParams: SearchParams<Filters>,
) => Promise<SearchAPIResponse<ResultType>>;

export type ExportCallback<Filters extends Record<string, unknown>> = (
  api: StotlesAPI,
  commonParams: CommonSearchAPIParams,
  searchParams: SearchParams<Filters>,
  format: "xlsx" | "csv",
) => Promise<[string, Blob]>;

type AsyncExportCallback<Filters extends Record<string, unknown>> = (
  commonParams: CommonSearchAPIParams,
  searchParams: SearchParams<Filters>,
  format: "xlsx" | "csv",
) => Promise<{
  jobId: string;
  downloadUrl: string;
}>;

type ColumnCallback<Filters extends Record<string, unknown>, ResultType> = (
  params: SearchParams<Filters>,
) => ColumnType<ResultType>[];

/**
 * A note on scroll: x and y values represent a minimum dimension,
 * beyond which the table will scroll (whilst preserving widths).
 * passing `true` means columns (but not rows) can be collapsed
 */
interface Props<Filters extends Record<string, unknown>, ResultType> {
  searchParams: SearchParams<Filters>;
  onSearchParamsChange: (newParams: SearchParams<Filters>) => void;
  onSearch: SearchCallback<Filters, ResultType>;
  onExport?: ExportCallback<Filters>;
  onAsyncExport?: AsyncExportCallback<Filters>;
  maxResultsToExport?: number;
  generateColumns: ColumnCallback<Filters, ResultType>;
  scroll?: TableProps<ResultType>["scroll"]; // see note above
  rowKey: keyof ResultType;
  getRowClickthroughLink?: (r: ResultType) => string;
  debounceSearchRequests?: boolean;
  exportedDataType: tracking.EventDataTypes;
  pageSize?: number;
  trackingSearchType?: "Buyers" | "Suppliers" | "Records";
  dataType?: ProHelperDataTypes;
  onRowAction?: (r: ResultType) => React.HTMLAttributes<HTMLElement>;
}

const COLUMN_HEADER_HEIGHT = 55;
// top bar + "results bar" + column headers
const OUTER_CONTENT_HEIGHT = 64 + 54 + COLUMN_HEADER_HEIGHT;
// this is just an initial guess
const calcInnerTableHeight = () => window.innerHeight - OUTER_CONTENT_HEIGHT;

// This is somewhat arbitrary, under 4k we can still deliver the records to the user in a reasonable time
const ASYNC_EXPORT_THRESHOLD = 4000;

const useDebounce400 = createUseDebounce(400);

/**
 * @deprecated This table is legacy for and only used in admin panel
 */
function SearchTableComponent<Filters extends Record<string, unknown>, ResultType extends object>({
  generateColumns,
  onSearch,
  onSearchParamsChange,
  onExport,
  maxResultsToExport,
  searchParams,
  scroll,
  rowKey,
  getRowClickthroughLink,
  debounceSearchRequests,
  exportedDataType,
  pageSize,
  trackingSearchType,
  dataType,
  onRowAction,
  onAsyncExport,
}: Props<Filters, ResultType>) {
  const api = useStotlesApi();
  // Used to track request sequence, so we can discard results for requests coming out of order.
  const requestSeqNoRef = React.useRef(1);
  // We also need to store the table elems as refs so we can scroll to top when filters change
  // antd uses to separate columns with separate scrolls for regular and fixed columns
  // so we need to store both...
  const tableScrollElemRef = React.useRef<HTMLElement[] | null>(null);

  // We also need to capture the ref to the table container to get it's height
  const tableContainerRef = React.useRef<HTMLElement | null>(null);

  // controls the loading indicator for infinite scroll
  const [isLoadingNextPage, setIsLoadingNextPage] = React.useState(false);
  // controls the loading indicator of the whole table
  const [isLoadingNewSearch, setIsLoadingNewSearch] = React.useState(false);

  const [nextPage, _setNextPage] = React.useState<number | null>(1);
  // use a ref so the latest nextPage can be accessed in an event listener
  const nextPageRef = React.useRef(nextPage);
  const setNextPage = (data: number | null) => {
    nextPageRef.current = data;
    _setNextPage(data);
  };

  const [pagingState, setPagingState] = React.useState<PagingState>({
    total: 0,
    currentOffset: 0,
    currentPage: 1,
  });

  const [rows, setRows] = React.useState<ResultType[] | undefined>(undefined);

  // note calcInnerTableHeight function itself passed (not called)
  const [innerTableHeight, setInnerTableHeight] = React.useState(calcInnerTableHeight);

  // If page == 1 then reset results
  const fetchResults = React.useCallback(
    async (page: number, searchParams: SearchParams<Filters>) => {
      requestSeqNoRef.current = requestSeqNoRef.current + 1;
      const thisRequestSeqNo = requestSeqNoRef.current;

      const requestPageSize = pageSize || 20;
      const queryParams: CommonSearchAPIParams = {};
      queryParams.offset = requestPageSize * (page - 1);
      queryParams.limit = requestPageSize;

      const { sort } = searchParams;
      if (sort) {
        queryParams.sort = sort.field;
        queryParams.sort_order = sort.order;
      }

      setIsLoadingNextPage(true);

      const response = await onSearch(api, queryParams, searchParams);

      // Was another request fired in the meantime?
      if (requestSeqNoRef.current > thisRequestSeqNo) {
        return;
      }

      if (page == 1) {
        setRows(response.results);
      } else {
        setRows([...(rows || []), ...response.results]);
      }

      setIsLoadingNextPage(false);
      setIsLoadingNewSearch(false);
      if (response.paging_info.next_offset === null) setNextPage(null);

      setPagingState({
        total: response.paging_info.total_results,
        currentOffset: response.paging_info.offset,
        currentPage: page,
      });
    },
    [onSearch, api, rows, pageSize],
  );

  const debouncedFetchResults = useDebounce400(fetchResults);

  React.useEffect(() => {
    if (trackingSearchType) {
      tracking.logEvent(tracking.EventNames.searchPerformed, {
        ...searchParams,
        "Search type": trackingSearchType,
      });
    }
    setNextPage(1);
    if (tableScrollElemRef.current) {
      for (const tableElem of tableScrollElemRef.current) {
        if (tableElem) tableElem.scrollTop = 0;
      }
    }
    setIsLoadingNewSearch(true);
    if (debounceSearchRequests) {
      debouncedFetchResults(1, searchParams);
    } else {
      void fetchResults(1, searchParams);
    }
    // TODO: if this effect depends on fetchResults it can get into infinite loop of fetches
    // There are better solutions than disableing the eslint warning, but the current solution
    // shouldn't cause any issues unless `onSearch` or `api` changes
    // eslint-disable-next-line
  }, [searchParams, pageSize]);

  React.useEffect(() => {
    if (nextPage && nextPage !== pagingState.currentPage) {
      void fetchResults(nextPage, searchParams);
      setIsLoadingNextPage(true);
    }
    // See comment above for why we don't specify `fetchResults` in deps.
    // eslint-disable-next-line
  }, [nextPage]);

  const columns = React.useMemo(() => {
    const columns = generateColumns(searchParams);
    const sort = searchParams.sort;
    return columns.map((c) => {
      let sortOrder: "ascend" | "descend" | undefined;
      if (sort && c.key === sort.field) {
        sortOrder = sort.order === "ASC" ? "ascend" : "descend";
      } else {
        sortOrder = undefined;
      }
      return { ...c, sortOrder };
    });
  }, [generateColumns, searchParams]);

  const handleTableChange = React.useCallback<TableChangeCallback<ResultType>>(
    (_pagination, _filters, sorter) => {
      if (Array.isArray(sorter)) {
        sorter = sorter[0];
      }

      const newSortState: SortState = {
        field: sorter.columnKey as string,
        order: sorter.order === "descend" ? "DESC" : "ASC",
      };

      const oldSortState = searchParams.sort;
      if (
        oldSortState &&
        (newSortState.field !== oldSortState.field || newSortState.order !== oldSortState.order)
      ) {
        onSearchParamsChange({
          ...searchParams,
          sort: newSortState,
        });
      }
    },
    [onSearchParamsChange, searchParams],
  );

  // If `filters` doesn't have a `text` field then we will just never show the relevance sort
  // option.
  const showRelevanceSortOption = !!(searchParams.filters as any).text;
  const onSortByRelevance = React.useCallback(() => {
    onSearchParamsChange({ ...searchParams, sort: { field: "relevance", order: "DESC" } });
  }, [onSearchParamsChange, searchParams]);

  const recalculateTableHeight = React.useCallback(() => {
    const tableContainer = tableContainerRef.current;
    if (!tableContainer || !tableContainer.clientHeight) {
      return;
    }
    setInnerTableHeight(tableContainer.clientHeight - COLUMN_HEADER_HEIGHT);
  }, []);

  React.useEffect(() => {
    const handleResize = recalculateTableHeight;
    window.addEventListener("resize", handleResize, { passive: true });
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [recalculateTableHeight]);

  const extractRowkey = (record: ResultType, index: number | undefined) =>
    `${record[rowKey]}-${index}`;

  const scrollState = React.useMemo(
    () => ({ ...scroll, y: innerTableHeight }),
    [scroll, innerTableHeight],
  );

  const onRow = React.useMemo(() => {
    if (onRowAction) return onRowAction;
    else if (getRowClickthroughLink) {
      return createHandleTableRowClickthrough(getRowClickthroughLink);
    }
  }, [getRowClickthroughLink, onRowAction]);

  const exportButton = React.useMemo(() => {
    if (!onExport) {
      return null;
    }

    const exportActions = async (format: "csv" | "xlsx") => {
      const queryParams: CommonSearchAPIParams = {};
      queryParams.offset = 0;
      if (!window.currentUser?.has_export_permission) {
        queryParams.limit = maxResultsToExport;
      }
      const { sort } = searchParams;
      if (sort) {
        queryParams.sort = sort.field;
        queryParams.sort_order = sort.order;
      }

      return await onExport(api, queryParams, searchParams, format);
    };

    const asyncExport = async (format: "csv" | "xlsx") => {
      assertDefined(onAsyncExport);
      const queryParams: CommonSearchAPIParams = {};
      queryParams.offset = 0;
      const { sort } = searchParams;
      if (sort) {
        queryParams.sort = sort.field;
        queryParams.sort_order = sort.order;
      }
      return await onAsyncExport(queryParams, searchParams, format);
    };

    // We use the async export if the total is over the threshold and the user has export permissions
    const useAsyncExport =
      pagingState.total > ASYNC_EXPORT_THRESHOLD && window.currentUser?.has_export_permission;

    return useAsyncExport ? (
      <AsyncExportButton
        onExport={asyncExport}
        exportedDataType={exportedDataType}
        resultsCount={pagingState.total}
      />
    ) : (
      <LimitedExportButton
        exportAction={exportActions}
        exportLimit={maxResultsToExport}
        resultsCount={pagingState.total}
        requiredDataType={dataType}
        exportedDataType={exportedDataType}
      />
    );
  }, [
    api,
    searchParams,
    onExport,
    pagingState.total,
    onAsyncExport,
    maxResultsToExport,
    dataType,
    exportedDataType,
  ]);

  const handleTableContainerRef = React.useCallback(
    (instance: Element | Text | null) => {
      if (!instance) {
        tableContainerRef.current = null;
      } else if (instance instanceof HTMLElement) {
        tableContainerRef.current = instance;
        setTimeout(() => {
          recalculateTableHeight();
        }, 0);
      }
    },
    [recalculateTableHeight],
  );

  return (
    <div className={css.container}>
      <div className={css.resultsBar}>
        <h2 className={css.resultsCount}>
          {pagingState && pluralise(pagingState.total, "result", true)}
        </h2>
        <div className={css.expander} />
        {showRelevanceSortOption && (
          <div>
            {searchParams.sort && searchParams.sort.field === "relevance" ? (
              <Button disabled type="dashed">
                Sorted by keyword relevance
              </Button>
            ) : (
              <Button type="dashed" onClick={onSortByRelevance}>
                Sort by keyword relevance
              </Button>
            )}
          </div>
        )}
        <div className={css.exportButton}>{exportButton}</div>
      </div>
      <div ref={handleTableContainerRef} className={css.searchTable}>
        <Table<ResultType>
          rowKey={extractRowkey}
          dataSource={rows}
          pagination={false}
          loading={!rows || isLoadingNewSearch || isLoadingNextPage}
          columns={columns}
          onChange={handleTableChange}
          scroll={scrollState}
          onRow={onRow}
          isError={false}
        />
      </div>
    </div>
  );
}

/**
 * @deprecated This table is legacy for and only used in admin panel
 */
export const SearchTable = React.memo(SearchTableComponent) as typeof SearchTableComponent;
