import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { RecordTimelineDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type QueryKey = ["noticeTimeline", string];

export function useNoticeTimeline(
  procurementProcessId: string,
  options?: UseQueryOptions<RecordTimelineDto, unknown, RecordTimelineDto, QueryKey>,
) {
  const api = useOpenApi();

  return useQuery(
    ["noticeTimeline", procurementProcessId],
    () => api.recordTimeline({ recordFamilyId: procurementProcessId }),
    options,
  );
}
