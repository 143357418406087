import { BuyerCategoryAssignment } from "lib/types/models";

export type BuyingStats = {
  record_count: number;
  latest_activity: string;
};

export type OrgSummary = {
  guid: string;
  name: string;
  is_stotles_verified: boolean;
  address?: string | null;
  town?: string | null;
  country: string;
  postal_code?: string | null;
  url?: string | null;
  oscar_id?: string | null;
  companies_house_id?: string | null;
  categories: BuyerCategoryAssignment[];
  is_deleted?: boolean;
  aliases?: string[];
};

export type OrgWithStats = OrgSummary & { stats?: BuyingStats };
export type OrgMap = Record<string, { org: OrgSummary; stats?: BuyingStats }>;
export enum CandidateOperation {
  NOT_RELATED = "NOT_RELATED",
  DUPLICATE = "DUPLICATE",
  MULTIPLE_ENTITIES = "MULTIPLE_ENTITIES",
  UNSURE = "UNSURE",
  UPDATE_ATTRIBUTES = "UPDATE_ATTRIBUTES",
  TRANSFER_RESPONSIBILITIES = "TRANSFER_RESPONSIBILITIES",
  INACTIVE = "INACTIVE",
  CREATE_ALIAS = "CREATE_ALIAS",
  SIGNALS_TO_ORG = "SIGNALS_TO_ORG",
}
export type QualifiedCandidate =
  | CreateAliasCandidate
  | PlainQualifiedCandidate
  | MultipleEntitiesCandidate
  | UpdateAttributesChange;

type PlainQualifiedCandidate = {
  qualification: Exclude<
    CandidateOperation,
    | CandidateOperation.MULTIPLE_ENTITIES
    | CandidateOperation.UPDATE_ATTRIBUTES
    | CandidateOperation.CREATE_ALIAS
  >;
  buyer: OrgWithStats;
  processStage: ProcessStages.DONE;
  note?: string;
};

export type UpdatableAttributes = {
  oscar_id?: string | number | null | undefined;
  is_stotles_verified?: boolean | null | undefined;
  companies_house_id?: string | null | undefined;
};

export enum ProcessStages {
  REQUIRES_CLEAN = "REQUIRES_CLEAN",
  DONE = "DONE",
  CANCELLED = "CANCELLED",
}

export enum MultipleEntitiesTypes {
  HIERARCHICAL = "HIERARCHICAL",
  DISTINCT = "DISTINCT",
}

export type CreateAliasCandidate = {
  buyer: OrgWithStats;
  processStage: ProcessStages;
  qualification: CandidateOperation.CREATE_ALIAS;
  alias?: string;
  note?: string;
};

export type MultipleEntitiesCandidate = {
  buyer: OrgWithStats;
  processStage: ProcessStages;
  qualification: CandidateOperation.MULTIPLE_ENTITIES;
  cleaningData?: HierarchicalCandidate | DistinctCandidate;
  note?: string;
};

export type UpdateAttributesChange = {
  qualification: CandidateOperation.UPDATE_ATTRIBUTES;
  processStage: ProcessStages;
  buyer: OrgWithStats;
  note?: string;
  newValues: UpdatableAttributes;
};

export enum ValidOrgsCount {
  UP_TO_ONE = "UP_TO_ONE",
  TWO = "TWO",
  THREE_OR_MORE = "THREE_OR_MORE",
}

export type CandidateAction =
  | { type: "RENAME"; newName: string }
  | { type: "MERGE"; replacedBy: OrgWithStats };

export type HierarchicalCandidate = {
  type: MultipleEntitiesTypes.HIERARCHICAL;
  validBuyersCount?: ValidOrgsCount;
  relationToAnchor?: "IS_CHILD" | "IS_PARENT";
  hierarchyConfirmed?: boolean;
  candidateAction?: CandidateAction;
};

export type DistinctCandidate = {
  type: MultipleEntitiesTypes.DISTINCT;
  selectedDistinctBuyers?: OrgWithStats[];
  newDistinctBuyers?: OrgToBeCreated[];
};

export type OrgToBeCreated = {
  guid: string;
  name: string;
  is_stotles_verified?: boolean;
  oscar_id?: string;
};

export type SubmittedLog<Decision> = {
  id: string;
  task_id: string;
  candidate_organisation_id: string;
  is_completed: boolean;
  decision_json: Decision;
};
