/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminCreateBuyerListRequest
 */
export interface AdminCreateBuyerListRequest {
    /**
     * 
     * @type {string}
     * @memberof AdminCreateBuyerListRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateBuyerListRequest
     */
    userGuid: string;
    /**
     * 
     * @type {string}
     * @memberof AdminCreateBuyerListRequest
     */
    sharingType: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminCreateBuyerListRequest
     */
    buyerGuids?: Array<string> | null;
}

/**
 * Check if a given object implements the AdminCreateBuyerListRequest interface.
 */
export function instanceOfAdminCreateBuyerListRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "userGuid" in value;
    isInstance = isInstance && "sharingType" in value;

    return isInstance;
}

export function AdminCreateBuyerListRequestFromJSON(json: any): AdminCreateBuyerListRequest {
    return AdminCreateBuyerListRequestFromJSONTyped(json, false);
}

export function AdminCreateBuyerListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminCreateBuyerListRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'userGuid': json['user_guid'],
        'sharingType': json['sharing_type'],
        'buyerGuids': !exists(json, 'buyer_guids') ? undefined : json['buyer_guids'],
    };
}

export function AdminCreateBuyerListRequestToJSON(value?: AdminCreateBuyerListRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'user_guid': value.userGuid,
        'sharing_type': value.sharingType,
        'buyer_guids': value.buyerGuids,
    };
}

