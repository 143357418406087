/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordSearchConfigRecordFilterBuyersCategories
 */
export interface RecordSearchConfigRecordFilterBuyersCategories {
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilterBuyersCategories
     */
    id: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RecordSearchConfigRecordFilterBuyersCategories
     */
    includeNulls?: boolean | null;
}

/**
 * Check if a given object implements the RecordSearchConfigRecordFilterBuyersCategories interface.
 */
export function instanceOfRecordSearchConfigRecordFilterBuyersCategories(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function RecordSearchConfigRecordFilterBuyersCategoriesFromJSON(json: any): RecordSearchConfigRecordFilterBuyersCategories {
    return RecordSearchConfigRecordFilterBuyersCategoriesFromJSONTyped(json, false);
}

export function RecordSearchConfigRecordFilterBuyersCategoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSearchConfigRecordFilterBuyersCategories {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'includeNulls': !exists(json, 'include_nulls') ? undefined : json['include_nulls'],
    };
}

export function RecordSearchConfigRecordFilterBuyersCategoriesToJSON(value?: RecordSearchConfigRecordFilterBuyersCategories | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'include_nulls': value.includeNulls,
    };
}

