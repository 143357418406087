/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RuleResultsResponseExistingFrameworkMatchesInner } from './RuleResultsResponseExistingFrameworkMatchesInner';
import {
    RuleResultsResponseExistingFrameworkMatchesInnerFromJSON,
    RuleResultsResponseExistingFrameworkMatchesInnerFromJSONTyped,
    RuleResultsResponseExistingFrameworkMatchesInnerToJSON,
} from './RuleResultsResponseExistingFrameworkMatchesInner';
import type { RuleResultsResponseNotices } from './RuleResultsResponseNotices';
import {
    RuleResultsResponseNoticesFromJSON,
    RuleResultsResponseNoticesFromJSONTyped,
    RuleResultsResponseNoticesToJSON,
} from './RuleResultsResponseNotices';

/**
 * 
 * @export
 * @interface RuleResultsResponse
 */
export interface RuleResultsResponse {
    /**
     * 
     * @type {RuleResultsResponseNotices}
     * @memberof RuleResultsResponse
     */
    notices: RuleResultsResponseNotices;
    /**
     * 
     * @type {RuleResultsResponseNotices}
     * @memberof RuleResultsResponse
     */
    documents: RuleResultsResponseNotices;
    /**
     * 
     * @type {RuleResultsResponseNotices}
     * @memberof RuleResultsResponse
     */
    all: RuleResultsResponseNotices;
    /**
     * 
     * @type {Array<RuleResultsResponseExistingFrameworkMatchesInner>}
     * @memberof RuleResultsResponse
     */
    existingFrameworkMatches: Array<RuleResultsResponseExistingFrameworkMatchesInner>;
}

/**
 * Check if a given object implements the RuleResultsResponse interface.
 */
export function instanceOfRuleResultsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "notices" in value;
    isInstance = isInstance && "documents" in value;
    isInstance = isInstance && "all" in value;
    isInstance = isInstance && "existingFrameworkMatches" in value;

    return isInstance;
}

export function RuleResultsResponseFromJSON(json: any): RuleResultsResponse {
    return RuleResultsResponseFromJSONTyped(json, false);
}

export function RuleResultsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleResultsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notices': RuleResultsResponseNoticesFromJSON(json['notices']),
        'documents': RuleResultsResponseNoticesFromJSON(json['documents']),
        'all': RuleResultsResponseNoticesFromJSON(json['all']),
        'existingFrameworkMatches': ((json['existingFrameworkMatches'] as Array<any>).map(RuleResultsResponseExistingFrameworkMatchesInnerFromJSON)),
    };
}

export function RuleResultsResponseToJSON(value?: RuleResultsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notices': RuleResultsResponseNoticesToJSON(value.notices),
        'documents': RuleResultsResponseNoticesToJSON(value.documents),
        'all': RuleResultsResponseNoticesToJSON(value.all),
        'existingFrameworkMatches': ((value.existingFrameworkMatches as Array<any>).map(RuleResultsResponseExistingFrameworkMatchesInnerToJSON)),
    };
}

