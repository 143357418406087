/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SuppliersSectionGenerated } from './SuppliersSectionGenerated';
import {
    SuppliersSectionGeneratedFromJSON,
    SuppliersSectionGeneratedFromJSONTyped,
    SuppliersSectionGeneratedToJSON,
} from './SuppliersSectionGenerated';
import type { SuppliersSectionParameters } from './SuppliersSectionParameters';
import {
    SuppliersSectionParametersFromJSON,
    SuppliersSectionParametersFromJSONTyped,
    SuppliersSectionParametersToJSON,
} from './SuppliersSectionParameters';

/**
 * 
 * @export
 * @interface SuppliersSection
 */
export interface SuppliersSection {
    /**
     * 
     * @type {string}
     * @memberof SuppliersSection
     */
    type: SuppliersSectionTypeEnum;
    /**
     * 
     * @type {SuppliersSectionParameters}
     * @memberof SuppliersSection
     */
    parameters: SuppliersSectionParameters;
    /**
     * 
     * @type {SuppliersSectionGenerated}
     * @memberof SuppliersSection
     */
    generated: SuppliersSectionGenerated;
}


/**
 * @export
 */
export const SuppliersSectionTypeEnum = {
    Suppliers: 'SUPPLIERS'
} as const;
export type SuppliersSectionTypeEnum = typeof SuppliersSectionTypeEnum[keyof typeof SuppliersSectionTypeEnum];


/**
 * Check if a given object implements the SuppliersSection interface.
 */
export function instanceOfSuppliersSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "parameters" in value;
    isInstance = isInstance && "generated" in value;

    return isInstance;
}

export function SuppliersSectionFromJSON(json: any): SuppliersSection {
    return SuppliersSectionFromJSONTyped(json, false);
}

export function SuppliersSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuppliersSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'parameters': SuppliersSectionParametersFromJSON(json['parameters']),
        'generated': SuppliersSectionGeneratedFromJSON(json['generated']),
    };
}

export function SuppliersSectionToJSON(value?: SuppliersSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'parameters': SuppliersSectionParametersToJSON(value.parameters),
        'generated': SuppliersSectionGeneratedToJSON(value.generated),
    };
}

