/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchEntitiesResponseResultEntityResult } from './SearchEntitiesResponseResultEntityResult';
import {
    SearchEntitiesResponseResultEntityResultFromJSON,
    SearchEntitiesResponseResultEntityResultFromJSONTyped,
    SearchEntitiesResponseResultEntityResultToJSON,
} from './SearchEntitiesResponseResultEntityResult';

/**
 * 
 * @export
 * @interface SearchEntitiesResponseResult
 */
export interface SearchEntitiesResponseResult {
    /**
     * 
     * @type {string}
     * @memberof SearchEntitiesResponseResult
     */
    searchTerm: string;
    /**
     * 
     * @type {Array<SearchEntitiesResponseResultEntityResult>}
     * @memberof SearchEntitiesResponseResult
     */
    entities: Array<SearchEntitiesResponseResultEntityResult>;
}

/**
 * Check if a given object implements the SearchEntitiesResponseResult interface.
 */
export function instanceOfSearchEntitiesResponseResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "searchTerm" in value;
    isInstance = isInstance && "entities" in value;

    return isInstance;
}

export function SearchEntitiesResponseResultFromJSON(json: any): SearchEntitiesResponseResult {
    return SearchEntitiesResponseResultFromJSONTyped(json, false);
}

export function SearchEntitiesResponseResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchEntitiesResponseResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchTerm': json['search_term'],
        'entities': ((json['entities'] as Array<any>).map(SearchEntitiesResponseResultEntityResultFromJSON)),
    };
}

export function SearchEntitiesResponseResultToJSON(value?: SearchEntitiesResponseResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'search_term': value.searchTerm,
        'entities': ((value.entities as Array<any>).map(SearchEntitiesResponseResultEntityResultToJSON)),
    };
}

