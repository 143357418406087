/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListNoticeMappingsRequest
 */
export interface ListNoticeMappingsRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListNoticeMappingsRequest
     */
    recordIds: Array<string>;
}

/**
 * Check if a given object implements the ListNoticeMappingsRequest interface.
 */
export function instanceOfListNoticeMappingsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recordIds" in value;

    return isInstance;
}

export function ListNoticeMappingsRequestFromJSON(json: any): ListNoticeMappingsRequest {
    return ListNoticeMappingsRequestFromJSONTyped(json, false);
}

export function ListNoticeMappingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListNoticeMappingsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordIds': json['recordIds'],
    };
}

export function ListNoticeMappingsRequestToJSON(value?: ListNoticeMappingsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordIds': value.recordIds,
    };
}

