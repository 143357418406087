/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RichTextSectionParameters } from './RichTextSectionParameters';
import {
    RichTextSectionParametersFromJSON,
    RichTextSectionParametersFromJSONTyped,
    RichTextSectionParametersToJSON,
} from './RichTextSectionParameters';

/**
 * 
 * @export
 * @interface RichTextSection
 */
export interface RichTextSection {
    /**
     * 
     * @type {string}
     * @memberof RichTextSection
     */
    type: RichTextSectionTypeEnum;
    /**
     * 
     * @type {RichTextSectionParameters}
     * @memberof RichTextSection
     */
    parameters: RichTextSectionParameters;
    /**
     * 
     * @type {RichTextSectionParameters}
     * @memberof RichTextSection
     */
    generated: RichTextSectionParameters;
}


/**
 * @export
 */
export const RichTextSectionTypeEnum = {
    RichText: 'RICH_TEXT'
} as const;
export type RichTextSectionTypeEnum = typeof RichTextSectionTypeEnum[keyof typeof RichTextSectionTypeEnum];


/**
 * Check if a given object implements the RichTextSection interface.
 */
export function instanceOfRichTextSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "parameters" in value;
    isInstance = isInstance && "generated" in value;

    return isInstance;
}

export function RichTextSectionFromJSON(json: any): RichTextSection {
    return RichTextSectionFromJSONTyped(json, false);
}

export function RichTextSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RichTextSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'parameters': RichTextSectionParametersFromJSON(json['parameters']),
        'generated': RichTextSectionParametersFromJSON(json['generated']),
    };
}

export function RichTextSectionToJSON(value?: RichTextSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'parameters': RichTextSectionParametersToJSON(value.parameters),
        'generated': RichTextSectionParametersToJSON(value.generated),
    };
}

