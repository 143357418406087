/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordSearchConfigCompanyRecordFilterDateFilter
 */
export interface RecordSearchConfigCompanyRecordFilterDateFilter {
    /**
     * 
     * @type {Date}
     * @memberof RecordSearchConfigCompanyRecordFilterDateFilter
     */
    from?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof RecordSearchConfigCompanyRecordFilterDateFilter
     */
    to?: Date | null;
}

/**
 * Check if a given object implements the RecordSearchConfigCompanyRecordFilterDateFilter interface.
 */
export function instanceOfRecordSearchConfigCompanyRecordFilterDateFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RecordSearchConfigCompanyRecordFilterDateFilterFromJSON(json: any): RecordSearchConfigCompanyRecordFilterDateFilter {
    return RecordSearchConfigCompanyRecordFilterDateFilterFromJSONTyped(json, false);
}

export function RecordSearchConfigCompanyRecordFilterDateFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSearchConfigCompanyRecordFilterDateFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : (json['from'] === null ? null : new Date(json['from'])),
        'to': !exists(json, 'to') ? undefined : (json['to'] === null ? null : new Date(json['to'])),
    };
}

export function RecordSearchConfigCompanyRecordFilterDateFilterToJSON(value?: RecordSearchConfigCompanyRecordFilterDateFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from === undefined ? undefined : (value.from === null ? null : value.from.toISOString()),
        'to': value.to === undefined ? undefined : (value.to === null ? null : value.to.toISOString()),
    };
}

