/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    companyGuid: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    teamId: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    firstName: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    lastName: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isAdmin: boolean;
}

/**
 * Check if a given object implements the UserDto interface.
 */
export function instanceOfUserDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "companyGuid" in value;
    isInstance = isInstance && "teamId" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "isAdmin" in value;

    return isInstance;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'guid': json['guid'],
        'companyGuid': json['company_guid'],
        'teamId': json['team_id'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'email': json['email'],
        'isAdmin': json['is_admin'],
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'guid': value.guid,
        'company_guid': value.companyGuid,
        'team_id': value.teamId,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'email': value.email,
        'is_admin': value.isAdmin,
    };
}

