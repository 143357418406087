/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchAccountsRequest
 */
export interface SearchAccountsRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchAccountsRequest
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchAccountsRequest
     */
    limit?: number;
}

/**
 * Check if a given object implements the SearchAccountsRequest interface.
 */
export function instanceOfSearchAccountsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchAccountsRequestFromJSON(json: any): SearchAccountsRequest {
    return SearchAccountsRequestFromJSONTyped(json, false);
}

export function SearchAccountsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchAccountsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'text') ? undefined : json['text'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
    };
}

export function SearchAccountsRequestToJSON(value?: SearchAccountsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'limit': value.limit,
    };
}

