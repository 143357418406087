import * as React from "react";

import { RecordMatchInfo } from "../../lib/qued/queryRunner";
import { RecordMatch, SelfServeQuery } from "../../lib/StotlesApi";
import { useStotlesApi } from "../../lib/stotlesApiContext";

type RecordMatchInfoMap = Record<string, RecordMatchInfo>;

export function convertRecordMatchToRecordMatchInfo(
  matches: Record<string, RecordMatch>,
): RecordMatchInfoMap {
  const guidToMatchInfo: RecordMatchInfoMap = {};
  for (const [guid, queryMatch] of Object.entries(matches)) {
    guidToMatchInfo[guid] = {
      keyword_matches: queryMatch.text_matches,
    };
  }
  return guidToMatchInfo;
}

export function useRecordMatchData(
  recordGuids: string[] | undefined,
  companyId?: number,
  feedCriteria?: SelfServeQuery,
): RecordMatchInfoMap | undefined {
  const api = useStotlesApi();

  const fetchedGuidsSet = React.useRef<Set<string>>();

  const [recordMatchInfoMap, setRecordMatchInfoMap] = React.useState<RecordMatchInfoMap>();

  React.useEffect(() => {
    if (recordMatchInfoMap) {
      fetchedGuidsSet.current = new Set(Object.keys(recordMatchInfoMap));
    }
  }, [recordMatchInfoMap]);

  // If criteria changed reset the cached matches
  React.useEffect(() => {
    if (feedCriteria) {
      setRecordMatchInfoMap({});
    }
  }, [feedCriteria]);

  React.useEffect(() => {
    if (!recordGuids || recordGuids.length === 0 || !companyId) {
      return;
    }
    void (async () => {
      let existingGuids = fetchedGuidsSet.current;
      if (existingGuids === undefined) {
        existingGuids = new Set<string>();
        fetchedGuidsSet.current = existingGuids;
      }
      const newGuids = recordGuids.filter((guid) => !existingGuids?.has(guid));
      if (newGuids.length === 0) {
        return;
      }

      const matches = await api.annotateRecords(companyId, newGuids, feedCriteria);

      const guidToMatchInfo = convertRecordMatchToRecordMatchInfo(matches.matched_records);
      setRecordMatchInfoMap((oldMap) => ({ ...oldMap, ...guidToMatchInfo }));
    })();
  }, [recordGuids, api, companyId, feedCriteria]);

  return recordMatchInfoMap;
}
