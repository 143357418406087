/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyersBySupplierRequestSignals } from './BuyersBySupplierRequestSignals';
import {
    BuyersBySupplierRequestSignalsFromJSON,
    BuyersBySupplierRequestSignalsFromJSONTyped,
    BuyersBySupplierRequestSignalsToJSON,
} from './BuyersBySupplierRequestSignals';

/**
 * 
 * @export
 * @interface BuyersBySupplierRequest
 */
export interface BuyersBySupplierRequest {
    /**
     * 
     * @type {string}
     * @memberof BuyersBySupplierRequest
     */
    sort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuyersBySupplierRequest
     */
    sortOrder?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BuyersBySupplierRequest
     */
    limit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BuyersBySupplierRequest
     */
    offset?: number | null;
    /**
     * 
     * @type {BuyersBySupplierRequestSignals}
     * @memberof BuyersBySupplierRequest
     */
    signals?: BuyersBySupplierRequestSignals;
    /**
     * 
     * @type {Array<string>}
     * @memberof BuyersBySupplierRequest
     */
    buyerListIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BuyersBySupplierRequest
     */
    buyerGuids?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BuyersBySupplierRequest
     */
    buyerName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BuyersBySupplierRequest
     */
    showKeyAccountsOnly: boolean;
}

/**
 * Check if a given object implements the BuyersBySupplierRequest interface.
 */
export function instanceOfBuyersBySupplierRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "showKeyAccountsOnly" in value;

    return isInstance;
}

export function BuyersBySupplierRequestFromJSON(json: any): BuyersBySupplierRequest {
    return BuyersBySupplierRequestFromJSONTyped(json, false);
}

export function BuyersBySupplierRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyersBySupplierRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'sortOrder': !exists(json, 'sort_order') ? undefined : json['sort_order'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'signals': !exists(json, 'signals') ? undefined : BuyersBySupplierRequestSignalsFromJSON(json['signals']),
        'buyerListIds': !exists(json, 'buyer_list_ids') ? undefined : json['buyer_list_ids'],
        'buyerGuids': !exists(json, 'buyer_guids') ? undefined : json['buyer_guids'],
        'buyerName': !exists(json, 'buyer_name') ? undefined : json['buyer_name'],
        'showKeyAccountsOnly': json['show_key_accounts_only'],
    };
}

export function BuyersBySupplierRequestToJSON(value?: BuyersBySupplierRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sort': value.sort,
        'sort_order': value.sortOrder,
        'limit': value.limit,
        'offset': value.offset,
        'signals': BuyersBySupplierRequestSignalsToJSON(value.signals),
        'buyer_list_ids': value.buyerListIds,
        'buyer_guids': value.buyerGuids,
        'buyer_name': value.buyerName,
        'show_key_accounts_only': value.showKeyAccountsOnly,
    };
}

