import React from "react";
import { message } from "antd5";
import { DateTime } from "luxon";

import FeedPreviewOutputs from "components/onboarding/FeedPreviewOutputs";
import { getSignalFiltersData, SignalFiltersData } from "components/onboarding/onboardingUtils";
import {
  FeedPreviewPageSources,
  FeedPreviewProvider,
  MatchCountMap,
} from "components/onboarding/SharedOnboarding";
import { createUseDebounce } from "lib/debounce";
import { useSubscription } from "lib/providers/Subscription";
import { PreviewGuidMatchMap, SelfServeQuery, Signal, SignalCategory } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";
import { RecordPreview, TenderStage } from "lib/types/models";
import { OutOfOrderResponseError } from "../../../lib/search/SequentialRequestHandler";

export const DEFAULT_PREVIEW_LOOKBACK = DateTime.now().startOf("day").minus({ months: 6 });

type Props = {
  feedCriteria: SelfServeQuery;
  pageSource: FeedPreviewPageSources;
  onResultCountsChange: (newCounts: Partial<Record<SignalCategory, MatchCountMap>>) => void;
  setFeedPreviewLoading: (loading: boolean) => void;
  feedPreviewLoading: boolean;
  companyId?: number;
};

const useDebounce1500 = createUseDebounce(1500);

function FeedSettingsPreview({
  feedCriteria,
  pageSource,
  onResultCountsChange,
  companyId,
  setFeedPreviewLoading,
  feedPreviewLoading,
}: Props): JSX.Element {
  const api = useStotlesApi();
  const subscription = useSubscription();

  const [results, setResults] = React.useState<RecordPreview[]>([]);

  const feedProvider = React.useMemo(
    () => new FeedPreviewProvider(api, companyId),
    [api, companyId],
  );

  const [guidMatchMap, setGuidMatchMap] = React.useState<PreviewGuidMatchMap | undefined>();
  const [filterDataSets, setFilterDataSets] = React.useState<SignalFiltersData | undefined>();
  const [signals, setSignals] = React.useState<Signal[]>();
  const hasAwards = subscription.hasDataTypes("AWARDS");

  const searchRecords = React.useCallback(async () => {
    try {
      setFeedPreviewLoading(true);

      const { preview_results, guid_match_map, signals } = await feedProvider.getForQuery(
        feedCriteria,
        DEFAULT_PREVIEW_LOOKBACK.toJSDate(),
      );
      const fds = getSignalFiltersData(signals, guid_match_map);

      const recordCounts: Partial<Record<keyof SignalFiltersData, MatchCountMap | undefined>> = {};
      for (const [signalCategory, filterSet] of Object.entries(fds)) {
        recordCounts[signalCategory as SignalCategory] = filterSet?.counts;
      }
      onResultCountsChange(recordCounts);
      setFilterDataSets(fds);
      setGuidMatchMap(guid_match_map);
      const results = hasAwards
        ? preview_results
        : preview_results.filter((r) => r.stage !== TenderStage.AWARDED);
      setResults(
        results.sort(
          (a, b) => new Date(b.publish_date).getTime() - new Date(a.publish_date).getTime(),
        ),
      );
      setSignals(signals);
      setFeedPreviewLoading(false);
    } catch (e) {
      if (e instanceof OutOfOrderResponseError) {
        // Nothing to do, we just discard the results
        return;
      }
      setFeedPreviewLoading(false);
      void message.error("Unable to perform search; please contact us if the problem persists");
      throw e;
    }
  }, [feedCriteria, feedProvider, hasAwards, onResultCountsChange, setFeedPreviewLoading]);

  const debouncedSearchRecords = useDebounce1500(searchRecords);
  React.useEffect(() => {
    // If the keywords haven't been filled in yet we don't want to do a search
    // Might change in the future when we have more fields
    if (feedCriteria.keywords.keywords.length === 0) {
      // If the user deleted the last keyword we want to set the results to []
      setResults([]);
      return;
    } else {
      void debouncedSearchRecords();
    }
  }, [feedCriteria, debouncedSearchRecords]);

  const signalMap = React.useMemo(
    () => signals && new Map(signals.map((s) => [s.id, s])),
    [signals],
  );

  return (
    <FeedPreviewOutputs
      signalMap={signalMap}
      results={results}
      loading={feedPreviewLoading}
      guidMatchMap={guidMatchMap}
      signalFiltersData={filterDataSets}
      pageSource={pageSource}
      allSignals={signals}
      feedCriteria={feedCriteria}
      companyId={companyId}
    />
  );
}

// The Route component doesn't accept the Form.create type so we have to wrap it before we export
export default FeedSettingsPreview;
