import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { FindProviderResponse } from "lib/generated/integration-api";
import { useIntegrationApi } from "lib/integrationApiContext";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

type QueryKey = [string];

export function useFindIntegrationProvider(
  options?: UseQueryOptions<FindProviderResponse, unknown, FindProviderResponse, QueryKey>,
) {
  const api = useIntegrationApi();
  return useQuery(
    ["integrationProvider"],
    async () => {
      const response = await api.fetchProvider();
      return response;
    },
    { ...REACT_QUERY_OPTIONS_NEVER_REFETCH, ...options },
  );
}
