import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useOpenApi } from "lib/openApiContext";

type AddEntriesParams = { buyerListGuid: string; buyerListName: string; buyerGuids: string[] };
export function useAddBuyerEntries(
  options?: UseMutationOptions<void, unknown, AddEntriesParams, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ buyerListGuid, buyerGuids }: AddEntriesParams) =>
      api.addBuyersToList({ id: buyerListGuid, addBuyersRequest: { buyerGuids } }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(["buyerLists"]);
        void queryClient.invalidateQueries([variables.buyerListGuid]);
        void queryClient.invalidateQueries(["supplierStats"]);
        void queryClient.invalidateQueries(["buyers", "buyersForSupplier"]);

        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
    },
  );
}
