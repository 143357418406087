/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationDtoActor } from './NotificationDtoActor';
import {
    NotificationDtoActorFromJSON,
    NotificationDtoActorFromJSONTyped,
    NotificationDtoActorToJSON,
} from './NotificationDtoActor';
import type { NotificationDtoTextAttributes } from './NotificationDtoTextAttributes';
import {
    NotificationDtoTextAttributesFromJSON,
    NotificationDtoTextAttributesFromJSONTyped,
    NotificationDtoTextAttributesToJSON,
} from './NotificationDtoTextAttributes';

/**
 * 
 * @export
 * @interface NotificationDto
 */
export interface NotificationDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    link: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    readAt: string | null;
    /**
     * 
     * @type {NotificationDtoActor}
     * @memberof NotificationDto
     */
    actor: NotificationDtoActor;
    /**
     * 
     * @type {string}
     * @memberof NotificationDto
     */
    verb: string;
    /**
     * 
     * @type {NotificationDtoTextAttributes}
     * @memberof NotificationDto
     */
    textAttributes: NotificationDtoTextAttributes;
}

/**
 * Check if a given object implements the NotificationDto interface.
 */
export function instanceOfNotificationDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "readAt" in value;
    isInstance = isInstance && "actor" in value;
    isInstance = isInstance && "verb" in value;
    isInstance = isInstance && "textAttributes" in value;

    return isInstance;
}

export function NotificationDtoFromJSON(json: any): NotificationDto {
    return NotificationDtoFromJSONTyped(json, false);
}

export function NotificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': json['created_at'],
        'link': json['link'],
        'readAt': json['read_at'],
        'actor': NotificationDtoActorFromJSON(json['actor']),
        'verb': json['verb'],
        'textAttributes': NotificationDtoTextAttributesFromJSON(json['text_attributes']),
    };
}

export function NotificationDtoToJSON(value?: NotificationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created_at': value.createdAt,
        'link': value.link,
        'read_at': value.readAt,
        'actor': NotificationDtoActorToJSON(value.actor),
        'verb': value.verb,
        'text_attributes': NotificationDtoTextAttributesToJSON(value.textAttributes),
    };
}

