import * as React from "react";

import { blue50, blue600 } from "lib/themes/colors";

type Props = {
  size?: number;
  background?: string;
  fill?: string;
  className?: string;
  label?: string;
};

export default function Signal({
  size = 16,
  background = blue50,
  fill = blue600,
  className,
  label,
}: Props) {
  return (
    <svg
      className={className ?? undefined}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={background}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={label ?? "signalIcon"}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75744 2.8144C4.01779 3.07475 4.01779 3.49686 3.75744 3.75721C1.41429 6.10036 1.41429 9.89935 3.75744 12.2425C4.01779 12.5028 4.01779 12.925 3.75744 13.1853C3.49709 13.4457 3.07498 13.4457 2.81463 13.1853C-0.0492131 10.3215 -0.0492131 5.67825 2.81463 2.8144C3.07498 2.55405 3.49709 2.55405 3.75744 2.8144ZM12.2427 2.81443C12.5031 2.55408 12.9252 2.55408 13.1855 2.81443C16.0494 5.67828 16.0494 10.3215 13.1855 13.1853C12.9252 13.4457 12.5031 13.4457 12.2427 13.1853C11.9824 12.925 11.9824 12.5029 12.2427 12.2425C14.5859 9.89938 14.5859 6.10039 12.2427 3.75724C11.9824 3.49689 11.9824 3.07478 12.2427 2.81443ZM5.64306 4.7C5.90341 4.96035 5.90341 5.38246 5.64306 5.64281C4.34131 6.94455 4.34131 9.0551 5.64306 10.3569C5.90341 10.6172 5.90341 11.0393 5.64306 11.2997C5.38271 11.56 4.9606 11.56 4.70025 11.2997C2.8778 9.47721 2.8778 6.52244 4.70025 4.7C4.9606 4.43965 5.38271 4.43965 5.64306 4.7ZM10.3571 4.70002C10.6175 4.43967 11.0396 4.43967 11.2999 4.70002C13.1224 6.52247 13.1224 9.47724 11.2999 11.2997C11.0396 11.56 10.6175 11.56 10.3571 11.2997C10.0968 11.0393 10.0968 10.6172 10.3571 10.3569C11.6589 9.05513 11.6589 6.94458 10.3571 5.64283C10.0968 5.38248 10.0968 4.96037 10.3571 4.70002ZM8.00008 7.33319C7.63189 7.33319 7.33341 7.63166 7.33341 7.99985C7.33341 8.36804 7.63189 8.66652 8.00008 8.66652C8.36827 8.66652 8.66675 8.36804 8.66675 7.99985C8.66675 7.63166 8.36827 7.33319 8.00008 7.33319ZM6.00008 7.99985C6.00008 6.89528 6.89551 5.99985 8.00008 5.99985C9.10465 5.99985 10.0001 6.89528 10.0001 7.99985C10.0001 9.10442 9.10465 9.99985 8.00008 9.99985C6.89551 9.99985 6.00008 9.10442 6.00008 7.99985Z"
        fill={fill}
      />
    </svg>
  );
}
