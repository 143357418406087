import React, { ComponentType, ReactNode, useMemo, useState } from "react";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Empty, Input, Popover } from "antd5";
import classnames from "classnames";
import List from "rc-virtual-list";

import TextButton from "components/actions/TextButton";
import Badge from "lib/core_components/Badge";
import { EventNames, useTracking } from "lib/tracking";

import css from "./MultiSelectDropdownList.module.scss";

export type Option<T> = {
  title: string;
  value: T;
  OptionComponent?: ReactNode;
};

type Props<T> = {
  title: string;
  description?: string;
  options: Option<T>[];
  selectedOptions: T[];
  onChange: (selectedOptions: T[]) => void;
  className?: string;
  overlayClassName?: string;
  optionClassName?: string;
  searchable?: boolean;
  OptionComponent?: ComponentType<{ title: string }>;
  disabled?: boolean;
  loading?: boolean;
  skipSort?: boolean;
  hideSelectAll?: boolean;
  trackingField?: "title" | "value"; // defaults to value
  virtualisation?: { height: number; itemHeight: number };
};

function MultiSelectDropdown<T = string>({
  title,
  description,
  onChange: onCheck,
  options,
  selectedOptions,
  optionClassName,
  className,
  overlayClassName,
  searchable,
  OptionComponent,
  disabled,
  hideSelectAll,
  loading,
  skipSort,
  trackingField = "value",
  virtualisation,
}: Props<T>): JSX.Element {
  const { logEvent } = useTracking();
  const [searchTerm, setSearchTerm] = useState("");
  const filterTrackingEvent = React.useCallback(
    (field: T, selected: boolean) => {
      logEvent(EventNames.filterActioned, {
        "Filter name": title,
        "Filter selection": field,
        "Action type": selected ? "Filter applied" : "Filter cleared",
      });
    },
    [logEvent, title],
  );

  const selected = useMemo(() => new Set(selectedOptions), [selectedOptions]);

  const sortedOptions = useMemo(() => {
    const sorted = skipSort
      ? options
      : options.sort((a, b) =>
          a.title.trim().toLocaleLowerCase().localeCompare(b.title.trim().toLocaleLowerCase()),
        );

    if (!searchTerm) {
      return sorted;
    }

    return sorted.filter((s) =>
      s.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
    );
  }, [options, searchTerm, skipSort]);

  const overlay = React.useMemo(() => {
    const selectAll =
      selected.size === sortedOptions.length ? (
        <TextButton
          onClick={(e) => {
            e.stopPropagation();
            onCheck([]);
            filterTrackingEvent("All options" as unknown as T, false);
          }}
        >
          Deselect all
        </TextButton>
      ) : (
        <TextButton
          onClick={(e) => {
            e.stopPropagation();

            onCheck(options.map((o) => o.value));
            filterTrackingEvent("All options" as unknown as T, true);
          }}
        >
          Select all
        </TextButton>
      );

    if (sortedOptions.length === 0) {
      return (
        <div className={css.overlay} onClick={(e) => e.stopPropagation()}>
          {searchable && (
            <div className={css.inputContainer}>
              <Input
                autoFocus
                prefix={<SearchOutlined />}
                placeholder="Search..."
                className={css.input}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                allowClear
              />
            </div>
          )}
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      );
    } else {
      const renderOption = (option: Option<T>) => (
        <li key={`${option.value}`} className={css.listItem} onClick={(e) => e.stopPropagation()}>
          <Checkbox
            checked={selected.has(option.value)}
            className={css.optionWrapper}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              onCheck(selected.delete(option.value) ? [...selected] : [...selected, option.value]);
              filterTrackingEvent(option[trackingField] as T, e.target.checked);
            }}
          >
            <span className={optionClassName} onClick={(e) => e.stopPropagation()}>
              {option.OptionComponent ? (
                option.OptionComponent
              ) : OptionComponent ? (
                <OptionComponent title={option.title} />
              ) : (
                option.title
              )}
            </span>
          </Checkbox>
        </li>
      );
      return (
        <div className={classnames(css.overlay, overlayClassName)}>
          <div className={css.selectAll} onClick={(e) => e.stopPropagation()}>
            {description && <p className={css.description}>{description}</p>}
            {!hideSelectAll && selectAll}
            {searchable && (
              <Input
                autoFocus
                prefix={<SearchOutlined />}
                placeholder="Search..."
                className={css.input}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                allowClear
              />
            )}
          </div>
          <ul className={css.list}>
            {virtualisation ? (
              <List
                data={sortedOptions}
                height={virtualisation.height}
                itemHeight={virtualisation.itemHeight}
                virtual
                itemKey="value"
              >
                {renderOption}
              </List>
            ) : (
              sortedOptions.map((o) => renderOption(o))
            )}
          </ul>
        </div>
      );
    }
  }, [
    selected,
    sortedOptions,
    onCheck,
    filterTrackingEvent,
    options,
    searchable,
    searchTerm,
    overlayClassName,
    description,
    hideSelectAll,
    virtualisation,
    optionClassName,
    OptionComponent,
    trackingField,
  ]);

  return (
    <Popover
      trigger={["click"]}
      content={overlay}
      arrow={false}
      placement="bottomLeft"
      overlayInnerStyle={{ padding: 0 }}
    >
      <Button className={className} loading={loading} disabled={disabled}>
        {title}{" "}
        {!!selected.size && (
          <>
            <Badge>{selected.size === sortedOptions.length ? "All" : selected.size}</Badge>{" "}
          </>
        )}
        <DownOutlined />
      </Button>
    </Popover>
  );
}

export default MultiSelectDropdown;
