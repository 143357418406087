/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AllBuyersStatsRequestAllBuyersStatsFiltersCategories } from './AllBuyersStatsRequestAllBuyersStatsFiltersCategories';
import {
    AllBuyersStatsRequestAllBuyersStatsFiltersCategoriesFromJSON,
    AllBuyersStatsRequestAllBuyersStatsFiltersCategoriesFromJSONTyped,
    AllBuyersStatsRequestAllBuyersStatsFiltersCategoriesToJSON,
} from './AllBuyersStatsRequestAllBuyersStatsFiltersCategories';
import type { AllBuyersStatsRequestAllBuyersStatsFiltersHqCountry } from './AllBuyersStatsRequestAllBuyersStatsFiltersHqCountry';
import {
    AllBuyersStatsRequestAllBuyersStatsFiltersHqCountryFromJSON,
    AllBuyersStatsRequestAllBuyersStatsFiltersHqCountryFromJSONTyped,
    AllBuyersStatsRequestAllBuyersStatsFiltersHqCountryToJSON,
} from './AllBuyersStatsRequestAllBuyersStatsFiltersHqCountry';

/**
 * 
 * @export
 * @interface AllBuyersStatsRequestAllBuyersStatsFilters
 */
export interface AllBuyersStatsRequestAllBuyersStatsFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFilters
     */
    signals?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFilters
     */
    buyerName?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFilters
     */
    buyerGuids?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFilters
     */
    buyerLists?: Array<string>;
    /**
     * 
     * @type {AllBuyersStatsRequestAllBuyersStatsFiltersCategories}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFilters
     */
    categories?: AllBuyersStatsRequestAllBuyersStatsFiltersCategories;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFilters
     */
    buyerTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFilters
     */
    countryCodes?: Array<string>;
    /**
     * 
     * @type {AllBuyersStatsRequestAllBuyersStatsFiltersHqCountry}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFilters
     */
    hqCountry?: AllBuyersStatsRequestAllBuyersStatsFiltersHqCountry;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFilters
     */
    regionIds?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFilters
     */
    supplierIds?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFilters
     */
    supplierSme?: string | null;
}

/**
 * Check if a given object implements the AllBuyersStatsRequestAllBuyersStatsFilters interface.
 */
export function instanceOfAllBuyersStatsRequestAllBuyersStatsFilters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AllBuyersStatsRequestAllBuyersStatsFiltersFromJSON(json: any): AllBuyersStatsRequestAllBuyersStatsFilters {
    return AllBuyersStatsRequestAllBuyersStatsFiltersFromJSONTyped(json, false);
}

export function AllBuyersStatsRequestAllBuyersStatsFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllBuyersStatsRequestAllBuyersStatsFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signals': !exists(json, 'signals') ? undefined : json['signals'],
        'buyerName': !exists(json, 'buyer_name') ? undefined : json['buyer_name'],
        'buyerGuids': !exists(json, 'buyer_guids') ? undefined : json['buyer_guids'],
        'buyerLists': !exists(json, 'buyer_lists') ? undefined : json['buyer_lists'],
        'categories': !exists(json, 'categories') ? undefined : AllBuyersStatsRequestAllBuyersStatsFiltersCategoriesFromJSON(json['categories']),
        'buyerTypes': !exists(json, 'buyer_types') ? undefined : json['buyer_types'],
        'countryCodes': !exists(json, 'country_codes') ? undefined : json['country_codes'],
        'hqCountry': !exists(json, 'hq_country') ? undefined : AllBuyersStatsRequestAllBuyersStatsFiltersHqCountryFromJSON(json['hq_country']),
        'regionIds': !exists(json, 'region_ids') ? undefined : json['region_ids'],
        'supplierIds': !exists(json, 'supplier_ids') ? undefined : json['supplier_ids'],
        'supplierSme': !exists(json, 'supplier_sme') ? undefined : json['supplier_sme'],
    };
}

export function AllBuyersStatsRequestAllBuyersStatsFiltersToJSON(value?: AllBuyersStatsRequestAllBuyersStatsFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signals': value.signals,
        'buyer_name': value.buyerName,
        'buyer_guids': value.buyerGuids,
        'buyer_lists': value.buyerLists,
        'categories': AllBuyersStatsRequestAllBuyersStatsFiltersCategoriesToJSON(value.categories),
        'buyer_types': value.buyerTypes,
        'country_codes': value.countryCodes,
        'hq_country': AllBuyersStatsRequestAllBuyersStatsFiltersHqCountryToJSON(value.hqCountry),
        'region_ids': value.regionIds,
        'supplier_ids': value.supplierIds,
        'supplier_sme': value.supplierSme,
    };
}

