import React from "react";
import { FieldValues } from "react-hook-form";

import { useOpportunityWorkflow } from "../../lib/hooks/api/opportunities/useOpportunityWorkflow";
import { Select, SelectProps } from "../form_components/Inputs";

type GroupedOptionsType = {
  label: string;
  value: string;
  options: { label: string; value: string }[];
};

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options">;

export function OpportunityStageSelect<T extends FieldValues>(props: FieldProps<T>) {
  const { data, isLoading } = useOpportunityWorkflow();

  const options =
    data?.transitions.reduce<GroupedOptionsType[]>((acc, transition) => {
      const group = acc.findIndex((group) => group.value === transition.group);
      if (group > -1) {
        acc[group].options.push({ label: transition.stage.name, value: transition.stage.id });
      } else {
        acc.push({
          label: transition.group.replace("_", " "),
          value: transition.group,
          options: [{ label: transition.stage.name, value: transition.stage.id }],
        });
      }
      return acc;
    }, []) ?? [];

  return <Select {...props} loading={isLoading} options={options} />;
}
