import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd5";

import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { SupplierOption } from "./types";

import css from "./SelectedSupplierTags.module.scss";

// TODO: cleanup once we decide to keep or remove signal identifiers in the tags
// hide for now while we test with users
// function getToolTip(supplierId: string) {
//   if (signals?.competitors?.find((s) => s.id === supplierId)) {
//     if (signals?.partners?.find((s) => s.id === supplierId)) {
//       return "Competitor and partner signal";
//     }
//     return "Competitor signal";
//   }

//   if (signals?.partners?.find((s) => s.id === supplierId)) {
//     return "Partner signal";
//   }
// }

type Props = {
  selectedSuppliers: SupplierOption[];
  handleSuppliersSelection: (suppliers: SupplierOption[]) => void;
};

function SelectedSupplierTags({ selectedSuppliers, handleSuppliersSelection }: Props) {
  // const signalIds = competitors.concat(partners).map((s) => s.value);

  return (
    <>
      <div className={css.filterLabelDiv} aria-label="filterLabel">
        <span>{selectedSuppliers.length} selected</span>
        <Button className={css.clearButton} onClick={() => handleSuppliersSelection([])}>
          Clear
        </Button>
      </div>
      <div className={css.supplierSelections} aria-label="supplierSelections">
        <div className={css.tagContainer}>
          {selectedSuppliers.map((supplier: SupplierOption) => {
            // const isSignal = signalIds.includes(supplier.value);

            return (
              <Tag
                className={css.tag}
                onClose={() =>
                  handleSuppliersSelection(
                    selectedSuppliers.filter((s) => s.value !== supplier.value),
                  )
                }
                closeIcon={<CloseOutlined />}
                key={supplier.value}
                title={supplier.label}
              >
                {/* 
            // TODO: cleanup once we decide to keep or remove
            // hide signal information for now to test with users
            {isSignal && (
              <Signal className={css.signalIcon} size={16} label="signalIcon" />
            )}
              */}
                <EllipsisTooltipText
                  fullText={supplier.label}
                  textProps={{ className: css.tagLabel }}
                />
              </Tag>
            );
            // TODO: cleanup once we decide to keep or remove
            //   return isSignal ? (
            //     <Tooltip
            //       title={getToolTip(supplier.value, competitors, partners)}
            //       key={supplier.value}
            //       placement="bottom"
            //     >
            //       {tag}
            //     </Tooltip>
            //   ) : (
            //     {tag}
            //   );
          })}
        </div>
      </div>
    </>
  );
}

export default SelectedSupplierTags;
