/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PagingInfo } from './PagingInfo';
import {
    PagingInfoFromJSON,
    PagingInfoFromJSONTyped,
    PagingInfoToJSON,
} from './PagingInfo';
import type { PartnerCompetitorStat } from './PartnerCompetitorStat';
import {
    PartnerCompetitorStatFromJSON,
    PartnerCompetitorStatFromJSONTyped,
    PartnerCompetitorStatToJSON,
} from './PartnerCompetitorStat';

/**
 * 
 * @export
 * @interface PartnersCompetitorsResponse
 */
export interface PartnersCompetitorsResponse {
    /**
     * 
     * @type {Array<PartnerCompetitorStat>}
     * @memberof PartnersCompetitorsResponse
     */
    results: Array<PartnerCompetitorStat>;
    /**
     * 
     * @type {PagingInfo}
     * @memberof PartnersCompetitorsResponse
     */
    pagingInfo: PagingInfo;
}

/**
 * Check if a given object implements the PartnersCompetitorsResponse interface.
 */
export function instanceOfPartnersCompetitorsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;
    isInstance = isInstance && "pagingInfo" in value;

    return isInstance;
}

export function PartnersCompetitorsResponseFromJSON(json: any): PartnersCompetitorsResponse {
    return PartnersCompetitorsResponseFromJSONTyped(json, false);
}

export function PartnersCompetitorsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartnersCompetitorsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(PartnerCompetitorStatFromJSON)),
        'pagingInfo': PagingInfoFromJSON(json['paging_info']),
    };
}

export function PartnersCompetitorsResponseToJSON(value?: PartnersCompetitorsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(PartnerCompetitorStatToJSON)),
        'paging_info': PagingInfoToJSON(value.pagingInfo),
    };
}

