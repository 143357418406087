/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SignalDto } from './SignalDto';
import {
    SignalDtoFromJSON,
    SignalDtoFromJSONTyped,
    SignalDtoToJSON,
} from './SignalDto';

/**
 * 
 * @export
 * @interface SupplierStatsSummary
 */
export interface SupplierStatsSummary {
    /**
     * 
     * @type {number}
     * @memberof SupplierStatsSummary
     */
    allRecordsCount: number;
    /**
     * 
     * @type {number}
     * @memberof SupplierStatsSummary
     */
    keywordRecordsCount: number;
    /**
     * 
     * @type {number}
     * @memberof SupplierStatsSummary
     */
    keyAccountsCount: number;
    /**
     * 
     * @type {number}
     * @memberof SupplierStatsSummary
     */
    expiriesCount: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierStatsSummary
     */
    latestActivity: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplierStatsSummary
     */
    signalName: string;
    /**
     * 
     * @type {Array<SignalDto>}
     * @memberof SupplierStatsSummary
     */
    signals: Array<SignalDto>;
}

/**
 * Check if a given object implements the SupplierStatsSummary interface.
 */
export function instanceOfSupplierStatsSummary(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "allRecordsCount" in value;
    isInstance = isInstance && "keywordRecordsCount" in value;
    isInstance = isInstance && "keyAccountsCount" in value;
    isInstance = isInstance && "expiriesCount" in value;
    isInstance = isInstance && "latestActivity" in value;
    isInstance = isInstance && "signalName" in value;
    isInstance = isInstance && "signals" in value;

    return isInstance;
}

export function SupplierStatsSummaryFromJSON(json: any): SupplierStatsSummary {
    return SupplierStatsSummaryFromJSONTyped(json, false);
}

export function SupplierStatsSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierStatsSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allRecordsCount': json['all_records_count'],
        'keywordRecordsCount': json['keyword_records_count'],
        'keyAccountsCount': json['key_accounts_count'],
        'expiriesCount': json['expiries_count'],
        'latestActivity': json['latest_activity'],
        'signalName': json['signal_name'],
        'signals': ((json['signals'] as Array<any>).map(SignalDtoFromJSON)),
    };
}

export function SupplierStatsSummaryToJSON(value?: SupplierStatsSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'all_records_count': value.allRecordsCount,
        'keyword_records_count': value.keywordRecordsCount,
        'key_accounts_count': value.keyAccountsCount,
        'expiries_count': value.expiriesCount,
        'latest_activity': value.latestActivity,
        'signal_name': value.signalName,
        'signals': ((value.signals as Array<any>).map(SignalDtoToJSON)),
    };
}

