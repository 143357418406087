/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NoticeSignalsRequestSignalsInner } from './NoticeSignalsRequestSignalsInner';
import {
    NoticeSignalsRequestSignalsInnerFromJSON,
    NoticeSignalsRequestSignalsInnerFromJSONTyped,
    NoticeSignalsRequestSignalsInnerToJSON,
} from './NoticeSignalsRequestSignalsInner';

/**
 * 
 * @export
 * @interface NoticeSignalsRequest
 */
export interface NoticeSignalsRequest {
    /**
     * Query identifier, used for logging & debugging.
     * @type {string}
     * @memberof NoticeSignalsRequest
     */
    id: string;
    /**
     * The language codes to be used for the search. Internally we currently support en, de, es, fr and nl. Other languages will fall back to the default analyser.
     * @type {Array<string>}
     * @memberof NoticeSignalsRequest
     */
    languages: Array<string>;
    /**
     * The ids of the notice/framework/document to be matched against the query.
     * @type {Array<string>}
     * @memberof NoticeSignalsRequest
     */
    ids: Array<string>;
    /**
     * 
     * @type {Array<NoticeSignalsRequestSignalsInner>}
     * @memberof NoticeSignalsRequest
     */
    signals: Array<NoticeSignalsRequestSignalsInner>;
}

/**
 * Check if a given object implements the NoticeSignalsRequest interface.
 */
export function instanceOfNoticeSignalsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "languages" in value;
    isInstance = isInstance && "ids" in value;
    isInstance = isInstance && "signals" in value;

    return isInstance;
}

export function NoticeSignalsRequestFromJSON(json: any): NoticeSignalsRequest {
    return NoticeSignalsRequestFromJSONTyped(json, false);
}

export function NoticeSignalsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoticeSignalsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'languages': json['languages'],
        'ids': json['ids'],
        'signals': ((json['signals'] as Array<any>).map(NoticeSignalsRequestSignalsInnerFromJSON)),
    };
}

export function NoticeSignalsRequestToJSON(value?: NoticeSignalsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'languages': value.languages,
        'ids': value.ids,
        'signals': ((value.signals as Array<any>).map(NoticeSignalsRequestSignalsInnerToJSON)),
    };
}

