/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommentDtoAuthor
 */
export interface CommentDtoAuthor {
    /**
     * 
     * @type {string}
     * @memberof CommentDtoAuthor
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDtoAuthor
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDtoAuthor
     */
    lastName: string;
}

/**
 * Check if a given object implements the CommentDtoAuthor interface.
 */
export function instanceOfCommentDtoAuthor(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;

    return isInstance;
}

export function CommentDtoAuthorFromJSON(json: any): CommentDtoAuthor {
    return CommentDtoAuthorFromJSONTyped(json, false);
}

export function CommentDtoAuthorFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentDtoAuthor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guid': json['guid'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
    };
}

export function CommentDtoAuthorToJSON(value?: CommentDtoAuthor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guid': value.guid,
        'first_name': value.firstName,
        'last_name': value.lastName,
    };
}

