/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RequestOutreachContactsRequestContactFilters } from './RequestOutreachContactsRequestContactFilters';
import {
    RequestOutreachContactsRequestContactFiltersFromJSON,
    RequestOutreachContactsRequestContactFiltersFromJSONTyped,
    RequestOutreachContactsRequestContactFiltersToJSON,
} from './RequestOutreachContactsRequestContactFilters';
import type { RequestOutreachContactsRequestNoticeFilters } from './RequestOutreachContactsRequestNoticeFilters';
import {
    RequestOutreachContactsRequestNoticeFiltersFromJSON,
    RequestOutreachContactsRequestNoticeFiltersFromJSONTyped,
    RequestOutreachContactsRequestNoticeFiltersToJSON,
} from './RequestOutreachContactsRequestNoticeFilters';

/**
 * 
 * @export
 * @interface RequestOutreachContactsRequest
 */
export interface RequestOutreachContactsRequest {
    /**
     * 
     * @type {RequestOutreachContactsRequestNoticeFilters}
     * @memberof RequestOutreachContactsRequest
     */
    noticeFilters?: RequestOutreachContactsRequestNoticeFilters;
    /**
     * 
     * @type {RequestOutreachContactsRequestContactFilters}
     * @memberof RequestOutreachContactsRequest
     */
    contactFilters?: RequestOutreachContactsRequestContactFilters;
    /**
     * 
     * @type {string}
     * @memberof RequestOutreachContactsRequest
     */
    additionalInformation?: string | null;
}

/**
 * Check if a given object implements the RequestOutreachContactsRequest interface.
 */
export function instanceOfRequestOutreachContactsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RequestOutreachContactsRequestFromJSON(json: any): RequestOutreachContactsRequest {
    return RequestOutreachContactsRequestFromJSONTyped(json, false);
}

export function RequestOutreachContactsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestOutreachContactsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'noticeFilters': !exists(json, 'notice_filters') ? undefined : RequestOutreachContactsRequestNoticeFiltersFromJSON(json['notice_filters']),
        'contactFilters': !exists(json, 'contact_filters') ? undefined : RequestOutreachContactsRequestContactFiltersFromJSON(json['contact_filters']),
        'additionalInformation': !exists(json, 'additional_information') ? undefined : json['additional_information'],
    };
}

export function RequestOutreachContactsRequestToJSON(value?: RequestOutreachContactsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notice_filters': RequestOutreachContactsRequestNoticeFiltersToJSON(value.noticeFilters),
        'contact_filters': RequestOutreachContactsRequestContactFiltersToJSON(value.contactFilters),
        'additional_information': value.additionalInformation,
    };
}

