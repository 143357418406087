/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProcurementStageDtoProcurementStageQualificationDto } from './ProcurementStageDtoProcurementStageQualificationDto';
import {
    ProcurementStageDtoProcurementStageQualificationDtoFromJSON,
    ProcurementStageDtoProcurementStageQualificationDtoFromJSONTyped,
    ProcurementStageDtoProcurementStageQualificationDtoToJSON,
} from './ProcurementStageDtoProcurementStageQualificationDto';
import type { ProcurementStageDtoQualificationStateDto } from './ProcurementStageDtoQualificationStateDto';
import {
    ProcurementStageDtoQualificationStateDtoFromJSON,
    ProcurementStageDtoQualificationStateDtoFromJSONTyped,
    ProcurementStageDtoQualificationStateDtoToJSON,
} from './ProcurementStageDtoQualificationStateDto';

/**
 * 
 * @export
 * @interface ProcurementStageDto
 */
export interface ProcurementStageDto {
    /**
     * 
     * @type {string}
     * @memberof ProcurementStageDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProcurementStageDto
     */
    stage: string;
    /**
     * 
     * @type {string}
     * @memberof ProcurementStageDto
     */
    recordFamilyId: string;
    /**
     * 
     * @type {ProcurementStageDtoQualificationStateDto}
     * @memberof ProcurementStageDto
     */
    qualification?: ProcurementStageDtoQualificationStateDto;
    /**
     * 
     * @type {ProcurementStageDtoProcurementStageQualificationDto}
     * @memberof ProcurementStageDto
     */
    procurementStageQualification?: ProcurementStageDtoProcurementStageQualificationDto;
}

/**
 * Check if a given object implements the ProcurementStageDto interface.
 */
export function instanceOfProcurementStageDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "stage" in value;
    isInstance = isInstance && "recordFamilyId" in value;

    return isInstance;
}

export function ProcurementStageDtoFromJSON(json: any): ProcurementStageDto {
    return ProcurementStageDtoFromJSONTyped(json, false);
}

export function ProcurementStageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcurementStageDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'stage': json['stage'],
        'recordFamilyId': json['record_family_id'],
        'qualification': !exists(json, 'qualification') ? undefined : ProcurementStageDtoQualificationStateDtoFromJSON(json['qualification']),
        'procurementStageQualification': !exists(json, 'procurement_stage_qualification') ? undefined : ProcurementStageDtoProcurementStageQualificationDtoFromJSON(json['procurement_stage_qualification']),
    };
}

export function ProcurementStageDtoToJSON(value?: ProcurementStageDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'stage': value.stage,
        'record_family_id': value.recordFamilyId,
        'qualification': ProcurementStageDtoQualificationStateDtoToJSON(value.qualification),
        'procurement_stage_qualification': ProcurementStageDtoProcurementStageQualificationDtoToJSON(value.procurementStageQualification),
    };
}

