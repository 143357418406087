import React from "react";

import { Markdown } from "components/reports/builder_components/Markdown";

import css from "./Text.module.scss";

type RichTextProps = {
  value: string;
  id: string;
};

export function RichText({ value, id }: RichTextProps): JSX.Element {
  return (
    <div id={id}>
      <Markdown className={css.text} text={value} />
    </div>
  );
}
