import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";
import { getSignalTypes } from "lib/types/models";
import { updateCachedNotice, updateCachedNotices, updateCachedRecord } from "../notices/utils";

export type UpdateLeadAssigneeParams = {
  procurementStageId: string;
  procurementStageStage: string;
  recordGuid: string;
  signals: { name: string; category: string }[];
  score: number | null | undefined;
  user?: { guid: string; firstName: string; lastName: string; email: string };
  companyGuid: string;
};

export function useUpdateLeadAssignee(
  options?: UseMutationOptions<void, unknown, UpdateLeadAssigneeParams, unknown>,
) {
  const api = useOpenApi();
  const { logEvent } = useTracking();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ companyGuid, user, procurementStageId, recordGuid }: UpdateLeadAssigneeParams) => {
      return api.updateLeadAssignee({
        companyGuid,
        procurementStageId,
        updateLeadAssigneeRequest: { userGuid: user?.guid || null, recordId: recordGuid },
      });
    },
    {
      ...options,
      onSuccess: (data, vars, ctx) => {
        const { user, procurementStageStage, signals, score, recordGuid } = vars;
        const signalTypes = getSignalTypes(signals);

        logEvent(EventNames.assigneeActioned, {
          "User assigned": user ? user.email : "Unassigned",
          "Record guid": recordGuid,
          Stage: procurementStageStage,
          "Signal types": signalTypes ? Object.keys(signalTypes) : [],
          "Signal score": score,
          Signals: signalTypes,
        });

        // Update the cache with the new qualification
        updateCachedRecord(queryClient, recordGuid, (r) => ({ ...r, assignee: user }));

        updateCachedNotice(queryClient, recordGuid, (r) => ({ ...r, assignee: user }));
        updateCachedNotices(queryClient, recordGuid, (r) => ({ ...r, assignee: user }));

        options?.onSuccess?.(data, vars, ctx);
      },
    },
  );
}
