import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { MarkNotificationsAsReadRequest } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";

type vars = {
  notificationIds: MarkNotificationsAsReadRequest["notificationIds"];
  contextSource: string;
};
export function useMarkNotificationsAsRead(
  options?: UseMutationOptions<unknown, unknown, vars, unknown>,
) {
  const api = useOpenApi();
  const qc = useQueryClient();
  const tracking = useTracking();

  return useMutation(
    async ({ notificationIds }: vars) =>
      api.markNotificationsAsRead({ markNotificationsAsReadRequest: { notificationIds } }),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await qc.invalidateQueries(["notifications"]);
        tracking.logEvent(EventNames.notificationMarkedAsRead, {
          "Context source": variables.contextSource,
          "Notification ID":
            variables.notificationIds.length === 1
              ? variables.notificationIds[0]
              : variables.notificationIds,
        });
        options?.onSuccess?.(data, variables, context);
      },
    },
  );
}
