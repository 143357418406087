import React from "react";
import { Select, Spin } from "antd5";

import { createUseDebounce } from "lib/debounce";
import { Account } from "lib/generated/integration-api";
import { useAccountSearchProvider } from "lib/hooks/api/integrations/useAccountSearchProvider";
import FormItemWrapper from "../FormItemWrapper";

import css from "../formStyles.module.scss";

const OPTIONS_LIMIT = 50;
const MIN_SEARCH_CHARACTERS = 1;

type AccountSearchProps = {
  id?: string;
  label: string;
  required?: boolean;
  value: string;
  updateValue: (value: string) => void;
  errors: string;
  description?: string;
};

const { Option } = Select;
const useDebounce200 = createUseDebounce(200);

function getOptions({ accounts, moreExist }: { accounts?: Account[]; moreExist?: boolean }) {
  const arr = [];
  if (moreExist)
    arr.push(
      <Option key={"moreExist"} value="moreExist" disabled>
        {OPTIONS_LIMIT}+ options found, narrow your search
      </Option>,
    );
  if (accounts) {
    arr.push(
      ...accounts.map((x) => (
        <Option key={x.id} value={x.id}>
          {x.name}
        </Option>
      )),
    );
  }
  return arr;
}

export function AccountSearch({
  id,
  label,
  required,
  value,
  updateValue,
  errors,
  description,
}: AccountSearchProps): JSX.Element {
  const [textSearch, setTextSearch] = React.useState("");
  // We only allosw searching if the user has typed a minimum amount of search characters
  const allowedToFetch = React.useMemo(
    () => textSearch.length >= MIN_SEARCH_CHARACTERS,
    [textSearch],
  );
  const { data, isLoading, isFetching } = useAccountSearchProvider(textSearch, OPTIONS_LIMIT, {
    enabled: allowedToFetch,
  });

  const accounts = data?.accounts;
  const moreExist = data?.moreExist;

  const debouncedTextSearch = useDebounce200(setTextSearch);

  const options = React.useMemo(() => {
    if (!allowedToFetch) {
      return (
        <Option key={"searchPrompt"} value="searchPrompt" disabled>
          Please search with at least two characters
        </Option>
      );
    } else if (!accounts && isLoading) {
      return (
        <Option key={"loading"} value="loading" disabled>
          <Spin size="small" /> Loading
        </Option>
      );
    } else return getOptions({ accounts, moreExist });
  }, [accounts, isLoading, allowedToFetch, moreExist]);

  return (
    <FormItemWrapper
      formIdentifier="string"
      label={label}
      required={required}
      errors={errors}
      description={description}
    >
      <Select
        id={id}
        loading={isFetching}
        allowClear={!required}
        defaultValue={value}
        placeholder="Search Accounts..."
        className={css.fullWidth}
        showSearch
        onSearch={(v) => debouncedTextSearch(v)}
        filterOption={false}
        onChange={(o: string) => {
          updateValue(o.toString() ?? "");
        }}
      >
        {options}
      </Select>
    </FormItemWrapper>
  );
}
