/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyerDto } from './BuyerDto';
import {
    BuyerDtoFromJSON,
    BuyerDtoFromJSONTyped,
    BuyerDtoToJSON,
} from './BuyerDto';

/**
 * 
 * @export
 * @interface BuyerListDto
 */
export interface BuyerListDto {
    /**
     * 
     * @type {string}
     * @memberof BuyerListDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerListDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerListDto
     */
    ownerId: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerListDto
     */
    sharingType: string;
    /**
     * 
     * @type {Date}
     * @memberof BuyerListDto
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof BuyerListDto
     */
    updatedAt: Date;
    /**
     * 
     * @type {Array<BuyerDto>}
     * @memberof BuyerListDto
     */
    entries: Array<BuyerDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BuyerListDto
     */
    permissionActions: Array<string>;
}

/**
 * Check if a given object implements the BuyerListDto interface.
 */
export function instanceOfBuyerListDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "ownerId" in value;
    isInstance = isInstance && "sharingType" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "updatedAt" in value;
    isInstance = isInstance && "entries" in value;
    isInstance = isInstance && "permissionActions" in value;

    return isInstance;
}

export function BuyerListDtoFromJSON(json: any): BuyerListDto {
    return BuyerListDtoFromJSONTyped(json, false);
}

export function BuyerListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyerListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'ownerId': json['owner_id'],
        'sharingType': json['sharing_type'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
        'entries': ((json['entries'] as Array<any>).map(BuyerDtoFromJSON)),
        'permissionActions': json['permission_actions'],
    };
}

export function BuyerListDtoToJSON(value?: BuyerListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'owner_id': value.ownerId,
        'sharing_type': value.sharingType,
        'created_at': (value.createdAt.toISOString()),
        'updated_at': (value.updatedAt.toISOString()),
        'entries': ((value.entries as Array<any>).map(BuyerDtoToJSON)),
        'permission_actions': value.permissionActions,
    };
}

