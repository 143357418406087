/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecordSearchConfigCompanyRecordFilter } from './RecordSearchConfigCompanyRecordFilter';
import {
    RecordSearchConfigCompanyRecordFilterFromJSON,
    RecordSearchConfigCompanyRecordFilterFromJSONTyped,
    RecordSearchConfigCompanyRecordFilterToJSON,
} from './RecordSearchConfigCompanyRecordFilter';
import type { RecordSearchConfigRecordFilter } from './RecordSearchConfigRecordFilter';
import {
    RecordSearchConfigRecordFilterFromJSON,
    RecordSearchConfigRecordFilterFromJSONTyped,
    RecordSearchConfigRecordFilterToJSON,
} from './RecordSearchConfigRecordFilter';

/**
 * 
 * @export
 * @interface RecordSearchConfig
 */
export interface RecordSearchConfig {
    /**
     * 
     * @type {RecordSearchConfigRecordFilter}
     * @memberof RecordSearchConfig
     */
    recordFilter?: RecordSearchConfigRecordFilter;
    /**
     * 
     * @type {RecordSearchConfigCompanyRecordFilter}
     * @memberof RecordSearchConfig
     */
    companyRecordFilter?: RecordSearchConfigCompanyRecordFilter;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfig
     */
    allowedDataTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchConfig
     */
    sortField: string;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchConfig
     */
    sortOrder: string;
    /**
     * 
     * @type {number}
     * @memberof RecordSearchConfig
     */
    offset: number;
    /**
     * 
     * @type {number}
     * @memberof RecordSearchConfig
     */
    limit?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchConfig
     */
    sortBySignalsOrder?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RecordSearchConfig
     */
    deduplicateResults?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RecordSearchConfig
     */
    enableCountryAccessCheck: boolean;
}

/**
 * Check if a given object implements the RecordSearchConfig interface.
 */
export function instanceOfRecordSearchConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sortField" in value;
    isInstance = isInstance && "sortOrder" in value;
    isInstance = isInstance && "offset" in value;
    isInstance = isInstance && "enableCountryAccessCheck" in value;

    return isInstance;
}

export function RecordSearchConfigFromJSON(json: any): RecordSearchConfig {
    return RecordSearchConfigFromJSONTyped(json, false);
}

export function RecordSearchConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSearchConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordFilter': !exists(json, 'record_filter') ? undefined : RecordSearchConfigRecordFilterFromJSON(json['record_filter']),
        'companyRecordFilter': !exists(json, 'company_record_filter') ? undefined : RecordSearchConfigCompanyRecordFilterFromJSON(json['company_record_filter']),
        'allowedDataTypes': !exists(json, 'allowed_data_types') ? undefined : json['allowed_data_types'],
        'sortField': json['sort_field'],
        'sortOrder': json['sort_order'],
        'offset': json['offset'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'sortBySignalsOrder': !exists(json, 'sort_by_signals_order') ? undefined : json['sort_by_signals_order'],
        'deduplicateResults': !exists(json, 'deduplicate_results') ? undefined : json['deduplicate_results'],
        'enableCountryAccessCheck': json['enable_country_access_check'],
    };
}

export function RecordSearchConfigToJSON(value?: RecordSearchConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'record_filter': RecordSearchConfigRecordFilterToJSON(value.recordFilter),
        'company_record_filter': RecordSearchConfigCompanyRecordFilterToJSON(value.companyRecordFilter),
        'allowed_data_types': value.allowedDataTypes,
        'sort_field': value.sortField,
        'sort_order': value.sortOrder,
        'offset': value.offset,
        'limit': value.limit,
        'sort_by_signals_order': value.sortBySignalsOrder,
        'deduplicate_results': value.deduplicateResults,
        'enable_country_access_check': value.enableCountryAccessCheck,
    };
}

