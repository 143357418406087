import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { SendContactRequest, SendContactResponse } from "lib/generated/integration-api";
import { useIntegrationApi } from "lib/integrationApiContext";

export function useSendContact(
  options?: UseMutationOptions<SendContactResponse, unknown, SendContactRequest, unknown>,
) {
  const api = useIntegrationApi();
  const queryClient = useQueryClient();
  return useMutation(
    ({ ...request }: SendContactRequest) =>
      api.sendContact({
        sendContactRequest: request,
      }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        if (options?.onSuccess) {
          options.onSuccess(data, variables, context);
        }
        void queryClient.invalidateQueries(["contactDetails", variables.contactId]);
      },
    },
  );
}
