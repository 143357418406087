import * as React from "react";
import { BehaviorSubject } from "rxjs";

// TODO: Add helpers that we can use in class-based components

// We use a numeric enum so we can easily compare sizes, e.g `if (currentSize < ScreenSize.LG) {...}`
export enum ScreenSize {
  XS,
  SM,
  MD,
  LG,
  XL,
  XXL,
}

interface ResponsivenessSvc {
  readonly screenSize: BehaviorSubject<ScreenSize>;
}

class InvalidResponsivenessSvc implements ResponsivenessSvc {
  get screenSize(): BehaviorSubject<ScreenSize> {
    throw new Error("Accessing ResponsivenessSvc without a provider.");
  }
}

const ResponsivenessSvcContext = React.createContext<ResponsivenessSvc>(
  new InvalidResponsivenessSvc(),
);

function useObservable<T>(o: BehaviorSubject<T>) {
  const [value, setValue] = React.useState(o.getValue());
  React.useEffect(() => {
    const subscription = o.subscribe((nextValue) => {
      setValue(nextValue);
    });
    return () => subscription.unsubscribe();
  }, [o]);
  return value;
}

export function useResponsiveScreenSize(): ScreenSize {
  const svc = React.useContext(ResponsivenessSvcContext);
  return useObservable(svc.screenSize);
}

class ResponsivenessSvcImpl implements ResponsivenessSvc {
  screenSize: BehaviorSubject<ScreenSize>;
  constructor() {
    this.screenSize = new BehaviorSubject<ScreenSize>(this.calculateSize());
  }

  mount() {
    window.addEventListener("resize", this.handleResize, { passive: true });
  }
  unmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  private calculateSize() {
    /**
     * Breakpoints are based on antd's breakpoints (which match Bootstrap 4)
     */
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1600) {
      return ScreenSize.XXL;
    } else if (screenWidth >= 1200) {
      return ScreenSize.XL;
    } else if (screenWidth >= 992) {
      return ScreenSize.LG;
    } else if (screenWidth >= 768) {
      return ScreenSize.MD;
    } else if (screenWidth >= 576) {
      return ScreenSize.SM;
    } else {
      return ScreenSize.XS;
    }
  }

  private handleResize = (_e: UIEvent) => {
    this.screenSize.next(this.calculateSize());
  };
}

export function ResponsivenessSvcProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const svc = React.useMemo(() => {
    return new ResponsivenessSvcImpl();
  }, []);

  React.useEffect(() => {
    svc.mount();
    return () => {
      svc.unmount();
    };
  }, [svc]);

  return (
    <ResponsivenessSvcContext.Provider value={svc}>{children}</ResponsivenessSvcContext.Provider>
  );
}
