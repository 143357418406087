import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { CommentDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

export function useComments(
  procurementStageId: string,
  options?: UseQueryOptions<CommentDto[], unknown, CommentDto[], string[]>,
) {
  const api = useOpenApi();
  const currentUser = window.currentUser;
  return useQuery(
    ["comments", procurementStageId],
    async () => {
      if (!currentUser) {
        return [];
      }
      const response = await api.getProcurementStageComments({
        procurementStageId,
        companyGuid: currentUser.company.guid,
      });
      return response.comments;
    },
    options,
  );
}
