/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PagingInfo } from './PagingInfo';
import {
    PagingInfoFromJSON,
    PagingInfoFromJSONTyped,
    PagingInfoToJSON,
} from './PagingInfo';
import type { SupplierSignalBuyerStats } from './SupplierSignalBuyerStats';
import {
    SupplierSignalBuyerStatsFromJSON,
    SupplierSignalBuyerStatsFromJSONTyped,
    SupplierSignalBuyerStatsToJSON,
} from './SupplierSignalBuyerStats';

/**
 * 
 * @export
 * @interface SupplierSignalBuyerResponse
 */
export interface SupplierSignalBuyerResponse {
    /**
     * 
     * @type {Array<SupplierSignalBuyerStats>}
     * @memberof SupplierSignalBuyerResponse
     */
    results: Array<SupplierSignalBuyerStats>;
    /**
     * 
     * @type {PagingInfo}
     * @memberof SupplierSignalBuyerResponse
     */
    pagingInfo: PagingInfo;
}

/**
 * Check if a given object implements the SupplierSignalBuyerResponse interface.
 */
export function instanceOfSupplierSignalBuyerResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;
    isInstance = isInstance && "pagingInfo" in value;

    return isInstance;
}

export function SupplierSignalBuyerResponseFromJSON(json: any): SupplierSignalBuyerResponse {
    return SupplierSignalBuyerResponseFromJSONTyped(json, false);
}

export function SupplierSignalBuyerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierSignalBuyerResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(SupplierSignalBuyerStatsFromJSON)),
        'pagingInfo': PagingInfoFromJSON(json['paging_info']),
    };
}

export function SupplierSignalBuyerResponseToJSON(value?: SupplierSignalBuyerResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(SupplierSignalBuyerStatsToJSON)),
        'paging_info': PagingInfoToJSON(value.pagingInfo),
    };
}

