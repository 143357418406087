import { ThemeConfig } from "antd5";

import { yellow600 } from "./colors";

export const GoldenCollapse: ThemeConfig = {
  components: {
    Collapse: {
      colorBorder: yellow600,
    },
  },
};
