/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PersonalSubscriptionContent } from './PersonalSubscriptionContent';
import {
    PersonalSubscriptionContentFromJSON,
    PersonalSubscriptionContentFromJSONTyped,
    PersonalSubscriptionContentToJSON,
} from './PersonalSubscriptionContent';

/**
 * 
 * @export
 * @interface SubscriptionContentsResponse
 */
export interface SubscriptionContentsResponse {
    /**
     * 
     * @type {Array<PersonalSubscriptionContent>}
     * @memberof SubscriptionContentsResponse
     */
    personalSubscriptions: Array<PersonalSubscriptionContent>;
}

/**
 * Check if a given object implements the SubscriptionContentsResponse interface.
 */
export function instanceOfSubscriptionContentsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "personalSubscriptions" in value;

    return isInstance;
}

export function SubscriptionContentsResponseFromJSON(json: any): SubscriptionContentsResponse {
    return SubscriptionContentsResponseFromJSONTyped(json, false);
}

export function SubscriptionContentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionContentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personalSubscriptions': ((json['personal_subscriptions'] as Array<any>).map(PersonalSubscriptionContentFromJSON)),
    };
}

export function SubscriptionContentsResponseToJSON(value?: SubscriptionContentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personal_subscriptions': ((value.personalSubscriptions as Array<any>).map(PersonalSubscriptionContentToJSON)),
    };
}

