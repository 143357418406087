import React from "react";

import { checkOutlined, plusCircleOutlined } from "lib/icons/untitled_ui/SVGs";
import PlanComparisonTable from "./PlanComparisonTable";

import css from "./SubscriptionPlansDetails.module.scss";

export type DataSource = {
  feature: string;
  forFree: JSX.Element;
  forGrowth: JSX.Element;
  forExpert: JSX.Element;
};

export function SubscriptionPlansDetails() {
  function textWithIcon(text: string, icon: JSX.Element) {
    return (
      <div className={css.textWithIcon}>
        {icon}
        <span className={css.text}>{text}</span>
      </div>
    );
  }

  const includedTextWithIcon = textWithIcon(
    "Included",
    <span className={css.includedIcon}>{checkOutlined}</span>,
  );

  const selfServeTextWithIcon = textWithIcon(
    "Self-serve",
    <span className={css.includedIcon}>{checkOutlined}</span>,
  );
  const supportedTextWithIcon = textWithIcon(
    "Supported",
    <span className={css.includedIcon}>{checkOutlined}</span>,
  );
  const customisedTextWithIcon = textWithIcon(
    "Customised",
    <span className={css.includedIcon}>{checkOutlined}</span>,
  );
  const addonTextWithIcon = textWithIcon(
    "Add on",
    <span className={css.addonIcon}> {plusCircleOutlined} </span>,
  );
  const notIncluded = <></>;
  const basicTextWithIcon = textWithIcon(
    "Basic",
    <span className={css.includedIcon}>{checkOutlined}</span>,
  );
  const advancedTextWithIcon = textWithIcon(
    "Advanced",
    <span className={css.includedIcon}>{checkOutlined}</span>,
  );

  const includedForAllPlans = () => {
    return {
      forFree: includedTextWithIcon,
      forGrowth: includedTextWithIcon,
      forExpert: includedTextWithIcon,
    };
  };

  const includedForAllPlansExceptFree = () => {
    return {
      forFree: notIncluded,
      forGrowth: includedTextWithIcon,
      forExpert: includedTextWithIcon,
    };
  };

  const includedForExpertAddonGrowth = () => {
    return {
      forFree: notIncluded,
      forGrowth: addonTextWithIcon,
      forExpert: includedTextWithIcon,
    };
  };

  const basicForGrowthAdvancedForExpert = () => {
    return {
      forFree: notIncluded,
      forGrowth: basicTextWithIcon,
      forExpert: advancedTextWithIcon,
    };
  };

  const includedForExpert = () => {
    return {
      forFree: notIncluded,
      forGrowth: notIncluded,
      forExpert: includedTextWithIcon,
    };
  };

  const selfServeFreeSupportedGrowthCustomisedExpert = () => {
    return {
      forFree: selfServeTextWithIcon,
      forGrowth: supportedTextWithIcon,
      forExpert: customisedTextWithIcon,
    };
  };

  const essentialsContent: DataSource[] = [
    {
      feature: "Users",
      forFree: textWithIcon("Users", <span className={css.includedIcon}>{checkOutlined}</span>),
      forGrowth: textWithIcon(
        "5 users maximum",
        <span className={css.includedIcon}>{checkOutlined}</span>,
      ),
      forExpert: textWithIcon("Custom", <span className={css.includedIcon}>{checkOutlined}</span>),
    },
  ];

  const dataContent: DataSource[] = [
    {
      feature: "Tenders",
      ...includedForAllPlans(),
    },
    {
      feature: "Pre-Tenders & PINS data",
      ...includedForAllPlans(),
    },
    {
      feature: "Procurement contact information",
      ...includedForAllPlans(),
    },
    {
      feature: "Historical contract awards & framework data",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Buyer intelligence",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Partner intelligence",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Competitor intelligence",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Key decision maker contact information",
      ...includedForExpertAddonGrowth(),
    },
    {
      feature: "Strategic documents & minutes data",
      ...includedForExpertAddonGrowth(),
    },
    {
      feature: "Invoiced spend data",
      ...includedForExpertAddonGrowth(),
    },
    {
      feature: "Framework data",
      ...includedForExpertAddonGrowth(),
    },
  ];

  const toolsContent: DataSource[] = [
    {
      feature: "Saved views & lists",
      ...includedForAllPlans(),
    },
    {
      feature: "Team tagging & commenting",
      ...includedForAllPlans(),
    },
    {
      feature: "Customisable notifications",
      ...includedForAllPlans(),
    },
    { feature: "Target buyer mapping and monitoring", ...includedForAllPlans() },
    {
      feature: "Geographic filters",
      ...includedForAllPlans(),
    },
    {
      feature: "Signal scores",
      ...includedForAllPlans(),
    },
    {
      feature: "CRM integrations (Salesforce & Hubspot)",
      ...selfServeFreeSupportedGrowthCustomisedExpert(),
    },
    {
      feature: "Collaboration tool integrations (MS Teams & Slack)",
      ...selfServeFreeSupportedGrowthCustomisedExpert(),
    },
    {
      feature: "Relevant buyer-intent rankings",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Uncapped procurement data exports",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Partner relationship mapping & monitoring",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Enterprise SAML/SSO",
      ...includedForExpert(),
    },
    {
      feature:
        "Advanced teaming tools & configurations for multiple teams, territories & product lines.",
      ...includedForExpert(),
    },
  ];

  const customerSuccessContent: DataSource[] = [
    {
      feature: "Self-serve access to Stotles help centre support",
      ...includedForAllPlans(),
    },
    {
      feature: "On-boarding support",
      forFree: selfServeTextWithIcon,
      forGrowth: basicTextWithIcon,
      forExpert: textWithIcon(
        "Full configuration",
        <span className={css.includedIcon}>{checkOutlined}</span>,
      ),
    },
    {
      feature: "Live chat support (9am-6pm M - F)",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Dedicated Customer Success Manager",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "Configuration & technical support pack",
      ...basicForGrowthAdvancedForExpert(),
    },
    {
      feature: "Training & enablement sessions pack",
      ...basicForGrowthAdvancedForExpert(),
    },
    {
      feature: "Strategy and COR sessions pack",
      ...basicForGrowthAdvancedForExpert(),
    },
  ];

  const managedServicesContent: DataSource[] = [
    {
      feature: "Custom briefings & reports (credits)",
      ...includedForExpertAddonGrowth(),
    },
    {
      feature: "FOI requests (credits)",
      ...includedForExpertAddonGrowth(),
    },
  ];

  const aiFeaturesContent: DataSource[] = [
    {
      feature: "Tender Summaries",
      ...includedForAllPlans(),
    },
    {
      feature: "Award Summaries",
      ...includedForAllPlansExceptFree(),
    },
    {
      feature: "AI Outreach Builder",
      ...includedForAllPlansExceptFree(),
    },
  ];

  return (
    <div className={css.planDetailsSection} id={"planComparisonSection"}>
      <div className={css.description}>
        <h1>Compare our plans in detail.</h1>
        <p>
          Discover exactly what’s included in each of our offered plans and decide which works best
          for your pub-sec needs.
        </p>
      </div>

      <div className={css.planNames}>
        <div className={css.plan}>
          <p>{""}</p>
        </div>
        <div className={css.plan}>
          <p>Free</p>
        </div>
        <div className={css.plan}>
          <p>Growth</p>
        </div>
        <div className={css.plan}>
          <p>Expert</p>
        </div>
      </div>

      <div className={css.planComparisons}>
        <PlanComparisonTable header="Essentials" data={essentialsContent} />
        <PlanComparisonTable header="Data" data={dataContent} />
        <PlanComparisonTable header="Tools" data={toolsContent} />
        <PlanComparisonTable header="Customer Success" data={customerSuccessContent} />
        <PlanComparisonTable header="Managed services" data={managedServicesContent} />
        <PlanComparisonTable header="AI Features" data={aiFeaturesContent} />
      </div>
    </div>
  );
}
