import * as React from "react";
import { Spin } from "antd5";

import css from "./CentredSpinner.module.scss";

export default function CentredSpinner({ label }: { label?: string }): JSX.Element {
  return (
    <div className={css.container} aria-label={label}>
      <Spin />
    </div>
  );
}
