import * as React from "react";
import classNames from "classnames";

import { yellow, yellow600 } from "lib/themes/colors";

import css from "./DefaultIcon.module.scss";

type Props = {
  fill?: string;
  className?: string;
};

export default function PaywallStar({ fill = yellow, className }: Props): JSX.Element {
  return (
    <svg
      width="21"
      height="18"
      className={classNames(css.defaultIcon, className)}
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 3412" clipPath="url(#clip0_816_5678)">
        <g id="vuesax/outline/star">
          <g id="star">
            <path
              id="Vector"
              d="M5.78059 5.04972L1.53714 7.15468C1.24717 7.29851 0.887426 7.17733 0.743588 6.88736C0.599749 6.5974 0.720931 6.23765 1.0109 6.09381L5.25435 3.98886C5.54432 3.84502 5.90406 3.96621 6.0479 4.25617C6.19174 4.54614 6.07055 4.90589 5.78059 5.04972Z"
              fill={fill}
            />
            <path
              id="Vector_2"
              d="M8.57097 16.0036L6.44925 17.056C6.15928 17.1999 5.79954 17.0787 5.6557 16.7887C5.51186 16.4988 5.63304 16.139 5.92301 15.9952L8.04473 14.9427C8.3347 14.7989 8.69444 14.92 8.83828 15.21C8.98212 15.5 8.86094 15.8597 8.57097 16.0036Z"
              fill={fill}
            />
            <path
              id="Vector_3"
              d="M4.69946 11.7545L3.99222 12.1054C3.70225 12.2492 3.3425 12.128 3.19867 11.838C3.05483 11.5481 3.17601 11.1883 3.46598 11.0445L4.17322 10.6937C4.46319 10.5498 4.82293 10.671 4.96677 10.961C5.11061 11.251 4.98943 11.6107 4.69946 11.7545Z"
              fill={fill}
            />
          </g>
        </g>
        <g id="Vector_4">
          <path
            d="M20.1807 9.01105L16.9796 6.48574L17.2458 2.41706C17.2532 2.30572 17.2236 2.19501 17.1615 2.10229C17.006 1.86939 16.6901 1.80629 16.4559 1.96097L13.0654 4.22591L9.27786 2.71629C9.17358 2.67443 9.05927 2.66899 8.95211 2.69887C8.8222 2.7356 8.71218 2.82239 8.64624 2.94019C8.58029 3.05799 8.56381 3.19715 8.60041 3.32709L9.70551 7.25167L7.09868 10.3874C7.03793 10.4603 6.99881 10.5487 6.98577 10.6427C6.97272 10.7367 6.98627 10.8325 7.02488 10.9191C7.06348 11.0058 7.1256 11.0799 7.20419 11.1331C7.28277 11.1862 7.37469 11.2163 7.4695 11.2199L11.5438 11.3812L13.7205 14.829C13.7796 14.9236 13.87 14.996 13.9745 15.0343C14.2378 15.1313 14.5299 14.9971 14.6269 14.7338L16.0391 10.9084L19.9905 9.90314C20.0982 9.87544 20.1945 9.81357 20.2639 9.72518C20.4401 9.50579 20.4009 9.18589 20.1807 9.01105ZM15.1755 9.94734L14.0013 13.1285L12.1898 10.2622L8.80095 10.1279L10.9688 7.52044L10.0498 4.25565L13.2004 5.51132L16.0197 3.6286L15.7989 7.01151L18.4616 9.11221L15.1755 9.94734Z"
            fill={fill}
          />
          <path
            d="M15.1755 9.94734L14.0013 13.1285L12.1898 10.2622L8.80095 10.1279L10.9688 7.52044L10.0498 4.25565L13.2004 5.51132L16.0197 3.6286L15.7989 7.01151L18.4616 9.11221L15.1755 9.94734Z"
            fill={fill}
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_816_5678"
          x1="5.78478"
          y1="3.72574"
          x2="6.32695"
          y2="6.16161"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={yellow600} />
          <stop offset="1" stopColor={fill} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_816_5678"
          x1="8.57516"
          y1="14.6796"
          x2="8.7438"
          y2="17.0511"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={yellow600} />
          <stop offset="1" stopColor={fill} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_816_5678"
          x1="4.70365"
          y1="10.4306"
          x2="4.34206"
          y2="12.4657"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={yellow600} />
          <stop offset="1" stopColor={fill} />
        </linearGradient>
        <linearGradient
          id="paint3_linear_816_5678"
          x1="22.8205"
          y1="5.30893"
          x2="5.04499"
          y2="8.56429"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={yellow600} />
          <stop offset="1" stopColor={fill} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_816_5678"
          x1="22.8205"
          y1="5.30893"
          x2="5.04499"
          y2="8.56429"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={yellow600} />
          <stop offset="1" stopColor={fill} />
        </linearGradient>
        <clipPath id="clip0_816_5678">
          <rect width="20.8421" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
