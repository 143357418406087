/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DecisionTypeUpdateAttributes
 */
export interface DecisionTypeUpdateAttributes {
    /**
     * 
     * @type {string}
     * @memberof DecisionTypeUpdateAttributes
     */
    oscarId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DecisionTypeUpdateAttributes
     */
    companiesHouseId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DecisionTypeUpdateAttributes
     */
    isStotlesVerified?: boolean | null;
}

/**
 * Check if a given object implements the DecisionTypeUpdateAttributes interface.
 */
export function instanceOfDecisionTypeUpdateAttributes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DecisionTypeUpdateAttributesFromJSON(json: any): DecisionTypeUpdateAttributes {
    return DecisionTypeUpdateAttributesFromJSONTyped(json, false);
}

export function DecisionTypeUpdateAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): DecisionTypeUpdateAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oscarId': !exists(json, 'oscar_id') ? undefined : json['oscar_id'],
        'companiesHouseId': !exists(json, 'companies_house_id') ? undefined : json['companies_house_id'],
        'isStotlesVerified': !exists(json, 'is_stotles_verified') ? undefined : json['is_stotles_verified'],
    };
}

export function DecisionTypeUpdateAttributesToJSON(value?: DecisionTypeUpdateAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oscar_id': value.oscarId,
        'companies_house_id': value.companiesHouseId,
        'is_stotles_verified': value.isStotlesVerified,
    };
}

