/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FieldData } from './FieldData';
import {
    FieldDataFromJSON,
    FieldDataFromJSONTyped,
    FieldDataToJSON,
} from './FieldData';

/**
 * 
 * @export
 * @interface NoticeConfigBuilderRequest
 */
export interface NoticeConfigBuilderRequest {
    /**
     * 
     * @type {string}
     * @memberof NoticeConfigBuilderRequest
     */
    entityName: string;
    /**
     * 
     * @type {Array<FieldData>}
     * @memberof NoticeConfigBuilderRequest
     */
    fields: Array<FieldData>;
}

/**
 * Check if a given object implements the NoticeConfigBuilderRequest interface.
 */
export function instanceOfNoticeConfigBuilderRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entityName" in value;
    isInstance = isInstance && "fields" in value;

    return isInstance;
}

export function NoticeConfigBuilderRequestFromJSON(json: any): NoticeConfigBuilderRequest {
    return NoticeConfigBuilderRequestFromJSONTyped(json, false);
}

export function NoticeConfigBuilderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoticeConfigBuilderRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityName': json['entityName'],
        'fields': ((json['fields'] as Array<any>).map(FieldDataFromJSON)),
    };
}

export function NoticeConfigBuilderRequestToJSON(value?: NoticeConfigBuilderRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityName': value.entityName,
        'fields': ((value.fields as Array<any>).map(FieldDataToJSON)),
    };
}

