import * as React from "react";
import { Popover, PopoverProps } from "antd5";

import css from "./CtaPopover.module.scss";

type Props = {
  buttons: React.ReactNode[];
} & PopoverProps;

/**
 * Call-to-action Popover
 *
 * A reusable popover component which can display some `buttons` of your choosing
 *
 * @prop buttons Array<React.ReactNode>
 */
export default function CtaPopover(props: React.PropsWithChildren<Props>) {
  const { buttons, children, content, ...rest } = props;

  return (
    <Popover
      {...rest}
      content={
        <div>
          {content}
          <div className={css.buttons}>{buttons}</div>
        </div>
      }
    >
      {children}
    </Popover>
  );
}
