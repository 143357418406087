/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssignProcurementStage } from './AssignProcurementStage';
import {
    AssignProcurementStageFromJSON,
    AssignProcurementStageFromJSONTyped,
    AssignProcurementStageToJSON,
} from './AssignProcurementStage';
import type { CommentProcurementStage } from './CommentProcurementStage';
import {
    CommentProcurementStageFromJSON,
    CommentProcurementStageFromJSONTyped,
    CommentProcurementStageToJSON,
} from './CommentProcurementStage';
import type { MentionProcurementStage } from './MentionProcurementStage';
import {
    MentionProcurementStageFromJSON,
    MentionProcurementStageFromJSONTyped,
    MentionProcurementStageToJSON,
} from './MentionProcurementStage';
import type { QualifyProcurementStage } from './QualifyProcurementStage';
import {
    QualifyProcurementStageFromJSON,
    QualifyProcurementStageFromJSONTyped,
    QualifyProcurementStageToJSON,
} from './QualifyProcurementStage';

/**
 * 
 * @export
 * @interface NotificationDtoTextAttributes
 */
export interface NotificationDtoTextAttributes {
    /**
     * 
     * @type {CommentProcurementStage}
     * @memberof NotificationDtoTextAttributes
     */
    commentProcurementStage?: CommentProcurementStage;
    /**
     * 
     * @type {AssignProcurementStage}
     * @memberof NotificationDtoTextAttributes
     */
    assignProcurementStage?: AssignProcurementStage;
    /**
     * 
     * @type {QualifyProcurementStage}
     * @memberof NotificationDtoTextAttributes
     */
    qualifyProcurementStage?: QualifyProcurementStage;
    /**
     * 
     * @type {MentionProcurementStage}
     * @memberof NotificationDtoTextAttributes
     */
    mentionProcurementStage?: MentionProcurementStage;
}

/**
 * Check if a given object implements the NotificationDtoTextAttributes interface.
 */
export function instanceOfNotificationDtoTextAttributes(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationDtoTextAttributesFromJSON(json: any): NotificationDtoTextAttributes {
    return NotificationDtoTextAttributesFromJSONTyped(json, false);
}

export function NotificationDtoTextAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationDtoTextAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commentProcurementStage': !exists(json, 'comment_procurement_stage') ? undefined : CommentProcurementStageFromJSON(json['comment_procurement_stage']),
        'assignProcurementStage': !exists(json, 'assign_procurement_stage') ? undefined : AssignProcurementStageFromJSON(json['assign_procurement_stage']),
        'qualifyProcurementStage': !exists(json, 'qualify_procurement_stage') ? undefined : QualifyProcurementStageFromJSON(json['qualify_procurement_stage']),
        'mentionProcurementStage': !exists(json, 'mention_procurement_stage') ? undefined : MentionProcurementStageFromJSON(json['mention_procurement_stage']),
    };
}

export function NotificationDtoTextAttributesToJSON(value?: NotificationDtoTextAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'comment_procurement_stage': CommentProcurementStageToJSON(value.commentProcurementStage),
        'assign_procurement_stage': AssignProcurementStageToJSON(value.assignProcurementStage),
        'qualify_procurement_stage': QualifyProcurementStageToJSON(value.qualifyProcurementStage),
        'mention_procurement_stage': MentionProcurementStageToJSON(value.mentionProcurementStage),
    };
}

