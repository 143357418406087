import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { Account } from "lib/generated/integration-api";
import { useIntegrationApi } from "lib/integrationApiContext";

type QueryKey = [string, string | undefined];

export function useAccountSearchProvider(
  text: string,
  limit?: number,
  options?: UseQueryOptions<
    { accounts: Account[]; moreExist: boolean },
    unknown,
    { accounts: Account[]; moreExist: boolean },
    QueryKey
  >,
) {
  const api = useIntegrationApi();

  return useQuery(
    ["accountSearchProvider", text],
    async () => {
      const { accounts, moreExist } = await api.searchAccounts({
        searchAccountsRequest: { text, limit },
      });
      return { accounts, moreExist };
    },
    options,
  );
}
