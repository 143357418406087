/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchEntitiesRequest
 */
export interface SearchEntitiesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchEntitiesRequest
     */
    names: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SearchEntitiesRequest
     */
    entityType?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchEntitiesRequest
     */
    stotlesScoreThreshold?: number | null;
}

/**
 * Check if a given object implements the SearchEntitiesRequest interface.
 */
export function instanceOfSearchEntitiesRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "names" in value;

    return isInstance;
}

export function SearchEntitiesRequestFromJSON(json: any): SearchEntitiesRequest {
    return SearchEntitiesRequestFromJSONTyped(json, false);
}

export function SearchEntitiesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchEntitiesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'names': json['names'],
        'entityType': !exists(json, 'entity_type') ? undefined : json['entity_type'],
        'stotlesScoreThreshold': !exists(json, 'stotles_score_threshold') ? undefined : json['stotles_score_threshold'],
    };
}

export function SearchEntitiesRequestToJSON(value?: SearchEntitiesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'names': value.names,
        'entity_type': value.entityType,
        'stotles_score_threshold': value.stotlesScoreThreshold,
    };
}

