/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordTimelineObj
 */
export interface RecordTimelineObj {
    /**
     * 
     * @type {string}
     * @memberof RecordTimelineObj
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordTimelineObj
     */
    stotlesUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordTimelineObj
     */
    externalSourceUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordTimelineObj
     */
    stage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordTimelineObj
     */
    publishDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordTimelineObj
     */
    startDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordTimelineObj
     */
    endDate?: string | null;
}

/**
 * Check if a given object implements the RecordTimelineObj interface.
 */
export function instanceOfRecordTimelineObj(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RecordTimelineObjFromJSON(json: any): RecordTimelineObj {
    return RecordTimelineObjFromJSONTyped(json, false);
}

export function RecordTimelineObjFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordTimelineObj {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'stotlesUrl': !exists(json, 'stotles_url') ? undefined : json['stotles_url'],
        'externalSourceUrl': !exists(json, 'external_source_url') ? undefined : json['external_source_url'],
        'stage': !exists(json, 'stage') ? undefined : json['stage'],
        'publishDate': !exists(json, 'publish_date') ? undefined : json['publish_date'],
        'startDate': !exists(json, 'start_date') ? undefined : json['start_date'],
        'endDate': !exists(json, 'end_date') ? undefined : json['end_date'],
    };
}

export function RecordTimelineObjToJSON(value?: RecordTimelineObj | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'stotles_url': value.stotlesUrl,
        'external_source_url': value.externalSourceUrl,
        'stage': value.stage,
        'publish_date': value.publishDate,
        'start_date': value.startDate,
        'end_date': value.endDate,
    };
}

