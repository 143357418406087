import * as React from "react";
import { BellOutlined } from "@ant-design/icons";
import { Badge, ConfigProvider, Dropdown, Empty, MenuProps, Tooltip } from "antd5";
import { DateTime, Interval } from "luxon";

import TextButton from "components/actions/TextButton";
import UserInitials from "components/comments/UserInitials";
import CentredSpinner from "lib/core_components/CentredSpinner";
import { assertCurrentUser } from "lib/currentUser";
import { AssignProcurementStage } from "lib/generated/app-api/models/AssignProcurementStage";
import { CommentProcurementStage } from "lib/generated/app-api/models/CommentProcurementStage";
import { MentionProcurementStage } from "lib/generated/app-api/models/MentionProcurementStage";
import { NotificationDto } from "lib/generated/app-api/models/NotificationDto";
import { NotificationDtoTextAttributes } from "lib/generated/app-api/models/NotificationDtoTextAttributes";
import { QualifyProcurementStage } from "lib/generated/app-api/models/QualifyProcurementStage";
import { useMarkNotificationsAsRead } from "lib/hooks/api/notifications/useMarkNotificationsAsRead";
import { useMarkNotificationsAsUnread } from "lib/hooks/api/notifications/useMarkNotificationsAsUnread";
import { useNotifications } from "lib/hooks/api/notifications/useNotifications";
import { ShadowlessDropdown } from "lib/themes/ShadowlessDropdown";
import { EventNames, useTracking } from "lib/tracking";
import { truncate } from "lib/utils";
import { DateGranularity, toHumanRelativeTimeFromNow } from "lib/utils/relative_time";
import { NavBarButton } from "./AppLayoutNavbar";

import css from "./NotificationCentre.module.scss";

enum TimePeriod {
  TODAY = "Today",
  YESTERDAY = "Yesterday",
  PAST_WEEK = "Past week",
  PAST_MONTH = "Past month",
}

const TODAY_INTERVAL = Interval.fromDateTimes(
  DateTime.now().startOf("day"),
  DateTime.now().endOf("day"),
);
const YESTERDAY_INTERVAL = Interval.fromDateTimes(
  DateTime.now().minus({ days: 1 }).startOf("day"),
  DateTime.now().minus({ days: 1 }).endOf("day"),
);
const A_WEEK_AGO_INTERVAL = Interval.fromDateTimes(
  DateTime.now().minus({ week: 1 }).startOf("day"),
  DateTime.now().endOf("day"),
);
const A_MONTH_AGO_INTERVAL = Interval.fromDateTimes(
  DateTime.now().minus({ month: 1 }).startOf("day"),
  DateTime.now().endOf("day"),
);

const getTimePeriod = (date: string): TimePeriod | null => {
  const evaluatedDate = DateTime.fromISO(date);
  if (TODAY_INTERVAL.contains(evaluatedDate)) {
    return TimePeriod.TODAY;
  }
  if (YESTERDAY_INTERVAL.contains(evaluatedDate)) {
    return TimePeriod.YESTERDAY;
  }
  if (A_WEEK_AGO_INTERVAL.contains(evaluatedDate)) {
    return TimePeriod.PAST_WEEK;
  }
  if (A_MONTH_AGO_INTERVAL.contains(evaluatedDate)) {
    return TimePeriod.PAST_MONTH;
  }
  return null;
};

/** @deprecated - once vertical nav is fully implemented, this component should be removed */
export default function NotificationCentre(): JSX.Element {
  const { data, isLoading, refetch } = useNotifications({ enabled: !!window.currentUser });
  const { mutate: markNotificationsAsRead } = useMarkNotificationsAsRead();
  const { logEvent } = useTracking();

  const hasUnreadNotifications = data?.notifications.some((n) => !n.readAt);

  const notificationDropdown: MenuProps = React.useMemo(() => {
    const timePeriods: Record<TimePeriod, NotificationDto[]> = {
      [TimePeriod.TODAY]: [],
      [TimePeriod.YESTERDAY]: [],
      [TimePeriod.PAST_WEEK]: [],
      [TimePeriod.PAST_MONTH]: [],
    };

    if (isLoading || !data?.notifications.length) {
      return {
        items: [],
      };
    }

    const notificationsGroupedByTimePeriod =
      data?.notifications.reduce<Record<string, NotificationDto[]>>((collection, n) => {
        const dateGrouping = getTimePeriod(n.createdAt);
        // If the grouping is null it means it's more than a month old we don't show it
        if (!dateGrouping) return collection;

        collection[dateGrouping].push(n);

        return collection;
      }, timePeriods) || {};

    return {
      items: Object.entries(notificationsGroupedByTimePeriod)
        .filter(([_, ns]) => ns.length > 0)
        .map(([group, notifications]) => {
          return {
            key: group,
            label: group,
            type: "group",
            children: notifications
              .sort(({ createdAt: aCreatedAt }, { createdAt: bCreatedAt }) =>
                bCreatedAt.localeCompare(aCreatedAt),
              )
              .map((n) => {
                return {
                  key: n.id,
                  label: <Notification notification={n} />,
                };
              }),
          };
        }),
    };
  }, [isLoading, data]);

  return (
    <ConfigProvider theme={ShadowlessDropdown}>
      <Dropdown
        placement="bottomRight"
        dropdownRender={(menu) => (
          <NotificationDropdown
            menu={menu}
            markAllAsRead={() => {
              markNotificationsAsRead({
                notificationIds: data?.notifications.map((n) => n.id) || [],
                contextSource: "Bulk action",
              });
            }}
            notificationCount={data?.notifications.length || 0}
            loading={isLoading}
          />
        )}
        menu={notificationDropdown}
        trigger={["click"]}
        onOpenChange={async (visible) => {
          if (visible) {
            await refetch();
          }
        }}
      >
        <Badge dot={hasUnreadNotifications} offset={[-2, 5]}>
          <NavBarButton
            onClick={async () => {
              logEvent(EventNames.notificationCentreOpened, {});
            }}
          >
            <BellOutlined className={css.bell} />
          </NavBarButton>
        </Badge>
      </Dropdown>
    </ConfigProvider>
  );
}

type NotificationDropdownProps = {
  menu: React.ReactNode;
  markAllAsRead: () => void;
  notificationCount: number;
  loading: boolean;
};

function NotificationDropdown({
  menu,
  markAllAsRead,
  notificationCount,
  loading,
}: NotificationDropdownProps) {
  if (loading) {
    return (
      <div className={css.dropdownMenu}>
        <CentredSpinner />
      </div>
    );
  } else if (!notificationCount) {
    return (
      <div className={css.dropdownMenu}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No activity to display" />
      </div>
    );
  } else {
    return (
      <div className={css.dropdownMenu}>
        <div className={css.header}>
          <h4>Notifications</h4>

          <TextButton onClick={markAllAsRead}>Mark all as read</TextButton>
        </div>
        {menu}
      </div>
    );
  }
}

function assertTextAttributes(
  verb: "comment",
  textAttrs: NotificationDtoTextAttributes,
): CommentProcurementStage;
function assertTextAttributes(
  verb: "qualify",
  textAttrs: NotificationDtoTextAttributes,
): QualifyProcurementStage;
function assertTextAttributes(
  verb: "assign",
  textAttrs: NotificationDtoTextAttributes,
): AssignProcurementStage;
function assertTextAttributes(
  verb: "mention",
  textAttrs: NotificationDtoTextAttributes,
): MentionProcurementStage;
function assertTextAttributes(
  verb: string,
  textAttrs: NotificationDtoTextAttributes,
):
  | QualifyProcurementStage
  | AssignProcurementStage
  | CommentProcurementStage
  | MentionProcurementStage
  | never {
  switch (verb) {
    case "comment": {
      const attrs = textAttrs.commentProcurementStage;

      if (!attrs) throw `Invalid notification ${JSON.stringify(textAttrs)}`;

      return attrs;
    }
    case "assign": {
      const attrs = textAttrs.assignProcurementStage;

      if (!attrs) throw `Invalid notification ${JSON.stringify(textAttrs)}`;

      return attrs;
    }
    case "qualify": {
      const attrs = textAttrs.qualifyProcurementStage;

      if (!attrs) throw `Invalid notification ${JSON.stringify(textAttrs)}`;

      return attrs;
    }
    case "mention": {
      const attrs = textAttrs.mentionProcurementStage;

      if (!attrs) throw `Invalid notification ${JSON.stringify(textAttrs)}`;

      return attrs;
    }
    default:
      throw `invalid verb ${verb}`;
  }
}

const DELETED_COMMENT_PLACEHOLDER = "[This comment was deleted]";

export function Notification({ notification }: { notification: NotificationDto }) {
  const { mutate: markAsRead } = useMarkNotificationsAsRead();
  const { mutate: markAsUnread } = useMarkNotificationsAsUnread();
  const { logEvent } = useTracking();

  const notificationText = React.useMemo(() => {
    switch (notification.verb) {
      case "comment": {
        const { actorName, recordTitle, commentText, deletedAt } = assertTextAttributes(
          notification.verb,
          notification.textAttributes,
        );

        const truncatedRecordTitle = truncate(recordTitle, 50);
        const commentFragment = deletedAt ? (
          <em>{DELETED_COMMENT_PLACEHOLDER}</em>
        ) : (
          <q>{truncate(commentText, 50)}</q>
        );

        return (
          <>
            <b>{actorName}</b> commented on &quot;<b>{truncatedRecordTitle}</b>&quot;:
            <br />
            {commentFragment}
          </>
        );
      }
      case "qualify": {
        const { actorName, recordTitle, toStatusLabel } = assertTextAttributes(
          notification.verb,
          notification.textAttributes,
        );
        const truncatedRecordTitle = truncate(recordTitle, 50);
        return (
          <>
            <b>{actorName}</b> changed the qualification status of &quot;
            <b>{truncatedRecordTitle}</b>&quot; to {toStatusLabel}
          </>
        );
      }
      case "assign": {
        const { actorName, recordTitle, oldAssignee, newAssignee } = assertTextAttributes(
          notification.verb,
          notification.textAttributes,
        );
        const truncatedRecordTitle = truncate(recordTitle, 50);
        const currentUser = assertCurrentUser();

        let oldAssigneeText: string;
        if (oldAssignee) {
          if (oldAssignee.guid) {
            oldAssigneeText = oldAssignee.guid === currentUser.guid ? "you" : oldAssignee.name;
          } else {
            oldAssigneeText = "[User no longer exists]";
          }
        } else {
          oldAssigneeText = "Unassigned";
        }

        let newAssigneeText: string;
        if (newAssignee) {
          if (newAssignee.guid) {
            newAssigneeText = newAssignee.guid === currentUser.guid ? "you" : newAssignee.name;
          } else {
            newAssigneeText = "[User no longer exists]";
          }
        } else {
          newAssigneeText = "Unassigned";
        }

        return (
          <>
            <b>{actorName}</b> has assigned &quot;<b>{truncatedRecordTitle}</b>&quot; from{" "}
            {oldAssigneeText} to {newAssigneeText}
          </>
        );
      }
      case "mention": {
        const { actorName, recordTitle, commentText, deletedAt } = assertTextAttributes(
          notification.verb,
          notification.textAttributes,
        );

        const truncatedRecordTitle = truncate(recordTitle, 50);
        const commentFragment = deletedAt ? (
          <em>{DELETED_COMMENT_PLACEHOLDER}</em>
        ) : (
          <q>{truncate(commentText, 50)}</q>
        );

        return (
          <>
            <b>{actorName}</b> mentioned you in &quot;<b>{truncatedRecordTitle}</b>&quot;:
            <br />
            {commentFragment}
          </>
        );
      }
      default:
        throw `Unsupported notification type ${notification.verb}`;
    }
  }, [notification.verb, notification.textAttributes]);

  const toggleReadStatusBtn = React.useMemo(() => {
    let label: string;
    let cb: () => void;

    if (notification.readAt) {
      label = "Mark as unread";
      cb = () => markAsUnread([notification.id]);
    } else {
      label = "Mark as read";
      cb = () => markAsRead({ notificationIds: [notification.id], contextSource: "button" });
    }

    return (
      <TextButton
        onClick={(e) => {
          // So we don't navigate to the link
          e.preventDefault();
          // So we don't close the dropdown
          e.stopPropagation();
          cb();
        }}
        className={css.markAsRead}
      >
        {label}
      </TextButton>
    );
  }, [notification.readAt, notification.id, markAsRead, markAsUnread]);

  return (
    <div className={css.notification}>
      <div className={css.notificationHeader}>
        <div>
          <span>
            <Tooltip title={notification.createdAt.substr(0, 10)}>
              {toHumanRelativeTimeFromNow(notification.createdAt, DateGranularity.second)}
            </Tooltip>{" "}
            {!notification.readAt && <span className={css.redDot} />}
          </span>
        </div>
        {toggleReadStatusBtn}
      </div>
      <a
        href={notification.link}
        onClick={(e) => {
          // stop the link navgiating away before marking as read
          e.preventDefault();
          logEvent(EventNames.notificationClick, {
            "Destination url": notification.link,
            "Notification ID": notification.id,
          });
          markAsRead({ notificationIds: [notification.id], contextSource: "notification" });
          window.location.href = notification.link;
        }}
        className={css.notificationContent}
      >
        <div className={css.avatarSection}>
          <UserInitials
            firstName={notification.actor.firstName}
            lastName={notification.actor.lastName}
          />
        </div>
        <div className={css.text}>{notificationText}</div>
      </a>
    </div>
  );
}
