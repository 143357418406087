import React from "react";
import { InputNumber } from "antd5";

import FormItemWrapper from "../FormItemWrapper";

import css from "../formStyles.module.scss";

type NumberProps = {
  id?: string;
  label: string;
  required?: boolean;
  value: number;
  updateValue: (value: number | null) => void;
  errors: string;
  description?: string;
};

export function Number({
  id,
  label,
  required,
  value,
  updateValue,
  errors,
  description,
}: NumberProps): JSX.Element {
  return (
    <FormItemWrapper
      label={label}
      formIdentifier="string"
      required={required}
      errors={errors}
      description={description}
    >
      <InputNumber
        id={id}
        value={value}
        onChange={updateValue}
        className={css.fullWidth}
        required={required}
      />
    </FormItemWrapper>
  );
}
