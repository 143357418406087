/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestOutreachContactsRequestNoticeFilters
 */
export interface RequestOutreachContactsRequestNoticeFilters {
    /**
     * 
     * @type {string}
     * @memberof RequestOutreachContactsRequestNoticeFilters
     */
    noticeGuid: string;
    /**
     * 
     * @type {string}
     * @memberof RequestOutreachContactsRequestNoticeFilters
     */
    noticeTitle: string;
    /**
     * 
     * @type {string}
     * @memberof RequestOutreachContactsRequestNoticeFilters
     */
    buyerGuid: string;
    /**
     * 
     * @type {string}
     * @memberof RequestOutreachContactsRequestNoticeFilters
     */
    buyerName: string;
}

/**
 * Check if a given object implements the RequestOutreachContactsRequestNoticeFilters interface.
 */
export function instanceOfRequestOutreachContactsRequestNoticeFilters(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "noticeGuid" in value;
    isInstance = isInstance && "noticeTitle" in value;
    isInstance = isInstance && "buyerGuid" in value;
    isInstance = isInstance && "buyerName" in value;

    return isInstance;
}

export function RequestOutreachContactsRequestNoticeFiltersFromJSON(json: any): RequestOutreachContactsRequestNoticeFilters {
    return RequestOutreachContactsRequestNoticeFiltersFromJSONTyped(json, false);
}

export function RequestOutreachContactsRequestNoticeFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestOutreachContactsRequestNoticeFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'noticeGuid': json['notice_guid'],
        'noticeTitle': json['notice_title'],
        'buyerGuid': json['buyer_guid'],
        'buyerName': json['buyer_name'],
    };
}

export function RequestOutreachContactsRequestNoticeFiltersToJSON(value?: RequestOutreachContactsRequestNoticeFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notice_guid': value.noticeGuid,
        'notice_title': value.noticeTitle,
        'buyer_guid': value.buyerGuid,
        'buyer_name': value.buyerName,
    };
}

