/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BuyerCategoryDto
 */
export interface BuyerCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof BuyerCategoryDto
     */
    buyerCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerCategoryDto
     */
    source: string;
}

/**
 * Check if a given object implements the BuyerCategoryDto interface.
 */
export function instanceOfBuyerCategoryDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "buyerCategoryId" in value;
    isInstance = isInstance && "source" in value;

    return isInstance;
}

export function BuyerCategoryDtoFromJSON(json: any): BuyerCategoryDto {
    return BuyerCategoryDtoFromJSONTyped(json, false);
}

export function BuyerCategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyerCategoryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buyerCategoryId': json['buyer_category_id'],
        'source': json['source'],
    };
}

export function BuyerCategoryDtoToJSON(value?: BuyerCategoryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buyer_category_id': value.buyerCategoryId,
        'source': value.source,
    };
}

