/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PerCpvStats
 */
export interface PerCpvStats {
    /**
     * 
     * @type {string}
     * @memberof PerCpvStats
     */
    cpvCode: string;
    /**
     * 
     * @type {string}
     * @memberof PerCpvStats
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PerCpvStats
     */
    totalAwarded: number;
    /**
     * 
     * @type {number}
     * @memberof PerCpvStats
     */
    totalValue: number;
    /**
     * 
     * @type {number}
     * @memberof PerCpvStats
     */
    avgContractValue: number;
    /**
     * 
     * @type {number}
     * @memberof PerCpvStats
     */
    percentToSmes: number;
}

/**
 * Check if a given object implements the PerCpvStats interface.
 */
export function instanceOfPerCpvStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cpvCode" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "totalAwarded" in value;
    isInstance = isInstance && "totalValue" in value;
    isInstance = isInstance && "avgContractValue" in value;
    isInstance = isInstance && "percentToSmes" in value;

    return isInstance;
}

export function PerCpvStatsFromJSON(json: any): PerCpvStats {
    return PerCpvStatsFromJSONTyped(json, false);
}

export function PerCpvStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerCpvStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cpvCode': json['cpv_code'],
        'name': json['name'],
        'totalAwarded': json['total_awarded'],
        'totalValue': json['total_value'],
        'avgContractValue': json['avg_contract_value'],
        'percentToSmes': json['percent_to_smes'],
    };
}

export function PerCpvStatsToJSON(value?: PerCpvStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cpv_code': value.cpvCode,
        'name': value.name,
        'total_awarded': value.totalAwarded,
        'total_value': value.totalValue,
        'avg_contract_value': value.avgContractValue,
        'percent_to_smes': value.percentToSmes,
    };
}

