import React from "react";
import { ConfigProvider } from "antd5";

import { InfoButton } from "components/actions/InfoButton";

import css from "./FormItemWrapper.module.scss";

type Props = {
  children: React.ReactChildren | React.ReactChild;
  label: string;
  required?: boolean;
  formIdentifier: string;
  errors: string;
  description?: string;
};

function FormItemWrapper({
  children,
  label,
  required,
  formIdentifier,
  errors,
  description,
}: Props) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            zIndexPopupBase: 100000,
          },
          DatePicker: {
            zIndexPopupBase: 100000,
          },
        },
      }}
    >
      <div id={`#/properties/${formIdentifier}`} className={css.formItem}>
        <div className={css.heading}>
          {label}
          {required && <span className={css.validation}>*</span>}
          {description && <InfoButton className={css.infoButton} note={description} />}
        </div>
        {children}
        {errors && <span className={css.validation}>{errors}</span>}
      </div>
    </ConfigProvider>
  );
}

export default FormItemWrapper;
