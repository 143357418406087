/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePinnedSavedViewRequest
 */
export interface CreatePinnedSavedViewRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePinnedSavedViewRequest
     */
    savedViewId: string;
}

/**
 * Check if a given object implements the CreatePinnedSavedViewRequest interface.
 */
export function instanceOfCreatePinnedSavedViewRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "savedViewId" in value;

    return isInstance;
}

export function CreatePinnedSavedViewRequestFromJSON(json: any): CreatePinnedSavedViewRequest {
    return CreatePinnedSavedViewRequestFromJSONTyped(json, false);
}

export function CreatePinnedSavedViewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePinnedSavedViewRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'savedViewId': json['saved_view_id'],
    };
}

export function CreatePinnedSavedViewRequestToJSON(value?: CreatePinnedSavedViewRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'saved_view_id': value.savedViewId,
    };
}

