import jwtDecode, { JwtPayload } from "jwt-decode";

import { STOTLES_OPEN_API } from "lib/openApiContext";

interface IntegrationTokenProvider {
  getIntegrationToken: () => Promise<string>;
}

const THRESHOLD_EXPIRY_SECONDS = 30;

function extractExpiryDate(token: string): number | undefined {
  const decoded: JwtPayload = jwtDecode(token);
  return decoded.exp;
}

function tokenIsExpired(expiryDate: number) {
  // The jwt token exp is stated in seconds since epoch
  const tokenExpiry = expiryDate;
  // We convert the current time in milliseconds since epock to seconds since epoch
  const currentTime = Date.now() / 1000;
  // We subtract a threashold from the token expiry to give ourselves time to make the request
  // Then we check if the diff is less than 0. If so, then the token is deemed expired.
  return currentTime >= tokenExpiry - THRESHOLD_EXPIRY_SECONDS;
}

export default class DefaultIntegrationTokenProvider implements IntegrationTokenProvider {
  private accessToken: string | undefined;
  constructor() {
    this.getIntegrationToken = this.getIntegrationToken.bind(this);
  }

  async getIntegrationToken() {
    const expiryDate = this.accessToken ? extractExpiryDate(this.accessToken) : undefined;
    if (!this.accessToken || !expiryDate || tokenIsExpired(expiryDate)) {
      const response = await STOTLES_OPEN_API.getAppApiToken();
      this.accessToken = response.token;
    }
    return this.accessToken;
  }
}
