import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { assertCurrentUser } from "lib/currentUser";
import { GetRecordListsResponseResult } from "lib/generated/app-api";
import { useStotlesApi } from "lib/stotlesApiContext";
import { EventDataTypes, EventNames, useTracking } from "lib/tracking";
import { BasicSignal, getSignalTypes } from "lib/types/models";
import { updateCachedNotices, updateCachedRecord } from "../utils";

type Params = {
  list: GetRecordListsResponseResult;
  recordGuid: string;
  signals: BasicSignal[];
  recordStage: string;
  contextSource: string;
};

export function useAddNoticeToList(
  options?: UseMutationOptions<unknown, unknown, Params, unknown>,
) {
  const api = useStotlesApi();
  const { logEvent } = useTracking();
  const queryClient = useQueryClient();
  const user = assertCurrentUser();

  return useMutation(
    async ({ list, recordGuid }: Params) => api.addRecordToList(list.id, recordGuid),
    {
      ...options,
      onSuccess: async (data, vars, ctx) => {
        await queryClient.invalidateQueries(["noticeLists"]);

        const { list, contextSource } = vars;

        const signalTypes = getSignalTypes(vars.signals);

        logEvent(EventNames.recordAddedToList, {
          "List name": list.name,
          "List id": list.id,
          "Data type": EventDataTypes.recordList,
          "Context source": contextSource,
          Stage: vars.recordStage,
          "Signal types": signalTypes ? Object.keys(signalTypes) : [],
          Signals: signalTypes,
        });

        // Update the cache
        updateCachedRecord(queryClient, vars.recordGuid, (r) => ({
          ...r,
          lists: [
            ...r.lists,
            {
              companyId: user.company.id,
              guid: list.guid,
              id: list.id,
              name: list.name,
              ownerId: list.user.id,
            },
          ],
        }));

        updateCachedNotices(queryClient, vars.recordGuid, (r) => ({
          ...r,
          lists: [
            ...r.lists,
            {
              id: list.id,
              name: list.name,
            },
          ],
        }));

        options?.onSuccess?.(data, vars, ctx);
      },
    },
  );
}
