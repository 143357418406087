/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NoticeFormResponse
 */
export interface NoticeFormResponse {
    /**
     * 
     * @type {string}
     * @memberof NoticeFormResponse
     */
    entityName: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeFormResponse
     */
    form: string;
    /**
     * 
     * @type {string}
     * @memberof NoticeFormResponse
     */
    layout: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof NoticeFormResponse
     */
    mappings: { [key: string]: string; };
}

/**
 * Check if a given object implements the NoticeFormResponse interface.
 */
export function instanceOfNoticeFormResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entityName" in value;
    isInstance = isInstance && "form" in value;
    isInstance = isInstance && "layout" in value;
    isInstance = isInstance && "mappings" in value;

    return isInstance;
}

export function NoticeFormResponseFromJSON(json: any): NoticeFormResponse {
    return NoticeFormResponseFromJSONTyped(json, false);
}

export function NoticeFormResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoticeFormResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityName': json['entityName'],
        'form': json['form'],
        'layout': json['layout'],
        'mappings': json['mappings'],
    };
}

export function NoticeFormResponseToJSON(value?: NoticeFormResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityName': value.entityName,
        'form': value.form,
        'layout': value.layout,
        'mappings': value.mappings,
    };
}

