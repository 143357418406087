/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AllBuyersStats } from './AllBuyersStats';
import {
    AllBuyersStatsFromJSON,
    AllBuyersStatsFromJSONTyped,
    AllBuyersStatsToJSON,
} from './AllBuyersStats';

/**
 * 
 * @export
 * @interface AllBuyersStatsResponse
 */
export interface AllBuyersStatsResponse {
    /**
     * 
     * @type {Array<AllBuyersStats>}
     * @memberof AllBuyersStatsResponse
     */
    signalStats: Array<AllBuyersStats>;
    /**
     * 
     * @type {number}
     * @memberof AllBuyersStatsResponse
     */
    totalCount: number;
}

/**
 * Check if a given object implements the AllBuyersStatsResponse interface.
 */
export function instanceOfAllBuyersStatsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "signalStats" in value;
    isInstance = isInstance && "totalCount" in value;

    return isInstance;
}

export function AllBuyersStatsResponseFromJSON(json: any): AllBuyersStatsResponse {
    return AllBuyersStatsResponseFromJSONTyped(json, false);
}

export function AllBuyersStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllBuyersStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signalStats': ((json['signal_stats'] as Array<any>).map(AllBuyersStatsFromJSON)),
        'totalCount': json['total_count'],
    };
}

export function AllBuyersStatsResponseToJSON(value?: AllBuyersStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signal_stats': ((value.signalStats as Array<any>).map(AllBuyersStatsToJSON)),
        'total_count': value.totalCount,
    };
}

