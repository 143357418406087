import React, { useContext } from "react";
import { Select } from "antd5";
import { OptionProps } from "antd5/es/select";

import { usePropertyOptions } from "lib/hooks/api/integrations/usePropertyOptions";
import FormItemWrapper from "../FormItemWrapper";
import { FormContext } from "../useFormDataContext";

import css from "../formStyles.module.scss";

type OptionsProps = {
  id: string;
  label: string;
  required?: boolean;
  value: string;
  disabled: boolean;
  updateValue: (value: string) => void;
  errors: string;
  description?: string;
};

const { Option } = Select;

export function Options({
  id,
  label,
  required,
  value,
  disabled,
  updateValue,
  errors,
  description,
}: OptionsProps): JSX.Element {
  const formData = useContext(FormContext);
  const { data: values, isLoading } = usePropertyOptions(
    id.substring(id.lastIndexOf("/") + 1),
    formData,
  );
  return (
    <FormItemWrapper
      formIdentifier="string"
      label={label}
      required={required}
      errors={errors}
      description={description}
    >
      <Select
        id={id}
        loading={isLoading}
        allowClear={!required}
        defaultValue={value}
        labelInValue
        placeholder="Select..."
        className={css.fullWidth}
        onChange={updateValue}
        disabled={disabled}
        showSearch
        filterOption={(inputValue, option) => {
          if (!option) return false;

          return option.toString().toLowerCase().includes(inputValue.toLowerCase());
        }}
        onSelect={(
          _: string,
          o: React.ReactElement<OptionProps, string | React.JSXElementConstructor<unknown>>,
        ) => {
          updateValue(o.key?.toString() ?? "");
        }}
      >
        {values?.map((x) => (
          <Option key={x.id} value={x.id}>
            {x.label}
          </Option>
        ))}
      </Select>
    </FormItemWrapper>
  );
}
