import React from "react";

type ProviderProps<Type> = React.PropsWithChildren<{ instance?: Type }>;

type DependencyInjectionConfig<Type> = {
  Provider: React.ComponentType<ProviderProps<Type>>;
  useInstance: () => Type;
};

export function defineInjectable<T>(name: string, factory?: () => T): DependencyInjectionConfig<T> {
  const context = React.createContext<T | undefined>(undefined);
  return {
    Provider: (props) => {
      const instance: T = React.useMemo(() => {
        if (props.instance) {
          return props.instance;
        }
        if (!factory) {
          throw new Error(
            `'${name}' was defined without a factory, but an instance was not provided.`,
          );
        }
        return factory();
      }, [props.instance]);
      return <context.Provider value={instance}>{props.children}</context.Provider>;
    },
    useInstance: () => {
      const instance = React.useContext(context);
      if (!instance) {
        throw new Error(`Accessing injectable '${name}' without a provider.`);
      }
      return instance;
    },
  };
}
