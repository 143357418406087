import * as React from "react";

export default function Bookmark(): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="current"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 2H4V15L8 12.6364L12 15V2Z" fill="current" />
    </svg>
  );
}
