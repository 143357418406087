/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyerCategoryDto } from './BuyerCategoryDto';
import {
    BuyerCategoryDtoFromJSON,
    BuyerCategoryDtoFromJSONTyped,
    BuyerCategoryDtoToJSON,
} from './BuyerCategoryDto';
import type { BuyerSummaryDtoMetadata } from './BuyerSummaryDtoMetadata';
import {
    BuyerSummaryDtoMetadataFromJSON,
    BuyerSummaryDtoMetadataFromJSONTyped,
    BuyerSummaryDtoMetadataToJSON,
} from './BuyerSummaryDtoMetadata';

/**
 * 
 * @export
 * @interface BuyerSummaryDto
 */
export interface BuyerSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof BuyerSummaryDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BuyerSummaryDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerSummaryDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerSummaryDto
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuyerSummaryDto
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuyerSummaryDto
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuyerSummaryDto
     */
    town?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuyerSummaryDto
     */
    nutsLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuyerSummaryDto
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerSummaryDto
     */
    oscarId?: string | null;
    /**
     * 
     * @type {Array<BuyerCategoryDto>}
     * @memberof BuyerSummaryDto
     */
    categories: Array<BuyerCategoryDto>;
    /**
     * 
     * @type {boolean}
     * @memberof BuyerSummaryDto
     */
    isStotlesVerified: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuyerSummaryDto
     */
    isArchived: boolean;
    /**
     * 
     * @type {BuyerSummaryDtoMetadata}
     * @memberof BuyerSummaryDto
     */
    metadata?: BuyerSummaryDtoMetadata;
}

/**
 * Check if a given object implements the BuyerSummaryDto interface.
 */
export function instanceOfBuyerSummaryDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "isStotlesVerified" in value;
    isInstance = isInstance && "isArchived" in value;

    return isInstance;
}

export function BuyerSummaryDtoFromJSON(json: any): BuyerSummaryDto {
    return BuyerSummaryDtoFromJSONTyped(json, false);
}

export function BuyerSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyerSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'country': json['country'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'postalCode': !exists(json, 'postal_code') ? undefined : json['postal_code'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'nutsLocation': !exists(json, 'nuts_location') ? undefined : json['nuts_location'],
        'guid': json['guid'],
        'oscarId': !exists(json, 'oscar_id') ? undefined : json['oscar_id'],
        'categories': ((json['categories'] as Array<any>).map(BuyerCategoryDtoFromJSON)),
        'isStotlesVerified': json['is_stotles_verified'],
        'isArchived': json['is_archived'],
        'metadata': !exists(json, 'metadata') ? undefined : BuyerSummaryDtoMetadataFromJSON(json['metadata']),
    };
}

export function BuyerSummaryDtoToJSON(value?: BuyerSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'country': value.country,
        'url': value.url,
        'postal_code': value.postalCode,
        'address': value.address,
        'town': value.town,
        'nuts_location': value.nutsLocation,
        'guid': value.guid,
        'oscar_id': value.oscarId,
        'categories': ((value.categories as Array<any>).map(BuyerCategoryDtoToJSON)),
        'is_stotles_verified': value.isStotlesVerified,
        'is_archived': value.isArchived,
        'metadata': BuyerSummaryDtoMetadataToJSON(value.metadata),
    };
}

