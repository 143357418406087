import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { useStotlesApi } from "lib/stotlesApiContext";
import { RecordListSummary } from "lib/types/models";

export function useNoticeListsForRecord(
  recordId: string,
  options?: UseQueryOptions<RecordListSummary[], unknown, RecordListSummary[], string[]>,
) {
  const api = useStotlesApi();
  return useQuery(
    ["noticeLists", recordId],
    async () => {
      const { record_lists } = await api.getRecordListsForRecord(recordId);
      return record_lists;
    },
    options,
  );
}
