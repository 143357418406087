import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { NotificationsIndexResponse } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

export function useNotifications(
  options?: UseQueryOptions<unknown, unknown, NotificationsIndexResponse, string[]>,
) {
  const api = useOpenApi();

  return useQuery(["notifications"], async () => api.getNotifications(), options);
}
