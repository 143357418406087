import React, { RefObject, useEffect, useRef } from "react";
import styled from "@emotion/styled";

import CentredSpinner from "../../../lib/core_components/CentredSpinner";
import { NotificationDto } from "../../../lib/generated/app-api";
import { useMarkNotificationsAsRead } from "../../../lib/hooks/api/notifications/useMarkNotificationsAsRead";
import { useNotifications } from "../../../lib/hooks/api/notifications/useNotifications";
import { checkSquare } from "../../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { getTimePeriod, TimePeriod } from "../../../lib/utils/dateTimeUtils";
import { Text } from "../../../styles/utility-components";
import { Notification } from "../NotificationCentre";
import { COLLAPSEDWIDTH, EXPANDEDWIDTH } from "./VerticalNav.styles";

type NotificationsPanelProps = {
  open: boolean;
  onClose: () => void;
  mainNavRef: RefObject<HTMLDivElement>;
};

function NotificationsPanel({ open, onClose, mainNavRef }: NotificationsPanelProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { data, isLoading } = useNotifications({ enabled: !!window.currentUser });
  const { mutate: markNotificationsAsRead } = useMarkNotificationsAsRead();

  const timePeriods: Record<TimePeriod, NotificationDto[]> = {
    [TimePeriod.TODAY]: [],
    [TimePeriod.YESTERDAY]: [],
    [TimePeriod.PAST_WEEK]: [],
    [TimePeriod.PAST_MONTH]: [],
  };

  const noNotifications = data?.notifications.length === 0;

  const notificationsGroupedByTimePeriod =
    data?.notifications.reduce<Record<string, NotificationDto[]>>((collection, n) => {
      const dateGrouping = getTimePeriod(n.createdAt);
      // If the grouping is null it means it's more than a month old we don't show it
      if (!dateGrouping) return collection;

      collection[dateGrouping].push(n);

      return collection;
    }, timePeriods) || {};

  useEffect(() => {
    if (!open) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        mainNavRef.current &&
        !mainNavRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [open, mainNavRef, onClose, wrapperRef]);

  return (
    <Wrapper open={open} ref={wrapperRef}>
      <TopRow>
        <Text h3>Notifications</Text>
        {/* replace with button component */}
        {!noNotifications && (
          <Button
            onClick={() => {
              markNotificationsAsRead({
                notificationIds: data?.notifications.map((n) => n.id) || [],
                contextSource: "Bulk action",
              });
            }}
          >
            <UIcon size={16} svg={checkSquare} />
            <Text color="inherit">Mark all as read</Text>
          </Button>
        )}
      </TopRow>
      {isLoading && <CentredSpinner />}
      {!isLoading && (
        <Notifications>
          {noNotifications ? (
            <Empty>
              <Text>You have no notifications.</Text>
            </Empty>
          ) : (
            Object.entries(notificationsGroupedByTimePeriod).map(([timePeriod, notifications]) => {
              if (notifications.length === 0) return null;

              return (
                <div key={timePeriod}>
                  <TimePeriodText>{timePeriod}</TimePeriodText>
                  <Notifications>
                    {notifications.map((notification) => (
                      <Notification key={notification.id} notification={notification} />
                    ))}
                  </Notifications>
                </div>
              );
            })
          )}
        </Notifications>
      )}
    </Wrapper>
  );
}

export default NotificationsPanel;

const Wrapper = styled.div<{ open: boolean }>(({ theme, open }) => ({
  position: "fixed",
  flexDirection: "column",
  transform: `translateX(${open ? `${EXPANDEDWIDTH}px` : `${-EXPANDEDWIDTH + COLLAPSEDWIDTH - 60}px`})`,
  transition: "all 0.45s", // timed to close with the main nav
  visibility: open ? "visible" : "hidden",
  height: "100%",
  backgroundColor: theme.colors.white,
  width: EXPANDEDWIDTH + 60,
  zIndex: -1,
  padding: 24,
  boxShadow: "rgba(0, 0, 0, 0.07) 0px 4px 10px",
}));

const TopRow = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 24,
});

const Button = styled.button(({ theme }) => ({
  height: 32,
  display: "flex",
  alignItems: "center",
  gap: 8,
  border: "none",
  borderRadius: 4,
  padding: "0 8px",
  background: "none",
  color: theme.colors.sysPrimaryDefault,
  cursor: "pointer",
  transition: "all 0.3s",

  "&:hover": {
    color: theme.colors.sysPrimaryHover,
    backgroundColor: theme.colors.sysPrimarySubtle,
  },
}));

const Notifications = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 24,
  marginBottom: 24,
});

const TimePeriodText = styled(Text)(({ theme }) => ({
  color: theme.colors.sysTextSecondary,
  marginBottom: 8,
}));

const Empty = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  textAlign: "center",
  marginTop: 60,
  padding: "0 24px",
});
