import React from "react";
import { ControlProps } from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";

import { Date } from "./Date";

function DateControl({
  data,
  handleChange,
  path,
  label,
  required,
  errors,
}: ControlProps): JSX.Element {
  return (
    <Date
      errors={errors}
      value={data}
      label={label}
      required={required}
      updateValue={(value: string | string[]) => handleChange(path, value)}
    />
  );
}

export default withJsonFormsControlProps(DateControl);
