import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import {
  GetRecordSummaryDetailsRequestAttributeNamesEnum,
  GetRecordSummaryDetailsResponse,
} from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "./utils";

type QueryKey = [string, string, GetRecordSummaryDetailsRequestAttributeNamesEnum[]];

export function useRecordSummaryDetails(
  guid: string,
  attributes: GetRecordSummaryDetailsRequestAttributeNamesEnum[],
  options?: UseQueryOptions<
    GetRecordSummaryDetailsResponse,
    unknown,
    GetRecordSummaryDetailsResponse,
    QueryKey
  >,
) {
  const api = useOpenApi();
  return useQuery(
    ["recordSummary", guid, attributes],
    async () => {
      const list = await api.getRecordSummaryDetails({
        getRecordSummaryDetailsRequest: { recordGuid: guid, attributeNames: attributes },
      });
      return list;
    },
    {
      ...options,
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
    },
  );
}
