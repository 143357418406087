/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Field
 */
export interface Field {
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    type: string;
    /**
     * 
     * @type {boolean}
     * @memberof Field
     */
    required: boolean;
    /**
     * 
     * @type {string}
     * @memberof Field
     */
    description: string;
}

/**
 * Check if a given object implements the Field interface.
 */
export function instanceOfField(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "required" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function FieldFromJSON(json: any): Field {
    return FieldFromJSONTyped(json, false);
}

export function FieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): Field {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'required': json['required'],
        'description': json['description'],
    };
}

export function FieldToJSON(value?: Field | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'required': value.required,
        'description': value.description,
    };
}

