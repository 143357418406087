import * as React from "react";

import StotlesAPI from "lib/StotlesApi";

/**
 * How to use this:
 *
 * 1. Place an <ApiContextProvider> component somewhere close to the root of your component tree.
 *    *It has to contain the rest of the componets that might use it.*
 * 2. In a functional component:
 *    `const api = useStotlesApi();`
 * 3. In a class component:
 * ```
 *    class MyComponent extends React.Component<WithStotlesApi<Props>,...> {
 *    ...
 *      onSomething = async() => {
 *        await this.props.api.doSomething()
 *      }
 *    }
 *
 *    export default withStotlesApi(MyComponent);
 * ```
 */

/**
 * Data stored in the context
 */
interface ApiContextData {
  readonly api: StotlesAPI;
}

/**
 * The default context used when there is no provider present.
 * Throws errors on access.
 */
class EmptyApiContext implements ApiContextData {
  get api(): StotlesAPI {
    throw new Error("Accessing API context without a provider.");
  }
}

/**
 * The actual React Context object
 */
const ApiContext = React.createContext<ApiContextData>(new EmptyApiContext());

type ApiContextProviderProps = {
  api: StotlesAPI;
};

/**
 * Context provider.
 *
 * Place this near the top of your component tree so other components can access the api.
 */
export function ApiContextProvider(
  props: React.PropsWithChildren<ApiContextProviderProps>,
): JSX.Element {
  const { api, children } = props;
  const contextData = React.useMemo(() => {
    return { api };
  }, [api]);

  return <ApiContext.Provider value={contextData}>{children}</ApiContext.Provider>;
}

/**
 * Hook for accessing the API context - returns an api object directly.
 */
export function useStotlesApi(): StotlesAPI {
  return React.useContext(ApiContext).api;
}

/**
 * Adds `api` prop to your props.
 */
export type WithStotlesApi<Props> = Props & { readonly api: StotlesAPI };

/**
 * HOC that injects `api` prop to your component using the API context.
 */
export function withStotlesApi<Props>(
  Component: React.ComponentType<WithStotlesApi<Props>>,
): (props: Readonly<Props>) => JSX.Element {
  function wrapper(props: Readonly<Props>) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const api = useStotlesApi();
    return <Component {...props} api={api} />;
  }

  return wrapper;
}
