/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RegionDto } from './RegionDto';
import {
    RegionDtoFromJSON,
    RegionDtoFromJSONTyped,
    RegionDtoToJSON,
} from './RegionDto';

/**
 * 
 * @export
 * @interface DescribeRegionResponse
 */
export interface DescribeRegionResponse {
    /**
     * 
     * @type {RegionDto}
     * @memberof DescribeRegionResponse
     */
    region: RegionDto;
}

/**
 * Check if a given object implements the DescribeRegionResponse interface.
 */
export function instanceOfDescribeRegionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "region" in value;

    return isInstance;
}

export function DescribeRegionResponseFromJSON(json: any): DescribeRegionResponse {
    return DescribeRegionResponseFromJSONTyped(json, false);
}

export function DescribeRegionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DescribeRegionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'region': RegionDtoFromJSON(json['region']),
    };
}

export function DescribeRegionResponseToJSON(value?: DescribeRegionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'region': RegionDtoToJSON(value.region),
    };
}

