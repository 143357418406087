/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CpvChartSectionParametersBuyer } from './CpvChartSectionParametersBuyer';
import {
    CpvChartSectionParametersBuyerFromJSON,
    CpvChartSectionParametersBuyerFromJSONTyped,
    CpvChartSectionParametersBuyerToJSON,
} from './CpvChartSectionParametersBuyer';
import type { OverallCpvStats } from './OverallCpvStats';
import {
    OverallCpvStatsFromJSON,
    OverallCpvStatsFromJSONTyped,
    OverallCpvStatsToJSON,
} from './OverallCpvStats';
import type { PerCpvStats } from './PerCpvStats';
import {
    PerCpvStatsFromJSON,
    PerCpvStatsFromJSONTyped,
    PerCpvStatsToJSON,
} from './PerCpvStats';

/**
 * 
 * @export
 * @interface CpvChartSectionParameters
 */
export interface CpvChartSectionParameters {
    /**
     * 
     * @type {string}
     * @memberof CpvChartSectionParameters
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof CpvChartSectionParameters
     */
    description?: string;
    /**
     * 
     * @type {CpvChartSectionParametersBuyer}
     * @memberof CpvChartSectionParameters
     */
    buyer?: CpvChartSectionParametersBuyer;
    /**
     * 
     * @type {boolean}
     * @memberof CpvChartSectionParameters
     */
    upcomingExpiries?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CpvChartSectionParameters
     */
    lookbackPeriod?: string;
    /**
     * 
     * @type {OverallCpvStats}
     * @memberof CpvChartSectionParameters
     */
    overallStats?: OverallCpvStats;
    /**
     * 
     * @type {Array<PerCpvStats>}
     * @memberof CpvChartSectionParameters
     */
    perCpvStats?: Array<PerCpvStats>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CpvChartSectionParameters
     */
    selectedCpvCodes?: Array<string>;
}

/**
 * Check if a given object implements the CpvChartSectionParameters interface.
 */
export function instanceOfCpvChartSectionParameters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CpvChartSectionParametersFromJSON(json: any): CpvChartSectionParameters {
    return CpvChartSectionParametersFromJSONTyped(json, false);
}

export function CpvChartSectionParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): CpvChartSectionParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'buyer': !exists(json, 'buyer') ? undefined : CpvChartSectionParametersBuyerFromJSON(json['buyer']),
        'upcomingExpiries': !exists(json, 'upcoming_expiries') ? undefined : json['upcoming_expiries'],
        'lookbackPeriod': !exists(json, 'lookback_period') ? undefined : json['lookback_period'],
        'overallStats': !exists(json, 'overall_stats') ? undefined : OverallCpvStatsFromJSON(json['overall_stats']),
        'perCpvStats': !exists(json, 'per_cpv_stats') ? undefined : ((json['per_cpv_stats'] as Array<any>).map(PerCpvStatsFromJSON)),
        'selectedCpvCodes': !exists(json, 'selected_cpv_codes') ? undefined : json['selected_cpv_codes'],
    };
}

export function CpvChartSectionParametersToJSON(value?: CpvChartSectionParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'description': value.description,
        'buyer': CpvChartSectionParametersBuyerToJSON(value.buyer),
        'upcoming_expiries': value.upcomingExpiries,
        'lookback_period': value.lookbackPeriod,
        'overall_stats': OverallCpvStatsToJSON(value.overallStats),
        'per_cpv_stats': value.perCpvStats === undefined ? undefined : ((value.perCpvStats as Array<any>).map(PerCpvStatsToJSON)),
        'selected_cpv_codes': value.selectedCpvCodes,
    };
}

