/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyerRelationshipParams } from './BuyerRelationshipParams';
import {
    BuyerRelationshipParamsFromJSON,
    BuyerRelationshipParamsFromJSONTyped,
    BuyerRelationshipParamsToJSON,
} from './BuyerRelationshipParams';

/**
 * 
 * @export
 * @interface CreateBuyerRelationshipsRequest
 */
export interface CreateBuyerRelationshipsRequest {
    /**
     * 
     * @type {Array<BuyerRelationshipParams>}
     * @memberof CreateBuyerRelationshipsRequest
     */
    outgoingRelationships: Array<BuyerRelationshipParams>;
}

/**
 * Check if a given object implements the CreateBuyerRelationshipsRequest interface.
 */
export function instanceOfCreateBuyerRelationshipsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "outgoingRelationships" in value;

    return isInstance;
}

export function CreateBuyerRelationshipsRequestFromJSON(json: any): CreateBuyerRelationshipsRequest {
    return CreateBuyerRelationshipsRequestFromJSONTyped(json, false);
}

export function CreateBuyerRelationshipsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBuyerRelationshipsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'outgoingRelationships': ((json['outgoing_relationships'] as Array<any>).map(BuyerRelationshipParamsFromJSON)),
    };
}

export function CreateBuyerRelationshipsRequestToJSON(value?: CreateBuyerRelationshipsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'outgoing_relationships': ((value.outgoingRelationships as Array<any>).map(BuyerRelationshipParamsToJSON)),
    };
}

