/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataSource } from './DataSource';
import {
    DataSourceFromJSON,
    DataSourceFromJSONTyped,
    DataSourceToJSON,
} from './DataSource';

/**
 * 
 * @export
 * @interface SaveDataSourcesRequest
 */
export interface SaveDataSourcesRequest {
    /**
     * 
     * @type {string}
     * @memberof SaveDataSourcesRequest
     */
    companyGuid: string;
    /**
     * 
     * @type {Array<DataSource>}
     * @memberof SaveDataSourcesRequest
     */
    companyDefaultDataSources: Array<DataSource>;
    /**
     * 
     * @type {{ [key: string]: Array<DataSource>; }}
     * @memberof SaveDataSourcesRequest
     */
    dataSourcesPerUserGuid: { [key: string]: Array<DataSource>; };
}

/**
 * Check if a given object implements the SaveDataSourcesRequest interface.
 */
export function instanceOfSaveDataSourcesRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyGuid" in value;
    isInstance = isInstance && "companyDefaultDataSources" in value;
    isInstance = isInstance && "dataSourcesPerUserGuid" in value;

    return isInstance;
}

export function SaveDataSourcesRequestFromJSON(json: any): SaveDataSourcesRequest {
    return SaveDataSourcesRequestFromJSONTyped(json, false);
}

export function SaveDataSourcesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveDataSourcesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyGuid': json['company_guid'],
        'companyDefaultDataSources': ((json['company_default_data_sources'] as Array<any>).map(DataSourceFromJSON)),
        'dataSourcesPerUserGuid': json['data_sources_per_user_guid'],
    };
}

export function SaveDataSourcesRequestToJSON(value?: SaveDataSourcesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_guid': value.companyGuid,
        'company_default_data_sources': ((value.companyDefaultDataSources as Array<any>).map(DataSourceToJSON)),
        'data_sources_per_user_guid': value.dataSourcesPerUserGuid,
    };
}

