/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregationResultValue } from './AggregationResultValue';
import {
    AggregationResultValueFromJSON,
    AggregationResultValueFromJSONTyped,
    AggregationResultValueToJSON,
} from './AggregationResultValue';

/**
 * 
 * @export
 * @interface AggregationResult
 */
export interface AggregationResult {
    /**
     * 
     * @type {string}
     * @memberof AggregationResult
     */
    name: string;
    /**
     * 
     * @type {AggregationResultValue}
     * @memberof AggregationResult
     */
    value: AggregationResultValue;
}

/**
 * Check if a given object implements the AggregationResult interface.
 */
export function instanceOfAggregationResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "value" in value;

    return isInstance;
}

export function AggregationResultFromJSON(json: any): AggregationResult {
    return AggregationResultFromJSONTyped(json, false);
}

export function AggregationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AggregationResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'value': AggregationResultValueFromJSON(json['value']),
    };
}

export function AggregationResultToJSON(value?: AggregationResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'value': AggregationResultValueToJSON(value.value),
    };
}

