import React from "react";
import { Input } from "antd5";

import FormItemWrapper from "../FormItemWrapper";

type StringProps = {
  id?: string;
  label: string;
  required?: boolean;
  value: number;
  updateValue: (value: string | undefined) => void;
  errors: string;
  description?: string;
};

export function String({
  id,
  label,
  required,
  value,
  updateValue,
  errors,
  description,
}: StringProps): JSX.Element {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateValue(e.currentTarget.value.length < 1 ? undefined : e.currentTarget.value);
  };

  return (
    <FormItemWrapper
      label={label}
      formIdentifier="string"
      required={required}
      errors={errors}
      description={description}
    >
      <Input id={id} value={value} onChange={handleInputChange} required={required} allowClear />
    </FormItemWrapper>
  );
}
