import { keyframes } from "@emotion/react";

export const fadeIn = keyframes({
  from: { opacity: 0 },
  to: { opacity: 1 },
});

export const fadeOut = keyframes({
  from: { opacity: 1 },
  to: { opacity: 0 },
});

export const scaleIn = keyframes({
  from: { transform: "scale(0)" },
  to: { transform: "scale(1)" },
});

export const scaleOut = keyframes({
  from: { transform: "scale(1)" },
  to: { transform: "scale(0)" },
});

export const pulse = keyframes({
  "0%": { transform: "scale(1)" },
  "50%": { transform: "scale(1.05)" },
  "100%": { transform: "scale(1)" },
});

export const shake = keyframes({
  "0%": { transform: "translateX(0)" },
  "25%": { transform: "translateX(-5px)" },
  "50%": { transform: "translateX(5px)" },
  "75%": { transform: "translateX(-5px)" },
  "100%": { transform: "translateX(0)" },
});

export const fadeInAndSlideUp = keyframes({
  from: { opacity: 0, transform: "translateY(4px)" },
  to: { opacity: 1, transform: "translateY(0)" },
});
