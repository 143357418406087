/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateNotificationSettingsRequest
 */
export interface UpdateNotificationSettingsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationSettingsRequest
     */
    notificationsEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateNotificationSettingsRequest
     */
    sendAppActivityNotifications?: boolean;
}

/**
 * Check if a given object implements the UpdateNotificationSettingsRequest interface.
 */
export function instanceOfUpdateNotificationSettingsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateNotificationSettingsRequestFromJSON(json: any): UpdateNotificationSettingsRequest {
    return UpdateNotificationSettingsRequestFromJSONTyped(json, false);
}

export function UpdateNotificationSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateNotificationSettingsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notificationsEnabled': !exists(json, 'notifications_enabled') ? undefined : json['notifications_enabled'],
        'sendAppActivityNotifications': !exists(json, 'send_app_activity_notifications') ? undefined : json['send_app_activity_notifications'],
    };
}

export function UpdateNotificationSettingsRequestToJSON(value?: UpdateNotificationSettingsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notifications_enabled': value.notificationsEnabled,
        'send_app_activity_notifications': value.sendAppActivityNotifications,
    };
}

