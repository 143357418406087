import React from "react";

import { DataSource } from "./SubscriptionPlansDetails";

import css from "./PlanComparisonTable.module.scss";

const PlanComparisonTable = ({ header, data }: { header: string; data: DataSource[] }) => {
  return (
    <div className={css.planTableContainer}>
      <div className={css.header}>{header}</div>
      {data.map((item) => (
        <div className={css.row}>
          <div className={css.featureColumn}>{item.feature}</div>
          <div className={css.freeColumn}>{item.forFree}</div>
          <div className={css.growthColumn}>{item.forGrowth}</div>
          <div className={css.expertColumn}>{item.forExpert}</div>
        </div>
      ))}
    </div>
  );
};

export default PlanComparisonTable;
