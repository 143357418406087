import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd5";

import { TextArea } from "components/form_components/Inputs";
import { assertCurrentUser } from "lib/currentUser";
import { ProductTourCompletionStateResponseTourStateTourIdentifierEnum } from "lib/generated/app-api";
import { useProductFeedback } from "lib/hooks/useProductTour";
import { EventNames, useTracking } from "lib/tracking";

import css from "./FeedbackModal.module.scss";

type FeedbackModalProps = {
  tourIdentifier: ProductTourCompletionStateResponseTourStateTourIdentifierEnum;
  permanentlyHide: () => void;
  title?: string;
  description?: string;
  isOpen: boolean;
  onClose: () => void;
  icon?: React.ReactNode;
};

export function FeedbackModal({
  tourIdentifier,
  permanentlyHide,
  isOpen,
  onClose,
  title = "How did it go?",
  description = "We’d love to hear how you found this new feature. Please let us know if this feature performed as expected.",
  icon = null,
}: FeedbackModalProps) {
  const { logEvent } = useTracking();
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const user = assertCurrentUser();

  const onCloseModal = () => {
    permanentlyHide();
    onClose();
  };

  const onLike = () => {
    logEvent(EventNames.userLike, {
      "Tour identifier": tourIdentifier,
      "User ID": user.id,
      "User email": user.email,
      "User company": user.company,
    });
    onCloseModal();
  };

  const onDislike = () => {
    logEvent(EventNames.userDislike, {
      "Tour identifier": tourIdentifier,
      "User ID": user.id,
      "User email": user.email,
      "User company": user.company,
    });
    setShowFeedbackForm(true);
  };

  return (
    <Modal
      centered
      open={isOpen}
      closable={false}
      onCancel={onCloseModal}
      className={css.modal}
      footer={null}
    >
      <div>
        {showFeedbackForm ? (
          <FeedbackForm onClose={onCloseModal} tourIdentifier={tourIdentifier} />
        ) : (
          <div className={css.content}>
            {icon}
            <h3 className={css.modalTitle}>{title}</h3>
            <p className={css.modalDesc}>{description}</p>
            <div className={css.footer}>
              <Button type="primary" onClick={onLike} className={css.btn}>
                <LikeOutlined className={css.likeIcon} />
              </Button>
              <Button onClick={onDislike} className={css.btn}>
                <DislikeOutlined className={css.dislikeIcon} />
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

function FeedbackForm({
  onClose,
  tourIdentifier,
}: {
  onClose: () => void;
  tourIdentifier: ProductTourCompletionStateResponseTourStateTourIdentifierEnum;
}) {
  const { control, handleSubmit } = useForm<{ feedback: string }>();
  const { logEvent } = useTracking();
  const { mutate } = useProductFeedback({ onSuccess: () => onClose() });
  const user = assertCurrentUser();

  const onSubmit = handleSubmit(({ feedback }) => {
    mutate({ feedback, tourIdentifier, userId: user.id });
    logEvent(EventNames.submittedFeedback, {
      "Tour identifier": tourIdentifier,
      "User ID": user.id,
      "User email": user.email,
      "User company": user.company,
    });
  });

  const onDismiss = () => {
    logEvent(EventNames.dismissFeedback, {
      "Tour identifier": tourIdentifier,
      "User ID": user.id,
      "User email": user.email,
      "User company": user.company,
    });
    onClose();
  };

  return (
    <form className={css.content} onSubmit={onSubmit}>
      <h3 className={css.modalTitle}>What could we improve?</h3>
      <p className={css.modalDesc}>
        Tell us more about why this feature wasn’t up to scratch so we can work to improve it.
      </p>
      <TextArea
        control={control}
        name="feedback"
        rules={{ required: { value: true, message: "Feedback is required" } }}
        placeholder=""
        label=""
        className={css.userFeedback}
        rows={4}
      />
      <div className={css.footer}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button onClick={onDismiss}>Dismiss</Button>
      </div>
    </form>
  );
}
