// TODO: Convert to `abstract` new  after upgrade to TypeScript 4.2
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Interface = new (...args: any) => any;
type AnyClass = Record<string, unknown>;
type Factory<T extends AnyClass> = () => T;

/**
 * This implements a very simple, typed class container.
 *
 * You can register "interfaces" (abstract classes) and their factories,
 * and then resolve instances of classes implementing those interfaces.
 *
 * It currently doesn't resolve any intra-dependencies.
 *
 */
export class DIContainer {
  // We have to erase types here, but the interface of this class
  // (getInstance & registerProvider) ensure that the types in the maps match up.

  // This maps an abstract class constructor to a factory of instances
  // of class that implements the interface
  private factoryMap = new Map<Interface, Factory<AnyClass>>();

  // This maps an abstract calss constructor to an instance of class that implements it
  private instanceMap = new Map<Interface, AnyClass>();

  /**
   * Resolves an interface into an instance that implements it.
   * Throws an error if the interface was not registered.
   *
   * @param ctor constructor of an abstract class ("interface")
   * @returns instance of a class that implements the interface
   */
  getInstance<Provider extends Interface>(ctor: Provider): InstanceType<Provider> {
    const instance = this.instanceMap.get(ctor);
    if (instance) {
      return instance as InstanceType<Provider>;
    }

    const factory = this.getFactory(ctor);
    const newInstance = factory();
    this.instanceMap.set(ctor, newInstance);
    return newInstance;
  }

  /**
   * Registers an interface/factory pair.
   *
   * @param type constructor of an abstract class ("interface")
   * @param constructor a factory of instances that implement the interface
   */
  registerProvider<ProviderInterface extends Interface>(
    type: ProviderInterface,
    constructor: Factory<InstanceType<ProviderInterface>>,
  ): void {
    this.factoryMap.set(type, constructor);
  }

  /**
   * Internal function for getting the factory for an interface in a typed way.
   */
  private getFactory<Provider extends Interface>(ctor: Provider): Factory<InstanceType<Provider>> {
    const factory = this.factoryMap.get(ctor);
    if (!factory) {
      throw new Error(`Class ${ctor.name} is not registered with the StotlesDataProvider.`);
    }
    // We ensure this cast is correct by only allowing `registerProvider` to modify `this.factoryMap`
    return factory as Factory<InstanceType<Provider>>;
  }
}
