import React from "react";
import Icon from "@ant-design/icons";
import classnames from "classnames";

import Bookmark from "lib/icons/Bookmark";

import css from "./Bookmark.module.scss";

type Props = {
  colour: "red" | "blue" | "grey";
  filled?: boolean;
  className?: string;
};

function BookmarkIcon({ colour, filled, className }: Props): JSX.Element {
  return (
    <Icon
      component={Bookmark}
      className={classnames(css[`bookmark-${colour}`], filled ? css.filled : css.empty, className)}
    />
  );
}

export default BookmarkIcon;
