import React from "react";
import styled from "@emotion/styled";

import { Text } from "../../../styles/utility-components";

type SubNavItemProps = {
  title: string;
  path: string;
};

function SubNavItem({ title, path }: SubNavItemProps) {
  const active = window.location.pathname === path;

  return (
    <Wrapper href={path}>
      <Line />
      <Item active={active}>
        <Text removeLineHeight bold fontWeight="500" color="inherit">
          {title}
        </Text>
      </Item>
    </Wrapper>
  );
}

export default SubNavItem;

const Wrapper = styled.a({
  display: "flex",
  alignItems: "center",
  gap: 8,
  height: 36,
  paddingLeft: 15,
});

const Line = styled.div(({ theme }) => ({
  height: "100%",
  borderLeft: `2px solid ${theme.colors.sysBorderPrimary}`,
}));

const Item = styled.div<{ active: boolean }>(({ theme, active }) => ({
  display: "flex",
  alignItems: "center",
  gap: 8,
  padding: 8,
  height: "100%",
  width: "100%",
  borderRadius: 4,
  color: theme.colors.sysTextDefault,
  cursor: "pointer",
  transition: "all 0.3s",

  "&:hover": {
    cursor: "pointer",
    color: theme.colors.white,

    ...(!active && {
      backgroundColor: theme.colors.sysPrimarySubtle,
      color: theme.colors.sysPrimaryHover,
    }),
  },

  ...(active && {
    backgroundColor: theme.colors.sysPrimaryDefault,
    color: theme.colors.white,
  }),
}));
