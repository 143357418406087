import * as React from "react";

export default function DoubleTick({ color }: { color?: string }): JSX.Element {
  return (
    <svg width="14" height="14" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 4.4375L2.9375 6.625L8.1875 1.375M6 5.75L6.875 6.625L12.125 1.375"
        stroke={color ?? "#565656"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
