import React from "react";
import { Button } from "antd5";

import { usePqlActioned } from "lib/hooks/api/users/usePqlActioned";

import css from "./ActionableBanner.module.scss";

import BACKGROUND_GEOS_LEFT from "../../../assets/images/background_geos_left.svg";
import BACKGROUND_GEOS_RIGHT from "../../../assets/images/background_geos_right.svg";

interface ActionableBannerProps {
  setBookADemoModalOpen: (isOpen: boolean) => void;
}
export const ActionableBanner = ({ setBookADemoModalOpen }: ActionableBannerProps) => {
  const { mutate } = usePqlActioned();

  return (
    <div className={css.actionableBanner}>
      <img className={css.backgroundGeosLeft} src={BACKGROUND_GEOS_LEFT} />
      <img className={css.backgroundGeosRight} src={BACKGROUND_GEOS_RIGHT} />
      <div className={css.bannerContent}>
        <h1>Become a preferred pub-sec supplier today</h1>
        <div>
          <Button
            className={css.bookADemoButton}
            onClick={() => {
              setBookADemoModalOpen(true);
              mutate({
                actionType: "Book a demo in-app",
                pageUrl: window.location.href,
              });
            }}
          >
            Book a demo
          </Button>
        </div>
      </div>
    </div>
  );
};
