import React, { ReactNode } from "react";
import classNames from "classnames";

import css from "./Headers.module.scss";

export const PageHeader = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return <h1 className={classNames(css.pageHeader, className)}>{children}</h1>;
};
