import * as React from "react";
import { CloseOutlined } from "@ant-design/icons";

import { BookCallButton, ChatWithUsButton, EmailUsButton } from "./ContactMethodButton";

import css from "./ContactUsWidget.module.scss";

interface ContactUsWidgetProps {
  onClose: () => void;
  triggeredFrom: string;
}

export function ContactUsWidget({ onClose, triggeredFrom }: ContactUsWidgetProps): JSX.Element {
  return (
    <div className={css.contactUsWidgetContainer}>
      <div className={css.contactUsWidget}>
        <div className={css.contactUsHeader}>
          <h1>Get in touch</h1>
          <CloseOutlined onClick={onClose} />
        </div>
        <div className={css.contactMethods}>
          <ChatWithUsButton triggeredFrom={triggeredFrom} />

          <EmailUsButton />

          <BookCallButton />
        </div>
      </div>
    </div>
  );
}
