/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NotificationDto } from './NotificationDto';
import {
    NotificationDtoFromJSON,
    NotificationDtoFromJSONTyped,
    NotificationDtoToJSON,
} from './NotificationDto';

/**
 * 
 * @export
 * @interface NotificationsIndexResponse
 */
export interface NotificationsIndexResponse {
    /**
     * 
     * @type {Array<NotificationDto>}
     * @memberof NotificationsIndexResponse
     */
    notifications: Array<NotificationDto>;
}

/**
 * Check if a given object implements the NotificationsIndexResponse interface.
 */
export function instanceOfNotificationsIndexResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "notifications" in value;

    return isInstance;
}

export function NotificationsIndexResponseFromJSON(json: any): NotificationsIndexResponse {
    return NotificationsIndexResponseFromJSONTyped(json, false);
}

export function NotificationsIndexResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationsIndexResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notifications': ((json['notifications'] as Array<any>).map(NotificationDtoFromJSON)),
    };
}

export function NotificationsIndexResponseToJSON(value?: NotificationsIndexResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notifications': ((value.notifications as Array<any>).map(NotificationDtoToJSON)),
    };
}

