import React, { useCallback } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Empty } from "antd5";
import { getDocument } from "pdfjs-dist/legacy/build/pdf";

import useAsync from "lib/hooks/useAsync";
import { PdfViewer } from "./DisplayPdf";

import css from "./PdfLoader.module.scss";

type DisplayPdfProps = {
  searchTerms?: string[];
  documentUrl?: string;
  caseSensitive?: boolean;
  onSlideoutClose?: () => void;
};

export function PdfLoader({
  searchTerms,
  documentUrl,
  caseSensitive,
  onSlideoutClose,
}: DisplayPdfProps): JSX.Element {
  const fetchDocument = useCallback(() => {
    if (!documentUrl) {
      return Promise.resolve(undefined);
    }
    return getDocument(documentUrl).promise;
  }, [documentUrl]);

  const pdf = useAsync(fetchDocument);

  /*
    Search PDF file for given term(s)
  */
  if (!documentUrl) {
    return <div>No document selected</div>;
  }

  if (pdf.status === "loading" || pdf.status === "reloading") {
    return (
      <div className={css.toolbar}>
        <div className={css.spinner}>
          Loading...
          <LoadingOutlined />
        </div>
      </div>
    );
  }

  if (pdf.status === "error") {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Failed to load document" />;
  }

  if (!pdf.value) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No document selected" />;
  }

  return (
    <PdfViewer
      pdf={pdf.value}
      searchTerms={searchTerms}
      caseSensitive={caseSensitive}
      onSlideoutClose={onSlideoutClose}
    />
  );
}
