/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetRecordListsResponseResultUser } from './GetRecordListsResponseResultUser';
import {
    GetRecordListsResponseResultUserFromJSON,
    GetRecordListsResponseResultUserFromJSONTyped,
    GetRecordListsResponseResultUserToJSON,
} from './GetRecordListsResponseResultUser';

/**
 * 
 * @export
 * @interface GetRecordListsResponseResult
 */
export interface GetRecordListsResponseResult {
    /**
     * 
     * @type {number}
     * @memberof GetRecordListsResponseResult
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GetRecordListsResponseResult
     */
    guid: string;
    /**
     * 
     * @type {GetRecordListsResponseResultUser}
     * @memberof GetRecordListsResponseResult
     */
    user: GetRecordListsResponseResultUser;
    /**
     * 
     * @type {string}
     * @memberof GetRecordListsResponseResult
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof GetRecordListsResponseResult
     */
    recordCount: number;
}

/**
 * Check if a given object implements the GetRecordListsResponseResult interface.
 */
export function instanceOfGetRecordListsResponseResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "recordCount" in value;

    return isInstance;
}

export function GetRecordListsResponseResultFromJSON(json: any): GetRecordListsResponseResult {
    return GetRecordListsResponseResultFromJSONTyped(json, false);
}

export function GetRecordListsResponseResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRecordListsResponseResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'guid': json['guid'],
        'user': GetRecordListsResponseResultUserFromJSON(json['user']),
        'name': json['name'],
        'recordCount': json['record_count'],
    };
}

export function GetRecordListsResponseResultToJSON(value?: GetRecordListsResponseResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'guid': value.guid,
        'user': GetRecordListsResponseResultUserToJSON(value.user),
        'name': value.name,
        'record_count': value.recordCount,
    };
}

