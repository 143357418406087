export const NoPaddingModal = {
  components: {
    Modal: {
      padding: 0,
      paddingLG: 0,
      paddingMD: 0,
      paddingXS: 0,
      paddingContentHorizontalLG: 0,
    },
  },
};
