import { ThemeConfig } from "antd5";

export const GoldenSkeleton: ThemeConfig = {
  components: {
    Skeleton: {
      gradientFromColor: "rgba(241, 194, 50, 0.2)",
      gradientToColor: "rgba(241, 194, 50, 0.4)",
    },
  },
};
