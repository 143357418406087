import React from "react";
import { WarningTwoTone } from "@ant-design/icons";
import { Input, Modal } from "antd5";

import { yellow500 } from "lib/themes/colors";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (buyerName: string) => void;
  modalTitle: string;
  title?: string;
  description?: string | React.ReactNode;
};

function TextInputModal({
  isOpen,
  onClose,
  onSubmit,
  title,
  modalTitle,
  description,
}: Props): JSX.Element {
  const [input, setInput] = React.useState<string>("");

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={modalTitle}
      onOk={() => {
        onSubmit(input);
        onClose();
      }}
      okText="Submit"
    >
      <div>
        <h1 style={{ fontSize: "16px" }}>
          <WarningTwoTone twoToneColor={yellow500} style={{ marginRight: "8px" }} />
          {title}
        </h1>
        {description && <span style={{ marginTop: "16px" }}>{description}</span>}
        <Input value={input} onChange={(v) => setInput(v.target.value)} autoFocus />
      </div>
    </Modal>
  );
}

export default TextInputModal;
