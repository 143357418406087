import React from "react";
import { Button } from "antd5";

import { assertCurrentUser } from "lib/currentUser";
import { ExpertPlan, FreePlan, GrowthPlan } from "lib/icons/PlanIcon";
import { arrowDown, checkOutlined } from "lib/icons/untitled_ui/SVGs";

import css from "./SubscriptionPlans.module.scss";

export function SubscriptionPlans() {
  const currentUser = assertCurrentUser();
  const currentPlan = currentUser.subscription;

  const isFreePlan = currentPlan === "freemium";
  const isGrowthPlan = currentPlan === "growth";
  const isExpertPlan = currentPlan === "expert";

  return (
    <div className={css.subscriptionPlans}>
      <Plan
        graphic={<FreePlan className={css.planIcon} />}
        title="Free"
        description="Great for those who need a single source-of-truth for finding & organising public sector opportunities."
        featurePromo="Our Stotles Free plan includes:"
        features={[
          "Unlimited basic UKI procurement data",
          "Core workflow & organsiation tools",
          "Self-serve access to Stotles Help-Center",
          "Unlimited users",
          "Self-serve CRM & collaboration integrations",
        ]}
        currentPlan={isFreePlan}
      />
      <Plan
        graphic={<GrowthPlan className={css.planIcon} />}
        title="Growth"
        description="Perfect to get ahead of tenders. Data & tools to approach the right buyers, with the right message, at the right time."
        featurePromo="Everything in Stotles Free, plus:"
        features={[
          "Unlimited advanced UKI procurement data",
          "Key buyer, competitor, and partner monitoring",
          "Supported CRM & collaboration integrations",
          "Live chat support & Customer Success on-boarding support",
          "Advanced data, tools, and services available for add-on",
        ]}
        currentPlan={isGrowthPlan}
      />
      <Plan
        graphic={<ExpertPlan className={css.planIcon} />}
        title="Expert"
        description="Ideal for complex needs and big growth ambitions using deeper integrations, better insights, & more support."
        featurePromo="Everything in Stotles Growth, plus:"
        features={[
          "Advanced buyer intent data sources",
          "Advanced teaming tools for multiple teams, territories & verticals",
          "Custom CRM and API integrations",
          "Custom reporting & analyst research",
          "Full-service Customer Success QBRs, enablement, training, & support",
        ]}
        currentPlan={isExpertPlan}
      />
    </div>
  );
}

function Plan({
  graphic,
  title,
  description,
  featurePromo,
  features,
  currentPlan,
}: {
  graphic: React.ReactNode;
  title: string;
  description: string;
  featurePromo: string;
  features: string[];
  currentPlan?: boolean;
}) {
  const scrollToPlanComparison = () => {
    const element = document.getElementById("planComparisonSection");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className={css.plan}>
      <div className={css.planHeader}>
        {graphic}
        {currentPlan && (
          <div className={css.currentPlanBadge}>
            <p>CURRENT</p>
          </div>
        )}
      </div>
      <h3>{title}</h3>
      <p>{description}</p>
      <Button className={css.scrollToPlansButton} onClick={scrollToPlanComparison}>
        {"See full plan details"}
        <span className={css.arrowDownIcon}>{arrowDown}</span>
      </Button>
      <p className={css.featuresHeading}>{featurePromo}</p>
      <ul>
        {features.map((feature) => (
          <li className={css.listItem}>
            <span className={css.checkIcon}>{checkOutlined}</span>
            <span>{feature}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
