import {
  ALL_COMPETITORS_TOKEN,
  ALL_CPV_CODES_TOKEN,
  ALL_KEYWORDS_TOKEN,
  ALL_PARTNERS_TOKEN,
} from "lib/types/models";
import { SupplierMentionType } from "../../lib/types/graphQLEnums";

export function countFilters(
  filters: Record<string, unknown>,
  groupedKeys: Record<string, string[]>,
  useNewNoticesSearch: boolean = false,
) {
  const result: Record<string, number> = {};

  for (const [groupKey, filterKeys] of Object.entries(groupedKeys)) {
    result[groupKey] = filterKeys.reduce((count: number, key: string) => {
      const value = filters[key];

      // special cases
      if (key === "supplierSme") {
        return count + (value === "" ? 0 : 1);
      }

      if (key === "supplierMentionType") {
        return (
          count +
          (value === undefined || value === SupplierMentionType.MentionedOrDirectlyAwarded ? 0 : 1)
        );
      }

      if (!value || !Array.isArray(value) || value.length === 0) {
        return count + countValues(value);
      }

      if (key === "supplierGuids" || key === "supplierIds") {
        let filterCount = countValues(value);

        if (value.includes(ALL_PARTNERS_TOKEN)) {
          filterCount--;
        }
        if (value.includes(ALL_COMPETITORS_TOKEN)) {
          filterCount--;
        }
        return count + filterCount;
      }

      if (key === "keywords") {
        return count + handleCountWithSignalToken(value as string[], ALL_KEYWORDS_TOKEN);
      }

      if (key === "cpvCodes") {
        return count + handleCountWithSignalToken(value as string[], ALL_CPV_CODES_TOKEN);
      }

      if (key === "relevanceScore" && useNewNoticesSearch) {
        return countValues(value) > 0 ? 1 : 0;
      }

      return count + countValues(value);
    }, 0);
  }

  return result;
}

/**
 * Removes all signal token from the count
 * @param items
 */
function handleCountWithSignalToken(items: string[], token: string): number {
  return items.includes(token) ? items.length - 1 : items.length;
}

function countValues(value: unknown): number {
  if (value === null || value === undefined) return 0;

  if (Array.isArray(value)) {
    return value.length;
  }

  if (typeof value === "object") {
    let count = 0;
    let hasFromOrTo = false;

    for (const [key, nestedValue] of Object.entries(value)) {
      if (key === "from" || key === "to") {
        if (nestedValue !== null && nestedValue !== undefined && !hasFromOrTo) {
          count += 1;
          hasFromOrTo = true;
        }
      } else if (key === "hideNulls") {
        count += nestedValue ? 1 : 0;
      } else if (typeof nestedValue === "object" && nestedValue !== null) {
        count += countValues(nestedValue); // Recursively count nested values
      }
    }
    return count;
  }

  if (typeof value === "string" || typeof value === "boolean") {
    return value ? 1 : 0;
  }

  return 0;
}
