import * as React from "react";

import { TextMatch } from "lib/qued/queryRunner";
import { escapeRegExp } from "lib/utils";
import { findRegexMatches, SimpleMatchHighlighter } from "./TextMatchHighlighter";

function useHighlightWordMatches(content: string, wordsToHighlight: string[] | null | undefined) {
  const keywordMatches = React.useMemo(() => {
    let all: TextMatch[] = [];
    if (!content.length || !wordsToHighlight?.length) return all;

    for (const word of wordsToHighlight) {
      // Escape the word for use in a regular expression and create a case-insensitive regexp
      const regexp = new RegExp(`\\b(${escapeRegExp(word)})\\b`, "gi");

      // Find all matches for the word in the content
      const matches = findRegexMatches(content, regexp);
      all = all.concat(matches);
    }

    return (
      all
        .sort((a, b) => a.start - b.start)
        // Remove any duplicate matches (same start or end position)
        .filter(
          (match, index) =>
            all.findIndex((m) => match.start === m.start || match.end === m.end) === index,
        )
    );
  }, [content, wordsToHighlight]);

  return keywordMatches;
}

type Props = {
  content: string;
  wordsToHighlight: string[] | null | undefined;
};

export function TextHighlighter({ content, wordsToHighlight }: Props): JSX.Element {
  const matches = useHighlightWordMatches(content, wordsToHighlight);

  return <SimpleMatchHighlighter text={content} matches={matches} />;
}
