import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { BuyerCategory } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";

export function useBuyerCategories(
  options?: UseQueryOptions<BuyerCategory[], unknown, BuyerCategory[], [string]>,
) {
  const api = useStotlesApi();

  return useQuery(
    ["buyerCategories"],
    async () => {
      const result = await api.getBuyerCategories();
      return result.buyer_categories;
    },
    options,
  );
}
