/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplierSummaryDto } from './SupplierSummaryDto';
import {
    SupplierSummaryDtoFromJSON,
    SupplierSummaryDtoFromJSONTyped,
    SupplierSummaryDtoToJSON,
} from './SupplierSummaryDto';

/**
 * 
 * @export
 * @interface LotDto
 */
export interface LotDto {
    /**
     * 
     * @type {number}
     * @memberof LotDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LotDto
     */
    lotTitle: string | null;
    /**
     * 
     * @type {string}
     * @memberof LotDto
     */
    lotNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof LotDto
     */
    awardDate: string | null;
    /**
     * 
     * @type {number}
     * @memberof LotDto
     */
    value: number | null;
    /**
     * 
     * @type {string}
     * @memberof LotDto
     */
    currency: string | null;
    /**
     * 
     * @type {string}
     * @memberof LotDto
     */
    calculatedExpiry: string | null;
    /**
     * 
     * @type {string}
     * @memberof LotDto
     */
    placeOfPerformance: string | null;
    /**
     * 
     * @type {string}
     * @memberof LotDto
     */
    startDate: string | null;
    /**
     * 
     * @type {string}
     * @memberof LotDto
     */
    endDate: string | null;
    /**
     * 
     * @type {Array<SupplierSummaryDto>}
     * @memberof LotDto
     */
    suppliers: Array<SupplierSummaryDto>;
}

/**
 * Check if a given object implements the LotDto interface.
 */
export function instanceOfLotDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "lotTitle" in value;
    isInstance = isInstance && "lotNumber" in value;
    isInstance = isInstance && "awardDate" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "calculatedExpiry" in value;
    isInstance = isInstance && "placeOfPerformance" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "suppliers" in value;

    return isInstance;
}

export function LotDtoFromJSON(json: any): LotDto {
    return LotDtoFromJSONTyped(json, false);
}

export function LotDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LotDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'lotTitle': json['lot_title'],
        'lotNumber': json['lot_number'],
        'awardDate': json['award_date'],
        'value': json['value'],
        'currency': json['currency'],
        'calculatedExpiry': json['calculated_expiry'],
        'placeOfPerformance': json['place_of_performance'],
        'startDate': json['start_date'],
        'endDate': json['end_date'],
        'suppliers': ((json['suppliers'] as Array<any>).map(SupplierSummaryDtoFromJSON)),
    };
}

export function LotDtoToJSON(value?: LotDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lot_title': value.lotTitle,
        'lot_number': value.lotNumber,
        'award_date': value.awardDate,
        'value': value.value,
        'currency': value.currency,
        'calculated_expiry': value.calculatedExpiry,
        'place_of_performance': value.placeOfPerformance,
        'start_date': value.startDate,
        'end_date': value.endDate,
        'suppliers': ((value.suppliers as Array<any>).map(SupplierSummaryDtoToJSON)),
    };
}

