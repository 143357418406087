/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateSavedViewType } from './CreateSavedViewType';
import {
    CreateSavedViewTypeFromJSON,
    CreateSavedViewTypeFromJSONTyped,
    CreateSavedViewTypeToJSON,
} from './CreateSavedViewType';

/**
 * 
 * @export
 * @interface CreateSavedViewRequest
 */
export interface CreateSavedViewRequest {
    /**
     * 
     * @type {CreateSavedViewType}
     * @memberof CreateSavedViewRequest
     */
    savedView: CreateSavedViewType;
}

/**
 * Check if a given object implements the CreateSavedViewRequest interface.
 */
export function instanceOfCreateSavedViewRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "savedView" in value;

    return isInstance;
}

export function CreateSavedViewRequestFromJSON(json: any): CreateSavedViewRequest {
    return CreateSavedViewRequestFromJSONTyped(json, false);
}

export function CreateSavedViewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSavedViewRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'savedView': CreateSavedViewTypeFromJSON(json['saved_view']),
    };
}

export function CreateSavedViewRequestToJSON(value?: CreateSavedViewRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'saved_view': CreateSavedViewTypeToJSON(value.savedView),
    };
}

