/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InspectRecordSearchRequestByEntriesApiParams } from './InspectRecordSearchRequestByEntriesApiParams';
import {
    InspectRecordSearchRequestByEntriesApiParamsFromJSON,
    InspectRecordSearchRequestByEntriesApiParamsFromJSONTyped,
    InspectRecordSearchRequestByEntriesApiParamsToJSON,
} from './InspectRecordSearchRequestByEntriesApiParams';
import type { InspectRecordSearchRequestBySearchApiParams } from './InspectRecordSearchRequestBySearchApiParams';
import {
    InspectRecordSearchRequestBySearchApiParamsFromJSON,
    InspectRecordSearchRequestBySearchApiParamsFromJSONTyped,
    InspectRecordSearchRequestBySearchApiParamsToJSON,
} from './InspectRecordSearchRequestBySearchApiParams';

/**
 * 
 * @export
 * @interface InspectRecordSearchRequest
 */
export interface InspectRecordSearchRequest {
    /**
     * 
     * @type {InspectRecordSearchRequestByEntriesApiParams}
     * @memberof InspectRecordSearchRequest
     */
    byEntriesApiParams?: InspectRecordSearchRequestByEntriesApiParams;
    /**
     * 
     * @type {InspectRecordSearchRequestBySearchApiParams}
     * @memberof InspectRecordSearchRequest
     */
    bySearchApiParams?: InspectRecordSearchRequestBySearchApiParams;
}

/**
 * Check if a given object implements the InspectRecordSearchRequest interface.
 */
export function instanceOfInspectRecordSearchRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InspectRecordSearchRequestFromJSON(json: any): InspectRecordSearchRequest {
    return InspectRecordSearchRequestFromJSONTyped(json, false);
}

export function InspectRecordSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectRecordSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'byEntriesApiParams': !exists(json, 'by_entries_api_params') ? undefined : InspectRecordSearchRequestByEntriesApiParamsFromJSON(json['by_entries_api_params']),
        'bySearchApiParams': !exists(json, 'by_search_api_params') ? undefined : InspectRecordSearchRequestBySearchApiParamsFromJSON(json['by_search_api_params']),
    };
}

export function InspectRecordSearchRequestToJSON(value?: InspectRecordSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'by_entries_api_params': InspectRecordSearchRequestByEntriesApiParamsToJSON(value.byEntriesApiParams),
        'by_search_api_params': InspectRecordSearchRequestBySearchApiParamsToJSON(value.bySearchApiParams),
    };
}

