/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecordSearchConfigRecordFilterBuyers } from './RecordSearchConfigRecordFilterBuyers';
import {
    RecordSearchConfigRecordFilterBuyersFromJSON,
    RecordSearchConfigRecordFilterBuyersFromJSONTyped,
    RecordSearchConfigRecordFilterBuyersToJSON,
} from './RecordSearchConfigRecordFilterBuyers';
import type { RecordSearchConfigRecordFilterDateFilter } from './RecordSearchConfigRecordFilterDateFilter';
import {
    RecordSearchConfigRecordFilterDateFilterFromJSON,
    RecordSearchConfigRecordFilterDateFilterFromJSONTyped,
    RecordSearchConfigRecordFilterDateFilterToJSON,
} from './RecordSearchConfigRecordFilterDateFilter';
import type { RecordSearchConfigRecordFilterFrameworks } from './RecordSearchConfigRecordFilterFrameworks';
import {
    RecordSearchConfigRecordFilterFrameworksFromJSON,
    RecordSearchConfigRecordFilterFrameworksFromJSONTyped,
    RecordSearchConfigRecordFilterFrameworksToJSON,
} from './RecordSearchConfigRecordFilterFrameworks';
import type { RecordSearchConfigRecordFilterNullableDateFilter } from './RecordSearchConfigRecordFilterNullableDateFilter';
import {
    RecordSearchConfigRecordFilterNullableDateFilterFromJSON,
    RecordSearchConfigRecordFilterNullableDateFilterFromJSONTyped,
    RecordSearchConfigRecordFilterNullableDateFilterToJSON,
} from './RecordSearchConfigRecordFilterNullableDateFilter';
import type { RecordSearchConfigRecordFilterSuppliers } from './RecordSearchConfigRecordFilterSuppliers';
import {
    RecordSearchConfigRecordFilterSuppliersFromJSON,
    RecordSearchConfigRecordFilterSuppliersFromJSONTyped,
    RecordSearchConfigRecordFilterSuppliersToJSON,
} from './RecordSearchConfigRecordFilterSuppliers';
import type { RecordSearchConfigRecordFilterValue } from './RecordSearchConfigRecordFilterValue';
import {
    RecordSearchConfigRecordFilterValueFromJSON,
    RecordSearchConfigRecordFilterValueFromJSONTyped,
    RecordSearchConfigRecordFilterValueToJSON,
} from './RecordSearchConfigRecordFilterValue';

/**
 * 
 * @export
 * @interface RecordSearchConfigRecordFilter
 */
export interface RecordSearchConfigRecordFilter {
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilter
     */
    id?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilter
     */
    guid?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilter
     */
    procurementStageId?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilter
     */
    procurementProcessId?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilter
     */
    recordType?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilter
     */
    countryCode?: Array<string> | null;
    /**
     * 
     * @type {RecordSearchConfigRecordFilterDateFilter}
     * @memberof RecordSearchConfigRecordFilter
     */
    publishDate?: RecordSearchConfigRecordFilterDateFilter;
    /**
     * 
     * @type {RecordSearchConfigRecordFilterBuyers}
     * @memberof RecordSearchConfigRecordFilter
     */
    buyers?: RecordSearchConfigRecordFilterBuyers;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilter
     */
    cpvDimensions?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilter
     */
    languageCode?: Array<string> | null;
    /**
     * 
     * @type {RecordSearchConfigRecordFilterValue}
     * @memberof RecordSearchConfigRecordFilter
     */
    value?: RecordSearchConfigRecordFilterValue;
    /**
     * 
     * @type {RecordSearchConfigRecordFilterNullableDateFilter}
     * @memberof RecordSearchConfigRecordFilter
     */
    closeDate?: RecordSearchConfigRecordFilterNullableDateFilter;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilter
     */
    stage?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilter
     */
    relevanceScore?: Array<RecordSearchConfigRecordFilterRelevanceScoreEnum>;
    /**
     * 
     * @type {RecordSearchConfigRecordFilterDateFilter}
     * @memberof RecordSearchConfigRecordFilter
     */
    awardDate?: RecordSearchConfigRecordFilterDateFilter;
    /**
     * 
     * @type {RecordSearchConfigRecordFilterNullableDateFilter}
     * @memberof RecordSearchConfigRecordFilter
     */
    expiryDate?: RecordSearchConfigRecordFilterNullableDateFilter;
    /**
     * 
     * @type {RecordSearchConfigRecordFilterSuppliers}
     * @memberof RecordSearchConfigRecordFilter
     */
    suppliers?: RecordSearchConfigRecordFilterSuppliers;
    /**
     * 
     * @type {RecordSearchConfigRecordFilterFrameworks}
     * @memberof RecordSearchConfigRecordFilter
     */
    frameworks?: RecordSearchConfigRecordFilterFrameworks;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchConfigRecordFilter
     */
    textQuery?: string | null;
}


/**
 * @export
 */
export const RecordSearchConfigRecordFilterRelevanceScoreEnum = {
    None: 'None',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High'
} as const;
export type RecordSearchConfigRecordFilterRelevanceScoreEnum = typeof RecordSearchConfigRecordFilterRelevanceScoreEnum[keyof typeof RecordSearchConfigRecordFilterRelevanceScoreEnum];


/**
 * Check if a given object implements the RecordSearchConfigRecordFilter interface.
 */
export function instanceOfRecordSearchConfigRecordFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RecordSearchConfigRecordFilterFromJSON(json: any): RecordSearchConfigRecordFilter {
    return RecordSearchConfigRecordFilterFromJSONTyped(json, false);
}

export function RecordSearchConfigRecordFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSearchConfigRecordFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'guid': !exists(json, 'guid') ? undefined : json['guid'],
        'procurementStageId': !exists(json, 'procurement_stage_id') ? undefined : json['procurement_stage_id'],
        'procurementProcessId': !exists(json, 'procurement_process_id') ? undefined : json['procurement_process_id'],
        'recordType': !exists(json, 'record_type') ? undefined : json['record_type'],
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
        'publishDate': !exists(json, 'publish_date') ? undefined : RecordSearchConfigRecordFilterDateFilterFromJSON(json['publish_date']),
        'buyers': !exists(json, 'buyers') ? undefined : RecordSearchConfigRecordFilterBuyersFromJSON(json['buyers']),
        'cpvDimensions': !exists(json, 'cpv_dimensions') ? undefined : json['cpv_dimensions'],
        'languageCode': !exists(json, 'language_code') ? undefined : json['language_code'],
        'value': !exists(json, 'value') ? undefined : RecordSearchConfigRecordFilterValueFromJSON(json['value']),
        'closeDate': !exists(json, 'close_date') ? undefined : RecordSearchConfigRecordFilterNullableDateFilterFromJSON(json['close_date']),
        'stage': !exists(json, 'stage') ? undefined : json['stage'],
        'relevanceScore': !exists(json, 'relevance_score') ? undefined : json['relevance_score'],
        'awardDate': !exists(json, 'award_date') ? undefined : RecordSearchConfigRecordFilterDateFilterFromJSON(json['award_date']),
        'expiryDate': !exists(json, 'expiry_date') ? undefined : RecordSearchConfigRecordFilterNullableDateFilterFromJSON(json['expiry_date']),
        'suppliers': !exists(json, 'suppliers') ? undefined : RecordSearchConfigRecordFilterSuppliersFromJSON(json['suppliers']),
        'frameworks': !exists(json, 'frameworks') ? undefined : RecordSearchConfigRecordFilterFrameworksFromJSON(json['frameworks']),
        'textQuery': !exists(json, 'text_query') ? undefined : json['text_query'],
    };
}

export function RecordSearchConfigRecordFilterToJSON(value?: RecordSearchConfigRecordFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'guid': value.guid,
        'procurement_stage_id': value.procurementStageId,
        'procurement_process_id': value.procurementProcessId,
        'record_type': value.recordType,
        'country_code': value.countryCode,
        'publish_date': RecordSearchConfigRecordFilterDateFilterToJSON(value.publishDate),
        'buyers': RecordSearchConfigRecordFilterBuyersToJSON(value.buyers),
        'cpv_dimensions': value.cpvDimensions,
        'language_code': value.languageCode,
        'value': RecordSearchConfigRecordFilterValueToJSON(value.value),
        'close_date': RecordSearchConfigRecordFilterNullableDateFilterToJSON(value.closeDate),
        'stage': value.stage,
        'relevance_score': value.relevanceScore,
        'award_date': RecordSearchConfigRecordFilterDateFilterToJSON(value.awardDate),
        'expiry_date': RecordSearchConfigRecordFilterNullableDateFilterToJSON(value.expiryDate),
        'suppliers': RecordSearchConfigRecordFilterSuppliersToJSON(value.suppliers),
        'frameworks': RecordSearchConfigRecordFilterFrameworksToJSON(value.frameworks),
        'text_query': value.textQuery,
    };
}

