import * as React from "react";

import * as colors from "./../themes/colors";

export default function RelevanceIcon({
  relevance,
  darkMode = false,
}: {
  relevance?: number | null;
  darkMode: boolean;
}): JSX.Element {
  const colour = darkMode ? colors.grey50 : colors.grey25;
  let bar1 = colour;
  let bar2 = colour;
  let bar3 = colour;
  if (relevance === 1) {
    bar1 = "#E12F31";
  } else if (relevance === 2) {
    bar1 = "#FF981A";
    bar2 = "#FFCA4C";
  } else if (relevance === 3) {
    bar1 = "#15BF4C";
    bar2 = "#33DD6A";
    bar3 = "#51FB88";
  }

  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label={`Score: ${relevance}`}
    >
      <rect y="0.5" width="5" height="21" rx="2.5" fill={bar1} />
      <rect x="7" y="0.5" width="5" height="21" rx="2.5" fill={bar2} />
      <rect x="14" y="0.5" width="5" height="21" rx="2.5" fill={bar3} />
    </svg>
  );
}
