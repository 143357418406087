import React from "react";
import { Tooltip } from "antd5";
import classNames from "classnames";

import { TextLink } from "components/actions/Links";
import { EventNames } from "lib/tracking";

import css from "./ChartBar.module.scss";

export type ChartBarProps = {
  percentage: number;
  label: string;
  colour: "mid-blue" | "light-blue";
  tooltipContent?: React.ReactNode;
  link?: string;
  cpvCode: string;
};

export function ChartBar({
  label,
  percentage,
  colour,
  link,
  tooltipContent,
  cpvCode,
}: ChartBarProps): JSX.Element {
  if (percentage > 100) {
    percentage = 100;
  } else if (percentage < 0) {
    percentage = 0;
  }

  let bar = link ? (
    <TextLink
      className={classNames(css.bar, css[colour])}
      to={link}
      targetType="new-tab"
      eventName={EventNames.cpvChartClicked}
      eventAttributes={{ link: link, "CPV code": cpvCode }}
    ></TextLink>
  ) : (
    <div className={classNames(css.bar, css[colour])}></div>
  );

  if (tooltipContent) {
    bar = <Tooltip title={tooltipContent}>{bar}</Tooltip>;
  }
  return (
    <>
      <div
        className={css.chartBar}
        style={{
          gridTemplateColumns: `${percentage + 1}fr minmax(0, ${151 - percentage}fr)`,
        }}
      >
        {bar}
        <div className={css.label}>{label}</div>
      </div>
    </>
  );
}
