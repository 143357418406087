/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecordSearchConfig } from './RecordSearchConfig';
import {
    RecordSearchConfigFromJSON,
    RecordSearchConfigFromJSONTyped,
    RecordSearchConfigToJSON,
} from './RecordSearchConfig';

/**
 * 
 * @export
 * @interface InspectRecordSearchResponse
 */
export interface InspectRecordSearchResponse {
    /**
     * 
     * @type {RecordSearchConfig}
     * @memberof InspectRecordSearchResponse
     */
    searchConfig: RecordSearchConfig;
    /**
     * 
     * @type {string}
     * @memberof InspectRecordSearchResponse
     */
    searchSql: string;
}

/**
 * Check if a given object implements the InspectRecordSearchResponse interface.
 */
export function instanceOfInspectRecordSearchResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "searchConfig" in value;
    isInstance = isInstance && "searchSql" in value;

    return isInstance;
}

export function InspectRecordSearchResponseFromJSON(json: any): InspectRecordSearchResponse {
    return InspectRecordSearchResponseFromJSONTyped(json, false);
}

export function InspectRecordSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectRecordSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'searchConfig': RecordSearchConfigFromJSON(json['search_config']),
        'searchSql': json['search_sql'],
    };
}

export function InspectRecordSearchResponseToJSON(value?: InspectRecordSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'search_config': RecordSearchConfigToJSON(value.searchConfig),
        'search_sql': value.searchSql,
    };
}

