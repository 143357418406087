import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { SupplierSearchRequest, SupplierSearchResponse } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

export function useSuppliers(
  request: SupplierSearchRequest,
  options?: UseQueryOptions<
    SupplierSearchResponse,
    unknown,
    SupplierSearchResponse,
    (string | SupplierSearchRequest)[]
  >,
) {
  const api = useOpenApi();
  return useQuery(
    ["suppliers", request],
    async () => {
      return api.searchSuppliers({ supplierSearchRequest: request });
    },
    options,
  );
}
