import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { SavedSuppliersQuery } from "lib/generated/app-service-gql/graphql";
import { graphql } from "../../../generated/app-service-gql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const savedSuppliersQuery = graphql(`
  query savedSuppliers {
    signalSettings {
      competitorGuids
      partnerGuids
    }
  }
`);

export function useSavedSuppliers(
  options?: UseQueryOptions<SavedSuppliersQuery, unknown, SavedSuppliersQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(["savedSuppliers"], savedSuppliersQuery, [], {
    ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
    ...options,
  });

  return { data: data?.signalSettings, ...rest };
}
