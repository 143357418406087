/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordDtoRelatedFrameworkProcessDto
 */
export interface RecordDtoRelatedFrameworkProcessDto {
    /**
     * 
     * @type {string}
     * @memberof RecordDtoRelatedFrameworkProcessDto
     */
    frameworkId: string;
    /**
     * 
     * @type {string}
     * @memberof RecordDtoRelatedFrameworkProcessDto
     */
    processType: RecordDtoRelatedFrameworkProcessDtoProcessTypeEnum;
}


/**
 * @export
 */
export const RecordDtoRelatedFrameworkProcessDtoProcessTypeEnum = {
    Framework: 'FRAMEWORK',
    FrameworkCallOff: 'FRAMEWORK_CALL_OFF'
} as const;
export type RecordDtoRelatedFrameworkProcessDtoProcessTypeEnum = typeof RecordDtoRelatedFrameworkProcessDtoProcessTypeEnum[keyof typeof RecordDtoRelatedFrameworkProcessDtoProcessTypeEnum];


/**
 * Check if a given object implements the RecordDtoRelatedFrameworkProcessDto interface.
 */
export function instanceOfRecordDtoRelatedFrameworkProcessDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "frameworkId" in value;
    isInstance = isInstance && "processType" in value;

    return isInstance;
}

export function RecordDtoRelatedFrameworkProcessDtoFromJSON(json: any): RecordDtoRelatedFrameworkProcessDto {
    return RecordDtoRelatedFrameworkProcessDtoFromJSONTyped(json, false);
}

export function RecordDtoRelatedFrameworkProcessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordDtoRelatedFrameworkProcessDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frameworkId': json['framework_id'],
        'processType': json['process_type'],
    };
}

export function RecordDtoRelatedFrameworkProcessDtoToJSON(value?: RecordDtoRelatedFrameworkProcessDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'framework_id': value.frameworkId,
        'process_type': value.processType,
    };
}

