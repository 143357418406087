/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NoticeSignalsResponseResultsInner
 */
export interface NoticeSignalsResponseResultsInner {
    /**
     * 
     * @type {string}
     * @memberof NoticeSignalsResponseResultsInner
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof NoticeSignalsResponseResultsInner
     */
    score: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof NoticeSignalsResponseResultsInner
     */
    matchedSignalIds: Array<string>;
}

/**
 * Check if a given object implements the NoticeSignalsResponseResultsInner interface.
 */
export function instanceOfNoticeSignalsResponseResultsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "score" in value;
    isInstance = isInstance && "matchedSignalIds" in value;

    return isInstance;
}

export function NoticeSignalsResponseResultsInnerFromJSON(json: any): NoticeSignalsResponseResultsInner {
    return NoticeSignalsResponseResultsInnerFromJSONTyped(json, false);
}

export function NoticeSignalsResponseResultsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoticeSignalsResponseResultsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'score': json['score'],
        'matchedSignalIds': json['matchedSignalIds'],
    };
}

export function NoticeSignalsResponseResultsInnerToJSON(value?: NoticeSignalsResponseResultsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'score': value.score,
        'matchedSignalIds': value.matchedSignalIds,
    };
}

