import * as React from "react";

import { CompanyDataProvider, ICompanyDataProvider } from "lib/data_providers/CompanyDataProvider";
import { DefaultApi } from "lib/generated/app-api";
import { BuyerDataProvider, IBuyerDataProvider } from "../data_providers/BuyerDataProvider";
import { DIContainer, Interface } from "../diContainer";
import useAsync, { AsyncState, checkAsyncErrors } from "../hooks/useAsync";
import { defineInjectable } from "../reactInjector";
import StotlesAPI from "../StotlesApi";

type StotlesDataProvidersContainer = DIContainer;

export function CreateStotlesDataProvidersContainer(
  api: StotlesAPI,
  openApi: DefaultApi,
): StotlesDataProvidersContainer {
  const provider = new DIContainer();

  // Register more providers here!
  provider.registerProvider(IBuyerDataProvider, () => new BuyerDataProvider(api));
  provider.registerProvider(ICompanyDataProvider, () => new CompanyDataProvider(openApi));

  return provider;
}

const STOTLES_DATA_INJECTABLE = defineInjectable<StotlesDataProvidersContainer>(
  "StotlesDataProvidersContainer",
);

export const StotlesDataContextProvider = STOTLES_DATA_INJECTABLE.Provider;

/**
 * Wraps `useAsync` by first resolving the correct data provider.
 * The providers have to be registered in `CreateStotlesDataProvidersContainer`.
 * Throws if the async function raises an error.
 *
 * @param dataProviderClass provider interface to use for the fetch function
 * @param fn takes a provider instance and returns a promise with the data
 * @param dependencies acts the same as dependencies in `useCallback`, should list all dependencies in `fn`
 * @returns AsyncState with the response of `fn`
 */
export function useStotlesData<T extends Interface, U, HookDeps extends unknown[]>(
  dataProviderClass: T,
  fn: (t: InstanceType<T>) => Promise<U>,
  dependencies: HookDeps,
): AsyncState<U> {
  const queryResult = useStotlesDataUnsafe(dataProviderClass, fn, dependencies);

  // because this is used as a hook, if it throws it means that the component will be blocked from
  // rendering and you'll get a white-out
  try {
    return checkAsyncErrors(queryResult);
  } catch (e) {
    return {
      status: "error",
      value: undefined,
      error: e,
    };
  }
}

/**
 * Wraps `useAsync` by first resolving the correct data provider.
 * See `useStotlesData` for full documentation. This function doesn't throw when it encounters
 * errors but just returns regular `AsyncState`
 */
function useStotlesDataUnsafe<T extends Interface, U, HookDeps extends unknown[]>(
  dataProviderClass: T,
  fn: (t: InstanceType<T>) => Promise<U>,
  dependencies: HookDeps,
): AsyncState<U> {
  const diContainer = STOTLES_DATA_INJECTABLE.useInstance();
  const dataProvider = React.useMemo(
    () => diContainer.getInstance(dataProviderClass),
    [diContainer, dataProviderClass],
  );

  // `fn` is omitted on purpose from dependencies as its real dependencies should be passed as a param to this hook
  // this makes it much nicer to specify `fn` as an arrow function instead of having a separate `useCallback` for it.
  // Unfortunately the eslint-plugin-react-hooks plugin doesn't support custom hooks with our signature
  // so we can't use it to enforce correct deps, but these functions should be small enough to never need more than 1-2.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryFn = React.useCallback(() => fn(dataProvider), [dataProvider, ...dependencies]);

  return useAsync(queryFn);
}
