/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LinkListSectionGenerated } from './LinkListSectionGenerated';
import {
    LinkListSectionGeneratedFromJSON,
    LinkListSectionGeneratedFromJSONTyped,
    LinkListSectionGeneratedToJSON,
} from './LinkListSectionGenerated';
import type { LinkListSectionParameters } from './LinkListSectionParameters';
import {
    LinkListSectionParametersFromJSON,
    LinkListSectionParametersFromJSONTyped,
    LinkListSectionParametersToJSON,
} from './LinkListSectionParameters';

/**
 * 
 * @export
 * @interface LinkListSection
 */
export interface LinkListSection {
    /**
     * 
     * @type {string}
     * @memberof LinkListSection
     */
    type?: LinkListSectionTypeEnum;
    /**
     * 
     * @type {LinkListSectionParameters}
     * @memberof LinkListSection
     */
    parameters: LinkListSectionParameters;
    /**
     * 
     * @type {LinkListSectionGenerated}
     * @memberof LinkListSection
     */
    generated: LinkListSectionGenerated;
}


/**
 * @export
 */
export const LinkListSectionTypeEnum = {
    LinkList: 'LINK_LIST'
} as const;
export type LinkListSectionTypeEnum = typeof LinkListSectionTypeEnum[keyof typeof LinkListSectionTypeEnum];


/**
 * Check if a given object implements the LinkListSection interface.
 */
export function instanceOfLinkListSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parameters" in value;
    isInstance = isInstance && "generated" in value;

    return isInstance;
}

export function LinkListSectionFromJSON(json: any): LinkListSection {
    return LinkListSectionFromJSONTyped(json, false);
}

export function LinkListSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkListSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'parameters': LinkListSectionParametersFromJSON(json['parameters']),
        'generated': LinkListSectionGeneratedFromJSON(json['generated']),
    };
}

export function LinkListSectionToJSON(value?: LinkListSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'parameters': LinkListSectionParametersToJSON(value.parameters),
        'generated': LinkListSectionGeneratedToJSON(value.generated),
    };
}

