import React from "react";
import { ControlProps } from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";

import { Number } from "./Number";

function NumberControl({
  data,
  label,
  required,
  path,
  handleChange,
  errors,
}: ControlProps): JSX.Element {
  return (
    <Number
      value={data}
      label={label}
      required={required}
      updateValue={(value: number | null) => handleChange(path, value)}
      errors={errors}
    />
  );
}

export default withJsonFormsControlProps(NumberControl);
