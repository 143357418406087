/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InspectRecordSearchRequestBySearchApiParams
 */
export interface InspectRecordSearchRequestBySearchApiParams {
    /**
     * 
     * @type {number}
     * @memberof InspectRecordSearchRequestBySearchApiParams
     */
    companyGuid?: number;
    /**
     * 
     * @type {string}
     * @memberof InspectRecordSearchRequestBySearchApiParams
     */
    userGuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InspectRecordSearchRequestBySearchApiParams
     */
    apiParams: string;
}

/**
 * Check if a given object implements the InspectRecordSearchRequestBySearchApiParams interface.
 */
export function instanceOfInspectRecordSearchRequestBySearchApiParams(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "apiParams" in value;

    return isInstance;
}

export function InspectRecordSearchRequestBySearchApiParamsFromJSON(json: any): InspectRecordSearchRequestBySearchApiParams {
    return InspectRecordSearchRequestBySearchApiParamsFromJSONTyped(json, false);
}

export function InspectRecordSearchRequestBySearchApiParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InspectRecordSearchRequestBySearchApiParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyGuid': !exists(json, 'company_guid') ? undefined : json['company_guid'],
        'userGuid': !exists(json, 'user_guid') ? undefined : json['user_guid'],
        'apiParams': json['api_params'],
    };
}

export function InspectRecordSearchRequestBySearchApiParamsToJSON(value?: InspectRecordSearchRequestBySearchApiParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_guid': value.companyGuid,
        'user_guid': value.userGuid,
        'api_params': value.apiParams,
    };
}

