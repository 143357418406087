/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSearchRequest
 */
export interface UserSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof UserSearchRequest
     */
    sort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSearchRequest
     */
    sortOrder?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserSearchRequest
     */
    limit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserSearchRequest
     */
    offset?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserSearchRequest
     */
    text?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSearchRequest
     */
    companyGuids?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSearchRequest
     */
    teamIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSearchRequest
     */
    roles?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSearchRequest
     */
    access?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserSearchRequest
     */
    configured?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserSearchRequest
     */
    adminOnly?: boolean | null;
}

/**
 * Check if a given object implements the UserSearchRequest interface.
 */
export function instanceOfUserSearchRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserSearchRequestFromJSON(json: any): UserSearchRequest {
    return UserSearchRequestFromJSONTyped(json, false);
}

export function UserSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'sortOrder': !exists(json, 'sort_order') ? undefined : json['sort_order'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'companyGuids': !exists(json, 'company_guids') ? undefined : json['company_guids'],
        'teamIds': !exists(json, 'team_ids') ? undefined : json['team_ids'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'access': !exists(json, 'access') ? undefined : json['access'],
        'configured': !exists(json, 'configured') ? undefined : json['configured'],
        'adminOnly': !exists(json, 'admin_only') ? undefined : json['admin_only'],
    };
}

export function UserSearchRequestToJSON(value?: UserSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sort': value.sort,
        'sort_order': value.sortOrder,
        'limit': value.limit,
        'offset': value.offset,
        'text': value.text,
        'company_guids': value.companyGuids,
        'team_ids': value.teamIds,
        'roles': value.roles,
        'access': value.access,
        'configured': value.configured,
        'admin_only': value.adminOnly,
    };
}

