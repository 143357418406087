import * as React from "react";
import classNames from "classnames";

import { yellow } from "lib/themes/colors";

import css from "./DefaultIcon.module.scss";

type Props = {
  fill?: string;
  className?: string;
  width?: number;
  height?: number;
};

export default function Sparkles({
  fill = yellow,
  width = 16,
  height = 16,
  className,
}: Props): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      className={classNames(css.defaultIcon, className)}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="AI Stars">
        <path
          id="Vector 4 (Stroke)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.375 1.75C19.0472 1.75 19.6208 2.27332 19.7313 2.98733L20.5712 8.41474C21.2888 13.051 23.7562 17.1607 27.3877 19.7679C27.7702 20.0426 28 20.5049 28 21C28 21.4951 27.7702 21.9574 27.3877 22.2321C23.7562 24.8393 21.2888 28.949 20.5712 33.5853L19.7313 39.0127C19.6208 39.7267 19.0472 40.25 18.375 40.25C17.7028 40.25 17.1292 39.7267 17.0187 39.0127L16.1788 33.5853C15.4612 28.949 12.9938 24.8393 9.36229 22.2321C8.97976 21.9574 8.75 21.4951 8.75 21C8.75 20.5049 8.97976 20.0426 9.36229 19.7679C12.9938 17.1607 15.4612 13.051 16.1788 8.41474L17.0187 2.98733C17.1292 2.27332 17.7028 1.75 18.375 1.75Z"
          fill={fill}
        />
        <path
          id="Vector 4 (Stroke)_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.625 17.5C38.2972 17.5 38.8708 17.9995 38.9813 18.6811L39.8212 23.8618C40.5388 28.2873 43.0062 32.2102 46.6377 34.6989C47.0202 34.9611 47.25 35.4024 47.25 35.875C47.25 36.3476 47.0202 36.7889 46.6377 37.0511C43.0062 39.5398 40.5388 43.4627 39.8212 47.8882L38.9813 53.0689C38.8708 53.7505 38.2972 54.25 37.625 54.25C36.9528 54.25 36.3792 53.7505 36.2687 53.0689L35.4288 47.8882C34.7112 43.4627 32.2438 39.5398 28.6123 37.0511C28.2298 36.7889 28 36.3476 28 35.875C28 35.4024 28.2298 34.9611 28.6123 34.6989C32.2438 32.2102 34.7112 28.2873 35.4288 23.8618L36.2687 18.6811C36.3792 17.9995 36.9528 17.5 37.625 17.5Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
