/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NoticeDetailsRequest
 */
export interface NoticeDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof NoticeDetailsRequest
     */
    recordId: string;
}

/**
 * Check if a given object implements the NoticeDetailsRequest interface.
 */
export function instanceOfNoticeDetailsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recordId" in value;

    return isInstance;
}

export function NoticeDetailsRequestFromJSON(json: any): NoticeDetailsRequest {
    return NoticeDetailsRequestFromJSONTyped(json, false);
}

export function NoticeDetailsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoticeDetailsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordId': json['recordId'],
    };
}

export function NoticeDetailsRequestToJSON(value?: NoticeDetailsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordId': value.recordId,
    };
}

