/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyerSummaryDto } from './BuyerSummaryDto';
import {
    BuyerSummaryDtoFromJSON,
    BuyerSummaryDtoFromJSONTyped,
    BuyerSummaryDtoToJSON,
} from './BuyerSummaryDto';
import type { CpvCodesDto } from './CpvCodesDto';
import {
    CpvCodesDtoFromJSON,
    CpvCodesDtoFromJSONTyped,
    CpvCodesDtoToJSON,
} from './CpvCodesDto';
import type { LotDto } from './LotDto';
import {
    LotDtoFromJSON,
    LotDtoFromJSONTyped,
    LotDtoToJSON,
} from './LotDto';
import type { ProcurementStageDto } from './ProcurementStageDto';
import {
    ProcurementStageDtoFromJSON,
    ProcurementStageDtoFromJSONTyped,
    ProcurementStageDtoToJSON,
} from './ProcurementStageDto';
import type { RecordDtoAssigneeDto } from './RecordDtoAssigneeDto';
import {
    RecordDtoAssigneeDtoFromJSON,
    RecordDtoAssigneeDtoFromJSONTyped,
    RecordDtoAssigneeDtoToJSON,
} from './RecordDtoAssigneeDto';
import type { RecordDtoRelatedFrameworkProcessDto } from './RecordDtoRelatedFrameworkProcessDto';
import {
    RecordDtoRelatedFrameworkProcessDtoFromJSON,
    RecordDtoRelatedFrameworkProcessDtoFromJSONTyped,
    RecordDtoRelatedFrameworkProcessDtoToJSON,
} from './RecordDtoRelatedFrameworkProcessDto';
import type { RecordDtoSeenByUser } from './RecordDtoSeenByUser';
import {
    RecordDtoSeenByUserFromJSON,
    RecordDtoSeenByUserFromJSONTyped,
    RecordDtoSeenByUserToJSON,
} from './RecordDtoSeenByUser';
import type { RecordListSummaryDto } from './RecordListSummaryDto';
import {
    RecordListSummaryDtoFromJSON,
    RecordListSummaryDtoFromJSONTyped,
    RecordListSummaryDtoToJSON,
} from './RecordListSummaryDto';
import type { SignalDto } from './SignalDto';
import {
    SignalDtoFromJSON,
    SignalDtoFromJSONTyped,
    SignalDtoToJSON,
} from './SignalDto';

/**
 * 
 * @export
 * @interface RecordDto
 */
export interface RecordDto {
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    guid: string;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof RecordDto
     */
    valueSrc: number | null;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    currency: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    buyerContactEmail: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    buyerContactPhone: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    buyerContactName: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    publishDate: string;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    stage: string;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    closeDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    tenderDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    awardDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    expiryDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RecordDto
     */
    tendersReceived: number | null;
    /**
     * 
     * @type {BuyerSummaryDto}
     * @memberof RecordDto
     */
    buyer: BuyerSummaryDto;
    /**
     * 
     * @type {Array<BuyerSummaryDto>}
     * @memberof RecordDto
     */
    buyers: Array<BuyerSummaryDto>;
    /**
     * 
     * @type {Array<CpvCodesDto>}
     * @memberof RecordDto
     */
    cpvCodes: Array<CpvCodesDto>;
    /**
     * 
     * @type {Array<LotDto>}
     * @memberof RecordDto
     */
    lots?: Array<LotDto> | null;
    /**
     * 
     * @type {Array<RecordListSummaryDto>}
     * @memberof RecordDto
     */
    lists: Array<RecordListSummaryDto>;
    /**
     * 
     * @type {Array<SignalDto>}
     * @memberof RecordDto
     */
    signals: Array<SignalDto>;
    /**
     * 
     * @type {number}
     * @memberof RecordDto
     */
    relevanceScore?: number | null;
    /**
     * 
     * @type {ProcurementStageDto}
     * @memberof RecordDto
     */
    procurementStage: ProcurementStageDto;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    hiddenDate: string | null;
    /**
     * 
     * @type {number}
     * @memberof RecordDto
     */
    commentCount?: number | null;
    /**
     * 
     * @type {RecordDtoAssigneeDto}
     * @memberof RecordDto
     */
    assignee?: RecordDtoAssigneeDto;
    /**
     * 
     * @type {Array<RecordDtoSeenByUser>}
     * @memberof RecordDto
     */
    seenByUsers: Array<RecordDtoSeenByUser>;
    /**
     * 
     * @type {Date}
     * @memberof RecordDto
     */
    addedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    canonicalUrlPath: string;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    urlPath: string;
    /**
     * 
     * @type {string}
     * @memberof RecordDto
     */
    type: string;
    /**
     * 
     * @type {RecordDtoRelatedFrameworkProcessDto}
     * @memberof RecordDto
     */
    relatedFrameworkProcess?: RecordDtoRelatedFrameworkProcessDto;
}

/**
 * Check if a given object implements the RecordDto interface.
 */
export function instanceOfRecordDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "valueSrc" in value;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "buyerContactEmail" in value;
    isInstance = isInstance && "buyerContactPhone" in value;
    isInstance = isInstance && "buyerContactName" in value;
    isInstance = isInstance && "publishDate" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "stage" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "tendersReceived" in value;
    isInstance = isInstance && "buyer" in value;
    isInstance = isInstance && "buyers" in value;
    isInstance = isInstance && "cpvCodes" in value;
    isInstance = isInstance && "lists" in value;
    isInstance = isInstance && "signals" in value;
    isInstance = isInstance && "procurementStage" in value;
    isInstance = isInstance && "hiddenDate" in value;
    isInstance = isInstance && "seenByUsers" in value;
    isInstance = isInstance && "canonicalUrlPath" in value;
    isInstance = isInstance && "urlPath" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function RecordDtoFromJSON(json: any): RecordDto {
    return RecordDtoFromJSONTyped(json, false);
}

export function RecordDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guid': json['guid'],
        'name': json['name'],
        'valueSrc': json['value_src'],
        'currency': json['currency'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'buyerContactEmail': json['buyer_contact_email'],
        'buyerContactPhone': json['buyer_contact_phone'],
        'buyerContactName': json['buyer_contact_name'],
        'publishDate': json['publish_date'],
        'country': json['country'],
        'countryCode': json['country_code'],
        'stage': json['stage'],
        'url': json['url'],
        'closeDate': !exists(json, 'close_date') ? undefined : json['close_date'],
        'tenderDate': !exists(json, 'tender_date') ? undefined : json['tender_date'],
        'awardDate': !exists(json, 'award_date') ? undefined : json['award_date'],
        'expiryDate': !exists(json, 'expiry_date') ? undefined : json['expiry_date'],
        'tendersReceived': json['tenders_received'],
        'buyer': BuyerSummaryDtoFromJSON(json['buyer']),
        'buyers': ((json['buyers'] as Array<any>).map(BuyerSummaryDtoFromJSON)),
        'cpvCodes': ((json['cpv_codes'] as Array<any>).map(CpvCodesDtoFromJSON)),
        'lots': !exists(json, 'lots') ? undefined : (json['lots'] === null ? null : (json['lots'] as Array<any>).map(LotDtoFromJSON)),
        'lists': ((json['lists'] as Array<any>).map(RecordListSummaryDtoFromJSON)),
        'signals': ((json['signals'] as Array<any>).map(SignalDtoFromJSON)),
        'relevanceScore': !exists(json, 'relevance_score') ? undefined : json['relevance_score'],
        'procurementStage': ProcurementStageDtoFromJSON(json['procurement_stage']),
        'hiddenDate': json['hidden_date'],
        'commentCount': !exists(json, 'comment_count') ? undefined : json['comment_count'],
        'assignee': !exists(json, 'assignee') ? undefined : RecordDtoAssigneeDtoFromJSON(json['assignee']),
        'seenByUsers': ((json['seen_by_users'] as Array<any>).map(RecordDtoSeenByUserFromJSON)),
        'addedAt': !exists(json, 'added_at') ? undefined : (json['added_at'] === null ? null : new Date(json['added_at'])),
        'canonicalUrlPath': json['canonical_url_path'],
        'urlPath': json['url_path'],
        'type': json['type'],
        'relatedFrameworkProcess': !exists(json, 'related_framework_process') ? undefined : RecordDtoRelatedFrameworkProcessDtoFromJSON(json['related_framework_process']),
    };
}

export function RecordDtoToJSON(value?: RecordDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guid': value.guid,
        'name': value.name,
        'value_src': value.valueSrc,
        'currency': value.currency,
        'description': value.description,
        'buyer_contact_email': value.buyerContactEmail,
        'buyer_contact_phone': value.buyerContactPhone,
        'buyer_contact_name': value.buyerContactName,
        'publish_date': value.publishDate,
        'country': value.country,
        'country_code': value.countryCode,
        'stage': value.stage,
        'url': value.url,
        'close_date': value.closeDate,
        'tender_date': value.tenderDate,
        'award_date': value.awardDate,
        'expiry_date': value.expiryDate,
        'tenders_received': value.tendersReceived,
        'buyer': BuyerSummaryDtoToJSON(value.buyer),
        'buyers': ((value.buyers as Array<any>).map(BuyerSummaryDtoToJSON)),
        'cpv_codes': ((value.cpvCodes as Array<any>).map(CpvCodesDtoToJSON)),
        'lots': value.lots === undefined ? undefined : (value.lots === null ? null : (value.lots as Array<any>).map(LotDtoToJSON)),
        'lists': ((value.lists as Array<any>).map(RecordListSummaryDtoToJSON)),
        'signals': ((value.signals as Array<any>).map(SignalDtoToJSON)),
        'relevance_score': value.relevanceScore,
        'procurement_stage': ProcurementStageDtoToJSON(value.procurementStage),
        'hidden_date': value.hiddenDate,
        'comment_count': value.commentCount,
        'assignee': RecordDtoAssigneeDtoToJSON(value.assignee),
        'seen_by_users': ((value.seenByUsers as Array<any>).map(RecordDtoSeenByUserToJSON)),
        'added_at': value.addedAt === undefined ? undefined : (value.addedAt === null ? null : value.addedAt.toISOString()),
        'canonical_url_path': value.canonicalUrlPath,
        'url_path': value.urlPath,
        'type': value.type,
        'related_framework_process': RecordDtoRelatedFrameworkProcessDtoToJSON(value.relatedFrameworkProcess),
    };
}

