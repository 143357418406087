/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReportSupplier } from './ReportSupplier';
import {
    ReportSupplierFromJSON,
    ReportSupplierFromJSONTyped,
    ReportSupplierToJSON,
} from './ReportSupplier';

/**
 * 
 * @export
 * @interface SuppliersSectionGenerated
 */
export interface SuppliersSectionGenerated {
    /**
     * 
     * @type {{ [key: string]: ReportSupplier; }}
     * @memberof SuppliersSectionGenerated
     */
    suppliers: { [key: string]: ReportSupplier; };
    /**
     * 
     * @type {string}
     * @memberof SuppliersSectionGenerated
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof SuppliersSectionGenerated
     */
    description?: string;
}

/**
 * Check if a given object implements the SuppliersSectionGenerated interface.
 */
export function instanceOfSuppliersSectionGenerated(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "suppliers" in value;

    return isInstance;
}

export function SuppliersSectionGeneratedFromJSON(json: any): SuppliersSectionGenerated {
    return SuppliersSectionGeneratedFromJSONTyped(json, false);
}

export function SuppliersSectionGeneratedFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuppliersSectionGenerated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'suppliers': (mapValues(json['suppliers'], ReportSupplierFromJSON)),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function SuppliersSectionGeneratedToJSON(value?: SuppliersSectionGenerated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'suppliers': (mapValues(value.suppliers, ReportSupplierToJSON)),
        'title': value.title,
        'description': value.description,
    };
}

