import React from "react";

import { CpvSummary } from "components/cpv_summary/CpvSummary";
import SectionHeader from "components/reports/builder_components/SectionHeader";
import { CpvChartSectionGenerated } from "lib/generated/admin-api";
import { pluralise } from "lib/utils";

import css from "./CpvChart.module.scss";

type CpvChartProps = CpvChartSectionGenerated & {
  id: string;
};

const UpcomingExpiriesLookbackDates: Record<string, string> = {
  "1 year": "P1Y",
  "2 years": "P2Y",
  "3 years": "P3Y",
  "4 years": "P4Y",
  "5 years": "P5Y",
};

const HistoricalAwardsLookbackDates: Record<string, string> = {
  "1 year": "P-1Y",
  "2 years": "P-2Y",
  "3 years": "P-3Y",
  "4 years": "P-4Y",
  "5 years": "P-5Y",
};

function CpvChart({
  overallStats,
  perCpvStats,
  buyerId,
  title,
  description,
  upcomingExpiries,
  lookbackPeriod,
  id,
}: CpvChartProps): JSX.Element | null {
  if (!overallStats || !perCpvStats) {
    return null;
  }
  return (
    <div id={id}>
      <SectionHeader title={title} description={description} />
      <div className={css.dataDescription}>
        {`Showing ${
          upcomingExpiries
            ? "upcoming contract expiries in the next"
            : "historical awards from the past"
        } ${lookbackPeriod ?? "3 years"} for the ${pluralise(
          perCpvStats.length,
          "sector",
        )} displayed`}
      </div>
      <CpvSummary
        overallStats={{
          total_awarded: overallStats.totalAwarded,
          total_value: overallStats.totalValue,
          avg_contract_value: overallStats.avgContractValue,
          percent_to_smes: overallStats.percentToSmes,
        }}
        perCpvStats={perCpvStats.map((cpv) => ({
          code: cpv.cpvCode,
          name: cpv.name,
          total_awarded: cpv.totalAwarded,
          total_value: cpv.totalValue,
          avg_contract_value: cpv.avgContractValue,
          percent_to_smes: cpv.percentToSmes,
        }))}
        dateFilter={
          upcomingExpiries
            ? {
                expiryDate: {
                  filter: {
                    relativeFrom: "PT0S",
                    relativeTo: UpcomingExpiriesLookbackDates[lookbackPeriod ?? "3 years"] ?? "P3Y",
                  },
                },
              }
            : {
                publishDate: {
                  relativeFrom:
                    HistoricalAwardsLookbackDates[lookbackPeriod ?? "3 years"] ?? "P-3Y",
                  relativeTo: "PT0S",
                },
              }
        }
        buyerId={buyerId}
      />
    </div>
  );
}

export default CpvChart;
