/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplierFilters } from './SupplierFilters';
import {
    SupplierFiltersFromJSON,
    SupplierFiltersFromJSONTyped,
    SupplierFiltersToJSON,
} from './SupplierFilters';

/**
 * 
 * @export
 * @interface SupplierExportRequest
 */
export interface SupplierExportRequest {
    /**
     * 
     * @type {SupplierFilters}
     * @memberof SupplierExportRequest
     */
    filters: SupplierFilters;
    /**
     * 
     * @type {string}
     * @memberof SupplierExportRequest
     */
    format: SupplierExportRequestFormatEnum;
}


/**
 * @export
 */
export const SupplierExportRequestFormatEnum = {
    Csv: 'csv',
    Xlsx: 'xlsx'
} as const;
export type SupplierExportRequestFormatEnum = typeof SupplierExportRequestFormatEnum[keyof typeof SupplierExportRequestFormatEnum];


/**
 * Check if a given object implements the SupplierExportRequest interface.
 */
export function instanceOfSupplierExportRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filters" in value;
    isInstance = isInstance && "format" in value;

    return isInstance;
}

export function SupplierExportRequestFromJSON(json: any): SupplierExportRequest {
    return SupplierExportRequestFromJSONTyped(json, false);
}

export function SupplierExportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierExportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filters': SupplierFiltersFromJSON(json['filters']),
        'format': json['format'],
    };
}

export function SupplierExportRequestToJSON(value?: SupplierExportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filters': SupplierFiltersToJSON(value.filters),
        'format': value.format,
    };
}

