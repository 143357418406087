/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SubmittedDecision } from './SubmittedDecision';
import {
    SubmittedDecisionFromJSON,
    SubmittedDecisionFromJSONTyped,
    SubmittedDecisionToJSON,
} from './SubmittedDecision';

/**
 * 
 * @export
 * @interface SubmitOrgCleaningTaskRequest
 */
export interface SubmitOrgCleaningTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof SubmitOrgCleaningTaskRequest
     */
    organisationId?: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOrgCleaningTaskRequest
     */
    buyerGuid?: string;
    /**
     * 
     * @type {Array<SubmittedDecision>}
     * @memberof SubmitOrgCleaningTaskRequest
     */
    decisions: Array<SubmittedDecision>;
    /**
     * 
     * @type {string}
     * @memberof SubmitOrgCleaningTaskRequest
     */
    taskType: SubmitOrgCleaningTaskRequestTaskTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SubmitOrgCleaningTaskRequest
     */
    cancelLocation: string;
    /**
     * 
     * @type {string}
     * @memberof SubmitOrgCleaningTaskRequest
     */
    cancelNote: string;
}


/**
 * @export
 */
export const SubmitOrgCleaningTaskRequestTaskTypeEnum = {
    DeDupeCleaning: 'de_dupe_cleaning',
    RelationshipCleaning: 'relationship_cleaning',
    DataMigration: 'data_migration'
} as const;
export type SubmitOrgCleaningTaskRequestTaskTypeEnum = typeof SubmitOrgCleaningTaskRequestTaskTypeEnum[keyof typeof SubmitOrgCleaningTaskRequestTaskTypeEnum];


/**
 * Check if a given object implements the SubmitOrgCleaningTaskRequest interface.
 */
export function instanceOfSubmitOrgCleaningTaskRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "decisions" in value;
    isInstance = isInstance && "taskType" in value;
    isInstance = isInstance && "cancelLocation" in value;
    isInstance = isInstance && "cancelNote" in value;

    return isInstance;
}

export function SubmitOrgCleaningTaskRequestFromJSON(json: any): SubmitOrgCleaningTaskRequest {
    return SubmitOrgCleaningTaskRequestFromJSONTyped(json, false);
}

export function SubmitOrgCleaningTaskRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitOrgCleaningTaskRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organisationId': !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
        'buyerGuid': !exists(json, 'buyer_guid') ? undefined : json['buyer_guid'],
        'decisions': ((json['decisions'] as Array<any>).map(SubmittedDecisionFromJSON)),
        'taskType': json['task_type'],
        'cancelLocation': json['cancel_location'],
        'cancelNote': json['cancel_note'],
    };
}

export function SubmitOrgCleaningTaskRequestToJSON(value?: SubmitOrgCleaningTaskRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organisation_id': value.organisationId,
        'buyer_guid': value.buyerGuid,
        'decisions': ((value.decisions as Array<any>).map(SubmittedDecisionToJSON)),
        'task_type': value.taskType,
        'cancel_location': value.cancelLocation,
        'cancel_note': value.cancelNote,
    };
}

