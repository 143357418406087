import { ThemeConfig } from "antd5";

import * as colors from "./colors";

export const TourComponent: ThemeConfig = {
  components: {
    Modal: { contentBg: colors.blue900, headerBg: colors.blue900, titleFontSize: 16 },
    Button: {
      colorBorder: colors.blue900,
      colorBgContainer: colors.white,
    },
  },
  token: { colorText: colors.white },
};
