import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { TeamsQuery } from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";

const teams = graphql(`
  query teams {
    teams {
      id
      name
      isPrivate
      users {
        guid
        firstName
        lastName
      }
      feedSettingsId
    }
  }
`);

export function useTeams(options?: UseQueryOptions<TeamsQuery, unknown, TeamsQuery, QueryKey>) {
  const { data, ...rest } = useGraphQlQuery(["teams"], teams, [], options);

  return { data: data?.teams, ...rest };
}
