import React from "react";
import { ConfigProvider, Modal } from "antd5";

import { useFindIntegrationProvider } from "lib/hooks/api/integrations/useFindIntegrationProvider";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { PaywallContent } from "./PaywallContent";
import { FeatureType, PaywallImg, PaywallVideo } from "./paywallUtils";

import css from "./PaywallModal.module.scss";

export type PaywallModalProps = {
  featureType: FeatureType;
  contextSource?: string;
  isOpen: boolean;
  isAddOn?: boolean;
  onClose: () => void;
  trackingData?: EventData;
  video?: boolean;
};
function PaywallModal({
  featureType,
  isOpen,
  isAddOn,
  contextSource,
  onClose,
  trackingData,
  video = false,
}: PaywallModalProps) {
  const { logEvent } = useTracking();
  const { data: providerType } = useFindIntegrationProvider();

  const header = video ? (
    <PaywallVideo featureType={featureType} />
  ) : (
    <PaywallImg context={"MODAL"} featureType={featureType} className={css.modalImgHeight} />
  );

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            paddingMD: 0,
            paddingContentHorizontalLG: 0,
            borderRadius: 8,
          },
        },
      }}
    >
      <Modal
        open={isOpen}
        width={video ? 900 : undefined}
        // Tracking when the modal is opened or dismissed
        afterOpenChange={(modalOpen) =>
          logEvent(modalOpen ? EventNames.paywallModalOpened : EventNames.paywallModalDismissed, {
            "Context source": contextSource,
            "Data type": featureType,
            "Connected provider": providerType,
            ...trackingData,
          })
        }
        centered
        onCancel={onClose}
        footer={null}
        style={{ padding: 0 }}
        zIndex={9999}
        title={header}
        className={css.modal}
      >
        <PaywallContent featureType={featureType} isAddOn={isAddOn} trackingData={trackingData} />
      </Modal>
    </ConfigProvider>
  );
}

export default PaywallModal;
