import * as React from "react";
import classnames from "classnames";

import { TextLink } from "components/actions/Links";
import SectionHeader from "components/reports/builder_components/SectionHeader";
import { LinkMetadata } from "lib/generated/admin-api/models";
import { EventNames } from "lib/tracking";

import css from "./LinkList.module.scss";

import DEFAULT_IMAGE from "../../../../../assets/images/img_placeholder.svg";

type Props = {
  linksMetadata: LinkMetadata[];
  title?: string;
  description?: string;
  id: string;
};

const getHostnameFromRegex = (url: string) => {
  // run against regex, not using new URL because it requires a protocol
  // the link scraper doesnt, so the rendering of the data isn't where this should break
  const matches = url.match(/^(?:https?:)?(?:\/\/)?([^/?]+)/i);
  // extract hostname (will be null if no match is found)
  return matches && matches[1]?.replace("www.", "");
};

function LinkList({ linksMetadata, title, description, id }: Props): JSX.Element {
  return (
    <div className={css.linkListContainer} id={id}>
      <SectionHeader title={title} description={description} />
      <ul>
        {linksMetadata.map((l, i) => (
          <li key={i}>
            <div
              className={classnames(css.imageContainer, { [css.placeholderContainer]: !l.srcUrl })}
            >
              {l.srcUrl ? (
                <img
                  className={css.articleImg}
                  src={l.srcUrl}
                  alt={`article thumbnail image for ${l.title}`}
                />
              ) : (
                <img className={css.placeholderImg} src={DEFAULT_IMAGE} alt="file-icon" />
              )}
            </div>
            <div className={css.textContainer}>
              <h3 className={css.title}>
                <TextLink
                  to={l.url}
                  targetType="external"
                  showIcon={false}
                  eventName={EventNames.reportLinkedListItemClicked}
                  eventAttributes={{ link: l.url }}
                >
                  {l.title}
                </TextLink>
              </h3>
              {l.description && <p className={css.description}>{l.description}</p>}
              {l.caption && <p className={css.caption}>{l.caption}</p>}
              <small>{l.url && getHostnameFromRegex(l.url)}</small>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default LinkList;
