/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QualifyProcurementStage
 */
export interface QualifyProcurementStage {
    /**
     * 
     * @type {string}
     * @memberof QualifyProcurementStage
     */
    actorName: string;
    /**
     * 
     * @type {string}
     * @memberof QualifyProcurementStage
     */
    recordTitle: string;
    /**
     * 
     * @type {string}
     * @memberof QualifyProcurementStage
     */
    toStatusLabel: string;
}

/**
 * Check if a given object implements the QualifyProcurementStage interface.
 */
export function instanceOfQualifyProcurementStage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "actorName" in value;
    isInstance = isInstance && "recordTitle" in value;
    isInstance = isInstance && "toStatusLabel" in value;

    return isInstance;
}

export function QualifyProcurementStageFromJSON(json: any): QualifyProcurementStage {
    return QualifyProcurementStageFromJSONTyped(json, false);
}

export function QualifyProcurementStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): QualifyProcurementStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actorName': json['actor_name'],
        'recordTitle': json['record_title'],
        'toStatusLabel': json['to_status_label'],
    };
}

export function QualifyProcurementStageToJSON(value?: QualifyProcurementStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor_name': value.actorName,
        'record_title': value.recordTitle,
        'to_status_label': value.toStatusLabel,
    };
}

