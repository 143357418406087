import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { NoticeDetailsResponse } from "lib/generated/integration-api";
import { useIntegrationApi } from "lib/integrationApiContext";

type QueryKey = [string, string];

export function useNoticeDetails(
  recordGuid: string,
  options?: UseQueryOptions<NoticeDetailsResponse, unknown, NoticeDetailsResponse, QueryKey>,
) {
  const api = useIntegrationApi();
  return useQuery(
    ["noticeDetails", recordGuid],
    async () => {
      const response = await api.noticeDetails({ noticeDetailsRequest: { recordId: recordGuid } });
      return response;
    },
    options,
  );
}
