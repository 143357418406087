/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchEntitiesResponseResultEntityResultEntity } from './SearchEntitiesResponseResultEntityResultEntity';
import {
    SearchEntitiesResponseResultEntityResultEntityFromJSON,
    SearchEntitiesResponseResultEntityResultEntityFromJSONTyped,
    SearchEntitiesResponseResultEntityResultEntityToJSON,
} from './SearchEntitiesResponseResultEntityResultEntity';
import type { SearchEntitiesResponseResultEntityResultMetadata } from './SearchEntitiesResponseResultEntityResultMetadata';
import {
    SearchEntitiesResponseResultEntityResultMetadataFromJSON,
    SearchEntitiesResponseResultEntityResultMetadataFromJSONTyped,
    SearchEntitiesResponseResultEntityResultMetadataToJSON,
} from './SearchEntitiesResponseResultEntityResultMetadata';

/**
 * 
 * @export
 * @interface SearchEntitiesResponseResultEntityResult
 */
export interface SearchEntitiesResponseResultEntityResult {
    /**
     * 
     * @type {SearchEntitiesResponseResultEntityResultEntity}
     * @memberof SearchEntitiesResponseResultEntityResult
     */
    entity: SearchEntitiesResponseResultEntityResultEntity;
    /**
     * 
     * @type {SearchEntitiesResponseResultEntityResultMetadata}
     * @memberof SearchEntitiesResponseResultEntityResult
     */
    metadata: SearchEntitiesResponseResultEntityResultMetadata;
}

/**
 * Check if a given object implements the SearchEntitiesResponseResultEntityResult interface.
 */
export function instanceOfSearchEntitiesResponseResultEntityResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entity" in value;
    isInstance = isInstance && "metadata" in value;

    return isInstance;
}

export function SearchEntitiesResponseResultEntityResultFromJSON(json: any): SearchEntitiesResponseResultEntityResult {
    return SearchEntitiesResponseResultEntityResultFromJSONTyped(json, false);
}

export function SearchEntitiesResponseResultEntityResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchEntitiesResponseResultEntityResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entity': SearchEntitiesResponseResultEntityResultEntityFromJSON(json['entity']),
        'metadata': SearchEntitiesResponseResultEntityResultMetadataFromJSON(json['metadata']),
    };
}

export function SearchEntitiesResponseResultEntityResultToJSON(value?: SearchEntitiesResponseResultEntityResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entity': SearchEntitiesResponseResultEntityResultEntityToJSON(value.entity),
        'metadata': SearchEntitiesResponseResultEntityResultMetadataToJSON(value.metadata),
    };
}

