/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto } from './RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto';
import {
    RecordSearchRequestDtoNullableDateFilterDtoDateFilterDtoFromJSON,
    RecordSearchRequestDtoNullableDateFilterDtoDateFilterDtoFromJSONTyped,
    RecordSearchRequestDtoNullableDateFilterDtoDateFilterDtoToJSON,
} from './RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto';

/**
 * 
 * @export
 * @interface RecordSearchRequestDtoNullableDateFilterDto
 */
export interface RecordSearchRequestDtoNullableDateFilterDto {
    /**
     * 
     * @type {RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto}
     * @memberof RecordSearchRequestDtoNullableDateFilterDto
     */
    filter?: RecordSearchRequestDtoNullableDateFilterDtoDateFilterDto;
    /**
     * 
     * @type {boolean}
     * @memberof RecordSearchRequestDtoNullableDateFilterDto
     */
    includeNulls?: boolean | null;
}

/**
 * Check if a given object implements the RecordSearchRequestDtoNullableDateFilterDto interface.
 */
export function instanceOfRecordSearchRequestDtoNullableDateFilterDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RecordSearchRequestDtoNullableDateFilterDtoFromJSON(json: any): RecordSearchRequestDtoNullableDateFilterDto {
    return RecordSearchRequestDtoNullableDateFilterDtoFromJSONTyped(json, false);
}

export function RecordSearchRequestDtoNullableDateFilterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSearchRequestDtoNullableDateFilterDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filter': !exists(json, 'filter') ? undefined : RecordSearchRequestDtoNullableDateFilterDtoDateFilterDtoFromJSON(json['filter']),
        'includeNulls': !exists(json, 'include_nulls') ? undefined : json['include_nulls'],
    };
}

export function RecordSearchRequestDtoNullableDateFilterDtoToJSON(value?: RecordSearchRequestDtoNullableDateFilterDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filter': RecordSearchRequestDtoNullableDateFilterDtoDateFilterDtoToJSON(value.filter),
        'include_nulls': value.includeNulls,
    };
}

