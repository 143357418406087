import { DateTime, Interval } from "luxon";

export enum TimePeriod {
  TODAY = "Today",
  YESTERDAY = "Yesterday",
  PAST_WEEK = "Past week",
  PAST_MONTH = "Past month",
}

const TODAY_INTERVAL = Interval.fromDateTimes(
  DateTime.now().startOf("day"),
  DateTime.now().endOf("day"),
);

const YESTERDAY_INTERVAL = Interval.fromDateTimes(
  DateTime.now().minus({ days: 1 }).startOf("day"),
  DateTime.now().minus({ days: 1 }).endOf("day"),
);

const A_WEEK_AGO_INTERVAL = Interval.fromDateTimes(
  DateTime.now().minus({ week: 1 }).startOf("day"),
  DateTime.now().endOf("day"),
);

const A_MONTH_AGO_INTERVAL = Interval.fromDateTimes(
  DateTime.now().minus({ month: 1 }).startOf("day"),
  DateTime.now().endOf("day"),
);

export const getTimePeriod = (date: string): TimePeriod | null => {
  const evaluatedDate = DateTime.fromISO(date);
  if (TODAY_INTERVAL.contains(evaluatedDate)) {
    return TimePeriod.TODAY;
  }
  if (YESTERDAY_INTERVAL.contains(evaluatedDate)) {
    return TimePeriod.YESTERDAY;
  }
  if (A_WEEK_AGO_INTERVAL.contains(evaluatedDate)) {
    return TimePeriod.PAST_WEEK;
  }
  if (A_MONTH_AGO_INTERVAL.contains(evaluatedDate)) {
    return TimePeriod.PAST_MONTH;
  }
  return null;
};
