import React from "react";
// eslint-disable-next-line no-restricted-imports
import { Skeleton, Table } from "antd5";
import { ColumnType } from "antd5/es/table";
import { SkeletonProps } from "antd5/lib/skeleton";

// Usage:
// <SkeletonTable loading={loading} columns={columns} active={active} rowCount={rowCount}... >
//   <Table columns={columns} ... /> regular table
// </SkeletonTable>
//
// OR
// <SkeletonTable columns={columns} active={active} rowCount={rowCount}... />
//

type SkeletonTableColumnsType = {
  key: string;
};

type SkeletonTableProps = SkeletonProps & {
  columns: Omit<
    ColumnType<SkeletonTableColumnsType>,
    | "render"
    | "onFilter"
    | "sorter"
    | "children"
    | "onCellClick"
    | "onCell"
    | "onHeaderCell"
    | "shouldCellUpdate"
  >[];
  pageSize: number;
  rowCount?: number;
};

export default function SkeletonTable({
  loading = false,
  active = false,
  rowCount,
  pageSize,
  columns,
  children,
  className,
}: SkeletonTableProps): JSX.Element {
  const rows = rowCount ? Math.min(rowCount, pageSize) : pageSize;
  return loading || !children ? (
    <Table
      rowKey="key"
      pagination={false}
      dataSource={[...Array(rows)].map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={columns.map((column) => {
        return {
          ...column,
          render: function renderPlaceholder() {
            return (
              <Skeleton
                key={column.key}
                title
                active={active}
                paragraph={false}
                className={className}
              />
            );
          },
        };
      })}
    />
  ) : (
    <>{children}</>
  );
}
