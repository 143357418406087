/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanyDto } from './CompanyDto';
import {
    CompanyDtoFromJSON,
    CompanyDtoFromJSONTyped,
    CompanyDtoToJSON,
} from './CompanyDto';

/**
 * 
 * @export
 * @interface SearchCompaniesResponse
 */
export interface SearchCompaniesResponse {
    /**
     * 
     * @type {Array<CompanyDto>}
     * @memberof SearchCompaniesResponse
     */
    companies: Array<CompanyDto>;
}

/**
 * Check if a given object implements the SearchCompaniesResponse interface.
 */
export function instanceOfSearchCompaniesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companies" in value;

    return isInstance;
}

export function SearchCompaniesResponseFromJSON(json: any): SearchCompaniesResponse {
    return SearchCompaniesResponseFromJSONTyped(json, false);
}

export function SearchCompaniesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchCompaniesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companies': ((json['companies'] as Array<any>).map(CompanyDtoFromJSON)),
    };
}

export function SearchCompaniesResponseToJSON(value?: SearchCompaniesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companies': ((value.companies as Array<any>).map(CompanyDtoToJSON)),
    };
}

