/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ColumnSetting } from './ColumnSetting';
import {
    ColumnSettingFromJSON,
    ColumnSettingFromJSONTyped,
    ColumnSettingToJSON,
} from './ColumnSetting';

/**
 * 
 * @export
 * @interface ViewConfigurationTableSettings
 */
export interface ViewConfigurationTableSettings {
    /**
     * 
     * @type {Array<ColumnSetting>}
     * @memberof ViewConfigurationTableSettings
     */
    columns: Array<ColumnSetting>;
}

/**
 * Check if a given object implements the ViewConfigurationTableSettings interface.
 */
export function instanceOfViewConfigurationTableSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "columns" in value;

    return isInstance;
}

export function ViewConfigurationTableSettingsFromJSON(json: any): ViewConfigurationTableSettings {
    return ViewConfigurationTableSettingsFromJSONTyped(json, false);
}

export function ViewConfigurationTableSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewConfigurationTableSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'columns': ((json['columns'] as Array<any>).map(ColumnSettingFromJSON)),
    };
}

export function ViewConfigurationTableSettingsToJSON(value?: ViewConfigurationTableSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'columns': ((value.columns as Array<any>).map(ColumnSettingToJSON)),
    };
}

