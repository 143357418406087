import { EventNames, useTracking } from "lib/tracking";

export type OutreachTrackingEvent =
  | "generate"
  | "regenerate"
  | "skip"
  | "copyEmail"
  | "copySubject"
  | "copyBody"
  | "sendEmail"
  | "visitEmailSetupHelp";

export function useOutreachTracking() {
  const { logEvent } = useTracking();

  /**
   * @param event OutreachTrackingEvent the event type
   * @param data {} Should not contain Page source or Context source, because these will be taken
   * from the tracking context
   */
  function track(event: OutreachTrackingEvent, data: Record<string, unknown> = {}) {
    switch (event) {
      case "generate":
        return logEvent(EventNames.generateOutreachClicked, data);
      case "regenerate":
        return logEvent(EventNames.refreshOutreachClicked, data);
      case "skip":
        return logEvent(EventNames.skipGenerateOutreachClicked, {
          Reason: "No messages generated", //TODO: modal needs to be updated to include skip after gernerate
          ...data,
        });
      case "copyEmail":
        return logEvent(EventNames.emailCopied, {
          ...data,
        });
      case "copySubject":
        return logEvent(EventNames.outreachCopy, {
          Selection: "Subject line",
          ...data,
        });
      case "copyBody":
        return logEvent(EventNames.outreachCopy, {
          Selection: "Message content",
          ...data,
        });
      case "sendEmail":
        return logEvent(EventNames.sendOutreach, data);
      case "visitEmailSetupHelp":
        return logEvent(EventNames.clickedExternalLink, data);
      default:
        break;
    }
  }

  return { track };
}
