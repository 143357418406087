/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecordTimelineObj } from './RecordTimelineObj';
import {
    RecordTimelineObjFromJSON,
    RecordTimelineObjFromJSONTyped,
    RecordTimelineObjToJSON,
} from './RecordTimelineObj';

/**
 * 
 * @export
 * @interface RecordTimelineDto
 */
export interface RecordTimelineDto {
    /**
     * 
     * @type {Array<RecordTimelineObj>}
     * @memberof RecordTimelineDto
     */
    timelineStages: Array<RecordTimelineObj>;
    /**
     * 
     * @type {boolean}
     * @memberof RecordTimelineDto
     */
    extensionAvailable: boolean | null;
}

/**
 * Check if a given object implements the RecordTimelineDto interface.
 */
export function instanceOfRecordTimelineDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "timelineStages" in value;
    isInstance = isInstance && "extensionAvailable" in value;

    return isInstance;
}

export function RecordTimelineDtoFromJSON(json: any): RecordTimelineDto {
    return RecordTimelineDtoFromJSONTyped(json, false);
}

export function RecordTimelineDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordTimelineDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timelineStages': ((json['timeline_stages'] as Array<any>).map(RecordTimelineObjFromJSON)),
        'extensionAvailable': json['extension_available'],
    };
}

export function RecordTimelineDtoToJSON(value?: RecordTimelineDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timeline_stages': ((value.timelineStages as Array<any>).map(RecordTimelineObjToJSON)),
        'extension_available': value.extensionAvailable,
    };
}

