/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupplierSignalStatsResponse
 */
export interface SupplierSignalStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof SupplierSignalStatsResponse
     */
    allRecordsCount: number;
    /**
     * 
     * @type {number}
     * @memberof SupplierSignalStatsResponse
     */
    keywordRecordsCount: number;
    /**
     * 
     * @type {number}
     * @memberof SupplierSignalStatsResponse
     */
    keyAccountsCount: number;
    /**
     * 
     * @type {number}
     * @memberof SupplierSignalStatsResponse
     */
    expiriesCount: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierSignalStatsResponse
     */
    latestActivity: string | null;
}

/**
 * Check if a given object implements the SupplierSignalStatsResponse interface.
 */
export function instanceOfSupplierSignalStatsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "allRecordsCount" in value;
    isInstance = isInstance && "keywordRecordsCount" in value;
    isInstance = isInstance && "keyAccountsCount" in value;
    isInstance = isInstance && "expiriesCount" in value;
    isInstance = isInstance && "latestActivity" in value;

    return isInstance;
}

export function SupplierSignalStatsResponseFromJSON(json: any): SupplierSignalStatsResponse {
    return SupplierSignalStatsResponseFromJSONTyped(json, false);
}

export function SupplierSignalStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierSignalStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allRecordsCount': json['all_records_count'],
        'keywordRecordsCount': json['keyword_records_count'],
        'keyAccountsCount': json['key_accounts_count'],
        'expiriesCount': json['expiries_count'],
        'latestActivity': json['latest_activity'],
    };
}

export function SupplierSignalStatsResponseToJSON(value?: SupplierSignalStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'all_records_count': value.allRecordsCount,
        'keyword_records_count': value.keywordRecordsCount,
        'key_accounts_count': value.keyAccountsCount,
        'expiries_count': value.expiriesCount,
        'latest_activity': value.latestActivity,
    };
}

