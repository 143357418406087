import React from "react";

import css from "./TableBanner.module.scss";

type TableBannerProps = {
  title: string;
  description: string;
  graphic?: JSX.Element;
  ctaElement?: JSX.Element;
};

export default function TableBanner({
  title,
  description,
  graphic,
  ctaElement,
}: TableBannerProps): JSX.Element {
  return (
    <div className={css.banner}>
      <div className={css.contentWrapper}>
        {graphic && graphic}
        <div className={css.content}>
          <h4>{title}</h4>
          <span className={css.description}>{description}</span>
        </div>
      </div>
      {ctaElement && ctaElement}
    </div>
  );
}
