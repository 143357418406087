/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyersRelationshipStatsResponseSignalStats } from './BuyersRelationshipStatsResponseSignalStats';
import {
    BuyersRelationshipStatsResponseSignalStatsFromJSON,
    BuyersRelationshipStatsResponseSignalStatsFromJSONTyped,
    BuyersRelationshipStatsResponseSignalStatsToJSON,
} from './BuyersRelationshipStatsResponseSignalStats';

/**
 * 
 * @export
 * @interface BuyersRelationshipStatsResponse
 */
export interface BuyersRelationshipStatsResponse {
    /**
     * 
     * @type {Array<BuyersRelationshipStatsResponseSignalStats>}
     * @memberof BuyersRelationshipStatsResponse
     */
    signalStats: Array<BuyersRelationshipStatsResponseSignalStats>;
    /**
     * 
     * @type {number}
     * @memberof BuyersRelationshipStatsResponse
     */
    totalCount?: number;
}

/**
 * Check if a given object implements the BuyersRelationshipStatsResponse interface.
 */
export function instanceOfBuyersRelationshipStatsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "signalStats" in value;

    return isInstance;
}

export function BuyersRelationshipStatsResponseFromJSON(json: any): BuyersRelationshipStatsResponse {
    return BuyersRelationshipStatsResponseFromJSONTyped(json, false);
}

export function BuyersRelationshipStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyersRelationshipStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signalStats': ((json['signal_stats'] as Array<any>).map(BuyersRelationshipStatsResponseSignalStatsFromJSON)),
        'totalCount': !exists(json, 'total_count') ? undefined : json['total_count'],
    };
}

export function BuyersRelationshipStatsResponseToJSON(value?: BuyersRelationshipStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signal_stats': ((value.signalStats as Array<any>).map(BuyersRelationshipStatsResponseSignalStatsToJSON)),
        'total_count': value.totalCount,
    };
}

