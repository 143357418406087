import * as React from "react";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";

import {
  FeedbackRequest,
  ProductTourCompletionStateResponseTourStateTourIdentifierEnum,
} from "lib/generated/app-api";
import { MarkAsCompleteRequest } from "lib/generated/app-api/models/MarkAsCompleteRequest";
import { ProductTourCompletionStateResponse } from "lib/generated/app-api/models/ProductTourCompletionStateResponse";
import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "./api/utils";

type QueryKey = ["tour_states", string];

type TourCompletionDateByIdentifier = Partial<
  Record<ProductTourCompletionStateResponseTourStateTourIdentifierEnum, Date | null>
>;

/**
 * Utility function to covert response object into a type more easily-usable in application code
 */
function responseDtoToTourCompletionStates(
  resp: ProductTourCompletionStateResponse,
): TourCompletionDateByIdentifier {
  const dateByIdentifier: TourCompletionDateByIdentifier = {};

  for (const item of resp.tours) {
    dateByIdentifier[item.tourIdentifier] = item.completedAt;
  }

  return dateByIdentifier;
}

/**
 * Lower level function for fetching the tour states. Shouldn't have to be exported or used outside
 * this file
 */
function useTourStates(
  userGuid: string,
  options?: UseQueryOptions<
    TourCompletionDateByIdentifier,
    unknown,
    TourCompletionDateByIdentifier,
    QueryKey
  >,
) {
  const api = useOpenApi();
  return useQuery(
    ["tour_states", userGuid],
    async () => {
      const resp = await api.getProductTourStates({
        indexRequest: {
          userId: userGuid,
        },
      });

      return responseDtoToTourCompletionStates(resp);
    },
    { ...REACT_QUERY_OPTIONS_NEVER_REFETCH, ...options },
  );
}

/**
 * Gives user feedback for a given user on a particular product/feature
 */
export function useProductFeedback(
  options?: UseMutationOptions<void, unknown, FeedbackRequest, string>,
) {
  const api = useOpenApi();
  return useMutation(
    async (req) =>
      await api.productFeedback({
        feedbackRequest: req,
      }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
    },
  );
}

/**
 * Lower level function for updating a tour state. Shouldn't have to be exported or used outside
 * this file
 */
function useMarkTourAsComplete(
  options?: UseMutationOptions<
    ProductTourCompletionStateResponse,
    unknown,
    MarkAsCompleteRequest,
    string
  >,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (req) =>
      await api.markProductTourAsComplete({
        markAsCompleteRequest: req,
      }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void queryClient.setQueryData<TourCompletionDateByIdentifier>(
          ["tour_states", variables.userId],
          responseDtoToTourCompletionStates(data),
        );

        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
    },
  );
}

type Options = {
  /**
   * How long to wait before it's ok to show the component (when it first mounts), in milliseconds.
   * Useful when the tour is being shown in a component which is dependent on animations, ie.
   * Drawer or Modal
   */
  delayMsOnFirstMount?: number;

  disableTour?: boolean;
};

/**
 * A hook for handling product tours; pass in the tour you want to manage, and get back boolean of
 * whether the tour should be visible, and 2 ways of dismissing it (temporarily ie. shows again on
 * page refresh; or permanently). No extra state management required.
 *
 * @throws Error if there is no window.currentUser - we do not track product tours for anonymous
 *   users (though we have few public-facing pages in app these days)
 */
export function useProductTour(
  identifier: ProductTourCompletionStateResponseTourStateTourIdentifierEnum,
  options?: Options,
) {
  // It's best not to assertCurrentUser here, because of usage on anonymous sessions like guest passes
  const user = window.currentUser;
  const { logEvent } = useTracking();
  const [tourOpen, setTourOpen] = React.useState(false);
  const [timeoutCompleted, setTimeoutCompleted] = React.useState(false);

  const { data: tourStates } = useTourStates(user?.guid ?? "", {
    enabled: !!user,
  });

  React.useEffect(() => {
    if (options?.delayMsOnFirstMount) {
      const timeout = setTimeout(() => setTimeoutCompleted(true), options.delayMsOnFirstMount);
      return () => clearTimeout(timeout);
    } else {
      setTimeoutCompleted(true);
    }
  }, [options?.delayMsOnFirstMount]);

  React.useEffect(() => {
    if (tourStates && !tourStates[identifier]) {
      setTourOpen(true);
    } else if (tourStates && tourStates[identifier]) {
      setTourOpen(false);
    }

    if (options?.disableTour || !user) {
      setTourOpen(false);
    }
  }, [tourStates, identifier, options?.disableTour, user]);

  const { mutate: markTourComplete } = useMarkTourAsComplete();

  const permanentlyHide = React.useCallback(() => {
    if (user) {
      markTourComplete({
        userId: user.guid,
        tourIdentifier: identifier,
        completedAt: new Date(),
      });
      logEvent(EventNames.productTourCompleted, {
        "Tour identifier": identifier,
      });
    }
  }, [identifier, markTourComplete, user, logEvent]);

  const temporarilyHide = React.useCallback(() => {
    setTourOpen(false);
    logEvent(EventNames.productTourHidden, {
      "Tour identifier": identifier,
    });
  }, [logEvent, identifier]);

  return { tourOpen: tourOpen && timeoutCompleted, permanentlyHide, temporarilyHide };
}
