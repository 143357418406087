/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminBuyerListDetails } from './AdminBuyerListDetails';
import {
    AdminBuyerListDetailsFromJSON,
    AdminBuyerListDetailsFromJSONTyped,
    AdminBuyerListDetailsToJSON,
} from './AdminBuyerListDetails';

/**
 * 
 * @export
 * @interface AdminBuyerListsResponse
 */
export interface AdminBuyerListsResponse {
    /**
     * 
     * @type {Array<AdminBuyerListDetails>}
     * @memberof AdminBuyerListsResponse
     */
    buyerLists: Array<AdminBuyerListDetails>;
}

/**
 * Check if a given object implements the AdminBuyerListsResponse interface.
 */
export function instanceOfAdminBuyerListsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "buyerLists" in value;

    return isInstance;
}

export function AdminBuyerListsResponseFromJSON(json: any): AdminBuyerListsResponse {
    return AdminBuyerListsResponseFromJSONTyped(json, false);
}

export function AdminBuyerListsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminBuyerListsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buyerLists': ((json['buyer_lists'] as Array<any>).map(AdminBuyerListDetailsFromJSON)),
    };
}

export function AdminBuyerListsResponseToJSON(value?: AdminBuyerListsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buyer_lists': ((value.buyerLists as Array<any>).map(AdminBuyerListDetailsToJSON)),
    };
}

