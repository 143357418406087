/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RuleResultsResponseNoticesResultsInnerMatchedElementsInner } from './RuleResultsResponseNoticesResultsInnerMatchedElementsInner';
import {
    RuleResultsResponseNoticesResultsInnerMatchedElementsInnerFromJSON,
    RuleResultsResponseNoticesResultsInnerMatchedElementsInnerFromJSONTyped,
    RuleResultsResponseNoticesResultsInnerMatchedElementsInnerToJSON,
} from './RuleResultsResponseNoticesResultsInnerMatchedElementsInner';

/**
 * 
 * @export
 * @interface RuleResultsResponseNoticesResultsInner
 */
export interface RuleResultsResponseNoticesResultsInner {
    /**
     * 
     * @type {string}
     * @memberof RuleResultsResponseNoticesResultsInner
     */
    procurementProcessId: string;
    /**
     * 
     * @type {Array<RuleResultsResponseNoticesResultsInnerMatchedElementsInner>}
     * @memberof RuleResultsResponseNoticesResultsInner
     */
    matchedElements: Array<RuleResultsResponseNoticesResultsInnerMatchedElementsInner>;
}

/**
 * Check if a given object implements the RuleResultsResponseNoticesResultsInner interface.
 */
export function instanceOfRuleResultsResponseNoticesResultsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "procurementProcessId" in value;
    isInstance = isInstance && "matchedElements" in value;

    return isInstance;
}

export function RuleResultsResponseNoticesResultsInnerFromJSON(json: any): RuleResultsResponseNoticesResultsInner {
    return RuleResultsResponseNoticesResultsInnerFromJSONTyped(json, false);
}

export function RuleResultsResponseNoticesResultsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleResultsResponseNoticesResultsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'procurementProcessId': json['procurementProcessId'],
        'matchedElements': ((json['matchedElements'] as Array<any>).map(RuleResultsResponseNoticesResultsInnerMatchedElementsInnerFromJSON)),
    };
}

export function RuleResultsResponseNoticesResultsInnerToJSON(value?: RuleResultsResponseNoticesResultsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'procurementProcessId': value.procurementProcessId,
        'matchedElements': ((value.matchedElements as Array<any>).map(RuleResultsResponseNoticesResultsInnerMatchedElementsInnerToJSON)),
    };
}

