import * as React from "react";

export default function Holder(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 1024 1024"
    >
      <path
        fill="currentColor"
        d="M300 276.497a56 56 0 1 0 56-96.994a56 56 0 0 0-56 96.994Zm0 284a56 56 0 1 0 56-96.994a56 56 0 0 0-56 96.994ZM640 228a56 56 0 1 0 112 0a56 56 0 0 0-112 0Zm0 284a56 56 0 1 0 112 0a56 56 0 0 0-112 0ZM300 844.497a56 56 0 1 0 56-96.994a56 56 0 0 0-56 96.994ZM640 796a56 56 0 1 0 112 0a56 56 0 0 0-112 0Z"
      />
    </svg>
  );
}
