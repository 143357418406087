/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecordSearchConfigCompanyRecordFilterDateFilter } from './RecordSearchConfigCompanyRecordFilterDateFilter';
import {
    RecordSearchConfigCompanyRecordFilterDateFilterFromJSON,
    RecordSearchConfigCompanyRecordFilterDateFilterFromJSONTyped,
    RecordSearchConfigCompanyRecordFilterDateFilterToJSON,
} from './RecordSearchConfigCompanyRecordFilterDateFilter';
import type { RecordSearchConfigCompanyRecordFilterSignals } from './RecordSearchConfigCompanyRecordFilterSignals';
import {
    RecordSearchConfigCompanyRecordFilterSignalsFromJSON,
    RecordSearchConfigCompanyRecordFilterSignalsFromJSONTyped,
    RecordSearchConfigCompanyRecordFilterSignalsToJSON,
} from './RecordSearchConfigCompanyRecordFilterSignals';
import type { RecordSearchConfigCompanyRecordFilterSignalsFilter } from './RecordSearchConfigCompanyRecordFilterSignalsFilter';
import {
    RecordSearchConfigCompanyRecordFilterSignalsFilterFromJSON,
    RecordSearchConfigCompanyRecordFilterSignalsFilterFromJSONTyped,
    RecordSearchConfigCompanyRecordFilterSignalsFilterToJSON,
} from './RecordSearchConfigCompanyRecordFilterSignalsFilter';

/**
 * 
 * @export
 * @interface RecordSearchConfigCompanyRecordFilter
 */
export interface RecordSearchConfigCompanyRecordFilter {
    /**
     * 
     * @type {string}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    companyGuid: string;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    teamId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    feedSettingsId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    userGuid?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    recordListIds?: Array<number> | null;
    /**
     * 
     * @type {RecordSearchConfigCompanyRecordFilterDateFilter}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    recordListAddedDate?: RecordSearchConfigCompanyRecordFilterDateFilter;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    assignee?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    signalCategory?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    showUserHiddenLeads?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    signalTextQuery?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    ignoredSignalCategories?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    procurementStageQualifications?: Array<RecordSearchConfigCompanyRecordFilterProcurementStageQualificationsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    relevanceScore?: Array<RecordSearchConfigCompanyRecordFilterRelevanceScoreEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    includedQualificationStates?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    excludedQualificationStates?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    buyerListIds?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    excludeUnqualified?: boolean;
    /**
     * 
     * @type {RecordSearchConfigCompanyRecordFilterSignalsFilter}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    signalsFilter?: RecordSearchConfigCompanyRecordFilterSignalsFilter;
    /**
     * 
     * @type {RecordSearchConfigCompanyRecordFilterSignals}
     * @memberof RecordSearchConfigCompanyRecordFilter
     */
    signals?: RecordSearchConfigCompanyRecordFilterSignals;
}


/**
 * @export
 */
export const RecordSearchConfigCompanyRecordFilterProcurementStageQualificationsEnum = {
    Unqualified: 'unqualified',
    NotRelevant: 'not_relevant',
    InReview: 'in_review',
    BidPrep: 'bid_prep',
    BidSubmitted: 'bid_submitted',
    NoBid: 'no_bid',
    Won: 'won',
    Lost: 'lost',
    PreEngageToDo: 'pre_engage_to_do',
    PreEngageDone: 'pre_engage_done',
    PreEngageNotRelevant: 'pre_engage_not_relevant'
} as const;
export type RecordSearchConfigCompanyRecordFilterProcurementStageQualificationsEnum = typeof RecordSearchConfigCompanyRecordFilterProcurementStageQualificationsEnum[keyof typeof RecordSearchConfigCompanyRecordFilterProcurementStageQualificationsEnum];

/**
 * @export
 */
export const RecordSearchConfigCompanyRecordFilterRelevanceScoreEnum = {
    None: 'None',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High'
} as const;
export type RecordSearchConfigCompanyRecordFilterRelevanceScoreEnum = typeof RecordSearchConfigCompanyRecordFilterRelevanceScoreEnum[keyof typeof RecordSearchConfigCompanyRecordFilterRelevanceScoreEnum];


/**
 * Check if a given object implements the RecordSearchConfigCompanyRecordFilter interface.
 */
export function instanceOfRecordSearchConfigCompanyRecordFilter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyGuid" in value;

    return isInstance;
}

export function RecordSearchConfigCompanyRecordFilterFromJSON(json: any): RecordSearchConfigCompanyRecordFilter {
    return RecordSearchConfigCompanyRecordFilterFromJSONTyped(json, false);
}

export function RecordSearchConfigCompanyRecordFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSearchConfigCompanyRecordFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyGuid': json['company_guid'],
        'teamId': !exists(json, 'team_id') ? undefined : json['team_id'],
        'feedSettingsId': !exists(json, 'feed_settings_id') ? undefined : json['feed_settings_id'],
        'userGuid': !exists(json, 'user_guid') ? undefined : json['user_guid'],
        'recordListIds': !exists(json, 'record_list_ids') ? undefined : json['record_list_ids'],
        'recordListAddedDate': !exists(json, 'record_list_added_date') ? undefined : RecordSearchConfigCompanyRecordFilterDateFilterFromJSON(json['record_list_added_date']),
        'assignee': !exists(json, 'assignee') ? undefined : json['assignee'],
        'signalCategory': !exists(json, 'signal_category') ? undefined : json['signal_category'],
        'showUserHiddenLeads': !exists(json, 'show_user_hidden_leads') ? undefined : json['show_user_hidden_leads'],
        'signalTextQuery': !exists(json, 'signal_text_query') ? undefined : json['signal_text_query'],
        'ignoredSignalCategories': !exists(json, 'ignored_signal_categories') ? undefined : json['ignored_signal_categories'],
        'procurementStageQualifications': !exists(json, 'procurement_stage_qualifications') ? undefined : json['procurement_stage_qualifications'],
        'relevanceScore': !exists(json, 'relevance_score') ? undefined : json['relevance_score'],
        'includedQualificationStates': !exists(json, 'included_qualification_states') ? undefined : json['included_qualification_states'],
        'excludedQualificationStates': !exists(json, 'excluded_qualification_states') ? undefined : json['excluded_qualification_states'],
        'buyerListIds': !exists(json, 'buyer_list_ids') ? undefined : json['buyer_list_ids'],
        'excludeUnqualified': !exists(json, 'exclude_unqualified') ? undefined : json['exclude_unqualified'],
        'signalsFilter': !exists(json, 'signals_filter') ? undefined : RecordSearchConfigCompanyRecordFilterSignalsFilterFromJSON(json['signals_filter']),
        'signals': !exists(json, 'signals') ? undefined : RecordSearchConfigCompanyRecordFilterSignalsFromJSON(json['signals']),
    };
}

export function RecordSearchConfigCompanyRecordFilterToJSON(value?: RecordSearchConfigCompanyRecordFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_guid': value.companyGuid,
        'team_id': value.teamId,
        'feed_settings_id': value.feedSettingsId,
        'user_guid': value.userGuid,
        'record_list_ids': value.recordListIds,
        'record_list_added_date': RecordSearchConfigCompanyRecordFilterDateFilterToJSON(value.recordListAddedDate),
        'assignee': value.assignee,
        'signal_category': value.signalCategory,
        'show_user_hidden_leads': value.showUserHiddenLeads,
        'signal_text_query': value.signalTextQuery,
        'ignored_signal_categories': value.ignoredSignalCategories,
        'procurement_stage_qualifications': value.procurementStageQualifications,
        'relevance_score': value.relevanceScore,
        'included_qualification_states': value.includedQualificationStates,
        'excluded_qualification_states': value.excludedQualificationStates,
        'buyer_list_ids': value.buyerListIds,
        'exclude_unqualified': value.excludeUnqualified,
        'signals_filter': RecordSearchConfigCompanyRecordFilterSignalsFilterToJSON(value.signalsFilter),
        'signals': RecordSearchConfigCompanyRecordFilterSignalsToJSON(value.signals),
    };
}

