import { RecordFamily } from "../pages/admin/record_projections/common";
import API from "./Api";

type ListRecordProjectionsRequest = {
  query: string;
  date_from?: string;
  date_to?: string;
};

class StotlesAdminAPI extends API {
  constructor(endpoint: string = window.location.origin) {
    super(endpoint);
  }
  getRecordProjections(
    query: string,
    dateFrom: string | undefined,
    dateTo: string | undefined,
  ): Promise<{ record_families: RecordFamily[] }> {
    const queryParams: Partial<ListRecordProjectionsRequest> = { query };
    if (dateFrom) queryParams.date_from = dateFrom;
    if (dateTo) queryParams.date_to = dateTo;

    return this.fetchJSON<ListRecordProjectionsRequest, { record_families: RecordFamily[] }>(
      "GET",
      "admin/record_projections/search",
      {
        query: queryParams as Record<string, string>,
      },
    );
  }

  performBulkAdminUpload(file: string, resource: string): Promise<unknown> {
    return this.fetchJSON("POST", `admin/bulk/${resource}/perform`, { body: { file } });
  }

  validateBulkAdminUpload(file: string, resource: string): Promise<{ errors?: string[] }> {
    return this.fetchJSON<{ file: string }, { errors?: string[] }>(
      "POST",
      `admin/bulk/${resource}/validate`,
      { body: { file } },
    );
  }
}

export default StotlesAdminAPI;
