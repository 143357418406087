import React, { useCallback } from "react";
import { Button, Tooltip } from "antd5";

import BookmarkIcon from "components/actions/Bookmark";
import { useSavedSuppliers } from "lib/hooks/api/signals/useSavedSuppliers";
import { EventNames, useTracking } from "lib/tracking";
import { isArrayInArray } from "lib/utils";
import SaveSupplierPopover from "./SaveSupplierPopover";

// eslint-disable-next-line css-modules/no-unused-class
import css from "./SaveSupplier.module.scss";

type Props = {
  supplierGuids: string[];
};

function BulkSaveSupplier({ supplierGuids }: Props) {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const { data: savedSuppliers, isLoading } = useSavedSuppliers();

  const { partnerGuids, competitorGuids } = savedSuppliers || {
    partnerGuids: [],
    competitorGuids: [],
  };

  const tracking = useTracking();

  const onBulkSaveSupplierOp = useCallback(
    (allSupplierSignalCategoryGuids: string[], category: "Competitor" | "Partner") => {
      const isSavingSupplier = isArrayInArray(supplierGuids, allSupplierSignalCategoryGuids);
      tracking.logEvent(EventNames.recordQueryUpdated, {
        "Context source": "Bulk action",
        "Action type": isSavingSupplier ? "Added" : "Removed",
        "Supplier GUIDs": supplierGuids,
        "Signal type": category,
      });
    },
    [supplierGuids, tracking],
  );

  return (
    <SaveSupplierPopover
      supplierGuids={supplierGuids}
      onSaveSupplierOp={onBulkSaveSupplierOp}
      isPopoverOpen={isPopoverOpen}
      onOpenChange={setIsPopoverOpen}
      competitorGuids={competitorGuids}
      partnerGuids={partnerGuids}
      contextSource="Bulk action"
    >
      <div>
        <Tooltip title={`Save ${supplierGuids.length} suppliers as a competitor or partner`}>
          <Button type="default" className={css.saveSupplierButton} loading={isLoading}>
            <BookmarkIcon colour="blue" /> Save
          </Button>
        </Tooltip>
      </div>
    </SaveSupplierPopover>
  );
}

export default BulkSaveSupplier;
