/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RuleResultsResponseNoticesResultsInnerMatchedElementsInner
 */
export interface RuleResultsResponseNoticesResultsInnerMatchedElementsInner {
    /**
     * 
     * @type {string}
     * @memberof RuleResultsResponseNoticesResultsInnerMatchedElementsInner
     */
    elementType: RuleResultsResponseNoticesResultsInnerMatchedElementsInnerElementTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RuleResultsResponseNoticesResultsInnerMatchedElementsInner
     */
    elementId: string;
    /**
     * 
     * @type {number}
     * @memberof RuleResultsResponseNoticesResultsInnerMatchedElementsInner
     */
    score: number;
}


/**
 * @export
 */
export const RuleResultsResponseNoticesResultsInnerMatchedElementsInnerElementTypeEnum = {
    ProcurementStage: 'procurement_stage',
    Document: 'document'
} as const;
export type RuleResultsResponseNoticesResultsInnerMatchedElementsInnerElementTypeEnum = typeof RuleResultsResponseNoticesResultsInnerMatchedElementsInnerElementTypeEnum[keyof typeof RuleResultsResponseNoticesResultsInnerMatchedElementsInnerElementTypeEnum];


/**
 * Check if a given object implements the RuleResultsResponseNoticesResultsInnerMatchedElementsInner interface.
 */
export function instanceOfRuleResultsResponseNoticesResultsInnerMatchedElementsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "elementType" in value;
    isInstance = isInstance && "elementId" in value;
    isInstance = isInstance && "score" in value;

    return isInstance;
}

export function RuleResultsResponseNoticesResultsInnerMatchedElementsInnerFromJSON(json: any): RuleResultsResponseNoticesResultsInnerMatchedElementsInner {
    return RuleResultsResponseNoticesResultsInnerMatchedElementsInnerFromJSONTyped(json, false);
}

export function RuleResultsResponseNoticesResultsInnerMatchedElementsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleResultsResponseNoticesResultsInnerMatchedElementsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elementType': json['elementType'],
        'elementId': json['elementId'],
        'score': json['score'],
    };
}

export function RuleResultsResponseNoticesResultsInnerMatchedElementsInnerToJSON(value?: RuleResultsResponseNoticesResultsInnerMatchedElementsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elementType': value.elementType,
        'elementId': value.elementId,
        'score': value.score,
    };
}

