/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetRecordSummaryDetailsResponseValue } from './GetRecordSummaryDetailsResponseValue';
import {
    GetRecordSummaryDetailsResponseValueFromJSON,
    GetRecordSummaryDetailsResponseValueFromJSONTyped,
    GetRecordSummaryDetailsResponseValueToJSON,
} from './GetRecordSummaryDetailsResponseValue';

/**
 * 
 * @export
 * @interface GetRecordSummaryDetailsResponse
 */
export interface GetRecordSummaryDetailsResponse {
    /**
     * 
     * @type {Array<GetRecordSummaryDetailsResponseValue>}
     * @memberof GetRecordSummaryDetailsResponse
     */
    values: Array<GetRecordSummaryDetailsResponseValue>;
}

/**
 * Check if a given object implements the GetRecordSummaryDetailsResponse interface.
 */
export function instanceOfGetRecordSummaryDetailsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "values" in value;

    return isInstance;
}

export function GetRecordSummaryDetailsResponseFromJSON(json: any): GetRecordSummaryDetailsResponse {
    return GetRecordSummaryDetailsResponseFromJSONTyped(json, false);
}

export function GetRecordSummaryDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRecordSummaryDetailsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'values': ((json['values'] as Array<any>).map(GetRecordSummaryDetailsResponseValueFromJSON)),
    };
}

export function GetRecordSummaryDetailsResponseToJSON(value?: GetRecordSummaryDetailsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'values': ((value.values as Array<any>).map(GetRecordSummaryDetailsResponseValueToJSON)),
    };
}

