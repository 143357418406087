import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useOpenApi } from "lib/openApiContext";

type RemoveEntriesParams = { buyerListGuid: string; buyerListName: string; buyerGuids: string[] };
export function useRemoveBuyerEntries(
  options?: UseMutationOptions<void, unknown, RemoveEntriesParams, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ buyerListGuid, buyerGuids }: RemoveEntriesParams) =>
      api.removeBuyersFromList({ id: buyerListGuid, removeBuyersRequest: { buyerGuids } }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(["buyerLists"]);
        void queryClient.invalidateQueries(["supplierStats"]);
        void queryClient.invalidateQueries(["buyers", "buyersForSupplier"]);
        void queryClient.invalidateQueries([variables.buyerListGuid]);
        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
    },
  );
}
