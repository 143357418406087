/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReportRecord } from './ReportRecord';
import {
    ReportRecordFromJSON,
    ReportRecordFromJSONTyped,
    ReportRecordToJSON,
} from './ReportRecord';

/**
 * 
 * @export
 * @interface GenerateRecordSectionResponse
 */
export interface GenerateRecordSectionResponse {
    /**
     * 
     * @type {Array<ReportRecord>}
     * @memberof GenerateRecordSectionResponse
     */
    records: Array<ReportRecord>;
}

/**
 * Check if a given object implements the GenerateRecordSectionResponse interface.
 */
export function instanceOfGenerateRecordSectionResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "records" in value;

    return isInstance;
}

export function GenerateRecordSectionResponseFromJSON(json: any): GenerateRecordSectionResponse {
    return GenerateRecordSectionResponseFromJSONTyped(json, false);
}

export function GenerateRecordSectionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenerateRecordSectionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'records': ((json['records'] as Array<any>).map(ReportRecordFromJSON)),
    };
}

export function GenerateRecordSectionResponseToJSON(value?: GenerateRecordSectionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'records': ((value.records as Array<any>).map(ReportRecordToJSON)),
    };
}

