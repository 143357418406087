/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserDto } from './UserDto';
import {
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * 
 * @export
 * @interface DescribeUserResponse
 */
export interface DescribeUserResponse {
    /**
     * 
     * @type {UserDto}
     * @memberof DescribeUserResponse
     */
    user: UserDto;
}

/**
 * Check if a given object implements the DescribeUserResponse interface.
 */
export function instanceOfDescribeUserResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;

    return isInstance;
}

export function DescribeUserResponseFromJSON(json: any): DescribeUserResponse {
    return DescribeUserResponseFromJSONTyped(json, false);
}

export function DescribeUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DescribeUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserDtoFromJSON(json['user']),
    };
}

export function DescribeUserResponseToJSON(value?: DescribeUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserDtoToJSON(value.user),
    };
}

