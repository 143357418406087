import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Modal, Tour, TourProps } from "antd5";

import { TourComponent } from "lib/themes/TourComponent";

import css from "./StartTourModal.module.scss";

type Props = {
  enabled?: boolean;
  title: string;
  description: string;
  width?: number;
  tourSteps: TourProps["steps"];
  tourOpen: boolean;
  showTour: boolean;
  dismissTour: () => void;
  onShowTour: () => void;
  onHideTour: () => void;
};

/**
 * Common modal which kicks off a tour. Just needs to be provided with the tour steps
 * and the tour identifier.
 * @param param0
 * @returns
 */
export function StartTourModal({
  enabled = true,
  title,
  description,
  width = 688,
  tourSteps,
  tourOpen,
  showTour,
  dismissTour,
  onShowTour,
  onHideTour,
}: Props) {
  return (
    <ConfigProvider theme={TourComponent}>
      <Modal
        open={enabled && tourOpen && !showTour}
        width={width}
        title={title}
        className={css.bottomRight}
        wrapClassName={css.allowBackgroundActivity}
        footer={null}
        // For if user clicks the mask
        onCancel={dismissTour}
        // For if user clicks cancel button
        closeIcon={<CloseOutlined className={css.closeIcon} onClick={dismissTour} />}
        mask={false}
      >
        <p>{description}</p>
        <div className={css.footer}>
          <Button type="text" onClick={onHideTour} className={css.noThanks}>
            No thanks
          </Button>
          <Button onClick={onShowTour} className={css.startTourBtn}>
            Take a look
          </Button>
        </div>
      </Modal>

      <Tour
        open={showTour}
        onClose={dismissTour}
        onFinish={onHideTour}
        mask={{ style: { pointerEvents: "none" } }}
        steps={tourSteps}
        defaultCurrent={0}
        indicatorsRender={(current, total) => (
          <span>
            {current + 1} / {total}
          </span>
        )}
        scrollIntoViewOptions={{ behavior: "smooth" }}
      />
    </ConfigProvider>
  );
}
