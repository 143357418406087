import React from "react";
import { Button } from "antd5";

import Signal from "lib/icons/Signal";

import css from "./SignalEmptyState.module.scss";

type Props = {
  entity: string;
  message: string;
};

function SignalEmptyState({ entity, message }: Props) {
  return (
    <div className={css.emptyStateDropdown}>
      <div aria-label="Empty signal state" className={css.emptyStateContainer}>
        <div className={css.emptyStateTextContainer}>
          <b>{`You haven’t saved any ${entity}s yet`}</b>
          {message}
        </div>
        <Button
          type="primary"
          aria-label="Signals settings button"
          className={css.signalSettingsBtn}
          target="_blank"
          href="/account-management/feed-settings"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={css.signalSettingsBtnContents}>
            <Signal fill="white" background="none" />
            Save {entity} signals
          </div>
        </Button>
      </div>
    </div>
  );
}

export default SignalEmptyState;
