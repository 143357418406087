import React from "react";
import { Input } from "antd5";

import FormItemWrapper from "../FormItemWrapper";

const { TextArea } = Input;

type TextAreaProps = {
  id?: string;
  label: string;
  required?: boolean;
  value: number;
  updateValue: (value: string | undefined) => void;
  errors: string;
  description?: string;
};

export function TextAreaInput({
  id,
  label,
  required,
  value,
  updateValue,
  errors,
  description,
}: TextAreaProps): JSX.Element {
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateValue(e.currentTarget.value.length < 1 ? undefined : e.currentTarget.value);
  };

  return (
    <FormItemWrapper
      label={label}
      formIdentifier="string"
      required={required}
      errors={errors}
      description={description}
    >
      <TextArea id={id} rows={3} value={value} onChange={handleInputChange} required={required} />
    </FormItemWrapper>
  );
}
