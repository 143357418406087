/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactSearchRequestDto
 */
export interface ContactSearchRequestDto {
    /**
     * 
     * @type {number}
     * @memberof ContactSearchRequestDto
     */
    offset?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContactSearchRequestDto
     */
    limit?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSearchRequestDto
     */
    sort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSearchRequestDto
     */
    sortOrder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactSearchRequestDto
     */
    jobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof ContactSearchRequestDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ContactSearchRequestDto
     */
    lastName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactSearchRequestDto
     */
    seniority: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactSearchRequestDto
     */
    jobFunction: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ContactSearchRequestDto
     */
    buyerIds: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactSearchRequestDto
     */
    buyerListIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSearchRequestDto
     */
    includeEmptyJobFunction: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactSearchRequestDto
     */
    buyerCategoryIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ContactSearchRequestDto
     */
    includeUncategorisedBuyers: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactSearchRequestDto
     */
    contactIds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactSearchRequestDto
     */
    contactDisplayOptions: Array<string>;
}

/**
 * Check if a given object implements the ContactSearchRequestDto interface.
 */
export function instanceOfContactSearchRequestDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "jobTitle" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "seniority" in value;
    isInstance = isInstance && "jobFunction" in value;
    isInstance = isInstance && "buyerIds" in value;
    isInstance = isInstance && "buyerListIds" in value;
    isInstance = isInstance && "includeEmptyJobFunction" in value;
    isInstance = isInstance && "buyerCategoryIds" in value;
    isInstance = isInstance && "includeUncategorisedBuyers" in value;
    isInstance = isInstance && "contactIds" in value;
    isInstance = isInstance && "contactDisplayOptions" in value;

    return isInstance;
}

export function ContactSearchRequestDtoFromJSON(json: any): ContactSearchRequestDto {
    return ContactSearchRequestDtoFromJSONTyped(json, false);
}

export function ContactSearchRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactSearchRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'sortOrder': !exists(json, 'sort_order') ? undefined : json['sort_order'],
        'jobTitle': json['job_title'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'seniority': json['seniority'],
        'jobFunction': json['job_function'],
        'buyerIds': json['buyer_ids'],
        'buyerListIds': json['buyer_list_ids'],
        'includeEmptyJobFunction': json['include_empty_job_function'],
        'buyerCategoryIds': json['buyer_category_ids'],
        'includeUncategorisedBuyers': json['include_uncategorised_buyers'],
        'contactIds': json['contact_ids'],
        'contactDisplayOptions': json['contact_display_options'],
    };
}

export function ContactSearchRequestDtoToJSON(value?: ContactSearchRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offset': value.offset,
        'limit': value.limit,
        'sort': value.sort,
        'sort_order': value.sortOrder,
        'job_title': value.jobTitle,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'seniority': value.seniority,
        'job_function': value.jobFunction,
        'buyer_ids': value.buyerIds,
        'buyer_list_ids': value.buyerListIds,
        'include_empty_job_function': value.includeEmptyJobFunction,
        'buyer_category_ids': value.buyerCategoryIds,
        'include_uncategorised_buyers': value.includeUncategorisedBuyers,
        'contact_ids': value.contactIds,
        'contact_display_options': value.contactDisplayOptions,
    };
}

