/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateIndustryRequest
 */
export interface UpdateIndustryRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateIndustryRequest
     */
    industry: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateIndustryRequest
     */
    subIndustry?: string | null;
}

/**
 * Check if a given object implements the UpdateIndustryRequest interface.
 */
export function instanceOfUpdateIndustryRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "industry" in value;

    return isInstance;
}

export function UpdateIndustryRequestFromJSON(json: any): UpdateIndustryRequest {
    return UpdateIndustryRequestFromJSONTyped(json, false);
}

export function UpdateIndustryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateIndustryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'industry': json['industry'],
        'subIndustry': !exists(json, 'sub_industry') ? undefined : json['sub_industry'],
    };
}

export function UpdateIndustryRequestToJSON(value?: UpdateIndustryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'industry': value.industry,
        'sub_industry': value.subIndustry,
    };
}

