import React, { useMemo } from "react";

import ProcurementTimeline, {
  TimelineObj,
  TimelineStage,
} from "lib/core_components/ProcurementTimeline";
import { RecordTimelineObj } from "lib/generated/app-api";
import { useNoticeTimeline } from "lib/hooks/api/notices/useNoticeTimeline";
import { TenderStage } from "lib/types/models";

import css from "./NoticeTimeline.module.scss";

/**
 * Converts notice stages to one of 4 timeline stages, the timeline stages that these will eventually resolve to in
 * the ProcurementTimeline component may change depending on how the date periods relate to the current date
 * (for example if the end date of the award stage is in the past, the notice is expired)
 * @param stage
 * @returns
 */
function noticeStageToTimelineStage(stage?: string | null): TimelineStage {
  switch (stage) {
    // Stale pre-tender will eventually be resolved to stale in ProcurementTimeline component
    case TenderStage.STALE_PRE_TENDER:
    case TenderStage.PRE_TENDER:
      return TimelineStage.PRE_TENDER;
    case TenderStage.OPEN:
    case TenderStage.CLOSED:
      return TimelineStage.TENDER;
    case TenderStage.AWARDED:
      return TimelineStage.AWARD;
    default:
      return TimelineStage.UNKNOWN;
  }
}

/**
 * Organises the timelineStages returned from the graphQL API into a common format which
 * the {@link ProcurementTimeline} component can understand
 * @param timelineStages
 * @returns
 */
function serializeTimelineStages(timelineStages: RecordTimelineObj[]): TimelineObj[] {
  return (
    timelineStages?.map((timelineStage) => {
      const { stage, title, externalSourceUrl, startDate, endDate, stotlesUrl, publishDate } =
        timelineStage;
      return {
        title: title,
        stage: noticeStageToTimelineStage(stage),
        stotlesUrl: stotlesUrl,
        externalSourceUrl,
        publishDate: publishDate ? new Date(publishDate) : undefined,
        startDate: startDate ? new Date(startDate) : undefined,
        endDate: endDate ? new Date(endDate) : undefined,
      };
    }) ?? []
  );
}

type Props = {
  procurementProcessId: string;
  stage: string;
};
export default function NoticeTimeline({ procurementProcessId, stage }: Props): JSX.Element {
  const { data, isLoading: isLoadingTimeline } = useNoticeTimeline(procurementProcessId);

  const currentStage = noticeStageToTimelineStage(stage);

  const timelineStages = useMemo(
    () => serializeTimelineStages(data?.timelineStages ?? []),
    [data?.timelineStages],
  );
  return (
    <div className={css.noticeTimeline} aria-label="Notice timeline">
      <h2 className={css.subtitle}>Timeline</h2>
      <ProcurementTimeline
        currentStage={currentStage}
        isLoading={isLoadingTimeline}
        timelineStages={timelineStages}
        extensionAvailable={data?.extensionAvailable ?? false}
      />
    </div>
  );
}
