/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TeamDetails } from './TeamDetails';
import {
    TeamDetailsFromJSON,
    TeamDetailsFromJSONTyped,
    TeamDetailsToJSON,
} from './TeamDetails';

/**
 * 
 * @export
 * @interface AvailableTeamResponse
 */
export interface AvailableTeamResponse {
    /**
     * 
     * @type {Array<TeamDetails>}
     * @memberof AvailableTeamResponse
     */
    availableTeams: Array<TeamDetails>;
}

/**
 * Check if a given object implements the AvailableTeamResponse interface.
 */
export function instanceOfAvailableTeamResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "availableTeams" in value;

    return isInstance;
}

export function AvailableTeamResponseFromJSON(json: any): AvailableTeamResponse {
    return AvailableTeamResponseFromJSONTyped(json, false);
}

export function AvailableTeamResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableTeamResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availableTeams': ((json['available_teams'] as Array<any>).map(TeamDetailsFromJSON)),
    };
}

export function AvailableTeamResponseToJSON(value?: AvailableTeamResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available_teams': ((value.availableTeams as Array<any>).map(TeamDetailsToJSON)),
    };
}

