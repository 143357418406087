import * as React from "react";

import { findRegexMatches } from "lib/core_components/TextMatchHighlighter";
import { GetRecordSummaryDetailsRequestAttributeNamesEnum } from "lib/generated/app-api";
import { SignalDto } from "lib/generated/app-api/models/SignalDto";
import { useRecordSummaryDetails } from "lib/hooks/api/useRecordSummaryDetails";
import { useStoredRecordSummary } from "lib/hooks/api/useStoredRecordSummary";
import { TextMatch } from "lib/qued/queryRunner";
import { escapeRegExp } from "lib/utils";
import { ORDER_OF_SECTIONS_ON_PAGE, SummaryValue, usefulResponse } from "./utils";

/**
 * Hook for combining the fetching stored response and on-demand responses, and presenting the
 * response in a uniform data structure.
 *
 * In the future this is likely something we could do in the back end with a single API that can
 * handle reading from the db/openAi, and streaming back responses when ready - but let's see if
 * this will be successful enough to warrant it!
 */
export function useSummaryData(recordGuid: string): {
  data: Partial<Record<GetRecordSummaryDetailsRequestAttributeNamesEnum, SummaryValue>>;
  isCalculating: boolean;
} {
  // Fetch summary for all attribute_names from the DB
  const { data: preloadedSummaries } = useStoredRecordSummary(
    recordGuid,
    ORDER_OF_SECTIONS_ON_PAGE,
  );

  const summariesToCalculateOnDemand =
    preloadedSummaries?.values.filter((s) => !s.content).map((s) => s.attributeName) || [];

  // Fetch the summaries for the remaining attribute_names on demand
  const { data: onDemandSummaries, isFetching: isCalculating } = useRecordSummaryDetails(
    recordGuid,
    summariesToCalculateOnDemand,
    {
      enabled:
        // only fetch the on-demand summaries if we have any to fetch
        summariesToCalculateOnDemand.length > 0,
      // openAi often gets 503 errors
      retry: 3,
      retryDelay: 500,
    },
  );

  const mapping = React.useMemo(() => {
    const valuesByAttribute: Partial<
      Record<GetRecordSummaryDetailsRequestAttributeNamesEnum, SummaryValue>
    > = {};

    for (const attr of ORDER_OF_SECTIONS_ON_PAGE) {
      const preloadedValue = preloadedSummaries?.values.find((s) => s.attributeName === attr);
      const value = preloadedValue?.content
        ? preloadedValue
        : onDemandSummaries?.values.find((s) => s.attributeName === attr);

      // reject anything that has null content
      if (value && value.content) {
        valuesByAttribute[attr] = {
          loaded: true,
          // discard the content if not useful
          content: usefulResponse(value.content) ? value.content : null,
          attributeName: attr,
        };
      }
    }

    return valuesByAttribute;
  }, [preloadedSummaries, onDemandSummaries]);

  return { data: mapping, isCalculating };
}

export function useSimpleKeywordMatches(text: string | null, signalSettings?: SignalDto[]) {
  const keywordMatches = React.useMemo(() => {
    let all: TextMatch[] = [];
    if (!text || !signalSettings) return all;

    for (const signal of signalSettings) {
      let regexp: RegExp;
      const kw = signal.name;
      if (kw.length > 8 && kw.match(/^[A-Z]+$/)) {
        regexp = new RegExp(`\\b(${escapeRegExp(kw)})\\b`, "g");
        const matches = findRegexMatches(text, regexp);
        all = all.concat(matches);
      } else {
        // our simplistic findRegexMatches function requires a regexp with exactly one match group,
        // so it is eaasier to break apart any "AND" keywords and treat them as separate terms
        //
        // most of the time this will be length 1, and sometimes 2 (eg. Digital AND transformation)
        const regexps = kw
          .split(" AND ")
          .filter((w) => !!w)
          .map((w) => new RegExp(`\\b(${escapeRegExp(w)})`, "gi"));

        for (const r of regexps) {
          const matches = findRegexMatches(text, r);
          all = all.concat(matches);
        }
      }
    }

    return (
      all
        .sort((a, b) => a.start - b.start)
        // remove any sections highlighted more than once
        .filter(
          (match, index) =>
            all.findIndex((m) => match.start === m.start || match.end === m.end) === index,
        )
    );
  }, [text, signalSettings]);

  return keywordMatches;
}
