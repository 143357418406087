import * as React from "react";
import classNames from "classnames";

import { IBuyerDataProvider } from "../data_providers/BuyerDataProvider";
import { useStotlesData } from "../providers/StotlesData";

import css from "./BuyerCategoryLabel.module.scss";

export type Props = {
  buyerCategoryId: string;
  size?: "small" | "default"; // small - font-size 12px, normal: default font size
  className?: string;
};

function BuyerCategoryLabel({ buyerCategoryId, size, className }: Props): JSX.Element | null {
  const { value: buyerCategories } = useStotlesData(
    IBuyerDataProvider,
    (bc) => bc.getBuyerCategories(),
    [],
  );

  if (!buyerCategories) {
    return null;
  }

  const category = buyerCategories.get(buyerCategoryId);
  if (!category) {
    return null;
  }

  const parent = category.parent_category_id
    ? buyerCategories.get(category.parent_category_id)
    : undefined;

  return (
    <div
      className={classNames(
        css.pairContainer,
        size === "small" ? css.labelSizeSmall : null,
        className,
      )}
    >
      {parent && (
        <>
          <span>{parent.name}</span>
          <span className={css.divider}>&gt;</span>
        </>
      )}
      <span>{category.name}</span>
    </div>
  );
}

export default BuyerCategoryLabel;
