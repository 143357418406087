import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button } from "antd5";

import css from "./ClearSelectedButton.module.scss";

export function ClearSelectedButton({ count, onClear }: { count: number; onClear: () => void }) {
  return (
    <Button onClick={onClear} type="text">
      <CloseCircleOutlined className={css.closeIcon} /> {count} selected
    </Button>
  );
}
