import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { convertRecordMatchToRecordMatchInfo } from "components/my_feed/useRecordMatchData";
import { NEW_HIGHLIGHT_LOGIC, useVariableValue } from "lib/featureFlags";
import { RecordMatchInfo } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";

export function useAnnotateNotice(
  noticeId = "",
  options?: UseQueryOptions<RecordMatchInfo, unknown, RecordMatchInfo, string[]>,
) {
  const api = useStotlesApi();
  const user = window.currentUser;

  const isNewHighlightingLogicEnabled = useVariableValue(NEW_HIGHLIGHT_LOGIC, false);

  return useQuery(
    ["annotateRecords", noticeId],
    async () => {
      if (!user || !noticeId) {
        // This will not run as it is diabled below when user or noticeId is not present
        return { keyword_matches: [] };
      }
      const response = await api.annotateRecords(user.company.id, [noticeId]);

      const annotations = convertRecordMatchToRecordMatchInfo(response.matched_records)[noticeId];
      // If the record does not match any keywords, it will not be found in the response, so we return empty matches
      if (!annotations) {
        return { keyword_matches: [] };
      }
      return annotations;
    },
    { ...options, enabled: !!user && !!noticeId && !isNewHighlightingLogicEnabled },
  );
}
