import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { assertCurrentUser } from "lib/currentUser";
import { GetRecordListsResponseResult } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type QueryKey = [string];

type Response = {
  allLists: GetRecordListsResponseResult[];
  userLists: GetRecordListsResponseResult[];
  sharedLists: GetRecordListsResponseResult[];
  listNames: string[];
};

export function useNoticeLists(options?: UseQueryOptions<Response, unknown, Response, QueryKey>) {
  const api = useOpenApi();
  const user = assertCurrentUser();

  return useQuery(
    ["noticeLists"],
    async () => {
      const response = await api.getRecordLists();

      const myLists = response?.results.filter((list) => list.user.guid === user.guid);
      const sharedLists = response?.results.filter((list) => list.user.guid !== user.guid);
      const listNames = response?.results.map((list) => list.name);

      return {
        allLists: response?.results || [],
        userLists: myLists || [],
        sharedLists: sharedLists || [],
        listNames: listNames || [],
      };
    },
    { ...options },
  );
}
