/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateLeadSubscriptionRequest
 */
export interface CreateLeadSubscriptionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLeadSubscriptionRequest
     */
    frequency: CreateLeadSubscriptionRequestFrequencyEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadSubscriptionRequest
     */
    webhookUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadSubscriptionRequest
     */
    name?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLeadSubscriptionRequest
     */
    viewIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadSubscriptionRequest
     */
    type: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateLeadSubscriptionRequest
     */
    listIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadSubscriptionRequest
     */
    companyGuid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadSubscriptionRequest
     */
    teamId?: string | null;
}


/**
 * @export
 */
export const CreateLeadSubscriptionRequestFrequencyEnum = {
    Daily: 'DAILY',
    Weekly: 'WEEKLY'
} as const;
export type CreateLeadSubscriptionRequestFrequencyEnum = typeof CreateLeadSubscriptionRequestFrequencyEnum[keyof typeof CreateLeadSubscriptionRequestFrequencyEnum];


/**
 * Check if a given object implements the CreateLeadSubscriptionRequest interface.
 */
export function instanceOfCreateLeadSubscriptionRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "frequency" in value;
    isInstance = isInstance && "viewIds" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function CreateLeadSubscriptionRequestFromJSON(json: any): CreateLeadSubscriptionRequest {
    return CreateLeadSubscriptionRequestFromJSONTyped(json, false);
}

export function CreateLeadSubscriptionRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeadSubscriptionRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frequency': json['frequency'],
        'webhookUrl': !exists(json, 'webhook_url') ? undefined : json['webhook_url'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'viewIds': json['view_ids'],
        'type': json['type'],
        'listIds': !exists(json, 'list_ids') ? undefined : json['list_ids'],
        'companyGuid': !exists(json, 'company_guid') ? undefined : json['company_guid'],
        'teamId': !exists(json, 'team_id') ? undefined : json['team_id'],
    };
}

export function CreateLeadSubscriptionRequestToJSON(value?: CreateLeadSubscriptionRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'frequency': value.frequency,
        'webhook_url': value.webhookUrl,
        'name': value.name,
        'view_ids': value.viewIds,
        'type': value.type,
        'list_ids': value.listIds,
        'company_guid': value.companyGuid,
        'team_id': value.teamId,
    };
}

