import * as React from "react";

export function usePreventNavigation(preventNavigation: boolean, message: string): void {
  const preventNavigationState = React.useRef(preventNavigation);
  React.useEffect(() => {
    preventNavigationState.current = preventNavigation;
  }, [preventNavigation]);

  const handler = React.useCallback(
    (e: BeforeUnloadEvent) => {
      if (!preventNavigationState.current) return; // no-op
      // Try to handle most browsers based on
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      e.preventDefault();
      // Most browsers won't show this message either way
      // but the modal should show up.
      e.returnValue = message;
      return message;
    },
    [message],
  );

  React.useEffect(() => {
    window.addEventListener("beforeunload", handler);
    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, [handler]);
}
