import * as React from "react";
import { Alert } from "antd5";

import { OrgWithStats, UpdatableAttributes } from "./types";

import css from "./OrgUpdatePreview.module.scss";

const FIELD_LABEL_BY_FIELD_NAME = {
  is_stotles_verified: "Is verified?",
  oscar_id: "Oscar ID",
  companies_house_id: "Companies House ID",
};

type Props = {
  index: number;
  anchorOrg: OrgWithStats;
  orgName: string;
  orgId: string;
  newValues?: UpdatableAttributes;
};

function OrgUpdatePreview({ anchorOrg, orgName, orgId, newValues, index }: Props): JSX.Element {
  return (
    <Alert
      key={index}
      className={css.container}
      type="warning"
      message={
        <h2>
          Updates to {orgName} ({orgId})
        </h2>
      }
      showIcon
      description={
        <dl className={css.buyerUpdate}>
          {newValues &&
            Object.entries(newValues).map(([name, val]) => {
              return (
                anchorOrg[name as keyof UpdatableAttributes] !== val && (
                  <>
                    <dt>Property:</dt>
                    <dd>
                      {name in FIELD_LABEL_BY_FIELD_NAME
                        ? FIELD_LABEL_BY_FIELD_NAME[name as keyof typeof FIELD_LABEL_BY_FIELD_NAME]
                        : name}
                    </dd>
                    <dt>Current value:</dt>
                    <dd>{`${anchorOrg[name as keyof UpdatableAttributes]}`}</dd>
                    <dt>Updated value:</dt>
                    <dd>{`${val}`}</dd>
                  </>
                )
              );
            })}
        </dl>
      }
    />
  );
}

export default OrgUpdatePreview;
