/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LeadSignal } from './LeadSignal';
import {
    LeadSignalFromJSON,
    LeadSignalFromJSONTyped,
    LeadSignalToJSON,
} from './LeadSignal';

/**
 * 
 * @export
 * @interface ReportSupplier
 */
export interface ReportSupplier {
    /**
     * 
     * @type {number}
     * @memberof ReportSupplier
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ReportSupplier
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReportSupplier
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportSupplier
     */
    isSme?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportSupplier
     */
    awardCount: number;
    /**
     * 
     * @type {string}
     * @memberof ReportSupplier
     */
    latestActivity: string;
    /**
     * 
     * @type {Array<LeadSignal>}
     * @memberof ReportSupplier
     */
    signals: Array<LeadSignal>;
}

/**
 * Check if a given object implements the ReportSupplier interface.
 */
export function instanceOfReportSupplier(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "awardCount" in value;
    isInstance = isInstance && "latestActivity" in value;
    isInstance = isInstance && "signals" in value;

    return isInstance;
}

export function ReportSupplierFromJSON(json: any): ReportSupplier {
    return ReportSupplierFromJSONTyped(json, false);
}

export function ReportSupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportSupplier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'isSme': !exists(json, 'is_sme') ? undefined : json['is_sme'],
        'awardCount': json['award_count'],
        'latestActivity': json['latest_activity'],
        'signals': ((json['signals'] as Array<any>).map(LeadSignalFromJSON)),
    };
}

export function ReportSupplierToJSON(value?: ReportSupplier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'country': value.country,
        'is_sme': value.isSme,
        'award_count': value.awardCount,
        'latest_activity': value.latestActivity,
        'signals': ((value.signals as Array<any>).map(LeadSignalToJSON)),
    };
}

