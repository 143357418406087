/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateLeadSubscriptionContentRequest
 */
export interface CreateLeadSubscriptionContentRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateLeadSubscriptionContentRequest
     */
    frequency: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadSubscriptionContentRequest
     */
    resourceId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeadSubscriptionContentRequest
     */
    resourceType: string;
}

/**
 * Check if a given object implements the CreateLeadSubscriptionContentRequest interface.
 */
export function instanceOfCreateLeadSubscriptionContentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "frequency" in value;
    isInstance = isInstance && "resourceId" in value;
    isInstance = isInstance && "resourceType" in value;

    return isInstance;
}

export function CreateLeadSubscriptionContentRequestFromJSON(json: any): CreateLeadSubscriptionContentRequest {
    return CreateLeadSubscriptionContentRequestFromJSONTyped(json, false);
}

export function CreateLeadSubscriptionContentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeadSubscriptionContentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frequency': json['frequency'],
        'resourceId': json['resource_id'],
        'resourceType': json['resource_type'],
    };
}

export function CreateLeadSubscriptionContentRequestToJSON(value?: CreateLeadSubscriptionContentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'frequency': value.frequency,
        'resource_id': value.resourceId,
        'resource_type': value.resourceType,
    };
}

