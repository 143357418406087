import React from "react";

import { DefaultTheme } from "components/app_layout/DefaultTheme";

type Props = {
  width?: number | string;
  height?: number | string;
  fill?: string;
  marginTop?: number | string;
};

export function CircleEmpty({
  width = 20,
  height = 20,
  fill = DefaultTheme.token?.colorPrimaryBg,
  marginTop = 0,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      style={{ marginTop }}
    >
      <circle cx="10" cy="10" r="9" fill="white" stroke={fill} strokeWidth="2" />
    </svg>
  );
}

export function CircleThirdFull({
  width = 20,
  height = 20,
  fill = DefaultTheme.token?.colorPrimary,
  marginTop = 0,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      style={{ marginTop }}
    >
      <circle cx="10" cy="10" r="9" fill="white" stroke={fill} strokeWidth="2" />
      <path
        d="M10 4C11.0522 4 12.0858 4.27668 12.9973 4.80228C13.9087 5.32788 14.666 6.08392 15.193 6.99456C15.72 7.90521 15.9983 8.93843 16 9.99058C16.0016 11.0427 15.7266 12.0768 15.2024 12.9891L10 10V4Z"
        fill={fill}
      />
    </svg>
  );
}

export function CircleHalfFull({
  width = 20,
  height = 20,
  fill = DefaultTheme.token?.colorPrimary,
  marginTop = 0,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop }}
    >
      <circle cx="10" cy="10" r="9" fill="white" stroke={fill} strokeWidth="2" />

      <path
        d="M10 16C11.5913 16 13.1174 15.3679 14.2426 14.2426C15.3679 13.1174 16 11.5913 16 10C16 8.4087 15.3679 6.88258 14.2426 5.75736C13.1174 4.63214 11.5913 4 10 4L10 10L10 16Z"
        fill={fill}
      />
    </svg>
  );
}

export function CircleTwoThirdsFull({
  width = 20,
  height = 20,
  fill = DefaultTheme.token?.colorPrimary,
  marginTop = 0,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop }}
    >
      <circle cx="10" cy="10" r="9" fill="white" stroke={fill} strokeWidth="2" />

      <path
        d="M10 4C11.0522 4 12.0858 4.27668 12.9973 4.80228C13.9087 5.32788 14.666 6.08392 15.193 6.99456C15.72 7.9052 15.9983 8.93842 16 9.99057C16.0016 11.0427 15.7266 12.0768 15.2024 12.9891C14.6783 13.9014 13.9234 14.6598 13.0136 15.1883C12.1038 15.7167 11.071 15.9967 10.0189 16C8.9667 16.0033 7.93218 15.7298 7.01907 15.2071C6.10595 14.6844 5.34635 13.9307 4.81646 13.0217L10 10V4Z"
        fill={fill}
      />
    </svg>
  );
}

export function CircleFull({
  width = 20,
  height = 20,
  fill = DefaultTheme.token?.colorPrimary,
  marginTop = 0,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop }}
    >
      <circle cx="10" cy="10" r="9" fill="white" stroke={fill} strokeWidth="2" />
      <circle cx="10" cy="10" r="6" fill={fill} />
    </svg>
  );
}

export function TrophyCircle({
  width = 20,
  height = 20,
  fill = DefaultTheme.token?.colorPrimary,
  marginTop = 0,
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop }}
    >
      <circle cx="10" cy="10" r="9" fill={fill} stroke={fill} strokeWidth="2" />
      <svg
        x="3.5"
        y="3.5"
        width="13"
        height="13"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_615_9143)">
          <path
            d="M6 7.5C4.34315 7.5 3 6.15685 3 4.5V1.72222C3 1.5153 3 1.41184 3.03015 1.32899C3.0807 1.19011 3.19011 1.0807 3.32899 1.03015C3.41184 1 3.5153 1 3.72222 1H8.27778C8.4847 1 8.58816 1 8.67101 1.03015C8.80989 1.0807 8.9193 1.19011 8.96985 1.32899C9 1.41184 9 1.5153 9 1.72222V4.5C9 6.15685 7.65685 7.5 6 7.5ZM6 7.5V9M9 2H10.25C10.483 2 10.5995 2 10.6913 2.03806C10.8139 2.08881 10.9112 2.18614 10.9619 2.30866C11 2.40054 11 2.51703 11 2.75V3C11 3.46499 11 3.69748 10.9489 3.88823C10.8102 4.40587 10.4059 4.81019 9.88823 4.94889C9.69748 5 9.46499 5 9 5M3 2H1.75C1.51703 2 1.40054 2 1.30866 2.03806C1.18614 2.08881 1.08881 2.18614 1.03806 2.30866C1 2.40054 1 2.51703 1 2.75V3C1 3.46499 1 3.69748 1.05111 3.88823C1.18981 4.40587 1.59413 4.81019 2.11177 4.94889C2.30252 5 2.53501 5 3 5M3.72222 11H8.27778C8.40051 11 8.5 10.9005 8.5 10.7778C8.5 9.79594 7.70406 9 6.72222 9H5.27778C4.29594 9 3.5 9.79594 3.5 10.7778C3.5 10.9005 3.59949 11 3.72222 11Z"
            stroke="white"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_615_9143">
            <rect width="12" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </svg>
  );
}
