/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReportDto } from './ReportDto';
import {
    ReportDtoFromJSON,
    ReportDtoFromJSONTyped,
    ReportDtoToJSON,
} from './ReportDto';

/**
 * 
 * @export
 * @interface GetReportsResponse
 */
export interface GetReportsResponse {
    /**
     * 
     * @type {Array<ReportDto>}
     * @memberof GetReportsResponse
     */
    reports: Array<ReportDto>;
}

/**
 * Check if a given object implements the GetReportsResponse interface.
 */
export function instanceOfGetReportsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reports" in value;

    return isInstance;
}

export function GetReportsResponseFromJSON(json: any): GetReportsResponse {
    return GetReportsResponseFromJSONTyped(json, false);
}

export function GetReportsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetReportsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reports': ((json['reports'] as Array<any>).map(ReportDtoFromJSON)),
    };
}

export function GetReportsResponseToJSON(value?: GetReportsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reports': ((value.reports as Array<any>).map(ReportDtoToJSON)),
    };
}

