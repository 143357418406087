/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecordSearchRequestDtoBuyersCategories } from './RecordSearchRequestDtoBuyersCategories';
import {
    RecordSearchRequestDtoBuyersCategoriesFromJSON,
    RecordSearchRequestDtoBuyersCategoriesFromJSONTyped,
    RecordSearchRequestDtoBuyersCategoriesToJSON,
} from './RecordSearchRequestDtoBuyersCategories';

/**
 * 
 * @export
 * @interface RecordSearchRequestDtoBuyers
 */
export interface RecordSearchRequestDtoBuyers {
    /**
     * 
     * @type {Array<number>}
     * @memberof RecordSearchRequestDtoBuyers
     */
    id?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDtoBuyers
     */
    guid?: Array<string> | null;
    /**
     * 
     * @type {RecordSearchRequestDtoBuyersCategories}
     * @memberof RecordSearchRequestDtoBuyers
     */
    categories?: RecordSearchRequestDtoBuyersCategories;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDtoBuyers
     */
    countryCode?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchRequestDtoBuyers
     */
    regionIds?: Array<string>;
}

/**
 * Check if a given object implements the RecordSearchRequestDtoBuyers interface.
 */
export function instanceOfRecordSearchRequestDtoBuyers(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RecordSearchRequestDtoBuyersFromJSON(json: any): RecordSearchRequestDtoBuyers {
    return RecordSearchRequestDtoBuyersFromJSONTyped(json, false);
}

export function RecordSearchRequestDtoBuyersFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSearchRequestDtoBuyers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'guid': !exists(json, 'guid') ? undefined : json['guid'],
        'categories': !exists(json, 'categories') ? undefined : RecordSearchRequestDtoBuyersCategoriesFromJSON(json['categories']),
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
        'regionIds': !exists(json, 'region_ids') ? undefined : json['region_ids'],
    };
}

export function RecordSearchRequestDtoBuyersToJSON(value?: RecordSearchRequestDtoBuyers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'guid': value.guid,
        'categories': RecordSearchRequestDtoBuyersCategoriesToJSON(value.categories),
        'country_code': value.countryCode,
        'region_ids': value.regionIds,
    };
}

