import React, { useCallback } from "react";
import { Control, useController } from "react-hook-form";
import { Alert, Tag } from "antd5";
import { MigrateSignalsFormState } from "pages/app/OrgSignalMigrationPage";

import { EllipsisTooltipText, EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { SignalToMigrate } from "lib/hooks/api/admin/signal_migration/useSignalsToMigrate";
import { OrgPrimaryRole } from "../../../lib/types/graphQLEnums";
import OrganisationSearchTable from "./OrganisationSearchTable";
import SignalsToMigrateSelection, { deduplicateOrgs } from "./SignalsToMigrateSelection";

import css from "./MigrateSignalsStep.module.scss";

type Props = {
  control: Control<MigrateSignalsFormState, unknown>;
  isReview: boolean;
};

function MigrateSignalsStep({ control, isReview }: Props): JSX.Element {
  const {
    field: { value: signals, onChange: onSignalsChange },
    fieldState: { error: signalsError, isDirty: signalsIsDirty },
  } = useController({
    name: "signals",
    control,
    rules: {
      required: "Please select at least one signal",
    },
  });

  const {
    field: { value: orgs, onChange: onOrgsChange },
    fieldState: { error: orgsError, isDirty: orgsIsDirty },
  } = useController({
    name: "orgs",
    control,
    defaultValue: isReview
      ? undefined
      : deduplicateOrgs(signals.map((signal) => signal.mappedOrgs).flat()), // Doesn't work
    rules: {
      required: "Please select at least one organisation",
    },
  });

  /**
   * When a new signal is added, we need to add the orgs from that signal to * the selected orgs
   * @param newSelectedSignals
   */
  const addOrgsFromNewSignals = useCallback(
    (newSelectedSignals: SignalToMigrate[]) => {
      const orgsFromNewSignals = newSelectedSignals.map((signal) => signal.mappedOrgs).flat();

      const dedupedOrgs = deduplicateOrgs([...orgsFromNewSignals, ...(orgs || [])]);

      onOrgsChange(dedupedOrgs);
    },
    [onOrgsChange, orgs],
  );

  return (
    <>
      <div className={css.pageContent}>
        <div className={css.section}>
          {signalsError && signalsIsDirty && (
            <Alert message={signalsError.message} type="error" showIcon className={css.alert} />
          )}
          <SignalsToMigrateSelection
            selectedSignals={signals}
            onSignalsChange={onSignalsChange}
            addOrgsFromNewSignals={addOrgsFromNewSignals}
          />
          <div className={css.tagsContainer}>
            {signals.map((signal) => (
              <ul>
                <li key={signal.name}>
                  <Tag
                    key={signal.name}
                    closable={true}
                    color="green"
                    className={css.tag}
                    onClose={() => onSignalsChange(signals.filter((s) => s.name !== signal.name))}
                  >
                    <EllipsisTooltipText
                      fullText={`${signal.name} (${signal.ids.length})`}
                      containerClassname={css.selectedItem}
                    />
                  </Tag>
                </li>
              </ul>
            ))}
          </div>
        </div>

        <div className={css.section}>
          {orgsError && orgsIsDirty && (
            <Alert message={orgsError.message} type="error" showIcon className={css.alert} />
          )}
          <OrganisationSearchTable
            selectedOrgs={orgs || []}
            onOrgsChange={onOrgsChange}
            primaryRole={OrgPrimaryRole.Supplier}
            containerClassname={css.tableContainer}
          />
          <div className={css.tagsContainer}>
            {orgs?.map((org) => (
              <ul>
                <li key={org.id}>
                  <Tag
                    key={org.id}
                    closable={true}
                    color="geekblue"
                    className={css.tag}
                    onClose={() => onOrgsChange(orgs.filter((o) => o.id !== org.id))}
                  >
                    <EllipsisTooltipTextLink
                      fullText={org.name}
                      linkText={<b>{org.name}</b>}
                      linkProps={{ to: `/suppliers/${org.id}` }}
                      containerClassname={css.selectedItem}
                    />
                  </Tag>
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default MigrateSignalsStep;
