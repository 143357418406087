import React from "react";
import { ControlProps } from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";

import { AccountSearch } from "./AccountSearch";

function AccountSearchControl({
  data,
  handleChange,
  path,
  label,
  required,
  errors,
}: ControlProps): JSX.Element {
  return (
    <AccountSearch
      value={data}
      label={label}
      required={required}
      updateValue={(value: string) => handleChange(path, value)}
      errors={errors}
    />
  );
}

export default withJsonFormsControlProps(AccountSearchControl);
