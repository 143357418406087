/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ViewConfigurationFilterSettingsNumericRangeSetting
 */
export interface ViewConfigurationFilterSettingsNumericRangeSetting {
    /**
     * 
     * @type {number}
     * @memberof ViewConfigurationFilterSettingsNumericRangeSetting
     */
    from?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ViewConfigurationFilterSettingsNumericRangeSetting
     */
    to?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ViewConfigurationFilterSettingsNumericRangeSetting
     */
    nulls?: boolean | null;
}

/**
 * Check if a given object implements the ViewConfigurationFilterSettingsNumericRangeSetting interface.
 */
export function instanceOfViewConfigurationFilterSettingsNumericRangeSetting(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ViewConfigurationFilterSettingsNumericRangeSettingFromJSON(json: any): ViewConfigurationFilterSettingsNumericRangeSetting {
    return ViewConfigurationFilterSettingsNumericRangeSettingFromJSONTyped(json, false);
}

export function ViewConfigurationFilterSettingsNumericRangeSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewConfigurationFilterSettingsNumericRangeSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
        'nulls': !exists(json, 'nulls') ? undefined : json['nulls'],
    };
}

export function ViewConfigurationFilterSettingsNumericRangeSettingToJSON(value?: ViewConfigurationFilterSettingsNumericRangeSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'to': value.to,
        'nulls': value.nulls,
    };
}

