import * as React from "react";

type MessageStatusHook = {
  hidden: boolean;
  handleHiddenChange: (isBecomingHidden: boolean) => void;
};

/*
 * This hook operates in on the basis of thinking about "hiddenness" rather than visibility, given that
 * it is the hiddenness of the message which we are wanting to remember - ie. things are assumed to
 * be visible, unless localStorage or sessionStorage says otherwise
 *
 * eg. a suitable `key` to use might be "hideFeedDataTypesMessage"
 */
export function useLocalDismissibleMsg(
  key: string,
  storageType: "localStorage" | "sessionStorage",
): [MessageStatusHook["hidden"], MessageStatusHook["handleHiddenChange"]] {
  const storage = storageType === "localStorage" ? localStorage : sessionStorage;
  const [hidden, setHidden] = React.useState(() => {
    const val = storage.getItem(key);
    return val === "true";
  });
  const handleHiddenChange = React.useCallback(
    (isBecomingHidden: boolean) => {
      setHidden(isBecomingHidden);
      storage.setItem(key, isBecomingHidden.toString());
    },
    [key, storage],
  );

  return [hidden, handleHiddenChange];
}
