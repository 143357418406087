/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplierSearchRequestBuyerFilters } from './SupplierSearchRequestBuyerFilters';
import {
    SupplierSearchRequestBuyerFiltersFromJSON,
    SupplierSearchRequestBuyerFiltersFromJSONTyped,
    SupplierSearchRequestBuyerFiltersToJSON,
} from './SupplierSearchRequestBuyerFilters';
import type { SupplierSearchRequestSignalFilter } from './SupplierSearchRequestSignalFilter';
import {
    SupplierSearchRequestSignalFilterFromJSON,
    SupplierSearchRequestSignalFilterFromJSONTyped,
    SupplierSearchRequestSignalFilterToJSON,
} from './SupplierSearchRequestSignalFilter';

/**
 * 
 * @export
 * @interface SupplierSearchRequest
 */
export interface SupplierSearchRequest {
    /**
     * 
     * @type {number}
     * @memberof SupplierSearchRequest
     */
    offset?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SupplierSearchRequest
     */
    limit?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SupplierSearchRequest
     */
    sort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplierSearchRequest
     */
    sortOrder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplierSearchRequest
     */
    text?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierSearchRequest
     */
    isSme?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierSearchRequest
     */
    guid?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SupplierSearchRequest
     */
    id?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierSearchRequest
     */
    recordId?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierSearchRequest
     */
    country?: Array<string> | null;
    /**
     * 
     * @type {SupplierSearchRequestSignalFilter}
     * @memberof SupplierSearchRequest
     */
    signals?: SupplierSearchRequestSignalFilter;
    /**
     * 
     * @type {SupplierSearchRequestBuyerFilters}
     * @memberof SupplierSearchRequest
     */
    buyerFilters?: SupplierSearchRequestBuyerFilters;
}

/**
 * Check if a given object implements the SupplierSearchRequest interface.
 */
export function instanceOfSupplierSearchRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplierSearchRequestFromJSON(json: any): SupplierSearchRequest {
    return SupplierSearchRequestFromJSONTyped(json, false);
}

export function SupplierSearchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierSearchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'sortOrder': !exists(json, 'sort_order') ? undefined : json['sort_order'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'isSme': !exists(json, 'is_sme') ? undefined : json['is_sme'],
        'guid': !exists(json, 'guid') ? undefined : json['guid'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'recordId': !exists(json, 'record_id') ? undefined : json['record_id'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'signals': !exists(json, 'signals') ? undefined : SupplierSearchRequestSignalFilterFromJSON(json['signals']),
        'buyerFilters': !exists(json, 'buyer_filters') ? undefined : SupplierSearchRequestBuyerFiltersFromJSON(json['buyer_filters']),
    };
}

export function SupplierSearchRequestToJSON(value?: SupplierSearchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'offset': value.offset,
        'limit': value.limit,
        'sort': value.sort,
        'sort_order': value.sortOrder,
        'text': value.text,
        'is_sme': value.isSme,
        'guid': value.guid,
        'id': value.id,
        'record_id': value.recordId,
        'country': value.country,
        'signals': SupplierSearchRequestSignalFilterToJSON(value.signals),
        'buyer_filters': SupplierSearchRequestBuyerFiltersToJSON(value.buyerFilters),
    };
}

