type SortFunc<T> = (v1: T, v2: T) => number;
type Defined<V> = Exclude<V, null | undefined>;
type Picker<T, V> = (row: T) => V | null | undefined;

function nullAwareSort<T, V>(
  extract: Picker<T, V>,
  compare: SortFunc<Defined<V>>,
  nullsFirst?: boolean,
): SortFunc<T> {
  return (r1, r2) => {
    const v1 = extract(r1);
    const v2 = extract(r2);

    if ((v1 === null || v1 === undefined) && (v2 === null || v2 === undefined)) {
      return 0;
    } else if (v1 === null || v1 === undefined) {
      return nullsFirst ? -1 : 1;
    } else if (v2 === null || v2 === undefined) {
      return nullsFirst ? 1 : -1;
    }

    return compare(v1 as Defined<V>, v2 as Defined<V>);
  };
}

export function stringSort<T>(extract: Picker<T, string>, nullsFirst = true): SortFunc<T> {
  return nullAwareSort(extract, (v1, v2) => v1.localeCompare(v2), nullsFirst);
}

export function numberSort<T>(
  extract: (value: T) => number | null | undefined,
  sortDirection?: "ascend" | "descend" | null | undefined, // sort direction is needed to sort nulls properly
): SortFunc<T> {
  return nullAwareSort(extract, (v1, v2) => v1 - v2, sortDirection === "descend");
}
