/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetNotificationSettingsResponse
 */
export interface GetNotificationSettingsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetNotificationSettingsResponse
     */
    notificationsEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetNotificationSettingsResponse
     */
    sendAppActivityNotifications: boolean;
}

/**
 * Check if a given object implements the GetNotificationSettingsResponse interface.
 */
export function instanceOfGetNotificationSettingsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "notificationsEnabled" in value;
    isInstance = isInstance && "sendAppActivityNotifications" in value;

    return isInstance;
}

export function GetNotificationSettingsResponseFromJSON(json: any): GetNotificationSettingsResponse {
    return GetNotificationSettingsResponseFromJSONTyped(json, false);
}

export function GetNotificationSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetNotificationSettingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notificationsEnabled': json['notifications_enabled'],
        'sendAppActivityNotifications': json['send_app_activity_notifications'],
    };
}

export function GetNotificationSettingsResponseToJSON(value?: GetNotificationSettingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notifications_enabled': value.notificationsEnabled,
        'send_app_activity_notifications': value.sendAppActivityNotifications,
    };
}

