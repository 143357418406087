import React, { HTMLAttributes } from "react";
import styled from "@emotion/styled";

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  onClick?: () => void;
  icon?: React.ReactNode;
  iconOnClick?: () => void;
}

function FilterCollapseBadge({ children, onClick, icon, iconOnClick, ...rest }: BadgeProps) {
  function stopPropagation(e: React.MouseEvent) {
    e.stopPropagation();
    iconOnClick?.();
  }

  return (
    <Wrapper onClick={onClick} {...rest}>
      {children}
      {icon && <IconWrapper onClick={(e) => stopPropagation(e)}>{icon}</IconWrapper>}
    </Wrapper>
  );
}

export default FilterCollapseBadge;

const Wrapper = styled.div(({ theme }) => ({
  width: "fit-content",
  display: "flex",
  alignItems: "center",
  height: "24px",
  padding: "5px 6px 5px 12px",
  gap: "8px",
  backgroundColor: theme.colors.sysInfoSubtle,
  borderRadius: "16px",
  border: `1px solid ${theme.colors.sysInfoDefault}`,
  fontSize: "12px",
  color: theme.colors.sysInfoDefault,
  transition: "all 0.3s ease",
  "&:hover": {
    backgroundColor: theme.colors.sysInfoSubtleAlt,
  },
}));

const IconWrapper = styled.div(({ theme }) => ({
  color: theme.colors.sysInfoDefault,
}));
