import * as React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Tooltip } from "antd5";
import classnames from "classnames";

import css from "./UserInitials.module.scss";

type Props = {
  firstName?: string;
  lastName?: string;
  showTooltip?: boolean;
  className?: string;
  type?: "default" | "small";
};
const colours: string[] = [
  "pink",
  "turquoise",
  "yellow",
  "blue",
  "gold",
  "dark-blue",
  "green",
  "orange",
  "light-green",
  "red",
];

function getUserColour(firstName?: string, lastName?: string) {
  const first = firstName?.charCodeAt(0) ?? 0;
  const second = lastName?.charCodeAt(0) ?? 0;
  const userNumber = first + second;
  const colourIndex = userNumber % colours.length;
  return colours[colourIndex];
}

function UserInitials({
  className,
  showTooltip = true,
  firstName,
  lastName,
  type = "default",
}: Props): JSX.Element {
  const initials = (firstName?.charAt(0) || "") + (lastName?.charAt(0) || "");
  const userColour = React.useMemo(() => getUserColour(firstName, lastName), [firstName, lastName]);
  return (
    <Tooltip title={showTooltip ? `${firstName} ${lastName}` : undefined} trigger="hover">
      <div
        className={classnames(css.assigned, css.userInitialsContainer, className, css[userColour], {
          [css.small]: type === "small",
        })}
      >
        <span>{initials.toUpperCase()}</span>
      </div>
    </Tooltip>
  );
}

export function UnassignedIcon({
  showTooltip = true,
  className,
  type = "default",
}: {
  showTooltip?: boolean;
  className?: string;
  type?: "default" | "small";
}): JSX.Element {
  return (
    <Tooltip title={showTooltip ? "Unassigned" : undefined} trigger="hover">
      <div
        className={classnames(css.unassigned, css.userInitialsContainer, className, {
          [css.small]: type === "small",
        })}
      >
        <UserOutlined />
      </div>
    </Tooltip>
  );
}

export default UserInitials;
