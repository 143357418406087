/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MergeCompanyRequest
 */
export interface MergeCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof MergeCompanyRequest
     */
    sourceCompanyGuid: string;
    /**
     * 
     * @type {string}
     * @memberof MergeCompanyRequest
     */
    targetCompanyGuid: string;
    /**
     * 
     * @type {string}
     * @memberof MergeCompanyRequest
     */
    targetTeamId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MergeCompanyRequest
     */
    selectedUserGuids: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof MergeCompanyRequest
     */
    mergeSeenRecords: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MergeCompanyRequest
     */
    mergeLeadQuals: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MergeCompanyRequest
     */
    mergeLeadSubs: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MergeCompanyRequest
     */
    mergeFeedSettings: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MergeCompanyRequest
     */
    mergeSavedViews: boolean;
}

/**
 * Check if a given object implements the MergeCompanyRequest interface.
 */
export function instanceOfMergeCompanyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sourceCompanyGuid" in value;
    isInstance = isInstance && "targetCompanyGuid" in value;
    isInstance = isInstance && "targetTeamId" in value;
    isInstance = isInstance && "selectedUserGuids" in value;
    isInstance = isInstance && "mergeSeenRecords" in value;
    isInstance = isInstance && "mergeLeadQuals" in value;
    isInstance = isInstance && "mergeLeadSubs" in value;
    isInstance = isInstance && "mergeFeedSettings" in value;
    isInstance = isInstance && "mergeSavedViews" in value;

    return isInstance;
}

export function MergeCompanyRequestFromJSON(json: any): MergeCompanyRequest {
    return MergeCompanyRequestFromJSONTyped(json, false);
}

export function MergeCompanyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergeCompanyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sourceCompanyGuid': json['source_company_guid'],
        'targetCompanyGuid': json['target_company_guid'],
        'targetTeamId': json['target_team_id'],
        'selectedUserGuids': json['selected_user_guids'],
        'mergeSeenRecords': json['merge_seen_records'],
        'mergeLeadQuals': json['merge_lead_quals'],
        'mergeLeadSubs': json['merge_lead_subs'],
        'mergeFeedSettings': json['merge_feed_settings'],
        'mergeSavedViews': json['merge_saved_views'],
    };
}

export function MergeCompanyRequestToJSON(value?: MergeCompanyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source_company_guid': value.sourceCompanyGuid,
        'target_company_guid': value.targetCompanyGuid,
        'target_team_id': value.targetTeamId,
        'selected_user_guids': value.selectedUserGuids,
        'merge_seen_records': value.mergeSeenRecords,
        'merge_lead_quals': value.mergeLeadQuals,
        'merge_lead_subs': value.mergeLeadSubs,
        'merge_feed_settings': value.mergeFeedSettings,
        'merge_saved_views': value.mergeSavedViews,
    };
}

