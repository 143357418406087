import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd5";

// TODO: refactor so input label class isn't needed as its used by the filters.
// eslint-disable-next-line css-modules/no-unused-class
import css from "./FilterWithDisabledPopup.module.scss";

type FilterDisabledProps = {
  filterTitle: React.ReactNode;
  popupTitle: string;
  popupDescription: string;
  filterDisabled: boolean;
  clearFilter: () => void;
  filterComponent: React.ReactNode;
};
/**
 * Puts a popup over a filter component when it is disabled because a conflicting filter is currently actioned (for example we can't filter by buyer lists
 * and singular buyers at the same time because they clash). Allows the user to clear the offending filter to fix this
 * @param param0
 * @returns
 */
export function FilterWithDisabledPopup({
  filterTitle,
  popupTitle,
  popupDescription,
  filterDisabled,
  filterComponent,
  clearFilter,
}: FilterDisabledProps): JSX.Element {
  const [isPopupOpen, setIsPopupOpen] = React.useState(false);

  return (
    <div className={css.inputGroup}>
      {filterTitle}
      <Popover
        trigger="hover"
        placement="bottomLeft"
        open={filterDisabled && isPopupOpen}
        onOpenChange={(open: boolean) => setIsPopupOpen(open)}
        content={
          <div className={css.disabledPopupBody}>
            <h3 className={css.disabledPopupHead}>{popupTitle}</h3>
            <span className={css.disabledPopupTxt}>{popupDescription}</span>
            <div>
              <Button onClick={clearFilter} icon={<CloseOutlined />}>
                Remove filter
              </Button>
            </div>
          </div>
        }
        overlayClassName={css.disabledPopup}
      >
        <div>{filterComponent}</div>
      </Popover>
    </div>
  );
}
