import IntegrationTokenProvider from "lib/providers/IntegrationTokenProvider";
import { Configuration, DefaultApi } from "./generated/integration-api";
import { defineInjectable } from "./reactInjector";

const tokenProvider = new IntegrationTokenProvider();

const apiConfig = new Configuration({
  // locally we need to define the integration service url but in sandbox and prod it's just base
  basePath: window.integration_service_url,
  accessToken: () => tokenProvider.getIntegrationToken(),
});

const WEBAPP_API = new DefaultApi(apiConfig);

const INTEGRATION_API_INJECTABLE = defineInjectable<DefaultApi>("IntegrationApi", () => {
  return WEBAPP_API;
});

export const useIntegrationApi = INTEGRATION_API_INJECTABLE.useInstance;

export const IntegrationAPIProvider = INTEGRATION_API_INJECTABLE.Provider;
