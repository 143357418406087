/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RecordListSectionGenerated } from './RecordListSectionGenerated';
import {
    RecordListSectionGeneratedFromJSON,
    RecordListSectionGeneratedFromJSONTyped,
    RecordListSectionGeneratedToJSON,
} from './RecordListSectionGenerated';
import type { RecordListSectionParameters } from './RecordListSectionParameters';
import {
    RecordListSectionParametersFromJSON,
    RecordListSectionParametersFromJSONTyped,
    RecordListSectionParametersToJSON,
} from './RecordListSectionParameters';

/**
 * 
 * @export
 * @interface RecordListSection
 */
export interface RecordListSection {
    /**
     * 
     * @type {string}
     * @memberof RecordListSection
     */
    type: RecordListSectionTypeEnum;
    /**
     * 
     * @type {RecordListSectionParameters}
     * @memberof RecordListSection
     */
    parameters: RecordListSectionParameters;
    /**
     * 
     * @type {RecordListSectionGenerated}
     * @memberof RecordListSection
     */
    generated: RecordListSectionGenerated;
}


/**
 * @export
 */
export const RecordListSectionTypeEnum = {
    RecordList: 'RECORD_LIST'
} as const;
export type RecordListSectionTypeEnum = typeof RecordListSectionTypeEnum[keyof typeof RecordListSectionTypeEnum];


/**
 * Check if a given object implements the RecordListSection interface.
 */
export function instanceOfRecordListSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "parameters" in value;
    isInstance = isInstance && "generated" in value;

    return isInstance;
}

export function RecordListSectionFromJSON(json: any): RecordListSection {
    return RecordListSectionFromJSONTyped(json, false);
}

export function RecordListSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordListSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'parameters': RecordListSectionParametersFromJSON(json['parameters']),
        'generated': RecordListSectionGeneratedFromJSON(json['generated']),
    };
}

export function RecordListSectionToJSON(value?: RecordListSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'parameters': RecordListSectionParametersToJSON(value.parameters),
        'generated': RecordListSectionGeneratedToJSON(value.generated),
    };
}

