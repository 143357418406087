/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLeadAssigneeRequest
 */
export interface UpdateLeadAssigneeRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateLeadAssigneeRequest
     */
    userGuid: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeadAssigneeRequest
     */
    recordId: string;
}

/**
 * Check if a given object implements the UpdateLeadAssigneeRequest interface.
 */
export function instanceOfUpdateLeadAssigneeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userGuid" in value;
    isInstance = isInstance && "recordId" in value;

    return isInstance;
}

export function UpdateLeadAssigneeRequestFromJSON(json: any): UpdateLeadAssigneeRequest {
    return UpdateLeadAssigneeRequestFromJSONTyped(json, false);
}

export function UpdateLeadAssigneeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeadAssigneeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userGuid': json['user_guid'],
        'recordId': json['record_id'],
    };
}

export function UpdateLeadAssigneeRequestToJSON(value?: UpdateLeadAssigneeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_guid': value.userGuid,
        'record_id': value.recordId,
    };
}

