/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetLeadSubscriptionsStatusResponse
 */
export interface GetLeadSubscriptionsStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof GetLeadSubscriptionsStatusResponse
     */
    status: GetLeadSubscriptionsStatusResponseStatusEnum;
}


/**
 * @export
 */
export const GetLeadSubscriptionsStatusResponseStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;
export type GetLeadSubscriptionsStatusResponseStatusEnum = typeof GetLeadSubscriptionsStatusResponseStatusEnum[keyof typeof GetLeadSubscriptionsStatusResponseStatusEnum];


/**
 * Check if a given object implements the GetLeadSubscriptionsStatusResponse interface.
 */
export function instanceOfGetLeadSubscriptionsStatusResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function GetLeadSubscriptionsStatusResponseFromJSON(json: any): GetLeadSubscriptionsStatusResponse {
    return GetLeadSubscriptionsStatusResponseFromJSONTyped(json, false);
}

export function GetLeadSubscriptionsStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLeadSubscriptionsStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
    };
}

export function GetLeadSubscriptionsStatusResponseToJSON(value?: GetLeadSubscriptionsStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

