/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FieldData
 */
export interface FieldData {
    /**
     * 
     * @type {string}
     * @memberof FieldData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FieldData
     */
    stotlesField: string;
    /**
     * 
     * @type {boolean}
     * @memberof FieldData
     */
    isRequired: boolean;
}

/**
 * Check if a given object implements the FieldData interface.
 */
export function instanceOfFieldData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "stotlesField" in value;
    isInstance = isInstance && "isRequired" in value;

    return isInstance;
}

export function FieldDataFromJSON(json: any): FieldData {
    return FieldDataFromJSONTyped(json, false);
}

export function FieldDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FieldData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'stotlesField': json['stotlesField'],
        'isRequired': json['isRequired'],
    };
}

export function FieldDataToJSON(value?: FieldData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'stotlesField': value.stotlesField,
        'isRequired': value.isRequired,
    };
}

