/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ViewConfigurationFilterSettings } from './ViewConfigurationFilterSettings';
import {
    ViewConfigurationFilterSettingsFromJSON,
    ViewConfigurationFilterSettingsFromJSONTyped,
    ViewConfigurationFilterSettingsToJSON,
} from './ViewConfigurationFilterSettings';
import type { ViewConfigurationSortOrder } from './ViewConfigurationSortOrder';
import {
    ViewConfigurationSortOrderFromJSON,
    ViewConfigurationSortOrderFromJSONTyped,
    ViewConfigurationSortOrderToJSON,
} from './ViewConfigurationSortOrder';
import type { ViewConfigurationTableSettings } from './ViewConfigurationTableSettings';
import {
    ViewConfigurationTableSettingsFromJSON,
    ViewConfigurationTableSettingsFromJSONTyped,
    ViewConfigurationTableSettingsToJSON,
} from './ViewConfigurationTableSettings';

/**
 * 
 * @export
 * @interface ViewConfiguration
 */
export interface ViewConfiguration {
    /**
     * 
     * @type {ViewConfigurationFilterSettings}
     * @memberof ViewConfiguration
     */
    filterSettings: ViewConfigurationFilterSettings;
    /**
     * 
     * @type {ViewConfigurationSortOrder}
     * @memberof ViewConfiguration
     */
    sortOrder: ViewConfigurationSortOrder;
    /**
     * 
     * @type {ViewConfigurationTableSettings}
     * @memberof ViewConfiguration
     */
    tableSettings: ViewConfigurationTableSettings;
}

/**
 * Check if a given object implements the ViewConfiguration interface.
 */
export function instanceOfViewConfiguration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filterSettings" in value;
    isInstance = isInstance && "sortOrder" in value;
    isInstance = isInstance && "tableSettings" in value;

    return isInstance;
}

export function ViewConfigurationFromJSON(json: any): ViewConfiguration {
    return ViewConfigurationFromJSONTyped(json, false);
}

export function ViewConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filterSettings': ViewConfigurationFilterSettingsFromJSON(json['filter_settings']),
        'sortOrder': ViewConfigurationSortOrderFromJSON(json['sort_order']),
        'tableSettings': ViewConfigurationTableSettingsFromJSON(json['table_settings']),
    };
}

export function ViewConfigurationToJSON(value?: ViewConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filter_settings': ViewConfigurationFilterSettingsToJSON(value.filterSettings),
        'sort_order': ViewConfigurationSortOrderToJSON(value.sortOrder),
        'table_settings': ViewConfigurationTableSettingsToJSON(value.tableSettings),
    };
}

