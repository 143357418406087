import React from "react";
import { DatePicker } from "antd5";
import dayjs, { Dayjs } from "dayjs";

import FormItemWrapper from "../FormItemWrapper";

import css from "../formStyles.module.scss";

type DateProps = {
  id?: string;
  label: string;
  required?: boolean;
  value: number;
  updateValue: (value: string | string[]) => void;
  errors: string;
  description?: string;
};

export function Date({
  id,
  label,
  required,
  value,
  updateValue,
  errors,
  description,
}: DateProps): JSX.Element {
  const handleInputChange = (_: Dayjs | null, dateString: string | string[]) => {
    updateValue(dateString);
  };

  return (
    <FormItemWrapper
      formIdentifier="string"
      label={label}
      required={required}
      errors={errors}
      description={description}
    >
      <DatePicker
        id={id}
        defaultValue={value ? dayjs(value, "YYYY-MM-DD") : undefined}
        onChange={handleInputChange}
        className={css.fullWidth}
        style={{ zIndex: "100000" }}
      />
    </FormItemWrapper>
  );
}
