import { ThemeConfig } from "antd5";

import * as colors from "./colors";

export const RelevanceButton: ThemeConfig = {
  components: {
    Button: {
      textHoverBg: colors.grey25,
      colorBgTextActive: colors.grey50,
    },
  },
};
