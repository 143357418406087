/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DocumentReferenceParameters } from './DocumentReferenceParameters';
import {
    DocumentReferenceParametersFromJSON,
    DocumentReferenceParametersFromJSONTyped,
    DocumentReferenceParametersToJSON,
} from './DocumentReferenceParameters';

/**
 * 
 * @export
 * @interface DocumentReference
 */
export interface DocumentReference {
    /**
     * 
     * @type {string}
     * @memberof DocumentReference
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentReference
     */
    reportId?: string;
    /**
     * 
     * @type {DocumentReferenceParameters}
     * @memberof DocumentReference
     */
    parameters: DocumentReferenceParameters;
}

/**
 * Check if a given object implements the DocumentReference interface.
 */
export function instanceOfDocumentReference(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "parameters" in value;

    return isInstance;
}

export function DocumentReferenceFromJSON(json: any): DocumentReference {
    return DocumentReferenceFromJSONTyped(json, false);
}

export function DocumentReferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentReference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'reportId': !exists(json, 'report_id') ? undefined : json['report_id'],
        'parameters': DocumentReferenceParametersFromJSON(json['parameters']),
    };
}

export function DocumentReferenceToJSON(value?: DocumentReference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'report_id': value.reportId,
        'parameters': DocumentReferenceParametersToJSON(value.parameters),
    };
}

