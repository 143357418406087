/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CpvChartSectionGenerated } from './CpvChartSectionGenerated';
import {
    CpvChartSectionGeneratedFromJSON,
    CpvChartSectionGeneratedFromJSONTyped,
    CpvChartSectionGeneratedToJSON,
} from './CpvChartSectionGenerated';
import type { CpvChartSectionParameters } from './CpvChartSectionParameters';
import {
    CpvChartSectionParametersFromJSON,
    CpvChartSectionParametersFromJSONTyped,
    CpvChartSectionParametersToJSON,
} from './CpvChartSectionParameters';

/**
 * 
 * @export
 * @interface CpvChartSection
 */
export interface CpvChartSection {
    /**
     * 
     * @type {string}
     * @memberof CpvChartSection
     */
    type: CpvChartSectionTypeEnum;
    /**
     * 
     * @type {CpvChartSectionParameters}
     * @memberof CpvChartSection
     */
    parameters: CpvChartSectionParameters;
    /**
     * 
     * @type {CpvChartSectionGenerated}
     * @memberof CpvChartSection
     */
    generated: CpvChartSectionGenerated;
}


/**
 * @export
 */
export const CpvChartSectionTypeEnum = {
    CpvChart: 'CPV_CHART'
} as const;
export type CpvChartSectionTypeEnum = typeof CpvChartSectionTypeEnum[keyof typeof CpvChartSectionTypeEnum];


/**
 * Check if a given object implements the CpvChartSection interface.
 */
export function instanceOfCpvChartSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "parameters" in value;
    isInstance = isInstance && "generated" in value;

    return isInstance;
}

export function CpvChartSectionFromJSON(json: any): CpvChartSection {
    return CpvChartSectionFromJSONTyped(json, false);
}

export function CpvChartSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CpvChartSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'parameters': CpvChartSectionParametersFromJSON(json['parameters']),
        'generated': CpvChartSectionGeneratedFromJSON(json['generated']),
    };
}

export function CpvChartSectionToJSON(value?: CpvChartSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'parameters': CpvChartSectionParametersToJSON(value.parameters),
        'generated': CpvChartSectionGeneratedToJSON(value.generated),
    };
}

