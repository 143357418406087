/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyerListDto } from './BuyerListDto';
import {
    BuyerListDtoFromJSON,
    BuyerListDtoFromJSONTyped,
    BuyerListDtoToJSON,
} from './BuyerListDto';

/**
 * 
 * @export
 * @interface GetBuyerListsResponse
 */
export interface GetBuyerListsResponse {
    /**
     * 
     * @type {Array<BuyerListDto>}
     * @memberof GetBuyerListsResponse
     */
    buyerLists: Array<BuyerListDto>;
}

/**
 * Check if a given object implements the GetBuyerListsResponse interface.
 */
export function instanceOfGetBuyerListsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "buyerLists" in value;

    return isInstance;
}

export function GetBuyerListsResponseFromJSON(json: any): GetBuyerListsResponse {
    return GetBuyerListsResponseFromJSONTyped(json, false);
}

export function GetBuyerListsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBuyerListsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buyerLists': ((json['buyer_lists'] as Array<any>).map(BuyerListDtoFromJSON)),
    };
}

export function GetBuyerListsResponseToJSON(value?: GetBuyerListsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buyer_lists': ((value.buyerLists as Array<any>).map(BuyerListDtoToJSON)),
    };
}

