import linkify from "linkify-it";

import { SimpleUserDto } from "lib/generated/app-api";
import { MatchType, TextHighlighterMatch } from "./core_components/TextMatchHighlighter";

export const getDisplayUserName = (user: SimpleUserDto): string =>
  `${user.firstName} ${user.lastName}`;

const getFullDisplayUserName = (user: SimpleUserDto): string => `@${getDisplayUserName(user)}`;

const getMarkdownName = (user: SimpleUserDto): string =>
  `@[${user.firstName} ${user.lastName}](${user.guid})`;

const COMMENT_MENTIONS_REGEX = /@\[(.*?)\]\((.*?)\)/g;

/**
 * Quick and dirty basic mark down for comments, simply uses basic regex to find and replace usernames
 *
 * Converts the content displayed to the user into something that can be stored in the database
 * convertTextToMarkdown is the reverse of convertMarkdownToText.
 *
 * convertTextToMarkdown => "@John Doe comment data" => "@[John Doe](user-guid) comment data"
 * @param content
 * @param users
 * @returns
 */
export function convertTextToMarkdown(content: string, users?: SimpleUserDto[]): string {
  let databaseContent = content;

  users?.forEach((user) => {
    databaseContent = databaseContent.replace(getFullDisplayUserName(user), getMarkdownName(user));
  });

  return databaseContent;
}

/**
 * Converts markdown content stored in the database to something that can be displayed to the user
 *
 * convertMarkdownToText => "@[John Doe](user-guid) comment data" => "@John Doe comment data"
 *
 * @param databaseContent
 * @param users
 * @returns
 */
export function convertMarkdownToText(databaseContent: string): string {
  function replacer(_: string, name: string) {
    return `@${name}`;
  }

  const content = databaseContent.replace(COMMENT_MENTIONS_REGEX, replacer);

  return content;
}

export function getMentionDetails(
  match: string,
  users: SimpleUserDto[],
): { name: string; email: string } | undefined {
  const details = match.match(/\((.*)\)/);

  if (details) {
    const guid = details[1];

    const user = users.find((u) => u.guid === guid);
    if (user) {
      return {
        email: user.email,
        name: getDisplayUserName(user),
      };
    }
  }
  return;
}

export function extractMentionRegions(databaseContent: string): TextHighlighterMatch[] {
  let match;
  const matchData: TextHighlighterMatch[] = [];
  while ((match = COMMENT_MENTIONS_REGEX.exec(databaseContent))) {
    matchData.push({
      start: match.index,
      end: match.index + match[0].length,
      matchType: MatchType.Mention,
    });
  }

  return matchData;
}

export function extractLinkRegions(databaseContent: string): TextHighlighterMatch[] {
  let matches: TextHighlighterMatch[] = [];
  const commentLinks = linkify();

  const linkMatches = commentLinks.match(databaseContent)?.map((m) => ({
    start: m.index,
    end: m.lastIndex,
    keyword: m.text,
    matchType: MatchType.Link,
  }));

  if (linkMatches) {
    matches = matches.concat(linkMatches);
  }

  const mentionsMatches = extractMentionRegions(databaseContent);
  matches = matches.concat(mentionsMatches);

  return matches;
}
