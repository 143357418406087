import * as React from "react";
import classNames from "classnames";

import {
  convertRecordFiltersToNoticeFilters,
  generateNoticeSearchUrl,
} from "components/notices/utils";
import { RecordFilters } from "components/record_search/types";
import { ProHelperDataTypes, useProHelper } from "lib/providers/ProHelper";
import { useSubscription } from "lib/providers/Subscription";
import { BuyerCpvStats, BuyerCpvStatsPerCode } from "lib/StotlesApi";
import { CpvMiniChart } from "./CpvMiniChart";

import css from "./CpvSummary.module.scss";

type CpvSummaryProps = {
  overallStats: BuyerCpvStats;
  perCpvStats: BuyerCpvStatsPerCode[];
  buyerGuid?: string;
  buyerId?: number;
  dateFilter: Pick<RecordFilters, "expiryDate" | "publishDate">;
  requiredSlideoutType?: ProHelperDataTypes;
};

export function CpvSummary({
  overallStats,
  perCpvStats,
  buyerGuid,
  buyerId,
  dateFilter,
  requiredSlideoutType,
}: CpvSummaryProps): JSX.Element {
  const subscription = useSubscription();
  const canViewAdvancedData = requiredSlideoutType
    ? subscription.hasDataTypes(requiredSlideoutType)
    : true;
  const { checkSubscription } = useProHelper();

  const showSlideout = React.useCallback(() => {
    if (requiredSlideoutType) {
      checkSubscription(requiredSlideoutType);
    }
  }, [checkSubscription, requiredSlideoutType]);
  return (
    <table className={css.summary}>
      <tbody>
        <tr>
          <td>
            <div className={css.totals}>
              <StatsDetails
                description="Number of contracts and frameworks awarded"
                value={overallStats.total_awarded.toLocaleString("en-GB")}
                icon={<Square colour="mid-blue" />}
              />
              <StatsDetails
                description="Total contract and framework value (GBP)"
                value={`£${overallStats.total_value.toLocaleString("en-GB")}`}
                unit="GBP"
                icon={<Square colour="light-blue" />}
              />
              <StatsDetails
                description="Average contract and framework value"
                value={`£${overallStats.avg_contract_value?.toLocaleString("en-GB")}`}
                unit="GBP"
              />
              <StatsDetails
                description="Reported % of contract and framework value awarded to SMEs"
                value={`${overallStats.percent_to_smes?.toFixed(1) || "0.0"}%`}
              />
            </div>
          </td>
          <td>
            <div
              className={css.cpvCodeStats}
              onClick={canViewAdvancedData ? undefined : showSlideout}
            >
              {perCpvStats.map((c, i) => {
                const params: RecordFilters = {
                  cpvDimensions: [c.code.substr(0, 2)],
                  searchMode: "AWARDS",
                  ...dateFilter,
                };
                if (buyerGuid) {
                  params.buyerGuids = [buyerGuid];
                }
                // TODO: Remove buyerId once reports have been backfilled to use buyer guids
                // Currently we use guids on the buyer profile but ids in the reports for this
                if (buyerId) {
                  params.buyerIds = [buyerId];
                }
                const path = generateNoticeSearchUrl(
                  convertRecordFiltersToNoticeFilters(params),
                  undefined,
                );
                return (
                  <CpvMiniChart
                    cpvCode={c.code}
                    matchingRecordsCount={c.total_awarded}
                    matchingRecordsValue={c.total_value}
                    totalRecordsCount={overallStats.total_awarded}
                    totalRecordsValue={overallStats.total_value}
                    description={c.name}
                    link={canViewAdvancedData ? path : undefined}
                    key={i}
                  />
                );
              })}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

type StatsDetailProps = {
  description: string;
  value: string;
  unit?: string;
  icon?: React.ReactNode;
};

function StatsDetails({ description, value, unit, icon }: StatsDetailProps): JSX.Element {
  return (
    <div className={css.statsDetail}>
      <div className={css.description}>{description}</div>
      <div className={css.valueContainer}>
        {icon && <span>{icon}</span>}
        <span className={css.value}>
          {value} <span className={css.unit}>{unit}</span>
        </span>
      </div>
    </div>
  );
}

type SquareProps = {
  colour: "mid-blue" | "light-blue";
};

function Square({ colour }: SquareProps) {
  return <div className={classNames(css.square, css[colour])} />;
}
