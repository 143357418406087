/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ViewConfiguration } from './ViewConfiguration';
import {
    ViewConfigurationFromJSON,
    ViewConfigurationFromJSONTyped,
    ViewConfigurationToJSON,
} from './ViewConfiguration';

/**
 * 
 * @export
 * @interface SavedViewType
 */
export interface SavedViewType {
    /**
     * 
     * @type {string}
     * @memberof SavedViewType
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SavedViewType
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SavedViewType
     */
    ownerId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SavedViewType
     */
    teamId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SavedViewType
     */
    companyId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SavedViewType
     */
    isStandardView: boolean;
    /**
     * 
     * @type {ViewConfiguration}
     * @memberof SavedViewType
     */
    view: ViewConfiguration;
    /**
     * 
     * @type {string}
     * @memberof SavedViewType
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SavedViewType
     */
    updatedAt?: Date | null;
}

/**
 * Check if a given object implements the SavedViewType interface.
 */
export function instanceOfSavedViewType(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isStandardView" in value;
    isInstance = isInstance && "view" in value;

    return isInstance;
}

export function SavedViewTypeFromJSON(json: any): SavedViewType {
    return SavedViewTypeFromJSONTyped(json, false);
}

export function SavedViewTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SavedViewType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'ownerId': !exists(json, 'owner_id') ? undefined : json['owner_id'],
        'teamId': !exists(json, 'team_id') ? undefined : json['team_id'],
        'companyId': !exists(json, 'company_id') ? undefined : json['company_id'],
        'isStandardView': json['is_standard_view'],
        'view': ViewConfigurationFromJSON(json['view']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'updatedAt': !exists(json, 'updated_at') ? undefined : (json['updated_at'] === null ? null : new Date(json['updated_at'])),
    };
}

export function SavedViewTypeToJSON(value?: SavedViewType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'owner_id': value.ownerId,
        'team_id': value.teamId,
        'company_id': value.companyId,
        'is_standard_view': value.isStandardView,
        'view': ViewConfigurationToJSON(value.view),
        'description': value.description,
        'updated_at': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
    };
}

