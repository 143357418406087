/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyerCategoryAssignmentDto } from './BuyerCategoryAssignmentDto';
import {
    BuyerCategoryAssignmentDtoFromJSON,
    BuyerCategoryAssignmentDtoFromJSONTyped,
    BuyerCategoryAssignmentDtoToJSON,
} from './BuyerCategoryAssignmentDto';
import type { BuyersRelationshipStatsResponseSignalStatsStats } from './BuyersRelationshipStatsResponseSignalStatsStats';
import {
    BuyersRelationshipStatsResponseSignalStatsStatsFromJSON,
    BuyersRelationshipStatsResponseSignalStatsStatsFromJSONTyped,
    BuyersRelationshipStatsResponseSignalStatsStatsToJSON,
} from './BuyersRelationshipStatsResponseSignalStatsStats';
import type { Signal } from './Signal';
import {
    SignalFromJSON,
    SignalFromJSONTyped,
    SignalToJSON,
} from './Signal';

/**
 * 
 * @export
 * @interface BuyersRelationshipStatsResponseSignalStats
 */
export interface BuyersRelationshipStatsResponseSignalStats {
    /**
     * 
     * @type {string}
     * @memberof BuyersRelationshipStatsResponseSignalStats
     */
    buyerGuid: string;
    /**
     * 
     * @type {string}
     * @memberof BuyersRelationshipStatsResponseSignalStats
     */
    buyerName: string;
    /**
     * 
     * @type {BuyersRelationshipStatsResponseSignalStatsStats}
     * @memberof BuyersRelationshipStatsResponseSignalStats
     */
    stats: BuyersRelationshipStatsResponseSignalStatsStats;
    /**
     * 
     * @type {Array<Signal>}
     * @memberof BuyersRelationshipStatsResponseSignalStats
     */
    signals: Array<Signal>;
    /**
     * 
     * @type {Array<BuyerCategoryAssignmentDto>}
     * @memberof BuyersRelationshipStatsResponseSignalStats
     */
    categories: Array<BuyerCategoryAssignmentDto>;
}

/**
 * Check if a given object implements the BuyersRelationshipStatsResponseSignalStats interface.
 */
export function instanceOfBuyersRelationshipStatsResponseSignalStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "buyerGuid" in value;
    isInstance = isInstance && "buyerName" in value;
    isInstance = isInstance && "stats" in value;
    isInstance = isInstance && "signals" in value;
    isInstance = isInstance && "categories" in value;

    return isInstance;
}

export function BuyersRelationshipStatsResponseSignalStatsFromJSON(json: any): BuyersRelationshipStatsResponseSignalStats {
    return BuyersRelationshipStatsResponseSignalStatsFromJSONTyped(json, false);
}

export function BuyersRelationshipStatsResponseSignalStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyersRelationshipStatsResponseSignalStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buyerGuid': json['buyer_guid'],
        'buyerName': json['buyer_name'],
        'stats': BuyersRelationshipStatsResponseSignalStatsStatsFromJSON(json['stats']),
        'signals': ((json['signals'] as Array<any>).map(SignalFromJSON)),
        'categories': ((json['categories'] as Array<any>).map(BuyerCategoryAssignmentDtoFromJSON)),
    };
}

export function BuyersRelationshipStatsResponseSignalStatsToJSON(value?: BuyersRelationshipStatsResponseSignalStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buyer_guid': value.buyerGuid,
        'buyer_name': value.buyerName,
        'stats': BuyersRelationshipStatsResponseSignalStatsStatsToJSON(value.stats),
        'signals': ((value.signals as Array<any>).map(SignalToJSON)),
        'categories': ((value.categories as Array<any>).map(BuyerCategoryAssignmentDtoToJSON)),
    };
}

