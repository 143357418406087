/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer } from './BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer';
import {
    BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerFromJSON,
    BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerFromJSONTyped,
    BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerToJSON,
} from './BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer';

/**
 * 
 * @export
 * @interface BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter
 */
export interface BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter {
    /**
     * 
     * @type {BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer}
     * @memberof BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter
     */
    keywords?: BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer;
    /**
     * 
     * @type {BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer}
     * @memberof BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter
     */
    competitors?: BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer;
    /**
     * 
     * @type {BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer}
     * @memberof BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter
     */
    partners?: BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer;
    /**
     * 
     * @type {BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer}
     * @memberof BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter
     */
    cpvCodes?: BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer;
    /**
     * 
     * @type {BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer}
     * @memberof BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter
     */
    buyers?: BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer;
}

/**
 * Check if a given object implements the BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter interface.
 */
export function instanceOfBuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterFromJSON(json: any): BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter {
    return BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterFromJSONTyped(json, false);
}

export function BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'keywords': !exists(json, 'keywords') ? undefined : BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerFromJSON(json['keywords']),
        'competitors': !exists(json, 'competitors') ? undefined : BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerFromJSON(json['competitors']),
        'partners': !exists(json, 'partners') ? undefined : BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerFromJSON(json['partners']),
        'cpvCodes': !exists(json, 'cpv_codes') ? undefined : BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerFromJSON(json['cpv_codes']),
        'buyers': !exists(json, 'buyers') ? undefined : BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerFromJSON(json['buyers']),
    };
}

export function BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterToJSON(value?: BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'keywords': BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerToJSON(value.keywords),
        'competitors': BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerToJSON(value.competitors),
        'partners': BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerToJSON(value.partners),
        'cpv_codes': BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerToJSON(value.cpvCodes),
        'buyers': BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerToJSON(value.buyers),
    };
}

