/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendContactRequest
 */
export interface SendContactRequest {
    /**
     * 
     * @type {string}
     * @memberof SendContactRequest
     */
    contactCompanyName: string;
    /**
     * 
     * @type {string}
     * @memberof SendContactRequest
     */
    contactId: string;
    /**
     * 
     * @type {string}
     * @memberof SendContactRequest
     */
    salutation?: string;
    /**
     * 
     * @type {string}
     * @memberof SendContactRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof SendContactRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof SendContactRequest
     */
    emailAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof SendContactRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof SendContactRequest
     */
    jobTitle?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SendContactRequest
     */
    jobFunction?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SendContactRequest
     */
    seniority?: string;
}

/**
 * Check if a given object implements the SendContactRequest interface.
 */
export function instanceOfSendContactRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "contactCompanyName" in value;
    isInstance = isInstance && "contactId" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;

    return isInstance;
}

export function SendContactRequestFromJSON(json: any): SendContactRequest {
    return SendContactRequestFromJSONTyped(json, false);
}

export function SendContactRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendContactRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contactCompanyName': json['contactCompanyName'],
        'contactId': json['contactId'],
        'salutation': !exists(json, 'salutation') ? undefined : json['salutation'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'jobFunction': !exists(json, 'jobFunction') ? undefined : json['jobFunction'],
        'seniority': !exists(json, 'seniority') ? undefined : json['seniority'],
    };
}

export function SendContactRequestToJSON(value?: SendContactRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contactCompanyName': value.contactCompanyName,
        'contactId': value.contactId,
        'salutation': value.salutation,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'emailAddress': value.emailAddress,
        'phoneNumber': value.phoneNumber,
        'jobTitle': value.jobTitle,
        'jobFunction': value.jobFunction,
        'seniority': value.seniority,
    };
}

