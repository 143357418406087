import React from "react";
import { Button, message } from "antd5";

import { ButtonLink } from "components/actions/Links";
import { FeedbackModal } from "components/modals/FeedbackModal";
import PaywallPopover from "components/paywall/PaywallPopover";
import { ProductTourCompletionStateResponseTourStateTourIdentifierEnum } from "lib/generated/app-api";
import { useDescribeContactDetails } from "lib/hooks/api/integrations/useContactDetails";
import { useFindIntegrationProvider } from "lib/hooks/api/integrations/useFindIntegrationProvider";
import { useSendContact } from "lib/hooks/api/integrations/useSendContact";
import { useProductTour } from "lib/hooks/useProductTour";
import { useDialogManager } from "lib/providers/DialogManager";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { EventNames, useTracking } from "lib/tracking";
import { ContactResult } from "../../lib/hooks/api/contacts/useContacts";
import { ContactSentToCRMTourModal } from "./ContactSentToCRMTourModal";
import { ConnectToCRMButton } from "./ProviderButtons";
import { friendlyProviderName, getIntegrationIcon } from "./util";

type Props = {
  contact: ContactResult;
  showingTour: boolean;
};

export function SendContactToCRM({ contact, showingTour }: Props) {
  const { data: providerTypeResponse } = useFindIntegrationProvider();
  const providerType = providerTypeResponse?.providerType;
  const { data: extraContactData } = useDescribeContactDetails(contact.id, {
    enabled: !!providerType,
  });
  const { logEvent } = useTracking();
  const dialogManager = useDialogManager();

  const { checkSubscription, authorised: hasContacts } = useCheckSubscription("CONTACTS", {
    "Context source": "CRM button",
  });

  // Disabling contacts CTAs like viewing or sending contacts via CRM.
  // Not disabling connect to CRM when user doesn't have contacts because we always
  // want them to do this (unless there's an ongoing tour)
  const disableContactsCta = !hasContacts || showingTour || !!window.guestUser;

  const { tourOpen: hasNotSeenIntroTour, permanentlyHide } = useProductTour(
    ProductTourCompletionStateResponseTourStateTourIdentifierEnum.CrmContactSendIntro,
  );

  const { tourOpen: hasNotSeenFeedbackOption, permanentlyHide: permanentlyHideFeedback } =
    useProductTour(
      ProductTourCompletionStateResponseTourStateTourIdentifierEnum.CrmContactSendFeedback,
    );

  const trackingAttributes = {
    "Connected provider": providerType,
    "Data types": "Contact",
    "Contact function": contact.jobFunction,
    "Contact job title": contact.jobTitle,
    "Contact seniority": contact.seniority,
    "Buyer name": contact.buyer?.name,
  };

  const { mutate: sendContact, isLoading } = useSendContact({
    onSuccess: (data, _variables, _context) => {
      if (!providerType) {
        return;
      }
      logEvent(EventNames.objectSentToCRM, trackingAttributes);
      void message.open({
        type: data.isDuplicate ? "warning" : "success",
        content: (
          <span>
            {data.isDuplicate
              ? `Contact already exists in your ${friendlyProviderName[providerType]} account`
              : `Sent to ${friendlyProviderName[providerType]} `}

            {"  "}
            {data.externalUrl && (
              <ButtonLink to={data.externalUrl} targetType="new-tab" style={{ marginLeft: "8px" }}>
                {getIntegrationIcon(providerType)} View
              </ButtonLink>
            )}
          </span>
        ),
      });
      if (hasNotSeenIntroTour) {
        void dialogManager.openDialog(ContactSentToCRMTourModal, {
          contactId: contact.id,
          providerType,
          dialogManager,
          permanentlyHide,
          externalUrl: data.externalUrl ?? "#",
        });

        // If user has not seen the option to give feedback,
        // but has seen the initial congrats modal - then ask for feedback
      } else if (hasNotSeenFeedbackOption) {
        void dialogManager.openDialog(FeedbackModal, {
          tourIdentifier:
            ProductTourCompletionStateResponseTourStateTourIdentifierEnum.CrmContactSendFeedback,
          title: "You pushed a contact to your CRM! How did it go?",
          permanentlyHide: permanentlyHideFeedback,
        });
      }
    },
    onError() {
      void message.error("Failed to send contact");
    },
  });

  if (!providerType) return <ConnectToCRMButton showingTour={showingTour} />;

  if (extraContactData?.externalUrl) {
    return (
      <ButtonLink
        to={disableContactsCta ? "" : extraContactData.externalUrl}
        onClick={(e) => {
          if (showingTour) {
            e.preventDefault();
          } else {
            checkSubscription();
          }
        }}
        targetType="new-tab"
      >
        {getIntegrationIcon(providerType)} View
      </ButtonLink>
    );
  }

  return (
    <PaywallPopover
      featureType={window.guestUser ? "GUEST_ACCESS_UPGRADE" : "CONTACTS"}
      contextSource="CRM button"
    >
      <Button
        onClick={() => {
          if (!disableContactsCta) {
            logEvent(EventNames.sendToCRMClicked, trackingAttributes);
            sendContact({
              salutation: contact.title,
              firstName: contact.firstName,
              contactCompanyName: contact.buyer?.name || "",
              jobTitle: contact.jobTitle,
              jobFunction: contact.jobFunction,
              seniority: contact.seniority,
              lastName: contact.lastName,
              contactId: contact.id,
              emailAddress: contact.email || "",
              phoneNumber: contact.phone || "",
            });
          }
        }}
        disabled={isLoading}
      >
        {getIntegrationIcon(providerType)} Send
      </Button>
    </PaywallPopover>
  );
}
