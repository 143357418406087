import * as React from "react";
import { ConfigProvider, Dropdown, Menu, Tooltip, Tree } from "antd5";
import classnames from "classnames";

import { SignalFiltersData } from "components/onboarding/onboardingUtils";
import {
  FeedPreviewPageSources,
  PreviewSignalFilters,
  SignalFilterCategoryData,
} from "components/onboarding/SharedOnboarding";
import { getCountLabel, validateTerm } from "lib/previewQueryUtils";
import { ShadowlessDropdown } from "lib/themes/ShadowlessDropdown";
import * as tracking from "lib/tracking";
import { SignalCategory } from "../../lib/StotlesApi";

import css from "./RecordPreviewFilterDropdown.module.scss";

const { TreeNode } = Tree;

type PreviewTreeFilterProps = {
  signalFilterCategoryData: SignalFilterCategoryData;
  handleFilterCheck: (filterObject: PreviewSignalFilters) => void;
  filterType: string;
  // This is a controlled filter, we keep track of and pass in values of checked boxes
  checkedKeys: string[];
  pageSource: FeedPreviewPageSources;
};

function PreviewTreeFilter({
  signalFilterCategoryData: filterDataSet,
  handleFilterCheck,
  filterType,
  checkedKeys,
  pageSource,
}: PreviewTreeFilterProps) {
  const handleCheck = React.useCallback(
    (ck) => {
      tracking.logEvent(tracking.EventNames.feedPreviewFiltered, {
        "Page source": pageSource,
        "Context source": "Tag filter dropdown",
        "Checked keys": ck,
        "Type of item being filtered": filterType,
      });
      handleFilterCheck({ [filterType]: ck as string[] });
    },
    [pageSource, filterType, handleFilterCheck],
  );
  return (
    <Tree
      className={css.recordPreviewFilter}
      selectable={false}
      checkedKeys={checkedKeys}
      onCheck={handleCheck}
      multiple
      checkable
      defaultExpandAll
    >
      <TreeNode
        key={`parent: ${filterType}`}
        title={
          <span className={classnames(`tag-${filterDataSet.colour || "default"}`, css.dropdownTag)}>
            {filterDataSet.title}
          </span>
        }
      >
        {filterDataSet &&
          filterDataSet.signals.map((signal) => {
            const filter = signal.name;
            const count = filterDataSet.counts?.[filter];
            const warning = filterType === "keywords" ? validateTerm(count, filter) : undefined;
            const renderCountLabel = getCountLabel({ count, value: filter, warning });
            return (
              <TreeNode
                key={filter}
                title={
                  <span
                    className={classnames(
                      `tag-${filterDataSet.colour || "default"}`,
                      css.dropdownTag,
                      {
                        [css.warningTag]: warning,
                      },
                    )}
                  >
                    <Tooltip placement="top" title={warning} overlayClassName={css.filterTooltip}>
                      {renderCountLabel}
                    </Tooltip>
                  </span>
                }
              />
            );
          })}
      </TreeNode>
    </Tree>
  );
}

type Props = {
  children: React.ReactNode;
  signalFiltersData: SignalFiltersData;
  handleFilterCheck: (filterObject: PreviewSignalFilters) => void;
  checkedKeys: PreviewSignalFilters;
  pageSource: FeedPreviewPageSources;
  open: boolean;
  setOpen: (visible: boolean) => void;
};

function RecordPreviewFilterDropdown({
  children,
  signalFiltersData: filterDataSets,
  handleFilterCheck,
  checkedKeys,
  pageSource,
  open,
  setOpen,
}: Props): JSX.Element {
  const previewFilter = React.useMemo(
    () => (
      // Because we're passing it to Dropdown we have to wrap it in a menu
      <Menu>
        {/* We render one filter per filterDataSet because they filter the set in different ways */}
        {/* They're only displayed together in the UI */}
        {(Object.entries(filterDataSets) as [SignalCategory, SignalFilterCategoryData][])
          .filter(([_, dataSet]) => !!dataSet)
          .map(([filterType, dataSet]) => (
            <PreviewTreeFilter
              key={filterType}
              filterType={filterType}
              signalFilterCategoryData={dataSet as SignalFilterCategoryData}
              handleFilterCheck={handleFilterCheck}
              checkedKeys={checkedKeys[filterType] as string[]}
              pageSource={pageSource}
            />
          ))}
      </Menu>
    ),
    [filterDataSets, handleFilterCheck, checkedKeys, pageSource],
  );
  return (
    <ConfigProvider theme={ShadowlessDropdown}>
      <Dropdown
        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
        trigger={["click"]}
        dropdownRender={() => previewFilter}
        overlayClassName={css.recordPreviewFilterDropdown}
        open={open}
        onOpenChange={(open) => {
          if (open) {
            tracking.logEvent(tracking.EventNames.feedPreviewFilterButtonClicked, {
              "Page source": pageSource,
              "Context source": "Tag filter dropdown",
            });
          }

          setOpen(open);
        }}
      >
        {children}
      </Dropdown>
    </ConfigProvider>
  );
}

export default RecordPreviewFilterDropdown;
