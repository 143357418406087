import { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  ExportContactsMutation,
  ExportContactsMutationVariables,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const exportContacts = graphql(`
  mutation exportContacts(
    $filters: SearchContactsRequest!
    $exportFormat: ExportFormatArgs!
    $selectedContacts: SelectedContacts!
  ) {
    exportContacts(
      SearchContactsRequest: $filters
      ExportFormat: $exportFormat
      SelectedContacts: $selectedContacts
    ) {
      id
    }
  }
`);

type UseContactExport = UseMutationResult<
  ExportContactsMutation["exportContacts"],
  unknown,
  ExportContactsMutationVariables,
  unknown
>;

export function useContactExport(
  options?: UseMutationOptions<
    ExportContactsMutation,
    unknown,
    ExportContactsMutationVariables,
    unknown
  >,
): UseContactExport {
  const { data, ...rest } = useGraphQlMutation(exportContacts, options);
  return { data: data?.exportContacts, ...rest } as unknown as UseContactExport;
}
