/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MarkAsCompleteRequest
 */
export interface MarkAsCompleteRequest {
    /**
     * 
     * @type {string}
     * @memberof MarkAsCompleteRequest
     */
    tourIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof MarkAsCompleteRequest
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof MarkAsCompleteRequest
     */
    completedAt: Date;
}

/**
 * Check if a given object implements the MarkAsCompleteRequest interface.
 */
export function instanceOfMarkAsCompleteRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tourIdentifier" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "completedAt" in value;

    return isInstance;
}

export function MarkAsCompleteRequestFromJSON(json: any): MarkAsCompleteRequest {
    return MarkAsCompleteRequestFromJSONTyped(json, false);
}

export function MarkAsCompleteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarkAsCompleteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tourIdentifier': json['tour_identifier'],
        'userId': json['user_id'],
        'completedAt': (new Date(json['completed_at'])),
    };
}

export function MarkAsCompleteRequestToJSON(value?: MarkAsCompleteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tour_identifier': value.tourIdentifier,
        'user_id': value.userId,
        'completed_at': (value.completedAt.toISOString()),
    };
}

