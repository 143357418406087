/* eslint no-restricted-imports: ["error", { patterns: [{
    group: ["*"],
    message: "Please consider the importance of this import, and add exceptions if really necessary"
}]}]
--------
This module is used for landing pages, which need to be very light and minimal on imports. If you
need to change some functionality here, consider looking at lib/utils.ts
*/

export type ClipboardContent = {
  plain?: string;
  html?: string;
};

const setClipboardContents = (event: ClipboardEvent, content: ClipboardContent) => {
  if (event.clipboardData) {
    for (const [format, data] of Object.entries(content)) {
      if (data) {
        event.clipboardData.setData(`text/${format}`, data);
      }
    }
  }
  event.preventDefault();
};

export function copyToClipboard(content: ClipboardContent): boolean {
  const copyListener = (e: ClipboardEvent) => setClipboardContents(e, content);
  let copied = false;

  // this may be overkill as `copy` should not be problematic, but it is nice if the caller
  // of this function can have feedback that copying was successful
  try {
    document.addEventListener<"copy">("copy", copyListener);
    copied = document.execCommand("copy");
  } catch (e) {
    copied = false;
  } finally {
    document.removeEventListener("copy", copyListener);
  }

  return copied;
}
