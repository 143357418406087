import React, { ReactNode } from "react";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { Content, Header, Item, Root, Trigger } from "@radix-ui/react-accordion";

export type CollapseItem = {
  header: ReactNode;
  content: ReactNode;
  key: string;
};

interface CollapseGroupProps {
  items: CollapseItem[];
  value?: string[];
  onChange?: (value: string[]) => void;
}

function CollapseGroup({ items, value, onChange }: CollapseGroupProps) {
  return (
    <Root type="multiple" value={value} onValueChange={onChange}>
      {items &&
        items.map((item) => (
          <StyledItem key={item.key} value={item.key}>
            <StyledHeader>
              <StyledTrigger>
                {item.header}
                <PlusWrapper>
                  <HorizontalLine />
                  <VerticalLine className="verticalLine" />
                </PlusWrapper>
              </StyledTrigger>
            </StyledHeader>
            <StyledContent>{item.content}</StyledContent>
          </StyledItem>
        ))}
    </Root>
  );
}

export default CollapseGroup;

const StyledItem = styled(Item)(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.sysBorderPrimary}`,
}));

const HorizontalLine = styled.div(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: "1.5px",
  borderRadius: "2px",
  backgroundColor: theme.colors.sysTextPlaceholder,
}));

const VerticalLine = styled.div(({ theme }) => ({
  position: "absolute",
  width: "1.5px",
  height: "94%",
  borderRadius: "2px",
  backgroundColor: theme.colors.sysTextPlaceholder,
  transition: "rotate 300ms cubic-bezier(0.87, 0, 0.13, 1)",
}));

const StyledTrigger = styled(Trigger)(({ theme }) => ({
  appearance: "none",
  background: "none",
  border: "none",
  margin: 0,
  font: "inherit",
  color: "inherit",
  textAlign: "inherit",
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "24px",
  fontSize: "14px",
  cursor: "pointer",
  transition: "background-color 0.3s ease",

  "&:hover": {
    backgroundColor: theme.colors.sysBackgroundAlternative,
  },

  '[data-state="open"] &': {
    ".verticalLine": {
      rotate: "-90deg",
    },
  },
}));

const StyledHeader = styled(Header)({
  display: "flex",
  width: "100%",
  margin: 0,
});

const slideDown = keyframes({
  from: {
    height: "0",
  },
  to: {
    height: "var(--radix-accordion-content-height)",
  },
});

const slideUp = keyframes({
  from: {
    height: "var(--radix-accordion-content-height)",
  },
  to: {
    height: "0",
  },
});

const StyledContent = styled(Content)({
  overflow: "hidden",

  "&[data-state='open']": {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },

  "&[data-state='closed']": {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

const PlusWrapper = styled.div({
  position: "relative",
  width: "12px",
  height: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
