/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordSearchConfigRecordFilterFrameworks
 */
export interface RecordSearchConfigRecordFilterFrameworks {
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilterFrameworks
     */
    ids?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecordSearchConfigRecordFilterFrameworks
     */
    frameworkRelationship?: Array<RecordSearchConfigRecordFilterFrameworksFrameworkRelationshipEnum>;
}


/**
 * @export
 */
export const RecordSearchConfigRecordFilterFrameworksFrameworkRelationshipEnum = {
    FrameworkNotice: 'FRAMEWORK_NOTICE',
    CallOff: 'CALL_OFF',
    NotFrameworkRelated: 'NOT_FRAMEWORK_RELATED'
} as const;
export type RecordSearchConfigRecordFilterFrameworksFrameworkRelationshipEnum = typeof RecordSearchConfigRecordFilterFrameworksFrameworkRelationshipEnum[keyof typeof RecordSearchConfigRecordFilterFrameworksFrameworkRelationshipEnum];


/**
 * Check if a given object implements the RecordSearchConfigRecordFilterFrameworks interface.
 */
export function instanceOfRecordSearchConfigRecordFilterFrameworks(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RecordSearchConfigRecordFilterFrameworksFromJSON(json: any): RecordSearchConfigRecordFilterFrameworks {
    return RecordSearchConfigRecordFilterFrameworksFromJSONTyped(json, false);
}

export function RecordSearchConfigRecordFilterFrameworksFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSearchConfigRecordFilterFrameworks {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'frameworkRelationship': !exists(json, 'framework_relationship') ? undefined : json['framework_relationship'],
    };
}

export function RecordSearchConfigRecordFilterFrameworksToJSON(value?: RecordSearchConfigRecordFilterFrameworks | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
        'framework_relationship': value.frameworkRelationship,
    };
}

