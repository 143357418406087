/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportDto
 */
export interface ReportDto {
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    publishedAt: string;
    /**
     * 
     * @type {string}
     * @memberof ReportDto
     */
    reportType: string;
}

/**
 * Check if a given object implements the ReportDto interface.
 */
export function instanceOfReportDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "publishedAt" in value;
    isInstance = isInstance && "reportType" in value;

    return isInstance;
}

export function ReportDtoFromJSON(json: any): ReportDto {
    return ReportDtoFromJSONTyped(json, false);
}

export function ReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'publishedAt': json['published_at'],
        'reportType': json['report_type'],
    };
}

export function ReportDtoToJSON(value?: ReportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'published_at': value.publishedAt,
        'report_type': value.reportType,
    };
}

