/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AllBuyersStatsStats } from './AllBuyersStatsStats';
import {
    AllBuyersStatsStatsFromJSON,
    AllBuyersStatsStatsFromJSONTyped,
    AllBuyersStatsStatsToJSON,
} from './AllBuyersStatsStats';
import type { BuyerCategoryAssignmentDto } from './BuyerCategoryAssignmentDto';
import {
    BuyerCategoryAssignmentDtoFromJSON,
    BuyerCategoryAssignmentDtoFromJSONTyped,
    BuyerCategoryAssignmentDtoToJSON,
} from './BuyerCategoryAssignmentDto';
import type { Signal } from './Signal';
import {
    SignalFromJSON,
    SignalFromJSONTyped,
    SignalToJSON,
} from './Signal';

/**
 * 
 * @export
 * @interface AllBuyersStats
 */
export interface AllBuyersStats {
    /**
     * 
     * @type {string}
     * @memberof AllBuyersStats
     */
    buyerGuid: string;
    /**
     * 
     * @type {string}
     * @memberof AllBuyersStats
     */
    buyerName: string;
    /**
     * 
     * @type {string}
     * @memberof AllBuyersStats
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof AllBuyersStats
     */
    town?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllBuyersStats
     */
    region?: string | null;
    /**
     * 
     * @type {AllBuyersStatsStats}
     * @memberof AllBuyersStats
     */
    stats: AllBuyersStatsStats;
    /**
     * 
     * @type {Array<Signal>}
     * @memberof AllBuyersStats
     */
    signals: Array<Signal>;
    /**
     * 
     * @type {Array<BuyerCategoryAssignmentDto>}
     * @memberof AllBuyersStats
     */
    categories: Array<BuyerCategoryAssignmentDto>;
}

/**
 * Check if a given object implements the AllBuyersStats interface.
 */
export function instanceOfAllBuyersStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "buyerGuid" in value;
    isInstance = isInstance && "buyerName" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "stats" in value;
    isInstance = isInstance && "signals" in value;
    isInstance = isInstance && "categories" in value;

    return isInstance;
}

export function AllBuyersStatsFromJSON(json: any): AllBuyersStats {
    return AllBuyersStatsFromJSONTyped(json, false);
}

export function AllBuyersStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllBuyersStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buyerGuid': json['buyer_guid'],
        'buyerName': json['buyer_name'],
        'country': json['country'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'stats': AllBuyersStatsStatsFromJSON(json['stats']),
        'signals': ((json['signals'] as Array<any>).map(SignalFromJSON)),
        'categories': ((json['categories'] as Array<any>).map(BuyerCategoryAssignmentDtoFromJSON)),
    };
}

export function AllBuyersStatsToJSON(value?: AllBuyersStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buyer_guid': value.buyerGuid,
        'buyer_name': value.buyerName,
        'country': value.country,
        'town': value.town,
        'region': value.region,
        'stats': AllBuyersStatsStatsToJSON(value.stats),
        'signals': ((value.signals as Array<any>).map(SignalToJSON)),
        'categories': ((value.categories as Array<any>).map(BuyerCategoryAssignmentDtoToJSON)),
    };
}

