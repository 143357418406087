import { useEffect, useRef } from "react";
import { useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import jwtDecode, { JwtPayload } from "jwt-decode";

import { useOpenApi } from "lib/openApiContext";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "./utils";

const THRESHOLD_EXPIRY_SECONDS = 30;

export function isTokenExpired(token: string) {
  const decoded: JwtPayload = jwtDecode(token);
  // The jwt token exp is stated in seconds since epoch
  const expiryDate = decoded.exp || 0;
  // We convert the current time in milliseconds since epock to seconds since epoch
  const currentTime = Date.now() / 1000;
  // We subtract a threashold from the token expiry to give ourselves time to make the request
  // Then we check if the diff is less than 0. If so, then the token is deemed expired.
  return currentTime >= expiryDate - THRESHOLD_EXPIRY_SECONDS;
}

export function useAppToken(options?: UseQueryOptions<string, unknown, string, string[]>) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  const isMountedRef = useRef(false);

  // track whether the hook is mounted or not
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const query = useQuery(
    ["appToken"],
    async () => {
      const data = await api.getAppApiToken();
      return data.token;
    },
    // cache infinitley as we manually invalidate the cache when the token expires
    { ...REACT_QUERY_OPTIONS_NEVER_REFETCH, ...options },
  );

  if (query.data && isTokenExpired(query.data) && isMountedRef.current) {
    void queryClient.invalidateQueries(["appToken"]);
  }

  return query;
}
