/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactDetailsResponse
 */
export interface ContactDetailsResponse {
    /**
     * 
     * @type {string}
     * @memberof ContactDetailsResponse
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDetailsResponse
     */
    externalUrl?: string;
}

/**
 * Check if a given object implements the ContactDetailsResponse interface.
 */
export function instanceOfContactDetailsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContactDetailsResponseFromJSON(json: any): ContactDetailsResponse {
    return ContactDetailsResponseFromJSONTyped(json, false);
}

export function ContactDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactDetailsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'externalUrl': !exists(json, 'externalUrl') ? undefined : json['externalUrl'],
    };
}

export function ContactDetailsResponseToJSON(value?: ContactDetailsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'externalId': value.externalId,
        'externalUrl': value.externalUrl,
    };
}

