import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { SearchResponse, SearchSuppliersParams } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";
import { EventNames, logEvent } from "lib/tracking";
import { SupplierSummary } from "lib/types/models";

type QueryKey = [string, string, SearchSuppliersParams];

export function useSupplierSearch(
  params: SearchSuppliersParams,
  options?: UseQueryOptions<
    SearchResponse<SupplierSummary>,
    unknown,
    SearchResponse<SupplierSummary>,
    QueryKey
  >,
) {
  const api = useStotlesApi();
  return useQuery(
    ["suppliers", "search", params],
    async () => {
      const suppliers = await api.searchSuppliers(params);
      logEvent(EventNames.searchPerformed, {
        ...params,
        "Search type": "SUPPLIERS",
      });
      return suppliers;
    },
    options,
  );
}
