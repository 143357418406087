/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyerCategoryAssignmentDto } from './BuyerCategoryAssignmentDto';
import {
    BuyerCategoryAssignmentDtoFromJSON,
    BuyerCategoryAssignmentDtoFromJSONTyped,
    BuyerCategoryAssignmentDtoToJSON,
} from './BuyerCategoryAssignmentDto';
import type { BuyerResultStats } from './BuyerResultStats';
import {
    BuyerResultStatsFromJSON,
    BuyerResultStatsFromJSONTyped,
    BuyerResultStatsToJSON,
} from './BuyerResultStats';
import type { Signal } from './Signal';
import {
    SignalFromJSON,
    SignalFromJSONTyped,
    SignalToJSON,
} from './Signal';

/**
 * 
 * @export
 * @interface BuyerResult
 */
export interface BuyerResult {
    /**
     * 
     * @type {string}
     * @memberof BuyerResult
     */
    buyerGuid: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerResult
     */
    buyerName: string;
    /**
     * 
     * @type {Array<BuyerCategoryAssignmentDto>}
     * @memberof BuyerResult
     */
    categories: Array<BuyerCategoryAssignmentDto>;
    /**
     * 
     * @type {Array<Signal>}
     * @memberof BuyerResult
     */
    signals: Array<Signal>;
    /**
     * 
     * @type {BuyerResultStats}
     * @memberof BuyerResult
     */
    stats: BuyerResultStats;
}

/**
 * Check if a given object implements the BuyerResult interface.
 */
export function instanceOfBuyerResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "buyerGuid" in value;
    isInstance = isInstance && "buyerName" in value;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "signals" in value;
    isInstance = isInstance && "stats" in value;

    return isInstance;
}

export function BuyerResultFromJSON(json: any): BuyerResult {
    return BuyerResultFromJSONTyped(json, false);
}

export function BuyerResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyerResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buyerGuid': json['buyer_guid'],
        'buyerName': json['buyer_name'],
        'categories': ((json['categories'] as Array<any>).map(BuyerCategoryAssignmentDtoFromJSON)),
        'signals': ((json['signals'] as Array<any>).map(SignalFromJSON)),
        'stats': BuyerResultStatsFromJSON(json['stats']),
    };
}

export function BuyerResultToJSON(value?: BuyerResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buyer_guid': value.buyerGuid,
        'buyer_name': value.buyerName,
        'categories': ((value.categories as Array<any>).map(BuyerCategoryAssignmentDtoToJSON)),
        'signals': ((value.signals as Array<any>).map(SignalToJSON)),
        'stats': BuyerResultStatsToJSON(value.stats),
    };
}

