/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePinnedSavedViewRequest
 */
export interface UpdatePinnedSavedViewRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePinnedSavedViewRequest
     */
    savedViewId: string;
    /**
     * 
     * @type {number}
     * @memberof UpdatePinnedSavedViewRequest
     */
    rank: number;
}

/**
 * Check if a given object implements the UpdatePinnedSavedViewRequest interface.
 */
export function instanceOfUpdatePinnedSavedViewRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "savedViewId" in value;
    isInstance = isInstance && "rank" in value;

    return isInstance;
}

export function UpdatePinnedSavedViewRequestFromJSON(json: any): UpdatePinnedSavedViewRequest {
    return UpdatePinnedSavedViewRequestFromJSONTyped(json, false);
}

export function UpdatePinnedSavedViewRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePinnedSavedViewRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'savedViewId': json['saved_view_id'],
        'rank': json['rank'],
    };
}

export function UpdatePinnedSavedViewRequestToJSON(value?: UpdatePinnedSavedViewRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'saved_view_id': value.savedViewId,
        'rank': value.rank,
    };
}

