import React from "react";
import { CloseCircleFilled, CloseCircleOutlined } from "@ant-design/icons";

import PaywallStar from "../../../lib/icons/PaywallStar";
import FilterCollapseBadge from "../filter_collapse_badge/FilterCollapseBadge";

import css from "./FilterCollapseHeader.module.scss";

export interface FilterCollapseHeaderProps {
  title: string;
  count?: number;
  clear: () => void;
  tag?: (title: string) => React.ReactNode;
  paywallIcon?: boolean;
}

function FilterCollapseHeader({
  title,
  count,
  clear,
  tag,
  paywallIcon,
}: FilterCollapseHeaderProps) {
  return (
    <div className={css.wrapper}>
      {
        <div className={css.titleContainer}>
          {title}
          {paywallIcon && <PaywallStar />}
          {tag && tag(title)}
        </div>
      }
      {count
        ? count > 0 && (
            <FilterCollapseBadge
              iconOnClick={clear}
              icon={
                <span className={css.closeIconWrapper}>
                  <CloseCircleOutlined className={css.closeIconDefault} />
                  <CloseCircleFilled className={css.closeIconHover} />
                </span>
              }
            >
              {count}
            </FilterCollapseBadge>
          )
        : null}
    </div>
  );
}

export default FilterCollapseHeader;
