/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddBuyersRequest
 */
export interface AddBuyersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AddBuyersRequest
     */
    buyerGuids: Array<string>;
}

/**
 * Check if a given object implements the AddBuyersRequest interface.
 */
export function instanceOfAddBuyersRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "buyerGuids" in value;

    return isInstance;
}

export function AddBuyersRequestFromJSON(json: any): AddBuyersRequest {
    return AddBuyersRequestFromJSONTyped(json, false);
}

export function AddBuyersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddBuyersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buyerGuids': json['buyer_guids'],
    };
}

export function AddBuyersRequestToJSON(value?: AddBuyersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buyer_guids': value.buyerGuids,
    };
}

