import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { App, Button, Modal } from "antd5";

import { useCreateNoticeList } from "lib/hooks/api/notices/lists/useCreateNoticeList";
import { ListForm } from "./UserListsModals";

type AddNewListButtonProps = {
  modal?: boolean;
};

export function AddNewListButton({ modal }: AddNewListButtonProps): JSX.Element {
  const { message } = App.useApp();
  const { mutate: createList, isLoading } = useCreateNoticeList({
    onSuccess: () => setIsModalOpen(false),
    onError: () =>
      message.error("Unable to create your lists. Please contact us if the problem persists"),
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div>
      {isModalOpen &&
        (modal ? (
          <Modal title="Create list" open={true} closable={false} footer={null}>
            <ListForm
              onSubmit={(data) => createList(data)}
              onCancel={() => setIsModalOpen(false)}
              listName=""
              isSubmitting={isLoading}
            />
          </Modal>
        ) : (
          <ListForm
            onSubmit={(data) => createList(data)}
            onCancel={() => setIsModalOpen(false)}
            listName=""
            isSubmitting={isLoading}
          />
        ))}
      {(modal || !isModalOpen) && (
        <Button icon={<PlusOutlined />} type="primary" onClick={() => setIsModalOpen(true)}>
          Create list
        </Button>
      )}
    </div>
  );
}
