/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NoticeFieldData } from './NoticeFieldData';
import {
    NoticeFieldDataFromJSON,
    NoticeFieldDataFromJSONTyped,
    NoticeFieldDataToJSON,
} from './NoticeFieldData';

/**
 * 
 * @export
 * @interface DescribeNoticeFieldsResponse
 */
export interface DescribeNoticeFieldsResponse {
    /**
     * 
     * @type {string}
     * @memberof DescribeNoticeFieldsResponse
     */
    entityName: string;
    /**
     * 
     * @type {Array<NoticeFieldData>}
     * @memberof DescribeNoticeFieldsResponse
     */
    fields: Array<NoticeFieldData>;
}

/**
 * Check if a given object implements the DescribeNoticeFieldsResponse interface.
 */
export function instanceOfDescribeNoticeFieldsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "entityName" in value;
    isInstance = isInstance && "fields" in value;

    return isInstance;
}

export function DescribeNoticeFieldsResponseFromJSON(json: any): DescribeNoticeFieldsResponse {
    return DescribeNoticeFieldsResponseFromJSONTyped(json, false);
}

export function DescribeNoticeFieldsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DescribeNoticeFieldsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entityName': json['entityName'],
        'fields': ((json['fields'] as Array<any>).map(NoticeFieldDataFromJSON)),
    };
}

export function DescribeNoticeFieldsResponseToJSON(value?: DescribeNoticeFieldsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entityName': value.entityName,
        'fields': ((value.fields as Array<any>).map(NoticeFieldDataToJSON)),
    };
}

