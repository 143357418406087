/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EnumValue } from './EnumValue';
import {
    EnumValueFromJSON,
    EnumValueFromJSONTyped,
    EnumValueToJSON,
} from './EnumValue';

/**
 * 
 * @export
 * @interface FetchPropertyValuesResponse
 */
export interface FetchPropertyValuesResponse {
    /**
     * 
     * @type {string}
     * @memberof FetchPropertyValuesResponse
     */
    propertyId: string;
    /**
     * 
     * @type {Array<EnumValue>}
     * @memberof FetchPropertyValuesResponse
     */
    values: Array<EnumValue>;
}

/**
 * Check if a given object implements the FetchPropertyValuesResponse interface.
 */
export function instanceOfFetchPropertyValuesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "propertyId" in value;
    isInstance = isInstance && "values" in value;

    return isInstance;
}

export function FetchPropertyValuesResponseFromJSON(json: any): FetchPropertyValuesResponse {
    return FetchPropertyValuesResponseFromJSONTyped(json, false);
}

export function FetchPropertyValuesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchPropertyValuesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'propertyId': json['propertyId'],
        'values': ((json['values'] as Array<any>).map(EnumValueFromJSON)),
    };
}

export function FetchPropertyValuesResponseToJSON(value?: FetchPropertyValuesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'propertyId': value.propertyId,
        'values': ((value.values as Array<any>).map(EnumValueToJSON)),
    };
}

