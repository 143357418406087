/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { BasicContactInformation } from './BasicContactInformation';
import {
    BasicContactInformationFromJSON,
    BasicContactInformationFromJSONTyped,
    BasicContactInformationToJSON,
} from './BasicContactInformation';
import type { ReferenceOrgDtoOrganisation } from './ReferenceOrgDtoOrganisation';
import {
    ReferenceOrgDtoOrganisationFromJSON,
    ReferenceOrgDtoOrganisationFromJSONTyped,
    ReferenceOrgDtoOrganisationToJSON,
} from './ReferenceOrgDtoOrganisation';

/**
 * 
 * @export
 * @interface ReferenceOrgDto
 */
export interface ReferenceOrgDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceOrgDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceOrgDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceOrgDto
     */
    sourceIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceOrgDto
     */
    source: ReferenceOrgDtoSourceEnum;
    /**
     * 
     * @type {BasicContactInformation}
     * @memberof ReferenceOrgDto
     */
    basicContactInfo: BasicContactInformation;
    /**
     * 
     * @type {Address}
     * @memberof ReferenceOrgDto
     */
    address: Address;
    /**
     * 
     * @type {object}
     * @memberof ReferenceOrgDto
     */
    additionalData: object;
    /**
     * 
     * @type {ReferenceOrgDtoOrganisation}
     * @memberof ReferenceOrgDto
     */
    organisation: ReferenceOrgDtoOrganisation;
}


/**
 * @export
 */
export const ReferenceOrgDtoSourceEnum = {
    Oscar: 'oscar',
    CompaniesHouse: 'companies_house',
    DunAndBradstreet: 'dun_and_bradstreet'
} as const;
export type ReferenceOrgDtoSourceEnum = typeof ReferenceOrgDtoSourceEnum[keyof typeof ReferenceOrgDtoSourceEnum];


/**
 * Check if a given object implements the ReferenceOrgDto interface.
 */
export function instanceOfReferenceOrgDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "sourceIdentifier" in value;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "basicContactInfo" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "additionalData" in value;
    isInstance = isInstance && "organisation" in value;

    return isInstance;
}

export function ReferenceOrgDtoFromJSON(json: any): ReferenceOrgDto {
    return ReferenceOrgDtoFromJSONTyped(json, false);
}

export function ReferenceOrgDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReferenceOrgDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'sourceIdentifier': json['source_identifier'],
        'source': json['source'],
        'basicContactInfo': BasicContactInformationFromJSON(json['basic_contact_info']),
        'address': AddressFromJSON(json['address']),
        'additionalData': json['additional_data'],
        'organisation': ReferenceOrgDtoOrganisationFromJSON(json['organisation']),
    };
}

export function ReferenceOrgDtoToJSON(value?: ReferenceOrgDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'source_identifier': value.sourceIdentifier,
        'source': value.source,
        'basic_contact_info': BasicContactInformationToJSON(value.basicContactInfo),
        'address': AddressToJSON(value.address),
        'additional_data': value.additionalData,
        'organisation': ReferenceOrgDtoOrganisationToJSON(value.organisation),
    };
}

