import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { App, Button } from "antd5";
import { z } from "zod";

import { useCreateOpportunity } from "../../../lib/hooks/api/opportunities/useCreateOpportunity";
import { OpportunityEntityType } from "../../../lib/types/graphQLEnums";
import { Flex } from "../../../styles/utility-components";
import { DatePicker, Input, TextArea } from "../../form_components/Inputs";
import TeamUserSelect from "../../form_components/TeamUserSelect";
import { OpportunityStageSelect } from "../OpportunityStageSelect";

const formSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  value: z.coerce.number().positive().optional(),
  stageId: z.string().uuid(),
  assignedToId: z.string().uuid().optional(),
  //transform the date object to a timestamp string
  closeDate: z.coerce
    .date()
    .transform((date) => date.toISOString())
    .optional(),
  entities: z
    .object({
      entityId: z.string().uuid(),
      entityType: z.nativeEnum(OpportunityEntityType),
    })
    .array()
    .optional(),
});

export type CreateOpportunityFormValues = z.infer<typeof formSchema>;

type Props = {
  defaultValues?: Partial<CreateOpportunityFormValues>;
  onCancel: () => void;
  onSuccess?: () => void;
};

export function CreateOpportunityForm({ defaultValues, onCancel, onSuccess }: Props) {
  const { message } = App.useApp();
  const { control, handleSubmit, reset } = useForm<CreateOpportunityFormValues>({
    defaultValues,
    resolver: zodResolver(formSchema),
  });
  const { mutate } = useCreateOpportunity({
    onSuccess: () => {
      reset();
      message.success("Opportunity created successfully");
      onSuccess?.();
    },
    onError: () => message.error("Failed to create opportunity"),
  });

  return (
    <form
      onSubmit={handleSubmit(({ entities, ...opportunity }) =>
        mutate({ opportunity, entities: { entities: entities || [] } }),
      )}
    >
      <Flex flexDirection="column" gap={8}>
        <Input name="name" control={control} label="Name" />
        <TextArea name="description" control={control} label="Description" />
        <TeamUserSelect name="assignedToId" control={control} label="Assigned To" />
        <Input name="value" control={control} label="Value" type="number" />
        <OpportunityStageSelect name="stageId" control={control} label="Stage" />
        <DatePicker name="closeDate" control={control} label="Close Date" />
        <Flex justifyContent="flex-end" gap={8}>
          {/* TODO: add assigned to  */}
          <Button onClick={onCancel}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Flex>
      </Flex>
    </form>
  );
}
