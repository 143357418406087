/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IframeSection
 */
export interface IframeSection {
    /**
     * 
     * @type {string}
     * @memberof IframeSection
     */
    type?: IframeSectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof IframeSection
     */
    src?: string;
}


/**
 * @export
 */
export const IframeSectionTypeEnum = {
    Iframe: 'IFRAME'
} as const;
export type IframeSectionTypeEnum = typeof IframeSectionTypeEnum[keyof typeof IframeSectionTypeEnum];


/**
 * Check if a given object implements the IframeSection interface.
 */
export function instanceOfIframeSection(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function IframeSectionFromJSON(json: any): IframeSection {
    return IframeSectionFromJSONTyped(json, false);
}

export function IframeSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): IframeSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'src': !exists(json, 'src') ? undefined : json['src'],
    };
}

export function IframeSectionToJSON(value?: IframeSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'src': value.src,
    };
}

