/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactDto
 */
export interface ContactDto {
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    jobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    seniority: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactDto
     */
    jobFunction: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    orgUrn: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    org: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    contactEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    contactPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    buyerGuid?: string | null;
}

/**
 * Check if a given object implements the ContactDto interface.
 */
export function instanceOfContactDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "jobTitle" in value;
    isInstance = isInstance && "seniority" in value;
    isInstance = isInstance && "jobFunction" in value;
    isInstance = isInstance && "orgUrn" in value;
    isInstance = isInstance && "org" in value;

    return isInstance;
}

export function ContactDtoFromJSON(json: any): ContactDto {
    return ContactDtoFromJSONTyped(json, false);
}

export function ContactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'jobTitle': json['job_title'],
        'seniority': json['seniority'],
        'jobFunction': json['job_function'],
        'orgUrn': json['org_urn'],
        'org': json['org'],
        'contactEmail': !exists(json, 'contact_email') ? undefined : json['contact_email'],
        'contactPhone': !exists(json, 'contact_phone') ? undefined : json['contact_phone'],
        'buyerGuid': !exists(json, 'buyer_guid') ? undefined : json['buyer_guid'],
    };
}

export function ContactDtoToJSON(value?: ContactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'job_title': value.jobTitle,
        'seniority': value.seniority,
        'job_function': value.jobFunction,
        'org_urn': value.orgUrn,
        'org': value.org,
        'contact_email': value.contactEmail,
        'contact_phone': value.contactPhone,
        'buyer_guid': value.buyerGuid,
    };
}

