/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SignalData } from './SignalData';
import {
    SignalDataFromJSON,
    SignalDataFromJSONTyped,
    SignalDataToJSON,
} from './SignalData';

/**
 * 
 * @export
 * @interface SupplierStatsResponse
 */
export interface SupplierStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof SupplierStatsResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SupplierStatsResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierStatsResponse
     */
    country: string;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierStatsResponse
     */
    isSme: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SupplierStatsResponse
     */
    town: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplierStatsResponse
     */
    address: string | null;
    /**
     * 
     * @type {number}
     * @memberof SupplierStatsResponse
     */
    awardsCount: number;
    /**
     * 
     * @type {Array<SignalData>}
     * @memberof SupplierStatsResponse
     */
    signals: Array<SignalData> | null;
    /**
     * 
     * @type {number}
     * @memberof SupplierStatsResponse
     */
    expiriesCount: number;
    /**
     * 
     * @type {number}
     * @memberof SupplierStatsResponse
     */
    keyAccountsCount: number;
}

/**
 * Check if a given object implements the SupplierStatsResponse interface.
 */
export function instanceOfSupplierStatsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "isSme" in value;
    isInstance = isInstance && "town" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "awardsCount" in value;
    isInstance = isInstance && "signals" in value;
    isInstance = isInstance && "expiriesCount" in value;
    isInstance = isInstance && "keyAccountsCount" in value;

    return isInstance;
}

export function SupplierStatsResponseFromJSON(json: any): SupplierStatsResponse {
    return SupplierStatsResponseFromJSONTyped(json, false);
}

export function SupplierStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'country': json['country'],
        'isSme': json['is_sme'],
        'town': json['town'],
        'address': json['address'],
        'awardsCount': json['awards_count'],
        'signals': (json['signals'] === null ? null : (json['signals'] as Array<any>).map(SignalDataFromJSON)),
        'expiriesCount': json['expiries_count'],
        'keyAccountsCount': json['key_accounts_count'],
    };
}

export function SupplierStatsResponseToJSON(value?: SupplierStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'country': value.country,
        'is_sme': value.isSme,
        'town': value.town,
        'address': value.address,
        'awards_count': value.awardsCount,
        'signals': (value.signals === null ? null : (value.signals as Array<any>).map(SignalDataToJSON)),
        'expiries_count': value.expiriesCount,
        'key_accounts_count': value.keyAccountsCount,
    };
}

