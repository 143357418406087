/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplierFiltersBuyerFilters } from './SupplierFiltersBuyerFilters';
import {
    SupplierFiltersBuyerFiltersFromJSON,
    SupplierFiltersBuyerFiltersFromJSONTyped,
    SupplierFiltersBuyerFiltersToJSON,
} from './SupplierFiltersBuyerFilters';
import type { SupplierFiltersSignalFilter } from './SupplierFiltersSignalFilter';
import {
    SupplierFiltersSignalFilterFromJSON,
    SupplierFiltersSignalFilterFromJSONTyped,
    SupplierFiltersSignalFilterToJSON,
} from './SupplierFiltersSignalFilter';

/**
 * 
 * @export
 * @interface SupplierFilters
 */
export interface SupplierFilters {
    /**
     * 
     * @type {string}
     * @memberof SupplierFilters
     */
    text?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierFilters
     */
    isSme?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierFilters
     */
    guid?: Array<string> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SupplierFilters
     */
    id?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierFilters
     */
    recordId?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierFilters
     */
    country?: Array<string> | null;
    /**
     * 
     * @type {SupplierFiltersSignalFilter}
     * @memberof SupplierFilters
     */
    signals?: SupplierFiltersSignalFilter;
    /**
     * 
     * @type {SupplierFiltersBuyerFilters}
     * @memberof SupplierFilters
     */
    buyerFilters?: SupplierFiltersBuyerFilters;
}

/**
 * Check if a given object implements the SupplierFilters interface.
 */
export function instanceOfSupplierFilters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplierFiltersFromJSON(json: any): SupplierFilters {
    return SupplierFiltersFromJSONTyped(json, false);
}

export function SupplierFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'text') ? undefined : json['text'],
        'isSme': !exists(json, 'is_sme') ? undefined : json['is_sme'],
        'guid': !exists(json, 'guid') ? undefined : json['guid'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'recordId': !exists(json, 'record_id') ? undefined : json['record_id'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'signals': !exists(json, 'signals') ? undefined : SupplierFiltersSignalFilterFromJSON(json['signals']),
        'buyerFilters': !exists(json, 'buyer_filters') ? undefined : SupplierFiltersBuyerFiltersFromJSON(json['buyer_filters']),
    };
}

export function SupplierFiltersToJSON(value?: SupplierFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'is_sme': value.isSme,
        'guid': value.guid,
        'id': value.id,
        'record_id': value.recordId,
        'country': value.country,
        'signals': SupplierFiltersSignalFilterToJSON(value.signals),
        'buyer_filters': SupplierFiltersBuyerFiltersToJSON(value.buyerFilters),
    };
}

