import * as React from "react";
import { Button, ButtonProps } from "antd5";
import classnames from "classnames";

import css from "./TextButton.module.scss";

export type TextButtonProps = {
  bold?: boolean;
  underline?: boolean;
  inheritSize?: boolean;
} & Omit<ButtonProps, "type">;

// This is a button styled like a link
function TextButton({
  children,
  className,
  bold,
  underline,
  inheritSize,
  ...buttonProps
}: TextButtonProps): JSX.Element {
  return (
    <Button
      className={classnames(
        css.textButton,
        {
          [css.bold]: bold,
          [css.inheritSize]: inheritSize,
        },
        className,
      )}
      {...buttonProps}
      type="link"
    >
      <span className={classnames({ [css.underline]: underline })}>{children}</span>
    </Button>
  );
}

export default TextButton;
