/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NoticeMappingDetails } from './NoticeMappingDetails';
import {
    NoticeMappingDetailsFromJSON,
    NoticeMappingDetailsFromJSONTyped,
    NoticeMappingDetailsToJSON,
} from './NoticeMappingDetails';

/**
 * 
 * @export
 * @interface ListNoticeMappingsResponse
 */
export interface ListNoticeMappingsResponse {
    /**
     * 
     * @type {Array<NoticeMappingDetails>}
     * @memberof ListNoticeMappingsResponse
     */
    results: Array<NoticeMappingDetails>;
}

/**
 * Check if a given object implements the ListNoticeMappingsResponse interface.
 */
export function instanceOfListNoticeMappingsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function ListNoticeMappingsResponseFromJSON(json: any): ListNoticeMappingsResponse {
    return ListNoticeMappingsResponseFromJSONTyped(json, false);
}

export function ListNoticeMappingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListNoticeMappingsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(NoticeMappingDetailsFromJSON)),
    };
}

export function ListNoticeMappingsResponseToJSON(value?: ListNoticeMappingsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(NoticeMappingDetailsToJSON)),
    };
}

