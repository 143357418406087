import React from "react";

import css from "./CpvCodes.module.scss";

type CpvCode = { code: string; name: string };

function CpvCodesContainer({ cpvCodes }: { cpvCodes: CpvCode[] }) {
  if (!cpvCodes.length) {
    return <span>No CPV codes available.</span>;
  }

  return (
    <div className={css.cpvCodesContainer}>
      {cpvCodes.map((cpvCode) => (
        <div key={cpvCode.code} className={css.cpvCode}>
          {cpvCode.code} - {cpvCode.name}
        </div>
      ))}
    </div>
  );
}

export default function CpvCodes({ cpvCodes }: { cpvCodes: CpvCode[] }) {
  return (
    <div className={css.section}>
      <h2 className={css.title}>CPV codes</h2>
      <CpvCodesContainer cpvCodes={cpvCodes} />
    </div>
  );
}
