/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Owner } from './Owner';
import {
    OwnerFromJSON,
    OwnerFromJSONTyped,
    OwnerToJSON,
} from './Owner';

/**
 * 
 * @export
 * @interface SearchOwnersResponse
 */
export interface SearchOwnersResponse {
    /**
     * 
     * @type {Array<Owner>}
     * @memberof SearchOwnersResponse
     */
    owners: Array<Owner>;
}

/**
 * Check if a given object implements the SearchOwnersResponse interface.
 */
export function instanceOfSearchOwnersResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "owners" in value;

    return isInstance;
}

export function SearchOwnersResponseFromJSON(json: any): SearchOwnersResponse {
    return SearchOwnersResponseFromJSONTyped(json, false);
}

export function SearchOwnersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchOwnersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'owners': ((json['owners'] as Array<any>).map(OwnerFromJSON)),
    };
}

export function SearchOwnersResponseToJSON(value?: SearchOwnersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'owners': ((value.owners as Array<any>).map(OwnerToJSON)),
    };
}

