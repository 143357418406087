const DocumentTypes: Record<string, string> = {
  //Taken from https://github.com/alphagov/government-frontend/blob/187fc8293de448f126da3113413575e63a4988fc/config/locales/en.yml#L67

  "gov_uk:aaib_report": "Air Accidents Investigation Branch report",
  "gov_uk:announcement": "Announcement",
  "gov_uk:asylum_support_decision": "Asylum support tribunal decision",
  "gov_uk:authored_article": "Authored article",
  "gov_uk:business_finance_support_scheme": "Business finance support scheme",
  "gov_uk:case_study": "Case study",
  "gov_uk:closed_consultation": "Closed consultation",
  "gov_uk:cma_case": "Competition and Markets Authority case",
  "gov_uk:coming_soon": "Coming Soon",
  "gov_uk:consultation": "Consultation",
  "gov_uk:consultation_outcome": "Consultation outcome",
  "gov_uk:corporate_information_page": "Information page",
  "gov_uk:corporate_report": "Corporate report",
  "gov_uk:correspondence": "Correspondence",
  "gov_uk:countryside_stewardship_grant": "Countryside Stewardship grant",
  "gov_uk:decision": "Decision",
  "gov_uk:detailed_guide": "Guidance",
  "gov_uk:dfid_research_output": "Research for Development Output",
  "gov_uk:document_collection": "Collection",
  "gov_uk:draft_text": "Draft text",
  "gov_uk:drug_safety_update": "Drug Safety Update",
  "gov_uk:employment_appeal_tribunal_decision": "Employment appeal tribunal decision",
  "gov_uk:employment_tribunal_decision": "Employment tribunal decision",
  "gov_uk:esi_fund": "European Structural and Investment Fund (ESIF)",
  "gov_uk:fatality_notice": "Fatality notice",
  "gov_uk:foi_release": "FOI release",
  "gov_uk:form": "Form",
  "gov_uk:government_response": "Government response",
  "gov_uk:guidance": "Guidance",
  "gov_uk:impact_assessment": "Impact assessment",
  "gov_uk:imported": "imported - awaiting type",
  "gov_uk:independent_report": "Independent report",
  "gov_uk:international_development_fund": "International development funding",
  "gov_uk:international_treaty": "International treaty",
  "gov_uk:maib_report": "Marine Accident Investigation Branch report",
  "gov_uk:map": "Map",
  "gov_uk:medical_safety_alert": "Alerts and recalls for drugs and medical devices",
  "gov_uk:national": "National statistics announcement",
  "gov_uk:national_statistics": "National statistics",
  "gov_uk:national_statistics_announcement": "National statistics announcement",
  "gov_uk:news_article": "News article",
  "gov_uk:news_story": "News story",
  "gov_uk:notice": "Notice",
  "gov_uk:official": "Official statistics announcement",
  "gov_uk:official_statistics": "Official Statistics",
  "gov_uk:official_statistics_announcement": "Official statistics announcement",
  "gov_uk:open_consultation": "Open consultation",
  "gov_uk:oral_statement": "Oral statement to Parliament",
  "gov_uk:policy": "Policy",
  "gov_uk:policy_paper": "Policy paper",
  "gov_uk:press_release": "Press release",
  "gov_uk:product-safety-alert-report-recall": "Product Safety Alerts, Reports and Recalls",
  "gov_uk:promotional": "Promotional material",
  "gov_uk:publication": "Publication",
  "gov_uk:raib_report": "Rail Accident Investigation Branch report",
  "gov_uk:regulation": "Regulation",
  "gov_uk:research": "Research and analysis",
  "gov_uk:residential_property_tribunal_decision": "Residential property tribunal decision",
  "gov_uk:service_sign_in": "Service sign in",
  "gov_uk:service_standard_report": "Service Standard Report",
  "gov_uk:speaking_notes": "Speaking notes",
  "gov_uk:speech": "Speech",
  "gov_uk:standard": "Standard",
  "gov_uk:statement_to_parliament": "Statement to Parliament",
  "gov_uk:statistical_data_set": "Statistical data set",
  "gov_uk:statistics_announcement": "Statistics release announcement",
  "gov_uk:statutory_guidance": "Statutory guidance",
  "gov_uk:take_part": "Take part",
  "gov_uk:tax_tribunal_decision": "Tax and Chancery tribunal decision",
  "gov_uk:transcript": "Transcript",
  "gov_uk:transparency": "Transparency data",
  "gov_uk:utaac_decision": "Administrative appeals tribunal decision",
  "gov_uk:world_location_news_article": "News article",
  "gov_uk:world_news_story": "World news story",
  "gov_uk:written_statement": "Written statement to Parliament",

  general: "Reports and accounts",
  meeting: "Strategy",
  "delivery-plans": "Budget",
  budget: "Budget",
  foi: "FOI",
  po: "PO",
};

export function getFriendlyDocumentType(documentType: string): string {
  return DocumentTypes[documentType] || documentType;
}
