/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DateSetting } from './DateSetting';
import {
    DateSettingFromJSON,
    DateSettingFromJSONTyped,
    DateSettingToJSON,
} from './DateSetting';
import type { ViewConfigurationFilterSettingsFrameworks } from './ViewConfigurationFilterSettingsFrameworks';
import {
    ViewConfigurationFilterSettingsFrameworksFromJSON,
    ViewConfigurationFilterSettingsFrameworksFromJSONTyped,
    ViewConfigurationFilterSettingsFrameworksToJSON,
} from './ViewConfigurationFilterSettingsFrameworks';
import type { ViewConfigurationFilterSettingsNumericRangeSetting } from './ViewConfigurationFilterSettingsNumericRangeSetting';
import {
    ViewConfigurationFilterSettingsNumericRangeSettingFromJSON,
    ViewConfigurationFilterSettingsNumericRangeSettingFromJSONTyped,
    ViewConfigurationFilterSettingsNumericRangeSettingToJSON,
} from './ViewConfigurationFilterSettingsNumericRangeSetting';
import type { ViewConfigurationFilterSettingsSignals } from './ViewConfigurationFilterSettingsSignals';
import {
    ViewConfigurationFilterSettingsSignalsFromJSON,
    ViewConfigurationFilterSettingsSignalsFromJSONTyped,
    ViewConfigurationFilterSettingsSignalsToJSON,
} from './ViewConfigurationFilterSettingsSignals';
import type { ViewConfigurationFilterSettingsSuppliers } from './ViewConfigurationFilterSettingsSuppliers';
import {
    ViewConfigurationFilterSettingsSuppliersFromJSON,
    ViewConfigurationFilterSettingsSuppliersFromJSONTyped,
    ViewConfigurationFilterSettingsSuppliersToJSON,
} from './ViewConfigurationFilterSettingsSuppliers';

/**
 * 
 * @export
 * @interface ViewConfigurationFilterSettings
 */
export interface ViewConfigurationFilterSettings {
    /**
     * 
     * @type {string}
     * @memberof ViewConfigurationFilterSettings
     */
    text?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    stage?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ViewConfigurationFilterSettings
     */
    buyerIds?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    buyerGuids?: Array<string>;
    /**
     * 
     * @type {DateSetting}
     * @memberof ViewConfigurationFilterSettings
     */
    closeDate?: DateSetting;
    /**
     * 
     * @type {boolean}
     * @memberof ViewConfigurationFilterSettings
     */
    closeNulls?: boolean;
    /**
     * 
     * @type {DateSetting}
     * @memberof ViewConfigurationFilterSettings
     */
    addedDate?: DateSetting;
    /**
     * 
     * @type {DateSetting}
     * @memberof ViewConfigurationFilterSettings
     */
    expiryDate?: DateSetting;
    /**
     * 
     * @type {boolean}
     * @memberof ViewConfigurationFilterSettings
     */
    expiryNulls?: boolean;
    /**
     * 
     * @type {DateSetting}
     * @memberof ViewConfigurationFilterSettings
     */
    publishDate?: DateSetting;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    countryCode?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    buyerCategoryId?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ViewConfigurationFilterSettings
     */
    buyerCategoryNulls?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    buyerCountryCodes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    buyerRegionIds?: Array<string>;
    /**
     * 
     * @type {ViewConfigurationFilterSettingsNumericRangeSetting}
     * @memberof ViewConfigurationFilterSettings
     */
    value?: ViewConfigurationFilterSettingsNumericRangeSetting;
    /**
     * 
     * @type {boolean}
     * @memberof ViewConfigurationFilterSettings
     */
    valueNulls?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ViewConfigurationFilterSettings
     */
    showUserHiddenLeads?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ViewConfigurationFilterSettings
     */
    includeAllDisqualifiedStates?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    assignee?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    excludeKeywords?: Array<string>;
    /**
     * 
     * @type {ViewConfigurationFilterSettingsSuppliers}
     * @memberof ViewConfigurationFilterSettings
     */
    suppliers?: ViewConfigurationFilterSettingsSuppliers;
    /**
     * 
     * @type {ViewConfigurationFilterSettingsFrameworks}
     * @memberof ViewConfigurationFilterSettings
     */
    frameworks?: ViewConfigurationFilterSettingsFrameworks;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    cpvDimensions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    cpvCodes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    procurementStageQualifications?: Array<ViewConfigurationFilterSettingsProcurementStageQualificationsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    buyerListIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    signalCategory?: Array<string>;
    /**
     * 
     * @type {ViewConfigurationFilterSettingsSignals}
     * @memberof ViewConfigurationFilterSettings
     */
    signals?: ViewConfigurationFilterSettingsSignals;
    /**
     * 
     * @type {Array<string>}
     * @memberof ViewConfigurationFilterSettings
     */
    relevanceScore?: Array<ViewConfigurationFilterSettingsRelevanceScoreEnum>;
    /**
     * 
     * @type {number}
     * @memberof ViewConfigurationFilterSettings
     */
    noticeList?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ViewConfigurationFilterSettings
     */
    noticeLists?: Array<number> | null;
}


/**
 * @export
 */
export const ViewConfigurationFilterSettingsProcurementStageQualificationsEnum = {
    Unqualified: 'unqualified',
    NotRelevant: 'not_relevant',
    InReview: 'in_review',
    BidPrep: 'bid_prep',
    BidSubmitted: 'bid_submitted',
    NoBid: 'no_bid',
    Won: 'won',
    Lost: 'lost',
    PreEngageToDo: 'pre_engage_to_do',
    PreEngageDone: 'pre_engage_done',
    PreEngageNotRelevant: 'pre_engage_not_relevant'
} as const;
export type ViewConfigurationFilterSettingsProcurementStageQualificationsEnum = typeof ViewConfigurationFilterSettingsProcurementStageQualificationsEnum[keyof typeof ViewConfigurationFilterSettingsProcurementStageQualificationsEnum];

/**
 * @export
 */
export const ViewConfigurationFilterSettingsRelevanceScoreEnum = {
    None: 'None',
    Low: 'Low',
    Medium: 'Medium',
    High: 'High'
} as const;
export type ViewConfigurationFilterSettingsRelevanceScoreEnum = typeof ViewConfigurationFilterSettingsRelevanceScoreEnum[keyof typeof ViewConfigurationFilterSettingsRelevanceScoreEnum];


/**
 * Check if a given object implements the ViewConfigurationFilterSettings interface.
 */
export function instanceOfViewConfigurationFilterSettings(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ViewConfigurationFilterSettingsFromJSON(json: any): ViewConfigurationFilterSettings {
    return ViewConfigurationFilterSettingsFromJSONTyped(json, false);
}

export function ViewConfigurationFilterSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewConfigurationFilterSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': !exists(json, 'text') ? undefined : json['text'],
        'stage': !exists(json, 'stage') ? undefined : json['stage'],
        'buyerIds': !exists(json, 'buyer_ids') ? undefined : json['buyer_ids'],
        'buyerGuids': !exists(json, 'buyer_guids') ? undefined : json['buyer_guids'],
        'closeDate': !exists(json, 'close_date') ? undefined : DateSettingFromJSON(json['close_date']),
        'closeNulls': !exists(json, 'close_nulls') ? undefined : json['close_nulls'],
        'addedDate': !exists(json, 'added_date') ? undefined : DateSettingFromJSON(json['added_date']),
        'expiryDate': !exists(json, 'expiry_date') ? undefined : DateSettingFromJSON(json['expiry_date']),
        'expiryNulls': !exists(json, 'expiry_nulls') ? undefined : json['expiry_nulls'],
        'publishDate': !exists(json, 'publish_date') ? undefined : DateSettingFromJSON(json['publish_date']),
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
        'buyerCategoryId': !exists(json, 'buyer_category_id') ? undefined : json['buyer_category_id'],
        'buyerCategoryNulls': !exists(json, 'buyer_category_nulls') ? undefined : json['buyer_category_nulls'],
        'buyerCountryCodes': !exists(json, 'buyer_country_codes') ? undefined : json['buyer_country_codes'],
        'buyerRegionIds': !exists(json, 'buyer_region_ids') ? undefined : json['buyer_region_ids'],
        'value': !exists(json, 'value') ? undefined : ViewConfigurationFilterSettingsNumericRangeSettingFromJSON(json['value']),
        'valueNulls': !exists(json, 'value_nulls') ? undefined : json['value_nulls'],
        'showUserHiddenLeads': !exists(json, 'show_user_hidden_leads') ? undefined : json['show_user_hidden_leads'],
        'includeAllDisqualifiedStates': !exists(json, 'include_all_disqualified_states') ? undefined : json['include_all_disqualified_states'],
        'assignee': !exists(json, 'assignee') ? undefined : json['assignee'],
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'excludeKeywords': !exists(json, 'exclude_keywords') ? undefined : json['exclude_keywords'],
        'suppliers': !exists(json, 'suppliers') ? undefined : ViewConfigurationFilterSettingsSuppliersFromJSON(json['suppliers']),
        'frameworks': !exists(json, 'frameworks') ? undefined : ViewConfigurationFilterSettingsFrameworksFromJSON(json['frameworks']),
        'cpvDimensions': !exists(json, 'cpv_dimensions') ? undefined : json['cpv_dimensions'],
        'cpvCodes': !exists(json, 'cpv_codes') ? undefined : json['cpv_codes'],
        'procurementStageQualifications': !exists(json, 'procurement_stage_qualifications') ? undefined : json['procurement_stage_qualifications'],
        'buyerListIds': !exists(json, 'buyer_list_ids') ? undefined : json['buyer_list_ids'],
        'signalCategory': !exists(json, 'signal_category') ? undefined : json['signal_category'],
        'signals': !exists(json, 'signals') ? undefined : ViewConfigurationFilterSettingsSignalsFromJSON(json['signals']),
        'relevanceScore': !exists(json, 'relevance_score') ? undefined : json['relevance_score'],
        'noticeList': !exists(json, 'notice_list') ? undefined : json['notice_list'],
        'noticeLists': !exists(json, 'notice_lists') ? undefined : json['notice_lists'],
    };
}

export function ViewConfigurationFilterSettingsToJSON(value?: ViewConfigurationFilterSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'stage': value.stage,
        'buyer_ids': value.buyerIds,
        'buyer_guids': value.buyerGuids,
        'close_date': DateSettingToJSON(value.closeDate),
        'close_nulls': value.closeNulls,
        'added_date': DateSettingToJSON(value.addedDate),
        'expiry_date': DateSettingToJSON(value.expiryDate),
        'expiry_nulls': value.expiryNulls,
        'publish_date': DateSettingToJSON(value.publishDate),
        'country_code': value.countryCode,
        'buyer_category_id': value.buyerCategoryId,
        'buyer_category_nulls': value.buyerCategoryNulls,
        'buyer_country_codes': value.buyerCountryCodes,
        'buyer_region_ids': value.buyerRegionIds,
        'value': ViewConfigurationFilterSettingsNumericRangeSettingToJSON(value.value),
        'value_nulls': value.valueNulls,
        'show_user_hidden_leads': value.showUserHiddenLeads,
        'include_all_disqualified_states': value.includeAllDisqualifiedStates,
        'assignee': value.assignee,
        'keywords': value.keywords,
        'exclude_keywords': value.excludeKeywords,
        'suppliers': ViewConfigurationFilterSettingsSuppliersToJSON(value.suppliers),
        'frameworks': ViewConfigurationFilterSettingsFrameworksToJSON(value.frameworks),
        'cpv_dimensions': value.cpvDimensions,
        'cpv_codes': value.cpvCodes,
        'procurement_stage_qualifications': value.procurementStageQualifications,
        'buyer_list_ids': value.buyerListIds,
        'signal_category': value.signalCategory,
        'signals': ViewConfigurationFilterSettingsSignalsToJSON(value.signals),
        'relevance_score': value.relevanceScore,
        'notice_list': value.noticeList,
        'notice_lists': value.noticeLists,
    };
}

