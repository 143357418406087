import { assertCurrentUser } from "lib/currentUser";
import { DefaultApi, SimpleUserDto } from "lib/generated/app-api";

// TODO: Make this class abstract after updating to TS 4.2
export class ICompanyDataProvider {
  getUsers(): Promise<SimpleUserDto[]> {
    throw new Error("Not implemented");
  }
}

export class CompanyDataProvider extends ICompanyDataProvider {
  private users: Promise<SimpleUserDto[]> | undefined;

  constructor(private readonly api: DefaultApi) {
    super();
  }

  async getUsers(): Promise<SimpleUserDto[]> {
    if (this.users) {
      return this.users;
    }

    const fetchUsers = async () => {
      const currentUser = assertCurrentUser();
      const { users } = await this.api.getUsers({ companyGuid: currentUser.company.guid });
      return users;
    };
    this.users = fetchUsers();
    return this.users;
  }
}
