import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { SelectProps } from "antd5/lib/select";
import { isEqual } from "lodash";

import { BuyerSelect } from "./BuyerSelect";

type Value<I extends "guid" | "id"> = I extends "guid" ? string[] : number[];

type BuyerMultiselectProps<I extends "guid" | "id"> = {
  optionLabelProp?: SelectProps["optionLabelProp"];
  customPlaceholder?: string;
  noMaxTagCount?: boolean;
  countryCodes?: string[];
  disabled?: boolean;
  showVerified?: boolean;
  id?: string;
  identifier: I;
  buyerIds: Value<I> | undefined;
  onBuyerIdsChange: (newIds: Value<I>) => void;
};

/**
 * @deprecated use BuyerSelect and react hook form instead
 */
function BuyerMultiselect<I extends "guid" | "id">(props: BuyerMultiselectProps<I>): JSX.Element {
  const { onBuyerIdsChange, identifier, buyerIds } = props;

  // the orignal typings of this input don't work well with react hook form
  const buyerIdss: number[] = props.identifier === "id" ? (buyerIds as number[]) : [];
  const buyerGuids: string[] = props.identifier === "guid" ? (buyerIds as string[]) : [];
  const { control, watch } = useForm<{ buyerIds: number[]; buyerGuids: string[] }>({
    defaultValues: { buyerIds: buyerIdss, buyerGuids },
  });

  const fieldValue = watch(identifier === "id" ? "buyerIds" : "buyerGuids");

  useEffect(() => {
    if (isEqual(fieldValue, buyerIds || [])) {
      return;
    }
    onBuyerIdsChange(fieldValue as Value<I>);
  }, [onBuyerIdsChange, fieldValue, buyerIds]);

  return (
    <form>
      <BuyerSelect
        {...props}
        name={identifier === "id" ? "buyerIds" : "buyerGuids"}
        label=""
        userBuyerIdOptions={identifier === "id"}
        control={control}
        mode="multiple"
      />
    </form>
  );
}

export default BuyerMultiselect;
