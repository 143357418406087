/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColumnSetting
 */
export interface ColumnSetting {
    /**
     * 
     * @type {string}
     * @memberof ColumnSetting
     */
    field: ColumnSettingFieldEnum;
    /**
     * 
     * @type {number}
     * @memberof ColumnSetting
     */
    width?: number;
    /**
     * 
     * @type {string}
     * @memberof ColumnSetting
     */
    title?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnSetting
     */
    disabled?: boolean;
}


/**
 * @export
 */
export const ColumnSettingFieldEnum = {
    DateAdded: 'date_added',
    Title: 'title',
    Indicator: 'indicator',
    RelevanceScore: 'relevance_score',
    Stage: 'stage',
    CloseDate: 'close_date',
    Buyer: 'buyer',
    UserTag: 'user_tag',
    PublishedDate: 'published_date',
    ExpiryDate: 'expiry_date',
    AwardDate: 'award_date',
    StartDate: 'start_date',
    Value: 'value',
    Country: 'country',
    Suppliers: 'suppliers',
    IsSme: 'is_sme',
    Qualification: 'qualification',
    HiddenDate: 'hidden_date',
    BuyerCategories: 'buyer_categories',
    Assignee: 'assignee',
    BuyerListId: 'buyer_list_id',
    BuyerListIds: 'buyer_list_ids',
    Framework: 'framework'
} as const;
export type ColumnSettingFieldEnum = typeof ColumnSettingFieldEnum[keyof typeof ColumnSettingFieldEnum];


/**
 * Check if a given object implements the ColumnSetting interface.
 */
export function instanceOfColumnSetting(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "field" in value;

    return isInstance;
}

export function ColumnSettingFromJSON(json: any): ColumnSetting {
    return ColumnSettingFromJSONTyped(json, false);
}

export function ColumnSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'field': json['field'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'disabled': !exists(json, 'disabled') ? undefined : json['disabled'],
    };
}

export function ColumnSettingToJSON(value?: ColumnSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'field': value.field,
        'width': value.width,
        'title': value.title,
        'disabled': value.disabled,
    };
}

