/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchReferenceOrgsRequest
 */
export interface SearchReferenceOrgsRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchReferenceOrgsRequest
     */
    sort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReferenceOrgsRequest
     */
    sortOrder?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SearchReferenceOrgsRequest
     */
    limit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchReferenceOrgsRequest
     */
    offset?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SearchReferenceOrgsRequest
     */
    source?: SearchReferenceOrgsRequestSourceEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchReferenceOrgsRequest
     */
    text?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchReferenceOrgsRequest
     */
    sourceIdentifier?: Array<string>;
}


/**
 * @export
 */
export const SearchReferenceOrgsRequestSourceEnum = {
    Oscar: 'oscar',
    CompaniesHouse: 'companies_house',
    DunAndBradstreet: 'dun_and_bradstreet'
} as const;
export type SearchReferenceOrgsRequestSourceEnum = typeof SearchReferenceOrgsRequestSourceEnum[keyof typeof SearchReferenceOrgsRequestSourceEnum];


/**
 * Check if a given object implements the SearchReferenceOrgsRequest interface.
 */
export function instanceOfSearchReferenceOrgsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchReferenceOrgsRequestFromJSON(json: any): SearchReferenceOrgsRequest {
    return SearchReferenceOrgsRequestFromJSONTyped(json, false);
}

export function SearchReferenceOrgsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchReferenceOrgsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'sortOrder': !exists(json, 'sort_order') ? undefined : json['sort_order'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'sourceIdentifier': !exists(json, 'source_identifier') ? undefined : json['source_identifier'],
    };
}

export function SearchReferenceOrgsRequestToJSON(value?: SearchReferenceOrgsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sort': value.sort,
        'sort_order': value.sortOrder,
        'limit': value.limit,
        'offset': value.offset,
        'source': value.source,
        'text': value.text,
        'source_identifier': value.sourceIdentifier,
    };
}

