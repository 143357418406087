/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReportRecord } from './ReportRecord';
import {
    ReportRecordFromJSON,
    ReportRecordFromJSONTyped,
    ReportRecordToJSON,
} from './ReportRecord';

/**
 * 
 * @export
 * @interface RecordListSectionGenerated
 */
export interface RecordListSectionGenerated {
    /**
     * 
     * @type {Array<ReportRecord>}
     * @memberof RecordListSectionGenerated
     */
    records: Array<ReportRecord>;
    /**
     * 
     * @type {string}
     * @memberof RecordListSectionGenerated
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordListSectionGenerated
     */
    description?: string;
}

/**
 * Check if a given object implements the RecordListSectionGenerated interface.
 */
export function instanceOfRecordListSectionGenerated(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "records" in value;

    return isInstance;
}

export function RecordListSectionGeneratedFromJSON(json: any): RecordListSectionGenerated {
    return RecordListSectionGeneratedFromJSONTyped(json, false);
}

export function RecordListSectionGeneratedFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordListSectionGenerated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'records': ((json['records'] as Array<any>).map(ReportRecordFromJSON)),
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function RecordListSectionGeneratedToJSON(value?: RecordListSectionGenerated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'records': ((value.records as Array<any>).map(ReportRecordToJSON)),
        'title': value.title,
        'description': value.description,
    };
}

