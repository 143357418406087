/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PagingInfo } from './PagingInfo';
import {
    PagingInfoFromJSON,
    PagingInfoFromJSONTyped,
    PagingInfoToJSON,
} from './PagingInfo';
import type { RecordDto } from './RecordDto';
import {
    RecordDtoFromJSON,
    RecordDtoFromJSONTyped,
    RecordDtoToJSON,
} from './RecordDto';

/**
 * 
 * @export
 * @interface RecordSearchResponseDto
 */
export interface RecordSearchResponseDto {
    /**
     * 
     * @type {Array<RecordDto>}
     * @memberof RecordSearchResponseDto
     */
    results: Array<RecordDto>;
    /**
     * 
     * @type {PagingInfo}
     * @memberof RecordSearchResponseDto
     */
    pagingInfo: PagingInfo;
}

/**
 * Check if a given object implements the RecordSearchResponseDto interface.
 */
export function instanceOfRecordSearchResponseDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;
    isInstance = isInstance && "pagingInfo" in value;

    return isInstance;
}

export function RecordSearchResponseDtoFromJSON(json: any): RecordSearchResponseDto {
    return RecordSearchResponseDtoFromJSONTyped(json, false);
}

export function RecordSearchResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSearchResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(RecordDtoFromJSON)),
        'pagingInfo': PagingInfoFromJSON(json['paging_info']),
    };
}

export function RecordSearchResponseDtoToJSON(value?: RecordSearchResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(RecordDtoToJSON)),
        'paging_info': PagingInfoToJSON(value.pagingInfo),
    };
}

