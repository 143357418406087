import { ThemeConfig } from "antd5";

import * as colors from "../../lib/themes/colors";

export const DefaultTheme: ThemeConfig = {
  token: {
    fontFamily: "Inter, Lato, sans-serif",
    colorPrimaryTextHover: colors.blue700,
    colorPrimary: colors.blue600,
    colorPrimaryHover: colors.blue700,
    colorPrimaryActive: "#2951A6",
    colorPrimaryText: colors.blue600,
    colorPrimaryBg: colors.blue50,
    colorPrimaryBgHover: colors.blue100,
    colorPrimaryBorder: colors.blue600,
    colorPrimaryBorderHover: colors.blue700,
    colorWarning: "#fa822d",
    colorSuccess: "#47ab17",
    colorInfo: colors.blue600,
    colorInfoHover: colors.blue700,
    colorInfoTextHover: colors.blue700,
    colorInfoTextActive: "#2951A6",
    colorInfoActive: "#2951A6",
    colorInfoBg: colors.blue50,
    colorInfoBgHover: colors.blue100,
    colorInfoBorder: colors.blue600,
    colorInfoBorderHover: colors.blue700,
    colorTextBase: "#2d3b54",
    colorText: "rgba(45, 59, 84, 1)",
    colorTextSecondary: "rgba(45, 59, 84, 1)",
    colorTextTertiary: "rgba(45, 59, 84, 0.80)",
    colorTextQuaternary: "rgba(45, 59, 84, 0.4)",
    colorBorder: "#EBECF0",
    colorBorderSecondary: "#EBECF0",
    colorBgMask: "rgba(45, 59, 84, 0.5)",
    colorBgSpotlight: "rgba(45, 59, 84, 1)",
    colorError: colors.errorRed,
    fontSizeHeading2: 32,
    fontSizeHeading1: 40,
    lineHeight: 1.57,
    lineHeightSM: 1.67,
    lineHeightLG: 1.57,
    lineHeightHeading1: 1.5,
    lineHeightHeading2: 1.5,
    lineHeightHeading3: 1.5,
    lineHeightHeading4: 1.5,
    borderRadius: 4,
    borderRadiusSM: 2,
    borderRadiusLG: 8,
  },
  components: {
    Layout: { headerBg: "#ffffff" },
    Button: {},
    Collapse: {
      colorBgContainer: "#fff",
      colorFillAlter: "#fff",
    },
    Tour: {
      colorBgContainer: colors.blue900,
      colorText: "#fff",
      colorIcon: "#fff",
      colorIconHover: "#fff",
    },
    Progress: {
      marginXS: 0,
    },
    Input: {
      colorIcon: colors.blue600,
      colorIconHover: colors.blue700,
    },
    Menu: {
      itemHoverBg: "#e6f7ff",
      itemPaddingInline: 0,
    },
    Table: {
      colorFillAlter: colors.opacityDark2,
      borderRadiusLG: 4,
    },
  },
};
