/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PagingInfo } from './PagingInfo';
import {
    PagingInfoFromJSON,
    PagingInfoFromJSONTyped,
    PagingInfoToJSON,
} from './PagingInfo';
import type { SupplierStat } from './SupplierStat';
import {
    SupplierStatFromJSON,
    SupplierStatFromJSONTyped,
    SupplierStatToJSON,
} from './SupplierStat';

/**
 * 
 * @export
 * @interface SuppliersV3Response
 */
export interface SuppliersV3Response {
    /**
     * 
     * @type {Array<SupplierStat>}
     * @memberof SuppliersV3Response
     */
    results: Array<SupplierStat>;
    /**
     * 
     * @type {PagingInfo}
     * @memberof SuppliersV3Response
     */
    pagingInfo: PagingInfo;
}

/**
 * Check if a given object implements the SuppliersV3Response interface.
 */
export function instanceOfSuppliersV3Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;
    isInstance = isInstance && "pagingInfo" in value;

    return isInstance;
}

export function SuppliersV3ResponseFromJSON(json: any): SuppliersV3Response {
    return SuppliersV3ResponseFromJSONTyped(json, false);
}

export function SuppliersV3ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuppliersV3Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(SupplierStatFromJSON)),
        'pagingInfo': PagingInfoFromJSON(json['paging_info']),
    };
}

export function SuppliersV3ResponseToJSON(value?: SuppliersV3Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(SupplierStatToJSON)),
        'paging_info': PagingInfoToJSON(value.pagingInfo),
    };
}

