import React from "react";
import { FieldValues } from "react-hook-form";
import { Select as AntSelect } from "antd5";
import classNames from "classnames";

import { useBuyerLists } from "lib/hooks/api/buyer_lists/useBuyerLists";
import { Select, SelectProps } from "./Inputs";

import css from "./BuyerListSelect.module.scss";

type Props = {
  selected?: string[];
  onSelect: (selected: string[]) => void;
  disabled?: boolean;
  placeholder?: string;
  showArrow?: boolean;
  className?: string;
};

export function BuyerListSelect({
  selected,
  onSelect,
  disabled,
  placeholder,
  showArrow,
  className,
}: Props) {
  const { data: lists } = useBuyerLists();

  const options = [
    {
      label: "Private",
      options: lists?.private.map((list) => ({ label: list.name, value: list.id })) || [],
    },
    {
      label: "Team",
      options: lists?.team.map((list) => ({ label: list.name, value: list.id })) || [],
    },
  ];

  return (
    <AntSelect
      placeholder={placeholder || "Select..."}
      className={classNames(css.buyerListSelect, className)}
      value={selected}
      onChange={onSelect}
      disabled={disabled}
      allowClear={true}
      mode="multiple"
      showArrow={showArrow}
      options={options}
      optionFilterProp="label"
    />
  );
}

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options">;
export function SelectBuyerList<T extends FieldValues>(props: FieldProps<T>) {
  const { data: lists, isLoading } = useBuyerLists();
  const options = [
    {
      label: "Private",
      options: lists?.private.map((list) => ({ label: list.name, value: list.id })) || [],
    },
    {
      label: "Team",
      options: lists?.team.map((list) => ({ label: list.name, value: list.id })) || [],
    },
  ];

  return <Select {...props} loading={isLoading} options={options} optionFilterProp="label" />;
}
