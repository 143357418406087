import React from "react";
import { Select } from "antd5";
import { OptionProps } from "antd5/es/select";

import { useOwnerSearchProvider } from "lib/hooks/api/integrations/useOwnerSearchProvider";
import FormItemWrapper from "../FormItemWrapper";

import css from "../formStyles.module.scss";

type OwnerSearchProps = {
  id?: string;
  label: string;
  required?: boolean;
  value: string;
  updateValue: (value: string) => void;
  errors: string;
  description?: string;
};

const { Option } = Select;

export function OwnerSearch({
  id,
  label,
  required,
  value,
  updateValue,
  errors,
  description,
}: OwnerSearchProps): JSX.Element {
  const { data: owner, isLoading } = useOwnerSearchProvider();
  return (
    <FormItemWrapper
      formIdentifier="string"
      label={label}
      required={required}
      errors={errors}
      description={description}
    >
      <Select
        id={id}
        loading={isLoading}
        allowClear={!required}
        defaultValue={value}
        placeholder="Search Owners..."
        className={css.fullWidth}
        showSearch
        filterOption={(inputValue, option) => {
          if (!option) return false;

          return (option.props.children as string)
            .toString()
            .toLowerCase()
            .includes(inputValue.toLowerCase());
        }}
        onSelect={(
          _: string,
          o: React.ReactElement<OptionProps, string | React.JSXElementConstructor<unknown>>,
        ) => {
          updateValue(o.key?.toString() ?? "");
        }}
      >
        {owner?.map((x) => (
          <Option key={x.id} value={x.id}>
            {x.name} - {x.email}
          </Option>
        ))}
      </Select>
    </FormItemWrapper>
  );
}
