/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DescribeUserRequest
 */
export interface DescribeUserRequest {
    /**
     * 
     * @type {string}
     * @memberof DescribeUserRequest
     */
    guid: string;
}

/**
 * Check if a given object implements the DescribeUserRequest interface.
 */
export function instanceOfDescribeUserRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "guid" in value;

    return isInstance;
}

export function DescribeUserRequestFromJSON(json: any): DescribeUserRequest {
    return DescribeUserRequestFromJSONTyped(json, false);
}

export function DescribeUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DescribeUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'guid': json['guid'],
    };
}

export function DescribeUserRequestToJSON(value?: DescribeUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'guid': value.guid,
    };
}

