import { RecordDetails, SupplierSummary } from "lib/types/models";

export function extractUniqueSuppliersFromRecord(notice: RecordDetails): SupplierSummary[] {
  if (!notice.lots) {
    return [];
  }
  const suppliers: SupplierSummary[] = [];
  for (const lot of notice.lots) {
    if (!lot.suppliers) continue;
    lot.suppliers.forEach((s) => {
      // This dedupes the suppliers in case there are some that are in multiple lots
      if (!suppliers.some((us) => us.id === s.id)) suppliers.push(s);
    });
  }
  return suppliers;
}
