/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupplierFiltersBuyerFilters
 */
export interface SupplierFiltersBuyerFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierFiltersBuyerFilters
     */
    buyerId?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierFiltersBuyerFilters
     */
    buyerListIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierFiltersBuyerFilters
     */
    buyerCategoryIds?: Array<string> | null;
}

/**
 * Check if a given object implements the SupplierFiltersBuyerFilters interface.
 */
export function instanceOfSupplierFiltersBuyerFilters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SupplierFiltersBuyerFiltersFromJSON(json: any): SupplierFiltersBuyerFilters {
    return SupplierFiltersBuyerFiltersFromJSONTyped(json, false);
}

export function SupplierFiltersBuyerFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierFiltersBuyerFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buyerId': !exists(json, 'buyer_id') ? undefined : json['buyer_id'],
        'buyerListIds': !exists(json, 'buyer_list_ids') ? undefined : json['buyer_list_ids'],
        'buyerCategoryIds': !exists(json, 'buyer_category_ids') ? undefined : json['buyer_category_ids'],
    };
}

export function SupplierFiltersBuyerFiltersToJSON(value?: SupplierFiltersBuyerFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buyer_id': value.buyerId,
        'buyer_list_ids': value.buyerListIds,
        'buyer_category_ids': value.buyerCategoryIds,
    };
}

