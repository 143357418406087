/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    address2?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    address3?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    town?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    countryCode: string;
}

/**
 * Check if a given object implements the Address interface.
 */
export function instanceOfAddress(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "countryCode" in value;

    return isInstance;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : json['address'],
        'address2': !exists(json, 'address2') ? undefined : json['address2'],
        'address3': !exists(json, 'address3') ? undefined : json['address3'],
        'town': !exists(json, 'town') ? undefined : json['town'],
        'postCode': !exists(json, 'post_code') ? undefined : json['post_code'],
        'countryCode': json['country_code'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'address2': value.address2,
        'address3': value.address3,
        'town': value.town,
        'post_code': value.postCode,
        'country_code': value.countryCode,
    };
}

