import * as React from "react";
import { Popover } from "antd5";

import TextButton from "components/actions/TextButton";
import { extractLinkRegions } from "lib/comment_utils";
import { TextMatchHighlighter } from "lib/core_components/TextMatchHighlighter";
import { assertCurrentUser } from "lib/currentUser";
import { CommentDto } from "lib/generated/app-api";
import { useDeleteComment } from "lib/hooks/api/notices/comments/useDeleteComment";
import { DateGranularity, toHumanRelativeTimeFromNow } from "lib/utils/relative_time";
import CommentInput from "./CommentInput";
import UserInitials from "./UserInitials";

import css from "./CommentSection.module.scss";

const charLimit = 500;

type CommentProps = {
  comment: CommentDto;
  recordGuid: string;
  procurementStageId: string;
};

export function CommentSection({
  comment,
  recordGuid,
  procurementStageId,
}: CommentProps): JSX.Element {
  const [expanded, setExpanded] = React.useState(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [editing, setEditing] = React.useState<boolean>(false);

  const { mutate: deleteComment, isLoading: isDeleting } = useDeleteComment({
    onSuccess: () => {
      setDeleting(false);
    },
  });
  const currentUser = assertCurrentUser();

  const longText = React.useMemo(() => {
    const matches = extractLinkRegions(comment.content);

    return <TextMatchHighlighter text={comment.content} matches={matches} />;
  }, [comment.content]);

  const shortText = React.useMemo(() => {
    if (comment.content && comment.content.length > charLimit) {
      const shortText = comment.content.slice(0, charLimit) + "...";
      const matches = extractLinkRegions(shortText);

      return <TextMatchHighlighter text={shortText} matches={matches} />;
    }
  }, [comment.content]);

  return (
    <div className={css.container}>
      {editing ? (
        <CommentInput
          onSave={() => setEditing(false)}
          onCancel={() => setEditing(false)}
          comment={comment}
          recordGuid={recordGuid}
          procurementStageId={procurementStageId}
        />
      ) : (
        <>
          <div className={css.header}>
            <UserInitials firstName={comment.author.firstName} lastName={comment.author.lastName} />
            <span className={css.userName}>
              {comment.author.firstName} {comment.author.lastName}
            </span>
            <span className={css.displayTime}>
              {toHumanRelativeTimeFromNow(comment.createdAt, DateGranularity.second)}
            </span>
            {comment.lastEditedAt && (
              <Popover
                className={css.displayEditedTime}
                content={toHumanRelativeTimeFromNow(comment.lastEditedAt, DateGranularity.second)}
              >
                Edited
              </Popover>
            )}
            {currentUser.guid === comment.author.guid &&
              !comment.deletedAt &&
              (deleting ? (
                <div className={css.editButtons}>
                  <span>Delete this comment?</span>
                  <TextButton onClick={() => setDeleting(false)}>No, cancel</TextButton>
                  <TextButton
                    danger
                    onClick={() =>
                      deleteComment({ id: comment.id, recordGuid, procurementStageId })
                    }
                    loading={isDeleting}
                  >
                    Yes, delete
                  </TextButton>
                </div>
              ) : (
                <div className={css.editButtons}>
                  <TextButton onClick={() => setEditing(true)}>Edit</TextButton>
                  <TextButton danger onClick={() => setDeleting(true)}>
                    Delete
                  </TextButton>
                </div>
              ))}
          </div>
          <div className={css.contentsContainer}>
            <p className={css.content}>
              {comment.deletedAt ? (
                <>This comment has been deleted</>
              ) : comment.content.length > charLimit ? (
                <>
                  {expanded ? longText : shortText}
                  <TextButton
                    underline
                    className={css.showMoreButton}
                    onClick={() => setExpanded((ex) => !ex)}
                  >
                    {expanded ? "Read less" : "Read more"}
                  </TextButton>
                </>
              ) : (
                longText
              )}
            </p>
          </div>
        </>
      )}
    </div>
  );
}
