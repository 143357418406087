/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregationResult } from './AggregationResult';
import {
    AggregationResultFromJSON,
    AggregationResultFromJSONTyped,
    AggregationResultToJSON,
} from './AggregationResult';

/**
 * 
 * @export
 * @interface AggregationGroupResult
 */
export interface AggregationGroupResult {
    /**
     * 
     * @type {string}
     * @memberof AggregationGroupResult
     */
    group: string;
    /**
     * 
     * @type {Array<AggregationResult>}
     * @memberof AggregationGroupResult
     */
    aggregations: Array<AggregationResult>;
}

/**
 * Check if a given object implements the AggregationGroupResult interface.
 */
export function instanceOfAggregationGroupResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "group" in value;
    isInstance = isInstance && "aggregations" in value;

    return isInstance;
}

export function AggregationGroupResultFromJSON(json: any): AggregationGroupResult {
    return AggregationGroupResultFromJSONTyped(json, false);
}

export function AggregationGroupResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AggregationGroupResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'group': json['group'],
        'aggregations': ((json['aggregations'] as Array<any>).map(AggregationResultFromJSON)),
    };
}

export function AggregationGroupResultToJSON(value?: AggregationGroupResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group': value.group,
        'aggregations': ((value.aggregations as Array<any>).map(AggregationResultToJSON)),
    };
}

