/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssignProcurementStageAssignee } from './AssignProcurementStageAssignee';
import {
    AssignProcurementStageAssigneeFromJSON,
    AssignProcurementStageAssigneeFromJSONTyped,
    AssignProcurementStageAssigneeToJSON,
} from './AssignProcurementStageAssignee';

/**
 * 
 * @export
 * @interface AssignProcurementStage
 */
export interface AssignProcurementStage {
    /**
     * 
     * @type {string}
     * @memberof AssignProcurementStage
     */
    actorName: string;
    /**
     * 
     * @type {string}
     * @memberof AssignProcurementStage
     */
    recordTitle: string;
    /**
     * 
     * @type {AssignProcurementStageAssignee}
     * @memberof AssignProcurementStage
     */
    oldAssignee: AssignProcurementStageAssignee;
    /**
     * 
     * @type {AssignProcurementStageAssignee}
     * @memberof AssignProcurementStage
     */
    newAssignee: AssignProcurementStageAssignee;
}

/**
 * Check if a given object implements the AssignProcurementStage interface.
 */
export function instanceOfAssignProcurementStage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "actorName" in value;
    isInstance = isInstance && "recordTitle" in value;
    isInstance = isInstance && "oldAssignee" in value;
    isInstance = isInstance && "newAssignee" in value;

    return isInstance;
}

export function AssignProcurementStageFromJSON(json: any): AssignProcurementStage {
    return AssignProcurementStageFromJSONTyped(json, false);
}

export function AssignProcurementStageFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignProcurementStage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'actorName': json['actor_name'],
        'recordTitle': json['record_title'],
        'oldAssignee': AssignProcurementStageAssigneeFromJSON(json['old_assignee']),
        'newAssignee': AssignProcurementStageAssigneeFromJSON(json['new_assignee']),
    };
}

export function AssignProcurementStageToJSON(value?: AssignProcurementStage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'actor_name': value.actorName,
        'record_title': value.recordTitle,
        'old_assignee': AssignProcurementStageAssigneeToJSON(value.oldAssignee),
        'new_assignee': AssignProcurementStageAssigneeToJSON(value.newAssignee),
    };
}

