/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DecisionToEdit } from './DecisionToEdit';
import {
    DecisionToEditFromJSON,
    DecisionToEditFromJSONTyped,
    DecisionToEditToJSON,
} from './DecisionToEdit';

/**
 * 
 * @export
 * @interface SubmitEditOrgCleaningTaskRequest
 */
export interface SubmitEditOrgCleaningTaskRequest {
    /**
     * 
     * @type {Array<DecisionToEdit>}
     * @memberof SubmitEditOrgCleaningTaskRequest
     */
    decisionsToEdit: Array<DecisionToEdit>;
}

/**
 * Check if a given object implements the SubmitEditOrgCleaningTaskRequest interface.
 */
export function instanceOfSubmitEditOrgCleaningTaskRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "decisionsToEdit" in value;

    return isInstance;
}

export function SubmitEditOrgCleaningTaskRequestFromJSON(json: any): SubmitEditOrgCleaningTaskRequest {
    return SubmitEditOrgCleaningTaskRequestFromJSONTyped(json, false);
}

export function SubmitEditOrgCleaningTaskRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubmitEditOrgCleaningTaskRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'decisionsToEdit': ((json['decisions_to_edit'] as Array<any>).map(DecisionToEditFromJSON)),
    };
}

export function SubmitEditOrgCleaningTaskRequestToJSON(value?: SubmitEditOrgCleaningTaskRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'decisions_to_edit': ((value.decisionsToEdit as Array<any>).map(DecisionToEditToJSON)),
    };
}

