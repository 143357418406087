import * as React from "react";

export default function Drag(): JSX.Element {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.64253 7.35681H12.6068V8.64253H8.64253H7.35503H3.39074L3.39253 7.35681H7.35681H8.64253Z"
        fill="#565656"
      />
      <path
        d="M8.64252 3.39251V6.21394H7.3568V3.39251H6.20144C6.17719 3.39257 6.15341 3.3858 6.13282 3.37296C6.11224 3.36013 6.09569 3.34176 6.08507 3.31995C6.07444 3.29815 6.07018 3.27379 6.07276 3.24967C6.07534 3.22555 6.08466 3.20265 6.09966 3.18359L7.89966 0.905014C7.91157 0.889595 7.92686 0.877112 7.94435 0.868523C7.96184 0.859935 7.98107 0.855469 8.00055 0.855469C8.02004 0.855469 8.03926 0.859935 8.05675 0.868523C8.07424 0.877112 8.08953 0.889595 8.10145 0.905014L9.90144 3.18359C9.96752 3.26751 9.9068 3.39251 9.79966 3.39251H8.64252Z"
        fill="#565656"
      />
      <path
        d="M7.35502 12.6068V9.78537H8.64252V12.6068H9.79609C9.90502 12.6068 9.96394 12.7318 9.89787 12.8157L8.09787 15.0925C8.04787 15.1586 7.94787 15.1586 7.89609 15.0925L6.09609 12.8157C6.03002 12.7318 6.09073 12.6068 6.19787 12.6068H7.35502Z"
        fill="#565656"
      />
    </svg>
  );
}
