/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchEntitiesResponseResultEntityResultMetadata
 */
export interface SearchEntitiesResponseResultEntityResultMetadata {
    /**
     * 
     * @type {number}
     * @memberof SearchEntitiesResponseResultEntityResultMetadata
     */
    score: number;
    /**
     * 
     * @type {object}
     * @memberof SearchEntitiesResponseResultEntityResultMetadata
     */
    scoreExplanation: object;
    /**
     * 
     * @type {number}
     * @memberof SearchEntitiesResponseResultEntityResultMetadata
     */
    stotlesScore: number;
    /**
     * 
     * @type {number}
     * @memberof SearchEntitiesResponseResultEntityResultMetadata
     */
    vectorSimilarity?: number | null;
}

/**
 * Check if a given object implements the SearchEntitiesResponseResultEntityResultMetadata interface.
 */
export function instanceOfSearchEntitiesResponseResultEntityResultMetadata(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "score" in value;
    isInstance = isInstance && "scoreExplanation" in value;
    isInstance = isInstance && "stotlesScore" in value;

    return isInstance;
}

export function SearchEntitiesResponseResultEntityResultMetadataFromJSON(json: any): SearchEntitiesResponseResultEntityResultMetadata {
    return SearchEntitiesResponseResultEntityResultMetadataFromJSONTyped(json, false);
}

export function SearchEntitiesResponseResultEntityResultMetadataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchEntitiesResponseResultEntityResultMetadata {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'score': json['score'],
        'scoreExplanation': json['score_explanation'],
        'stotlesScore': json['stotles_score'],
        'vectorSimilarity': !exists(json, 'vector_similarity') ? undefined : json['vector_similarity'],
    };
}

export function SearchEntitiesResponseResultEntityResultMetadataToJSON(value?: SearchEntitiesResponseResultEntityResultMetadata | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'score': value.score,
        'score_explanation': value.scoreExplanation,
        'stotles_score': value.stotlesScore,
        'vector_similarity': value.vectorSimilarity,
    };
}

