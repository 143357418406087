/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchResponseResultsInner } from './SearchResponseResultsInner';
import {
    SearchResponseResultsInnerFromJSON,
    SearchResponseResultsInnerFromJSONTyped,
    SearchResponseResultsInnerToJSON,
} from './SearchResponseResultsInner';

/**
 * 
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {number}
     * @memberof SearchResponse
     */
    total: number;
    /**
     * 
     * @type {Array<SearchResponseResultsInner>}
     * @memberof SearchResponse
     */
    results: Array<SearchResponseResultsInner>;
}

/**
 * Check if a given object implements the SearchResponse interface.
 */
export function instanceOfSearchResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "total" in value;
    isInstance = isInstance && "results" in value;

    return isInstance;
}

export function SearchResponseFromJSON(json: any): SearchResponse {
    return SearchResponseFromJSONTyped(json, false);
}

export function SearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'results': ((json['results'] as Array<any>).map(SearchResponseResultsInnerFromJSON)),
    };
}

export function SearchResponseToJSON(value?: SearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'results': ((value.results as Array<any>).map(SearchResponseResultsInnerToJSON)),
    };
}

