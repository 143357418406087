import React from "react";
import { EnumOption } from "@jsonforms/core";
import { Select } from "antd5";

import FormItemWrapper from "../FormItemWrapper";

import css from "../formStyles.module.scss";

const { Option } = Select;

type EnumProps = {
  id?: string;
  label: string;
  required?: boolean;
  value: string;
  options?: EnumOption[];
  updateValue: (value: string) => void;
  errors: string;
  description?: string;
};

export function Enum({
  id,
  label,
  required,
  options,
  value,
  updateValue,
  errors,
  description,
}: EnumProps): JSX.Element {
  return (
    <FormItemWrapper
      errors={errors}
      formIdentifier="string"
      label={label}
      required={required}
      description={description}
    >
      <Select id={id} onSelect={updateValue} value={value} className={css.fullWidth} allowClear>
        {options?.map((option) => {
          return (
            <Option key={option.value} label={option.label} value={option.value}>
              {option.label}
            </Option>
          );
        })}
      </Select>
    </FormItemWrapper>
  );
}
