import * as React from "react";

import { ChartBar } from "lib/core_components/ChartBar";

import css from "./CpvMiniChart.module.scss";

type CpvMiniChartProps = {
  cpvCode: string;
  description: string;
  matchingRecordsCount: number;
  totalRecordsCount: number;
  matchingRecordsValue: number;
  totalRecordsValue: number;
  link?: string;
};

export function CpvMiniChart({
  cpvCode,
  description,
  matchingRecordsCount,
  totalRecordsCount,
  matchingRecordsValue,
  totalRecordsValue,
  link,
}: CpvMiniChartProps): JSX.Element | null {
  if (matchingRecordsCount === 0 && matchingRecordsValue === 0) {
    return null;
  }

  const countPercentage =
    totalRecordsCount !== 0 ? (matchingRecordsCount / totalRecordsCount) * 100 : 0;
  const valuePercentage =
    totalRecordsValue !== 0 ? (matchingRecordsValue / totalRecordsValue) * 100 : 0;

  return (
    <div className={css.cpvMiniChart}>
      <div className={css.title}>
        {description} - <span>{cpvCode}</span>
      </div>
      <div className={css.chartBars}>
        <ChartBar
          label={matchingRecordsCount.toLocaleString("en-GB")}
          percentage={countPercentage}
          colour="mid-blue"
          tooltipContent="See contracts"
          link={link}
          cpvCode={cpvCode}
        />
        <ChartBar
          label={`£${matchingRecordsValue.toLocaleString("en-GB")}`}
          percentage={valuePercentage}
          colour="light-blue"
          tooltipContent="See contracts"
          link={link}
          cpvCode={cpvCode}
        />
      </div>
    </div>
  );
}
