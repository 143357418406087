import { UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  CreateOpportunityMutation,
  CreateOpportunityMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const createOpportunity = graphql(`
  mutation createOpportunity(
    $opportunity: CreateOpportunity!
    $entities: OpportunityEntitiesInput!
  ) {
    createOpportunity(entities: $entities, opportunity: $opportunity) {
      id
    }
  }
`);

export function useCreateOpportunity(
  options?: UseMutationOptions<
    CreateOpportunityMutation,
    unknown,
    CreateOpportunityMutationVariables,
    unknown
  >,
) {
  const queryClient = useQueryClient();
  const { data, ...rest } = useGraphQlMutation(createOpportunity, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(["opportunities"]);
      options?.onSuccess?.(data, variables, context);
    },
  });
  return { data: data?.createOpportunity, ...rest };
}
