import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { RecordDto, RecordSearchResponseDto } from "lib/generated/app-api";
import { NoticesQuery } from "lib/generated/app-service-gql/graphql";
import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";
import { SearchRequestQualifications } from "lib/types/graphQLEnums";
import { NoticeSearchQueryKey } from "../notices/useNotices";
import { updateCachedNotice } from "../notices/utils";
import { RecordSearchQueryKey } from "../useRecordSearch";

type Params = {
  procurementStage: { id: string; stage: string };
  recordGuid: string;
  signalTypes: Record<string, string[]> | undefined;
  score: number | null | undefined;
  contextSource: string;
};

export function useDeleteRecordQualification(
  options?: UseMutationOptions<unknown, unknown, Params, unknown>,
) {
  const api = useOpenApi();
  const { logEvent } = useTracking();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ procurementStage }: Params) => {
      const procurementStageId = procurementStage.id;
      return api.deleteProcurementStageQualification({
        deleteQualificationRequest: { procurementStageId },
      });
    },
    {
      ...options,
      onSuccess: (data, vars, ctx) => {
        const { recordGuid, procurementStage, score, signalTypes, contextSource } = vars;

        logEvent(EventNames.qualificationActioned, {
          "Record GUID": recordGuid,
          "Signal score": score ?? "0",
          "Context source": contextSource,
          "Qualification label": "Unqualified",
          Stage: procurementStage.stage,
          "Signal types": signalTypes ? Object.keys(signalTypes) : [],
          Signals: signalTypes,
        });

        // Update the individual record cache with the new qualification
        queryClient.setQueryData<RecordDto>(["record", recordGuid], (result) => {
          if (!result) {
            return result;
          }

          return {
            ...result,
            procurementStage: {
              ...result.procurementStage,
              procurementStageQualification: undefined,
            },
          };
        });

        updateCachedNotice(queryClient, vars.recordGuid, (r) => ({
          ...r,
          qualification: undefined,
        }));

        const notices = queryClient.getQueriesData<NoticesQuery>(["notices"]);
        notices.forEach(([key, searchData]) => {
          if (!searchData) {
            // no data in cache continue the loop
            return;
          }
          const [, searchParams] = key as NoticeSearchQueryKey;
          const noticeIndex = searchData.notices.results.findIndex(
            (r) => r.guid === vars.recordGuid,
          );
          // we need to update qualification in the cache
          if (noticeIndex > -1) {
            let removeRecord = false;
            // we need to remove the record from the cache as the record is no longer part of the filters
            if (
              searchParams.qualifications &&
              searchParams.qualifications.length > 0 &&
              !searchParams.qualifications.includes(SearchRequestQualifications.Unqualified)
            ) {
              removeRecord = true;
            }

            queryClient.setQueryData<NoticesQuery>(key, (resp) => {
              if (!resp) {
                return resp;
              }

              if (removeRecord) {
                return {
                  notices: {
                    ...resp.notices,
                    results: resp.notices.results.filter(
                      (notice) => notice.guid !== vars.recordGuid,
                    ),
                  },
                };
              }
              const results = [...resp.notices.results];

              results[noticeIndex] = {
                ...results[noticeIndex],
                qualification: undefined,
              };

              return {
                notices: { ...resp.notices, results },
              };
            });
          }
        });

        // Update the records cache with the new qualification
        const recordQueries = queryClient.getQueriesData<RecordSearchResponseDto>(["records"]);
        recordQueries.forEach(([key, searchData]) => {
          if (!searchData) {
            // no data in cache continue the loop
            return;
          }
          const [, searchParams] = key as RecordSearchQueryKey;
          const recordIndex = searchData.results.findIndex((r) => r.guid === vars.recordGuid);
          // we need to update qualification in the cache
          if (recordIndex > -1) {
            let removeRecord = false;
            // we need to remove the record from the cache as the record is no longer part of the filters
            if (
              searchParams.procurementStageQualifications &&
              searchParams.procurementStageQualifications.length > 0 &&
              !searchParams.procurementStageQualifications.includes("unqualified")
            ) {
              removeRecord = true;
            }

            queryClient.setQueryData<RecordSearchResponseDto>(key, (resp) => {
              if (!resp) {
                return resp;
              }
              if (removeRecord) {
                return {
                  ...resp,
                  results: resp.results.filter((record) => record.guid !== vars.recordGuid),
                };
              }
              const results = [...resp.results];
              results[recordIndex] = {
                ...results[recordIndex],
                procurementStage: {
                  ...results[recordIndex].procurementStage,
                  procurementStageQualification: undefined,
                },
              };
              return {
                ...resp,
                results,
              };
            });
          }
        });
        options?.onSuccess?.(data, vars, ctx);
      },
    },
  );
}
