/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CompanySubscriptionsResponse } from './CompanySubscriptionsResponse';
import {
    CompanySubscriptionsResponseFromJSON,
    CompanySubscriptionsResponseFromJSONTyped,
    CompanySubscriptionsResponseToJSON,
} from './CompanySubscriptionsResponse';
import type { PersonalSubscriptionsResponse } from './PersonalSubscriptionsResponse';
import {
    PersonalSubscriptionsResponseFromJSON,
    PersonalSubscriptionsResponseFromJSONTyped,
    PersonalSubscriptionsResponseToJSON,
} from './PersonalSubscriptionsResponse';

/**
 * 
 * @export
 * @interface GetLeadSubscriptionsResponse
 */
export interface GetLeadSubscriptionsResponse {
    /**
     * 
     * @type {PersonalSubscriptionsResponse}
     * @memberof GetLeadSubscriptionsResponse
     */
    personalSubscriptions: PersonalSubscriptionsResponse;
    /**
     * 
     * @type {CompanySubscriptionsResponse}
     * @memberof GetLeadSubscriptionsResponse
     */
    companySubscriptions: CompanySubscriptionsResponse;
}

/**
 * Check if a given object implements the GetLeadSubscriptionsResponse interface.
 */
export function instanceOfGetLeadSubscriptionsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "personalSubscriptions" in value;
    isInstance = isInstance && "companySubscriptions" in value;

    return isInstance;
}

export function GetLeadSubscriptionsResponseFromJSON(json: any): GetLeadSubscriptionsResponse {
    return GetLeadSubscriptionsResponseFromJSONTyped(json, false);
}

export function GetLeadSubscriptionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLeadSubscriptionsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'personalSubscriptions': PersonalSubscriptionsResponseFromJSON(json['personal_subscriptions']),
        'companySubscriptions': CompanySubscriptionsResponseFromJSON(json['company_subscriptions']),
    };
}

export function GetLeadSubscriptionsResponseToJSON(value?: GetLeadSubscriptionsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'personal_subscriptions': PersonalSubscriptionsResponseToJSON(value.personalSubscriptions),
        'company_subscriptions': CompanySubscriptionsResponseToJSON(value.companySubscriptions),
    };
}

