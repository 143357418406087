/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupplierSignalBuyersRequestFilters } from './SupplierSignalBuyersRequestFilters';
import {
    SupplierSignalBuyersRequestFiltersFromJSON,
    SupplierSignalBuyersRequestFiltersFromJSONTyped,
    SupplierSignalBuyersRequestFiltersToJSON,
} from './SupplierSignalBuyersRequestFilters';

/**
 * 
 * @export
 * @interface SupplierSignalBuyersRequest
 */
export interface SupplierSignalBuyersRequest {
    /**
     * 
     * @type {SupplierSignalBuyersRequestFilters}
     * @memberof SupplierSignalBuyersRequest
     */
    filters: SupplierSignalBuyersRequestFilters;
    /**
     * 
     * @type {string}
     * @memberof SupplierSignalBuyersRequest
     */
    signalName: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierSignalBuyersRequest
     */
    signalCategory: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierSignalBuyersRequest
     */
    sort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SupplierSignalBuyersRequest
     */
    sortOrder?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SupplierSignalBuyersRequest
     */
    limit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SupplierSignalBuyersRequest
     */
    offset?: number | null;
}

/**
 * Check if a given object implements the SupplierSignalBuyersRequest interface.
 */
export function instanceOfSupplierSignalBuyersRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "filters" in value;
    isInstance = isInstance && "signalName" in value;
    isInstance = isInstance && "signalCategory" in value;

    return isInstance;
}

export function SupplierSignalBuyersRequestFromJSON(json: any): SupplierSignalBuyersRequest {
    return SupplierSignalBuyersRequestFromJSONTyped(json, false);
}

export function SupplierSignalBuyersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierSignalBuyersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filters': SupplierSignalBuyersRequestFiltersFromJSON(json['filters']),
        'signalName': json['signal_name'],
        'signalCategory': json['signal_category'],
        'sort': !exists(json, 'sort') ? undefined : json['sort'],
        'sortOrder': !exists(json, 'sort_order') ? undefined : json['sort_order'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
    };
}

export function SupplierSignalBuyersRequestToJSON(value?: SupplierSignalBuyersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filters': SupplierSignalBuyersRequestFiltersToJSON(value.filters),
        'signal_name': value.signalName,
        'signal_category': value.signalCategory,
        'sort': value.sort,
        'sort_order': value.sortOrder,
        'limit': value.limit,
        'offset': value.offset,
    };
}

