/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter } from './BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter';
import {
    BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterFromJSON,
    BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterFromJSONTyped,
    BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterToJSON,
} from './BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter';
import type { BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategories } from './BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategories';
import {
    BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategoriesFromJSON,
    BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategoriesFromJSONTyped,
    BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategoriesToJSON,
} from './BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategories';

/**
 * 
 * @export
 * @interface BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters
 */
export interface BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters {
    /**
     * 
     * @type {BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter}
     * @memberof BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters
     */
    signals?: BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilter;
    /**
     * 
     * @type {string}
     * @memberof BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters
     */
    buyerName?: string | null;
    /**
     * 
     * @type {BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategories}
     * @memberof BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters
     */
    categories?: BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategories;
    /**
     * 
     * @type {Array<string>}
     * @memberof BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters
     */
    countryCodes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters
     */
    regionIds?: Array<string>;
}

/**
 * Check if a given object implements the BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters interface.
 */
export function instanceOfBuyersRelationshipStatsRequestBuyersRelationshipStatsFilters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersFromJSON(json: any): BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters {
    return BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersFromJSONTyped(json, false);
}

export function BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signals': !exists(json, 'signals') ? undefined : BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterFromJSON(json['signals']),
        'buyerName': !exists(json, 'buyer_name') ? undefined : json['buyer_name'],
        'categories': !exists(json, 'categories') ? undefined : BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategoriesFromJSON(json['categories']),
        'countryCodes': !exists(json, 'country_codes') ? undefined : json['country_codes'],
        'regionIds': !exists(json, 'region_ids') ? undefined : json['region_ids'],
    };
}

export function BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersToJSON(value?: BuyersRelationshipStatsRequestBuyersRelationshipStatsFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signals': BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterToJSON(value.signals),
        'buyer_name': value.buyerName,
        'categories': BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategoriesToJSON(value.categories),
        'country_codes': value.countryCodes,
        'region_ids': value.regionIds,
    };
}

