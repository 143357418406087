import * as React from "react";
import { CloseOutlined, CopyOutlined } from "@ant-design/icons";
import { Tag } from "antd5";

import css from "./OrgLabels.module.scss";

export function ParentLabel(): JSX.Element {
  return <Tag color="blue">PARENT</Tag>;
}
export function ChildLabel(): JSX.Element {
  return <Tag color="cyan">CHILD</Tag>;
}
export function RenamedLabel(): JSX.Element {
  return <Tag color="magenta">RENAMED</Tag>;
}
export function ReplacedLabel(): JSX.Element {
  return <Tag color="purple">REPLACED</Tag>;
}

export function AnchorLabel(): JSX.Element {
  return <Tag color="orange">ANCHOR</Tag>;
}

export function NewLabel(): JSX.Element {
  return <Tag color="green">NEW</Tag>;
}

// Duplicate selection labels

export function NotRelatedOption(): JSX.Element {
  return (
    <span className={css.notRelatedOption}>
      <CloseOutlined />
      Not mentioned
    </span>
  );
}
export function DuplicateOption(): JSX.Element {
  return (
    <span className={css.duplicateOption}>
      <CopyOutlined />
      Duplicate
    </span>
  );
}

export function ContainsMultipleOption(): JSX.Element {
  return <span className={css.containsMultipleOption}>Contains multiple entities</span>;
}
export function UnsureOption(): JSX.Element {
  return <span className={css.unsureOption}>Unsure</span>;
}
