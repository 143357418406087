import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { Owner } from "lib/generated/integration-api";
import { useIntegrationApi } from "lib/integrationApiContext";

type QueryKey = [string];

export function useOwnerSearchProvider(
  options?: UseQueryOptions<Owner[], unknown, Owner[], QueryKey>,
) {
  const api = useIntegrationApi();
  return useQuery(
    ["ownerSearchProvider"],
    async () => {
      const response = await api.searchOwners();
      return response.owners;
    },
    options,
  );
}
