/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AllBuyersStatsRequestAllBuyersStatsFiltersHqCountry
 */
export interface AllBuyersStatsRequestAllBuyersStatsFiltersHqCountry {
    /**
     * 
     * @type {Array<string>}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFiltersHqCountry
     */
    countries?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFiltersHqCountry
     */
    regions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFiltersHqCountry
     */
    selected?: Array<string>;
}

/**
 * Check if a given object implements the AllBuyersStatsRequestAllBuyersStatsFiltersHqCountry interface.
 */
export function instanceOfAllBuyersStatsRequestAllBuyersStatsFiltersHqCountry(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AllBuyersStatsRequestAllBuyersStatsFiltersHqCountryFromJSON(json: any): AllBuyersStatsRequestAllBuyersStatsFiltersHqCountry {
    return AllBuyersStatsRequestAllBuyersStatsFiltersHqCountryFromJSONTyped(json, false);
}

export function AllBuyersStatsRequestAllBuyersStatsFiltersHqCountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllBuyersStatsRequestAllBuyersStatsFiltersHqCountry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'countries': !exists(json, 'countries') ? undefined : json['countries'],
        'regions': !exists(json, 'regions') ? undefined : json['regions'],
        'selected': !exists(json, 'selected') ? undefined : json['selected'],
    };
}

export function AllBuyersStatsRequestAllBuyersStatsFiltersHqCountryToJSON(value?: AllBuyersStatsRequestAllBuyersStatsFiltersHqCountry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'countries': value.countries,
        'regions': value.regions,
        'selected': value.selected,
    };
}

