/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AllBuyersStatsRequestAllBuyersStatsFiltersCategories
 */
export interface AllBuyersStatsRequestAllBuyersStatsFiltersCategories {
    /**
     * 
     * @type {Array<string>}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFiltersCategories
     */
    categoryIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AllBuyersStatsRequestAllBuyersStatsFiltersCategories
     */
    includeUncategorised?: boolean | null;
}

/**
 * Check if a given object implements the AllBuyersStatsRequestAllBuyersStatsFiltersCategories interface.
 */
export function instanceOfAllBuyersStatsRequestAllBuyersStatsFiltersCategories(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AllBuyersStatsRequestAllBuyersStatsFiltersCategoriesFromJSON(json: any): AllBuyersStatsRequestAllBuyersStatsFiltersCategories {
    return AllBuyersStatsRequestAllBuyersStatsFiltersCategoriesFromJSONTyped(json, false);
}

export function AllBuyersStatsRequestAllBuyersStatsFiltersCategoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllBuyersStatsRequestAllBuyersStatsFiltersCategories {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryIds': !exists(json, 'category_ids') ? undefined : json['category_ids'],
        'includeUncategorised': !exists(json, 'include_uncategorised') ? undefined : json['include_uncategorised'],
    };
}

export function AllBuyersStatsRequestAllBuyersStatsFiltersCategoriesToJSON(value?: AllBuyersStatsRequestAllBuyersStatsFiltersCategories | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category_ids': value.categoryIds,
        'include_uncategorised': value.includeUncategorised,
    };
}

