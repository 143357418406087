import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import * as RadixDropdown from "@radix-ui/react-dropdown-menu";

import { fadeInAndSlideUp } from "../../../styles/animations";

type DropdownMenuProps = {
  trigger: ReactNode; // the element that will trigger the dropdown, e.g. a button.
  // the component must spread props and forward refs

  items: ReactNode[];
  align?: "start" | "center" | "end";
  width?: string | number;
  onOpenChange?: (open: boolean) => void;
};

function DropdownMenu({ trigger, items, width, onOpenChange }: DropdownMenuProps) {
  return (
    <Wrapper onOpenChange={onOpenChange}>
      <TriggerWrapper asChild>{trigger}</TriggerWrapper>
      <RadixDropdown.Portal>
        <Content align="start" sideOffset={4} width={width}>
          {items.map((item, index) => (
            <Item asChild key={index}>
              {item}
            </Item>
          ))}
        </Content>
      </RadixDropdown.Portal>
    </Wrapper>
  );
}

export default DropdownMenu;

const Wrapper = styled(RadixDropdown.Root)({
  zIndex: 3,
});

const TriggerWrapper = styled(RadixDropdown.Trigger)({});

const Content = styled(RadixDropdown.Content)<{ width?: number | string }>({
  display: "flex",
  position: "relative",
  flexDirection: "column",
  gap: 4,
  backgroundColor: "white",
  borderRadius: 4,
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.07)",
  padding: 8,
  width: 280,
  animation: `${fadeInAndSlideUp} 0.3s ease-out`,
  zIndex: 3,
});

const Item = styled(RadixDropdown.Item)(({ theme }) => ({
  height: 32,
  padding: "0 8px",
  display: "flex",
  alignItems: "center",
  gap: 8,
  cursor: "pointer",
  borderRadius: 4,

  "&:hover": {
    backgroundColor: theme.colors.sysPrimarySubtle,
    outline: "none",
  },
}));
