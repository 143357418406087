import React from "react";
import { TreeSelect } from "antd5";

import { BuyerCategory } from "lib/StotlesApi";
import { filterIterable } from "lib/utils";
import { IBuyerDataProvider } from "../../lib/data_providers/BuyerDataProvider";
import { useStotlesData } from "../../lib/providers/StotlesData";

const { SHOW_PARENT } = TreeSelect;
const UNCATEGORISED_ID = "uncategorised";
type Props = {
  selectedIds: string[];
  showUncategorised: boolean;
  onUpdate: (newIds: string[], showUncategorised: boolean) => void;
  placeholder?: string;
  style?: React.CSSProperties;
};

type TreeDataSet = {
  title: string;
  value: string;
  key: string;
  children?: TreeDataSet[];
};

type TopLevelIdMap = {
  [x: string]: string[];
};

function toTreeChild(child: BuyerCategory) {
  return { title: child.name, value: child.id, key: child.id };
}

function getCategoryChildren(
  pId: string,
  buyerCategories: IterableIterator<BuyerCategory>,
): TreeDataSet[] {
  return filterIterable(buyerCategories, (child: BuyerCategory) => child.parent_category_id === pId)
    .map(toTreeChild)
    .sort((a, b) => a.title.localeCompare(b.title));
}

function BuyerCategoryTreeSelect({
  selectedIds,
  onUpdate,
  showUncategorised,
  placeholder,
  style,
}: Props): JSX.Element {
  const { value: buyerCategories } = useStotlesData(
    IBuyerDataProvider,
    (provider) => provider.getBuyerCategories(),
    [],
  );

  const dataSet = React.useMemo(() => {
    const set: TreeDataSet[] = [];
    if (!buyerCategories) return set;
    for (const [catId, cat] of buyerCategories) {
      const allCategories = buyerCategories.values();
      const isParent = !cat.parent_category_id;
      if (isParent) {
        const children = getCategoryChildren(catId, allCategories);
        set.push({
          title: cat.name,
          key: catId,
          value: catId,
          children,
        });
      }
    }
    set.push({
      title: "Uncategorised",
      key: UNCATEGORISED_ID,
      value: UNCATEGORISED_ID,
      children: [],
    });
    return set;
  }, [buyerCategories]);

  // Given the "SHOW_PARENT" setting, when a top-level ID is selected we only receive the parent id from
  // the select. Therefore we need to grab all the child-ids as well
  const topLevelIdMap: TopLevelIdMap = React.useMemo(() => {
    const map: TopLevelIdMap = {};
    for (const d of dataSet) {
      if (d.children) {
        map[d.key] = d.children.map((c) => c.key);
      }
    }
    return map;
  }, [dataSet]);

  const handleChange = React.useCallback(
    (newTagIds) => {
      let showUncategorised = false;
      let convertedIds: string[] = [];
      for (const id of newTagIds) {
        // This is a "trick" to show the nulls filter in the dropdown to seem like a more conscious choice and not lack of data
        // There's no uncategorised category in the backend YET
        if (id === UNCATEGORISED_ID) {
          showUncategorised = true;
          continue;
        }
        if (topLevelIdMap[id]) {
          convertedIds = convertedIds.concat(topLevelIdMap[id]);
        }
        // If the top level category is selected, we also want to return any buyer assigned with just that parent category
        // (mostly we don't want to exlude this behaviour) so we return parent id as well
        convertedIds.push(id);
      }
      onUpdate(convertedIds, showUncategorised);
    },
    [topLevelIdMap, onUpdate],
  );

  return (
    <TreeSelect
      value={showUncategorised ? selectedIds.concat(UNCATEGORISED_ID) : selectedIds}
      onChange={handleChange}
      treeData={dataSet}
      showCheckedStrategy={SHOW_PARENT}
      allowClear={true}
      multiple
      treeCheckable={true}
      style={style ? style : { width: "100%" }}
      placeholder={placeholder ? placeholder : "Select..."}
      treeNodeFilterProp="title"
    />
  );
}

export default BuyerCategoryTreeSelect;
