/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateBuyerListSharingRequest
 */
export interface UpdateBuyerListSharingRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateBuyerListSharingRequest
     */
    newSharingType: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBuyerListSharingRequest
     */
    userGuid: string;
}

/**
 * Check if a given object implements the UpdateBuyerListSharingRequest interface.
 */
export function instanceOfUpdateBuyerListSharingRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "newSharingType" in value;
    isInstance = isInstance && "userGuid" in value;

    return isInstance;
}

export function UpdateBuyerListSharingRequestFromJSON(json: any): UpdateBuyerListSharingRequest {
    return UpdateBuyerListSharingRequestFromJSONTyped(json, false);
}

export function UpdateBuyerListSharingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBuyerListSharingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newSharingType': json['new_sharing_type'],
        'userGuid': json['user_guid'],
    };
}

export function UpdateBuyerListSharingRequestToJSON(value?: UpdateBuyerListSharingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_sharing_type': value.newSharingType,
        'user_guid': value.userGuid,
    };
}

