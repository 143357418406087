/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductTourCompletionStateResponseTourState
 */
export interface ProductTourCompletionStateResponseTourState {
    /**
     * 
     * @type {Date}
     * @memberof ProductTourCompletionStateResponseTourState
     */
    completedAt: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ProductTourCompletionStateResponseTourState
     */
    tourIdentifier: ProductTourCompletionStateResponseTourStateTourIdentifierEnum;
}


/**
 * @export
 */
export const ProductTourCompletionStateResponseTourStateTourIdentifierEnum = {
    CrmContactSendIntro: 'crm_contact_send_intro',
    CrmContactSendFeedback: 'crm_contact_send_feedback',
    OutreachBuilderSendFeedback: 'outreach_builder_send_feedback',
    OutreachBuilderEmailSetup: 'outreach_builder_email_setup',
    NewQualificationIntro: 'new_qualification_intro',
    PreEngageQualificationIntro: 'pre_engage_qualification_intro',
    ContactsQualificationIntro: 'contacts_qualification_intro',
    RelatedContactsIntro: 'related_contacts_intro'
} as const;
export type ProductTourCompletionStateResponseTourStateTourIdentifierEnum = typeof ProductTourCompletionStateResponseTourStateTourIdentifierEnum[keyof typeof ProductTourCompletionStateResponseTourStateTourIdentifierEnum];


/**
 * Check if a given object implements the ProductTourCompletionStateResponseTourState interface.
 */
export function instanceOfProductTourCompletionStateResponseTourState(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "completedAt" in value;
    isInstance = isInstance && "tourIdentifier" in value;

    return isInstance;
}

export function ProductTourCompletionStateResponseTourStateFromJSON(json: any): ProductTourCompletionStateResponseTourState {
    return ProductTourCompletionStateResponseTourStateFromJSONTyped(json, false);
}

export function ProductTourCompletionStateResponseTourStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductTourCompletionStateResponseTourState {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'completedAt': (json['completed_at'] === null ? null : new Date(json['completed_at'])),
        'tourIdentifier': json['tour_identifier'],
    };
}

export function ProductTourCompletionStateResponseTourStateToJSON(value?: ProductTourCompletionStateResponseTourState | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'completed_at': (value.completedAt === null ? null : value.completedAt.toISOString()),
        'tour_identifier': value.tourIdentifier,
    };
}

