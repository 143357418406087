import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import { SupplierExportRequest } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

export function useExportSuppliers(
  options?: UseMutationOptions<Blob, unknown, SupplierExportRequest, unknown>,
) {
  const api = useOpenApi();

  return useMutation(async (req) => {
    return api.exportSuppliers({
      supplierExportRequest: req,
    });
  }, options);
}
