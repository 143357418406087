/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserDetailsRequest
 */
export interface UpdateUserDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDetailsRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDetailsRequest
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDetailsRequest
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDetailsRequest
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDetailsRequest
     */
    admin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDetailsRequest
     */
    sendTiUpdates: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDetailsRequest
     */
    showBuyerCategories: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDetailsRequest
     */
    hasExportAccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDetailsRequest
     */
    hasAccountBriefingAccess: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDetailsRequest
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDetailsRequest
     */
    userPersona?: UpdateUserDetailsRequestUserPersonaEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDetailsRequest
     */
    pubSecExperience?: UpdateUserDetailsRequestPubSecExperienceEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDetailsRequest
     */
    hubspotContactId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDetailsRequest
     */
    subscription: UpdateUserDetailsRequestSubscriptionEnum;
}


/**
 * @export
 */
export const UpdateUserDetailsRequestUserPersonaEnum = {
    StrategyLeadership: 'Strategy / Leadership',
    OperationalEndUser: 'Operational / End User'
} as const;
export type UpdateUserDetailsRequestUserPersonaEnum = typeof UpdateUserDetailsRequestUserPersonaEnum[keyof typeof UpdateUserDetailsRequestUserPersonaEnum];

/**
 * @export
 */
export const UpdateUserDetailsRequestPubSecExperienceEnum = {
    None: 'none',
    Limited: 'limited',
    Familiar: 'familiar',
    Mature: 'mature',
    Buyer: 'buyer'
} as const;
export type UpdateUserDetailsRequestPubSecExperienceEnum = typeof UpdateUserDetailsRequestPubSecExperienceEnum[keyof typeof UpdateUserDetailsRequestPubSecExperienceEnum];

/**
 * @export
 */
export const UpdateUserDetailsRequestSubscriptionEnum = {
    Freemium: 'freemium',
    Growth: 'growth',
    Expert: 'expert'
} as const;
export type UpdateUserDetailsRequestSubscriptionEnum = typeof UpdateUserDetailsRequestSubscriptionEnum[keyof typeof UpdateUserDetailsRequestSubscriptionEnum];


/**
 * Check if a given object implements the UpdateUserDetailsRequest interface.
 */
export function instanceOfUpdateUserDetailsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "role" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "admin" in value;
    isInstance = isInstance && "sendTiUpdates" in value;
    isInstance = isInstance && "showBuyerCategories" in value;
    isInstance = isInstance && "hasExportAccess" in value;
    isInstance = isInstance && "hasAccountBriefingAccess" in value;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "hubspotContactId" in value;
    isInstance = isInstance && "subscription" in value;

    return isInstance;
}

export function UpdateUserDetailsRequestFromJSON(json: any): UpdateUserDetailsRequest {
    return UpdateUserDetailsRequestFromJSONTyped(json, false);
}

export function UpdateUserDetailsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserDetailsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'role': json['role'],
        'email': json['email'],
        'admin': json['admin'],
        'sendTiUpdates': json['send_ti_updates'],
        'showBuyerCategories': json['show_buyer_categories'],
        'hasExportAccess': json['has_export_access'],
        'hasAccountBriefingAccess': json['has_account_briefing_access'],
        'active': json['active'],
        'userPersona': !exists(json, 'user_persona') ? undefined : json['user_persona'],
        'pubSecExperience': !exists(json, 'pub_sec_experience') ? undefined : json['pub_sec_experience'],
        'hubspotContactId': json['hubspot_contact_id'],
        'subscription': json['subscription'],
    };
}

export function UpdateUserDetailsRequestToJSON(value?: UpdateUserDetailsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'role': value.role,
        'email': value.email,
        'admin': value.admin,
        'send_ti_updates': value.sendTiUpdates,
        'show_buyer_categories': value.showBuyerCategories,
        'has_export_access': value.hasExportAccess,
        'has_account_briefing_access': value.hasAccountBriefingAccess,
        'active': value.active,
        'user_persona': value.userPersona,
        'pub_sec_experience': value.pubSecExperience,
        'hubspot_contact_id': value.hubspotContactId,
        'subscription': value.subscription,
    };
}

