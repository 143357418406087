import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";
import { startCase } from "lodash";

import { getGQLQualificationType, getGQLSearchQualificationType } from "components/notices/utils";
import { useUserDataProvider } from "lib/data_providers/UserDataProvider";
import {
  CreateQualificationRequest,
  CreateQualificationResponse,
  RecordDto,
  RecordSearchResponseDto,
  SimpleUserDto,
} from "lib/generated/app-api";
import { NoticesQuery } from "lib/generated/app-service-gql/graphql";
import { useUsers } from "lib/hooks/api/useUsers";
import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";
import { NoticeSearchQueryKey } from "../notices/useNotices";
import { updateCachedNotice } from "../notices/utils";
import { RecordSearchQueryKey } from "../useRecordSearch";

type Params = {
  procurementStage: {
    id: string;
    stage: string;
  };
  recordGuid: string;
  signalTypes: Record<string, string[]> | undefined;
  score: number | null | undefined;
  qualification: CreateQualificationRequest["qualification"];
  contextSource: string;
};

export function useUpdateRecordQualification(
  options?: UseMutationOptions<CreateQualificationResponse, unknown, Params, unknown>,
) {
  const api = useOpenApi();
  const { leadWriteMethods } = useUserDataProvider();
  const { logEvent } = useTracking();
  const queryClient = useQueryClient();
  const { refetch: fetchUsers } = useUsers(undefined, { enabled: false });

  return useMutation(
    async ({ procurementStage, qualification }: Params) => {
      const procurementStageId = procurementStage.id;

      return api.createProcurementStageQualification({
        createQualificationRequest: {
          procurementStageId,
          qualification,
        },
      });
    },
    {
      ...options,
      onSuccess: async (data, vars, ctx) => {
        const { contextSource, procurementStage, signalTypes, score, recordGuid, qualification } =
          vars;
        let assignedUser: SimpleUserDto | undefined = undefined;
        if (data.assigneeId) {
          const { data: users } = await fetchUsers();
          assignedUser = users?.find((user) => user.guid === data.assigneeId);
        }

        const assignedTo = assignedUser
          ? {
              assignee: {
                ...assignedUser,
                first_name: assignedUser.firstName,
                last_name: assignedUser.lastName,
              },
            }
          : {};

        // TODO: remove this once fully moved over to react query
        leadWriteMethods.updateProcurementStageQualification(data.procurementStageId, data);
        if (assignedTo.assignee) {
          leadWriteMethods.assignLead(data.procurementStageId, assignedTo.assignee);
        }

        const stage = procurementStage.stage;

        logEvent(EventNames.qualificationActioned, {
          "Record GUID": recordGuid,
          "Context source": contextSource,
          "Qualification label": startCase(qualification),
          Stage: stage,
          "Signal types": signalTypes ? Object.keys(signalTypes) : [],
          "Signal score": score,
          Signals: signalTypes,
        });

        // Update the individual record cache with the new qualification
        queryClient.setQueryData<RecordDto>(["record", vars.recordGuid], (resp) => {
          if (!resp) {
            return resp;
          }

          return {
            ...resp,
            ...assignedTo,
            procurementStage: {
              ...resp.procurementStage,
              procurementStageQualification: {
                ...resp.procurementStage.procurementStageQualification,
                ...data,
              },
            },
          };
        });

        updateCachedNotice(queryClient, vars.recordGuid, (r) => ({
          ...r,
          ...assignedTo,
          qualification: getGQLQualificationType(data.qualification),
        }));

        const notices = queryClient.getQueriesData<NoticesQuery>(["notices"]);
        notices.forEach(([key, searchData]) => {
          const [, searchParams] = key as NoticeSearchQueryKey;
          if (!searchData) {
            // no data in cache continue the loop
            return;
          }
          const noticeIndex = searchData.notices.results.findIndex(
            (r) => r.guid === vars.recordGuid,
          );
          // we need to update qualification in the cache
          if (noticeIndex > -1) {
            let removeRecord = false;
            // we need to remove the record from the cache as the record is no longer part of the filters
            if (
              searchParams.qualifications &&
              searchParams.qualifications.length > 0 &&
              !searchParams.qualifications.includes(getGQLSearchQualificationType(qualification))
            ) {
              removeRecord = true;
            }

            queryClient.setQueryData<NoticesQuery>(key, (resp) => {
              if (!resp) {
                return resp;
              }

              if (removeRecord) {
                return {
                  notices: {
                    ...resp.notices,
                    results: resp.notices.results.filter(
                      (notice) => notice.guid !== vars.recordGuid,
                    ),
                  },
                };
              }

              const results = [...resp.notices.results];
              results[noticeIndex] = {
                ...results[noticeIndex],
                ...assignedTo,
                qualification: getGQLQualificationType(data.qualification),
              };

              return {
                notices: { ...resp.notices, results },
              };
            });
          }
        });

        const recordQueries = queryClient.getQueriesData<RecordSearchResponseDto>(["records"]);
        recordQueries.forEach(([key, searchData]) => {
          const [, searchParams] = key as RecordSearchQueryKey;
          if (!searchData) {
            // no data in cache continue the loop
            return;
          }
          const recordIndex = searchData.results.findIndex((r) => r.guid === vars.recordGuid);
          // we need to update qualification in the cache
          if (recordIndex > -1) {
            let removeRecord = false;
            // we need to remove the record from the cache as the record is no longer part of the filters
            if (
              searchParams.procurementStageQualifications &&
              searchParams.procurementStageQualifications.length > 0 &&
              !searchParams.procurementStageQualifications.includes(qualification)
            ) {
              removeRecord = true;
            }

            queryClient.setQueryData<RecordSearchResponseDto>(key, (resp) => {
              if (!resp) {
                return resp;
              }
              if (removeRecord) {
                return {
                  ...resp,
                  results: resp.results.filter((record) => record.guid !== vars.recordGuid),
                };
              }
              const results = [...resp.results];
              results[recordIndex] = {
                ...results[recordIndex],
                ...assignedTo,
                procurementStage: {
                  ...results[recordIndex].procurementStage,
                  procurementStageQualification: {
                    ...results[recordIndex].procurementStage.procurementStageQualification,
                    ...data,
                  },
                },
              };
              return {
                ...resp,
                results,
              };
            });
          }
        });
        options?.onSuccess?.(data, vars, ctx);
      },
    },
  );
}
