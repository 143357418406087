/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyerCategoryAssignmentDto } from './BuyerCategoryAssignmentDto';
import {
    BuyerCategoryAssignmentDtoFromJSON,
    BuyerCategoryAssignmentDtoFromJSONTyped,
    BuyerCategoryAssignmentDtoToJSON,
} from './BuyerCategoryAssignmentDto';
import type { SignalDto } from './SignalDto';
import {
    SignalDtoFromJSON,
    SignalDtoFromJSONTyped,
    SignalDtoToJSON,
} from './SignalDto';
import type { SupplierSignalBuyerStatsStats } from './SupplierSignalBuyerStatsStats';
import {
    SupplierSignalBuyerStatsStatsFromJSON,
    SupplierSignalBuyerStatsStatsFromJSONTyped,
    SupplierSignalBuyerStatsStatsToJSON,
} from './SupplierSignalBuyerStatsStats';

/**
 * 
 * @export
 * @interface SupplierSignalBuyerStats
 */
export interface SupplierSignalBuyerStats {
    /**
     * 
     * @type {string}
     * @memberof SupplierSignalBuyerStats
     */
    buyerGuid: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierSignalBuyerStats
     */
    buyerName: string;
    /**
     * 
     * @type {Array<SignalDto>}
     * @memberof SupplierSignalBuyerStats
     */
    keywordSignals: Array<SignalDto>;
    /**
     * 
     * @type {Array<BuyerCategoryAssignmentDto>}
     * @memberof SupplierSignalBuyerStats
     */
    categories: Array<BuyerCategoryAssignmentDto>;
    /**
     * 
     * @type {SupplierSignalBuyerStatsStats}
     * @memberof SupplierSignalBuyerStats
     */
    stats: SupplierSignalBuyerStatsStats;
}

/**
 * Check if a given object implements the SupplierSignalBuyerStats interface.
 */
export function instanceOfSupplierSignalBuyerStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "buyerGuid" in value;
    isInstance = isInstance && "buyerName" in value;
    isInstance = isInstance && "keywordSignals" in value;
    isInstance = isInstance && "categories" in value;
    isInstance = isInstance && "stats" in value;

    return isInstance;
}

export function SupplierSignalBuyerStatsFromJSON(json: any): SupplierSignalBuyerStats {
    return SupplierSignalBuyerStatsFromJSONTyped(json, false);
}

export function SupplierSignalBuyerStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierSignalBuyerStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buyerGuid': json['buyer_guid'],
        'buyerName': json['buyer_name'],
        'keywordSignals': ((json['keyword_signals'] as Array<any>).map(SignalDtoFromJSON)),
        'categories': ((json['categories'] as Array<any>).map(BuyerCategoryAssignmentDtoFromJSON)),
        'stats': SupplierSignalBuyerStatsStatsFromJSON(json['stats']),
    };
}

export function SupplierSignalBuyerStatsToJSON(value?: SupplierSignalBuyerStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buyer_guid': value.buyerGuid,
        'buyer_name': value.buyerName,
        'keyword_signals': ((value.keywordSignals as Array<any>).map(SignalDtoToJSON)),
        'categories': ((value.categories as Array<any>).map(BuyerCategoryAssignmentDtoToJSON)),
        'stats': SupplierSignalBuyerStatsStatsToJSON(value.stats),
    };
}

