/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommentDtoAuthor } from './CommentDtoAuthor';
import {
    CommentDtoAuthorFromJSON,
    CommentDtoAuthorFromJSONTyped,
    CommentDtoAuthorToJSON,
} from './CommentDtoAuthor';

/**
 * 
 * @export
 * @interface CommentDto
 */
export interface CommentDto {
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    content: string;
    /**
     * 
     * @type {CommentDtoAuthor}
     * @memberof CommentDto
     */
    author: CommentDtoAuthor;
    /**
     * 
     * @type {Date}
     * @memberof CommentDto
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CommentDto
     */
    lastEditedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    objectType: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    objectId: string;
    /**
     * 
     * @type {Date}
     * @memberof CommentDto
     */
    deletedAt?: Date;
}

/**
 * Check if a given object implements the CommentDto interface.
 */
export function instanceOfCommentDto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "author" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "objectType" in value;
    isInstance = isInstance && "objectId" in value;

    return isInstance;
}

export function CommentDtoFromJSON(json: any): CommentDto {
    return CommentDtoFromJSONTyped(json, false);
}

export function CommentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'content': json['content'],
        'author': CommentDtoAuthorFromJSON(json['author']),
        'createdAt': (new Date(json['created_at'])),
        'lastEditedAt': !exists(json, 'last_edited_at') ? undefined : (new Date(json['last_edited_at'])),
        'objectType': json['object_type'],
        'objectId': json['object_id'],
        'deletedAt': !exists(json, 'deleted_at') ? undefined : (new Date(json['deleted_at'])),
    };
}

export function CommentDtoToJSON(value?: CommentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'content': value.content,
        'author': CommentDtoAuthorToJSON(value.author),
        'created_at': (value.createdAt.toISOString()),
        'last_edited_at': value.lastEditedAt === undefined ? undefined : (value.lastEditedAt.toISOString()),
        'object_type': value.objectType,
        'object_id': value.objectId,
        'deleted_at': value.deletedAt === undefined ? undefined : (value.deletedAt.toISOString()),
    };
}

