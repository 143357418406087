import { ThemeConfig } from "antd5";

import { DefaultTheme } from "components/app_layout/DefaultTheme";

export const BlueSkeleton: ThemeConfig = {
  components: {
    Skeleton: {
      gradientFromColor: DefaultTheme.token?.colorPrimaryBg,
      gradientToColor: DefaultTheme.token?.colorPrimaryBgHover,
      borderRadius: 2,
    },
  },
};
