import React from "react";

import { blue500 } from "lib/themes/colors";

type Props = {
  fill?: string;
  className?: string;
  height?: number;
  width?: number;
};

export function ExternalLink({ fill = blue500, className, width = 20, height = 20 }: Props) {
  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1029 0.668043L14.4823 1.22087C14.3094 1.24121 14.2384 1.45384 14.3603 1.57771L15.7434 2.9829L10.0291 8.78852C9.94899 8.86987 9.94899 9.00299 10.0291 9.08434L11.0482 10.1197C11.1283 10.2011 11.2593 10.2011 11.3394 10.1197L17.0537 4.31412L18.4368 5.7193C18.5587 5.84318 18.7698 5.76922 18.788 5.59542L19.3322 0.901007C19.336 0.869491 19.3326 0.837515 19.3224 0.8075C19.3123 0.777485 19.2955 0.750221 19.2734 0.727772C19.2513 0.705322 19.2245 0.688276 19.1949 0.677928C19.1654 0.66758 19.1339 0.664199 19.1029 0.668043ZM11.5262 1.11114H1.16699C0.89085 1.11114 0.666992 1.335 0.666992 1.61114V18.8332C0.666992 19.1093 0.89085 19.3332 1.16699 19.3332H18.1026C18.3787 19.3332 18.6026 19.1093 18.6026 18.8332V9.50736C18.6026 9.23122 18.3787 9.00736 18.1026 9.00736H17.309C17.0329 9.00736 16.809 9.23122 16.809 9.50736V17.511H2.46055V2.93334H11.5262C11.8023 2.93334 12.0262 2.70949 12.0262 2.43334V1.61114C12.0262 1.335 11.8023 1.11114 11.5262 1.11114Z"
        fill={fill}
      />
    </svg>
  );
}
