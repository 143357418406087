/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SavedViewDetails
 */
export interface SavedViewDetails {
    /**
     * 
     * @type {string}
     * @memberof SavedViewDetails
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SavedViewDetails
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SavedViewDetails
     */
    rank?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SavedViewDetails
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SavedViewDetails
     */
    ownerId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SavedViewDetails
     */
    isStandardView: boolean;
}

/**
 * Check if a given object implements the SavedViewDetails interface.
 */
export function instanceOfSavedViewDetails(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "isStandardView" in value;

    return isInstance;
}

export function SavedViewDetailsFromJSON(json: any): SavedViewDetails {
    return SavedViewDetailsFromJSONTyped(json, false);
}

export function SavedViewDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SavedViewDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'rank': !exists(json, 'rank') ? undefined : json['rank'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'ownerId': !exists(json, 'owner_id') ? undefined : json['owner_id'],
        'isStandardView': json['is_standard_view'],
    };
}

export function SavedViewDetailsToJSON(value?: SavedViewDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'rank': value.rank,
        'description': value.description,
        'owner_id': value.ownerId,
        'is_standard_view': value.isStandardView,
    };
}

