/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InstallUrlRequest
 */
export interface InstallUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof InstallUrlRequest
     */
    environment?: InstallUrlRequestEnvironmentEnum;
}


/**
 * @export
 */
export const InstallUrlRequestEnvironmentEnum = {
    Production: 'PRODUCTION',
    Sandbox: 'SANDBOX'
} as const;
export type InstallUrlRequestEnvironmentEnum = typeof InstallUrlRequestEnvironmentEnum[keyof typeof InstallUrlRequestEnvironmentEnum];


/**
 * Check if a given object implements the InstallUrlRequest interface.
 */
export function instanceOfInstallUrlRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InstallUrlRequestFromJSON(json: any): InstallUrlRequest {
    return InstallUrlRequestFromJSONTyped(json, false);
}

export function InstallUrlRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InstallUrlRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'environment': !exists(json, 'environment') ? undefined : json['environment'],
    };
}

export function InstallUrlRequestToJSON(value?: InstallUrlRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'environment': value.environment,
    };
}

