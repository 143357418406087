/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendNoticeRequest
 */
export interface SendNoticeRequest {
    /**
     * 
     * @type {string}
     * @memberof SendNoticeRequest
     */
    recordId: string;
    /**
     * 
     * @type {string}
     * @memberof SendNoticeRequest
     */
    fields: string;
}

/**
 * Check if a given object implements the SendNoticeRequest interface.
 */
export function instanceOfSendNoticeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recordId" in value;
    isInstance = isInstance && "fields" in value;

    return isInstance;
}

export function SendNoticeRequestFromJSON(json: any): SendNoticeRequest {
    return SendNoticeRequestFromJSONTyped(json, false);
}

export function SendNoticeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendNoticeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordId': json['recordId'],
        'fields': json['fields'],
    };
}

export function SendNoticeRequestToJSON(value?: SendNoticeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordId': value.recordId,
        'fields': value.fields,
    };
}

