import React from "react";

import { blue500, brandRed, purple } from "lib/themes/colors";

type Props = {
  fill?: string;
  className?: string;
};

export function FreePlan({
  fill = brandRed, // Red colour
  className,
}: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 100 100" fill="none">
      <g id="Free Plan Icon">
        <path
          opacity="0.3"
          d="M0 100C-1.20706e-06 72.3858 22.3858 50 50 50L50 100L0 100Z"
          fill={fill}
        />
        <path opacity="0.5" d="M100 50C100 77.6142 77.6142 100 50 100L50 50L100 50Z" fill={fill} />
        <path opacity="0.2" d="M50 0C77.6142 0 100 22.3858 100 50L50 50L50 0Z" fill={fill} />
        <path opacity="0.7" d="M0 0C27.6142 0 50 22.3858 50 50L0 50L0 0Z" fill={fill} />
      </g>
    </svg>
  );
}

export function GrowthPlan({
  fill = purple, // Purple colour
  className,
}: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="0 0 20 20" fill="none">
      <g id="Growth Plan Icon">
        <path
          opacity="0.3"
          d="M0 10C-2.41411e-07 15.5228 4.47715 20 10 20L10 10L0 10Z"
          fill={fill}
        />
        <path opacity="0.5" d="M20 10C20 15.5228 15.5228 20 10 20L10 10L20 10Z" fill={fill} />
        <path opacity="0.2" d="M10 0C15.5228 0 20 4.47715 20 10L10 10L10 0Z" fill={fill} />
        <path opacity="0.7" d="M0 0C5.52285 0 10 4.47715 10 10L0 10L0 0Z" fill={fill} />
      </g>
    </svg>
  );
}

export function ExpertPlan({
  fill = blue500, // Blue colour
  className,
}: Props) {
  return (
    <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Expert Plan Icon">
        <path
          id="Rectangle 296"
          opacity="0.3"
          d="M0 10C-2.41411e-07 15.5228 4.47715 20 10 20L10 10L0 10Z"
          fill={fill}
        />
        <path
          id="Rectangle 297"
          opacity="0.5"
          d="M20 10C20 15.5228 15.5228 20 10 20L10 10L20 10Z"
          fill={fill}
        />
        <path
          id="Rectangle 298"
          opacity="0.2"
          d="M10 0C15.5228 0 20 4.47715 20 10L10 10L10 0Z"
          fill={fill}
        />
        <path
          id="Rectangle 299"
          opacity="0.7"
          d="M0 10C-2.41411e-07 4.47715 4.47715 -1.95703e-07 10 -4.37114e-07L10 10L0 10Z"
          fill={fill}
        />
      </g>
    </svg>
  );
}
