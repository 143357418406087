import React from "react";
import { ControlProps, EnumOption } from "@jsonforms/core";
import { withJsonFormsOneOfEnumProps } from "@jsonforms/react";

import { Enum } from "./Enum";

function EnumControl({
  data,
  handleChange,
  options,
  path,
  label,
  required,
  errors,
}: ControlProps & { options?: EnumOption[] }) {
  return (
    <Enum
      errors={errors}
      value={data}
      label={label}
      required={required}
      options={options}
      updateValue={(value: string) => handleChange(path, value)}
    />
  );
}

export default withJsonFormsOneOfEnumProps(EnumControl);
