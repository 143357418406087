import {
  DistinctCandidate,
  HierarchicalCandidate,
  MultipleEntitiesCandidate,
  MultipleEntitiesTypes,
  ProcessStages,
  ValidOrgsCount,
} from "components/organisation_clean/types";

const validateDistinctBuyer = (data: DistinctCandidate | undefined) => {
  const totalDistinctBuyers = [
    ...(data?.selectedDistinctBuyers || []),
    ...(data?.newDistinctBuyers || []),
  ];
  if (totalDistinctBuyers.length > 1) {
    return ProcessStages.DONE;
  } else return ProcessStages.REQUIRES_CLEAN;
};

const validateHierarchicalBuyer = (data: HierarchicalCandidate | undefined) => {
  if (!data) return ProcessStages.REQUIRES_CLEAN;
  if (
    data.validBuyersCount === ValidOrgsCount.UP_TO_ONE ||
    data.validBuyersCount === ValidOrgsCount.THREE_OR_MORE
  ) {
    return ProcessStages.DONE;
    // TODO: WOrk on this logic
  } else if ((data.validBuyersCount === ValidOrgsCount.TWO) === true && data.relationToAnchor) {
    if (
      data.candidateAction?.type === "RENAME" &&
      data.candidateAction.newName &&
      data.hierarchyConfirmed
    ) {
      return ProcessStages.DONE;
    } else if (
      data.candidateAction?.type === "MERGE" &&
      data.candidateAction.replacedBy &&
      data.hierarchyConfirmed
    ) {
      return ProcessStages.DONE;
    } else return ProcessStages.REQUIRES_CLEAN;
  } else return ProcessStages.REQUIRES_CLEAN;
};

export const validateCleaningProgress = (candidate: MultipleEntitiesCandidate): ProcessStages => {
  const cleaningData = candidate.cleaningData;

  if (candidate.processStage === ProcessStages.CANCELLED) {
    return ProcessStages.CANCELLED;
  }
  let processStage = ProcessStages.REQUIRES_CLEAN;
  if (!cleaningData) {
    return processStage;
  } else if (cleaningData.type === MultipleEntitiesTypes.DISTINCT) {
    processStage = validateDistinctBuyer(cleaningData);
  } else if (cleaningData.type === MultipleEntitiesTypes.HIERARCHICAL) {
    processStage = validateHierarchicalBuyer(cleaningData);
  }
  return processStage;
};
