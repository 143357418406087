/* tslint:disable */
/* eslint-disable */
/**
 * Integrations Service
 * Provides an API to integrate with various 3rd party integrations
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProviderTypes } from './ProviderTypes';
import {
    ProviderTypesFromJSON,
    ProviderTypesFromJSONTyped,
    ProviderTypesToJSON,
} from './ProviderTypes';

/**
 * 
 * @export
 * @interface ProviderOption
 */
export interface ProviderOption {
    /**
     * 
     * @type {string}
     * @memberof ProviderOption
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderOption
     */
    isInstalled: boolean;
    /**
     * 
     * @type {ProviderTypes}
     * @memberof ProviderOption
     */
    type: ProviderTypes;
}

/**
 * Check if a given object implements the ProviderOption interface.
 */
export function instanceOfProviderOption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isInstalled" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ProviderOptionFromJSON(json: any): ProviderOption {
    return ProviderOptionFromJSONTyped(json, false);
}

export function ProviderOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isInstalled': json['isInstalled'],
        'type': ProviderTypesFromJSON(json['type']),
    };
}

export function ProviderOptionToJSON(value?: ProviderOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isInstalled': value.isInstalled,
        'type': ProviderTypesToJSON(value.type),
    };
}

