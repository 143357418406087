/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetAssignedNoticesResponseResult
 */
export interface GetAssignedNoticesResponseResult {
    /**
     * 
     * @type {string}
     * @memberof GetAssignedNoticesResponseResult
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetAssignedNoticesResponseResult
     */
    assignee: string;
    /**
     * 
     * @type {string}
     * @memberof GetAssignedNoticesResponseResult
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof GetAssignedNoticesResponseResult
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof GetAssignedNoticesResponseResult
     */
    teamId: string;
    /**
     * 
     * @type {number}
     * @memberof GetAssignedNoticesResponseResult
     */
    count: number;
}

/**
 * Check if a given object implements the GetAssignedNoticesResponseResult interface.
 */
export function instanceOfGetAssignedNoticesResponseResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "assignee" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "teamId" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function GetAssignedNoticesResponseResultFromJSON(json: any): GetAssignedNoticesResponseResult {
    return GetAssignedNoticesResponseResultFromJSONTyped(json, false);
}

export function GetAssignedNoticesResponseResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAssignedNoticesResponseResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'assignee': json['assignee'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'teamId': json['team_id'],
        'count': json['count'],
    };
}

export function GetAssignedNoticesResponseResultToJSON(value?: GetAssignedNoticesResponseResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'assignee': value.assignee,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'team_id': value.teamId,
        'count': value.count,
    };
}

