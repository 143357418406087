/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetRecordSummaryDetailsResponseValue
 */
export interface GetRecordSummaryDetailsResponseValue {
    /**
     * 
     * @type {string}
     * @memberof GetRecordSummaryDetailsResponseValue
     */
    attributeName: GetRecordSummaryDetailsResponseValueAttributeNameEnum;
    /**
     * 
     * @type {string}
     * @memberof GetRecordSummaryDetailsResponseValue
     */
    content: string | null;
}


/**
 * @export
 */
export const GetRecordSummaryDetailsResponseValueAttributeNameEnum = {
    ContractValue: 'contract_value',
    TopicRelevance: 'topic_relevance',
    BidRequirements: 'bid_requirements',
    MentionedParties: 'mentioned_parties',
    RoutesToMarket: 'routes_to_market',
    IncumbentRelationships: 'incumbent_relationships',
    PreviousSimilarActivity: 'previous_similar_activity',
    ThingBeingProcured: 'thing_being_procured',
    Summary: 'summary'
} as const;
export type GetRecordSummaryDetailsResponseValueAttributeNameEnum = typeof GetRecordSummaryDetailsResponseValueAttributeNameEnum[keyof typeof GetRecordSummaryDetailsResponseValueAttributeNameEnum];


/**
 * Check if a given object implements the GetRecordSummaryDetailsResponseValue interface.
 */
export function instanceOfGetRecordSummaryDetailsResponseValue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "attributeName" in value;
    isInstance = isInstance && "content" in value;

    return isInstance;
}

export function GetRecordSummaryDetailsResponseValueFromJSON(json: any): GetRecordSummaryDetailsResponseValue {
    return GetRecordSummaryDetailsResponseValueFromJSONTyped(json, false);
}

export function GetRecordSummaryDetailsResponseValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRecordSummaryDetailsResponseValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeName': json['attribute_name'],
        'content': json['content'],
    };
}

export function GetRecordSummaryDetailsResponseValueToJSON(value?: GetRecordSummaryDetailsResponseValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attribute_name': value.attributeName,
        'content': value.content,
    };
}

