import React, { useState } from "react";
import { Button } from "antd5";

import { useVariableValue } from "../../lib/featureFlags";
import { clockRewind } from "../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../lib/icons/untitled_ui/UIcon";
import { EventNames, useTracking } from "../../lib/tracking";
import { ScheduleReminderModal } from "./ScheduleReminderModal";

type Props = {
  dataType: "notice" | "framework";
  anchorDate?: string | null;
  anchorType?: "expiry" | "close";
  dataId: string;
  dataTitle: string;
};

export function ScheduleReminderButton(props: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { logEvent } = useTracking();
  const isEnabled = useVariableValue("calendar-reminders", false);

  if (!isEnabled) {
    return null;
  }

  const handleOpen = () => {
    setIsModalOpen(true);
    logEvent(EventNames.scheduleReminderModalOpened, props);
  };

  return (
    <>
      <Button icon={<UIcon svg={clockRewind} size={16} />} onClick={handleOpen}>
        Reminder
      </Button>
      <ScheduleReminderModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        {...props}
      />
    </>
  );
}
