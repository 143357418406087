/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateQualificationResponse
 */
export interface CreateQualificationResponse {
    /**
     * 
     * @type {string}
     * @memberof CreateQualificationResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQualificationResponse
     */
    procurementStageId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQualificationResponse
     */
    qualifiedById: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQualificationResponse
     */
    teamId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateQualificationResponse
     */
    qualification: CreateQualificationResponseQualificationEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateQualificationResponse
     */
    assigneeId?: string | null;
}


/**
 * @export
 */
export const CreateQualificationResponseQualificationEnum = {
    NotRelevant: 'not_relevant',
    InReview: 'in_review',
    BidPrep: 'bid_prep',
    BidSubmitted: 'bid_submitted',
    NoBid: 'no_bid',
    Won: 'won',
    Lost: 'lost',
    PreEngageToDo: 'pre_engage_to_do',
    PreEngageDone: 'pre_engage_done',
    PreEngageNotRelevant: 'pre_engage_not_relevant'
} as const;
export type CreateQualificationResponseQualificationEnum = typeof CreateQualificationResponseQualificationEnum[keyof typeof CreateQualificationResponseQualificationEnum];


/**
 * Check if a given object implements the CreateQualificationResponse interface.
 */
export function instanceOfCreateQualificationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "procurementStageId" in value;
    isInstance = isInstance && "qualifiedById" in value;
    isInstance = isInstance && "teamId" in value;
    isInstance = isInstance && "qualification" in value;

    return isInstance;
}

export function CreateQualificationResponseFromJSON(json: any): CreateQualificationResponse {
    return CreateQualificationResponseFromJSONTyped(json, false);
}

export function CreateQualificationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateQualificationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'procurementStageId': json['procurement_stage_id'],
        'qualifiedById': json['qualified_by_id'],
        'teamId': json['team_id'],
        'qualification': json['qualification'],
        'assigneeId': !exists(json, 'assignee_id') ? undefined : json['assignee_id'],
    };
}

export function CreateQualificationResponseToJSON(value?: CreateQualificationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'procurement_stage_id': value.procurementStageId,
        'qualified_by_id': value.qualifiedById,
        'team_id': value.teamId,
        'qualification': value.qualification,
        'assignee_id': value.assigneeId,
    };
}

