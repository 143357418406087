import * as React from "react";

import { BetaIcon } from "lib/core_components/Labels";
import { ProviderTypes } from "lib/generated/integration-api";
import { getIntegrationIcon } from "./util";

import css from "./IntegrationModalHeader.module.scss";

type Props = {
  providerType: ProviderTypes;
  title?: string;
  subtitle?: string;
};

export function IntegrationModalHeader({ providerType, title, subtitle }: Props) {
  const icon = React.useMemo(() => getIntegrationIcon(providerType), [providerType]);
  return (
    <div className={css.modalHeader}>
      <div className={css.iconContainer}>{icon}</div>
      <div>
        {title && (
          <h1>
            {title} <BetaIcon />
          </h1>
        )}
        {subtitle && <h2>{subtitle}</h2>}
      </div>
    </div>
  );
}
