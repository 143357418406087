import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { NoticeFormResponse } from "lib/generated/integration-api";
import { useIntegrationApi } from "lib/integrationApiContext";

type QueryKey = [string];

export function useNoticeFormSchema(
  options?: UseQueryOptions<NoticeFormResponse, unknown, NoticeFormResponse, QueryKey>,
) {
  const api = useIntegrationApi();
  return useQuery(
    ["noticeFormSchema"],
    async () => {
      const response = await api.noticeFormSchema();
      return response;
    },
    options,
  );
}
