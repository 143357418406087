/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListPermission
 */
export interface ListPermission {
    /**
     * 
     * @type {string}
     * @memberof ListPermission
     */
    objectId: string;
    /**
     * 
     * @type {string}
     * @memberof ListPermission
     */
    accessorId: string;
    /**
     * 
     * @type {string}
     * @memberof ListPermission
     */
    accessorType: string;
    /**
     * 
     * @type {string}
     * @memberof ListPermission
     */
    roleName: string;
}

/**
 * Check if a given object implements the ListPermission interface.
 */
export function instanceOfListPermission(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "objectId" in value;
    isInstance = isInstance && "accessorId" in value;
    isInstance = isInstance && "accessorType" in value;
    isInstance = isInstance && "roleName" in value;

    return isInstance;
}

export function ListPermissionFromJSON(json: any): ListPermission {
    return ListPermissionFromJSONTyped(json, false);
}

export function ListPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListPermission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'objectId': json['object_id'],
        'accessorId': json['accessor_id'],
        'accessorType': json['accessor_type'],
        'roleName': json['role_name'],
    };
}

export function ListPermissionToJSON(value?: ListPermission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'object_id': value.objectId,
        'accessor_id': value.accessorId,
        'accessor_type': value.accessorType,
        'role_name': value.roleName,
    };
}

