import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { CommentDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";

type Params = {
  id: string;
  content: string;
  procurementStageId: string;
};

export function useUpdateComment(
  options?: UseMutationOptions<CommentDto, unknown, Params, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  const { logEvent } = useTracking();
  return useMutation(
    ({ id, content }: Params) => api.updateComment({ id, updateCommentRequest: { content } }),
    {
      ...options,
      onSuccess: async (data, vars, context) => {
        const procurementStageId = vars.procurementStageId;

        await queryClient.invalidateQueries(["comments", procurementStageId]);

        logEvent(EventNames.commentActioned, {
          "Action type": "edited",
          "Comment ID": data.id,
        });
        options?.onSuccess?.(data, vars, context);
      },
    },
  );
}
