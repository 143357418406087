import * as React from "react";
import { Button } from "antd5";
import { produce } from "immer";

import DistinctJourney from "./DistinctJourney";
import HierarchicalJourney from "./HierarchicalJourney";
import { CandidateItem } from "./OrgListPanel";
import {
  CandidateOperation,
  DistinctCandidate,
  HierarchicalCandidate,
  MultipleEntitiesCandidate,
  MultipleEntitiesTypes,
  OrgToBeCreated,
  OrgWithStats,
  QualifiedCandidate,
} from "./types";

import css from "./CleanDuplicatesPage.module.scss";

type Props = {
  anchorOrg: OrgWithStats;
  candidate: MultipleEntitiesCandidate;
  totalCandidates?: number;
  currentCandidateNumber?: number;
  modifyNewBuyers: React.Dispatch<React.SetStateAction<OrgToBeCreated[]>>;
  handleUpdateCandidate: React.Dispatch<React.SetStateAction<Record<string, QualifiedCandidate>>>;
  orgPrimaryRole: "Buyer" | "Supplier";
};

function CleanDuplicatesPage({
  anchorOrg,
  candidate,
  totalCandidates,
  currentCandidateNumber,
  modifyNewBuyers,
  handleUpdateCandidate,
  orgPrimaryRole,
}: Props): JSX.Element {
  // This function is used to setting the type, we can't use the partial update
  // because we want to avoid keeping old selections
  const handleUpdateCleanData = React.useCallback(
    (guid: string, cleaningData: HierarchicalCandidate | DistinctCandidate) => {
      handleUpdateCandidate(
        produce((qualifiedCandidates) => {
          const candidate = qualifiedCandidates[guid];
          if (candidate.qualification !== CandidateOperation.MULTIPLE_ENTITIES) {
            throw "Can't call update cleandata on a candidate which isn't multiple entities qualified";
          }
          candidate.cleaningData = cleaningData;
        }),
      );
    },
    [handleUpdateCandidate],
  );

  const handleUpdatePartialCleanData = React.useCallback(
    (guid: string, cleaningDataUpdate: Partial<HierarchicalCandidate | DistinctCandidate>) => {
      handleUpdateCandidate(
        produce((qualifiedCandidates) => {
          const candidate = qualifiedCandidates[guid];
          if (candidate.qualification !== CandidateOperation.MULTIPLE_ENTITIES) {
            throw "Can't call update cleandata on a candidate which isn't multiple entities qualified";
          }
          candidate.cleaningData = {
            ...candidate.cleaningData,
            ...cleaningDataUpdate,
          } as MultipleEntitiesCandidate["cleaningData"];
        }),
      );
    },
    [handleUpdateCandidate],
  );

  const addNewBuyer = React.useCallback(
    (b: OrgToBeCreated) => {
      modifyNewBuyers(
        produce((draft) => {
          draft.push(b);
        }),
      );
    },
    [modifyNewBuyers],
  );

  const removeNewBuyer = React.useCallback(
    (guid: string) => {
      modifyNewBuyers(
        produce((draft) => {
          const idx = draft.findIndex((b) => b.guid === guid);
          // In the hierarchical journey we stage the new buyers first before they're deleted,
          // If a relationship hasn't been added yet then it might not exist on the top level new buyers
          if (idx !== -1) {
            draft.splice(idx, 1);
          }
        }),
      );
    },
    [modifyNewBuyers],
  );

  const journey = React.useMemo(() => {
    if (candidate.cleaningData?.type === MultipleEntitiesTypes.DISTINCT)
      return (
        <DistinctJourney
          candidate={candidate.buyer}
          onBuyersSelect={(buyers, buyerType) =>
            handleUpdatePartialCleanData(candidate.buyer.guid, {
              [buyerType]: buyers,
            })
          }
          selectedDistinctBuyers={candidate.cleaningData.selectedDistinctBuyers || []}
          newBuyers={candidate.cleaningData.newDistinctBuyers || []}
          addNewBuyer={addNewBuyer}
          removeNewBuyer={removeNewBuyer}
          orgPrimaryRole={orgPrimaryRole}
        />
      );
    else if (candidate.cleaningData?.type === MultipleEntitiesTypes.HIERARCHICAL)
      return (
        <HierarchicalJourney
          candidate={candidate.buyer}
          anchor={anchorOrg}
          cleaningData={candidate.cleaningData}
          updateRelationship={(relationToAnchor) => {
            handleUpdatePartialCleanData(candidate.buyer.guid, {
              relationToAnchor,
            });
          }}
          updateValidBuyersQuestion={(v) => {
            handleUpdatePartialCleanData(candidate.buyer.guid, {
              validBuyersCount: v,
              relationToAnchor: undefined,
              candidateAction: undefined,
              hierarchyConfirmed: undefined,
            });
          }}
          renameCandidate={(newName: string) =>
            handleUpdatePartialCleanData(candidate.buyer.guid, {
              candidateAction: { newName, type: "RENAME" },
            })
          }
          replaceCandidate={(replacedBy: OrgWithStats) =>
            handleUpdatePartialCleanData(candidate.buyer.guid, {
              candidateAction: { replacedBy, type: "MERGE" },
            })
          }
          confirmHierarchy={(v) => {
            handleUpdatePartialCleanData(candidate.buyer.guid, {
              hierarchyConfirmed: v,
            });
          }}
          orgPrimaryRole={orgPrimaryRole}
        />
      );
  }, [
    candidate,
    anchorOrg,
    handleUpdatePartialCleanData,
    addNewBuyer,
    removeNewBuyer,
    orgPrimaryRole,
  ]);

  const getButtonStyle = React.useCallback(
    (type: MultipleEntitiesTypes) =>
      candidate.cleaningData?.type === type ? "primary" : "default",
    [candidate.cleaningData?.type],
  );

  return (
    <div className={css.cleanDuplicatesPage}>
      <div>
        <h1>Step 3. Clean Candidate buyers naming multiple organisations</h1>
        {currentCandidateNumber && totalCandidates && (
          <p>
            Qualifying number {currentCandidateNumber} of {totalCandidates}
          </p>
        )}
        <div className={css.candidateCard}>
          <h2 className={css.entityLabel}>Candidate</h2>
          <CandidateItem org={candidate.buyer} />
          <h2 className={css.entityLabel}>In relation to the anchor</h2>
          <CandidateItem org={anchorOrg} />
        </div>
        <div>
          <h3>Are these distinct buyers or do they have a hierarchical relationship?</h3>

          <div className={css.hierarchyTypeButtons}>
            <Button
              type={getButtonStyle(MultipleEntitiesTypes.DISTINCT)}
              onClick={() =>
                handleUpdateCleanData(candidate.buyer.guid, {
                  type: MultipleEntitiesTypes.DISTINCT,
                  selectedDistinctBuyers: [anchorOrg],
                })
              }
            >
              Distinct
            </Button>
            <Button
              type={getButtonStyle(MultipleEntitiesTypes.HIERARCHICAL)}
              onClick={() =>
                handleUpdateCleanData(candidate.buyer.guid, {
                  type: MultipleEntitiesTypes.HIERARCHICAL,
                })
              }
            >
              Hierarchical
            </Button>
          </div>
        </div>
      </div>
      <div>{journey}</div>
    </div>
  );
}

export default CleanDuplicatesPage;
