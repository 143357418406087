import React, { useState } from "react";
import {
  Collapsible as RadixCollapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@radix-ui/react-collapsible";

import Chevron from "../../../lib/icons/Chevron";

import css from "./Collapsible.module.scss";

type CollapsibleProps = {
  title: string;
  subtitle?: string;
  children?: React.ReactNode | React.ReactNode[];
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  disabled?: boolean;
};

export default function Collapsible({
  title,
  subtitle,
  children,
  defaultOpen = false,
  onOpenChange,
  disabled = false,
}: CollapsibleProps) {
  const [open, setOpen] = useState<boolean>(defaultOpen);

  // If the collapsible is disabled,
  // we don't want to allow it to open or close
  // therefore, do not call onOpenChange
  const handleOpenChange = (open: boolean) => {
    if (disabled) return;

    setOpen(open);
    onOpenChange && onOpenChange(open);
  };

  return (
    <RadixCollapsible
      className={`${css.container} ${disabled ? css.disabled : ""}`}
      open={open}
      onOpenChange={handleOpenChange}
      disabled={disabled}
    >
      <CollapsibleTrigger className={css.trigger} asChild>
        <div className={css.headerContainer}>
          <div className={css.header}>
            <p className={css.title}>{title}</p>
            <p className={css.subtitle}>{subtitle}</p>
          </div>
          <button
            className={css.button}
            type="button"
            aria-label={open ? "Collapse section" : "Expand section"}
          >
            {open ? <Chevron direction="up" /> : <Chevron direction="down" />}
          </button>
        </div>
      </CollapsibleTrigger>
      {open && <div className={css.divider} />}
      <CollapsibleContent className={css.content}>{children}</CollapsibleContent>
    </RadixCollapsible>
  );
}
