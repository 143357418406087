/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LeadSignal } from './LeadSignal';
import {
    LeadSignalFromJSON,
    LeadSignalFromJSONTyped,
    LeadSignalToJSON,
} from './LeadSignal';
import type { ReportRecordBuyer } from './ReportRecordBuyer';
import {
    ReportRecordBuyerFromJSON,
    ReportRecordBuyerFromJSONTyped,
    ReportRecordBuyerToJSON,
} from './ReportRecordBuyer';

/**
 * 
 * @export
 * @interface ReportRecord
 */
export interface ReportRecord {
    /**
     * 
     * @type {string}
     * @memberof ReportRecord
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ReportRecord
     */
    guid: string;
    /**
     * 
     * @type {number}
     * @memberof ReportRecord
     */
    valueSrc?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReportRecord
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportRecord
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportRecord
     */
    publishDate: string;
    /**
     * 
     * @type {string}
     * @memberof ReportRecord
     */
    closeDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportRecord
     */
    expiryDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportRecord
     */
    stage: ReportRecordStageEnum;
    /**
     * 
     * @type {string}
     * @memberof ReportRecord
     */
    canonicalUrlPath: string;
    /**
     * 
     * @type {ReportRecordBuyer}
     * @memberof ReportRecord
     */
    buyer?: ReportRecordBuyer;
    /**
     * 
     * @type {Array<LeadSignal>}
     * @memberof ReportRecord
     */
    leadSignals: Array<LeadSignal>;
}


/**
 * @export
 */
export const ReportRecordStageEnum = {
    PreTender: 'PRE_TENDER',
    StalePreTender: 'STALE_PRE_TENDER',
    Open: 'OPEN',
    Closed: 'CLOSED',
    Awarded: 'AWARDED'
} as const;
export type ReportRecordStageEnum = typeof ReportRecordStageEnum[keyof typeof ReportRecordStageEnum];


/**
 * Check if a given object implements the ReportRecord interface.
 */
export function instanceOfReportRecord(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "publishDate" in value;
    isInstance = isInstance && "stage" in value;
    isInstance = isInstance && "canonicalUrlPath" in value;
    isInstance = isInstance && "leadSignals" in value;

    return isInstance;
}

export function ReportRecordFromJSON(json: any): ReportRecord {
    return ReportRecordFromJSONTyped(json, false);
}

export function ReportRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'guid': json['guid'],
        'valueSrc': !exists(json, 'value_src') ? undefined : json['value_src'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'publishDate': json['publish_date'],
        'closeDate': !exists(json, 'close_date') ? undefined : json['close_date'],
        'expiryDate': !exists(json, 'expiry_date') ? undefined : json['expiry_date'],
        'stage': json['stage'],
        'canonicalUrlPath': json['canonical_url_path'],
        'buyer': !exists(json, 'buyer') ? undefined : ReportRecordBuyerFromJSON(json['buyer']),
        'leadSignals': ((json['lead_signals'] as Array<any>).map(LeadSignalFromJSON)),
    };
}

export function ReportRecordToJSON(value?: ReportRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'guid': value.guid,
        'value_src': value.valueSrc,
        'currency': value.currency,
        'description': value.description,
        'publish_date': value.publishDate,
        'close_date': value.closeDate,
        'expiry_date': value.expiryDate,
        'stage': value.stage,
        'canonical_url_path': value.canonicalUrlPath,
        'buyer': ReportRecordBuyerToJSON(value.buyer),
        'lead_signals': ((value.leadSignals as Array<any>).map(LeadSignalToJSON)),
    };
}

