import React from "react";
import { hot } from "react-hot-loader/root";
import { Checkbox, Input } from "antd5";
import { WrapAdminComponent } from "pages/admin/AdminComponent";

import { TextLink } from "components/actions/Links";
import { ColumnType, commonTableColumns } from "lib/core_components/Table/ColumnTypes";
import { SearchCallback, SearchTable } from "lib/search/SearchTable";
import { SearchParams } from "lib/search/types";
import { EventDataTypes } from "lib/tracking";
import { OscarOrgDetails } from "lib/types/models";
import { isDefined } from "lib/utils";

import css from "./PageContainer.module.scss";

type Filters = {
  query?: string;
};

type PageState = {
  searchParams: SearchParams<Filters>;
};

function isValidSearch(filters: Filters) {
  return filters.query && filters.query.length > 1;
}

type FilterPanelProps = {
  filters: Filters;
  onFiltersChange: (newFilters: Partial<Filters>) => void;
};

function FilterPanel({ filters, onFiltersChange }: FilterPanelProps) {
  return (
    <div>
      <Input
        placeholder="Name search"
        value={filters.query || ""}
        onChange={(e) => onFiltersChange({ query: e.currentTarget.value })}
      />
    </div>
  );
}

function OscarSearchPageContent() {
  const [pageState, setPageState] = React.useState<PageState>({
    searchParams: {
      filters: {
        query: "HM Revenue & Customs",
      },
      sort: { field: "name", order: "ASC" },
    },
  });

  const handleSearch = React.useCallback<SearchCallback<Filters, OscarOrgDetails>>(
    async (api, _commonParams, searchParams) => {
      const { filters } = searchParams;
      if (!isValidSearch(filters)) {
        return {
          paging_info: {
            limit: 0,
            next_offset: 0,
            offset: 0,
            total_results: 0,
          },
          results: [],
        };
      }
      const buyersResponse = await api.searchOscarOrgs({
        text: filters.query,
        limit: 10000,
        sort_order: searchParams.sort.order,
        sort: searchParams.sort.field,
      });
      return buyersResponse;
    },
    [],
  );

  const generateColumns = React.useCallback(() => {
    const columns: ColumnType<OscarOrgDetails>[] = [
      {
        ...commonTableColumns.expandableColumn,
        title: "Name",
        key: "name",
        render: (_, r) => <span>{r.data.Org}</span>,
        sorter: true,
      },
      {
        title: "Org URN",
        key: "org_urn",
        dataIndex: "org_urn",
        width: 100,
      },
      {
        title: "Archived",
        key: "archived_by_oscar",
        dataIndex: "archived_by_oscar",
        width: 100,
        render: (_, r) => <Checkbox checked={r.archived_by_oscar} />,
      },
      {
        title: "Website",
        key: "website",
        render: (_, r) =>
          r.data.WebsiteUrl && (
            <TextLink targetType="external" to={r.data.WebsiteUrl}>
              {r.data.WebsiteUrl}
            </TextLink>
          ),
        width: 250,
      },

      {
        title: "Address",
        key: "address",
        render: (_, r) =>
          [r.data.Add1, r.data.Add2, r.data.Add3, r.data.Add4].filter(isDefined).join(", "),
        width: 250,
      },
      {
        title: "Data types",
        key: "data_types",
        render: (_, r) => <span>{r.data.DataTypes}</span>,
        width: 200,
      },
      {
        title: "Org Types",
        key: "org_types",
        render: (_, r) => <span>{r.data.OrgTypes}</span>,
        width: 200,
      },
    ];
    return columns;
  }, []);

  return (
    <div className={css.pageContainer}>
      <FilterPanel
        filters={pageState.searchParams.filters}
        onFiltersChange={(newFilters) => {
          setPageState({
            ...pageState,
            searchParams: {
              ...pageState.searchParams,
              filters: { ...pageState.searchParams.filters, ...newFilters },
            },
          });
        }}
      />
      <SearchTable
        onSearch={handleSearch}
        onSearchParamsChange={(searchParams) => setPageState({ ...pageState, searchParams })}
        searchParams={pageState.searchParams}
        generateColumns={generateColumns}
        rowKey="org_urn"
        debounceSearchRequests={true}
        exportedDataType={EventDataTypes.contact}
      />
    </div>
  );
}

export default hot(WrapAdminComponent(OscarSearchPageContent));
