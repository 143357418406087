import React from "react";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Button, Card } from "antd5";

import { BetaTag } from "../../lib/core_components/Labels";
import { stars2 } from "../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../lib/icons/untitled_ui/UIcon";
import { grey0 } from "../../lib/themes/colors";

import css from "./AutoQualifyCard.module.scss";

export function AutoQualifyCard({ noticeId }: { noticeId: string }) {
  const isEnabled = useVariableValue("notice-auto-qualification", false);

  if (!isEnabled) {
    return null;
  }

  return (
    <div>
      <Card className={`${css.upperCard}`}>
        <div className={css.upperCardContent}>
          <h2 className={css.mediumTitle}>Auto-qualify</h2>
          <BetaTag />
        </div>
      </Card>
      <Card className={css.lowerCard}>
        <div className={css.lowerCardContent}>
          <p>
            Make a quick bid/no bid decision. Auto-qualify reads your tender documents and generates
            a qualification report.
          </p>
          <div>
            {/* ToDo: use a wouter link in the right context so that the whole app doesn't reload */}
            <a href={`/records/${noticeId}/auto_qualification`}>
              <Button type="primary" icon={<UIcon svg={stars2} color={grey0} size={16} />}>
                Run auto-qualify
              </Button>
            </a>
          </div>
        </div>
      </Card>
    </div>
  );
}
