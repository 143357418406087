import React from "react";
import { Modal } from "antd5";

import { CreateOpportunityForm, CreateOpportunityFormValues } from "./CreateOpportunityForm";

type Props = {
  defaultValues?: Partial<CreateOpportunityFormValues>;
  onClose: () => void;
  isOpen: boolean;
};

export function CreateOpportunityModal({ isOpen, defaultValues, onClose }: Props) {
  return (
    <Modal title="Create Opportunity" open={isOpen} footer={null} onCancel={onClose}>
      <CreateOpportunityForm onCancel={onClose} onSuccess={onClose} defaultValues={defaultValues} />
    </Modal>
  );
}
