import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, ConfigProvider, Modal } from "antd5";

import { MidPaddingModal } from "lib/themes/MidPaddingModal";
import { EventNames, useTracking } from "lib/tracking";

import css from "./SaveSupplierModal.module.scss";

export const HIDE_SAVE_SUPPLIER_MODAL = "HIDE_SAVE_SUPPLIER_MODAL";

export type SaveSupplierModalProps = {
  type: "partner" | "competitor";
  isBulkSave: boolean;
  isOpen: boolean;
  action: "Save" | "Remove";
  onConfirm: () => void;
  onClose: () => void;
};

function SaveSupplierModal({
  type,
  isOpen,
  isBulkSave,
  action,
  onConfirm,
  onClose,
}: SaveSupplierModalProps) {
  const { logEvent } = useTracking();

  const closeModal = (actionType: "Confirm" | "Cancel") => {
    logEvent(EventNames.saveSignalsModalActioned, {
      "Context source": "Save suppliers confirm modal",
      "Action type": actionType,
      "Don't show me again checked?":
        localStorage.getItem(HIDE_SAVE_SUPPLIER_MODAL) === "true" ? "Yes" : "No",
    });
    onClose();
  };

  return (
    <ConfigProvider theme={MidPaddingModal}>
      <Modal
        title={
          <div className={css.header}>
            <InfoCircleOutlined className={css.icon} />
            <h2 className={css.title}>
              {isBulkSave
                ? `${action} suppliers ${action === "Save" ? "as" : "from"} ${type} signals`
                : `${action} supplier as a ${type} signal`}
            </h2>
          </div>
        }
        open={isOpen}
        closable={false}
        footer={
          <div className={css.footer} aria-label="Modal footer">
            <Checkbox
              onChange={(e) =>
                localStorage.setItem(HIDE_SAVE_SUPPLIER_MODAL, `${e.target.checked}`)
              }
            >
              Don't show me this again
            </Checkbox>
            <span className={css.buttons}>
              <Button onClick={() => closeModal("Cancel")}>Cancel</Button>
              <Button
                type="primary"
                onClick={() => {
                  onConfirm();

                  closeModal("Confirm");
                }}
              >
                Continue
              </Button>
            </span>
          </div>
        }
      >
        <div className={css.content}>
          {isBulkSave
            ? `${action === "Save" ? "Saving" : "Removing"} these suppliers as ${type} signals `
            : `${action === "Save" ? "Saving" : "Removing"} this supplier as a ${type} signal `}
          will also update your signal settings and could affect your saved views, including the
          views owned by other users in your team.
        </div>
      </Modal>
    </ConfigProvider>
  );
}

export default SaveSupplierModal;
