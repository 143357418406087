/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategories
 */
export interface BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategories {
    /**
     * 
     * @type {Array<string>}
     * @memberof BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategories
     */
    categoryIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategories
     */
    includeUncategorised?: boolean | null;
}

/**
 * Check if a given object implements the BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategories interface.
 */
export function instanceOfBuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategories(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategoriesFromJSON(json: any): BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategories {
    return BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategoriesFromJSONTyped(json, false);
}

export function BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategoriesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategories {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryIds': !exists(json, 'category_ids') ? undefined : json['category_ids'],
        'includeUncategorised': !exists(json, 'include_uncategorised') ? undefined : json['include_uncategorised'],
    };
}

export function BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategoriesToJSON(value?: BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersCategories | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category_ids': value.categoryIds,
        'include_uncategorised': value.includeUncategorised,
    };
}

