/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PermissionUpdate
 */
export interface PermissionUpdate {
    /**
     * 
     * @type {string}
     * @memberof PermissionUpdate
     */
    accessorId: string;
    /**
     * 
     * @type {string}
     * @memberof PermissionUpdate
     */
    roleName: string | null;
}

/**
 * Check if a given object implements the PermissionUpdate interface.
 */
export function instanceOfPermissionUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessorId" in value;
    isInstance = isInstance && "roleName" in value;

    return isInstance;
}

export function PermissionUpdateFromJSON(json: any): PermissionUpdate {
    return PermissionUpdateFromJSONTyped(json, false);
}

export function PermissionUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PermissionUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessorId': json['accessor_id'],
        'roleName': json['role_name'],
    };
}

export function PermissionUpdateToJSON(value?: PermissionUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessor_id': value.accessorId,
        'role_name': value.roleName,
    };
}

