/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ExecuteCompletionRequest,
  ExecuteCompletionResponse,
  ExecutePromptRequest,
  ExecutePromptResponse,
  ForwardGeolocationRequest,
  ForwardGeolocationResponse,
  HealthControllerReadiness200Response,
  HealthControllerReadiness503Response,
  HighlightRecordsRequest,
  PreviewPromptResponse,
  QueryResult,
  ResolveOrganisationRequest,
  ResolveOrganisationResponse,
  SearchEntitiesRequest,
  SearchEntitiesResponse,
  SearchFrameworkOrganisationsRequest,
  SearchFrameworkOrganisationsResponse,
  SearchRecordsRequest,
} from '../models';
import {
    ExecuteCompletionRequestFromJSON,
    ExecuteCompletionRequestToJSON,
    ExecuteCompletionResponseFromJSON,
    ExecuteCompletionResponseToJSON,
    ExecutePromptRequestFromJSON,
    ExecutePromptRequestToJSON,
    ExecutePromptResponseFromJSON,
    ExecutePromptResponseToJSON,
    ForwardGeolocationRequestFromJSON,
    ForwardGeolocationRequestToJSON,
    ForwardGeolocationResponseFromJSON,
    ForwardGeolocationResponseToJSON,
    HealthControllerReadiness200ResponseFromJSON,
    HealthControllerReadiness200ResponseToJSON,
    HealthControllerReadiness503ResponseFromJSON,
    HealthControllerReadiness503ResponseToJSON,
    HighlightRecordsRequestFromJSON,
    HighlightRecordsRequestToJSON,
    PreviewPromptResponseFromJSON,
    PreviewPromptResponseToJSON,
    QueryResultFromJSON,
    QueryResultToJSON,
    ResolveOrganisationRequestFromJSON,
    ResolveOrganisationRequestToJSON,
    ResolveOrganisationResponseFromJSON,
    ResolveOrganisationResponseToJSON,
    SearchEntitiesRequestFromJSON,
    SearchEntitiesRequestToJSON,
    SearchEntitiesResponseFromJSON,
    SearchEntitiesResponseToJSON,
    SearchFrameworkOrganisationsRequestFromJSON,
    SearchFrameworkOrganisationsRequestToJSON,
    SearchFrameworkOrganisationsResponseFromJSON,
    SearchFrameworkOrganisationsResponseToJSON,
    SearchRecordsRequestFromJSON,
    SearchRecordsRequestToJSON,
} from '../models';

export interface ElasticQueryRequest {
    searchRecordsRequest: SearchRecordsRequest;
}

export interface ExecuteCompletionOperationRequest {
    executeCompletionRequest: ExecuteCompletionRequest;
}

export interface ExecutePromptOperationRequest {
    executePromptRequest: ExecutePromptRequest;
}

export interface ForwardGeocodeRequest {
    forwardGeolocationRequest: ForwardGeolocationRequest;
}

export interface HighlightRequest {
    highlightRecordsRequest: HighlightRecordsRequest;
}

export interface PreviewPromptRequest {
    executePromptRequest: ExecutePromptRequest;
}

export interface ResolveOrganisationOperationRequest {
    resolveOrganisationRequest: ResolveOrganisationRequest;
}

export interface SearchRequest {
    searchRecordsRequest: SearchRecordsRequest;
}

export interface SearchEntitiesOperationRequest {
    searchEntitiesRequest: SearchEntitiesRequest;
}

export interface SearchFrameworkOrganisationsOperationRequest {
    searchFrameworkOrganisationsRequest: SearchFrameworkOrganisationsRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * 
     */
    async elasticQueryRaw(requestParameters: ElasticQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.searchRecordsRequest === null || requestParameters.searchRecordsRequest === undefined) {
            throw new runtime.RequiredError('searchRecordsRequest','Required parameter requestParameters.searchRecordsRequest was null or undefined when calling elasticQuery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/search/elastic-query`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRecordsRequestToJSON(requestParameters.searchRecordsRequest),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * 
     */
    async elasticQuery(requestParameters: ElasticQueryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.elasticQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async executeCompletionRaw(requestParameters: ExecuteCompletionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecuteCompletionResponse>> {
        if (requestParameters.executeCompletionRequest === null || requestParameters.executeCompletionRequest === undefined) {
            throw new runtime.RequiredError('executeCompletionRequest','Required parameter requestParameters.executeCompletionRequest was null or undefined when calling executeCompletion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/llm-tools/execute-completion`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExecuteCompletionRequestToJSON(requestParameters.executeCompletionRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecuteCompletionResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async executeCompletion(requestParameters: ExecuteCompletionOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecuteCompletionResponse> {
        const response = await this.executeCompletionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async executePromptRaw(requestParameters: ExecutePromptOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ExecutePromptResponse>> {
        if (requestParameters.executePromptRequest === null || requestParameters.executePromptRequest === undefined) {
            throw new runtime.RequiredError('executePromptRequest','Required parameter requestParameters.executePromptRequest was null or undefined when calling executePrompt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/llm-tools/execute-prompt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExecutePromptRequestToJSON(requestParameters.executePromptRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExecutePromptResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async executePrompt(requestParameters: ExecutePromptOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ExecutePromptResponse> {
        const response = await this.executePromptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async forwardGeocodeRaw(requestParameters: ForwardGeocodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ForwardGeolocationResponse>> {
        if (requestParameters.forwardGeolocationRequest === null || requestParameters.forwardGeolocationRequest === undefined) {
            throw new runtime.RequiredError('forwardGeolocationRequest','Required parameter requestParameters.forwardGeolocationRequest was null or undefined when calling forwardGeocode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/geocoding`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForwardGeolocationRequestToJSON(requestParameters.forwardGeolocationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ForwardGeolocationResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async forwardGeocode(requestParameters: ForwardGeocodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ForwardGeolocationResponse> {
        const response = await this.forwardGeocodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async healthControllerReadinessRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthControllerReadiness200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthControllerReadiness200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async healthControllerReadiness(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthControllerReadiness200Response> {
        const response = await this.healthControllerReadinessRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async highlightRaw(requestParameters: HighlightRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryResult>> {
        if (requestParameters.highlightRecordsRequest === null || requestParameters.highlightRecordsRequest === undefined) {
            throw new runtime.RequiredError('highlightRecordsRequest','Required parameter requestParameters.highlightRecordsRequest was null or undefined when calling highlight.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/search/highlight`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HighlightRecordsRequestToJSON(requestParameters.highlightRecordsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryResultFromJSON(jsonValue));
    }

    /**
     * 
     */
    async highlight(requestParameters: HighlightRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryResult> {
        const response = await this.highlightRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async previewPromptRaw(requestParameters: PreviewPromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PreviewPromptResponse>> {
        if (requestParameters.executePromptRequest === null || requestParameters.executePromptRequest === undefined) {
            throw new runtime.RequiredError('executePromptRequest','Required parameter requestParameters.executePromptRequest was null or undefined when calling previewPrompt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/llm-tools/preview-prompt`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExecutePromptRequestToJSON(requestParameters.executePromptRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PreviewPromptResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async previewPrompt(requestParameters: PreviewPromptRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PreviewPromptResponse> {
        const response = await this.previewPromptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async resolveOrganisationRaw(requestParameters: ResolveOrganisationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResolveOrganisationResponse>> {
        if (requestParameters.resolveOrganisationRequest === null || requestParameters.resolveOrganisationRequest === undefined) {
            throw new runtime.RequiredError('resolveOrganisationRequest','Required parameter requestParameters.resolveOrganisationRequest was null or undefined when calling resolveOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/resolve-organisation`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResolveOrganisationRequestToJSON(requestParameters.resolveOrganisationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResolveOrganisationResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async resolveOrganisation(requestParameters: ResolveOrganisationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResolveOrganisationResponse> {
        const response = await this.resolveOrganisationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async searchRaw(requestParameters: SearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QueryResult>> {
        if (requestParameters.searchRecordsRequest === null || requestParameters.searchRecordsRequest === undefined) {
            throw new runtime.RequiredError('searchRecordsRequest','Required parameter requestParameters.searchRecordsRequest was null or undefined when calling search.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRecordsRequestToJSON(requestParameters.searchRecordsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QueryResultFromJSON(jsonValue));
    }

    /**
     * 
     */
    async search(requestParameters: SearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QueryResult> {
        const response = await this.searchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async searchEntitiesRaw(requestParameters: SearchEntitiesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchEntitiesResponse>> {
        if (requestParameters.searchEntitiesRequest === null || requestParameters.searchEntitiesRequest === undefined) {
            throw new runtime.RequiredError('searchEntitiesRequest','Required parameter requestParameters.searchEntitiesRequest was null or undefined when calling searchEntities.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/search-entities`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchEntitiesRequestToJSON(requestParameters.searchEntitiesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchEntitiesResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async searchEntities(requestParameters: SearchEntitiesOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchEntitiesResponse> {
        const response = await this.searchEntitiesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async searchFrameworkOrganisationsRaw(requestParameters: SearchFrameworkOrganisationsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchFrameworkOrganisationsResponse>> {
        if (requestParameters.searchFrameworkOrganisationsRequest === null || requestParameters.searchFrameworkOrganisationsRequest === undefined) {
            throw new runtime.RequiredError('searchFrameworkOrganisationsRequest','Required parameter requestParameters.searchFrameworkOrganisationsRequest was null or undefined when calling searchFrameworkOrganisations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/services/data-svc/framework-organisations/searchFrameworkOrganisations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchFrameworkOrganisationsRequestToJSON(requestParameters.searchFrameworkOrganisationsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchFrameworkOrganisationsResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    async searchFrameworkOrganisations(requestParameters: SearchFrameworkOrganisationsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchFrameworkOrganisationsResponse> {
        const response = await this.searchFrameworkOrganisationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
