import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { assertCurrentUser } from "lib/currentUser";
import { CommentDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";
import { updateCachedNotices, updateCachedRecord } from "../utils";

type Params = {
  procurementStageId: string;
  content: string;
  recordGuid: string;
};

export function useCreateComment(
  options?: UseMutationOptions<CommentDto, unknown, Params, unknown>,
) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  const { logEvent } = useTracking();
  const user = assertCurrentUser();
  return useMutation(
    ({ procurementStageId, content, recordGuid }: Params) =>
      api.createProcurementStageComment({
        procurementStageId,
        createCommentRequest: { content, recordId: recordGuid },
        companyGuid: user.company.guid,
      }),
    {
      ...options,
      onSuccess: async (data, vars, context) => {
        const procurementStageId = vars.procurementStageId;

        logEvent(EventNames.commentActioned, {
          "Action type": "created",
          "Comment ID": data.id,
        });

        await queryClient.invalidateQueries(["comments", procurementStageId]);

        updateCachedRecord(queryClient, vars.recordGuid, (r) => ({
          ...r,
          commentCount: r.commentCount ? r.commentCount + 1 : 1,
        }));

        updateCachedNotices(queryClient, vars.recordGuid, (r) => ({
          ...r,
          commentCount: r.comments?.length ? r.comments.length + 1 : 1,
        }));

        options?.onSuccess?.(data, vars, context);
      },
    },
  );
}
