/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OverallCpvStats
 */
export interface OverallCpvStats {
    /**
     * 
     * @type {number}
     * @memberof OverallCpvStats
     */
    totalAwarded: number;
    /**
     * 
     * @type {number}
     * @memberof OverallCpvStats
     */
    totalValue: number;
    /**
     * 
     * @type {number}
     * @memberof OverallCpvStats
     */
    avgContractValue: number;
    /**
     * 
     * @type {number}
     * @memberof OverallCpvStats
     */
    percentToSmes: number;
}

/**
 * Check if a given object implements the OverallCpvStats interface.
 */
export function instanceOfOverallCpvStats(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalAwarded" in value;
    isInstance = isInstance && "totalValue" in value;
    isInstance = isInstance && "avgContractValue" in value;
    isInstance = isInstance && "percentToSmes" in value;

    return isInstance;
}

export function OverallCpvStatsFromJSON(json: any): OverallCpvStats {
    return OverallCpvStatsFromJSONTyped(json, false);
}

export function OverallCpvStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverallCpvStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalAwarded': json['total_awarded'],
        'totalValue': json['total_value'],
        'avgContractValue': json['avg_contract_value'],
        'percentToSmes': json['percent_to_smes'],
    };
}

export function OverallCpvStatsToJSON(value?: OverallCpvStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total_awarded': value.totalAwarded,
        'total_value': value.totalValue,
        'avg_contract_value': value.avgContractValue,
        'percent_to_smes': value.percentToSmes,
    };
}

