/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReportSectionType } from './ReportSectionType';
import {
    ReportSectionTypeFromJSON,
    ReportSectionTypeFromJSONTyped,
    ReportSectionTypeToJSON,
} from './ReportSectionType';

/**
 * 
 * @export
 * @interface ReportData
 */
export interface ReportData {
    /**
     * 
     * @type {string}
     * @memberof ReportData
     */
    title: string;
    /**
     * 
     * @type {Array<ReportSectionType>}
     * @memberof ReportData
     */
    sections: Array<ReportSectionType>;
}

/**
 * Check if a given object implements the ReportData interface.
 */
export function instanceOfReportData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "sections" in value;

    return isInstance;
}

export function ReportDataFromJSON(json: any): ReportData {
    return ReportDataFromJSONTyped(json, false);
}

export function ReportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'sections': ((json['sections'] as Array<any>).map(ReportSectionTypeFromJSON)),
    };
}

export function ReportDataToJSON(value?: ReportData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'sections': ((value.sections as Array<any>).map(ReportSectionTypeToJSON)),
    };
}

