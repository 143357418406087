import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { PinnedSavedViewDto } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

export function usePinnedViews(
  options?: UseQueryOptions<PinnedSavedViewDto[], unknown, PinnedSavedViewDto[], string[]>,
) {
  const api = useOpenApi();
  return useQuery(
    ["pinnedViews"],
    async () => {
      const userId = window.currentUser?.guid;
      if (!userId) {
        return [];
      }
      const savedViews = await api.listPinnedSavedViews({
        listPinnedSavedViewRequest: { userId: userId },
      });
      return savedViews.pinnedSavedViews;
    },
    options,
  );
}
