import * as React from "react";

import { ButtonLink, TextLink } from "components/actions/Links";
import {
  convertRecordFiltersToNoticeFilters,
  generateNoticeSearchUrl,
} from "components/notices/utils";
import SectionHeader from "components/reports/builder_components/SectionHeader";
import SignalsContainer from "components/tags/SignalsContainer";
import { ReportSupplier } from "lib/generated/admin-api";
import { EventNames } from "lib/tracking";
import { LeadSignal } from "lib/types/models";
import { formatReportDate } from "../../utils";

import css from "./Suppliers.module.scss";

type Props = {
  suppliers: Record<number, ReportSupplier>;
  buyerIds: number[];
  title?: string;
  description?: string;
  id: string;
};

function sortByLatestActivity(a: ReportSupplier, b: ReportSupplier) {
  return new Date(b.latestActivity).getTime() - new Date(a.latestActivity).getTime();
}

function Suppliers({ suppliers, buyerIds, title, description, id }: Props): JSX.Element {
  const orderedSuppliers = React.useMemo(
    () => Object.values(suppliers).sort(sortByLatestActivity),
    [suppliers],
  );
  return (
    <div className={css.supplierSection} id={id}>
      <SectionHeader title={title} description={description} />
      <table className={css.suppliersTable}>
        <thead>
          <tr>
            <th>Signals</th>
            <th>Supplier</th>
            <th># of awards</th>
            <th>Awards</th>
            <th>Latest activity</th>
          </tr>
        </thead>
        <tbody>
          {orderedSuppliers.map((supplier) => {
            const url = `/suppliers/${supplier.id}`;
            const noticeSearchUrl = generateNoticeSearchUrl(
              convertRecordFiltersToNoticeFilters({
                searchMode: "AWARDS",
                supplierIds: [supplier.id],
                buyerIds: buyerIds,
              }),
            );
            return (
              <tr key={supplier.id} className={css.contentRow}>
                <td>
                  <SignalsContainer signals={supplier.signals as LeadSignal[]} />
                </td>
                <td>
                  <TextLink
                    to={url}
                    targetType="new-tab"
                    eventName={EventNames.reportSupplierTableProfileClicked}
                    eventAttributes={{ link: url }}
                  >
                    {supplier.name}
                  </TextLink>
                </td>
                <td>{supplier.awardCount} </td>
                <td>
                  <ButtonLink
                    to={noticeSearchUrl}
                    eventName={EventNames.reportSupplierTableAwardsClicked}
                    eventAttributes={{ link: noticeSearchUrl }}
                  >
                    View awards
                  </ButtonLink>
                </td>
                <td>{formatReportDate(supplier.latestActivity)} </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Suppliers;
