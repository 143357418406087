/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Report } from './Report';
import {
    ReportFromJSON,
    ReportFromJSONTyped,
    ReportToJSON,
} from './Report';

/**
 * 
 * @export
 * @interface GetPublicReportsResponse
 */
export interface GetPublicReportsResponse {
    /**
     * 
     * @type {Array<Report>}
     * @memberof GetPublicReportsResponse
     */
    reports: Array<Report>;
}

/**
 * Check if a given object implements the GetPublicReportsResponse interface.
 */
export function instanceOfGetPublicReportsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "reports" in value;

    return isInstance;
}

export function GetPublicReportsResponseFromJSON(json: any): GetPublicReportsResponse {
    return GetPublicReportsResponseFromJSONTyped(json, false);
}

export function GetPublicReportsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPublicReportsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reports': ((json['reports'] as Array<any>).map(ReportFromJSON)),
    };
}

export function GetPublicReportsResponseToJSON(value?: GetPublicReportsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reports': ((value.reports as Array<any>).map(ReportToJSON)),
    };
}

