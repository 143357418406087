/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AiQuestionSection
 */
export interface AiQuestionSection {
    /**
     * 
     * @type {string}
     * @memberof AiQuestionSection
     */
    type: AiQuestionSectionTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AiQuestionSection
     */
    question: string;
    /**
     * 
     * @type {string}
     * @memberof AiQuestionSection
     */
    answer: string;
    /**
     * 
     * @type {string}
     * @memberof AiQuestionSection
     */
    questionId: string;
    /**
     * 
     * @type {string}
     * @memberof AiQuestionSection
     */
    referenceId?: string;
}


/**
 * @export
 */
export const AiQuestionSectionTypeEnum = {
    AiQuestion: 'AI_QUESTION'
} as const;
export type AiQuestionSectionTypeEnum = typeof AiQuestionSectionTypeEnum[keyof typeof AiQuestionSectionTypeEnum];


/**
 * Check if a given object implements the AiQuestionSection interface.
 */
export function instanceOfAiQuestionSection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "question" in value;
    isInstance = isInstance && "answer" in value;
    isInstance = isInstance && "questionId" in value;

    return isInstance;
}

export function AiQuestionSectionFromJSON(json: any): AiQuestionSection {
    return AiQuestionSectionFromJSONTyped(json, false);
}

export function AiQuestionSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AiQuestionSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'question': json['question'],
        'answer': json['answer'],
        'questionId': json['questionId'],
        'referenceId': !exists(json, 'referenceId') ? undefined : json['referenceId'],
    };
}

export function AiQuestionSectionToJSON(value?: AiQuestionSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'question': value.question,
        'answer': value.answer,
        'questionId': value.questionId,
        'referenceId': value.referenceId,
    };
}

