/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetRecordSummaryDetailsRequest
 */
export interface GetRecordSummaryDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof GetRecordSummaryDetailsRequest
     */
    recordGuid: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetRecordSummaryDetailsRequest
     */
    attributeNames: Array<GetRecordSummaryDetailsRequestAttributeNamesEnum>;
}


/**
 * @export
 */
export const GetRecordSummaryDetailsRequestAttributeNamesEnum = {
    ContractValue: 'contract_value',
    TopicRelevance: 'topic_relevance',
    BidRequirements: 'bid_requirements',
    MentionedParties: 'mentioned_parties',
    RoutesToMarket: 'routes_to_market',
    IncumbentRelationships: 'incumbent_relationships',
    PreviousSimilarActivity: 'previous_similar_activity',
    ThingBeingProcured: 'thing_being_procured',
    Summary: 'summary'
} as const;
export type GetRecordSummaryDetailsRequestAttributeNamesEnum = typeof GetRecordSummaryDetailsRequestAttributeNamesEnum[keyof typeof GetRecordSummaryDetailsRequestAttributeNamesEnum];


/**
 * Check if a given object implements the GetRecordSummaryDetailsRequest interface.
 */
export function instanceOfGetRecordSummaryDetailsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "recordGuid" in value;
    isInstance = isInstance && "attributeNames" in value;

    return isInstance;
}

export function GetRecordSummaryDetailsRequestFromJSON(json: any): GetRecordSummaryDetailsRequest {
    return GetRecordSummaryDetailsRequestFromJSONTyped(json, false);
}

export function GetRecordSummaryDetailsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetRecordSummaryDetailsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordGuid': json['record_guid'],
        'attributeNames': json['attribute_names'],
    };
}

export function GetRecordSummaryDetailsRequestToJSON(value?: GetRecordSummaryDetailsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'record_guid': value.recordGuid,
        'attribute_names': value.attributeNames,
    };
}

