/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserSavedView } from './UserSavedView';
import {
    UserSavedViewFromJSON,
    UserSavedViewFromJSONTyped,
    UserSavedViewToJSON,
} from './UserSavedView';

/**
 * 
 * @export
 * @interface AvailableSavedViewsResponse
 */
export interface AvailableSavedViewsResponse {
    /**
     * 
     * @type {Array<UserSavedView>}
     * @memberof AvailableSavedViewsResponse
     */
    availableSavedViews: Array<UserSavedView>;
}

/**
 * Check if a given object implements the AvailableSavedViewsResponse interface.
 */
export function instanceOfAvailableSavedViewsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "availableSavedViews" in value;

    return isInstance;
}

export function AvailableSavedViewsResponseFromJSON(json: any): AvailableSavedViewsResponse {
    return AvailableSavedViewsResponseFromJSONTyped(json, false);
}

export function AvailableSavedViewsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableSavedViewsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'availableSavedViews': ((json['available_saved_views'] as Array<any>).map(UserSavedViewFromJSON)),
    };
}

export function AvailableSavedViewsResponseToJSON(value?: AvailableSavedViewsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'available_saved_views': ((value.availableSavedViews as Array<any>).map(UserSavedViewToJSON)),
    };
}

