import React from "react";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Button } from "antd5";
import { Skeleton } from "antd5/lib";

import { NEW_NOTICES_SEARCH, useVariableValue } from "lib/featureFlags";
import { NoticeDetails, useNotice } from "lib/hooks/api/notices/useNotice";
import { useNoticeHighlighting } from "lib/hooks/api/notices/useNoticeHighlighting";
import { useRecord } from "lib/hooks/api/useRecord";
import useElementInViewport from "lib/hooks/useElementInViewport";
import { RecordViewerEventData, useRecordViewer } from "lib/providers/RecordViewer";
import { EventNames, TrackingProvider, useTracking } from "lib/tracking";
import { getSignalTypes } from "lib/types/models";
import NoticeDetailsContent from "./NoticeDetailsContent";
import NoticeHeader from "./NoticeHeader";

import css from "./RecordPreview.module.scss";

type NoticePreviewProps = {
  guid: string;
  eventData?: RecordViewerEventData;
};

type NoticePreviewContentProps = {
  notice: NoticeDetails;
  eventData?: RecordViewerEventData;
};

function NoticePreviewContainer({ guid, eventData }: NoticePreviewProps) {
  const { data: notice, isLoading } = useNotice(guid);
  // temporary workaround to fix the open search signals error
  const { data: legacyNotice } = useRecord(guid);

  const useOpenSearch = useVariableValue(NEW_NOTICES_SEARCH, false);

  if (isLoading) {
    return (
      <div className={css.loadingSkeleton}>
        <Skeleton active title paragraph={{ rows: 5, width: "100%" }} />
        <Skeleton title={false} paragraph={{ rows: 10, width: "80%" }} />
        <Skeleton title={false} paragraph={{ rows: 10, width: "60%" }} />
      </div>
    );
  }

  if (!notice) {
    return <div>No data found for this record.</div>;
  }

  return (
    <NoticePreview
      notice={{
        ...notice,
        signals: useOpenSearch ? notice.signals : legacyNotice?.signals,
        score: useOpenSearch ? notice.score : notice.legacyRelevanceScore,
      }}
      eventData={eventData}
    />
  );
}

function NoticePreview({ notice, eventData }: NoticePreviewContentProps) {
  const [topOfPageRef, topOfPageIsVisible] = useElementInViewport<HTMLDivElement>();

  const { closeRecord } = useRecordViewer();
  const { logEvent } = useTracking();

  const signalTypes = getSignalTypes(notice.signals ?? []);

  const cpvCodes = notice.cpvCodes.map((code) => `${code.code} - ${code.name}`);

  const signalScore = notice.score ?? 0;

  React.useEffect(() => {
    logEvent(EventNames.recordPreviewSlideoutOpened, {
      ...eventData,
      "Record guid": notice.guid,
      "Signal score": signalScore,
      Stage: notice.stage ?? "Unknown",
      "Signal types": signalTypes ? Object.keys(signalTypes) : [],
      "Signals.CPV codes": cpvCodes || [],
      Signals: signalTypes,
    });
    // Should log only once on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { highlightTerms, filters } = useNoticeHighlighting(notice.procurementStage.id);

  return (
    <TrackingProvider
      data={{
        ...eventData,
        "Context source": `Record preview slide-out`,
        "Record GUID": notice.guid,
        "Signal score": signalScore,
        Stage: notice.stage ?? "Unknown",
        "Signal types": signalTypes ? Object.keys(signalTypes) : [],
        Signals: signalTypes,
      }}
    >
      <div className={css.previewWrapper}>
        <div className={css.controls}>
          <Button
            title="Close"
            icon={<DoubleRightOutlined className={css.closeIcon} />}
            onClick={closeRecord}
            className={css.controlsBtn}
          />
        </div>
        <div className={css.previewContent}>
          <NoticeHeader
            notice={notice}
            filters={filters}
            headerHighlights={highlightTerms?.title || []}
            trackingContextName="Record preview slide-out"
            disableTitleLink={!!window.guestUser}
            headerClassName={css.header}
            scrollClassName={css.scroll}
            isTopOfPageVisible={topOfPageIsVisible}
          />
          <div ref={topOfPageRef} />
          <div className={css.contentContainer}>
            <NoticeDetailsContent
              notice={notice}
              containerClassName={css.columnsContainer}
              trackingContextName="Record preview slide-out"
              noticeHighlights={highlightTerms}
            />
          </div>
        </div>
      </div>
    </TrackingProvider>
  );
}

export default NoticePreviewContainer;
