import React from "react";

import { Markdown } from "components/reports/builder_components/Markdown";

export function AnswerMarkdown({
  answer,
  trackingLinkContext,
}: {
  answer: string;
  trackingLinkContext?: (guid: string) => void;
}) {
  return <Markdown text={answer} trackingLinkContext={trackingLinkContext} targetType="new-tab" />;
}
