import React from "react";

import {
  LeadAssigneeData,
  ProcurementStageUserData,
  RecordDetails,
  RecordUserData,
  User,
  UserListSummary,
} from "lib/types/models";

// Where string is the procurement stage id
type LeadDataType = Record<string, ProcurementStageUserData>;
type RecordDataType = Record<string, RecordUserData>;
type RecordUpdateDataType = Record<string, Partial<RecordUserData>>;
type UserData = { has_dismissed_disqualification_warning: boolean };

// Lead data is on a procurement stage id basis
// Record data is on a record guid basis
type UserDataStore = {
  leadData: LeadDataType;
  userData: UserData;
  recordData: RecordDataType;
};

type UpdateLeadCacheAction = {
  type: "update_lead_cache";
  updates: LeadDataType;
};
type UpdateUserCacheAction = {
  type: "update_user_cache";
  updates: Partial<UserData>;
};
type CountOperators = "increment" | "decrement";
type UpdateCommentCountAction = {
  type: "update_comment_count";
  procurementStageId: string;
  operation: CountOperators;
};

type UpdateRecordCacheAction = {
  type: "update_record_cache";
  updates: RecordDataType;
};

type ArrayOperators = "add" | "remove";
type UpdateRecordListsAction = {
  type: "update_record_lists";
  recordGuid: string;
  operation: ArrayOperators;
  list: UserListSummary;
};

type UpdateRecordSeenByAction = {
  type: "update_record_seen_by";
  recordGuid: string;
  operation: Extract<ArrayOperators, "add">;
  user: { first_name: string; guid: string };
};

type Action =
  | UpdateLeadCacheAction
  | UpdateUserCacheAction
  | UpdateCommentCountAction
  | UpdateRecordCacheAction
  | UpdateRecordListsAction
  | UpdateRecordSeenByAction;

const updateLeadCache = (state: UserDataStore, updates: LeadDataType) => {
  const newLeadState = { ...state.leadData };
  for (const psId in updates) {
    newLeadState[psId] = { ...newLeadState[psId], ...updates[psId] };
  }
  return { ...state, leadData: newLeadState };
};

const updateUserCache = (state: UserDataStore, updates: Partial<UserData>) => {
  return { ...state, userData: { ...state.userData, ...updates } };
};

const updateRecordCache = (state: UserDataStore, updates: RecordUpdateDataType) => {
  const newRecordState = { ...state.recordData };
  for (const recordGuid in updates) {
    newRecordState[recordGuid] = { ...newRecordState[recordGuid], ...updates[recordGuid] };
  }
  return { ...state, recordData: newRecordState };
};

const getNewRecordLists = (state: UserDataStore, action: UpdateRecordListsAction) => {
  const newLists = state.recordData[action.recordGuid]?.lists?.slice() || [];
  // index tells us if it's in the list and we can use it to remove
  const listIndex = newLists.findIndex((l) => l.guid === action.list.guid);
  if (action.operation === "add" && listIndex === -1) {
    newLists.push(action.list);
  } else if (action.operation === "remove" && listIndex > -1) {
    newLists.splice(listIndex, 1);
  }
  return newLists;
};

const getNewRecordSeenBy = (state: UserDataStore, action: UpdateRecordSeenByAction) => {
  const newUsers = state.recordData[action.recordGuid]?.seenByUsers.slice() || [];
  // index tells us if it's in the list and we can use it to remove
  const userIndex = newUsers.findIndex((u) => u.guid === action.user.guid);
  if (action.operation === "add" && userIndex === -1) {
    newUsers.push(action.user);
  }
  return newUsers;
};

const reducer = (state: UserDataStore, action: Action): UserDataStore => {
  switch (action.type) {
    case "update_lead_cache":
      return updateLeadCache(state, action.updates);
    case "update_user_cache":
      return updateUserCache(state, action.updates);
    case "update_comment_count":
      return updateLeadCache(state, {
        [action.procurementStageId]: {
          comment_count:
            action.operation === "decrement"
              ? (state.leadData[action.procurementStageId].comment_count ?? 0) - 1
              : (state.leadData[action.procurementStageId].comment_count ?? 0) + 1,
        },
      });
    case "update_record_cache":
      return updateRecordCache(state, action.updates);
    case "update_record_lists":
      return updateRecordCache(state, {
        [action.recordGuid]: {
          lists: getNewRecordLists(state, action),
        },
      });
    case "update_record_seen_by":
      return updateRecordCache(state, {
        [action.recordGuid]: {
          seenByUsers: getNewRecordSeenBy(state, action),
        },
      });
    default:
      return state;
  }
};

type UserWriteMethods = {
  dismissDisqualificationWarning: () => void;
};

type LeadWriteMethods = {
  hideLead: (procurementStageId: string, hiddenDate: string) => void;
  unhideLead: (procurementStageId: string) => void;
  updateProcurementStageQualification: (
    procurementStageId: string,
    qualification: ProcurementStageUserData["procurementStageQualification"],
  ) => void;
  deleteProcurementStageQualification: (procurementStageId: string) => void;
  loadLeadDataFromRecords: (records: RecordDetails[]) => void;
  assignLead: (procurementStageId: string, assignee: LeadAssigneeData | null) => void;
  incrementCommentCount: (procurementStageId: string) => void;
  decrementCommentCount: (procurementStageId: string) => void;
};

type UserReadMethods = {
  getDismissedDisqualificationWarningValue: () => boolean;
};

type LeadReadMethods = {
  getDataForLead: (procurementStageId: string) => ProcurementStageUserData | undefined;
  getDataForLeads: (procurementStageIds: string[]) => {
    [x: string]: ProcurementStageUserData | undefined;
  };
};

type RecordReadMethods = {
  getDataForRecord: (guid: string) => RecordUserData | undefined;
};

type RecordWriteMethods = {
  loadRecordDataFromRecords: (records: RecordDetails[]) => void;
  addRecordToList: (guid: string, list: UserListSummary) => void;
  removeRecordFromList: (guid: string, list: UserListSummary) => void;
  markRecordAsSeen: (guid: string, user: User) => void;
};

type UserDataContext = {
  userDataStore: UserDataStore;
  userReadMethods: UserReadMethods;
  userWriteMethods: UserWriteMethods;
  leadReadMethods: LeadReadMethods;
  leadWriteMethods: LeadWriteMethods;
  recordReadMethods: RecordReadMethods;
  recordWriteMethods: RecordWriteMethods;
};

const extractLeadDataFromRecords = (records: RecordDetails[]) => {
  const extractedLeadData: LeadDataType = {};
  for (const r of records) {
    extractedLeadData[r.procurement_stage.id] = {
      hidden_date: r.hidden_date,
      qualification: r.procurement_stage.qualification,
      procurementStageQualification:
        r.procurement_stage.procurementStageQualification ||
        // when the record is passed directly to the page rather than the api it is in snake case
        r.procurement_stage.procurement_stage_qualification,
      assignee: r.assignee,
      comment_count: r.comment_count ?? 0,
    };
  }
  return extractedLeadData;
};

const extractRecordData = (records: RecordDetails[]) => {
  const extractedRecordData: RecordDataType = {};

  for (const r of records) {
    extractedRecordData[r.guid] = {
      lists: r.lists || [],
      seenByUsers: r.seen_by_users,
    };
  }
  return extractedRecordData;
};

const UserContext = React.createContext<UserDataContext | undefined>(undefined);

const initialState: UserDataStore = {
  leadData: {},
  recordData: {},
  userData: { has_dismissed_disqualification_warning: false },
};

export const UserDataProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [store, dispatch] = React.useReducer(reducer, initialState);

  const dispatchLeadCacheUpdate = React.useCallback((updates: LeadDataType) => {
    dispatch({
      type: "update_lead_cache",
      updates,
    });
  }, []);

  const dispatchUserCacheUpdate = React.useCallback((updates: Partial<UserData>) => {
    dispatch({
      type: "update_user_cache",
      updates,
    });
  }, []);

  const dispatchRecordCacheUpdate = React.useCallback((updates: RecordDataType) => {
    dispatch({
      type: "update_record_cache",
      updates,
    });
  }, []);

  const updateCommentCount = React.useCallback(
    (procurementStageId: string, operation: CountOperators) => {
      dispatch({
        type: "update_comment_count",
        procurementStageId,
        operation: operation,
      });
    },
    [],
  );

  const updateRecordLists = React.useCallback(
    (recordGuid: string, operation: ArrayOperators, list: UserListSummary) => {
      dispatch({
        type: "update_record_lists",
        recordGuid,
        operation,
        list,
      });
    },
    [],
  );

  React.useEffect(() => {
    if (window.currentUser) {
      dispatchUserCacheUpdate({
        has_dismissed_disqualification_warning:
          window.currentUser.has_dismissed_disqualification_warning,
      });
    }
  }, [dispatchUserCacheUpdate]);

  const recordWriteMethods: RecordWriteMethods = React.useMemo(
    () => ({
      loadRecordDataFromRecords: (records: RecordDetails[]) =>
        dispatchRecordCacheUpdate(extractRecordData(records)),
      addRecordToList: (guid: string, list: UserListSummary) =>
        updateRecordLists(guid, "add", list),
      removeRecordFromList: (guid: string, list: UserListSummary) =>
        updateRecordLists(guid, "remove", list),
      markRecordAsSeen: (guid: string, user: User) =>
        dispatch({
          type: "update_record_seen_by",
          recordGuid: guid,
          user: { first_name: user.first_name, guid: user.guid },
          operation: "add",
        }),
    }),
    [dispatchRecordCacheUpdate, updateRecordLists],
  );

  const leadWriteMethods: LeadWriteMethods = React.useMemo(
    () => ({
      hideLead: (psId: string, hidden_date: string) =>
        dispatchLeadCacheUpdate({ [psId]: { hidden_date } }),
      unhideLead: (psId: string) => dispatchLeadCacheUpdate({ [psId]: { hidden_date: null } }),
      updateProcurementStageQualification: (
        psId: string,
        procurementStageQualification: ProcurementStageUserData["procurementStageQualification"],
      ) => dispatchLeadCacheUpdate({ [psId]: { procurementStageQualification } }),
      deleteProcurementStageQualification: (psId: string) =>
        dispatchLeadCacheUpdate({ [psId]: { procurementStageQualification: undefined } }),
      loadLeadDataFromRecords: (records: RecordDetails[]) =>
        dispatchLeadCacheUpdate(extractLeadDataFromRecords(records)),
      assignLead: (psId: string, assignee: LeadAssigneeData | null) =>
        dispatchLeadCacheUpdate({ [psId]: { assignee } }),
      incrementCommentCount: (psId: string) => updateCommentCount(psId, "increment"),
      decrementCommentCount: (psId: string) => updateCommentCount(psId, "decrement"),
    }),
    [dispatchLeadCacheUpdate, updateCommentCount],
  );

  const userWriteMethods: UserWriteMethods = React.useMemo(
    () => ({
      dismissDisqualificationWarning: () =>
        dispatchUserCacheUpdate({ has_dismissed_disqualification_warning: true }),
    }),
    [dispatchUserCacheUpdate],
  );

  const recordReadMethods: RecordReadMethods = React.useMemo(
    () => ({
      getDataForRecord: (guid: string) => ({ ...store.recordData[guid] }),
    }),
    [store.recordData],
  );

  const leadReadMethods = React.useMemo(
    () => ({
      getDataForLeads: (psIds: string[]) =>
        psIds.reduce(
          (filteredStore, psId) => ({ ...filteredStore, [psId]: { ...store.leadData[psId] } }),
          {},
        ),
      getDataForLead: (psId: string) => ({ ...store.leadData[psId] }),
    }),
    [store.leadData],
  );

  const userReadMethods = React.useMemo(
    () => ({
      getDismissedDisqualificationWarningValue: () =>
        store.userData.has_dismissed_disqualification_warning,
    }),
    [store.userData],
  );

  const value = React.useMemo(
    () => ({
      userDataStore: store,
      userWriteMethods,
      userReadMethods,
      leadWriteMethods,
      leadReadMethods,
      recordReadMethods,
      recordWriteMethods,
    }),
    [
      store,
      userWriteMethods,
      userReadMethods,
      leadWriteMethods,
      leadReadMethods,
      recordReadMethods,
      recordWriteMethods,
    ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export function useUserDataProvider(): UserDataContext {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserDataProvider must be within UserDataProvider");
  }
  return context;
}
