/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeedSettingsQueryPreviewResponse
 */
export interface FeedSettingsQueryPreviewResponse {
    /**
     * 
     * @type {Array<object>}
     * @memberof FeedSettingsQueryPreviewResponse
     */
    signals: Array<object>;
    /**
     * 
     * @type {object}
     * @memberof FeedSettingsQueryPreviewResponse
     */
    query: object;
    /**
     * 
     * @type {string}
     * @memberof FeedSettingsQueryPreviewResponse
     */
    sql: string;
    /**
     * 
     * @type {string}
     * @memberof FeedSettingsQueryPreviewResponse
     */
    elasticQuery: string;
}

/**
 * Check if a given object implements the FeedSettingsQueryPreviewResponse interface.
 */
export function instanceOfFeedSettingsQueryPreviewResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "signals" in value;
    isInstance = isInstance && "query" in value;
    isInstance = isInstance && "sql" in value;
    isInstance = isInstance && "elasticQuery" in value;

    return isInstance;
}

export function FeedSettingsQueryPreviewResponseFromJSON(json: any): FeedSettingsQueryPreviewResponse {
    return FeedSettingsQueryPreviewResponseFromJSONTyped(json, false);
}

export function FeedSettingsQueryPreviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeedSettingsQueryPreviewResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signals': json['signals'],
        'query': json['query'],
        'sql': json['sql'],
        'elasticQuery': json['elastic_query'],
    };
}

export function FeedSettingsQueryPreviewResponseToJSON(value?: FeedSettingsQueryPreviewResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signals': value.signals,
        'query': value.query,
        'sql': value.sql,
        'elastic_query': value.elasticQuery,
    };
}

