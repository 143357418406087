import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  FrameworkUrlInformationQuery,
  QueryFrameworkArgs,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const frameworkQuery = graphql(`
  query frameworkUrlInformation($frameworkId: String!) {
    framework(frameworkId: $frameworkId) {
      id
      title
    }
  }
`);

export function useFrameworkUrlInformation(
  frameworkId: QueryFrameworkArgs["frameworkId"],
  options?: UseQueryOptions<
    FrameworkUrlInformationQuery,
    unknown,
    FrameworkUrlInformationQuery,
    QueryKey
  >,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["framework", frameworkId],
    frameworkQuery,
    [{ frameworkId: frameworkId }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return { data: data?.framework, ...rest };
}
