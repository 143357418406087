import * as React from "react";

import { TextHighlighter } from "lib/core_components/TextHighlighter";
import { SimpleMatchHighlighter } from "lib/core_components/TextMatchHighlighter";
import { useSignalSettings } from "lib/hooks/api/teams/useSignalSettings";
import { useSimpleKeywordMatches } from "./hooks";
import { ATTRIBUTE_NAMES, SummaryValue } from "./utils";

import css from "./SummaryPoint.module.scss";

import BRIEFCASE from "../../../assets/images/icons/briefcase.svg";
import CLIPBOARD_TICK from "../../../assets/images/icons/clipboard_tick.svg";
import CONTRACT from "../../../assets/images/icons/contract_text.svg";
import DROPBOX from "../../../assets/images/icons/dropbox.svg";
import STACK from "../../../assets/images/icons/stack.svg";

const ICONS_BY_ATTRIBUTE: Record<string, React.ReactNode> = {
  mentioned_parties: (
    <img src={BRIEFCASE} alt="briefcase" height={14} width={14} className={css.icon} />
  ),
  topic_relevance: <img src={STACK} alt="topics" height={14} width={14} className={css.icon} />,
  thing_being_procured: (
    <img src={DROPBOX} alt="package" height={14} width={14} className={css.icon} />
  ),
  contract_value: <img src={CONTRACT} alt="contract" height={14} width={14} className={css.icon} />,
  bid_requirements: (
    <img src={CLIPBOARD_TICK} alt="clipboard" height={14} width={14} className={css.icon} />
  ),
};

type Props = {
  summaryPoint: SummaryValue;
  wordsToHighlight: string[] | undefined;
  isNewHighlightingLogicEnabled: boolean | undefined;
};

export default function SummaryPoint({
  summaryPoint: { content, attributeName },
  wordsToHighlight,
  isNewHighlightingLogicEnabled,
}: Props) {
  const { data: signalSettings } = useSignalSettings();
  const keywordMatches = useSimpleKeywordMatches(content, signalSettings?.signals);

  if (!content) return null;

  return (
    <li>
      <span className={css.iconContainer}>{ICONS_BY_ATTRIBUTE[attributeName]}</span>
      <strong>{ATTRIBUTE_NAMES[attributeName]}</strong>
      <div className={css.content}>
        {isNewHighlightingLogicEnabled ? (
          <TextHighlighter content={content} wordsToHighlight={wordsToHighlight} />
        ) : (
          <SimpleMatchHighlighter text={content} matches={keywordMatches} />
        )}
      </div>
    </li>
  );
}
