import React from "react";
import { ControlProps } from "@jsonforms/core";
import { withJsonFormsControlProps } from "@jsonforms/react";

import { TextAreaInput } from "./TextArea";

function TextAreaControl({
  data,
  label,
  required,
  path,
  handleChange,
  errors,
}: ControlProps): JSX.Element {
  return (
    <TextAreaInput
      value={data}
      label={label}
      required={required}
      updateValue={(value: string | undefined) => handleChange(path, value)}
      errors={errors}
    />
  );
}

export default withJsonFormsControlProps(TextAreaControl);
