import React, { useState } from "react";
import { Button, ConfigProvider, Popover } from "antd5";

import { DefaultTheme } from "components/app_layout/DefaultTheme";
import { ProductTourCompletionStateResponseTourStateTourIdentifierEnum } from "lib/generated/app-api";
import { useProductTour } from "lib/hooks/useProductTour";
import * as colors from "lib/themes/colors";

import css from "./OnboardingPopover.module.scss";

const OnboardingTheme = {
  ...DefaultTheme,
  components: {
    Popover: {
      contentBg: colors.blue900,
      colorBgContainer: colors.blue900,
      colorBgElevated: colors.blue900,
      titleFontSize: 24,
    },
    Button: {
      ...DefaultTheme.components?.Button,

      colorBorder: colors.white,
      colorText: colors.white,
      colorBgContainer: colors.blue900,
    },
  },
  token: { colorText: colors.white },
};

export function OnboardingPopover({
  children,
  title,
  description,
  content = null,
  tour,
  enabled = true,
  defaultOpen,
}: {
  children: React.ReactNode;
  content?: React.ReactNode;
  title: string;
  description: string;
  tour: ProductTourCompletionStateResponseTourStateTourIdentifierEnum;
  enabled?: boolean;
  defaultOpen?: boolean;
}) {
  const { tourOpen, permanentlyHide } = useProductTour(tour);
  const [isOpen, setIsOpen] = useState(defaultOpen);

  if (!enabled || !tourOpen) {
    return <>{children}</>;
  }

  return (
    <ConfigProvider theme={OnboardingTheme}>
      <Popover
        trigger={["focus", "hover"]}
        content={
          // onClick stops other items i.e. slide outs from opening when interacting with the popover
          <div className={css.content} onClick={(e) => e.stopPropagation()}>
            <p>{description}</p>
            {content}
            <Button
              className={css.gotItButton}
              onClick={() => {
                permanentlyHide();
                setIsOpen(false);
              }}
            >
              Got it
            </Button>
          </div>
        }
        title={title}
        open={isOpen}
        onOpenChange={(val) => setIsOpen(val)}
      >
        {children}
      </Popover>
    </ConfigProvider>
  );
}
