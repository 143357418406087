import styled from "@emotion/styled";

export const EXPANDEDWIDTH = 300;
export const COLLAPSEDWIDTH = 80; // 32 internal width + 24 padding on each side

export const StaticWrapper = styled.div<{ stayExpanded: boolean }>(({ stayExpanded }) => ({
  zIndex: 3,
  width: stayExpanded ? EXPANDEDWIDTH : COLLAPSEDWIDTH,
  height: "100%",
  position: "relative",
  backgroundColor: "white",
}));

export const ExpandableWrapper = styled.div<{ expanded: boolean; showShadow: boolean }>(
  ({ theme, expanded, showShadow }) => ({
    height: "100%",
    flexShrink: 0,
    backgroundColor: theme.colors.white,
    boxShadow: showShadow ? "rgba(0, 0, 0, 0.1) 0px 4px 10px" : "none",
    borderRight: `1px solid ${theme.colors.sysBorderPrimary}`,
    display: "flex",
    flexDirection: "column",
    padding: 24,
    width: expanded ? EXPANDEDWIDTH : COLLAPSEDWIDTH,
    transition: "width 0.5s, transform 0.3s, box-shadow 0.3s",
    overflow: "hidden",
    overflowY: expanded ? "auto" : "hidden",
  }),
);

export const TopRow = styled.div({
  display: "flex",
  marginBottom: 24,
  gap: 16,
});

export const FadeInWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",

  animation: "fadeIn 0.3s forwards",
});

export const Logo = styled.img({
  height: 32,
  width: 32,
});

export const MainNav = styled.nav({
  paddingTop: 16,
  paddingBottom: 16,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 12,
});

export const FooterNav = styled.nav(({ theme }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.colors.sysBorderPrimary}`,
  paddingTop: 16,
  display: "flex",
  flexDirection: "column",
  gap: 12,
}));

export const Divider = styled.div(({ theme }) => ({
  height: 1,
  backgroundColor: theme.colors.sysBorderPrimary,
  margin: "8px 0",
}));

export const ExpandButton = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 32,
  width: 32,
  borderRadius: 4,
  cursor: "pointer",
  color: theme.colors.sysTextPlaceholder,
  transition: "all 0.3s",

  "&:hover": {
    color: theme.colors.sysTextDefault,
  },
}));

export const SubNavWrapper = styled.div<{
  subNavExpanded: boolean;
  height: number | string;
  shouldAnimate?: boolean;
}>(({ subNavExpanded, height, shouldAnimate = true }) => ({
  flexDirection: "column",
  maxHeight: subNavExpanded ? height : 0,
  overflow: "hidden",
  transition: shouldAnimate ? "all 0.3s" : "none",
}));
