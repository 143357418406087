/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer
 */
export interface BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer {
    /**
     * 
     * @type {boolean}
     * @memberof BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer
     */
    includeAll?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer
     */
    names?: Array<string> | null;
}

/**
 * Check if a given object implements the BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer interface.
 */
export function instanceOfBuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerFromJSON(json: any): BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer {
    return BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerFromJSONTyped(json, false);
}

export function BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'includeAll': !exists(json, 'include_all') ? undefined : json['include_all'],
        'names': !exists(json, 'names') ? undefined : json['names'],
    };
}

export function BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainerToJSON(value?: BuyersRelationshipStatsRequestBuyersRelationshipStatsFiltersBuyersRelationshipStatsSignalFilterSignalContainer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'include_all': value.includeAll,
        'names': value.names,
    };
}

