import React from "react";
import { Control } from "react-hook-form";
import { Divider } from "antd5";

import { SelectBuyerList } from "../../form_components/BuyerListSelect";
import { BuyerSelect } from "../../form_components/BuyerSelect";
import { BuyerTypeSelect } from "../../form_components/BuyerTypeSelect";
import { ContactFiltersComplete } from "../FilterForm";

import css from "./SharedFilterGroups.module.scss";

export interface BuyersFilterContentProps {
  control: Control<ContactFiltersComplete, unknown>;
  isBuyerIdsFilterApplied: boolean;
}

function BuyersFilterContent({ control, isBuyerIdsFilterApplied }: BuyersFilterContentProps) {
  return (
    <div className={css.filterSection} aria-label="Buyers filters">
      <BuyerSelect
        label="Buyers"
        name="buyerGuids"
        mode="multiple"
        userBuyerIdOptions={false}
        control={control}
        placeholder="Search buyers"
      />
      <Divider />
      <SelectBuyerList
        label="Buyers saved to"
        name="buyerListIds"
        mode="multiple"
        control={control}
        disabled={isBuyerIdsFilterApplied}
        placeholder="Select lists"
      />
      <BuyerTypeSelect
        label="Buyer type"
        name="buyerCategoryIds"
        control={control}
        placeholder="Select"
      />
    </div>
  );
}

export default BuyersFilterContent;
