import React from "react";
import { JsonForms } from "@jsonforms/react";
import { vanillaCells, vanillaRenderers } from "@jsonforms/vanilla-renderers";
import { Button, Modal } from "antd5";

import { DefaultTheme } from "components/app_layout/DefaultTheme";
import AccountSearchControl from "components/form_components/json_forms/account_search/AccountSearchControl";
import AccountSearchTester from "components/form_components/json_forms/account_search/AccountSearchTester";
import DateControl from "components/form_components/json_forms/date/DateControl";
import DateControlTester from "components/form_components/json_forms/date/DateControlTester";
import EnumControl from "components/form_components/json_forms/enum/EnumControl";
import EnumControlTester from "components/form_components/json_forms/enum/EnumControlTester";
import NumberControl from "components/form_components/json_forms/number/NumberControl";
import NumberTester from "components/form_components/json_forms/number/NumberTester";
import OptionsControl from "components/form_components/json_forms/options/OptionsControl";
import OptionsTester from "components/form_components/json_forms/options/OptionsTester";
import OwnerSearchControl from "components/form_components/json_forms/owner_search/OwnerSearchControl";
import OwnerSearchTester from "components/form_components/json_forms/owner_search/OwnerSearchTester";
import StringControl from "components/form_components/json_forms/string/StringControl";
import StringControlTester from "components/form_components/json_forms/string/StringControlTester";
import TextAreaControl from "components/form_components/json_forms/textarea/TextAreaControl";
import TextAreaTester from "components/form_components/json_forms/textarea/TextAreaTester";
import { FormContext } from "components/form_components/json_forms/useFormDataContext";
import { NoticeFormResponse } from "lib/generated/integration-api";
import { ProviderTypes } from "lib/generated/integration-api/models/ProviderTypes";
import { useNoticeFormSchema } from "lib/hooks/api/integrations/useNoticeFormSchema";
import { useSendNotice } from "lib/hooks/api/integrations/useSendNotice";
import { ExternalLink } from "lib/icons/ExternalLink";
import { EventNames, TrackingProvider, useTracking } from "lib/tracking";
import { IntegrationModalHeader } from "./IntegrationModalHeader";
import { SendToCRMButton } from "./ProviderButtons";
import { getNoticeHeaderCopy } from "./util";

import css from "./SendNoticeToCRMModal.module.scss";

const renderers = [
  ...vanillaRenderers,
  { tester: StringControlTester, renderer: StringControl },
  { tester: EnumControlTester, renderer: EnumControl },
  { tester: OwnerSearchTester, renderer: OwnerSearchControl },
  { tester: DateControlTester, renderer: DateControl },
  { tester: AccountSearchTester, renderer: AccountSearchControl },
  { tester: OptionsTester, renderer: OptionsControl },
  { tester: TextAreaTester, renderer: TextAreaControl },
  { tester: NumberTester, renderer: NumberControl },
];

type Notice = {
  guid: string;
  name: string;
  value?: number;
  url: string;
  closeDate?: string;
};

type SendNoticeToCRMModalProps = {
  notice: Notice;
  providerType: ProviderTypes;
  onClose: () => void;
  isOpen: boolean;
};

// Setup field mappings - pre-populate fields if they are present
function initialFormData(formData: NoticeFormResponse, notice: Notice) {
  const initialForm: Record<string, string | number> = {};
  for (const [key, val] of Object.entries(formData.mappings)) {
    switch (val) {
      case "title":
        initialForm[key] = notice.name;
        break;
      case "value":
        if (notice.value) {
          initialForm[key] = notice.value;
        }
        break;
      case "description":
        initialForm[key] =
          `Notice URL: ${window.location.origin}/records/${notice.guid} \r\nSource document URL:${notice.url}`;
        break;
      case "closeDate":
        if (notice.closeDate) {
          initialForm[key] = notice.closeDate;
        }
        break;
    }
  }

  return initialForm;
}

function cleanData(data: Record<string, unknown>): Record<string, unknown> {
  const newData = { ...data };
  for (const key of Object.keys(newData)) {
    if (newData[key] === undefined || newData[key] === null) {
      delete newData[key];
    }
  }
  return newData;
}

export function SendNoticeToCRMModal({
  notice,
  providerType,
  isOpen,
  onClose,
}: SendNoticeToCRMModalProps) {
  const { logEvent } = useTracking();
  const { mutate: sendNotice } = useSendNotice(providerType, notice.guid, notice.name, {
    onSuccess: () => {
      logEvent(EventNames.objectSentToCRM, trackingAttributes);
    },
  });
  const [data, setData] = React.useState({});
  const { data: formData } = useNoticeFormSchema({
    onSuccess: (fd) => setData(initialFormData(fd, notice)),
  });

  const [hasErrors, setHasErrors] = React.useState<boolean>(false);

  const trackingAttributes = React.useMemo(() => {
    return {
      "Data types": "Notice",
      "Connected provider": providerType,
    };
  }, [providerType]);

  const handleSubmit = React.useCallback(async () => {
    onClose();
    sendNotice({ recordGuid: notice.guid, data: cleanData(data) });
  }, [notice, onClose, sendNotice, data]);

  const onCancel = React.useCallback(() => {
    logEvent(EventNames.sendToCRMModalCancelled, trackingAttributes);
    onClose();
  }, [logEvent, trackingAttributes, onClose]);

  const footer = React.useMemo(
    () => (
      <div className={css.btnContainer}>
        <Button
          type="default"
          onClick={onCancel}
          className={css.editFrom}
          target="_blank"
          href="/account-management/integrations/setup"
        >
          Edit this form{" "}
          <ExternalLink className={css.externalLink} fill={DefaultTheme.token?.colorTextBase} />
        </Button>
        <SendToCRMButton
          disabled={hasErrors}
          onClick={handleSubmit}
          providerType={providerType}
          buttonType="primary"
          eventName={EventNames.sendToCRMClicked}
        />
      </div>
    ),
    [onCancel, hasErrors, handleSubmit, providerType],
  );

  if (!formData) {
    return <div>Loading</div>;
  }
  const headerCopy = getNoticeHeaderCopy(providerType, formData.entityName);

  return (
    <TrackingProvider
      data={{ "Context source": "Send to CRM modal", "Connected provider": providerType }}
    >
      <Modal
        open={isOpen}
        onCancel={onCancel}
        // To make sure it renders over the filter side drawer or any other thang
        zIndex={9999}
        title={
          <IntegrationModalHeader
            providerType={providerType}
            title={headerCopy.title}
            subtitle={headerCopy.subtitle}
          />
        }
        footer={footer}
      >
        <FormContext.Provider value={data}>
          <JsonForms
            schema={JSON.parse(formData.form)}
            data={data}
            renderers={renderers}
            uischema={JSON.parse(formData.layout)}
            cells={vanillaCells}
            onChange={({ errors, data }) => {
              setHasErrors(errors !== undefined && errors.length > 0);
              setData(data);
            }}
          />
        </FormContext.Provider>
      </Modal>
    </TrackingProvider>
  );
}
