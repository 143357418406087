/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AggregationGroupResult } from './AggregationGroupResult';
import {
    AggregationGroupResultFromJSON,
    AggregationGroupResultFromJSONTyped,
    AggregationGroupResultToJSON,
} from './AggregationGroupResult';

/**
 * Contains results of an aggregation query.
 *   The data in 'results' array is tied to the request AggregationQuery:
 *   - 'group' corresponds to the value of 'groupingField' for each group
 *   - each element in 'aggregations' array corresponds to one aggregation in the query,
 *     identified by aggregation's 'name'.
 *   Currently we only allow for aggregations with numeric results.
 * @export
 * @interface AggregateNotices200Response
 */
export interface AggregateNotices200Response {
    /**
     * 
     * @type {Array<AggregationGroupResult>}
     * @memberof AggregateNotices200Response
     */
    results: Array<AggregationGroupResult>;
    /**
     * 
     * @type {number}
     * @memberof AggregateNotices200Response
     */
    total: number;
}

/**
 * Check if a given object implements the AggregateNotices200Response interface.
 */
export function instanceOfAggregateNotices200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "results" in value;
    isInstance = isInstance && "total" in value;

    return isInstance;
}

export function AggregateNotices200ResponseFromJSON(json: any): AggregateNotices200Response {
    return AggregateNotices200ResponseFromJSONTyped(json, false);
}

export function AggregateNotices200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AggregateNotices200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(AggregationGroupResultFromJSON)),
        'total': json['total'],
    };
}

export function AggregateNotices200ResponseToJSON(value?: AggregateNotices200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(AggregationGroupResultToJSON)),
        'total': value.total,
    };
}

