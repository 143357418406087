import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useStotlesApi } from "lib/stotlesApiContext";

export function useSendInvites(options?: UseMutationOptions<void, unknown, string[], unknown>) {
  const api = useStotlesApi();
  const queryClient = useQueryClient();
  return useMutation((emails) => api.sendInvites(...emails), {
    ...options,
    onSuccess: (data, variables, context) => {
      void queryClient.invalidateQueries(["teams", "currentTeamInfo"]);
      options?.onSuccess && options?.onSuccess(data, variables, context);
    },
  });
}
