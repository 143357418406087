import * as React from "react";

import TextButton from "components/actions/TextButton";
import { useLocalDismissibleMsg } from "lib/hooks/useLocalDismissibleMessage";
import { ScreenSize, useResponsiveScreenSize } from "lib/providers/Responsive";

import css from "./SmallScreenWarning.module.scss";

const EXEMPT_PAGES = [
  "/records",
  "/wall-of-fame",
  "/cpv_codes",
  "/reports",
  "/get-started",
  "/email-verification",
];

const SIZE_WARNING_KEY = "hasSeenSizeWarning";

export const MAX_SMALL_SCREEN_WARNING_SIZE = ScreenSize.MD;

function SmallScreenWarning(): JSX.Element {
  const [hidden, onHiddenChange] = useLocalDismissibleMsg(SIZE_WARNING_KEY, "sessionStorage");
  const screenSize = useResponsiveScreenSize();

  const exemptPage = React.useMemo(
    () => EXEMPT_PAGES.some((e) => window.location.pathname.startsWith(e)),
    [],
  );

  const showSmallScreenWarning = React.useMemo(
    () => screenSize <= MAX_SMALL_SCREEN_WARNING_SIZE && !hidden && !exemptPage,
    [screenSize, hidden, exemptPage],
  );

  // To stop the body from scrolling when the warning is up
  React.useEffect(() => {
    const noScrollClass = css["no-scroll"];
    const body = window.document.body;
    if (showSmallScreenWarning) {
      body.classList.add(noScrollClass);
    } else {
      body.classList.remove(noScrollClass);
    }
  }, [showSmallScreenWarning]);

  return showSmallScreenWarning ? (
    <div className={css.smallScreenWarning}>
      <div className={css.content}>
        <h1>Hi, there!</h1>
        <p>
          We’re thrilled to see you visiting Stotles. Unfortunately, we don’t currently support
          small screens, and we recommend using a tablet or computer with screen width 992px or
          above for the best experience.
        </p>

        <TextButton onClick={() => onHiddenChange(true)}>Continue anyway</TextButton>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default SmallScreenWarning;
