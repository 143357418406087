/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignProcurementStageAssignee
 */
export interface AssignProcurementStageAssignee {
    /**
     * 
     * @type {string}
     * @memberof AssignProcurementStageAssignee
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AssignProcurementStageAssignee
     */
    guid: string | null;
}

/**
 * Check if a given object implements the AssignProcurementStageAssignee interface.
 */
export function instanceOfAssignProcurementStageAssignee(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "guid" in value;

    return isInstance;
}

export function AssignProcurementStageAssigneeFromJSON(json: any): AssignProcurementStageAssignee {
    return AssignProcurementStageAssigneeFromJSONTyped(json, false);
}

export function AssignProcurementStageAssigneeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignProcurementStageAssignee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'guid': json['guid'],
    };
}

export function AssignProcurementStageAssigneeToJSON(value?: AssignProcurementStageAssignee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'guid': value.guid,
    };
}

