import React from "react";
import { useForm } from "react-hook-form";
import { App, Button, Modal } from "antd5";

import { EventNames, useTracking } from "lib/tracking";
import { BasicSignal, getSignalTypes } from "lib/types/models";
import { useShareNotice } from "../../lib/hooks/api/notices/useShareNotice";
import { Checkbox, Select, TextArea } from "../form_components/Inputs";

import css from "./ShareModal.module.scss";

type RecordDetails = {
  guid: string;
  signals?: BasicSignal[];
  stage?: string | null;
  score: number;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  record: RecordDetails;
};

const INPUT_TOKEN_SEPARATORS = [",", " ", "\n"];

function ShareModal({ isOpen, onClose, record }: Props): JSX.Element {
  const { message } = App.useApp();
  const signalTypes = getSignalTypes(record.signals);
  const { control, handleSubmit } = useForm<{
    emails: string[];
    message: string;
    guids: string[];
    cc: boolean;
  }>({
    defaultValues: {
      emails: [],
      message: "",
      guids: [record.guid],
      cc: false,
    },
  });
  const { logEvent } = useTracking();

  const { mutate } = useShareNotice({
    onSuccess: (_, vars) => {
      message.success("Email sent successfully!");
      logEvent(EventNames.shareEmailSent, {
        "Form values": vars,
        Records: {
          guid: record.guid,
          Stage: record.stage,
          "Signal types": signalTypes ? Object.keys(signalTypes) : [],
          "Signal score": record.score,
          Signals: signalTypes,
        },
        "Signal types": signalTypes ? Object.keys(signalTypes) : [],
        Signals: record.signals,
        Stages: [record.stage],
      });
      onClose();
    },
    onError: () => {
      message.error("Failed to send email");
    },
  });

  return (
    <Modal open={isOpen} onCancel={onClose} footer={null} title="Share via email">
      <form
        onSubmit={handleSubmit((values) => mutate({ ...values, email: values.emails.join(",") }))}
        className={css.form}
      >
        <Select
          name="emails"
          control={control}
          label="To"
          options={[]}
          tokenSeparators={INPUT_TOKEN_SEPARATORS}
          mode="tags"
          open={false}
          suffixIcon={null}
          rules={{
            minLength: 1,
            required: "Please enter at least one email address",
            validate: (v) => {
              // Check if it's an array
              if (!Array.isArray(v)) {
                return "Please enter at least one email address";
              }

              // Check if the array is not empty
              if (v.length === 0) {
                return "Please enter at least one email address";
              }

              // Regular expression to validate email
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

              // Check if every string in the array is a valid email
              const allValidEmails = v.every(
                (email) => typeof email === "string" && emailRegex.test(email),
              );

              if (!allValidEmails) {
                return "Please enter a valid email address";
              }

              return allValidEmails;
            },
          }}
        />
        <TextArea name="message" control={control} label="Optional Message" rows={5} />
        <Checkbox name="cc" control={control} label="" fieldLabel="CC me in the email" />
        <div className={css.footer}>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Send
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ShareModal;
