import React, { useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import { CloseOutlined } from "@ant-design/icons";
import { Skeleton } from "antd5";

import RedactedWrapper from "lib/core_components/RedactedWrapper";
import { useDebouncedValue } from "lib/debounce";
import { useSupplierSearch } from "lib/hooks/api/suppliers/useSupplierSearch";
import Tag, { TagProps } from "../ui/tag/Tag";
import { Select, SelectProps } from "./Inputs";

import css from "./SupplierSelect.module.scss";

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options"> & {
  idType?: "guid" | "id";
  tagVariant?: TagProps["variant"];
  onCustomSearch?: (text: string) => void;
};

export function SupplierSelect<T extends FieldValues>({
  idType = "id",
  tagVariant,
  onCustomSearch,
  ...props
}: FieldProps<T>) {
  const { field } = useController(props);

  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText] = useDebouncedValue(searchText, 300);

  const showInitialSuppliers = !!field.value && !debouncedText && field.value.length > 0;
  const { data: initialSuppliers, isLoading: isLoadingInitial } = useSupplierSearch(
    {
      guid: idType === "guid" ? field.value : undefined,
      id: idType === "id" ? field.value : undefined,
    },
    {
      enabled: showInitialSuppliers,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const { data, isLoading } = useSupplierSearch(
    {
      sort: "relevance",
      text: debouncedText,
      limit: 20,
      country: ["UK", "IE"],
    },
    { enabled: !showInitialSuppliers && debouncedText.length > 1 },
  );

  let options = data?.results
    ? data.results.map((supplier) => ({
        label: supplier.name,
        value: idType === "id" ? supplier.id : supplier.guid,
      }))
    : [];

  if (!debouncedText && initialSuppliers?.results && options.length === 0) {
    options = initialSuppliers.results.map((supplier) => ({
      label: supplier.name,
      value: idType === "id" ? supplier.id : supplier.guid,
    }));
  }

  const loading =
    (isLoading && !showInitialSuppliers && !data) ||
    (isLoadingInitial && showInitialSuppliers && !initialSuppliers);

  const handleSearch = (val: string) => {
    if (onCustomSearch) {
      onCustomSearch(val);
    }

    setSearchText(val);
  };

  const renderDropdown = (menu: React.ReactElement) => {
    if (loading && !!debouncedText && !onCustomSearch) {
      return <Skeleton style={{ padding: "8px", paddingBottom: "0px" }} active />;
    }

    if (props?.dropdownRender) {
      return props.dropdownRender(menu);
    }

    return menu;
  };

  const dropdownStyle = debouncedText === "" ? { display: "none" } : {};

  return (
    <RedactedWrapper
      redactContent={
        <Select
          {...props}
          className={css.select}
          selectAll={!!debouncedText}
          loading={loading && !!debouncedText}
          options={options}
          optionFilterProp="label"
          onSearch={handleSearch}
          tagRender={(tagProps) => (
            <Tag
              {...tagProps}
              label={tagProps.label?.toString() ?? ""}
              isLoading={loading && tagProps.label?.toString() === tagProps.value}
              variant={tagVariant}
              icon={<CloseOutlined className={css.icon} />}
              onClick={() => {
                field.onChange(field.value.filter((value: string) => value !== tagProps.value));
              }}
            />
          )}
          showSearch
          filterOption={false}
          disabled
        />
      }
      requiredDataType="SUPPLIERS"
      contextSource="Supplier filter"
    >
      <Select
        {...props}
        className={css.select}
        selectAll={!!debouncedText}
        loading={loading && !!debouncedText}
        options={options}
        tagRender={(tagProps) => (
          <Tag
            {...tagProps}
            label={tagProps.label?.toString() ?? ""}
            isLoading={loading && tagProps.label?.toString() === tagProps.value}
            variant={tagVariant}
            icon={<CloseOutlined className={css.icon} />}
            onClick={() => {
              field.onChange(field.value.filter((value: string) => value !== tagProps.value));
            }}
          />
        )}
        dropdownRender={renderDropdown}
        dropdownStyle={dropdownStyle}
        optionFilterProp="label"
        onSearch={handleSearch}
        showSearch
        filterOption={false}
      />
    </RedactedWrapper>
  );
}
