import React from "react";

import { AnswerMarkdown } from "components/buyer_details/question_panel/AnswerMarkdown";
import { DispatchComponentProps } from "components/reports/Section";
import { AiQuestionSection as Props } from "lib/generated/admin-api";

export function AiQuestionSection({ section }: DispatchComponentProps<Props>) {
  return (
    <div>
      <h2>{section.question}</h2>
      <AnswerMarkdown answer={section.answer} />
    </div>
  );
}
