/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupplierSignalStatsRequest
 */
export interface SupplierSignalStatsRequest {
    /**
     * 
     * @type {string}
     * @memberof SupplierSignalStatsRequest
     */
    signalName: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierSignalStatsRequest
     */
    signalCategory: string;
}

/**
 * Check if a given object implements the SupplierSignalStatsRequest interface.
 */
export function instanceOfSupplierSignalStatsRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "signalName" in value;
    isInstance = isInstance && "signalCategory" in value;

    return isInstance;
}

export function SupplierSignalStatsRequestFromJSON(json: any): SupplierSignalStatsRequest {
    return SupplierSignalStatsRequestFromJSONTyped(json, false);
}

export function SupplierSignalStatsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupplierSignalStatsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signalName': json['signal_name'],
        'signalCategory': json['signal_category'],
    };
}

export function SupplierSignalStatsRequestToJSON(value?: SupplierSignalStatsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signal_name': value.signalName,
        'signal_category': value.signalCategory,
    };
}

