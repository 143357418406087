import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { ContactDetailsResponse } from "lib/generated/integration-api";
import { useIntegrationApi } from "lib/integrationApiContext";

type QueryKey = [string, string];

export function useDescribeContactDetails(
  contactId: string,
  options?: UseQueryOptions<ContactDetailsResponse, unknown, ContactDetailsResponse, QueryKey>,
) {
  const api = useIntegrationApi();
  return useQuery(
    ["contactDetails", contactId],
    async () => {
      const response = await api.describeContactDetails({
        contactDetailsRequest: { contactId: contactId },
      });
      return response;
    },
    options,
  );
}
