/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentEditItem
 */
export interface DocumentEditItem {
    /**
     * 
     * @type {string}
     * @memberof DocumentEditItem
     */
    ref: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentEditItem
     */
    caption?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentEditItem
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentEditItem
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentEditItem
     */
    highlightTerms?: Array<string>;
}

/**
 * Check if a given object implements the DocumentEditItem interface.
 */
export function instanceOfDocumentEditItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ref" in value;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function DocumentEditItemFromJSON(json: any): DocumentEditItem {
    return DocumentEditItemFromJSONTyped(json, false);
}

export function DocumentEditItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentEditItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ref': json['ref'],
        'caption': !exists(json, 'caption') ? undefined : json['caption'],
        'title': json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'highlightTerms': !exists(json, 'highlight_terms') ? undefined : json['highlight_terms'],
    };
}

export function DocumentEditItemToJSON(value?: DocumentEditItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ref': value.ref,
        'caption': value.caption,
        'title': value.title,
        'description': value.description,
        'highlight_terms': value.highlightTerms,
    };
}

