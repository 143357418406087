import React from "react";
import { AntdIconProps } from "@ant-design/icons/lib/components/AntdIcon";
import SafetyCertificateTwoTone from "@ant-design/icons/SafetyCertificateTwoTone";

function StotlesVerifiedIcon(
  props: Omit<AntdIconProps, "ref" | "theme" | "twoToneColor">,
): JSX.Element {
  const { style, ...otherProps } = props;

  return (
    <SafetyCertificateTwoTone
      // these are just default styles which will be overridden by other style props passed in
      style={{ verticalAlign: 0, marginLeft: "4px", ...style }}
      {...otherProps}
      twoToneColor="#4f86fa"
    />
  );
}

export default StotlesVerifiedIcon;
