/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuyerModelInstanceCategory } from './BuyerModelInstanceCategory';
import {
    BuyerModelInstanceCategoryFromJSON,
    BuyerModelInstanceCategoryFromJSONTyped,
    BuyerModelInstanceCategoryToJSON,
} from './BuyerModelInstanceCategory';

/**
 * 
 * @export
 * @interface BuyerModelInstance
 */
export interface BuyerModelInstance {
    /**
     * 
     * @type {number}
     * @memberof BuyerModelInstance
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BuyerModelInstance
     */
    guid: string;
    /**
     * 
     * @type {Date}
     * @memberof BuyerModelInstance
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof BuyerModelInstance
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerModelInstance
     */
    url: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuyerModelInstance
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof BuyerModelInstance
     */
    town: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuyerModelInstance
     */
    postcode: string | null;
    /**
     * 
     * @type {string}
     * @memberof BuyerModelInstance
     */
    address: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BuyerModelInstance
     */
    isArchived: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BuyerModelInstance
     */
    isStotlesVerified: boolean;
    /**
     * 
     * @type {string}
     * @memberof BuyerModelInstance
     */
    oscarId: string | null;
    /**
     * 
     * @type {Array<BuyerModelInstance>}
     * @memberof BuyerModelInstance
     */
    replacedBy: Array<BuyerModelInstance> | null;
    /**
     * 
     * @type {Array<BuyerModelInstanceCategory>}
     * @memberof BuyerModelInstance
     */
    categories: Array<BuyerModelInstanceCategory>;
}

/**
 * Check if a given object implements the BuyerModelInstance interface.
 */
export function instanceOfBuyerModelInstance(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "guid" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "countryCode" in value;
    isInstance = isInstance && "town" in value;
    isInstance = isInstance && "postcode" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "isArchived" in value;
    isInstance = isInstance && "isStotlesVerified" in value;
    isInstance = isInstance && "oscarId" in value;
    isInstance = isInstance && "replacedBy" in value;
    isInstance = isInstance && "categories" in value;

    return isInstance;
}

export function BuyerModelInstanceFromJSON(json: any): BuyerModelInstance {
    return BuyerModelInstanceFromJSONTyped(json, false);
}

export function BuyerModelInstanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuyerModelInstance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'guid': json['guid'],
        'createdAt': (new Date(json['created_at'])),
        'name': json['name'],
        'url': json['url'],
        'countryCode': json['country_code'],
        'town': json['town'],
        'postcode': json['postcode'],
        'address': json['address'],
        'isArchived': json['is_archived'],
        'isStotlesVerified': json['is_stotles_verified'],
        'oscarId': json['oscar_id'],
        'replacedBy': (json['replaced_by'] === null ? null : (json['replaced_by'] as Array<any>).map(BuyerModelInstanceFromJSON)),
        'categories': ((json['categories'] as Array<any>).map(BuyerModelInstanceCategoryFromJSON)),
    };
}

export function BuyerModelInstanceToJSON(value?: BuyerModelInstance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'guid': value.guid,
        'created_at': (value.createdAt.toISOString()),
        'name': value.name,
        'url': value.url,
        'country_code': value.countryCode,
        'town': value.town,
        'postcode': value.postcode,
        'address': value.address,
        'is_archived': value.isArchived,
        'is_stotles_verified': value.isStotlesVerified,
        'oscar_id': value.oscarId,
        'replaced_by': (value.replacedBy === null ? null : (value.replacedBy as Array<any>).map(BuyerModelInstanceToJSON)),
        'categories': ((value.categories as Array<any>).map(BuyerModelInstanceCategoryToJSON)),
    };
}

