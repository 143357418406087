import React, { ReactNode } from "react";

import { sysPrimaryDefault } from "../../themes/colors";

export interface UIconProps {
  svg: ReactNode;
  color?: string;
  size?: number; // in px
  className?: string;
}

// untitled_ui icon generic component
function UIcon({ svg, color = sysPrimaryDefault, size = 24, className }: UIconProps) {
  return (
    <div style={{ color, width: size, height: size }} className={className}>
      {svg}
    </div>
  );
}

export default UIcon;
