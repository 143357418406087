/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CpvChartSectionParametersBuyer
 */
export interface CpvChartSectionParametersBuyer {
    /**
     * 
     * @type {number}
     * @memberof CpvChartSectionParametersBuyer
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CpvChartSectionParametersBuyer
     */
    name?: string;
}

/**
 * Check if a given object implements the CpvChartSectionParametersBuyer interface.
 */
export function instanceOfCpvChartSectionParametersBuyer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function CpvChartSectionParametersBuyerFromJSON(json: any): CpvChartSectionParametersBuyer {
    return CpvChartSectionParametersBuyerFromJSONTyped(json, false);
}

export function CpvChartSectionParametersBuyerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CpvChartSectionParametersBuyer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function CpvChartSectionParametersBuyerToJSON(value?: CpvChartSectionParametersBuyer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
    };
}

