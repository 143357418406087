import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { SavedViewType } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

export function useAllViews(
  options?: UseQueryOptions<SavedViewType[], unknown, SavedViewType[], string[]>,
) {
  const api = useOpenApi();
  return useQuery(
    ["views"],
    async () => {
      const { savedViews } = await api.listSavedViews();
      return savedViews;
    },
    options,
  );
}
