import React from "react";
import { Select } from "antd5";

import {
  ContainsMultipleOption,
  DuplicateOption,
  NotRelatedOption,
  UnsureOption,
} from "./OrgLabels";
import { CandidateOperation } from "./types";

import css from "./OperationDropdown.module.scss";

type Props = {
  onSelect: (q: CandidateOperation) => void;
  value: CandidateOperation;
};

function OperationDropdown({ onSelect, value }: Props): JSX.Element {
  return (
    <Select<CandidateOperation>
      className={css.qualificationDropdown}
      onSelect={onSelect}
      value={value}
      optionLabelProp="label"
    >
      <Select.Option label={<NotRelatedOption />} value={CandidateOperation.NOT_RELATED}>
        Anchor not mentioned
      </Select.Option>
      <Select.Option label={<DuplicateOption />} value={CandidateOperation.DUPLICATE}>
        Duplicate of anchor
      </Select.Option>
      <Select.Option
        label={<ContainsMultipleOption />}
        value={CandidateOperation.MULTIPLE_ENTITIES}
      >
        Contains anchor and others
      </Select.Option>
      <Select.Option label={<UnsureOption />} value={CandidateOperation.UNSURE}>
        Unsure
      </Select.Option>
    </Select>
  );
}

export default OperationDropdown;
