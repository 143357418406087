/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SignalToOrgIds } from './SignalToOrgIds';
import {
    SignalToOrgIdsFromJSON,
    SignalToOrgIdsFromJSONTyped,
    SignalToOrgIdsToJSON,
} from './SignalToOrgIds';

/**
 * 
 * @export
 * @interface MigrateLayerCakeRequest
 */
export interface MigrateLayerCakeRequest {
    /**
     * 
     * @type {string}
     * @memberof MigrateLayerCakeRequest
     */
    feedSettingsId: string;
    /**
     * 
     * @type {boolean}
     * @memberof MigrateLayerCakeRequest
     */
    migrateSavedViews: boolean;
    /**
     * 
     * @type {Array<SignalToOrgIds>}
     * @memberof MigrateLayerCakeRequest
     */
    signalToOrgIds: Array<SignalToOrgIds>;
}

/**
 * Check if a given object implements the MigrateLayerCakeRequest interface.
 */
export function instanceOfMigrateLayerCakeRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "feedSettingsId" in value;
    isInstance = isInstance && "migrateSavedViews" in value;
    isInstance = isInstance && "signalToOrgIds" in value;

    return isInstance;
}

export function MigrateLayerCakeRequestFromJSON(json: any): MigrateLayerCakeRequest {
    return MigrateLayerCakeRequestFromJSONTyped(json, false);
}

export function MigrateLayerCakeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MigrateLayerCakeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'feedSettingsId': json['feed_settings_id'],
        'migrateSavedViews': json['migrate_saved_views'],
        'signalToOrgIds': ((json['signal_to_org_ids'] as Array<any>).map(SignalToOrgIdsFromJSON)),
    };
}

export function MigrateLayerCakeRequestToJSON(value?: MigrateLayerCakeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'feed_settings_id': value.feedSettingsId,
        'migrate_saved_views': value.migrateSavedViews,
        'signal_to_org_ids': ((value.signalToOrgIds as Array<any>).map(SignalToOrgIdsToJSON)),
    };
}

