/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveBuyersRequest
 */
export interface RemoveBuyersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RemoveBuyersRequest
     */
    buyerGuids: Array<string>;
}

/**
 * Check if a given object implements the RemoveBuyersRequest interface.
 */
export function instanceOfRemoveBuyersRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "buyerGuids" in value;

    return isInstance;
}

export function RemoveBuyersRequestFromJSON(json: any): RemoveBuyersRequest {
    return RemoveBuyersRequestFromJSONTyped(json, false);
}

export function RemoveBuyersRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveBuyersRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'buyerGuids': json['buyer_guids'],
    };
}

export function RemoveBuyersRequestToJSON(value?: RemoveBuyersRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'buyer_guids': value.buyerGuids,
    };
}

