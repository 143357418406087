/* tslint:disable */
/* eslint-disable */
/**
 * Stotles App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PersonalSubscriptionContent
 */
export interface PersonalSubscriptionContent {
    /**
     * 
     * @type {string}
     * @memberof PersonalSubscriptionContent
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalSubscriptionContent
     */
    leadSubscriptionId: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalSubscriptionContent
     */
    resourceType: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalSubscriptionContent
     */
    resourceId: string;
    /**
     * 
     * @type {string}
     * @memberof PersonalSubscriptionContent
     */
    resourceName: string | null;
    /**
     * 
     * @type {string}
     * @memberof PersonalSubscriptionContent
     */
    frequency: PersonalSubscriptionContentFrequencyEnum;
}


/**
 * @export
 */
export const PersonalSubscriptionContentFrequencyEnum = {
    Daily: 'DAILY',
    Weekly: 'WEEKLY'
} as const;
export type PersonalSubscriptionContentFrequencyEnum = typeof PersonalSubscriptionContentFrequencyEnum[keyof typeof PersonalSubscriptionContentFrequencyEnum];


/**
 * Check if a given object implements the PersonalSubscriptionContent interface.
 */
export function instanceOfPersonalSubscriptionContent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "leadSubscriptionId" in value;
    isInstance = isInstance && "resourceType" in value;
    isInstance = isInstance && "resourceId" in value;
    isInstance = isInstance && "resourceName" in value;
    isInstance = isInstance && "frequency" in value;

    return isInstance;
}

export function PersonalSubscriptionContentFromJSON(json: any): PersonalSubscriptionContent {
    return PersonalSubscriptionContentFromJSONTyped(json, false);
}

export function PersonalSubscriptionContentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PersonalSubscriptionContent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'leadSubscriptionId': json['lead_subscription_id'],
        'resourceType': json['resource_type'],
        'resourceId': json['resource_id'],
        'resourceName': json['resource_name'],
        'frequency': json['frequency'],
    };
}

export function PersonalSubscriptionContentToJSON(value?: PersonalSubscriptionContent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lead_subscription_id': value.leadSubscriptionId,
        'resource_type': value.resourceType,
        'resource_id': value.resourceId,
        'resource_name': value.resourceName,
        'frequency': value.frequency,
    };
}

