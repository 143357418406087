/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AiQuestionSection } from './AiQuestionSection';
import {
    AiQuestionSectionFromJSON,
    AiQuestionSectionFromJSONTyped,
    AiQuestionSectionToJSON,
} from './AiQuestionSection';
import type { ContactsSection } from './ContactsSection';
import {
    ContactsSectionFromJSON,
    ContactsSectionFromJSONTyped,
    ContactsSectionToJSON,
} from './ContactsSection';
import type { CpvChartSection } from './CpvChartSection';
import {
    CpvChartSectionFromJSON,
    CpvChartSectionFromJSONTyped,
    CpvChartSectionToJSON,
} from './CpvChartSection';
import type { DocumentListSection } from './DocumentListSection';
import {
    DocumentListSectionFromJSON,
    DocumentListSectionFromJSONTyped,
    DocumentListSectionToJSON,
} from './DocumentListSection';
import type { HeaderSection } from './HeaderSection';
import {
    HeaderSectionFromJSON,
    HeaderSectionFromJSONTyped,
    HeaderSectionToJSON,
} from './HeaderSection';
import type { IframeSection } from './IframeSection';
import {
    IframeSectionFromJSON,
    IframeSectionFromJSONTyped,
    IframeSectionToJSON,
} from './IframeSection';
import type { LinkListSection } from './LinkListSection';
import {
    LinkListSectionFromJSON,
    LinkListSectionFromJSONTyped,
    LinkListSectionToJSON,
} from './LinkListSection';
import type { RecordListSection } from './RecordListSection';
import {
    RecordListSectionFromJSON,
    RecordListSectionFromJSONTyped,
    RecordListSectionToJSON,
} from './RecordListSection';
import type { RichTextSection } from './RichTextSection';
import {
    RichTextSectionFromJSON,
    RichTextSectionFromJSONTyped,
    RichTextSectionToJSON,
} from './RichTextSection';
import type { SuppliersSection } from './SuppliersSection';
import {
    SuppliersSectionFromJSON,
    SuppliersSectionFromJSONTyped,
    SuppliersSectionToJSON,
} from './SuppliersSection';

/**
 * 
 * @export
 * @interface ReportSectionType
 */
export interface ReportSectionType {
    /**
     * 
     * @type {string}
     * @memberof ReportSectionType
     */
    id: string;
    /**
     * 
     * @type {RichTextSection}
     * @memberof ReportSectionType
     */
    richText?: RichTextSection;
    /**
     * 
     * @type {RecordListSection}
     * @memberof ReportSectionType
     */
    recordList?: RecordListSection;
    /**
     * 
     * @type {HeaderSection}
     * @memberof ReportSectionType
     */
    header?: HeaderSection;
    /**
     * 
     * @type {ContactsSection}
     * @memberof ReportSectionType
     */
    contacts?: ContactsSection;
    /**
     * 
     * @type {LinkListSection}
     * @memberof ReportSectionType
     */
    linkList?: LinkListSection;
    /**
     * 
     * @type {DocumentListSection}
     * @memberof ReportSectionType
     */
    documentList?: DocumentListSection;
    /**
     * 
     * @type {CpvChartSection}
     * @memberof ReportSectionType
     */
    cpvChart?: CpvChartSection;
    /**
     * 
     * @type {SuppliersSection}
     * @memberof ReportSectionType
     */
    suppliers?: SuppliersSection;
    /**
     * 
     * @type {IframeSection}
     * @memberof ReportSectionType
     */
    iframe?: IframeSection;
    /**
     * 
     * @type {AiQuestionSection}
     * @memberof ReportSectionType
     */
    aiQuestion?: AiQuestionSection;
}

/**
 * Check if a given object implements the ReportSectionType interface.
 */
export function instanceOfReportSectionType(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function ReportSectionTypeFromJSON(json: any): ReportSectionType {
    return ReportSectionTypeFromJSONTyped(json, false);
}

export function ReportSectionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportSectionType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'richText': !exists(json, 'richText') ? undefined : RichTextSectionFromJSON(json['richText']),
        'recordList': !exists(json, 'recordList') ? undefined : RecordListSectionFromJSON(json['recordList']),
        'header': !exists(json, 'header') ? undefined : HeaderSectionFromJSON(json['header']),
        'contacts': !exists(json, 'contacts') ? undefined : ContactsSectionFromJSON(json['contacts']),
        'linkList': !exists(json, 'linkList') ? undefined : LinkListSectionFromJSON(json['linkList']),
        'documentList': !exists(json, 'documentList') ? undefined : DocumentListSectionFromJSON(json['documentList']),
        'cpvChart': !exists(json, 'cpvChart') ? undefined : CpvChartSectionFromJSON(json['cpvChart']),
        'suppliers': !exists(json, 'suppliers') ? undefined : SuppliersSectionFromJSON(json['suppliers']),
        'iframe': !exists(json, 'iframe') ? undefined : IframeSectionFromJSON(json['iframe']),
        'aiQuestion': !exists(json, 'aiQuestion') ? undefined : AiQuestionSectionFromJSON(json['aiQuestion']),
    };
}

export function ReportSectionTypeToJSON(value?: ReportSectionType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'richText': RichTextSectionToJSON(value.richText),
        'recordList': RecordListSectionToJSON(value.recordList),
        'header': HeaderSectionToJSON(value.header),
        'contacts': ContactsSectionToJSON(value.contacts),
        'linkList': LinkListSectionToJSON(value.linkList),
        'documentList': DocumentListSectionToJSON(value.documentList),
        'cpvChart': CpvChartSectionToJSON(value.cpvChart),
        'suppliers': SuppliersSectionToJSON(value.suppliers),
        'iframe': IframeSectionToJSON(value.iframe),
        'aiQuestion': AiQuestionSectionToJSON(value.aiQuestion),
    };
}

