import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider } from "antd5";
import { CheckboxProps } from "antd5/lib";

import SignalEmptyState from "components/form_components/SignalEmptyState";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import Signal from "../../../../lib/icons/Signal";
import { SupplierOption } from "./types";

import css from "./SignalSuppliersChecklist.module.scss";

type Props = {
  category: "Competitor" | "Partner";
  options: SupplierOption[];
  onChange: (newSelectedSuppliers: SupplierOption[]) => void;
  selectedSuppliers: SupplierOption[];
  backBtnFn: () => void;
};

function SignalSuppliersChecklist({
  category,
  options,
  onChange,
  selectedSuppliers,
  backBtnFn,
}: Props) {
  const optionIds = options.map((o) => o.value);
  const selectedSupplierIds = selectedSuppliers.map((s) => s.value);

  // Checkbox options
  const checkedSupplierList = optionIds.filter((s) => selectedSupplierIds.includes(s));

  const isAllSuppliersChecked = options.length === checkedSupplierList.length;
  const isSomeSuppliersChecked =
    checkedSupplierList.length > 0 && checkedSupplierList.length < options.length;

  const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked) {
      onChange([...options, ...selectedSuppliers]);
    } else {
      onChange(selectedSuppliers.filter((s) => !optionIds.includes(s.value)));
    }
  };

  const emptyStateCategory = category.toLowerCase();

  const backBtn = (
    <Button
      onClick={() => backBtnFn()}
      className={css.backBtn}
      icon={<LeftOutlined />}
      iconPosition="start"
    >
      Back
    </Button>
  );

  return options.length === 0 ? (
    <>
      <div className={css.optionsHeader}>{backBtn}</div>
      <SignalEmptyState
        entity={emptyStateCategory}
        message={`Save suppliers as ${emptyStateCategory}s through your signal settings to uncover more renewal opportunities and achieve relevant results.`}
      />
    </>
  ) : (
    <div id="scrollableDiv" className={css.scrollableDiv} aria-label="scrollableDiv">
      <div className={css.optionsHeader}>
        {backBtn}
        <label className={css.labelAndIcon}>
          <Checkbox
            aria-label="Select all"
            indeterminate={isSomeSuppliersChecked}
            onChange={onCheckAllChange}
            checked={isAllSuppliersChecked}
            className={css.label}
          >
            Select all {category.toLowerCase()}s
          </Checkbox>
        </label>
        <Divider className={css.divider} />
      </div>

      <div className={css.checkboxGroup}>
        {options.map((currentSupplier) => (
          <React.Fragment key={currentSupplier.value}>
            <label className={css.labelAndIcon}>
              <Checkbox
                value={currentSupplier.value}
                name={currentSupplier.label}
                checked={selectedSupplierIds.includes(currentSupplier.value)}
                className={css.label}
                onChange={(e) => {
                  const isChecked = e.target.checked;

                  if (isChecked) {
                    onChange([...selectedSuppliers, currentSupplier]);
                  } else {
                    onChange(selectedSuppliers.filter((s) => s.value !== currentSupplier.value));
                  }
                }}
                aria-label="Supplier option"
              >
                <EllipsisTooltipText
                  fullText={currentSupplier.label}
                  containerClassname={css.labelText}
                />
              </Checkbox>
              <Signal className={css.signalIcon} size={16} label="signalIcon" />
            </label>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default SignalSuppliersChecklist;
